define("frontend/templates/add-bank-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j/CIfr7i",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"manualVerification\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"bank-account-modal\",null,[[\"user_id\",\"accountHolderName\",\"showClose\"],[[24,[\"model\",\"user_id\"]],[24,[\"model\",\"account_holder_name\"]],false]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"plaid-dropin\",null,[[\"user_id\",\"accountHolderName\",\"autoOpen\",\"setAutoPay\",\"showManualClose\",\"callSuccessAfterSave\",\"saveSuccess\"],[[24,[\"model\",\"user_id\"]],[24,[\"model\",\"account_holder_name\"]],true,false,false,true,[28,\"action\",[[23,0,[]],\"redirectToHooray\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/add-bank-account.hbs"
    }
  });

  _exports.default = _default;
});