define("frontend/templates/components/pick-a-day", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TtFnFi9S",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"classNames\",\"value\"],[\"form-control\",[24,[\"value\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/pick-a-day.hbs"
    }
  });

  _exports.default = _default;
});