define("frontend/components/state-autocomplete", ["exports", "frontend/mixins/autocomplete/highlight-first-match"], function (_exports, _highlightFirstMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend(_highlightFirstMatch.default, {
    countryInfo: inject.service(),
    states: computed.readOnly('countryInfo.states')
  });

  _exports.default = _default;
});