define("frontend/models/veterinarian-clinic-list-item", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend({
    vet_group: _emberData.default.belongsTo('vet-group', {
      async: false
    }),
    veterinarian_clinic: _emberData.default.belongsTo('veterinarian-clinic', {
      async: false
    }),
    isVetGroup: computed.notEmpty('vet_group'),
    isVet: computed.notEmpty('veterinarian_clinic')
  });

  _exports.default = _default;
});