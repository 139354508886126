define("frontend/templates/components/multi-select/select-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VIP6vL3v",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"thumbnail\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\",true],[10,\"class\",\"select-thumbnail\"],[11,\"src\",[22,\"thumbnail\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"select-label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"check\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/multi-select/select-option.hbs"
    }
  });

  _exports.default = _default;
});