define("frontend/components/percentage-input", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['percentage-input'],
    value: null,
    click: function click() {
      (0, _jquery.default)(this.element.querySelectorAll('input')).focus();
    }
  });

  _exports.default = _default;
});