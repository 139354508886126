define("frontend/templates/components/service-category-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5ej3N89o",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\",true],[11,\"src\",[29,[[22,\"iconUrl\"]]]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/service-category-icon.hbs"
    }
  });

  _exports.default = _default;
});