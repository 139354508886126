define("frontend/components/company-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['company-logo'],
    attributeBindings: ['src', 'alt'],
    company: null,
    placeholder: 'assets/images/settings/company-placeholder@3x.png',
    alt: 'Company Logo'
  });

  _exports.default = _default;
});