define("frontend/templates/components/mobile-masked-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BUp7IEU8",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mobile-input-label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[1,[28,\"input\",null,[[\"value\",\"type\",\"classNames\"],[[24,[\"value\"]],[24,[\"type\"]],\"mobile-input-value\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/mobile-masked-input.hbs"
    }
  });

  _exports.default = _default;
});