define("frontend/components/mobile-select", ["exports", "frontend/components/mobile-text-input"], function (_exports, _mobileTextInput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _mobileTextInput.default.extend({
    classNames: ['mobile-text-input', 'mobile-select'],
    classNameBindings: ['disabled'],
    options: [],
    value: null,
    passPreviousSelected: false,
    passFullOptionBack: false,
    setValueOnChange: true,
    defaultChooseText: computed('label', function () {
      return "Choose ".concat(this.get('label').toLowerCase());
    }),
    selectedLabel: computed('value', 'options.[]', function () {
      var _this = this;

      var selectedOption = {};
      this.get('options').forEach(function (opt) {
        if (opt.groupName === undefined) {
          if (opt.value == _this.get('value')) {
            selectedOption = opt;
          }
        } else {
          var innerFound = opt.options.find(function (innerOpt) {
            return innerOpt.value == _this.get('value');
          });

          if (innerFound) {
            selectedOption = innerFound;
          }
        }
      });
      return selectedOption.label;
    }),
    actions: {
      openSelect: function openSelect() {
        if (!this.get('disabled')) {
          if (this.openSelect) {
            this.openSelect();
          } else {
            this.set('selectOpen', true);
          }
        }
      },
      clickOption: function clickOption(option) {
        var previousSelection = this.get('value');
        this.set('selectOpen', false);

        if (this.get('setValueOnChange')) {
          this.set('value', option.value);
        }

        var valueToPassBack = this.get('passFullOptionBack') ? option : option.value;

        if (this.selectOption) {
          if (this.get('passPreviousSelected')) {
            this.selectOption(valueToPassBack, previousSelection);
          } else {
            this.selectOption(valueToPassBack);
          }
        }
      }
    }
  });

  _exports.default = _default;
});