define("frontend/components/payment-method-modal/add-credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['payment-method-modal'],
    saving: false,
    flashMessage: inject.service(),
    actions: {
      setCard: function setCard(card, stripe) {
        this.setProperties({
          card: card,
          stripe: stripe
        });
      },
      saveCard: function saveCard() {
        var _this = this;

        this.set('saving', true);
        this.get('stripe').createToken(this.get('card')).then(function (_ref) {
          var token = _ref.token,
              error = _ref.error;

          if (error) {
            _this.get('flashMessage').error(error.message);

            return;
          }

          _this.get('saveCard')(token);
        }).finally(function () {
          if (!_this.get('isDestroyed')) {
            _this.set('saving', false);
          }
        });
      }
    }
  });

  _exports.default = _default;
});