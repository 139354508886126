define("frontend/components/week-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['week-select'],
    selectedDays: [],
    startDate: null,
    lastStartDate: null,
    selectedDows: computed('selectedDays.[]', function () {
      return this.get('selectedDays').map(function (day) {
        return moment(day, 'YYYY-MM-DD').format('e');
      });
    }),
    weekDaysWithSelected: computed('selectedDows.[]', 'weekDays.[]', function () {
      var _this = this;

      return this.get('weekDays').map(function (date) {
        var dow = moment(date, 'YYYY-MM-DD').format('e');
        var isSelected = _this.get('selectedDows').indexOf(dow) > -1;
        return {
          isSelected: isSelected,
          date: date
        };
      });
    }),
    actions: {
      clickDay: function clickDay(day) {
        this.clickDay(day);
      }
    }
  });

  _exports.default = _default;
});