define("frontend/components/sign-out-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'button',
    classNames: ['sign-out-button'],
    classNameBindings: ['small'],
    small: false,
    click: function click() {
      this.signOut();
    }
  });

  _exports.default = _default;
});