define("frontend/controllers/admin/import-clients", ["exports", "frontend/mixins/autocomplete/highlight-first-match"], function (_exports, _highlightFirstMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Controller.extend(_highlightFirstMatch.default, {
    flashMessage: inject.service(),
    selectedCompany: null,
    errorTitle: null,
    errorMessages: [],
    showSuccessModal: false,
    actions: {
      uploadDocument: function uploadDocument(doc, fileName) {
        var _this = this;

        if (!this.get('selectedCompany')) {
          return;
        }

        this.set('uploading', true);
        Ember.$.ajax({
          url: "/api/v2/companies/".concat(this.get('selectedCompany.id'), "/import_clients"),
          method: 'POST',
          data: {
            document: doc,
            filename: fileName
          }
        }).then(function () {
          _this.set('showSuccessModal', true);
        }).fail(function (response) {
          if (response.status == 422) {
            _this.set('errorTitle', response.responseJSON.message);

            _this.set('errorMessages', response.responseJSON.errors);

            _this.set('uploading', false);
          } else {
            _this.get('flashMessage').error('Unknown error: Please let engineering know');
          }
        });
      },
      closeErrorModal: function closeErrorModal() {
        this.set('errorTitle', null);
        this.set('errorMessages', []);
      },
      closeSuccessModal: function closeSuccessModal() {
        window.location.reload();
        this.set('showSuccessModal', false);
      }
    }
  });

  _exports.default = _default;
});