define("frontend/templates/components/google-map", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cENQPgH6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"addressUrl\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"href\"]]],null,{\"statements\":[[0,\"    \"],[7,\"a\",true],[11,\"href\",[29,[[22,\"href\"]]]],[10,\"target\",\"_blank\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n      \"],[7,\"img\",true],[11,\"src\",[22,\"addressUrl\"]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"img\",true],[11,\"src\",[22,\"addressUrl\"]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/google-map.hbs"
    }
  });

  _exports.default = _default;
});