define("frontend/components/client-list/pet-output", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['pet-output'],
    tagName: 'section'
  });

  _exports.default = _default;
});