define("frontend/components/auto-bill/invoice-frequencies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['invoice-frequencies'],
    companyInvoiceFrequencies: [],
    companyInvoiceFrequenciesSorting: ['id'],
    sortedInvoiceFrequencies: computed.sort('companyInvoiceFrequencies', 'companyInvoiceFrequenciesSorting'),
    defaultInvoiceFrequency: null,
    biweeklyStart: null,
    biweeklyEnd: null
  });

  _exports.default = _default;
});