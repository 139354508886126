define("frontend/routes/client-list/household/appointments", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = _baseRoute.default.extend({
    queryParams: {
      appointmentType: {
        refreshModel: true
      }
    },
    currentUser: inject.service(),
    infinity: inject.service(),
    model: function model(_ref) {
      var appointmentType = _ref.appointmentType;

      var household = this._super.apply(this, arguments);

      var userIsStaff = this.get('currentUser.user.isStaff');

      if (userIsStaff) {
        return {
          forbidden: true
        };
      }

      if (appointmentType === 'repeating') {
        var recurring_appointment_groups = this.store.query('recurring-appointment-group', {
          household_id: household.get('id')
        });
        return Ember.RSVP.hash({
          household: household,
          recurring_appointment_groups: recurring_appointment_groups
        });
      } else if (appointmentType === 'preferences') {
        var employees = this.store.findAll('employee');
        household.reload();
        return Ember.RSVP.hash({
          household: household,
          employees: employees
        });
      } else {
        return Ember.RSVP.hash({
          household: household
        });
      }
    },
    afterModel: function afterModel(model) {
      // This will remove any newly created preferred staff while we load the real ones
      // from the backend
      // Need to do this in two loops since rolling back attributes will remove it
      // from preferred_staff and cause the forEach to go out of bounds
      var staffToRollback = [];
      model.household.get('preferred_staff').forEach(function (staff) {
        return staffToRollback.push(staff);
      });
      staffToRollback.forEach(function (staff) {
        return staff.rollbackAttributes();
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('destroying', false);
      controller.set('removingAppointment', null);
      controller.notifyPropertyChange('recurringAppointmentGroups');
      controller.set('selectedAppointments', []);
      controller.set('showAppointmentModal', false);
      controller.set('bulkEditing', false);
    },
    actions: {
      loading: function loading(transition) {
        var controller = this.controllerFor('client-list.household.appointments');
        controller.set('loading', true);
        transition.promise.finally(function () {
          controller.set('loading', false);
        });
      },
      transitionToAddAppointment: function transitionToAddAppointment(household) {
        var queryParams = {
          selectedHouseholdId: household.get('id'),
          backRoute: 'client-list.household.appointments',
          filter: household.get('owner.fullName'),
          backHouseholdId: household.get('id')
        };
        this.transitionTo('add-appointment.index', {
          queryParams: queryParams
        });
      },
      transitionToEditSingleAppointment: function transitionToEditSingleAppointment(appointmentIds) {
        var queryParams = {
          ids: appointmentIds,
          backRoute: 'client-list.household.appointments',
          backHouseholdId: this.get('controller.model.household.id')
        };
        this.transitionTo('edit-single-appointment', {
          queryParams: queryParams
        });
      },
      transitionToEditRecurringAppointmentGroup: function transitionToEditRecurringAppointmentGroup(group) {
        var queryParams = {
          backRoute: 'client-list.household.appointments',
          backHouseholdId: this.get('controller.model.household.id')
        };
        this.transitionTo('edit-recurring-appointment-group', group.get('id'), {
          queryParams: queryParams
        });
      },
      removeRecurringAppointmentGroup: function removeRecurringAppointmentGroup(appointmentGroup) {
        var queryParams = {
          backRoute: 'client-list.household.appointments'
        };
        this.transitionTo('remove-recurring-appointment-group', appointmentGroup.get('id'), {
          queryParams: queryParams
        });
      },
      infinityLoad: function infinityLoad() {
        this._infinityLoad();
      }
    }
  });

  _exports.default = _default;
});