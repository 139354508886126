define("frontend/templates/components/updating-time-frames-heads-up-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "08iCXdmU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,[[\"customContainerClassNames\"],[\"updating-time-frames-heads-up-modal\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[1,[28,\"inline-svg\",[\"assets/images/hamster.svg\"],null],false],[9],[0,\"\\n      \"],[7,\"header\",true],[8],[0,\"\\n        Heads up!\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"layout-row subheader\"],[8],[0,\"\\n      Our hamsters will be working hard in the background to update time windows for relevant appointments. This might take more than a few minutes, and you won’t be able to update your hours and time windows for \"],[7,\"span\",true],[10,\"class\",\"lowercase\"],[8],[1,[22,\"categoryName\"],false],[9],[0,\" services in the meantime.\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"buttons-container\"],[8],[0,\"\\n    \"],[1,[28,\"ppb-button\",null,[[\"classNames\",\"style\",\"text\",\"click\"],[\"save-button\",\"red\",\"Got it!\",[28,\"action\",[[23,0,[]],\"save\"],null]]]],false],[0,\"\\n    \"],[1,[28,\"ppb-button\",null,[[\"classNames\",\"style\",\"text\",\"click\"],[\"back-button\",\"red-white\",\"Let me check my work again ...\",[28,\"action\",[[23,0,[]],\"goBack\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/updating-time-frames-heads-up-modal.hbs"
    }
  });

  _exports.default = _default;
});