define("frontend/templates/client-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xyCzkIgs",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"sidebar-nav\",null,[[\"collapsed\",\"autoShowActivityLog\"],[true,[24,[\"autoShowActivityLog\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"client-list-page\"],[8],[0,\"\\n\"],[4,\"list-right-side-panel\",null,null,{\"statements\":[[4,\"if\",[[24,[\"noHouseholds\"]]],null,{\"statements\":[[4,\"unless\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"doo-error\",null,null,{\"statements\":[[0,\"          Your clients will appear here once you add them to PetPocketbook!\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"currentUser\",\"user\",\"canViewFullClientInfo\"]]],null,{\"statements\":[[4,\"unless\",[[28,\"and\",[[28,\"media\",[\"isMobile\"],null],[24,[\"onHouseholdRoute\"]]],null]],null,{\"statements\":[[0,\"          \"],[1,[28,\"household-select\",null,[[\"loading\",\"selectedHousehold\",\"selectHousehold\",\"infinityLoad\",\"transitionToAddClient\",\"households\"],[[24,[\"loading\"]],[24,[\"selectedHousehold\"]],[28,\"action\",[[23,0,[]],\"selectHousehold\"],null],[28,\"route-action\",[\"parentInfinityLoad\"],null],[28,\"route-action\",[\"transitionToAddClient\"],null],[24,[\"model\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"client-list-content\"],[8],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/client-list.hbs"
    }
  });

  _exports.default = _default;
});