define("frontend/routes/companies/pricing", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    media: Ember.inject.service(),
    model: function model() {
      this._super.apply(this, arguments);

      var serviceCategories = this.store.query('service-category', {}, {
        reload: true
      });
      var offeredServices = this.store.query('offered-service', {}, {
        reload: true
      });
      return Ember.RSVP.hash({
        serviceCategories: serviceCategories,
        offeredServices: offeredServices
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('dragAndDropActivated', !this.get('media.isMobile'));
    },
    actions: {
      reload: function reload() {
        var _this = this;

        this.refresh().then(function () {
          _this.get('controller').send('repositionOfferedServices');
        });
      }
    }
  });

  _exports.default = _default;
});