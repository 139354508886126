define("frontend/routes/schedule/index", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model: function model() {
      return this.modelFor('schedule');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.notifyPropertyChange('date');
      controller.notifyPropertyChange('services');
    }
  });

  _exports.default = _default;
});