define("frontend/templates/components/manually-send-contracts-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j5Lbjh6K",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,[[\"customContainerClassNames\"],[\"manually-send-contracts-modal\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"header-image\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"assets/images/signature-icon.svg\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"Congrats on your new client!\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Would you like to send your company agreements for your new client to review and sign now?\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"control-panel\"],[8],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"color\",\"onclick\",\"class\"],[\"red\",[28,\"action\",[[23,0,[]],\"sendContracts\"],null],\"send-button\"]],{\"statements\":[[0,\"      Send now\\n\"]],\"parameters\":[]},null],[4,\"ppb-button\",null,[[\"color\",\"onclick\",\"class\"],[\"red-white\",[28,\"action\",[[23,0,[]],\"onClose\"],null],\"dismiss-button\"]],{\"statements\":[[0,\"      No, I’ll ask them to review later\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/manually-send-contracts-modal.hbs"
    }
  });

  _exports.default = _default;
});