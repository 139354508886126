define("frontend/components/mobile-header-flex", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mobile-header-flex'],
    classNameBindings: ['type', 'includeSearchBar:has-search-bar'],
    activityLogs: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    hasUnreadLogs: Ember.computed.alias('activityLogs.hasUnreadLogs'),
    inboxHasUnreadMessages: Ember.inject.service(),
    hasUnreadMessages: Ember.computed.alias('inboxHasUnreadMessages.hasUnreadMessages'),
    type: 'default',
    showHamburger: true,
    showAdd: true,
    showMore: false,
    showEdit: false,
    doneText: 'Done',
    includeSearchBar: false,
    didInsertElement: function didInsertElement() {
      $('.ember-application').addClass('no-body-padding');
    },
    willDestroyElement: function willDestroyElement() {
      $('.ember-application').removeClass('no-body-padding');
    },
    showRedDot: Ember.computed('hasUnreadLogs', 'hasUnreadMessages', 'currentUser.user.isPetParent', function () {
      return this.get('hasUnreadMessages') || !this.get('currentUser.user.isPetParent') && this.get('hasUnreadLogs');
    }),
    actions: {
      clickHamburger: function clickHamburger() {
        Ember.run.scheduleOnce('afterRender', function () {
          $('body').toggleClass('push-active');
        });
      },
      clickBack: function clickBack() {
        this.clickBack();
      },
      clickAdd: function clickAdd() {
        this.clickAdd();
      },
      clickMore: function clickMore() {
        this.clickMore();
      },
      clickDone: function clickDone() {
        this.clickDone();
      },
      clickSearch: function clickSearch() {
        this.clickSearch();
      },
      clickGear: function clickGear() {
        this.clickGear();
      },
      clickPdf: function clickPdf() {
        this.clickPdf();
      },
      clickEdit: function clickEdit() {
        this.clickEdit();
      },
      clickSave: function clickSave() {
        this.clickSave();
      },
      clickNewMessage: function clickNewMessage() {
        this.clickNewMessage();
      }
    }
  });

  _exports.default = _default;
});