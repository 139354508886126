define("frontend/components/client-invoices-list/invoice-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['invoice-item'],
    classNameBindings: ['active']
  });

  _exports.default = _default;
});