define("frontend/controllers/portal/messages/company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    queryParams: ['appointmentId', 'showReportCard'],
    household: computed.readOnly('currentUser.user.household'),
    company_id: computed.readOnly('model.company.id'),
    settings: computed.readOnly('model.settings.settings'),
    appointmentId: null,
    showReportCard: false,
    groupedReportCardObserver: observer('appointmentId', 'showReportCard', function () {
      var _this = this;

      if (this.get('showReportCard')) {
        this.set('loadingReportCard', true);
        return this.get('store').findRecord('appointment', this.get('appointmentId')).then(function (appt) {
          return _this.get('store').findRecord('grouped_report_card', appt.get('grouped_report_card.id')).then(function (grc) {
            _this.set('loadingReportCard', false);

            _this.set('selectedReportCard', grc);
          });
        });
      }
    }),
    companies: computed('settings.@each.{user_last_seen_message_thread}', function () {
      var settings = this.get('settings');

      if (settings) {
        return $.map(settings, function (companySettings) {
          return Ember.Object.create({
            id: companySettings.company_id.toString(10),
            name: companySettings.company_name,
            logoUrl: companySettings.company_logo,
            lastMessage: companySettings.last_message,
            lastMessageTime: companySettings.last_message_time,
            lastSeenTime: companySettings.user_last_seen_message_thread,
            pendingRequestCategory: companySettings.pending_request_category
          });
        });
      }

      return [];
    }),
    hasMultipleCompanies: computed('companies.[]', function () {
      return this.get('companies.length') > 1;
    }),
    actions: {
      switchCompany: function switchCompany(companyId) {
        this.transitionToRoute('portal.messages.company', companyId);
      },
      onSentMessage: function onSentMessage() {
        this.get('settings').forEach(function (settings) {
          Ember.set(settings, 'user_last_seen_message_thread', moment().format());
        });
      },
      closeReportCard: function closeReportCard() {
        this.set('showReportCard', false);
        this.set('selectedReportCard', null);
      }
    }
  });

  _exports.default = _default;
});