define("frontend/templates/components/bootstrap-datepicker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jFxbIBbq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showToday\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",[29,[\"today-trigger \",[28,\"if\",[[24,[\"isToday\"]],\"disabled\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"today\"]],[8],[0,\"today\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/bootstrap-datepicker.hbs"
    }
  });

  _exports.default = _default;
});