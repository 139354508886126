define("frontend/models/address", ["exports", "ember-data", "frontend/utils/address-type", "frontend/mixins/address"], function (_exports, _emberData, _addressType, _address) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend(_address.default, {
    city: _emberData.default.attr('capitalized-string'),
    state: _emberData.default.attr('capitalized-string'),
    zipcode: _emberData.default.attr('trimmed-string'),
    street: _emberData.default.attr('capitalized-string'),
    address_type: _emberData.default.attr('string', {
      defaultValue: _addressType.default.HOME
    }),
    notes: _emberData.default.attr('string'),
    present: computed.readOnly('hasAddress'),
    static_maps_url: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    displayType: computed('address_type', function () {
      if (this.get('isHome')) {
        return 'Home';
      }

      if (this.get('isWork')) {
        return 'Work';
      }

      if (this.get('isOther')) {
        return 'Other';
      }
    }),
    isHome: computed.equal('address_type', _addressType.default.HOME),
    isWork: computed.equal('address_type', _addressType.default.WORK),
    isOther: computed.equal('address_type', _addressType.default.OTHER)
  });

  _exports.default = _default;
});