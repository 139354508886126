define("frontend/templates/edit-client/primary-contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "04KB9u3W",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"primary-contact add-edit-page\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"top-section\"],[8],[0,\"\\n    \"],[1,[28,\"user-selection-wheel\",null,[[\"canUpload\",\"selectedUser\",\"household\",\"onCreatePetOrUser\",\"clickPetOrUser\"],[true,[24,[\"model\",\"owner\"]],[24,[\"model\"]],[28,\"route-action\",[\"createPetOrUser\"],null],[28,\"route-action\",[\"clickPetOrUser\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[28,\"not\",[[24,[\"isPetParent\"]]],null]],null,{\"statements\":[[4,\"ppb-button\",null,[[\"classNames\",\"color\",\"onclick\"],[\"notifications-button\",\"light-red-background-with-icon\",[28,\"action\",[[23,0,[]],\"clickEditNotifications\",[24,[\"model\",\"owner\"]]],null]]],{\"statements\":[[0,\"        \"],[1,[28,\"inline-svg\",[\"assets/images/settings-icon-coral.svg\"],null],false],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[0,\"notifications\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n\"],[4,\"add-client/client-inputs\",null,[[\"onSubmit\",\"household\",\"editingNotificationSettings\",\"householdUser\"],[[28,\"route-action\",[\"save\"],null],[24,[\"model\"]],[24,[\"editingNotificationSettings\"]],[24,[\"model\",\"owner\"]]]],{\"statements\":[[0,\"    \"],[1,[28,\"adaptive-navigation-buttons\",null,[[\"clickRight\",\"clickLeft\",\"leftText\",\"rightText\"],[[23,1,[\"onSubmit\"]],[28,\"route-action\",[\"cancel\"],null],\"Cancel\",\"Save\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[4,\"if\",[[24,[\"editingNotificationSettings\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"edit-notifications-modal\",null,[[\"householdUser\",\"onClose\"],[[24,[\"model\",\"owner\"]],[28,\"action\",[[23,0,[]],\"closeEditNotifications\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/edit-client/primary-contact.hbs"
    }
  });

  _exports.default = _default;
});