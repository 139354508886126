define("frontend/routes/portal/messages", ["exports", "frontend/routes/base-route", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    currentUser: Ember.inject.service(),
    router: Ember.inject.service(),
    model: function model() {
      return _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/users/get_messaging_companies"),
        method: 'GET'
      });
    },
    setupController: function setupController() {
      this._super.apply(this, arguments); // eslint-disable-next-line no-undef,new-cap


      Beacon('destroy');
    }
  });

  _exports.default = _default;
});