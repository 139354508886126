define("frontend/templates/sign-up/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HEQjeO+t",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"sign-up-index-page\"],[8],[0,\"\\n  \"],[7,\"header\",true],[10,\"class\",\"get-started\"],[8],[0,\"Get started for free!\"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"get-started-blurb\"],[8],[0,\"Tell us about yourself below, and we’ll be in touch to discuss next steps!\"],[9],[0,\"\\n  \"],[1,[28,\"sign-up-inputs\",null,[[\"onSubmit\",\"request\"],[[28,\"action\",[[23,0,[]],\"submit\"],null],[24,[\"request\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/sign-up/index.hbs"
    }
  });

  _exports.default = _default;
});