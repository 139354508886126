define("frontend/templates/components/header-dot-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+QvRtfDx",
    "block": "{\"symbols\":[\"step\"],\"statements\":[[4,\"each\",[[24,[\"steps\"]]],null,{\"statements\":[[0,\"  \"],[7,\"span\",true],[11,\"class\",[29,[\"dot \",[28,\"if\",[[28,\"eq\",[[24,[\"currentStep\"]],[23,1,[]]],null],\"active\"],null]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/header-dot-progress.hbs"
    }
  });

  _exports.default = _default;
});