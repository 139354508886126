define("frontend/components/message-thread/new-message-household-select/selecting-households-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var inject = Ember.inject;

  var _default = Ember.Component.extend({
    selectedRecipients: null,
    selectedServiceCategories: [],
    selectedDates: [],
    step: 1,
    showMultidateMessage: false,
    shownMultidateMessage: false,
    specificDatesSelected: false,
    currentUser: inject.service(),
    actions: {
      nextStep: function nextStep() {
        this.set('step', this.get('step') + 1);
      },
      prevStep: function prevStep() {
        var currentStep = this.get('step'); // Skip the date selection step if the user hasn't
        // selected specific dates

        if (currentStep === 3) {
          if (this.get('selectedRecipients') === 'specific-dates') {
            this.set('step', currentStep - 1);
          } else {
            this.set('step', currentStep - 2);
          }
        } else if (currentStep > 1) {
          this.set('step', currentStep - 1);
        } else {
          this.get('onClose')();
        }
      },
      hideMultiDateMessage: function hideMultiDateMessage() {
        this.set('showMultiDateMessage', false);
        this.set('shownMultidateMessage', true);
      },
      dismissedMultiDateMessage: function dismissedMultiDateMessage() {
        this.send('hideMultiDateMessage');
        this.get('currentUser.user').reload();
      },
      changeDates: function changeDates(dateArray) {
        var formattedDates = dateArray.map(function (date) {
          return (0, _moment.default)(date).format('YYYY-MM-DD');
        });
        this.set('selectedDates', formattedDates);

        if (this.get('selectedDates.length') === 1) {
          if (this.get('currentUser.user').shouldSeeMessage('request-multi-date') && !this.get('shownMultidateMessage')) {
            this.set('showMultiDateMessage', true);
          }
        }
      },
      changeSingularDate: function changeSingularDate(momentDate) {
        var newDate = momentDate.format('YYYY-MM-DD');
        this.set('selectedDates', [newDate]);
      },
      updateSelectedRecipientDate: function updateSelectedRecipientDate(selection) {
        this.set('selectedRecipients', selection);

        if (selection === 'specific-dates') {
          this.set('step', this.get('step') + 1);
          this.set('specificDatesSelected', true);
        } else {
          this.set('step', this.get('step') + 2);
          this.set('specificDatesSelected', false);
        }
      },
      onClose: function onClose() {
        this.get('onClose')();
      },
      selectAppointments: function selectAppointments() {
        this.get('selectAppointments')();
      },
      setSelectedServiceCategories: function setSelectedServiceCategories(category) {
        var selectedCategories = this.get('selectedServiceCategories');

        if (selectedCategories.indexOf(category) > -1) {
          this.set('selectedServiceCategories', selectedCategories.without(category));
        } else {
          this.set('selectedServiceCategories', [].concat(_toConsumableArray(selectedCategories), [category]));
        }
      },
      selectAllServiceCategories: function selectAllServiceCategories() {
        var serviceCategories = this.get('serviceCategories');
        var selectedServiceCategories = this.get('selectedServiceCategories');

        if (serviceCategories === selectedServiceCategories) {
          this.set('selectedServiceCategories', []);
        } else {
          this.set('selectedServiceCategories', serviceCategories);
        }
      }
    }
  });

  _exports.default = _default;
});