define("frontend/models/appointment-compensation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    offered_service: _emberData.default.belongsTo('offered-service', {
      async: true
    }),
    user: _emberData.default.belongsTo('user', {
      async: true
    }),
    employee: _emberData.default.belongsTo('employee', {
      async: true
    }),
    employee_name: _emberData.default.attr(''),
    compensation: _emberData.default.attr('number'),
    additional_dog_compensation: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    num_completed: _emberData.default.attr('number'),
    num_additional_dogs: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    num_visits: _emberData.default.attr('number')
  });

  _exports.default = _default;
});