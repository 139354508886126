define("frontend/controllers/edit-client/pet", ["exports", "frontend/controllers/edit-client/edit-client-page-base", "frontend/utils/helpers"], function (_exports, _editClientPageBase, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = _editClientPageBase.default.extend({
    application: inject.controller(),
    flashMessage: inject.service(),
    appointmentsController: inject.controller('client-list.household'),
    currentSection: 'basic',
    household: computed.readOnly('model.household'),
    canRemove: false,
    actions: {
      redirectToRemovePet: function redirectToRemovePet(pet) {
        this.transitionToRoute('edit-client.remove-pet', pet);
      },
      notifyUserPetCannotBeRemoved: function notifyUserPetCannotBeRemoved() {
        var _getPetGrammaticalDet = (0, _helpers.getPetGrammaticalDetails)(this.model),
            subjectUpper = _getPetGrammaticalDet.subjectUpper,
            pronoun = _getPetGrammaticalDet.pronoun,
            verb = _getPetGrammaticalDet.verb;

        var message = "".concat(subjectUpper, " can't be removed because ").concat(pronoun, " ").concat(verb, " appointments with another care provider");
        this.get('flashMessage').sendFlashMessage(message, 'user-error');
      },
      uploadedPhoto: function uploadedPhoto(photo) {
        this.set('model.pet.avatar_url', photo);
      }
    }
  });

  _exports.default = _default;
});