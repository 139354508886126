define("frontend/templates/components/drag-drop-activated-flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Tm4f934h",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"flash-message \",[28,\"if\",[[24,[\"dragAndDropActivated\"]],\"activated\",\"deactivated\"],null]]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flash-message__message\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flash-message__message__header\"],[8],[0,\"Drag-and-drop mode \"],[1,[28,\"if\",[[24,[\"dragAndDropActivated\"]],\"enabled\",\"disabled\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flash-message__message__text\"],[8],[1,[22,\"message\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/drag-drop-activated-flash-message.hbs"
    }
  });

  _exports.default = _default;
});