define("frontend/templates/components/custom-pricing-modal/offered-service-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DHb14z0l",
    "block": "{\"symbols\":[\"offeredServicePrice\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[1,[28,\"inline-svg\",[\"assets/images/pricetag.svg\"],null],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header-text\"],[8],[0,\"\\n      Customize prices\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"offeredServicePrices\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"custom-offered-service-price\"],[8],[0,\"\\n      \"],[7,\"div\",true],[11,\"class\",[29,[\"bullet \",[28,\"unless\",[[23,1,[\"offeredServiceClientPrice\"]],\"invisible\"],null]]]],[11,\"style\",[29,[\"color: #\",[23,1,[\"offeredService\",\"color_hex\"]],\";\"]]],[8],[0,\"\\n        •\\n      \"],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"offeredServiceClientPrice\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"offered-service\",null,[[\"overridePrices\",\"priceOverride\",\"incrementalPriceOverride\",\"offeredService\",\"click\"],[true,[23,1,[\"offeredServiceClientPrice\",\"price\"]],[23,1,[\"offeredServiceClientPrice\",\"incremental_price\"]],[23,1,[\"offeredService\"]],[28,\"action\",[[23,0,[]],\"onEditPrice\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"offered-service\",null,[[\"offeredService\",\"click\"],[[23,1,[\"offeredService\"]],[28,\"action\",[[23,0,[]],\"onEditPrice\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"bottom\"],[8],[0,\"\\n  \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\",\"classNames\"],[\"red\",\"done\",[28,\"action\",[[23,0,[]],\"onClose\"],null],\"only-right\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/custom-pricing-modal/offered-service-list.hbs"
    }
  });

  _exports.default = _default;
});