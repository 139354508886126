define("frontend/components/payment-form/bank-account", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['pay-container', 'center-block'],
    currentUser: inject.service(),
    cookies: inject.service(),
    savedBankAccountInfo: false,
    plaidRedirect: false,
    oauthEnabled: false,
    didInsertElement: function didInsertElement() {
      var storedToken = this.get('cookies').read('plaidLinkToken');

      if (this.get('plaidRedirect') && storedToken) {
        this.get('cookies').clear('plaidLinkToken', {
          path: '/'
        });
        this.setupPlaidLink(storedToken, this);
      } else {
        var self = this;
        this.getPlaidLinkToken(this.get('currentUser.user.id')).then(function (response) {
          self.get('cookies').write('plaidLinkToken', response.token, {
            path: '/'
          });
          self.setupPlaidLink(response.token, self);
        });
      }
    },
    showLinkButtons: computed('nickname', 'savedBankAccountInfo', function () {
      if (this.get('nickname') || this.get('savedBankAccountInfo')) {
        return false;
      }

      return true;
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)(this.element.querySelectorAll('#plaid-link-div')).off('click');
      (0, _jquery.default)(this.element.querySelectorAll('#new-plaid-link-div')).off('click');
    },
    getPlaidLinkToken: function getPlaidLinkToken(userId) {
      return _jquery.default.ajax({
        url: "/api/v2/users/".concat(userId, "/plaid_link_token"),
        method: 'GET',
        data: {
          oauth: this.get('oauthEnabled')
        }
      });
    },
    setupPlaidLink: function setupPlaidLink(token, self) {
      var _this = this;

      var plaidRedirect = self.get('plaidRedirect');
      var plaidParams = {
        env: _environment.default.plaid_env,
        token: token,
        selectAccount: true,
        onSuccess: function onSuccess(public_token, metadata) {
          var account_nickname = "".concat(metadata.institution.name.toUpperCase(), " (").concat(metadata.account.name, ")");
          self.send('setBankAccount', public_token, metadata.account_id, account_nickname);
          linkHandler.destroy();
        },
        onExit: function onExit(err, metadata) {
          if (err && err.error_code) {
            self.set('error', err);
          } else if (metadata.status === 'institution_not_found') {
            self.get('openManualBankAccount')();
          } else {
            if (self.get('onClose')) {
              self.get('onClose')();
            }
          }

          linkHandler.destroy();
        }
      };

      if (plaidRedirect) {
        plaidParams.receivedRedirectUri = window.location.href;
      }

      var linkHandler = Plaid.create(plaidParams);

      var plaidLinkHandler = function plaidLinkHandler() {
        if (_this.get('onPlaidOpen')) {
          _this.get('onPlaidOpen')();
        }

        linkHandler.open();
      };

      (0, _jquery.default)(this.element.querySelectorAll('#plaid-link-div')).click(plaidLinkHandler);
      (0, _jquery.default)(this.element.querySelectorAll('#new-plaid-link-div')).click(plaidLinkHandler);

      if (plaidRedirect) {
        linkHandler.open();
      }
    },
    actions: {
      setBankAccount: function setBankAccount(token, account_id, nickname) {
        this.get('setBankAccount')(token, account_id, nickname);

        if (this.get('isDestroyed')) {
          return;
        }

        this.send('closeLinkButtons');
      },
      openLinkButtons: function openLinkButtons() {
        this.set('showLinkButtons', true);
        this.set('manuallyOpenedLinkButtons', true);
      },
      closeLinkButtons: function closeLinkButtons() {
        this.set('showLinkButtons', false);
        this.set('manuallyOpenedLinkButtons', false);
      },
      openManualBankAccount: function openManualBankAccount() {
        this.openManualBankAccount();
      }
    }
  });

  _exports.default = _default;
});