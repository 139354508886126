define("frontend/controllers/companies/integrations/quickbooks", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    flashMessage: inject.service(),
    company: computed.alias('currentUser.user.company'),
    currentTab: 'status',
    onStatusTab: computed.equal('currentTab', 'status'),
    onAboutTab: computed.equal('currentTab', 'about'),
    actions: {
      openBeacon: function openBeacon() {
        // eslint-disable-next-line no-undef,new-cap
        Beacon('open');
      },
      openEffectiveDateModal: function openEffectiveDateModal() {
        this.set('showEffectiveDateModal', true);
      },
      closeEffectiveDateModal: function closeEffectiveDateModal() {
        this.set('showEffectiveDateModal', false);
      },
      openConfirmationModal: function openConfirmationModal(effectiveDate) {
        this.set('effectiveDate', effectiveDate);
        this.set('showEffectiveDateModal', false);
        this.set('showConfirmationModal', true);
      },
      closeConfirmationModal: function closeConfirmationModal() {
        this.set('effectiveDate', null);
        this.set('showConfirmationModal', false);
      },
      openDisconnectModal: function openDisconnectModal() {
        this.set('showDisconnectModal', true);
      },
      closeDisconnectModal: function closeDisconnectModal() {
        this.set('showDisconnectModal', false);
      },
      quickbookAuth: function quickbookAuth() {
        this.set('showConfirmationModal', false);
        var effectiveDate = this.get('effectiveDate');

        _jquery.default.ajax({
          url: "/api/v2/companies/".concat(this.get('currentUser.user.company.id'), "/quickbooks_authenticate"),
          method: 'POST',
          data: {
            effective_date: effectiveDate
          }
        }).done(function (_ref) {
          var redirect = _ref.redirect;
          return window.location.replace(redirect);
        });
      },
      syncNow: function syncNow() {
        var _this = this;

        this.set('syncNowDisabled', true);

        _jquery.default.ajax({
          url: "/api/v2/companies/".concat(this.get('currentUser.user.company.id'), "/quickbooks_export"),
          method: 'POST'
        }).then(function () {
          _this.get('flashMessage').sendFlashMessage('QuickBooks sync has been successfully started! It may take a few minutes to finish', 'success');
        });
      },
      disconnectQuickbooks: function disconnectQuickbooks() {
        this.set('disconnectDisabled', true);

        _jquery.default.ajax({
          url: "/api/v2/companies/".concat(this.get('currentUser.user.company.id'), "/quickbooks_disconnect"),
          method: 'POST'
        }).then(function () {
          window.location.reload();
        });
      }
    }
  });

  _exports.default = _default;
});