define("frontend/services/calendar-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    showingCalendarOptions: false,
    hidingInactiveStaff: false
  });

  _exports.default = _default;
});