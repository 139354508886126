define("frontend/templates/components/flash-error-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TuY0p5XV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"flash-notification\",null,[[\"color\",\"showOverlay\",\"dismissAfter\",\"dismiss\"],[\"red\",[24,[\"showOverlay\"]],[24,[\"dismissAfter\"]],[24,[\"dismiss\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"flash-notification__message\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"icon\"]],\"bandage\"],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"inline-svg\",[\"assets/images/bandage.svg\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"inline-svg\",[\"assets/images/poop-icon.svg\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[14,1],[0,\"\\n\"],[4,\"if\",[[24,[\"withRefresh\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"button-container\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"ppb-button\",null,[[\"color\",\"classNames\",\"onclick\"],[\"red\",\"full-button\",[28,\"action\",[[23,0,[]],\"refresh\"],null]]],{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"refresh-button-icon\"],[8],[0,\"\\n              \"],[1,[28,\"inline-svg\",[\"assets/images/refresh-icon.svg\"],null],false],[0,\"\\n            \"],[9],[0,\"\\n            Refresh\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"          \"],[4,\"ppb-button\",null,[[\"color\",\"onclick\"],[\"red-white-border\",[28,\"action\",[[23,0,[]],\"refresh\"],null]]],{\"statements\":[[0,\"Refresh\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/flash-error-notification.hbs"
    }
  });

  _exports.default = _default;
});