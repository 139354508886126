define("frontend/components/drag-scroll", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SCROLL_SPEED = 6;

  var _default = Ember.Component.extend({
    classNames: ['drag-scroll'],
    scrollContainer: null,
    activated: false,
    pressTimer: null,
    isDragAllowed: false,
    PRESS_HOLD_DURATION: 500,
    originalPosition: {},
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.dragEnter = this.dragEnter.bind(this);
      this.dragLeave = this.dragLeave.bind(this);
      this.scrollUp = this.scrollUp.bind(this);
      this.scrollDown = this.scrollDown.bind(this);
      this.pressHoldStart = this.pressHoldStart.bind(this);
      this.pressHoldEnd = this.pressHoldEnd.bind(this);
      this.setup();
    },
    setup: function setup() {
      var _this = this;

      (0, _jquery.default)('.scroll-box').bind('mouseover', function (e) {
        _this.dragEnter(e);
      });
      (0, _jquery.default)('.scroll-box').bind('mouseleave', function (e) {
        _this.dragLeave(e);
      });
      var $draggableObject = (0, _jquery.default)('.draggable-object');
      $draggableObject.on('touchstart', this.pressHoldStart);
      $draggableObject.on('touchend', this.pressHoldEnd);
    },
    pressHoldStart: function pressHoldStart(event) {
      var _this2 = this;

      this.pressTimer = setTimeout(function () {
        _this2.set('isDragAllowed', true);
      }, this.PRESS_HOLD_DURATION);
    },
    pressHoldEnd: function pressHoldEnd(event) {
      clearTimeout(this.pressTimer);
      this.set('isDragAllowed', false);
    },
    dragEnter: function dragEnter(e) {
      if (!this.isDragAllowed) return;

      if ((0, _jquery.default)(e.target).hasClass('top')) {
        this.scrollingUp = true;
        this.scrollUp();
      } else {
        this.scrollingDown = true;
        this.scrollDown();
      }
    },
    dragLeave: function dragLeave(e) {
      if ((0, _jquery.default)(e.target).hasClass('top')) {
        this.scrollingUp = false;
      } else {
        this.scrollingDown = false;
      }
    },
    teardown: function teardown() {
      var $scrollBox = (0, _jquery.default)(this.element.querySelectorAll('.scroll-box'));
      var $draggableObject = (0, _jquery.default)('.draggable-object');
      $draggableObject.off('dragstart');
      $draggableObject.off('dragend');
      $scrollBox.off('dragenter');
      $scrollBox.off('dragleave');
      this.scrollingUp = false;
      this.scrollingDown = false;
      $draggableObject.off('touchstart', this.pressHoldStart);
      $draggableObject.off('touchend', this.pressHoldEnd);
    },
    willDestroyElement: function willDestroyElement() {
      this.teardown();
    },
    scrollUp: function scrollUp() {
      var _this3 = this;

      var isActivated = this.get('activated');

      if (isActivated) {
        var $scrollContainer = this.getScrollContainer();
        window.requestAnimationFrame(function () {
          $scrollContainer.scrollTop($scrollContainer.scrollTop() - SCROLL_SPEED);

          if (_this3.scrollingUp) {
            _this3.scrollUp();
          }
        });
      }
    },
    scrollDown: function scrollDown() {
      var _this4 = this;

      var isActivated = this.get('activated');

      if (isActivated) {
        var $scrollContainer = this.getScrollContainer();
        window.requestAnimationFrame(function () {
          $scrollContainer.scrollTop($scrollContainer.scrollTop() + SCROLL_SPEED);

          if (_this4.scrollingDown) {
            _this4.scrollDown();
          }
        });
      }
    },
    getScrollContainer: function getScrollContainer() {
      var $scrollContainer = (0, _jquery.default)(window);

      if (this.get('scrollContainer')) {
        $scrollContainer = (0, _jquery.default)(this.get('scrollContainer'));
      }

      return $scrollContainer;
    }
  });

  _exports.default = _default;
});