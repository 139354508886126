define("frontend/controllers/account-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    accountSession: inject.service(),
    currentUser: inject.service(),
    currentCompanyId: computed(function () {
      return parseInt(this.get('accountSession').getCurrentCompanyId());
    }),
    actions: {
      changeCompany: function changeCompany(company_id) {
        this.get('accountSession').switchAccounts(company_id);
      }
    }
  });

  _exports.default = _default;
});