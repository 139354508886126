define("frontend/templates/components/client-bill-delivery-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gDin+gif",
    "block": "{\"symbols\":[\"email\"],\"statements\":[[4,\"if\",[[24,[\"noBillRecipients\"]]],null,{\"statements\":[[4,\"paper-input-readonly\",null,[[\"click\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showingBillDeliveryModal\"]]],null],true],null]]],{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"placeholder-text\"],[8],[0,\"add a bill recipient\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[4,\"adaptive-clarification\",null,null,{\"statements\":[[0,\"You haven’t added any bill recipients, so no email notifications will be sent out when bills close.\"]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"removable-readonly-chips\",null,[[\"content\",\"removeItem\"],[[24,[\"householdEmails\"]],[28,\"action\",[[23,0,[]],\"removeEmail\"],null]]],{\"statements\":[[0,\"    \"],[1,[23,1,[\"address\"]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[7,\"a\",true],[10,\"class\",\"side-panel-callout\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showingBillDeliveryModal\"]]],null],true],null]],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n    add another bill recipient\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"showingBillDeliveryModal\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"bill-delivery-modal\",null,[[\"onSubmit\",\"onClose\",\"emails\"],[[28,\"action\",[[23,0,[]],\"addEmail\"],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showingBillDeliveryModal\"]]],null],false],null],[24,[\"household\",\"emails\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/client-bill-delivery-options.hbs"
    }
  });

  _exports.default = _default;
});