define("frontend/templates/components/invoices-widget/invoices-group-draft-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KuLc2CFQ",
    "block": "{\"symbols\":[],\"statements\":[[0,\"Scheduled for \"],[1,[28,\"format-date\",[[24,[\"sendDate\"]],\"MMMM D, YYYY\"],null],false],[0,\"\\n\"],[1,[28,\"collapse-button\",null,[[\"collapsed\",\"click\"],[[24,[\"collapsed\"]],[28,\"action\",[[23,0,[]],\"toggleCollapsed\"],null]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"total-amount\"],[8],[1,[28,\"format-money\",[[24,[\"invoicesGroup\",\"amount\"]]],null],false],[0,\" \"],[7,\"span\",true],[10,\"class\",\"regular-text\"],[8],[0,\"queued\"],[9],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/invoices-widget/invoices-group-draft-header.hbs"
    }
  });

  _exports.default = _default;
});