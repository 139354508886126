define("frontend/utils/helpers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.getPetGrammaticalDetails = void 0;

  var getPetGrammaticalDetails = function getPetGrammaticalDetails(pet) {
    var name = pet.name;
    var gender = pet.gender;
    var pronoun = gender ? gender === 'male' ? 'he' : 'she' : 'they';
    var possesivePronoun = gender ? gender === 'male' ? 'his' : 'her' : 'their';
    var subject = name || 'this pet';
    var verb = gender ? 'has' : 'have';
    return {
      subject: subject,
      subjectUpper: subject.charAt(0).toUpperCase() + subject.slice(1),
      pronoun: pronoun,
      verb: verb,
      possesivePronoun: possesivePronoun,
      possesivePronounUpper: possesivePronoun.charAt(0).toUpperCase() + possesivePronoun.slice(1)
    };
  };

  _exports.getPetGrammaticalDetails = getPetGrammaticalDetails;
});