define("frontend/templates/components/out-of-time-window-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eM4K+QFP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,[[\"customContainerClassNames\"],[\"out-of-time-window-modal\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n      \"],[7,\"header\",true],[8],[0,\"\\n        Hmm... \"],[1,[28,\"inline-svg\",[\"assets/images/emojis/face-with-monocle.svg\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"subheader\"],[8],[0,\"\\n        You’ve picked a time that’s outside of the estimated arrival window shown to your clients. How would you like to proceed?\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-center stacked-button top-button\"],[8],[0,\"\\n      \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red\",\"Pick another time\",[28,\"action\",[[23,0,[]],\"pickAnotherTime\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-center stacked-button bottom-button\"],[8],[0,\"\\n      \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red-white-border\",\"Update time window\",[28,\"action\",[[23,0,[]],\"updateTimeWindow\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/out-of-time-window-modal.hbs"
    }
  });

  _exports.default = _default;
});