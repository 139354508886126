define("frontend/routes/invoices-summary/index", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = _baseRoute.default.extend({
    media: inject.service(),
    model: function model() {
      if (this.get('media.isMobile')) {
        return;
      }

      var _this$_super = this._super.apply(this, arguments),
          invoiceListItems = _this$_super.invoiceListItems;

      if (invoiceListItems.get('length') > 0) {
        var invoices = invoiceListItems.filter(function (item) {
          return item.get('invoice') != null;
        }).map(function (listItem) {
          return listItem.get('invoice');
        });
        var firstId = invoices.get('firstObject.id');

        if (firstId) {
          return this.transitionTo('invoices-summary.invoice-detail', firstId);
        }
      }
    }
  });

  _exports.default = _default;
});