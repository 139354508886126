define("frontend/utils/payment-reminder-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EVERY_DAY = {
    label: 'Daily',
    value: 'every-day'
  };
  var EVERY_2_DAYS = {
    label: 'Every other day',
    value: 'every-2-days'
  };
  var EVERY_3_DAYS = {
    label: 'Every 3 days',
    value: 'every-3-days'
  };
  var EVERY_4_DAYS = {
    label: 'Every 4 days',
    value: 'every-4-days'
  };
  var EVERY_5_DAYS = {
    label: 'Every 5 days',
    value: 'every-5-days'
  };
  var WEEKLY = {
    label: 'Every week',
    value: 'every-week'
  };
  var BIWEEKLY = {
    label: 'Every other week',
    value: 'every-2-weeks'
  };
  var NEVER = {
    label: 'Never',
    value: null
  };

  var lookupByValue = function lookupByValue(value) {
    if (value === EVERY_DAY.value) {
      return EVERY_DAY;
    }

    if (value === EVERY_2_DAYS.value) {
      return EVERY_2_DAYS;
    }

    if (value === EVERY_3_DAYS.value) {
      return EVERY_3_DAYS;
    }

    if (value === EVERY_4_DAYS.value) {
      return EVERY_4_DAYS;
    }

    if (value === EVERY_5_DAYS.value) {
      return EVERY_5_DAYS;
    }

    if (value === WEEKLY.value) {
      return WEEKLY;
    }

    if (value === BIWEEKLY.value) {
      return BIWEEKLY;
    }

    return NEVER;
  };

  var _default = {
    EVERY_DAY: EVERY_DAY,
    EVERY_2_DAYS: EVERY_2_DAYS,
    EVERY_3_DAYS: EVERY_3_DAYS,
    EVERY_4_DAYS: EVERY_4_DAYS,
    EVERY_5_DAYS: EVERY_5_DAYS,
    WEEKLY: WEEKLY,
    BIWEEKLY: BIWEEKLY,
    NEVER: NEVER,
    lookupByValue: lookupByValue
  };
  _exports.default = _default;
});