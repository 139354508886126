define("frontend/templates/edit-single-appointment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IW5vUJbA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"new-header\",null,[[\"hasClose\",\"cancel\"],[[24,[\"showCloseButton\"]],[28,\"route-action\",[\"cancel\",[24,[\"model\",\"appointments\"]]],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"header-text left-right-button\"],[8],[4,\"if\",[[24,[\"model\",\"appointmentRequest\"]]],null,{\"statements\":[[0,\"Review request\"]],\"parameters\":[]},{\"statements\":[[0,\"Edit appointment\"]],\"parameters\":[]}],[9],[0,\"\\n  \"],[2,\" On mobile adaptive-navigation-buttons are rendered here \"],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"header-back-wormhole-container\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"header-next-wormhole-container\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"add-edit-container\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"saving\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"loading-spinner\",null,[[\"message\"],[\"Saving...\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"redirecting\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"loading-spinner\",null,[[\"message\"],[\"Redirecting...\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/edit-single-appointment.hbs"
    }
  });

  _exports.default = _default;
});