define("frontend/templates/components/sign-out-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NpAuUy/g",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"small\"]]],null,{\"statements\":[[0,\"  \"],[7,\"img\",true],[10,\"src\",\"assets/images/sidebar/sign-out@3x.png\"],[10,\"alt\",\"sign-out\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  Sign Out\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/sign-out-button.hbs"
    }
  });

  _exports.default = _default;
});