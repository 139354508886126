define("frontend/routes/edit-client/secondary-contact", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = _baseRoute.default.extend({
    currentUser: inject.service(),
    model: function model() {
      var household = this._super.apply(this, arguments);

      if (household.get('secondaryContact.addresses.length') === 0) {
        household.get('secondaryContact.addresses').pushObject(this.buildAddress(household));
      }

      return household;
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var secondaryContact = model.get('secondaryContact');
      var sortedEmails = secondaryContact.get('emails').sortBy('email_type').reverse();
      secondaryContact.set('emails', sortedEmails);
    },
    buildAddress: function buildAddress(household) {
      var address = household.get('owner.addresses.firstObject');

      if (address) {
        return this.store.createRecord('address', {
          street: address.get('street'),
          city: address.get('city'),
          state: address.get('state'),
          zipcode: address.get('zipcode'),
          country: address.get('country'),
          address_type: address.get('address_type'),
          notes: address.get('notes')
        });
      } else {
        var user = this.get('currentUser.user');
        var state = user.get('company.state') || user.get('client_accounts.firstObject.company_state');
        var country = user.get('company.country') || user.get('client_accounts.firstObject.country');
        return this.store.createRecord('address', {
          state: state,
          country: country
        });
      }
    }
  });

  _exports.default = _default;
});