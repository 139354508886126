define("frontend/templates/components/holiday-surcharge-holiday-list-item-date-choice", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jQzrwCPh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"holidayDate\"]],[24,[\"date\",\"date\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"icon-container\"],[8],[0,\"\\n    \"],[1,[28,\"inline-svg\",[[24,[\"iconUrl\"]]],null],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"holiday-name\"],[8],[0,\"\\n    \"],[1,[22,\"holidayName\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"month-text\"],[8],[0,\"\\n    \"],[1,[24,[\"date\",\"month\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"day-of-month\"],[8],[0,\"\\n    \"],[1,[24,[\"date\",\"day\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"day-of-week\"],[8],[0,\"\\n    \"],[1,[24,[\"date\",\"dayOfWeek\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/holiday-surcharge-holiday-list-item-date-choice.hbs"
    }
  });

  _exports.default = _default;
});