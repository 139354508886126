define("frontend/templates/companies/integrations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/OoEPAIp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"integrations-settings\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"integrations-title\"],[8],[0,\"Integrations\"],[9],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"integration-card\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"goToQuickbooks\"],null]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"beta-tag\"],[8],[0,\"\\n      \"],[1,[28,\"inline-svg\",[\"/assets/images/integration-logos/beta-tag.svg\"],null],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"icon\"],[8],[7,\"img\",true],[10,\"src\",\"/assets/images/integration-logos/quickbooks-logo.png\"],[8],[9],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n      Automatically sync invoices and payments to QuickBooks Online\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"integration-status\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"company\",\"quickbooks_realm_id\"]]],null,{\"statements\":[[0,\"        \"],[7,\"img\",true],[10,\"class\",\"status-icon\"],[10,\"src\",\"/assets/images/integration-logos/connected.png\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[7,\"img\",true],[10,\"class\",\"status-icon\"],[10,\"src\",\"/assets/images/integration-logos/not-connected.png\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/companies/integrations/index.hbs"
    }
  });

  _exports.default = _default;
});