define("frontend/components/assign-staff-date/date-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['date-header', 'date-header--blue'],
    format: null,
    date: null
  });

  _exports.default = _default;
});