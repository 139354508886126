define("frontend/templates/components/pdf-viewer-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uxB3kV+E",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,[[\"onClose\",\"customContainerClassNames\"],[[28,\"action\",[[23,0,[]],\"onClose\"],null],\"pdf-viewer-modal\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"iframe\",true],[11,\"src\",[29,[[22,\"pdfUrl\"]]]],[10,\"frameBorder\",\"0\"],[10,\"scrolling\",\"auto\"],[10,\"height\",\"100%\"],[10,\"width\",\"100%\"],[8],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"ppb-button\",null,[[\"color\",\"text\",\"click\"],[\"red\",\"Close\",[28,\"action\",[[23,0,[]],\"onClose\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/pdf-viewer-modal.hbs"
    }
  });

  _exports.default = _default;
});