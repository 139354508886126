define("frontend/controllers/microdeposits", ["exports", "frontend/config/environment", "ember-cp-validations", "jquery"], function (_exports, _environment, _emberCpValidations, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'depositOne': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true
    })],
    'depositTwo': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true
    })]
  });

  var _default = Ember.Controller.extend(Validations, {
    queryParams: ['isStandalone'],
    flashMessage: inject.service(),
    attemptsLeft: 0,
    isStandalone: false,
    showClose: computed.not('isStandalone'),
    bankInfo: computed('model.bank_info', function () {
      var bank_info = this.get('model.bank_info');
      return "".concat(bank_info.bank_name, " ending ").concat(bank_info.last4);
    }),
    actions: {
      openManualBankAccount: function openManualBankAccount() {
        this.set('openManualBankAccount', true);
      },
      closeManualBankAccount: function closeManualBankAccount() {
        this.set('openManualBankAccount', false);
      },
      save: function save() {
        var _this = this;

        this.set('depositOneError', false);
        this.set('depositTwoError', false);
        this.set('incorrectValuesError', false);
        var deposit_one = this.get('depositOne');
        var deposit_two = this.get('depositTwo');
        var errors = false;

        if (!this.get('validations.attrs.depositOne.isValid') || deposit_one < 0 || deposit_one >= 1) {
          this.set('depositOneError', true);
          errors = true;
        }

        if (!this.get('validations.attrs.depositTwo.isValid') || deposit_two < 0 || deposit_two >= 1) {
          this.set('depositTwoError', true);
          errors = true;
        }

        if (errors) {
          return;
        }

        this.set('submitDisabled', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/users/verify_bank"),
          method: 'POST',
          data: {
            emd5: this.get('emd5'),
            deposit_one: deposit_one,
            deposit_two: deposit_two
          }
        }).done(function (response) {
          _this.set('submitDisabled', false);

          _this.transitionToRoute('verified-bank-account', _this.get('emd5'), {
            queryParams: {
              last_four: response.last4
            }
          });
        }).fail(function (data) {
          if (data && data.responseText) {
            var parsed = JSON.parse(data.responseText);

            if (parsed.message) {
              if (parsed.attempts_left == 0) {
                return _this.transitionToRoute('add-bank-account', parsed.emd5, {
                  queryParams: {
                    manualVerification: true
                  }
                }).then(function () {
                  _this.get('flashMessage').sendFlashMessage('It looks like you’ve used up all 10 attempts to verify your account. Please try entering your account information again.', 'user-error');
                });
              } else {
                _this.set('incorrectValuesError', true);

                _this.set('submitDisabled', false);

                _this.set('attemptsLeft', parsed.attempts_left);
              }
            } else {
              _this.get('flashMessage').sendFlashMessage('Unexpected error adding bank account', 'error');
            }
          } else {
            _this.get('flashMessage').sendFlashMessage('Unexpected error adding bank account', 'error');
          }
        });
      }
    }
  });

  _exports.default = _default;
});