define("frontend/components/household-select/household-select-with-account-balance-option", ["exports", "frontend/components/household-select/household-select-option"], function (_exports, _householdSelectOption) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _householdSelectOption.default.extend({
    classNames: ['household-select-with-account-balance-option'],
    accountBalance: null,
    petIds: [],
    sortedPets: Ember.computed('petIds.[]', 'household.alphaSortedPets.[]', function () {
      var sortedPets = this.get('household.alphaSortedPets');
      var petIds = this.get('petIds');

      if (petIds.length > 0) {
        return sortedPets.filter(function (pet) {
          return petIds.includes(parseInt(pet.get('id')));
        });
      } else {
        return sortedPets;
      }
    })
  });

  _exports.default = _default;
});