define("frontend/templates/components/resizable-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aHYocyXg",
    "block": "{\"symbols\":[],\"statements\":[[7,\"label\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[1,[28,\"textarea\",null,[[\"autoresize\",\"placeholder\",\"rows\",\"value\"],[true,[24,[\"placeholder\"]],[24,[\"rows\"]],[24,[\"value\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/resizable-textarea.hbs"
    }
  });

  _exports.default = _default;
});