define("frontend/services/add-appointment-data", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.setup();
    },
    setup: function setup() {
      this.set('selectedPetValues', []);
      this.set('dateAppointments', []);
      this.set('selectedRepeatOption', 'never');
      this.set('selectedEndDateOption', 'never');
      this.set('selectedOneTimeDates', []);
      this.set('selectedRepeatWeekDays', []);
      this.set('startDate', (0, _moment.default)().format('YYYY-MM-DD'));
      this.set('endDate', (0, _moment.default)().format('YYYY-MM-DD'));
      this.set('backRoute', null);
      this.set('defaultCareProviderId', null);
      this.set('defaultTime', null);
      this.set('saving', false);
    }
  });

  _exports.default = _default;
});