define("frontend/components/mobile-text-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mobile-text-input'],
    classNameBindings: ['borderBottom', 'borderTop'],
    label: null,
    value: null,
    type: null,
    borderBottom: false,
    borderTop: true
  });

  _exports.default = _default;
});