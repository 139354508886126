define("frontend/components/paper-chips-with-autocomplete", ["exports", "ember-paper/components/paper-chips/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _component.default.extend({
    searchText: '',
    actions: {
      onChipClick: function onChipClick(item) {
        if (this.onChipClick) {
          this.onChipClick(item);
        }
      },
      handleInputFocus: function handleInputFocus(autocomplete) {
        var input = this.getInput();
        this.set('focusedElement', 'input');

        if (!this.get('content').length && !input.is(':focus')) {
          input.focus();
        } else {
          this.set('activeChip', -1);
        } // Keep track of the autocomplete, so we can force it to close when navigating to chips.


        if (Ember.isEmpty(this.get('autocomplete')) && input.is('.ember-paper-autocomplete-search-input')) {
          this.set('autocomplete', autocomplete);
        }
      },
      handleAddItem: function handleAddItem(newItem, publicApi) {
        if (Ember.isPresent(newItem)) {
          var item = newItem;

          if (Ember.isPresent(this.get('searchField'))) {
            item = {};
            item[this.get('searchField')] = newItem;
          }

          this.get('addItem')(item);
        } else {
          var _item = {};
          _item[this.get('searchField')] = undefined;
          this.get('addItem')(_item);
        }

        this.set('newChipValue', '');
        this.set('searchText', '');
        Ember.set(publicApi, 'searchText', '');
        Ember.set(publicApi, 'lastSearchedText', '');
      }
    }
  });

  _exports.default = _default;
});