define("frontend/routes/profile/edit-about-me", ["exports", "frontend/routes/base-route", "frontend/mixins/cleanup-on-transition"], function (_exports, _baseRoute, _cleanupOnTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_cleanupOnTransition.default, {
    currentUser: Ember.inject.service(),
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('addressSidePanelActivated', false);
      var phoneNumbers = model.get('phone_numbers');

      if (phoneNumbers.get('length') == 0) {
        var defaultPhone = this.store.createRecord('phone-number');
        model.get('phone_numbers').pushObject(defaultPhone);
      }

      var addresses = model.get('addresses');

      if (addresses.get('length') == 0) {
        var user = this.get('currentUser.user');
        var state = user.get('company.state');
        var country = user.get('company.country');
        var defaultAddress = this.store.createRecord('address', {
          state: state,
          country: country
        });
        model.get('addresses').pushObject(defaultAddress);
      }
    },
    actions: {
      willTransition: function willTransition() {
        this._super.apply(this, arguments);

        this.get('controller.model.phone_numbers').filterBy('hasDirtyAttributes', true).forEach(function (p) {
          return p.rollbackAttributes();
        });
        this.get('controller.model.addresses').filterBy('hasDirtyAttributes', true).forEach(function (a) {
          return a.rollbackAttributes();
        });
        this.get('controller.model.emails').filterBy('hasDirtyAttributes', true).forEach(function (e) {
          return e.rollbackAttributes();
        });
      },
      redirectToSearch: function redirectToSearch(email) {
        this.transitionTo('staff-search', {
          queryParams: {
            email: email
          }
        });
      }
    }
  });

  _exports.default = _default;
});