define("frontend/templates/edit-client/remove-household-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QbloJI/c",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"yes-skip-prompt remove-household-prompt\"],[8],[0,\"\\n  \"],[7,\"img\",true],[10,\"class\",\"avatar-image\"],[11,\"src\",[24,[\"model\",\"avatarOrPlaceholder\"]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"Are you sure you would like to remove \"],[1,[24,[\"model\",\"fullName\"]],false],[0,\"?\"],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"clarification\"],[8],[0,\"Once removed, \"],[1,[24,[\"model\",\"getFirstName\"]],false],[0,\" will no longer appear in the \"],[1,[24,[\"model\",\"household\",\"owner\",\"last_name\"]],false],[0,\" family profile.\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"button-container\"],[8],[0,\"\\n    \"],[4,\"ppb-button\",null,[[\"tabIndex\",\"onclick\",\"color\"],[\"1\",[28,\"route-action\",[\"cancel\"],null],\"red-white-border\"]],{\"statements\":[[0,\"Cancel\"]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"ppb-button\",null,[[\"tabIndex\",\"onclick\",\"color\"],[\"2\",[28,\"route-action\",[\"remove\"],null],\"red\"]],{\"statements\":[[0,\"Remove\"]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/edit-client/remove-household-user.hbs"
    }
  });

  _exports.default = _default;
});