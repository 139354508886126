define("frontend/components/schedule/index/services-list", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showChangeCareProvider: false,
    staff: [],
    classNameBindings: ['showChangeCareProvider'],
    searchFilter: null,
    showCanceledAppointments: false,
    highlightWindowStart: null,
    highlightWindowEnd: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _jquery.default)(window).resize(function () {
        _this.setLastElement();
      });
      Ember.run.scheduleOnce('afterRender', function () {
        _this.setLastElement();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)(window).off('resize');
    },
    // This is a hack for drag and drop. When you drag a dog over another dog it causes an appointment
    // to flow over to a new line sometimes. If you're trying to drag over the appointment that went to
    // the new line drag and drop will spaz out. This sets a class on the last element in the service
    // line so that it does not flow to a new line.
    // It's called when:
    // - An appointment is moved successfully
    // - The screen is resized
    // - An appointment starts to move
    setLastElement: function setLastElement() {
      var petItems = (0, _jquery.default)(this.element.querySelectorAll('.pet-item'));

      if (!petItems) {
        return;
      }

      petItems.each(function (index, petItem) {
        petItem = (0, _jquery.default)(petItem);

        if (petItem.offset().left > (0, _jquery.default)('.pets').offset().left + (0, _jquery.default)('.pets').width() - 100) {
          petItem.addClass('last-element-on-line');
        } else {
          petItem.removeClass('last-element-on-line');
        }
      });
    },
    allDayServices: Ember.computed('services.[]', 'services.@each.{sortedCanceledGroupedAppointmentsIds,sortedGroupedAppointmentsWithoutCanceledIds}', function () {
      var _this2 = this;

      var services = this.get('services').sortBy('starts_at', 'care_provider.fullName');
      return services.filter(function (service) {
        // Trigger for ember computed
        service.get('sortedCanceledGroupedAppointmentsIds');
        service.get('sortedGroupedAppointmentsWithoutCanceledIds');
        var allDayService = service.get('all_day_service');

        if (_this2.get('showCanceledAppointments')) {
          return allDayService && service.get('sortedCanceledGroupedAppointments.length') > 0;
        } else {
          return allDayService && service.get('sortedGroupedAppointmentsWithoutCanceled.length') > 0;
        }
      });
    }),
    timedServices: Ember.computed('services.[]', 'services.@each.{sortedCanceledGroupedAppointmentsIds,sortedGroupedAppointmentsWithoutCanceledIds}', function () {
      var _this3 = this;

      var services = this.get('services').sortBy('starts_at', 'care_provider.fullName');
      return services.filter(function (service) {
        // Trigger for ember computed
        service.get('sortedCanceledGroupedAppointmentsIds');
        service.get('sortedGroupedAppointmentsWithoutCanceledIds');
        var allDayService = service.get('all_day_service');

        if (_this3.get('showCanceledAppointments')) {
          return !allDayService && service.get('sortedCanceledGroupedAppointments.length') > 0;
        } else {
          return !allDayService && service.get('sortedGroupedAppointmentsWithoutCanceled.length') > 0;
        }
      });
    }),
    actions: {
      clickAppointment: function clickAppointment(appointment) {
        this.get('clickAppointment')(appointment);
      },
      startDraggingPet: function startDraggingPet(groupedAppointment) {
        this.get('startDraggingPet')(groupedAppointment);
      },
      stopDraggingPet: function stopDraggingPet() {
        this.get('stopDraggingPet')();
      },
      setLastElement: function setLastElement() {
        this.setLastElement();
      },
      changeCareProvider: function changeCareProvider() {
        this.get('changeCareProvider')();
      },
      queueGroupedAppointment: function queueGroupedAppointment(groupedAppointment) {
        this.queueGroupedAppointment(groupedAppointment);
      },
      startGroupedReportCardQueue: function startGroupedReportCardQueue() {
        this.startGroupedReportCardQueue();
      }
    }
  });

  _exports.default = _default;
});