define("frontend/templates/components/calendar-options/care-provider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tMpWkbef",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[8],[1,[28,\"inline-svg\",[\"assets/images/drag-icon.svg\"],null],false],[9],[0,\"\\n\"],[1,[28,\"avatar-with-name\",null,[[\"imageUrl\",\"name\"],[[24,[\"careProvider\",\"avatarOrPlaceholder\"]],[24,[\"careProvider\",\"fullName\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/calendar-options/care-provider.hbs"
    }
  });

  _exports.default = _default;
});