define("frontend/controllers/add-appointment/times", ["exports", "frontend/config/environment", "frontend/mixins/add-appointment", "frontend/utils/generate-dates", "moment", "jquery", "frontend/utils/scroll-util"], function (_exports, _environment, _addAppointment, _generateDates, _moment, _jquery, _scrollUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer;

  var _default = Ember.Controller.extend(_addAppointment.default, {
    media: inject.service(),
    selectedPetValues: computed.readOnly('addAppointmentData.selectedPetValues'),
    selectedOneTimeDates: computed.readOnly('addAppointmentData.selectedOneTimeDates'),
    selectedRepeatWeekDays: computed.readOnly('addAppointmentData.selectedRepeatWeekDays'),
    sidePanelOpen: computed.notEmpty('sidePanelAppointment'),
    activeDate: computed.alias('sidePanelAppointment.date'),
    dateAppointments: computed.alias('addAppointmentData.dateAppointments'),
    startDate: computed.readOnly('addAppointmentData.startDate'),
    defaultCareProviderId: computed.readOnly('addAppointmentData.defaultCareProviderId'),
    defaultTime: computed.readOnly('addAppointmentData.defaultTime'),
    preferredStaffSorting: ['position'],
    sortedPreferredStaff: computed.sort('model.household.preferred_staff', 'preferredStaffSorting'),
    hasPreferredStaff: computed.notEmpty('sortedPreferredStaff'),
    // This is a reference to the appointment in dateAppointments
    activeAppointment: null,
    // This is a copy of activeAppointment that is handed off to the side panel
    sidePanelAppointment: null,
    editingExistingAppointment: false,
    currentUser: Ember.inject.service(),
    noAppointments: computed.empty('dateAppointments'),
    missingAppointments: computed('sortedDatesWithAppointments.[]', function () {
      return this.get('sortedDatesWithAppointments').any(function (sortedDateWithAppointments) {
        return sortedDateWithAppointments.appointments.length === 0;
      });
    }),
    validAppointments: computed.not('missingAppointments'),
    showErrors: false,
    finishDisabled: computed.or('noAppointments', 'missingAppointments'),
    firstAppointmentCreated: false,
    dateWithAppointments: function dateWithAppointments(date) {
      var appointments = this.get('dateAppointments').filterBy('date', (0, _moment.default)(date).format('YYYY-MM-DD'));
      return {
        date: date,
        appointments: appointments
      };
    },
    sortedDatesWithAppointments: computed('sortedDailyDays.[]', 'sortedWeekDays.[]', 'sortedOneTimeDates.[]', 'sortedRepeatWeekDays.[]', 'sortedMonthlyDays.[]', 'selectedRepeatOption', function () {
      if (this.get('repeatsNever')) {
        return this.get('sortedOneTimeDates');
      } else if (this.get('repeatsWeekly') || this.get('repeatsBiweekly')) {
        return this.get('sortedRepeatWeekDays');
      } else if (this.get('repeatsDaily')) {
        return this.get('sortedDailyDays');
      } else if (this.get('repeatsWeekDays')) {
        return this.get('sortedWeekDays');
      } else if (this.get('repeatsMonthly')) {
        return this.get('sortedMonthlyDays');
      }
    }),
    sortedRepeatWeekDays: computed('selectedRepeatWeekDays.[]', 'dateAppointments.[]', function () {
      return this.get('selectedRepeatWeekDays').sort(this.sortDateAsc).map(this.dateWithAppointments.bind(this));
    }),
    sortedOneTimeDates: computed('selectedOneTimeDates.[]', 'dateAppointments.[]', function () {
      return this.get('selectedOneTimeDates').sort(this.sortDateAsc).map(this.dateWithAppointments.bind(this));
    }),
    sortedWeekDays: computed('dateAppointments.[]', function () {
      var start = (0, _moment.default)(this.get('startDate')).startOf('week').add(1, 'day');
      var end = (0, _moment.default)(this.get('startDate')).endOf('week').subtract(1, 'day');
      return (0, _generateDates.default)(start, end).map(this.dateWithAppointments.bind(this));
    }),
    sortedDailyDays: computed('dateAppointments.[]', function () {
      var start = (0, _moment.default)(this.get('startDate')).startOf('week').add(1, 'day');
      var end = (0, _moment.default)(this.get('startDate')).endOf('week');
      var days = (0, _generateDates.default)(start, end);
      days.push((0, _moment.default)(this.get('startDate')).startOf('week').format('YYYY-MM-DD'));
      return days.map(this.dateWithAppointments.bind(this));
    }),
    sortedMonthlyDays: computed('dateAppointments.[]', function () {
      var start = (0, _moment.default)(this.get('startDate')).add(1, 'month').startOf('month');
      return [start.format('YYYY-MM-DD')].map(this.dateWithAppointments.bind(this));
    }),
    // TODO should move to side panel
    assignStaffSidePanelHeader: computed('media.isMobile', 'repeatsWeekly', 'repeatsBiWeekly', 'repeatsDaily', 'repeatsMonthly', 'repeatsNever', 'sidePanelAppointment', function () {
      if (this.get('repeatsNever')) {
        if (this.get('media.isMobile')) {
          return (0, _moment.default)(this.get('activeDate')).format('ddd, MMM D');
        } else {
          return (0, _moment.default)(this.get('activeDate')).format('dddd, MMMM D');
        }
      } else {
        if (this.get('media.isMobile')) {
          if (this.get('repeatsMonthly')) {
            return '1st of each month';
          }

          return "".concat((0, _moment.default)(this.get('activeDate')).format('dddd'), "s");
        } else {
          if (this.get('repeatsMonthly')) {
            return '1st of each month appointment options';
          }

          return "".concat((0, _moment.default)(this.get('activeDate')).format('dddd'), " appointment options");
        }
      }
    }),
    dateHeaderFormat: computed('repeatsNever', 'repeatsMonthly', function () {
      if (this.get('repeatsNever')) {
        return 'ddd, MMM D';
      }

      if (this.get('repeatsMonthly')) {
        return '[1st of each month]';
      }

      return 'dddd[s]';
    }),
    createFirstAppointments: function createFirstAppointments(firstAppointment) {
      var _this = this;

      var careProvider = firstAppointment.careProvider,
          time = firstAppointment.time,
          special_instructions = firstAppointment.special_instructions,
          offered_service = firstAppointment.offered_service,
          timeFrameStart = firstAppointment.timeFrameStart,
          timeFrameEnd = firstAppointment.timeFrameEnd,
          setByCustomWindow = firstAppointment.setByCustomWindow,
          positionsPreviousPets = firstAppointment.positionsPreviousPets,
          chargeForLateBooking = firstAppointment.chargeForLateBooking,
          addOnServices = firstAppointment.addOnServices;
      var selectedDates = null;

      if (this.get('repeatsNever')) {
        selectedDates = this.get('selectedOneTimeDates');
      } else if (this.get('repeatsWeekly') || this.get('repeatsBiweekly')) {
        selectedDates = this.get('selectedRepeatWeekDays');
      } else if (this.get('repeatsDaily')) {
        selectedDates = this.get('sortedDailyDays').mapBy('date');
      } else if (this.get('repeatsMonthly')) {
        selectedDates = this.get('sortedMonthlyDays').mapBy('date');
      } else {
        selectedDates = this.get('sortedWeekDays').mapBy('date');
      }

      selectedDates.map(function (date) {
        return (0, _moment.default)(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
      }).forEach(function (date) {
        var newAddOnServices = addOnServices.map(function (addOnService) {
          return Ember.Object.create({
            offered_service: addOnService.offered_service,
            petIds: addOnService.petIds,
            petNames: addOnService.petNames
          });
        });

        _this.get('dateAppointments').pushObject(Ember.Object.create({
          date: date,
          time: time,
          offered_service: offered_service,
          special_instructions: special_instructions,
          careProvider: careProvider,
          timeFrameStart: timeFrameStart,
          timeFrameEnd: timeFrameEnd,
          setByCustomWindow: setByCustomWindow,
          positionsPreviousPets: positionsPreviousPets,
          chargeForLateBooking: chargeForLateBooking,
          addOnServices: newAddOnServices
        }));
      });
    },
    nonAddOnOfferedServices: computed('model.offered_services.[]', function () {
      return this.get('model.offered_services').filter(function (offeredService) {
        return !offeredService.get('is_add_on');
      });
    }),
    sortedOfferedServices: computed.sort('nonAddOnOfferedServices', function (a, b) {
      if (a.get('service_category_position') > b.get('service_category_position')) {
        return 1;
      } else if (a.get('service_category_position') < b.get('service_category_position')) {
        return -1;
      } else {
        if (a.get('position') > b.get('position')) {
          return 1;
        } else if (a.get('position') < b.get('position')) {
          return -1;
        }

        return 0;
      }
    }),
    showEstimatedCosts: computed('repeatsNever', 'noAppointments', function () {
      return this.get('repeatsNever') && !this.get('noAppointments');
    }),
    holidayDatesTooltipText: computed('holidaySurcharges.[]', function () {
      var text = '';
      this.get('holidaySurcharges').forEach(function (holidaySurcharge) {
        text += "".concat(holidaySurcharge.policy, ":\n\n");
        holidaySurcharge.date_counts.forEach(function (dateCount) {
          text += "".concat((0, _moment.default)(dateCount.date, 'YYYY-MM-DD').format('dddd, MMMM D'));

          if (dateCount.count > 1) {
            text += " (x".concat(dateCount.count, ")");
          }

          text += '\n';
        });
        text += '\n';
      });
      return text;
    }),
    weekendDatesTooltipText: computed('weekendSurcharges.[]', function () {
      var text = '';
      this.get('weekendSurcharges').forEach(function (weekendSurcharge) {
        text += "".concat(weekendSurcharge.policy, ":\n\n");
        weekendSurcharge.date_counts.forEach(function (dateCount) {
          text += "".concat((0, _moment.default)(dateCount.date, 'YYYY-MM-DD').format('dddd, MMMM D'));

          if (dateCount.count > 1) {
            text += " (x".concat(dateCount.count, ")");
          }

          text += '\n';
        });
        text += '\n';
      });
      return text;
    }),
    offHoursDatesTooltipText: computed('offHoursSurcharges.[]', function () {
      var text = '';
      this.get('offHoursSurcharges').forEach(function (offHoursSurcharge) {
        text += "".concat(offHoursSurcharge.policy, ":\n\n");
        offHoursSurcharge.date_counts.forEach(function (dateCount) {
          text += "".concat((0, _moment.default)(dateCount.date, 'YYYY-MM-DD').format('dddd, MMMM D'));

          if (dateCount.count > 1) {
            text += " (x".concat(dateCount.count, ")");
          }

          text += '\n';
        });
        text += '\n';
      });
      return text;
    }),
    lateBookingDatesTooltipText: computed('lateBookingSurcharges.[]', function () {
      var text = '';
      this.get('lateBookingSurcharges').forEach(function (lateBookingSurcharge) {
        text += "".concat(lateBookingSurcharge.policy, ":\n\n");
        lateBookingSurcharge.date_counts.forEach(function (dateCount) {
          text += "".concat((0, _moment.default)(dateCount.date, 'YYYY-MM-DD').format('dddd, MMMM D'));

          if (dateCount.count > 1) {
            text += " (x".concat(dateCount.count, ")");
          }

          text += '\n';
        });
        text += '\n';
      });
      return text;
    }),
    estimateCosts: function estimateCosts() {
      var _this2 = this;

      var appointments = this.get('dateAppointments').map(function (appt) {
        var addOnServices = appt.get('addOnServices').map(function (addOnService) {
          return {
            pet_ids: addOnService.get('petIds'),
            offered_service_id: addOnService.get('offered_service.id')
          };
        });
        return {
          care_provider_id: appt.get('careProvider.id'),
          date: appt.get('date'),
          offered_service_id: appt.get('offered_service.id'),
          time: appt.get('time'),
          time_frame_start: appt.get('timeFrameStart'),
          time_frame_end: appt.get('timeFrameEnd'),
          charge_for_late_booking: appt.get('chargeForLateBooking'),
          add_on_services: addOnServices.filter(function (addOnService) {
            return addOnService.offered_service_id;
          })
        };
      });
      var pet_ids = this.get('selectedPetValues').map(function (pet) {
        return pet.get('id');
      });
      this.set('loadingCosts', true);

      _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/appointments/estimated_costs"),
        method: 'POST',
        data: {
          appointments: appointments,
          pet_ids: pet_ids
        }
      }).done(function (response) {
        _this2.set('totalCost', response.total_cost);

        _this2.set('appointmentsCost', response.appointments);

        _this2.set('holidayCosts', response.holiday_surcharge);

        _this2.set('weekendCosts', response.weekend_surcharge);

        _this2.set('offHoursCosts', response.off_hours_surcharge);

        _this2.set('lateBookingCosts', response.late_booking_surcharge);

        _this2.set('offeredServiceEstimatedCosts', response.offered_service_prices);

        _this2.set('holidaySurcharges', response.holiday_dates);

        _this2.set('weekendSurcharges', response.weekend_dates);

        _this2.set('offHoursSurcharges', response.off_hours_dates);

        _this2.set('lateBookingSurcharges', response.late_booking_dates);

        _this2.set('salesTaxCost', response.sales_tax);

        _this2.set('salesTaxPercentage', response.sales_tax_percentage);

        _this2.set('salesTaxTooltipText', response.sales_tax_tooltip_string);

        _this2.set('loadingCosts', false);
      });
    },
    estimatedCostObserver: observer('repeatsNever', 'dateAppointments.@each.{careProvider,date,offered_service,time,chargeForLateBooking}', 'dateAppointments.@each.addOnServices.[]', function () {
      if (!this.get('repeatsNever') || this.get('noAppointments')) {
        return;
      }

      Ember.run.debounce(this, this.estimateCosts, 500);
    }),
    staffAppointmentApprovalEnabled: computed.alias('currentUser.user.company.staff_appointment_approval'),
    showRequestButton: computed.and('staffAppointmentApprovalEnabled', 'repeatsNever'),
    rightButtonText: computed('media.isMobile', function () {
      return this.get('media.isMobile') ? 'Finish' : 'Request';
    }),
    actions: {
      editExistingAppointment: function editExistingAppointment(appointment) {
        this.set('editingExistingAppointment', true);
        this.set('activeAppointment', appointment);
        this.set('sidePanelAppointment', appointment.getProperties('date', 'careProvider', 'time', 'special_instructions', 'offered_service', 'timeFrameStart', 'timeFrameEnd', 'setByCustomWindow', 'positionsPreviousPets', 'chargeForLateBooking', 'addOnServices'));
      },
      addNewAppointment: function addNewAppointment(date) {
        this.set('editingExistingAppointment', false);
        var careProviderId = this.get('defaultCareProviderId');

        if (careProviderId == null) {
          careProviderId = this.get('hasPreferredStaff') ? this.get('sortedPreferredStaff.firstObject.user_id') : this.get('currentUser.user.id');
        }

        var careProvider = this.get('model.care_providers').filterBy('id', careProviderId.toString()).get('firstObject');
        var offered_service = this.get('sortedOfferedServices.firstObject');
        var time = offered_service.get('all_day_service') ? '12:00 AM' : null;

        if (this.get('defaultTime')) {
          time = this.get('defaultTime');
        }

        var special_instructions = '';
        var setByCustomWindow = false;
        var positionsPreviousPets = 'first';
        var chargeForLateBooking = false;
        var addOnServices = [];
        this.set('sidePanelAppointment', {
          date: date,
          careProvider: careProvider,
          special_instructions: special_instructions,
          offered_service: offered_service,
          time: time,
          setByCustomWindow: setByCustomWindow,
          positionsPreviousPets: positionsPreviousPets,
          chargeForLateBooking: chargeForLateBooking,
          addOnServices: addOnServices
        });
      },
      createAppointmentAddOnService: function createAppointmentAddOnService(pets) {
        var petIds = pets.map(function (pet) {
          return pet.get('id');
        });
        var petNames = pets.map(function (pet) {
          return pet.get('name');
        });
        return Ember.Object.create({
          offered_service: null,
          petIds: petIds,
          petNames: petNames
        });
      },
      updateAppointment: function updateAppointment(appointmentProps) {
        this.get('activeAppointment').setProperties(appointmentProps);
        this.send('hideSidePanel');
      },
      createAppointment: function createAppointment(appointmentProps) {
        if (this.get('firstAppointmentCreated')) {
          var careProvider = appointmentProps.careProvider,
              date = appointmentProps.date,
              time = appointmentProps.time,
              special_instructions = appointmentProps.special_instructions,
              offered_service = appointmentProps.offered_service,
              timeFrameStart = appointmentProps.timeFrameStart,
              timeFrameEnd = appointmentProps.timeFrameEnd,
              setByCustomWindow = appointmentProps.setByCustomWindow,
              positionsPreviousPets = appointmentProps.positionsPreviousPets,
              chargeForLateBooking = appointmentProps.chargeForLateBooking,
              addOnServices = appointmentProps.addOnServices;
          date = (0, _moment.default)(date).format('YYYY-MM-DD');
          this.get('dateAppointments').pushObject(Ember.Object.create({
            date: date,
            time: time,
            special_instructions: special_instructions,
            offered_service: offered_service,
            careProvider: careProvider,
            timeFrameStart: timeFrameStart,
            timeFrameEnd: timeFrameEnd,
            setByCustomWindow: setByCustomWindow,
            positionsPreviousPets: positionsPreviousPets,
            chargeForLateBooking: chargeForLateBooking,
            addOnServices: addOnServices
          }));
        } else {
          this.set('firstAppointmentCreated', true);
          this.createFirstAppointments(appointmentProps);
        }

        this.send('hideSidePanel');
      },
      removeAppointment: function removeAppointment() {
        this.get('dateAppointments').removeObject(this.get('activeAppointment'));
        this.send('hideSidePanel');
      },
      hideSidePanel: function hideSidePanel() {
        this.set('editingExistingAppointment', false);
        this.set('sidePanelAppointment', null);
        this.set('activeAppointment', null);
      },
      clickRightButton: function clickRightButton() {
        if (this.get('media.isMobile')) {
          this.set('showErrors', true);

          if (this.get('missingAppointments')) {
            _scrollUtil.default.scrollTopWithHeader('.assign-staff-date--error');
          } else {
            this.send('request');
          }
        } else {
          this.send('request');
        }
      },
      closeRequestOrApproveModal: function closeRequestOrApproveModal() {
        this.set('showRequestOrApproveModal', false);
      }
    }
  });

  _exports.default = _default;
});