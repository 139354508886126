define("frontend/components/flash-message-new-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
    },
    yesText: 'Yes',
    noText: 'No',
    color: 'yellow',
    actions: {
      yes: function yes() {
        this.yes();
      },
      no: function no() {
        this.no();
      }
    }
  });

  _exports.default = _default;
});