define("frontend/templates/components/custom-pricing-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dmHBiHKD",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal-scrollable\",null,[[\"customContainerClassNames\",\"onClose\"],[\"custom-pricing-modal\",[28,\"action\",[[23,0,[]],\"onClose\"],null]]],{\"statements\":[[4,\"if\",[[24,[\"saving\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"loading-spinner\",null,[[\"message\"],[\"Saving\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"editingPrice\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"custom-pricing-modal/edit-price\",null,[[\"household\",\"offeredServicePrice\",\"resetPrices\",\"onSubmit\",\"onClose\"],[[24,[\"household\"]],[24,[\"editingPrice\"]],[28,\"action\",[[23,0,[]],\"resetPrices\"],null],[28,\"action\",[[23,0,[]],\"savePrice\"],null],[28,\"action\",[[23,0,[]],\"closeEditPrice\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"custom-pricing-modal/offered-service-list\",null,[[\"offeredServiceClientPrices\",\"numCustomPrices\",\"resetAllPrices\",\"onEditPrice\",\"onClose\"],[[24,[\"household\",\"offered_service_client_prices\"]],[24,[\"numCustomPrices\"]],[28,\"action\",[[23,0,[]],\"resetAllPrices\"],null],[28,\"action\",[[23,0,[]],\"editPrice\"],null],[28,\"action\",[[23,0,[]],\"onClose\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/custom-pricing-modal.hbs"
    }
  });

  _exports.default = _default;
});