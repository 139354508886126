define("frontend/routes/edit-client/add-secondary-contact", ["exports", "frontend/routes/base-route", "frontend/mixins/cleanup-on-transition", "frontend/mixins/transition-back-route", "frontend/utils/email-type"], function (_exports, _baseRoute, _cleanupOnTransition, _transitionBackRoute, _emailType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = _baseRoute.default.extend(_cleanupOnTransition.default, _transitionBackRoute.default, {
    addClientSaving: inject.service(),
    flashMessage: inject.service(),
    currentUser: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    model: function model() {
      var household = this._super.apply(this, arguments);

      var user = this.store.createRecord('user');
      var householdUser = this.store.createRecord('household-user', {
        user: user,
        household: household
      });
      var defaultEmail = this.store.createRecord('email', {
        email_type: _emailType.default.PREFERRED
      });
      var defaultPhone = this.store.createRecord('phone-number');
      householdUser.get('phone_numbers').pushObject(defaultPhone);
      householdUser.get('emails').pushObject(defaultEmail);
      householdUser.get('addresses').pushObject(this.buildAddress(household));
      return householdUser;
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('anySidePanelActive', false);
    },
    buildAddress: function buildAddress(household) {
      var address = household.get('owner.addresses.firstObject');

      if (address) {
        return this.store.createRecord('address', {
          street: address.get('street'),
          city: address.get('city'),
          state: address.get('state'),
          zipcode: address.get('zipcode'),
          country: address.get('country'),
          address_type: address.get('address_type'),
          notes: address.get('notes')
        });
      } else {
        var user = this.get('currentUser.user');
        var state = user.get('company.state') || user.get('client_accounts.firstObject.company_state');
        var country = user.get('company.country') || user.get('client_accounts.firstObject.country');
        return this.store.createRecord('address', {
          state: state,
          country: country
        });
      }
    },
    actions: {
      save: function save(user) {
        var _this = this;

        this.set('saving', true);
        var editClient = Ember.getOwner(this).lookup('controller:edit-client');
        var household = editClient.get('model');
        var backRoute = editClient.get('backRoute');
        var backId = editClient.get('backId');
        var ownerEmails = user.get('household.owner.emails');
        var emails = user.get('emails');
        var duplicateEmail = false;
        emails.forEach(function (email) {
          ownerEmails.forEach(function (ownerEmail) {
            if (email.get('address').toLowerCase() == ownerEmail.get('address').toLowerCase()) {
              duplicateEmail = true;

              _this.get('flashMessage').sendFlashMessage("Sorry, humans can't share emails! Please remove ".concat(email.get('address'), " from ").concat(user.get('household.owner.first_name'), "'s profile and save before adding a new human with this email."), 'user-error');

              _this.set('saving', false);

              return;
            }
          });
        });

        if (duplicateEmail) {
          return;
        }

        this.get('addClientSaving').saveUser(user).then(function () {
          if (_this.get('currentUser.user.isPetParent')) {
            household = null;
          }

          return _this.transitionBack(backRoute, household, backId);
        }).finally(function () {
          _this.set('saving', false);
        });
      }
    }
  });

  _exports.default = _default;
});