define("frontend/controllers/portal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    router: inject.service(),
    currentRouteName: computed.readOnly('router.currentRouteName'),
    queryParams: ['signContract', 'apptRqstCtgryID', 'apptRqstCmpnyID', 'apptRqstPtIDs'],
    signContract: null,
    signContractParam: computed.alias('signContract'),
    showSignContractsModal: false,
    filterSignatureRequestsWithCategoryID: null,
    apptRqstCtgryID: null,
    appointmentRequestCategoryID: computed.alias('apptRqstCtgryID'),
    apptRqstCmpnyID: null,
    appointmentRequestCompanyID: computed.alias('apptRqstCmpnyID'),
    apptRqstPtIDs: null,
    appointmentRequestPetIDs: computed.alias('apptRqstPtIDs'),
    onAppointmentRequestRoute: computed('currentRouteName', function () {
      return this.get('currentRouteName').split('.')[1] == 'appointment-request';
    }),
    inhouseSignaturesObserver: observer('model.inhouseSignatures', function () {
      var signatureRequests = this.get('model.inhouseSignatures') || [];
      var allCategoryIDs = [];
      signatureRequests.forEach(function (request) {
        if (request && request.category_ids_required_for) {
          allCategoryIDs.push.apply(allCategoryIDs, _toConsumableArray(request.category_ids_required_for.map(function (id) {
            return id.toString();
          })));
        }
      });
      var uniqueIDs = Array.from(new Set(allCategoryIDs));

      if (uniqueIDs.length) {
        this.get('currentUser').set('serviceCategoriesWithPendingAgreements', uniqueIDs);
      }

      this.get('currentUser').set('allCategoriesRequireSigning', this.get('model.allCategoriesRequireSigning'));

      if (signatureRequests.length) {
        this.get('currentUser').set('hasAgreementsToReview', true);
      }
    }),
    signatureRequests: computed('model.inhouseSignatures', 'filterSignatureRequestsWithCategoryID', 'signContractParam', 'appointmentRequestCategoryID', function () {
      var signatureRequests = this.get('model.inhouseSignatures') || [];
      var idOfRequestToSign = this.get('signContractParam');

      if (idOfRequestToSign) {
        this.set('showSignContractsModal', true);
        return signatureRequests.filter(function (request) {
          return request.id === parseInt(idOfRequestToSign);
        });
      } //The appointmentRequestCategoryID comes from URL param when user is directed here to sign from appt request flow in React


      var filterID = this.get('filterSignatureRequestsWithCategoryID') || parseInt(this.get('appointmentRequestCategoryID'));

      if (filterID) {
        this.set('showSignContractsModal', true);
        var filteredRequests = signatureRequests.filter(function (request) {
          return request.category_ids_required_for.includes(filterID);
        });
        return filteredRequests;
      }

      return signatureRequests;
    }),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      this.needToPromptWithSignatureRequests = function () {
        var storedData = JSON.parse(localStorage.getItem("dismissedSignatureRequests")) || {};
        var storedTime = storedData.time || 0;
        var dismissedSignatureRequestIDs = storedData.dismissedIDs || [];
        var currentTime = new Date().getTime();
        var timeDifference = currentTime - storedTime;
        var hasBeen4Hours = timeDifference >= 14400000;
        var signatureRequests = _this.get('signatureRequests') || [];
        var currentSignatureRequestIDs = signatureRequests.map(function (request) {
          return request.id;
        });
        var hasNewSignatureRequests = currentSignatureRequestIDs.some(function (id) {
          return !dismissedSignatureRequestIDs.includes(id);
        });
        return hasBeen4Hours || hasNewSignatureRequests;
      }, this.signatureRequestsPromptCheck = function () {
        var hasRequests = (_this.get('signatureRequests') || []).length;

        var shouldPrompt = _this.needToPromptWithSignatureRequests();

        var modalIsNotAlreadyShowing = !_this.get('showSignContractsModal');

        if (hasRequests && shouldPrompt && modalIsNotAlreadyShowing) {
          _this.set("showSignContractsModal", true);
        }
      };
      setTimeout(function () {
        var hasRequests = (_this.get('signatureRequests') || []).length;

        var hasBeen4Hours = _this.needToPromptWithSignatureRequests();

        if (hasRequests) {
          if (hasBeen4Hours) {
            _this.set("showSignContractsModal", true);
          } else {
            _this.promptCheckInterval = setInterval(_this.signatureRequestsPromptCheck, 60000);
          }
        }
      }, 2000);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.promptCheckInterval) {
        clearInterval(this.promptCheckInterval);
      }
    },
    actions: {
      reloadCurrentUser: function reloadCurrentUser(option) {
        var _this2 = this;

        this.get('currentUser').reload().then(function () {
          if (option == 'accepted') {
            _this2.transitionToRoute('portal.billing', {
              queryParams: {
                addedClient: true
              }
            });
          } else {
            _this2.send('refreshModel');
          }
        });
      },
      reviewContractsLater: function reviewContractsLater() {
        this.set('showSignContractsModal', false);
        var signatureRequests = this.get('signatureRequests') || [];
        var dismissedSignatureRequestIDs = signatureRequests.map(function (request) {
          return request.id;
        });
        var dismissedData = {
          time: new Date().getTime(),
          dismissedIDs: dismissedSignatureRequestIDs
        };
        localStorage.setItem("dismissedSignatureRequests", JSON.stringify(dismissedData));
      },
      reShowContractsModal: function reShowContractsModal() {
        this.set('showSignContractsModal', true);
      },
      completedSigningForSpecificCategory: function completedSigningForSpecificCategory() {
        var categoryID = this.get('filterSignatureRequestsWithCategoryID');
        var serviceCategoriesWithPendingAgreements = this.get('currentUser.serviceCategoriesWithPendingAgreements');
        var updatedServiceCategoriesWithPendingAgreements = serviceCategoriesWithPendingAgreements.filter(function (id) {
          return parseInt(id) !== categoryID;
        });
        this.get('currentUser').set('serviceCategoriesWithPendingAgreements', updatedServiceCategoriesWithPendingAgreements);
        this.get('currentUser').notifyPropertyChange('serviceCategoriesWithPendingAgreements');
        this.set('filterSignatureRequestsWithCategoryID', null);
      }
    }
  });

  _exports.default = _default;
});