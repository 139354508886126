define("frontend/adapters/application", ["exports", "active-model-adapter", "ember-simple-auth-token/mixins/token-authorizer"], function (_exports, _activeModelAdapter, _tokenAuthorizer) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.default.extend(_tokenAuthorizer.default, {
    namespace: 'api/v1',
    session: Ember.inject.service(),
    accountSession: Ember.inject.service(),
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      this.ensureResponseAuthorized(status, headers, payload, requestData);
      return this._super.apply(this, arguments);
    },
    ensureResponseAuthorized: function ensureResponseAuthorized(status) {
      if (status === 401) {
        if (this.get('session.isAuthenticated')) {
          this.get('accountSession').signOut().finally(function () {
            window.location.href = '/users/login';
          });
        } else {
          window.location.href = '/users/login';
        }
      }
    }
  });

  _exports.default = _default;
});