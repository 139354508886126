define("frontend/templates/components/household-select/household-select-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WRMNqAlk",
    "block": "{\"symbols\":[\"pet\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"household-user-names \",[28,\"if\",[[24,[\"pendingClient\"]],\"client-pending\"],null]]]],[8],[0,\"\\n  \"],[1,[24,[\"household\",\"owner\",\"fullName\"]],false],[4,\"if\",[[28,\"gt\",[[24,[\"household\",\"users\",\"length\"]],1],null]],null,{\"statements\":[[0,\",\\n    \"],[1,[24,[\"household\",\"secondaryContact\",\"fullName\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"deniedClient\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"denied-client-info\"],[8],[0,\"\\n      (declined)\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"pendingClient\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"pending-client-info\"],[8],[0,\"\\n      (pending)\\n    \"],[9],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"pets\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"household\",\"alphaSortedPets\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"avatar-with-name\",null,[[\"imageUrl\",\"innerBorder\",\"name\"],[[23,1,[\"avatarOrPlaceholder\"]],true,[23,1,[\"name\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/household-select/household-select-option.hbs"
    }
  });

  _exports.default = _default;
});