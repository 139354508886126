define("frontend/components/edit-notifications-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend({
    session: inject.service(),
    accountSession: inject.service(),
    flashMessage: inject.service(),
    currentUser: inject.service(),
    householdUser: null,
    companyUserProfile: computed.alias('householdUser.company_user_profile'),
    actions: {
      onClose: function onClose() {
        this.get('onClose')();
      }
    }
  });

  _exports.default = _default;
});