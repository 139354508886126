define("frontend/controllers/sign-up/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Controller.extend({
    ajax: inject.service(),
    request: {},
    actions: {
      submit: function submit(sign_up) {
        var _this = this;

        if (this.get('submitted')) {
          return;
        }

        this.set('submitted', true);
        var url = '/api/v1/support_messages/sign_up';
        this.get('ajax').request(url, {
          url: url,
          method: 'POST',
          data: {
            sign_up: sign_up
          }
        }).then(function () {
          return _this.transitionToRoute('sign-up.success');
        }).then(function () {
          mixpanel.track('signup', sign_up);
        });
      }
    }
  });

  _exports.default = _default;
});