define("frontend/components/view-grouped-report-card/notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ["view-report-card--notes-container"],
    reportCard: null,
    notes: computed("reportCard.notes", function () {
      var rawNotes = this.get("reportCard.notes");

      if (rawNotes) {
        var notesWithLineBreaks = rawNotes.replace(/\n/g, "<br>");
        return Ember.String.htmlSafe(notesWithLineBreaks);
      } else {
        return null;
      }
    })
  });

  _exports.default = _default;
});