define("frontend/templates/components/report-card-image-save", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6tvdTzwX",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-button\",null,[[\"onclick\",\"onMouseEnter\",\"onMouseLeave\"],[[28,\"action\",[[23,0,[]],\"saveImage\"],null],[28,\"action\",[[23,0,[]],\"hoverIcon\",true],null],[28,\"action\",[[23,0,[]],\"hoverIcon\",false],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"inline-svg\",[[24,[\"iconUrl\"]]],null],false],[0,\"\\n\"],[4,\"unless\",[[24,[\"iconOnly\"]]],null,{\"statements\":[[0,\"    save photo\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/report-card-image-save.hbs"
    }
  });

  _exports.default = _default;
});