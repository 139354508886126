define("frontend/components/vaccinations-modal", ["exports", "frontend/mixins/autocomplete/highlight-first-match"], function (_exports, _highlightFirstMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend(_highlightFirstMatch.default, {
    classNames: ['vaccinations-modal'],
    store: Ember.inject.service(),
    vaccinations: null,
    allVaccinations: null,
    vaccineSearchText: '',
    nameInvalid: false,
    hasVaccinations: computed("vaccinations.@each.dirtyType", function () {
      var vaccinations = this.get("vaccinations");
      var nonDeletedVaccinations = vaccinations.filter(function (v) {
        return v.dirtyType !== "deleted";
      });
      return nonDeletedVaccinations.get("length");
    }),
    vaccinationsNotYetAdded: computed("vaccinations", function () {
      var vaccinations = this.get("vaccinations");

      if (vaccinations.get("length") === 1) {
        var vaccine = vaccinations.get("firstObject");

        if (vaccine.expires_on === null && vaccine.name === null) {
          return true;
        }
      }

      return false;
    }),
    modalTitle: computed("vaccinationsNotYetAdded", function () {
      var vaccinationsNotYetAdded = this.get("vaccinationsNotYetAdded");
      return vaccinationsNotYetAdded ? "Add vaccinations" : "Update vaccinations";
    }),
    primaryButtonText: computed("vaccinationsNotYetAdded", function () {
      var vaccinationsNotYetAdded = this.get("vaccinationsNotYetAdded");
      return vaccinationsNotYetAdded ? "add" : "update";
    }),
    rollbackInvalid: function rollbackInvalid() {
      var rollback = [];
      this.get('vaccinations').forEach(function (v) {
        if (!v.get('name') || v.get('expiresOnInvalid')) {
          rollback.push(v);
        }
      });
      rollback.forEach(function (v) {
        return v.rollbackAttributes();
      });
    },
    actions: {
      onClose: function onClose() {
        this.rollbackInvalid();
        this.get('onClose')();
      },
      vaccineOnSearchTextChange: function vaccineOnSearchTextChange(newSearchText) {
        this.set('searchText', newSearchText);
      },
      vaccineSearchOnClose: function vaccineSearchOnClose(vaccinationRecord) {
        var _this$get;

        var searchText = (_this$get = this.get('searchText')) === null || _this$get === void 0 ? void 0 : _this$get.toLowerCase();
        var match = this.allVaccinations.content.find(function (vaccine) {
          return vaccine.name.toLowerCase() === searchText;
        });

        if (searchText && !match) {
          vaccinationRecord.set('nameErrors', ['* invalid vaccine']);
        } else if (match) {
          vaccinationRecord.set('name', match.name);
          vaccinationRecord.set('nameErrors', []);
          this.send('addVaccination', vaccinationRecord, match);
        } else {
          vaccinationRecord.set('nameErrors', []);
        }

        this.set('searchText', '');
      },
      addVaccination: function addVaccination(vaccinationRecord, vaccinationItem) {
        vaccinationRecord.set('nameErrors', []);

        if (!vaccinationItem) {
          vaccinationRecord.set('name', undefined);
        } else {
          vaccinationRecord.set('name', vaccinationItem.name);
        }
      },
      addAnotherVaccination: function addAnotherVaccination() {
        this.get('addAnotherVaccination')();
      },
      deleteVaccination: function deleteVaccination(vaccination) {
        vaccination.deleteRecord();
      },
      onSubmit: function onSubmit() {
        this.rollbackInvalid();
        this.get('onSubmit')();
      }
    }
  });

  _exports.default = _default;
});