define("frontend/components/add-client/client-inputs", ["exports", "frontend/utils/phone-number-type", "frontend/utils/email-type"], function (_exports, _phoneNumberType, _emailType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;
  var EMAIL_REGEX = /.+@.+\..+/;
  var DUPLICATE_EMAIL_ERROR = 'This email already exists!';
  var MUST_HAVE_PREFERRED_EMAIL_ERROR = 'You must have a preferred email!';
  var DUPLICATE_EMAILS_IN_FORM = 'Emails must not be duplicated!';
  var HEADER_HEIGHT = 84;

  var _default = Ember.Component.extend({
    classNames: ['inputs-container'],
    householdUser: null,
    household: null,
    isOnAboutMePage: false,
    store: inject.service(),
    currentUser: inject.service(),
    pronounOptions: ['he | him', 'she | her', 'they | them', 'rather not say'],
    scheduleOptions: [{
      value: 'self',
      label: 'My schedule'
    }, {
      value: 'all',
      label: 'All staff'
    }],
    phoneTypes: [{
      label: 'Mobile',
      value: _phoneNumberType.default.MOBILE
    }, {
      label: 'Work',
      value: _phoneNumberType.default.WORK
    }, {
      label: 'Home',
      value: _phoneNumberType.default.HOME
    }, {
      label: 'Other',
      value: _phoneNumberType.default.OTHER
    }],
    emailTypes: [{
      label: 'Preferred',
      value: _emailType.default.PREFERRED
    }, {
      label: 'Alternate',
      value: _emailType.default.ALTERNATE
    }],
    duplicateEmail: inject.service(),
    emailValidation: [{
      message: '*must be a valid email',
      validate: function validate(inputValue) {
        return EMAIL_REGEX.test(inputValue) || !inputValue || inputValue.trim() === '';
      }
    }],
    emailErrors: [],
    emails: computed('householdUser.emails.@each.isDeleted', function () {
      return this.get('householdUser.emails').filterBy('isDeleted', false);
    }),
    addresses: computed('householdUser.addresses.@each.isDeleted', function () {
      return this.get('householdUser.addresses').filterBy('isDeleted', false);
    }),
    phoneNumbers: computed('householdUser.phone_numbers.@each.isDeleted', function () {
      return this.get('householdUser.phone_numbers').filterBy('isDeleted', false);
    }),
    preferredNameLabel: computed('isOnAboutMePage', function () {
      return this.get('isOnAboutMePage') ? 'I prefer to go by' : 'prefers to go by';
    }),
    genderLabel: computed('isOnAboutMePage', function () {
      return this.get('isOnAboutMePage') ? 'I identify as' : 'identifies as';
    }),
    actions: {
      scrollTo: function scrollTo(selector) {
        var topOfElement = document.querySelector(selector).offsetTop - HEADER_HEIGHT;
        document.querySelector('.page-wrap').scrollTo({
          top: topOfElement
        });
      },
      deleteRecord: function deleteRecord(record) {
        record.deleteRecord();
      },
      deleteEmail: function deleteEmail(email) {
        email.deleteRecord();

        if (email.get('email_type') === _emailType.default.PREFERRED) {
          this.get('emails').get('firstObject').set('email_type', _emailType.default.PREFERRED);
        }
      },
      submit: function submit() {
        var _this = this;

        var promises = [];
        this.set('duplicateEmails', false);
        this.set('preferredEmailIsBlank', false);
        this.set('duplicateEmailsInForm', false);
        this.get('householdUser.emails').forEach(function (email) {
          if (!email.get('isDeleted')) {
            promises.pushObject(_this.get('duplicateEmail').check(email.get('address'), _this.get('household')).then(function (response) {
              var emails = _this.get('emails').getEach('address');

              var isDuplicateInForm = emails.map(function (address) {
                return address.toLowerCase();
              }).uniq().length !== emails.length;

              var emailTypes = _this.get('emails').getEach('email_type');

              if (response.duplicate && !response.part_of_household) {
                email.set('errs', [DUPLICATE_EMAIL_ERROR]);

                _this.set('duplicateEmails', true);
              } else if (Ember.isEqual(email.get('email_type'), _emailType.default.PREFERRED) && Ember.isBlank(email.get('address')) || !emailTypes.includes(_emailType.default.PREFERRED)) {
                email.set('errs', [MUST_HAVE_PREFERRED_EMAIL_ERROR]);

                _this.set('preferredEmailIsBlank', true);
              } else if (isDuplicateInForm) {
                _this.get('emails').forEach(function (email) {
                  return email.set('errs', []);
                });

                email.set('errs', [DUPLICATE_EMAILS_IN_FORM]);

                _this.set('duplicateEmailsInForm', true);
              } else {
                email.set('errs', []);
              }

              email.set('isTouched', true);
            }));
          }
        });
        Ember.RSVP.Promise.all(promises).then(function () {
          if (!_this.get('duplicateEmails') && !_this.get('preferredEmailIsBlank') && !_this.get('duplicateEmailsInForm')) {
            _this.get('onSubmit')(_this.get('householdUser'));
          } else {
            _this.send('onInvalid');
          }
        });
      },
      checkDuplicateEmail: function checkDuplicateEmail(email) {
        var _this2 = this;

        if (email.get('hasDirtyAttributes')) {
          if (!EMAIL_REGEX.test(email.get('address'))) {
            return;
          }

          this.set('checkingEmail', true);
          this.get('duplicateEmail').check(email.get('address'), this.get('household')).then(function (response) {
            if (response.duplicate && !response.part_of_household) {
              email.set('errs', [DUPLICATE_EMAIL_ERROR]);
            } else {
              email.set('errs', []);
            }
          }).finally(function () {
            _this2.set('checkingEmail', false);
          });
        } else {
          email.set('errs', []);
        }
      },
      addPhone: function addPhone() {
        var newPhone = this.get('store').createRecord('phone-number');
        this.get('householdUser.phone_numbers').pushObject(newPhone);
      },
      addEmail: function addEmail() {
        var newEmail = this.get('store').createRecord('email');
        this.get('householdUser.emails').pushObject(newEmail);
      },
      addAddress: function addAddress() {
        var state = this.get('currentUser.user.company.state');
        var country = this.get('currentUser.user.company.country');
        var newAddress = this.get('store').createRecord('address', {
          state: state,
          country: country
        });
        this.get('householdUser.addresses').pushObject(newAddress);
      },
      rollbackAddress: function rollbackAddress() {
        if (!this.get('editingAddress.isNew')) {
          this.get('editingAddress').rollbackAttributes();
        }

        this.set('editingAddress', null);

        if (this.get('householdUser.addresses.length') === 0) {
          this.send('addAddress');
        }
      },
      submitAddress: function submitAddress() {
        this.set('editingAddress', null);
      },
      changePhoneType: function changePhoneType(phone, phoneType) {
        phone.set('phone_type_id', phoneType.value);
      },
      changeEmailType: function changeEmailType(email, emailType) {
        var hasPreferredEmail = this.get('emails').some(function (email) {
          return email.get('email_type') === _emailType.default.PREFERRED;
        });

        if (hasPreferredEmail && emailType.value === _emailType.default.PREFERRED) {
          this.get('emails').forEach(function (email) {
            return email.set('email_type', _emailType.default.ALTERNATE);
          });
        }

        email.set('email_type', emailType.value);
        hasPreferredEmail = this.get('emails').some(function (email) {
          return email.get('email_type') === _emailType.default.PREFERRED;
        });

        if (!hasPreferredEmail) {
          email.set('errs', []);
          this.get('emails').filter(function (filteredEmail) {
            return filteredEmail.get('address') !== email.get('address');
          }).get('firstObject').set('email_type', _emailType.default.PREFERRED);
        }
      },
      // Were calling submit in onInvalid because ember forms has some issues
      // with errors and customValidations being used together with rendering
      // timing
      onInvalid: function onInvalid() {
        var _this3 = this;

        setTimeout(function () {
          if ($('.first-name-input input').hasClass('ng-invalid')) {
            _this3.send('scrollTo', '.first-name-input');
          } else if ($('.last-name-input input').hasClass('ng-invalid')) {
            _this3.send('scrollTo', '.last-name-input');
          } else {
            var phoneError = false;
            $('.phone-inputs input').each(function (index, input) {
              if ($(input).hasClass('ng-invalid')) {
                phoneError = true;
              }
            });

            if (phoneError) {
              _this3.send('scrollTo', '.phone-inputs');
            } else {
              var emailError = false;
              $('.email-inputs input').each(function (index, input) {
                if ($(input).hasClass('ng-invalid')) {
                  emailError = true;
                }
              });

              if (emailError) {
                _this3.send('scrollTo', '.email-inputs');
              } else {
                var addressError = false;
                $('.address-container input').each(function (index, input) {
                  if ($(input).hasClass('ng-invalid')) {
                    addressError = true;
                  }
                });

                if (addressError) {
                  _this3.send('scrollTo', '.address-container');
                } else {
                  _this3.send('submit');
                }
              }
            }
          }
        }, 500);
      },
      changeGender: function changeGender(gender) {
        this.set('householdUser.gender', gender);
      },
      changeDefaultSchedule: function changeDefaultSchedule(schedule) {
        this.set('householdUser.default_schedule', schedule.value);
      }
    }
  });

  _exports.default = _default;
});