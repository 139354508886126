define("frontend/templates/components/percentage-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "P390YOy4",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"input\",null,[[\"value\",\"classNames\"],[[24,[\"value\"]],\"form-control\"]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"percentage-sign\"],[8],[0,\"%\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"clearfix\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/percentage-input.hbs"
    }
  });

  _exports.default = _default;
});