define("frontend/components/company-logo-upload", ["exports", "frontend/components/photo-upload"], function (_exports, _photoUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _photoUpload.default.extend({
    classNames: ['company-logo-upload'],
    placeholder: 'assets/images/settings/company-placeholder-with-border@3x.png'
  });

  _exports.default = _default;
});