define("frontend/components/activated-field-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['input-callout', 'input-activated', 'activated-field-text'],
    classNameBindings: ['active', 'borderTop', 'borderBottom'],
    borderTop: false,
    borderBottom: false,
    active: false
  });

  _exports.default = _default;
});