define("frontend/components/mobile-gray-field-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mobile-gray-field-header', 'mobile-gray-helper'],
    classNameBindings: ['borderTop', 'borderBottom'],
    headerText: '',
    borderTop: true,
    borderBottom: false
  });

  _exports.default = _default;
});