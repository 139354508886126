define("frontend/templates/components/user-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "mjUOmbEg",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"user\",\"avatar_url\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"img-inner-border\",null,[[\"src\",\"borderHex\"],[[24,[\"user\",\"avatar_url\"]],\"FFF\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"img-inner-border\",null,[[\"src\",\"borderHex\"],[[24,[\"placeholder\"]],\"FFF\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/user-avatar.hbs"
    }
  });

  _exports.default = _default;
});