define("frontend/templates/verified-bank-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DKsfLBxW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"full-screen-page\"],[8],[0,\"\\n  \"],[7,\"h1\",true],[10,\"class\",\"static-logo-long\"],[10,\"id\",\"modal-logo\"],[8],[0,\"PetPocketbook\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"id\",\"verified-bank-modal-content\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"static-fireworks-logo\"],[8],[0,\"Fireworks\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"bold-heading\"],[8],[0,\"\\n      Hooray!\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"step-info\"],[8],[0,\"\\n      You’ve successfully connected your bank account\"],[4,\"if\",[[24,[\"last_four\"]]],null,{\"statements\":[[0,\" ending \"],[1,[22,\"last_four\"],false]],\"parameters\":[]},null],[0,\"!\"],[7,\"br\",true],[8],[9],[0,\"\\n      Now get ready to join this fetchin’ crew!\\n    \"],[9],[0,\"\\n    \"],[7,\"h1\",true],[10,\"class\",\"static-pack-of-dogs\"],[8],[0,\"Pack of dogs\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"or\",[[24,[\"model\",\"redirect_to_portal\"]],[24,[\"model\",\"payment_token\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"button-container\"],[8],[0,\"\\n        \"],[4,\"ppb-button\",null,[[\"color\",\"onclick\"],[\"red\",[28,\"action\",[[23,0,[]],\"redirectToPaymentPortal\"],null]]],{\"statements\":[[0,\"Pay my bills\"]],\"parameters\":[]},null],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/verified-bank-account.hbs"
    }
  });

  _exports.default = _default;
});