define("frontend/components/assign-time-side-panel", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['assign-staff-side-panel', 'assign-time-side-panel'],
    store: inject.service(),
    media: inject.service(),
    dateTime: null,
    careProviderId: null,
    offeredService: null,
    timeFrameStart: null,
    timeFrameEnd: null,
    setByCustomWindow: null,
    selectedPosition: null,
    appointmentsBeingEdited: [],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('originalStartTime', this.get('selectedStartTime'));
    },
    showErrors: false,
    showSelectedStartTimeError: computed.and('showErrors', 'selectedStartTimeInvalid'),
    selectedStartTimeInvalid: computed('selectedStartTime', function () {
      var strictParsing = true;
      var format = 'h:mm A';
      return Ember.isBlank(this.get('selectedStartTime')) || !(0, _moment.default)(this.get('selectedStartTime'), format, strictParsing).isValid();
    }),
    oneTimeAppointmentGroups: computed('dateTime', 'careProviderId', function () {
      var care_provider_id = this.get('careProviderId');

      var date = _moment.default.parseZone(this.get('dateTime')).format('YYYY-MM-DD');

      var start_date = date;
      var end_date = date;
      var promise = this.get('store').query('service', {
        care_provider_id: care_provider_id,
        start_date: start_date,
        end_date: end_date
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    shownOneTimeAppointmentGroups: computed('oneTimeAppointmentGroups', function () {
      return this.get('oneTimeAppointmentGroups').filter(function (service) {
        return !service.get('all_day_service');
      });
    }),
    selectedStartTime: computed('dateTime', function () {
      return _moment.default.parseZone(this.get('dateTime')).format('h:mm A');
    }),
    headerText: computed('dateTime', function () {
      if (this.get('media.isMobile')) {
        return _moment.default.parseZone(this.get('dateTime')).format('ddd, MMM D');
      } else {
        return _moment.default.parseZone(this.get('dateTime')).format('dddd, MMMM D');
      }
    }),
    selectedAppointmentGroup: computed('oneTimeAppointmentGroups.[]', 'selectedStartTime', 'appointmentsBeingEdited.[]', function () {
      var _this = this;

      var ags = this.get('oneTimeAppointmentGroups');
      var group = ags.filter(function (ag) {
        return ag.get('starts_at_time') == _this.get('selectedStartTime');
      }).get('firstObject');

      if (group) {
        if (this.get('originalStartTime') == this.get('selectedStartTime')) {
          (function () {
            var groupedAppointments = group.get('sortedGroupedAppointmentsWithoutCanceled');

            var apptsBeingEditedIds = _this.get('appointmentsBeingEdited').map(function (appt) {
              return appt.get('id');
            });

            var found = false;

            for (var i = 0; i < groupedAppointments.length; i++) {
              var groupedAppt = groupedAppointments.objectAt(i);

              if (groupedAppt.get('appointments').any(function (appt) {
                return apptsBeingEditedIds.includes(appt.get('id'));
              })) {
                _this.set('selectedPosition', i);

                found = true;
              }
            }

            if (!found) {
              _this.set('selectedPosition', 0);
            }
          })();
        } else {
          this.set('selectedPosition', 0);
        }
      }

      return group;
    }),
    showPickupDropdown: computed('selectedAppointmentGroup', 'appointmentsBeingEdited.[]', function () {
      var group = this.get('selectedAppointmentGroup');
      var appts = this.get('appointmentsBeingEdited');

      if (group && appts) {
        var groupedAppts = group.get('sortedGroupedAppointmentsWithoutCanceled');

        if (groupedAppts.length > 1) {
          return true;
        }

        var apptIds = groupedAppts.get('firstObject.appointments').map(function (appt) {
          return appt.get('id');
        });
        return !apptIds.any(function (apptId) {
          return apptId == appts.get('firstObject.id');
        });
      }

      return group;
    }),
    positionOptions: computed('selectedAppointmentGroup', 'appointmentsBeingEdited.[]', function () {
      var _this2 = this;

      var group = this.get('selectedAppointmentGroup');

      if (!group) {
        return;
      }

      var apptsBeingEditedIds = this.get('appointmentsBeingEdited').map(function (appt) {
        return appt.get('id');
      });
      var groupedAppointments = group.get('sortedGroupedAppointmentsWithoutCanceled');
      groupedAppointments = groupedAppointments.reject(function (groupedAppointment) {
        return groupedAppointment.get('appointments').any(function (appt) {
          return apptsBeingEditedIds.includes(appt.get('id'));
        });
      });
      var options = [{
        label: 'First',
        value: 0
      }];

      if (groupedAppointments.length > 1) {
        var _loop = function _loop(i) {
          var appt = groupedAppointments.objectAt(i);
          var nextAppt = groupedAppointments.objectAt(i + 1);
          var apptPetNames = appt.get('sortedAppointments').map(function (appt) {
            return appt.get('pet.name');
          }).join('/');

          var skipOption = _this2.get('appointmentsBeingEdited').any(function (apptBeingEdited) {
            var editingAppt = appt.get('sortedAppointments').any(function (appt) {
              return appt.get('id') == apptBeingEdited.get('id');
            });
            var editingNextAppt = nextAppt.get('sortedAppointments').any(function (appt) {
              return appt.get('id') == apptBeingEdited.get('id');
            });
            return editingAppt || editingNextAppt;
          });

          if (!skipOption) {
            options.push({
              label: "After ".concat(apptPetNames),
              value: i + 1
            });
          }
        };

        for (var i = 0; i < groupedAppointments.length - 1; i++) {
          _loop(i);
        }
      }

      options.push({
        label: 'Last',
        value: group.get('sortedGroupedAppointmentsWithoutCanceled').length
      });
      return options;
    }),
    pickupWindow: function pickupWindow() {
      var startTime = this.get('selectedStartTime');

      if (!startTime) {
        return {};
      }

      var serviceCategory = this.get('offeredService.serviceCategory');

      if (!serviceCategory.get('use_time_blocks')) {
        return {};
      }

      var timeFrames = serviceCategory.get('time_frames');
      var startTimeMoment = (0, _moment.default)(startTime, 'hh:mm a');
      var dayOfWeek = (0, _moment.default)(this.get('dateTime')).day();
      var potentialTimeFrames = timeFrames.filter(function (timeFrame) {
        var start = (0, _moment.default)(timeFrame.get('start_time'), 'hh:mm a');
        var end = (0, _moment.default)(timeFrame.get('end_time'), 'hh:mm a').subtract(1, 'second');
        return timeFrame.get('days_of_week').includes(dayOfWeek) && startTimeMoment >= start && startTimeMoment <= end;
      });
      var showStart = null;
      var showEnd = null;

      if (potentialTimeFrames.length >= 1) {
        showStart = potentialTimeFrames.get('firstObject').get('start_time').replace(' ', '');
        showEnd = potentialTimeFrames.get('firstObject').get('end_time').replace(' ', '');
      } else if (serviceCategory.get('time_window_in_minutes')) {
        var timeWindow = serviceCategory.get('time_window_in_minutes');
        showStart = (0, _moment.default)(startTime, 'hh:mm a').subtract(timeWindow, 'minutes').format('h:mmA');
        showEnd = (0, _moment.default)(startTime, 'hh:mm a').add(timeWindow, 'minutes').format('h:mmA');
      }

      if (showStart && showEnd) {
        return {
          timeFrameStart: showStart,
          timeFrameEnd: showEnd
        };
      }
    },
    actions: {
      close: function close() {
        this.close();
      },
      openCustomizeTimeWindowModal: function openCustomizeTimeWindowModal() {
        this.set('originalTimeWindowStart', this.get('timeFrameStart'));
        this.set('originalTimeWindowEnd', this.get('timeFrameEnd'));
        this.set('showTimeWindowModal', true);
      },
      closeCustomizeTimeWindowModal: function closeCustomizeTimeWindowModal(shouldUpdate) {
        if (shouldUpdate) {
          this.set('setByCustomWindow', true);
        } else {
          this.set('timeFrameStart', this.get('originalTimeWindowStart'));
          this.set('timeFrameEnd', this.get('originalTimeWindowEnd'));

          if (this.get('originalStartTime')) {
            this.set('selectedStartTime', this.get('originalStartTime'));
          }
        }

        this.set('originalTimeWindowStart', null);
        this.set('originalTimeWindowEnd', null);
        this.set('showTimeWindowModal', false);
      },
      updateSelectedStartTime: function updateSelectedStartTime(time) {
        // The customize time window modal uses timepickers with a different
        // format than the one we're using in this component but when the modal
        // is inserted it somehow overrides this components timepicker's format.
        // This will check to make sure that we are always using the expected
        // format for start times
        var badFormat = 'h:mmA';
        var badMoment = (0, _moment.default)(time, badFormat, true);

        if (badMoment.isValid()) {
          time = badMoment.format('h:mm A');
        }

        this.set('selectedStartTime', time);
      },
      closeOutOfTimeWindowModal: function closeOutOfTimeWindowModal(updateTime) {
        if (updateTime) {
          this.send('openCustomizeTimeWindowModal');

          var _this$pickupWindow = this.pickupWindow(),
              timeFrameStart = _this$pickupWindow.timeFrameStart,
              timeFrameEnd = _this$pickupWindow.timeFrameEnd;

          this.set('timeFrameStart', timeFrameStart);
          this.set('timeFrameEnd', timeFrameEnd);
        } else {
          this.set('selectedStartTime', this.get('originalStartTime'));
        }

        this.set('showOutOfTimeWindowModal', false);
      },
      updateTime: function updateTime() {
        if (this.get('selectedStartTimeInvalid')) {
          this.set('showErrors', true);
        } else {
          var startTimeMoment = (0, _moment.default)(this.get('selectedStartTime'), 'hh:mm a');
          var timeFrameStart = (0, _moment.default)(this.get('timeFrameStart'), 'h:mmA');
          var timeFrameEnd = (0, _moment.default)(this.get('timeFrameEnd'), 'h:mmA');

          if (startTimeMoment.isBefore(timeFrameStart) || startTimeMoment.isSameOrAfter(timeFrameEnd)) {
            this.set('showOutOfTimeWindowModal', true);
          } else {
            this.update(this.get('selectedStartTime'), this.get('selectedPosition'));
          }
        }
      }
    }
  });

  _exports.default = _default;
});