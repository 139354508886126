define("frontend/utils/bill-delivery-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EMAIL = 'email';
  var DO_NOT_SEND = null;
  var _default = {
    EMAIL: EMAIL,
    DO_NOT_SEND: DO_NOT_SEND
  };
  _exports.default = _default;
});