define("frontend/routes/microdeposits", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      return _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/users/get_bank_info?emd5=").concat(params.emd5),
        method: 'GET'
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      if (model.bank_info.status == 'verified') {
        this.transitionTo('verified-bank-account', model.emd5, {
          queryParams: {
            last_four: model.bank_info.last4
          }
        });
      }

      controller.set('emd5', model.emd5);
    },
    actions: {
      back: function back() {
        window.history.back();
      }
    }
  });

  _exports.default = _default;
});