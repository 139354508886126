define("frontend/templates/components/payments-widget/sent-header-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dRC/ndKb",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"selectOption\",\"all\"],null]],null]],[10,\"class\",\"sent-header-dropdown-option\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"All payments\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"selectOption\",\"card\"],null]],null]],[10,\"class\",\"sent-header-dropdown-option\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"Card payments\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"selectOption\",\"bank\"],null]],null]],[10,\"class\",\"sent-header-dropdown-option\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"Bank payments\"],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"selectOption\",\"manual\"],null]],null]],[10,\"class\",\"sent-header-dropdown-option\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"Manual payments\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/payments-widget/sent-header-dropdown.hbs"
    }
  });

  _exports.default = _default;
});