define("frontend/controllers/sign-up/success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      transitionHome: function transitionHome() {
        this.transitionToRoute('index');
      }
    }
  });

  _exports.default = _default;
});