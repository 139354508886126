define("frontend/templates/components/vet-list-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "v0T8WQpW",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",\"vet-option\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"clickedVet\",[24,[\"vet\"]]],null]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"vet-logo\"],[8],[0,\"\\n    \"],[7,\"img\",true],[11,\"src\",[29,[[24,[\"vet\",\"logoOrPlaceholder\"]]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"vet-info\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"vet-name\"],[8],[0,\"\\n      \"],[1,[24,[\"vet\",\"name\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"vet\",\"website\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"vet-website\"],[8],[0,\"\\n        \"],[1,[24,[\"vet\",\"website\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"vet\",\"fullAddress\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"vet-address\"],[8],[0,\"\\n        \"],[1,[24,[\"vet\",\"fullAddress\"]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"vet\",\"phone_number\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"vet-phone-number\"],[8],[0,\"\\n        \"],[1,[28,\"format-phone\",[[24,[\"vet\",\"phone_number\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/vet-list-option.hbs"
    }
  });

  _exports.default = _default;
});