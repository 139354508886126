define("frontend/components/cancel-appointment-modal", ["exports", "frontend/config/environment", "moment", "jquery"], function (_exports, _environment, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      observer = Ember.observer;

  var _default = Ember.Component.extend({
    cancellationNotes: null,
    groupedAppointment: null,
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);

      this.set('cancelingAppointment', false);
      this.set('triedToCancel', false);
      var ga = this.get('groupedAppointment');
      ga.notifyPropertyChange('pending');
      ga.notifyPropertyChange('sortedAppointments');
    },
    appointments: computed('groupedAppointment.sortedAppointments.[]', function () {
      var appointments = this.get('groupedAppointment.sortedAppointments').map(function (appt) {
        return {
          id: appt.get('id'),
          avatarOrPlaceholder: appt.get('pet').get('avatarOrPlaceholder'),
          pet_name: appt.get('pet').get('name'),
          selected: true
        };
      });
      return appointments;
    }),
    cancellationFeeObserver: observer('groupedAppointment', 'groupedAppointment.pending', function () {
      var _this = this;

      if (this.get('groupedAppointment.pending')) {
        return;
      }

      this.set('loadingCancellationFee', true);
      var appointment_ids = this.get('groupedAppointment.sortedAppointments').map(function (appt) {
        return appt.get('id');
      });

      _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/appointments/cancellation_fee"),
        method: 'GET',
        data: {
          appointment_ids: appointment_ids
        }
      }).then(function (response) {
        _this.set('cancellationFeePrice', response.cancellation_fee);

        _this.set('cancellationFeeAdditionalPrice', response.additional_pet_fee);

        _this.set('cancellationPolicyDescription', response.description);

        _this.set('cancellationFeeAmountType', response.amount_type);

        _this.set('cancellationFeePercentage', parseInt(response.percentage));

        _this.set('loadingCancellationFee', false);

        _this.set('cancelingAppointment', false);
      });
    }),
    cancellationFee: computed('cancellationFeePrice', 'cancellationFeeAdditionalPrice', 'appointments.@each.selected', {
      get: function get() {
        var fee = parseFloat(this.get('cancellationFeePrice'));
        var additionalFee = parseFloat(this.get('cancellationFeeAdditionalPrice'));
        var appts = this.get('appointments');
        var selectedAppts = appts.filter(function (appt) {
          return appt.selected;
        });
        var cancellationFee = 0;

        if (fee && fee > 0) {
          if (!isNaN(additionalFee)) {
            if (additionalFee == 0) {
              cancellationFee = fee;
            } else if (selectedAppts.length == appts.length) {
              cancellationFee = fee + (selectedAppts.length - 1) * additionalFee;
            } else {
              cancellationFee = selectedAppts.length * additionalFee;
            }
          } else {
            if (this.get('cancellationFeeAmountType') == 'fixed') {
              cancellationFee = fee;
            } else {
              cancellationFee = selectedAppts.length * fee;
            }
          }
        }

        return cancellationFee.toFixed(2);
      },
      set: function set(key, value) {
        return value;
      }
    }),
    household: computed('groupedAppointment.appointments.[]', function () {
      return this.get('groupedAppointment.appointments.firstObject.pet.household');
    }),
    pickupWindow: computed('groupedAppointment.pickup_window', function () {
      return this.get('groupedAppointment.pickup_window');
    }),
    offeredService: computed('groupedAppointment.offered_service', function () {
      return this.get('groupedAppointment.offered_service');
    }),
    service: computed('groupedAppointment.service', function () {
      return this.get('groupedAppointment.service');
    }),
    month: computed('service.starts_at', function () {
      return (0, _moment.default)(this.get('service.starts_at')).startOf('day').format('MMM');
    }),
    day: computed('service.starts_at', function () {
      return (0, _moment.default)(this.get('service.starts_at')).startOf('day').format('D');
    }),
    dayOfWeek: computed('service.starts_at', function () {
      return (0, _moment.default)(this.get('service.starts_at')).startOf('day').format('ddd');
    }),
    showSelectionError: computed('appointments.@each.selected', function () {
      var selected = this.get('appointments').filter(function (appt) {
        return appt.selected;
      });
      return selected.length == 0;
    }),
    showCancellationAmountError: computed('cancellationFeePrice', 'cancellationFee', 'triedToCancel', function () {
      if (this.get('triedToCancel')) {
        if (this.get('cancellationFeePrice') > 0 && this.get('cancellationFee') <= 0) {
          return true;
        }

        return false;
      }

      return false;
    }),
    actions: {
      cancelAppointment: function cancelAppointment(cancellationFee) {
        this.set('triedToCancel', true);

        if (this.get('showSelectionError') || cancellationFee && this.get('showCancellationAmountError')) {
          return;
        }

        var selectedAppts = this.get('appointments').filter(function (appt) {
          return appt.selected;
        });
        var apptIds = selectedAppts.map(function (appt) {
          return appt.id;
        });
        this.set('cancelingAppointment', true);
        this.set('triedToCancel', false);
        var groupedAppointment = this.get('groupedAppointment');
        this.get('cancelGroupedAppointment')(groupedAppointment, apptIds, cancellationFee, this.get('cancellationNotes'));
      },
      onClose: function onClose() {
        this.get('close')();
      }
    }
  });

  _exports.default = _default;
});