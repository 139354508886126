define("frontend/components/tab-side-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['tab-side-panel'],
    classNameBindings: ['color', 'style', 'active'],
    lighter: false
  });

  _exports.default = _default;
});