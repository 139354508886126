define("frontend/components/avatar-upload", ["exports", "frontend/components/photo-upload"], function (_exports, _photoUpload) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _photoUpload.default.extend({
    classNames: ['avatar-upload'],
    placeholder: 'assets/images/staff/placeholder-human-image@3x.png',
    aspectRatio: 1
  });

  _exports.default = _default;
});