define("frontend/templates/components/calendar-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iPKVb2IO",
    "block": "{\"symbols\":[],\"statements\":[[7,\"a\",false],[12,\"href\",\"#\"],[12,\"class\",[29,[[22,\"todayClass\"]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"selectNewDate\",[24,[\"date\"]]]],[8],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"day\"],[8],[1,[28,\"format-date\",[[24,[\"date\"]],\"ddd\"],null],false],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"day-num\"],[8],[1,[28,\"format-date\",[[24,[\"date\"]],\"D\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/calendar-date.hbs"
    }
  });

  _exports.default = _default;
});