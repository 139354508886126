define("frontend/components/holiday-surcharge-holiday-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    holidayDatesService: Ember.inject.service(),
    showSwitches: true,
    country: null,
    stateOrProvince: null,
    holidaySurchargePolicy: null,
    holidayDates: Ember.computed('country', 'stateOrProvince', function () {
      return this.get('holidayDatesService').getHolidays(this.get('country'), this.get('stateOrProvince'));
    })
  });

  _exports.default = _default;
});