define("frontend/controllers/edit-client", ["exports", "frontend/controllers/add-edit-client"], function (_exports, _addEditClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;
  var ADD_SECONDARY_CONTACT = 'secondary_contact';
  var ADD_PET = 'pet';

  var _default = _addEditClient.default.extend({
    editDropdownActive: false,
    queryParams: ['backRoute', 'backHouseholdId', 'backId'],
    session: inject.service(),
    accountSession: inject.service(),
    media: inject.service(),
    backRoute: null,
    backId: null,
    backHouseholdId: null,
    isPetParent: computed('session.data.authenticated.tokenData', function () {
      return this.get('accountSession').isPetParent();
    }),
    newClientPetOptions: computed('model.secondaryContact', function () {
      var options = [{
        value: ADD_PET,
        label: 'add pet'
      }];

      if (!this.get('model.secondaryContact')) {
        options.pushObject({
          value: ADD_SECONDARY_CONTACT,
          label: 'add secondary contact'
        });
      }

      return options;
    }),
    editRouteName: computed('currentRouteName', function () {
      switch (this.get('currentRouteName')) {
        case 'edit-client.pet':
          return 'pet';

        case 'edit-client.primary-contact':
          return 'primary contact';

        default:
          return 'secondary contact';
      }
    }),
    onProfileRoute: computed('currentRouteName', function () {
      var profileRoutes = ['edit-client.primary-contact', 'edit-client.pet', 'edit-client.secondary-contact'];
      var currentRoute = this.get('currentRouteName');
      return profileRoutes.indexOf(currentRoute) >= 0;
    }),
    isModalRoute: computed('currentRouteName', 'isMobileModalRoute', 'media.isMobile', function () {
      var universalModalRoutes = ['edit-client.remove-pet', 'edit-client.remove-household', 'edit-client.remove-household-user'];
      var currentRoute = this.get('currentRouteName');
      var isModalRoute = universalModalRoutes.indexOf(currentRoute) >= 0;
      var isMobileModalRoute = this.get('isMobileModalRoute');
      return isModalRoute || isMobileModalRoute;
    }),
    isMobileModalRoute: computed('currentRouteName', 'media.isMobile', function () {
      var modalRoutes = ['edit-client.add-secondary-contact', 'edit-client.add-pet'];
      var currentRoute = this.get('currentRouteName');
      var isMobile = this.get('media.isMobile');
      return modalRoutes.indexOf(currentRoute) >= 0 && isMobile;
    }),
    hasClose: computed('media.isMobile', 'currentRouteName', 'saving', function () {
      var isNotMobile = !this.get('media.isMobile');
      var notSaving = !this.get('saving');
      var closeRoutes = ['edit-client.remove-pet', 'edit-client.remove-household'];
      var currentRoute = this.get('currentRouteName');
      var isCloseRoute = closeRoutes.indexOf(currentRoute) >= 0;
      return (isNotMobile || isCloseRoute) && notSaving;
    }),
    actions: {
      transitionToPrimaryContact: function transitionToPrimaryContact() {
        if (!this.get('onProfileRoute')) {
          this.transitionToRoute('edit-client.primary-contact');
        }
      },
      selectOption: function selectOption(value) {
        this.transitionToRoute(value);
      },
      addNewClientOrPet: function addNewClientOrPet(clientOrPet) {
        switch (clientOrPet) {
          case ADD_PET:
            this.transitionToRoute('edit-client.add-pet');
            break;

          case ADD_SECONDARY_CONTACT:
            this.transitionToRoute('edit-client.add-secondary-contact');
            break;
        }
      }
    }
  });

  _exports.default = _default;
});