define("frontend/templates/components/staff-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D7rjAvuZ",
    "block": "{\"symbols\":[\"staff\"],\"statements\":[[4,\"each\",[[24,[\"filteredStaff\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"staff-list-item\",null,[[\"staff\",\"active\",\"clickStaff\"],[[23,1,[]],[28,\"eq\",[[24,[\"selectedStaff\"]],[23,1,[\"id\"]]],null],[28,\"action\",[[23,0,[]],\"clickStaff\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"staff-list-not-found\"],[8],[0,\"\\n\"],[4,\"doo-error\",null,null,{\"statements\":[[0,\"      \"],[7,\"p\",true],[8],[0,\"\\n        Sorry we couldn't find who you're looking for!\"],[7,\"br\",true],[8],[9],[0,\"\\n        \"],[7,\"span\",false],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"addEmployee\"]],[8],[0,\"Add a new staff member\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/staff-list.hbs"
    }
  });

  _exports.default = _default;
});