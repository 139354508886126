define("frontend/controllers/staff/index", ["exports", "ember-data", "frontend/config/environment", "ember-cp-validations", "jquery"], function (_exports, _emberData, _environment, _emberCpValidations, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'switchEmailAddress': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })]
  });

  var _default = Ember.Controller.extend(Validations, {
    queryParams: ['searchFilter', 'employeeFilter'],
    serachFilter: '',
    employeeFilter: 'all',
    startPay: null,
    endPay: null,
    compensationExportDisabled: false,
    flashMessage: inject.service(),
    staff: inject.controller(),
    showingStaffList: computed.alias('staff.showingStaffList'),
    headerText: computed.alias('staff.headerText'),
    hasSelectedEmployee: computed.alias('staff.hasSelectedEmployee'),
    apptComps: computed.mapBy('appointmentCompensations', 'compensation'),
    maxCompensation: computed.max('apptComps'),
    tipComps: computed.mapBy('tipCompensations', 'tip'),
    maxTip: computed.max('tipComps'),
    holidaySurchargeFeeComps: computed.mapBy('surchargeFeeCompensations', 'holiday_compensation'),
    maxHolidaySurchargeFeeComp: computed.max('holidaySurchargeFeeComps'),
    weekendSurchargeFeeComps: computed.mapBy('surchargeFeeCompensations', 'weekend_compensation'),
    maxWeekendSurchargeFeeComp: computed.max('weekendSurchargeFeeComps'),
    offHoursSurchargeFeeComps: computed.mapBy('surchargeFeeCompensations', 'off_hours_compensation'),
    maxOffHoursSurchargeFeeComp: computed.max('offHoursSurchargeFeeComps'),
    lateBookingSurchargeFeeComps: computed.mapBy('surchargeFeeCompensations', 'late_booking_compensation'),
    maxLateBookingSurchargeFeeComp: computed.max('lateBookingSurchargeFeeComps'),
    cancellationSurchargeFeeComps: computed.mapBy('surchargeFeeCompensations', 'cancellation_compensation'),
    maxCancellationSurchargeFeeComp: computed.max('cancellationSurchargeFeeComps'),
    totalCompensations: computed.mapBy('compensations', 'totalCompensation'),
    maxTotalCompensation: computed.max('totalCompensations'),
    apptsCompleted: computed.mapBy('appointmentCompensations', 'num_visits'),
    maxApptsCompleted: computed.max('apptsCompleted'),
    // users: computed.mapBy('model', 'user'),
    // TODO: investigate, with new versions of ember data do we need this
    savedUsers: computed.filterBy('users', 'isNew', false),
    activeEmployees: computed.filterBy('model', 'deleted_at', null),
    numActiveStaff: computed.readOnly('savedUsers.length'),
    currentUser: inject.service(),
    company: computed.readOnly('currentUser.user.company'),
    users: computed('activeEmployees', 'activeEmployees.@each.deactivated_at', function () {
      return this.get('activeEmployees').map(function (employee) {
        var user = employee.get('user');
        user.set('deactivated', employee.get('deactivated'));
        return user;
      });
    }),
    selectedEmployee: computed('employeeFilter', 'model.[]', function () {
      var _this = this;

      var employeeFilter = this.get('employeeFilter');
      var employees = this.get('model');

      if (employeeFilter && employees) {
        this.set('resendDisabled', false);
        var employee = employees.filter(function (employee) {
          return employee.get('user.id') === employeeFilter;
        }).get('firstObject');

        if (employee) {
          Ember.run.next(function () {
            if (!_this.isDestroyed) {
              _this.set('hasSelectedEmployee', true);

              var text = _this.get('currentUser.user.isOwner') ? employee.get('user.fullName') : 'Payroll';

              _this.set('headerText', text);
            }
          });
        }

        return employee;
      }

      return null;
    }),
    allAppointmentsCompleted: computed('appointmentCompensations.[]', function () {
      var ap = this.get('appointmentCompensations');
      return ap.reduce(function (a, b) {
        return a + b.get('num_completed') - b.get('num_additional_dogs');
      }, 0);
    }),
    allStaffCompensation: computed('allStaffApptCompensation', 'maxTip', 'maxHolidaySurchargeFeeComp', 'maxWeekendSurchargeFeeComp', 'maxOffHoursSurchargeFeeComp', 'maxLateBookingSurchargeFeeComp', 'maxCancellationSurchargeFeeComp', function () {
      var _this2 = this;

      var totalCompensation = this.get('allStaffApptCompensation');

      if (this.get('maxTip') && this.get('maxTip') > 0) {
        totalCompensation += this.get('maxTip');
      }

      ['maxHolidaySurchargeFeeComp', 'maxWeekendSurchargeFeeComp', 'maxOffHoursSurchargeFeeComp', 'maxLateBookingSurchargeFeeComp', 'maxCancellationSurchargeFeeComp'].forEach(function (feeLabel) {
        if (_this2.get(feeLabel) && _this2.get(feeLabel) > 0) {
          totalCompensation += _this2.get(feeLabel);
        }
      });
      return totalCompensation;
    }),
    allStaffApptCompensation: computed('appointmentCompensations.[]', 'selectedEmployee.compensation_type', function () {
      var ap = this.get('appointmentCompensations');
      var totalCompensation = ap.reduce(function (a, b) {
        return a + b.get('compensation') + b.get('additional_dog_compensation');
      }, 0);

      if (this.get('selectedEmployee') !== 'all') {
        // Set the min amount to pay on staff profile pages
        if (this.get('selectedEmployee.min_amount_to_pay') > totalCompensation) {
          totalCompensation = this.get('selectedEmployee.min_amount_to_pay');
        } // Hack for salary compensation types


        if (this.get('selectedEmployee.compensation_type') === 'salary') {
          totalCompensation = ap.get('firstObject.compensation');
        }
      }

      return totalCompensation;
    }),
    maxSelectedStaffComp: computed('maxTip', 'maxCompensation', 'maxHolidaySurchargeFeeComp', 'maxWeekendSurchargeFeeComp', 'maxOffHoursSurchargeFeeComp', 'maxLateBookingSurchargeFeeComp', 'maxCancellationSurchargeFeeComp', function () {
      var _this3 = this;

      var max = this.get('maxCompensation');
      ['maxTip', 'maxHolidaySurchargeFeeComp', 'maxWeekendSurchargeFeeComp', 'maxOffHoursSurchargeFeeComp', 'maxLateBookingSurchargeFeeComp', 'maxCancellationSurchargeFeeComp'].forEach(function (compLabel) {
        if (_this3.get(compLabel) > max) {
          max = _this3.get(compLabel);
        }
      });
      return max;
    }),
    // TODO Move this to route`
    appointmentCompensations: computed('employeeFilter', 'startPay', 'endPay', function () {
      var start_pay = this.get('startPay');
      var end_pay = this.get('endPay');
      var user_id = this.get('employeeFilter');
      var promise;

      if (!start_pay || !end_pay) {
        return [];
      }

      if (user_id === 'all') {
        promise = this.store.query('appointment-compensation', {
          start_pay: start_pay,
          end_pay: end_pay,
          api_version: 2
        }).then(function (aptCompensations) {
          // Set the min amount to pay on the staff index page
          aptCompensations.forEach(function (aptCompensation) {
            var minPay = aptCompensation.get('user.min_amount_to_pay');

            if (minPay && minPay > aptCompensation.get('compensation') + 0.0) {
              aptCompensation.set('compensation', parseFloat(minPay));
            }
          });
          return aptCompensations;
        });
      } else {
        promise = this.store.query('appointment-compensation', {
          start_pay: start_pay,
          end_pay: end_pay,
          user_id: user_id,
          api_version: 2
        });
      }

      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    surchargeFeeCompensations: computed('employeeFilter', 'startPay', 'endPay', function () {
      var start_pay = this.get('startPay');
      var end_pay = this.get('endPay');
      var user_id = this.get('employeeFilter');

      if (!start_pay || !end_pay) {
        return 0;
      }

      var promise = this.store.query('surcharge-fee-compensation', {
        start_pay: start_pay,
        end_pay: end_pay,
        user_id: user_id
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    allStaffSurchargeFeeCompensation: computed('surchargeFeeCompensations.[]', function () {
      return this.get('surchargeFeeCompensations').reduce(function (a, b) {
        return a + b.get('totalCompensation');
      }, 0);
    }),
    allStaffTips: computed('tipCompensations.[]', function () {
      return this.get('tipCompensations').reduce(function (a, b) {
        return a + b.get('tip');
      }, 0);
    }),
    tipCompensations: computed('employeeFilter', 'startPay', 'endPay', function () {
      var start_pay = this.get('startPay');
      var end_pay = this.get('endPay');
      var user_id = this.get('employeeFilter');

      if (!start_pay || !end_pay) {
        return 0;
      }

      var promise = this.store.query('tip-compensation', {
        start_pay: start_pay,
        end_pay: end_pay,
        user_id: user_id
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    unallocatedTips: computed('employeeFilter', function () {
      var _this4 = this;

      return _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/tips/unallocated"),
        method: 'GET'
      }).done(function (resp) {
        _this4.set('unallocatedTips', resp.amount);
      });
    }),
    showUnallocatedTips: Ember.computed('unallocatedTips', 'startPay', 'endPay', function () {
      var startDate = this.get('startPay');
      var endDate = this.get('endPay');
      var unallocated = this.get('unallocatedTips');

      if (startDate && endDate && unallocated) {
        var now = moment();

        if (moment(startDate, 'YYYY-MM-DD') < now && moment(endDate, 'YYYY-MM-DD') > now) {
          if (unallocated > 0) {
            return true;
          }
        }
      }

      return false;
    }),
    showNoAppointments: computed('appointmentCompensations.[]', 'tipCompensations.[]', 'surchargeFeeCompensations.[]', function () {
      return this.get('appointmentCompensations.length') == 0 && this.get('tipCompensations.length') == 0 && this.get('surchargeFeeCompensations.length') == 0;
    }),
    compensations: computed('appointmentCompensations.[]', 'tipCompensations.[]', 'surchargeFeeCompensations.[]', function () {
      var compensations = {};
      this.get('appointmentCompensations').forEach(function (apptComp) {
        var employeeId = apptComp.get('employee.id');

        if (compensations[employeeId]) {
          compensations[employeeId].apptComp = apptComp;
          compensations[employeeId].totalCompensation += apptComp.get('compensation');
          compensations[employeeId].totalApptComp += apptComp.get('compensation');
        } else {
          compensations[employeeId] = {
            employee: apptComp.get('employee'),
            fullName: apptComp.get('employee_name'),
            totalCompensation: apptComp.get('compensation'),
            totalApptComp: apptComp.get('compensation'),
            apptComp: apptComp
          };
        }
      });
      this.get('tipCompensations').forEach(function (tipComp) {
        var employeeId = tipComp.get('employee.id');

        if (compensations[employeeId]) {
          compensations[employeeId].tipComp = tipComp;
          compensations[employeeId].totalCompensation += tipComp.get('tip');
        } else {
          compensations[employeeId] = {
            employee: tipComp.get('employee'),
            fullName: tipComp.get('employee_name'),
            totalCompensation: tipComp.get('tip'),
            tipComp: tipComp
          };
        }
      });
      this.get('surchargeFeeCompensations').forEach(function (comp) {
        var employeeId = comp.get('employee.id');

        if (compensations[employeeId]) {
          compensations[employeeId].surchargeFeeComp = comp;
          compensations[employeeId].totalCompensation += comp.get('totalCompensation');
          compensations[employeeId].totalApptComp += comp.get('totalCompensation');
        } else {
          compensations[employeeId] = {
            employee: comp.get('employee'),
            fullName: comp.get('employee_name'),
            totalCompensation: comp.get('totalCompensation'),
            totalApptComp: comp.get('totalCompensation'),
            surchargeFeeComp: comp
          };
        }
      });
      return $.map(compensations, function (v) {
        return v;
      }).sort(this.sortCompensations);
    }),
    sortCompensations: function sortCompensations(a, b) {
      var fullNameA = a.fullName.toLowerCase();
      var fullNameB = b.fullName.toLowerCase();

      if (fullNameA < fullNameB) {
        return -1;
      } else if (fullNameA > fullNameB) {
        return 1;
      } else {
        return 0;
      }
    },
    actions: {
      addEmployee: function addEmployee() {
        this.transitionToRoute('staff-search');
      },
      clickStaff: function clickStaff(staff) {
        this.set('employeeFilter', staff.get('id'));
        this.set('showingStaffList', false);
        this.set('compensationExportDisabled', false);
      },
      staffListLoaded: function staffListLoaded(allStaff) {
        this.set('allStaff', allStaff);
      },
      editStaff: function editStaff(staff) {
        this.transitionToRoute('edit-staff', staff);
      },
      transitionToRemoveStaff: function transitionToRemoveStaff(staff) {
        this.transitionToRoute('companies.remove-staff', this.get('currentUser.user.company.id'), staff.id);
      },
      goToSchedulePage: function goToSchedulePage(care_provider_id) {
        this.transitionToRoute('schedule', {
          queryParams: {
            care_provider_id: care_provider_id
          }
        });
      },
      changePayPeriod: function changePayPeriod(startDate, endDate) {
        this.set('startPay', startDate);
        this.set('endPay', endDate);
        this.set('compensationExportDisabled', false);
      },
      requestHousehold: function requestHousehold(email) {
        var _this5 = this;

        this.set('resendDisabled', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/employees/add_company"),
          method: 'POST',
          data: {
            email: email
          }
        }).done(function () {
          Ember.getOwner(_this5).lookup('controller:application').send('notify', {
            message: "Your request to connect was sent to ".concat(email),
            type: 'success'
          });
        });
      },
      clickReactivate: function clickReactivate() {
        this.set('showReactivateFlash', true);
      },
      hideReactivate: function hideReactivate() {
        this.set('showReactivateFlash', false);
      },
      confirmReactivate: function confirmReactivate() {
        var _this6 = this;

        this.set('showReactivateFlash', false);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/employees/").concat(this.get('selectedEmployee.id'), "/reactivate"),
          method: 'POST'
        }).done(function () {
          _this6.get('flashMessage').sendFlashMessage("You\u2019ve successfully reactivated ".concat(_this6.get('selectedEmployee.user.getFirstName'), "'s account"), 'success-love');

          _this6.set('selectedEmployee.deactivated_at', null);
        });
      },
      exportData: function exportData() {
        var _this7 = this;

        var employeeId = this.get('selectedEmployee.id') || 'all';
        this.set('compensationExportDisabled', true);

        _jquery.default.ajax({
          url: "/api/v2/employee_compensation?employee_id=".concat(employeeId, "&start=").concat(this.get('startPay'), "&end=").concat(this.get('endPay')),
          method: 'GET'
        }).done(function (response) {
          Ember.getOwner(_this7).lookup('controller:application').send('notify', {
            message: "This may take a few minutes. Your data export will be sent to ".concat(response.email),
            type: 'success'
          });
        });
      },
      switchEmail: function switchEmail() {
        var _this8 = this;

        if (this.get('validations.isValid')) {
          this.set('emailError', false);
          var email = this.get('switchEmailAddress');
          var employeeId = this.get('selectedEmployee.id');

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/employees/").concat(employeeId, "/switch_email"),
            method: 'POST',
            data: {
              email: email,
              id: employeeId
            }
          }).done(function (response) {
            if (response.already_staff) {
              _this8.set('staffDuplicate', true);
            } else {
              _this8.send('refresh');

              _this8.set('switchEmailAddress', null);

              _this8.set('emailError', false);

              _this8.set('staffDuplicate', false);

              Ember.getOwner(_this8).lookup('controller:application').send('notify', {
                message: "Your request to connect was sent to ".concat(email),
                type: 'success'
              });

              _this8.transitionToRoute('staff', {
                queryParams: {
                  employeeFilter: response.staff_id
                }
              }).then(function () {
                window.location.reload();
              });
            }
          });
        } else {
          this.set('emailError', true);
        }
      }
    }
  });

  _exports.default = _default;
});