define("frontend/services/check-started-appointment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    hasStartedAppointment: function hasStartedAppointment(groupedAppointment) {
      var hasStarted = false;
      groupedAppointment.get('appointments').forEach(function (appt) {
        if (appt.get('started_at') && !appt.get('finished_at')) {
          hasStarted = true;
        }
      });
      return hasStarted;
    }
  });

  _exports.default = _default;
});