define("frontend/services/appointment-durations-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    durations: [{
      label: '0 minutes',
      value: 0
    }, {
      label: '5 minutes',
      value: 5
    }, {
      label: '10 minutes',
      value: 10
    }, {
      label: '15 minutes',
      value: 15
    }, {
      label: '20 minutes',
      value: 20
    }, {
      label: '25 minutes',
      value: 25
    }, {
      label: '30 minutes',
      value: 30
    }, {
      label: '35 minutes',
      value: 35
    }, {
      label: '40 minutes',
      value: 40
    }, {
      label: '45 minutes',
      value: 45
    }, {
      label: '50 minutes',
      value: 50
    }, {
      label: '55 minutes',
      value: 55
    }, {
      label: '1 hour',
      value: 60
    }, {
      label: '1 hour 5 minutes',
      value: 65
    }, {
      label: '1 hour 10 minutes',
      value: 70
    }, {
      label: '1 hour 15 minutes',
      value: 75
    }, {
      label: '1 hour 20 minutes',
      value: 80
    }, {
      label: '1 hour 25 minutes',
      value: 85
    }, {
      label: '1 hour 30 minutes',
      value: 90
    }, {
      label: '1 hour 35 minutes',
      value: 95
    }, {
      label: '1 hour 40 minutes',
      value: 100
    }, {
      label: '1 hour 45 minutes',
      value: 105
    }, {
      label: '1 hour 50 minutes',
      value: 110
    }, {
      label: '1 hour 55 minutes',
      value: 115
    }, {
      label: '2 hours',
      value: 120
    }, {
      label: '2 hours 5 minutes',
      value: 125
    }, {
      label: '2 hours 10 minutes',
      value: 130
    }, {
      label: '2 hours 15 minutes',
      value: 135
    }, {
      label: '2 hours 20 minutes',
      value: 140
    }, {
      label: '2 hours 25 minutes',
      value: 145
    }, {
      label: '2 hours 30 minutes',
      value: 150
    }, {
      label: '2 hours 35 minutes',
      value: 155
    }, {
      label: '2 hours 40 minutes',
      value: 160
    }, {
      label: '2 hours 45 minutes',
      value: 165
    }, {
      label: '2 hours 50 minutes',
      value: 170
    }, {
      label: '2 hours 55 minutes',
      value: 175
    }, {
      label: '3 hours',
      value: 180
    }, {
      label: '3 hours 5 minutes',
      value: 185
    }, {
      label: '3 hours 10 minutes',
      value: 190
    }, {
      label: '3 hours 15 minutes',
      value: 195
    }, {
      label: '3 hours 20 minutes',
      value: 200
    }, {
      label: '3 hours 25 minutes',
      value: 205
    }, {
      label: '3 hours 30 minutes',
      value: 210
    }, {
      label: '3 hours 35 minutes',
      value: 215
    }, {
      label: '3 hours 40 minutes',
      value: 220
    }, {
      label: '3 hours 45 minutes',
      value: 225
    }, {
      label: '3 hours 50 minutes',
      value: 230
    }, {
      label: '3 hours 55 minutes',
      value: 235
    }, {
      label: '4 hours',
      value: 240
    }, {
      label: '4 hours 5 minutes',
      value: 245
    }, {
      label: '4 hours 10 minutes',
      value: 250
    }, {
      label: '4 hours 15 minutes',
      value: 255
    }, {
      label: '4 hours 20 minutes',
      value: 260
    }, {
      label: '4 hours 25 minutes',
      value: 265
    }, {
      label: '4 hours 30 minutes',
      value: 270
    }, {
      label: '4 hours 35 minutes',
      value: 275
    }, {
      label: '4 hours 40 minutes',
      value: 280
    }, {
      label: '4 hours 45 minutes',
      value: 285
    }, {
      label: '4 hours 50 minutes',
      value: 290
    }, {
      label: '4 hours 55 minutes',
      value: 295
    }, {
      label: '5 hours',
      value: 300
    }, {
      label: '5 hours 5 minutes',
      value: 305
    }, {
      label: '5 hours 10 minutes',
      value: 310
    }, {
      label: '5 hours 15 minutes',
      value: 315
    }, {
      label: '5 hours 20 minutes',
      value: 320
    }, {
      label: '5 hours 25 minutes',
      value: 325
    }, {
      label: '5 hours 30 minutes',
      value: 330
    }, {
      label: '5 hours 35 minutes',
      value: 335
    }, {
      label: '5 hours 40 minutes',
      value: 340
    }, {
      label: '5 hours 45 minutes',
      value: 345
    }, {
      label: '5 hours 50 minutes',
      value: 350
    }, {
      label: '5 hours 55 minutes',
      value: 355
    }, {
      label: '6 hours',
      value: 360
    }, {
      label: '6 hours 5 minutes',
      value: 365
    }, {
      label: '6 hours 10 minutes',
      value: 370
    }, {
      label: '6 hours 15 minutes',
      value: 375
    }, {
      label: '6 hours 20 minutes',
      value: 380
    }, {
      label: '6 hours 25 minutes',
      value: 385
    }, {
      label: '6 hours 30 minutes',
      value: 390
    }, {
      label: '6 hours 35 minutes',
      value: 395
    }, {
      label: '6 hours 40 minutes',
      value: 400
    }, {
      label: '6 hours 45 minutes',
      value: 405
    }, {
      label: '6 hours 50 minutes',
      value: 410
    }, {
      label: '6 hours 55 minutes',
      value: 415
    }, {
      label: '7 hours',
      value: 420
    }, {
      label: '7 hours 5 minutes',
      value: 425
    }, {
      label: '7 hours 10 minutes',
      value: 430
    }, {
      label: '7 hours 15 minutes',
      value: 435
    }, {
      label: '7 hours 20 minutes',
      value: 440
    }, {
      label: '7 hours 25 minutes',
      value: 445
    }, {
      label: '7 hours 30 minutes',
      value: 450
    }, {
      label: '7 hours 35 minutes',
      value: 455
    }, {
      label: '7 hours 40 minutes',
      value: 460
    }, {
      label: '7 hours 45 minutes',
      value: 465
    }, {
      label: '7 hours 50 minutes',
      value: 470
    }, {
      label: '7 hours 55 minutes',
      value: 475
    }, {
      label: '8 hours',
      value: 480
    }, {
      label: '8 hours 5 minutes',
      value: 485
    }, {
      label: '8 hours 10 minutes',
      value: 490
    }, {
      label: '8 hours 15 minutes',
      value: 495
    }, {
      label: '8 hours 20 minutes',
      value: 500
    }, {
      label: '8 hours 25 minutes',
      value: 505
    }, {
      label: '8 hours 30 minutes',
      value: 510
    }, {
      label: '8 hours 35 minutes',
      value: 515
    }, {
      label: '8 hours 40 minutes',
      value: 520
    }, {
      label: '8 hours 45 minutes',
      value: 525
    }, {
      label: '8 hours 50 minutes',
      value: 530
    }, {
      label: '8 hours 55 minutes',
      value: 535
    }, {
      label: '9 hours',
      value: 540
    }, {
      label: '9 hours 5 minutes',
      value: 545
    }, {
      label: '9 hours 10 minutes',
      value: 550
    }, {
      label: '9 hours 15 minutes',
      value: 555
    }, {
      label: '9 hours 20 minutes',
      value: 560
    }, {
      label: '9 hours 25 minutes',
      value: 565
    }, {
      label: '9 hours 30 minutes',
      value: 570
    }, {
      label: '9 hours 35 minutes',
      value: 575
    }, {
      label: '9 hours 40 minutes',
      value: 580
    }, {
      label: '9 hours 45 minutes',
      value: 585
    }, {
      label: '9 hours 50 minutes',
      value: 590
    }, {
      label: '9 hours 55 minutes',
      value: 595
    }, {
      label: '10 hours',
      value: 600
    }, {
      label: '10 hours 5 minutes',
      value: 605
    }, {
      label: '10 hours 10 minutes',
      value: 610
    }, {
      label: '10 hours 15 minutes',
      value: 615
    }, {
      label: '10 hours 20 minutes',
      value: 620
    }, {
      label: '10 hours 25 minutes',
      value: 625
    }, {
      label: '10 hours 30 minutes',
      value: 630
    }, {
      label: '10 hours 35 minutes',
      value: 635
    }, {
      label: '10 hours 40 minutes',
      value: 640
    }, {
      label: '10 hours 45 minutes',
      value: 645
    }, {
      label: '10 hours 50 minutes',
      value: 650
    }, {
      label: '10 hours 55 minutes',
      value: 655
    }, {
      label: '11 hours',
      value: 660
    }, {
      label: '11 hours 5 minutes',
      value: 665
    }, {
      label: '11 hours 10 minutes',
      value: 670
    }, {
      label: '11 hours 15 minutes',
      value: 675
    }, {
      label: '11 hours 20 minutes',
      value: 680
    }, {
      label: '11 hours 25 minutes',
      value: 685
    }, {
      label: '11 hours 30 minutes',
      value: 690
    }, {
      label: '11 hours 35 minutes',
      value: 695
    }, {
      label: '11 hours 40 minutes',
      value: 700
    }, {
      label: '11 hours 45 minutes',
      value: 705
    }, {
      label: '11 hours 50 minutes',
      value: 710
    }, {
      label: '11 hours 55 minutes',
      value: 715
    }, {
      label: '12 hours',
      value: 720
    }]
  });

  _exports.default = _default;
});