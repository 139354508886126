define("frontend/routes/portal/messages/company", ["exports", "frontend/routes/base-route", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = _baseRoute.default.extend({
    infinity: inject.service(),
    currentUser: inject.service(),
    model: function model(_ref) {
      var company_id = _ref.company_id;
      var company = this.store.findRecord('company', company_id);

      var settings = _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/users/get_messaging_companies"),
        method: 'GET'
      });

      var household = this.get('currentUser.user.household');
      var messages = this.get('infinity').model('message', {
        household_id: household.get('id'),
        company_id: company_id,
        perPage: 20,
        startingPage: 1
      });
      return Ember.RSVP.hash({
        company: company,
        settings: settings,
        messages: messages
      });
    },
    actions: {
      goToAppointmentRequest: function goToAppointmentRequest(requestId, companyId) {
        var queryParams = {
          cid: companyId,
          ids: null,
          requestId: requestId,
          redirectedFromMessage: true
        };
        this.transitionTo('portal.appointment-request.summary', {
          queryParams: queryParams
        });
      }
    }
  });

  _exports.default = _default;
});