define("frontend/templates/components/appointment-all-day-service-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YKo+JedU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,[[\"onClose\",\"customContainerClassNames\"],[[28,\"action\",[[23,0,[]],\"close\"],null],\"appointment-too-soon-modal\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"close-button\"],[8],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"onclick\"],[[28,\"action\",[[23,0,[]],\"close\"],null]]],{\"statements\":[[0,\"        \"],[1,[28,\"inline-svg\",[\"assets/images/cancel-mobile-gray.svg\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"class\",\"smiling-face-emoji\"],[10,\"src\",\"assets/images/grinning-face-with-sweat@3x.png\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"header\",true],[8],[0,\"\\n        Sorry!\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"layout-row subheader\"],[8],[0,\"\\n      At the moment, this type of appointment can only be modified by your care provider. Please message \"],[1,[22,\"companyName\"],false],[0,\" directly to request changes to your appointment.\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"buttons-container\"],[8],[0,\"\\n    \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red\",\"go to messenger\",[28,\"action\",[[23,0,[]],\"goToMessenger\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/appointment-all-day-service-modal.hbs"
    }
  });

  _exports.default = _default;
});