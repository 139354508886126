define("frontend/templates/components/auto-bill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GJVhpmnS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"input-callout\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"input-label visible\"],[8],[0,\"\\n    Automatically bill\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"auto-bill/invoice-frequencies\",null,[[\"companyInvoiceFrequencies\",\"biweeklyStart\",\"biweeklyEnd\",\"defaultInvoiceFrequency\",\"click\"],[[24,[\"company\",\"invoice_frequencies\"]],[24,[\"company\",\"biweeklyStart\"]],[24,[\"company\",\"biweeklyEnd\"]],[24,[\"company\",\"default_invoice_frequency\"]],[28,\"action\",[[23,0,[]],\"openSidePanel\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"editingAutoBill\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"auto-bill/auto-bill-sidepanel\",null,[[\"defaultInvoiceFrequency\",\"biweeklyBillingPeriod\",\"invoiceFrequencies\",\"companyInvoiceFrequencies\",\"showAutobillDropdown\",\"close\"],[[24,[\"company\",\"default_invoice_frequency\"]],[24,[\"company\",\"billing_period\"]],[24,[\"invoiceFrequencies\"]],[24,[\"company\",\"invoice_frequencies\"]],[24,[\"showAutobillDropdown\"]],[28,\"action\",[[23,0,[]],\"closeSidePanel\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/auto-bill.hbs"
    }
  });

  _exports.default = _default;
});