define("frontend/components/view-grouped-report-card/care-provider-details", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    /**
     * The appointment associated to the report card used to determine the care provider and appointment date.
     */
    appointments: [],
    firstAppointment: computed('appointments.[]', function () {
      return this.get('appointments.firstObject');
    }),
    formattedDate: computed('firstAppointment.service.starts_at', function () {
      return _moment.default.parseZone(this.get('firstAppointment.service.starts_at')).format('ddd, MMM D, YYYY');
    }),
    classNames: ['view-report-card--care-provider-details']
  });

  _exports.default = _default;
});