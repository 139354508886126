define("frontend/components/add-client/pet-inputs", ["exports", "frontend/utils/scroll-util", "frontend/models/pet", "frontend/mixins/autocomplete/highlight-first-match", "ember-data", "frontend/utils/email-type"], function (_exports, _scrollUtil, _pet, _highlightFirstMatch, _emberData, _emailType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var inject = Ember.inject,
      computed = Ember.computed;
  var HEADER_HEIGHT = 84;

  var _default = Ember.Component.extend(_highlightFirstMatch.default, {
    router: inject.service(),
    classNames: ['inputs-container'],
    ajax: inject.service(),
    session: inject.service(),
    accountSession: inject.service(),
    pet: null,
    vetFilter: null,
    genderOptions: ['male', 'female'],
    currentSection: 'basic',
    onBasic: computed.equal('currentSection', 'basic'),
    onMedical: computed.equal('currentSection', 'medical'),
    onBehavioral: computed.equal('currentSection', 'behavioral'),
    onNotes: computed.equal('currentSection', 'notes'),
    isDog: computed.equal('pet.pet_type', 'Dog'),
    isCat: computed.equal('pet.pet_type', 'Cat'),
    isHorse: computed.equal('pet.pet_type', 'Horse'),
    dogOrCat: computed.or('isDog', 'isCat'),
    showBreed: computed.or('dogOrCat', 'isHorse'),
    hasEmergencyContacts: computed.gt('pet.emergency_contacts.length', 0),
    hasPrescriptions: computed.gt('pet.pet_prescriptions.length', 0),
    pronoun: computed('pet.gender', function () {
      if (this.get('pet.gender') == 'male') {
        return 'he';
      }

      return 'she';
    }),
    clarificationNotes: computed('pet.name', function () {
      var pronoun = this.get('pronoun');
      var name = this.get('pet.name');
      return {
        gets_along_with_extra_notes: "If there is any type of human or animal that ".concat(name, " doesn\u2019t get along with, let us know!"),
        phobias: "Is ".concat(name, " afraid of loud noises? Or spiders? Does ").concat(pronoun, " have separation anxiety or is ").concat(pronoun, " overly protective?"),
        potty_habits: "Does ".concat(name, " only poop downhill? Will ").concat(pronoun, " not pee if it\u2019s raining out?"),
        diet: "What do you feed ".concat(name, "? How much and when? Is ").concat(pronoun, " allergic to chicken?"),
        house_rules: "Does ".concat(name, " need to be crated? Is ").concat(pronoun, " allowed on furniture?"),
        off_leash_notes: "How does ".concat(name, " behave off leash? How is their recall?"),
        commands: "Sit. Down. Play dead! What does ".concat(name, " know and what is ").concat(pronoun, " learning?"),
        translations: "Does ".concat(name, " paw at the door when ").concat(pronoun, " needs to go potty? What else can ").concat(pronoun, " communicate?"),
        behavioral_extra_notes: "What else should we know about ".concat(name, "?"),
        client_notes: 'If you have instructions for your walkers that generally apply for all appointments, add them here!',
        internal_notes: 'This will be visible only to you and your staff!'
      };
    }),
    getsAlongWithOptions: ['Yes', 'Sometimes', 'No', 'Not Sure'],
    statusOptions: computed('pet.gender', function () {
      if (!this.get('pet.gender')) {
        return [];
      } else {
        return _pet.STATUS[this.get('pet.gender')];
      }
    }),
    isPetParent: computed('session.data.authenticated.tokenData', function () {
      return this.get('accountSession').isPetParent();
    }),
    store: inject.service(),
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var url = '/api/v2/pet_types';
      this.get('ajax').request(url).then(function (response) {
        _this.set('petTypeOptions', response);
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.handleQueryParam();
    },
    handleQueryParam: function handleQueryParam() {
      var currentURL = this.get('router.currentURL');
      var queryParams = new URLSearchParams(currentURL.split('?')[1]);
      var editSection = queryParams.get('editSection');

      if (editSection) {
        this.send('scrollTo', "#".concat(editSection));
      }
    },
    selectedStatus: computed('pet.spayed_or_neutered', 'statusOptions.[]', function () {
      return this.get('statusOptions').filterBy('value', this.get('pet.spayed_or_neutered'))[0];
    }),
    breedOptions: computed('pet.pet_type_id', function () {
      var pet_type = this.get('pet.pet_type_id');

      if (pet_type == 0) {
        pet_type = 1;
      }

      return this.get('store').query('breed', {
        pet_type: pet_type
      });
    }),
    fakeVetOptions: [{
      name: 'Loading'
    }],
    vetOptions: computed('vetFilter', function () {
      var _this2 = this;

      var filter = this.get('vetFilter');
      var promise = this.get('store').query('veterinarian-clinic', {
        filter: filter
      }, {
        reload: true
      }).then(function (clinics) {
        var mappedClinics = clinics.map(function (vet) {
          return {
            vet: vet
          };
        });

        if (_this2.get('vetFilter')) {
          mappedClinics.pushObject({
            name: 'Create a new vet?'
          });
        } else {
          mappedClinics.pushObject({
            name: 'Can’t find your vet? Try searching'
          });
        }

        return mappedClinics;
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    insuranceOptions: computed(function () {
      var promise = this.get('store').query('insurance', {}, {
        reload: true
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    emergencyContactOptions: computed('pet.household.emergency_contacts.[]', 'pet.emergency_contacts.[]', function () {
      var currentEcIds = this.get('pet.emergency_contacts').map(function (ec) {
        return ec.get('id');
      });
      return this.get('pet.household.emergency_contacts').filter(function (ec) {
        return !currentEcIds.includes(ec.get('id'));
      });
    }),
    selectedBreed: computed('breedOptions.[]', 'pet.breed.id', function () {
      return this.get('breedOptions').filterBy('id', this.get('pet.breed.id'));
    }),
    selectedPetType: computed('pet.{pet_type,pet_type_id}', function () {
      return {
        name: this.get('pet.pet_type'),
        id: this.get('pet.pet_type_id')
      };
    }),
    breedClasses: computed('notFoundBreed', function () {
      var dropdownClasses = ['breed-autocomplete'];

      if (this.get('notFoundBreed')) {
        dropdownClasses.push('not-found-breed');
      }

      return dropdownClasses.join(' ');
    }),
    newEmergencyContact: function newEmergencyContact(fullName) {
      if (!fullName) {
        fullName = ' ';
      }

      var parts = fullName.split(' ');

      var _parts = _slicedToArray(parts, 1),
          first_name = _parts[0];

      var last_name = undefined;

      if (parts.length > 1) {
        last_name = parts[parts.length - 1];
      }

      var pet = this.get('pet');
      var household = this.get('pet.household');
      var emergencyContact = this.get('store').createRecord('emergency-contact', {
        first_name: first_name,
        last_name: last_name,
        pet: pet,
        household: household
      });
      var email = this.get('store').createRecord('emergency-contact-email', {
        email_type: _emailType.default.PREFERRED
      });
      emergencyContact.get('emails').pushObject(email);
      var phone = this.get('store').createRecord('emergency-contact-phone-number');
      emergencyContact.get('phone_numbers').pushObject(phone);
      var address = this.get('store').createRecord('emergency-contact-address');
      emergencyContact.get('addresses').pushObject(address);
      return emergencyContact;
    },
    setVetSearchFilter: function setVetSearchFilter(args) {
      this.set('vetFilter', args.term);
    },
    actions: {
      scroll: function scroll() {
        if (_scrollUtil.default.isScrolledIntoView('#basic', HEADER_HEIGHT)) {
          this.set('currentSection', 'basic');
        } else if (_scrollUtil.default.isScrolledIntoView('#behavioral', HEADER_HEIGHT)) {
          this.set('currentSection', 'behavioral');
        } else if (_scrollUtil.default.isScrolledIntoView('#medical', HEADER_HEIGHT)) {
          this.set('currentSection', 'medical');
        } else if (_scrollUtil.default.isScrolledIntoView('#notes', HEADER_HEIGHT)) {
          this.set('currentSection', 'notes');
        }
      },
      submit: function submit() {
        var _this3 = this;

        if (this.get('pet.breed.id') == null && !Ember.isEmpty(this.get('breedName')) && this.get('dogOrCat')) {
          var matchExistingBreed = this.get('breedOptions').filter(function (breed) {
            return breed.get('name').toLowerCase() === _this3.get('breedName').toLowerCase();
          }).get('firstObject');

          if (matchExistingBreed) {
            this.set('pet.breed', matchExistingBreed);
            this.get('onSubmit')();
            return;
          }

          var name = this.get('breedName');
          var pet_type_id = this.get('pet.pet_type_id');
          var breed = this.get('store').createRecord('breed', {
            name: name,
            pet_type_id: pet_type_id
          });
          this.set('pet.breed', breed);
          breed.save().then(function () {
            _this3.get('onSubmit')();
          });
        } else {
          this.get('onSubmit')();
        }
      },
      changeVetClinic: function changeVetClinic(option) {
        this.set('pet.doctor', null);

        if (option == null) {
          this.set('pet.veterinarian_clinic', null);
        } else if (option.vet) {
          this.set('pet.veterinarian_clinic', option.vet);
        } else if (option.name.includes('Create')) {
          this.send('createVet', this.get('vetTerm'));
        }
      },
      createDoctor: function createDoctor(name) {
        var nameParts = name.split(' ');

        var _nameParts = _slicedToArray(nameParts, 1),
            first_name = _nameParts[0];

        var last_name = null;
        var veterinarian_clinic = this.get('pet.veterinarian_clinic');

        if (nameParts.length > 1) {
          last_name = nameParts[nameParts.length - 1];
        }

        var editingDoctor = this.get('store').createRecord('doctor', {
          first_name: first_name,
          last_name: last_name,
          veterinarian_clinic: veterinarian_clinic
        });
        veterinarian_clinic.notifyPropertyChange('doctors');
        this.set('editingDoctor', editingDoctor);
      },
      changePetStatus: function changePetStatus(status) {
        this.set('pet.spayed_or_neutered', status.value);
      },
      searchVets: function searchVets(term) {
        Ember.run.debounce(this, this.setVetSearchFilter, {
          term: term
        }, 500);
      },
      searchBreeds: function searchBreeds(term) {
        this.set('breedName', term);
        var pet_type = this.get('pet.pet_type_id');

        if (pet_type == 0) {
          pet_type = 1;
        }

        return this.get('store').query('breed', {
          pet_type: pet_type,
          filter: term
        });
      },
      scrollTo: function scrollTo(selector) {
        var pageWrapValue = $('.page-wrap').scrollTop();
        var selectorValue = $(selector).offset() ? $(selector).offset().top : 0;
        var topOfElement = pageWrapValue + selectorValue - HEADER_HEIGHT;
        document.querySelector('.page-wrap').scrollTo({
          top: topOfElement
        });
      },
      createVet: function createVet(name) {
        var editingVet = this.get('store').createRecord('veterinarian-clinic', {
          name: name
        });
        this.notifyPropertyChange('vetOptions');
        this.set('editingVet', editingVet);
        this.set('showVetModal', false);
      },
      saveVet: function saveVet(vet) {
        vet.save();
        this.set('pet.veterinarian_clinic', vet);
        this.set('editingVet', null);
      },
      setVetForPet: function setVetForPet(vet) {
        this.set('pet.veterinarian_clinic', vet);
        this.set('showVetModal', false);
      },
      createInsurance: function createInsurance(name) {
        var editingInsurance = this.get('store').createRecord('insurance', {
          name: name
        });
        this.notifyPropertyChange('insuranceOptions');
        this.set('editingInsurance', editingInsurance);
      },
      saveInsurance: function saveInsurance(insurance) {
        var existingInsurance = this.get('insuranceOptions.content').filter(function (loadedInsurance) {
          if (loadedInsurance.insurance && insurance.get('name').toLowerCase() === loadedInsurance.insurance.get('name').toLowerCase()) {
            return loadedInsurance.insurance;
          }
        }).get('firstObject');

        if (existingInsurance) {
          this.set('pet.insurance', existingInsurance);
          this.set('editingInsurance', null);
          return;
        }

        insurance.save();
        this.set('pet.insurance', insurance);
        this.set('editingInsurance', null);
      },
      addEmergencyContact: function addEmergencyContact(emergencyContact) {
        if (emergencyContact.get) {
          this.get('pet.emergency_contacts').pushObject(emergencyContact);
        } else {
          this.set('editingEmergencyContact', this.newEmergencyContact(emergencyContact.fullName));
        }
      },
      removeEmergencyContact: function removeEmergencyContact(emergencyContact) {
        this.get('pet.emergency_contacts').removeObject(emergencyContact);
      },
      rollbackEmergencyContact: function rollbackEmergencyContact() {
        this.get('editingEmergencyContact').rollbackAttributes();
        this.get('editingEmergencyContact.emails').forEach(function (email) {
          email.rollbackAttributes();
        });
        this.get('editingEmergencyContact.phone_numbers').forEach(function (number) {
          number.rollbackAttributes();
        });
        this.get('editingEmergencyContact.addresses').forEach(function (address) {
          address.rollbackAttributes();
        });
        this.set('editingEmergencyContact', null);
      },
      editEmergencyContact: function editEmergencyContact(emergencyContact) {
        if (emergencyContact.get('emails.length') == 0) {
          var email = this.get('store').createRecord('emergency-contact-email', {
            email_type: _emailType.default.PREFERRED
          });
          emergencyContact.get('emails').pushObject(email);
        }

        if (emergencyContact.get('phone_numbers.length') == 0) {
          var phone = this.get('store').createRecord('emergency-contact-phone-number');
          emergencyContact.get('phone_numbers').pushObject(phone);
        }

        if (emergencyContact.get('addresses.length') == 0) {
          var address = this.get('store').createRecord('emergency-contact-address');
          emergencyContact.get('addresses').pushObject(address);
        }

        this.set('editingEmergencyContact', emergencyContact);
      },
      saveEmergencyContact: function saveEmergencyContact(emergencyContact) {
        this.set('editingEmergencyContact', null);
        this.get('pet.emergency_contacts').pushObject(emergencyContact);
      },
      addPrescription: function addPrescription(prescription) {
        var newPrescription = this.get('store').createRecord('pet-prescription', {
          name: prescription.name
        });
        this.set('editingPrescription', newPrescription);
      },
      rollbackPrescription: function rollbackPrescription() {
        this.get('editingPrescription').rollbackAttributes();
        this.set('editingPrescription', null);
      },
      savePrescription: function savePrescription(prescription) {
        this.set('editingPrescription', null);
        this.get('pet.pet_prescriptions').pushObject(prescription);
      },
      removePrescription: function removePrescription(prescription) {
        this.get('pet.pet_prescriptions').removeObject(prescription);
      },
      editPrescriptions: function editPrescriptions(prescription) {
        this.set('editingPrescription', prescription);
      },
      rollbackVet: function rollbackVet() {
        this.get('editingVet').rollbackAttributes();
        this.set('editingVet', null);
      },
      rollbackInsurance: function rollbackInsurance() {
        this.get('editingInsurance').rollbackAttributes();
        this.set('editingInsurance', null);
      },
      saveDoctor: function saveDoctor(doctor) {
        doctor.save();
        var veterinarian_clinic = this.get('pet.veterinarian_clinic');
        veterinarian_clinic.notifyPropertyChange('doctors');
        this.set('pet.doctor', doctor);
        this.set('editingDoctor', null);
      },
      rollbackDoctor: function rollbackDoctor() {
        this.get('editingDoctor').rollbackAttributes();
        this.set('editingDoctor', null);
      },
      changePetType: function changePetType(petType) {
        if (petType.id === this.get('pet.pet_type_id')) {
          return;
        } // This should get added back in and or changed when we add in the tax
        // exempt toggle for non-dog pet types
        // if (petType.name != 'Dog') {
        //   this.set('pet.service_animal', false);
        // }


        this.set('pet.pet_type', petType.name);
        this.set('pet.pet_type_id', petType.id);
        this.set('pet.breed', null);
      },
      changeGender: function changeGender(gender) {
        if (_pet.STATUS[gender].indexOf(this.get('pet.status')) === -1) {
          this.set('pet.status', null);
        }

        this.set('pet.gender', gender);
      },
      changeGetsAlongWithDogs: function changeGetsAlongWithDogs(option) {
        this.set('pet.gets_along_with_dogs', option);
      },
      changeGetsAlongWithCats: function changeGetsAlongWithCats(option) {
        this.set('pet.gets_along_with_cats', option);
      },
      changeGetsAlongWithHumans: function changeGetsAlongWithHumans(option) {
        this.set('pet.gets_along_with_humans', option);
      },
      onInvalid: function onInvalid() {
        if ($('.name-input input').hasClass('ng-invalid')) {
          this.send('scrollTo', '.name-input');
        } else {
          this.send('scrollTo', '.gender-input');
        }
      }
    }
  });

  _exports.default = _default;
});