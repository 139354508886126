define("frontend/templates/components/custom-infinity-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3Eim3JYw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[3,\"in-viewport\",null,[[\"onEnter\",\"scrollableArea\"],[[28,\"action\",[[23,0,[]],\"didEnterViewport\"],null],[24,[\"scrollableArea\"]]]]],[8],[0,\"\\n  \"],[1,[22,\"svg-loading-spinner\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/custom-infinity-loader.hbs"
    }
  });

  _exports.default = _default;
});