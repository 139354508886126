define("frontend/templates/components/pet-inputs/vaccinations-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cgmvnkBa",
    "block": "{\"symbols\":[\"vaccination\"],\"statements\":[[4,\"each\",[[28,\"sort-by\",[\"name\",[24,[\"vaccinations\"]]],null]],null,{\"statements\":[[4,\"if\",[[28,\"not\",[[28,\"eq\",[[23,1,[\"dirtyType\"]],\"deleted\"],null]],null]],null,{\"statements\":[[4,\"if\",[[23,1,[\"name\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"class\",[29,[\"vaccination-item \",[28,\"if\",[[23,1,[\"expired\"]],\"vaccination-item--expired\"],null]]]],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"vaccination-name\"],[8],[1,[23,1,[\"name\"]],false],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"vaccination-dots\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[23,1,[\"expires_on\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[8],[0,\"exp \"],[1,[28,\"format-date\",[[23,1,[\"expires_on\"]],\"M/D/YYYY\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/pet-inputs/vaccinations-table.hbs"
    }
  });

  _exports.default = _default;
});