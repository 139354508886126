define("frontend/controllers/admin/index", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Controller.extend({
    impersonateEmail: '',
    session: inject.service(),
    accountSession: inject.service(),
    actions: {
      impersonate: function impersonate() {
        var _this = this;

        var store = this.get('session.store');
        var user_email = this.get('impersonateEmail');

        _jquery.default.post("".concat(_environment.default.APP.host, "/api/v2/impersonate_session"), {
          user: {
            user_email: user_email
          }
        }).then(function (response) {
          store.clear().then(function () {
            store.persist(response).then(function () {
              _this.get('accountSession').clearCompany();
            });
          });
        });
      }
    }
  });

  _exports.default = _default;
});