define("frontend/templates/components/document-list/mobile-document-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UgYRLRQl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"filename\"],[8],[1,[24,[\"document\",\"filename\"]],false],[9],[1,[28,\"document-image\",null,[[\"fileType\"],[[28,\"file-type\",[[24,[\"document\",\"filename\"]]],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/document-list/mobile-document-list-item.hbs"
    }
  });

  _exports.default = _default;
});