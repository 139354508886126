define("frontend/templates/components/free-trial-ends-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VYYyFcnL",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[4,\"ppb-modal\",null,[[\"onClose\"],[[28,\"action\",[[23,0,[]],\"onClose\"],null]]],{\"statements\":[[4,\"paper-form\",null,[[\"onSubmit\"],[[28,\"action\",[[23,0,[]],\"onSubmit\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n        \"],[7,\"header\",true],[8],[0,\"Edit free trial ending date\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"layout-row\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"layout-column flex\"],[8],[0,\"\\n            \"],[1,[28,\"component\",[[28,\"-assert-implicit-component-helper-argument\",[[23,1,[\"input\"]],\"expected `form.input` to be a contextual component but found a string. Did you mean `(component form.input)`? ('frontend/templates/components/free-trial-ends-modal.hbs' @ L10:C14) \"],null]],[[\"label\",\"type\",\"value\",\"required\",\"onChange\"],[\"Date free trial ends\",\"date\",[24,[\"company\",\"free_trial_ends\"]],true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"company\",\"free_trial_ends\"]]],null]],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"bottom\"],[8],[0,\"\\n      \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red-white\",\"cancel\",[28,\"action\",[[23,0,[]],\"onClose\"],null]]]],false],[0,\"\\n      \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red\",\"edit\",[28,\"action\",[[23,0,[]],[23,1,[\"onSubmit\"]]],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/free-trial-ends-modal.hbs"
    }
  });

  _exports.default = _default;
});