define("frontend/components/drag-drop-activated-flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    dragAndDropActivated: null,
    message: Ember.computed('dragAndDropActivated', function () {
      if (this.get('dragAndDropActivated')) {
        return 'You can now drag & drop dogs on the schedule!';
      } else {
        return 'You can now scroll without fear of accidental drags!';
      }
    })
  });

  _exports.default = _default;
});