define("frontend/components/mobile-text-area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mobile-text-area'],
    placeholder: null,
    value: null,
    classNameBindings: ['borderTop'],
    borderTop: false,
    rows: 4
  });

  _exports.default = _default;
});