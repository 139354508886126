define("frontend/components/payment-description-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    payment: null,
    hadDescription: false,
    didInsertElement: function didInsertElement() {
      if (this.get('payment.description')) {
        this.set('hadDescription', true);
      }
    },
    actions: {
      cancel: function cancel() {
        this.get('onClose')();
      },
      save: function save() {
        this.get('updateDescription')();
      }
    }
  });

  _exports.default = _default;
});