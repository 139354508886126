define("frontend/templates/components/text-area-callout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MRmB1R/e",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"mobile-gray-field-header\",null,[[\"borderTop\"],[false]],{\"statements\":[[0,\"    \"],[1,[22,\"label\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[28,\"mobile-text-area\",null,[[\"value\",\"placeholder\"],[[24,[\"value\"]],[24,[\"placeholder\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"input-callout\",null,[[\"tabindex\",\"label\",\"callout\",\"inputActivated\",\"inputSelector\"],[[24,[\"tabindex\"]],[24,[\"label\"]],[24,[\"callout\"]],[24,[\"inputActivated\"]],\"textarea\"]],{\"statements\":[[0,\"    \"],[1,[28,\"textarea\",null,[[\"autoresize\",\"tabindex\",\"value\",\"focus-out\",\"focus-in\"],[true,[24,[\"tabindex\"]],[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"focusOut\"],null],[28,\"action\",[[23,0,[]],\"focusIn\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"and\",[[28,\"and\",[[24,[\"hover\"]],[24,[\"hint\"]]],null],[24,[\"showHint\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"alert-hint\",null,[[\"hint\",\"deprecatedOldStyling\"],[[24,[\"hint\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/text-area-callout.hbs"
    }
  });

  _exports.default = _default;
});