define("frontend/controllers/inbox/distribution-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Controller.extend({
    currentUser: inject.service()
  });

  _exports.default = _default;
});