define("frontend/components/appointment-list", ["exports", "frontend/config/environment", "frontend/utils/string-util", "jquery"], function (_exports, _environment, _stringUtil, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['appointment-group'],
    flashMessage: inject.service(),
    editDisabled: computed.not('showButtonFooter'),
    recurringAppointments: null,
    groupedAppointments: null,
    selectedAppointments: [],
    selectedRecurringAppointments: [],
    selectedAppointmentRequests: [],
    selectedRecurringAddOns: [],
    selectAllRecurringAppointments: false,
    selectAllAppointments: false,
    selectedAllAppointmentRequests: false,
    newOptionId: null,
    recLoading: false,
    apptLoading: false,
    showDeleteConfirmationFlash: false,
    deleteConfirmationMessage: null,
    confirmDeleteAction: null,
    cancelDeleteAction: null,
    appointmentCheckersSelected: {},
    recurringAppointmentCheckersSelected: {},
    appointmentRequestCheckersSelected: {},
    recurringAddOnCheckersSelected: {},
    removeStaff: computed.equal('removeType', 'staff'),
    removeService: computed.equal('removeType', 'service'),
    showLoading: computed('recLoading', 'apptLoading', function () {
      return this.get('recLoading') || this.get('apptLoading');
    }),
    showButtonFooter: computed('selectedRecurringAddOns', 'selectedAppointments', 'selectedRecurringAppointments', 'selectedAppointmentRequests', 'showEditSidePanel', function () {
      var appts = this.get('selectedAppointments');
      var recAppts = this.get('selectedRecurringAppointments');
      var recAddOns = this.get('selectedRecurringAddOns');
      var appointmentRequests = this.get('selectedAppointmentRequests');
      var sidePanel = this.get('showEditSidePanel');
      return (appts.length > 0 || recAppts.length > 0 || appointmentRequests.length > 0 || recAddOns.length > 0) && !sidePanel;
    }),
    editSidePanelSaveDisabled: computed('newOptionId', function () {
      if (this.get('newOptionId')) {
        return false;
      }

      return true;
    }),
    serviceOrAddOn: computed('isAddOn', function () {
      return this.get('isAddOn') ? 'add-on' : 'service';
    }),
    dropdownSelectLabelText: computed('isAddOn', function () {
      if (this.get('isAddOn')) {
        return 'Switch add-on to';
      } else {
        return 'Switch service to';
      }
    }),
    dropdownPlaceholderText: computed('isAddOn', function () {
      if (this.get('isAddOn')) {
        return 'Select add-on';
      } else {
        return 'Select service';
      }
    }),
    showDeleteFlash: function showDeleteFlash(message, yesConfirmFunc, noConfirmFunc) {
      this.set('deleteConfirmationMessage', message);
      this.set('confirmDeleteAction', yesConfirmFunc);
      this.set('cancelDeleteAction', noConfirmFunc);
      this.set('showDeleteConfirmationFlash', true);
    },
    hideDeleteFlash: function hideDeleteFlash() {
      this.set('deleteConfirmationMessage', null);
      this.set('deleteConfirmationAction', null);
      this.set('cancelConfirmationAction', null);
      this.set('showDeleteConfirmationFlash', false);
    },
    actions: {
      selectRecurringAppointment: function selectRecurringAppointment(recurringAppointment) {
        var _this = this;

        var addOn = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        var name = addOn ? 'recurring-add-on' : 'recurring-appointment';

        if (recurringAppointment == 'all') {
          var selected = !this.get('selectAllRecurringAppointments');
          this.set('selectAllRecurringAppointments', selected);
          $(".".concat(name, "-checker")).each(function (_, value) {
            value.checked = selected;
          });
          var appt_ids = [];

          if (addOn) {
            var recurringAddOns = this.get('recurringAppointmentAddOns');
            recurringAddOns.forEach(function (appt) {
              appt_ids = appt_ids.concat(appt.ids);

              _this.set("recurringAddOnCheckersSelected.".concat(appt_ids.join('')), selected);
            });
            this.set('selectedRecurringAddOns', selected ? appt_ids : []);
          } else {
            var recurring_appts = this.get('recurringAppointments');
            recurring_appts.forEach(function (appt) {
              appt_ids = appt_ids.concat(appt.ids);

              _this.set("recurringAppointmentCheckersSelected.".concat(appt_ids.join('')), selected);
            });
            this.set('selectedRecurringAppointments', selected ? appt_ids : []);
          }
        } else {
          var _appt_ids = recurringAppointment.ids;

          var _selected;

          if (addOn) {
            _selected = !this.get("recurringAddOnCheckersSelected.".concat(_appt_ids.join('')));
            var selected_appt_ids = this.get('selectedRecurringAddOns');
            this.set("recurringAddOnCheckersSelected.".concat(_appt_ids.join('')), _selected);

            if (_selected) {
              this.set('selectedRecurringAddOns', selected_appt_ids.concat(_appt_ids));
            } else {
              this.set('selectedRecurringAddOns', $.grep(selected_appt_ids, function (value) {
                return $.inArray(value, _appt_ids) < 0;
              }));
            }
          } else {
            _selected = !this.get("recurringAppointmentCheckersSelected.".concat(_appt_ids.join('')));

            var _selected_appt_ids = this.get('selectedRecurringAppointments');

            this.set("recurringAppointmentCheckersSelected.".concat(_appt_ids.join('')), _selected);

            if (_selected) {
              this.set('selectedRecurringAppointments', _selected_appt_ids.concat(_appt_ids));
            } else {
              this.set('selectedRecurringAppointments', $.grep(_selected_appt_ids, function (value) {
                return $.inArray(value, _appt_ids) < 0;
              }));
            }
          }

          $("#appointment-list-item--".concat(name, "-").concat(_appt_ids.join(''))).each(function (_, value) {
            value.checked = _selected;
          });
        }
      },
      selectAppointment: function selectAppointment(selectedAppointment) {
        var _this2 = this;

        if (selectedAppointment == 'all') {
          var _ref;

          var selected = !this.get('selectAllAppointments');
          this.set('selectAllAppointments', selected);
          $('.appointment-checker').each(function (_, value) {
            value.checked = selected;
          });
          var appt_ids = [];
          var appts = this.get('groupedAppointments');

          (_ref = []).concat.apply(_ref, _toConsumableArray(appts)).forEach(function (appt) {
            if (selected) {
              appt_ids = appt_ids.concat([appt.grouped_appointment.id]);
            }

            _this2.set("appointmentCheckersSelected.".concat(appt.grouped_appointment.id), selected);
          });

          this.set('selectedAppointments', appt_ids);
        } else {
          var _selected2 = !this.get("appointmentCheckersSelected.".concat(selectedAppointment.grouped_appointment.id));

          var selected_appt_ids = this.get('selectedAppointments');
          var appt_id = selectedAppointment.grouped_appointment.id;
          this.set("appointmentCheckersSelected.".concat(appt_id), _selected2);

          if (_selected2) {
            this.set('selectedAppointments', selected_appt_ids.concat([appt_id]));
          } else {
            this.set('selectedAppointments', $.grep(selected_appt_ids, function (value) {
              return value != appt_id;
            }));
          }

          $("#appointment-list-item--appointment-".concat(appt_id)).each(function (_, value) {
            value.checked = _selected2;
          });
        }
      },
      selectAppointmentRequest: function selectAppointmentRequest(appointmentRequest) {
        var _this3 = this;

        if (appointmentRequest == 'all') {
          var selected = !this.get('selectAllAppointmentRequests');
          this.set('selectAllAppointmentRequests', selected);
          $('.appointment-request-checker').each(function (_, value) {
            value.checked = selected;
          });
          var ids = [];
          var requests = this.get('appointmentRequests');
          requests.forEach(function (request) {
            if (selected) {
              ids.push(request.appointment_request_limited.id);
            }

            _this3.set("appointmentRequestCheckersSelected.".concat(request.appointment_request_limited.id), selected);
          });
          this.set('selectedAppointmentRequests', ids);
        } else {
          var _selected3 = !this.get("appointmentRequestCheckersSelected.".concat(appointmentRequest.appointment_request_limited.id));

          var selected_appt_ids = this.get('selectedAppointmentRequests');
          var appt_id = appointmentRequest.appointment_request_limited.id;
          this.set("appointmentRequestCheckersSelected.".concat(appointmentRequest.appointment_request_limited.id), _selected3);

          if (_selected3) {
            this.set('selectedAppointmentRequests', selected_appt_ids.concat([appt_id]));
          } else {
            this.set('selectedAppointmentRequests', $.grep(selected_appt_ids, function (value) {
              return value != appt_id;
            }));
          }

          $("#appointment-list-item--appointment-request-".concat(appt_id)).each(function (_, value) {
            value.checked = _selected3;
          });
        }
      },
      transitionToSingleEdit: function transitionToSingleEdit(groupedAppointment) {
        var ids = groupedAppointment.appointments.map(function (ga) {
          return ga.id;
        }).join(',');
        this.transitionToSingleEdit(ids);
      },
      transitionToRecurringEdit: function transitionToRecurringEdit(recurringAppointment) {
        var id = recurringAppointment.group_id;
        this.transitionToRecurringEdit(id);
      },
      transitionToRequestEdit: function transitionToRequestEdit(appointmentRequest) {
        this.transitionToRequestEdit(appointmentRequest.appointment_request_limited.id);
      },
      hideDeleteSingleAppointmentFlash: function hideDeleteSingleAppointmentFlash() {
        this.hideDeleteFlash();
        this.set('selectedAppointment', null);
        this.set('deleteSingleType', null);
      },
      showDeleteSingleAppointmentFlash: function showDeleteSingleAppointmentFlash(apptType, ids, petName, occurrence) {
        var message;
        this.set('deleteSingleType', apptType);

        if (apptType == 'single') {
          message = "Are you sure you\u2019d like to remove ".concat(petName, "'s appointment?");
        } else {
          message = "Are you sure you\u2019d like to remove ".concat(petName, "'s ").concat(occurrence, " appointments?");
        }

        this.set('selectedAppointment', {
          ids: ids,
          petName: petName,
          occurrence: occurrence
        });
        this.showDeleteFlash(message, 'confirmDeleteSingleAppointment', 'hideDeleteSingleAppointmentFlash');
      },
      showDeleteSingleAppointmentRequestFlash: function showDeleteSingleAppointmentRequestFlash(id, pets) {
        var petNames = _stringUtil.default.joinWithAnd(pets.map(function (pet) {
          return pet.name;
        }));

        this.set('selectedAppointmentRequest', {
          id: id,
          pets: pets
        });
        this.showDeleteFlash("Are you sure you'd like to remove appointment request for ".concat(petNames, "?"), 'confirmDeleteSingleAppointmentRequest', 'hideDeleteAppointments');
      },
      confirmDeleteSingleAppointment: function confirmDeleteSingleAppointment() {
        var apptType = this.get('deleteSingleType');
        var selectedAppt = this.get('selectedAppointment');
        this.set('loadingMessage', 'Removing...');

        if (apptType == 'recurring') {
          this.send('deleteRecurringAppointments', selectedAppt.ids);
          this.get('flashMessage').sendFlashMessage("You\u2019ve successfully removed ".concat(selectedAppt.petName, "\u2019s ").concat(selectedAppt.occurrence, " appointments"), 'success-love');
        } else if (apptType == 'recurringAddOn') {
          this.send('deleteRecurringAddOns', selectedAppt.ids);
          this.get('flashMessage').sendFlashMessage("You\u2019ve successfully removed ".concat(selectedAppt.petName, "\u2019s ").concat(selectedAppt.occurrence, " appointments"), 'success-love');
        } else {
          this.send('deleteMultipleAppointments', this.get('selectedAppointment').ids);
          this.get('flashMessage').sendFlashMessage("You\u2019ve successfully removed ".concat(selectedAppt.petName, "\u2019s  appointment"), 'success-love');
        }

        this.set('selectedAppointment', null);
        this.hideDeleteFlash();
      },
      confirmDeleteSingleAppointmentRequest: function confirmDeleteSingleAppointmentRequest() {
        var selectedRequest = this.get('selectedAppointmentRequest');

        var petNames = _stringUtil.default.joinWithAnd(selectedRequest.pets.map(function (pet) {
          return pet.name;
        }));

        this.set('loadingMessage', 'Removing...');
        this.send('deleteAppointmentRequests', [selectedRequest.id]);
        this.get('flashMessage').sendFlashMessage("You've successfully removed appointment requests for ".concat(petNames), 'success-love');
        this.set('selectedAppointmentRequest', null);
        this.hideDeleteFlash();
      },
      confirmDeleteAppointments: function confirmDeleteAppointments() {
        this.set('loadingMessage', 'Removing...');

        if (this.get('isAddOn')) {
          this.send('deleteRecurringAddOns', this.get('selectedRecurringAddOns'));
          this.set('selectedRecurringAddOns', []);
        } else {
          this.send('deleteRecurringAppointments', this.get('selectedRecurringAppointments'));
          this.set('selectedRecurringAppointments', []);
        }

        this.send('deleteMultipleAppointments', this.get('selectedAppointments'));
        this.set('selectedAppointments', []);
        this.send('deleteAppointmentRequests', this.get('selectedAppointmentRequests'));
        this.set('selectedAppointmentRequests', []);
        this.hideDeleteFlash();
        this.set('showEditSidePanel', false);
      },
      confirmEditAppointments: function confirmEditAppointments() {
        this.set('loadingMessage', 'Hold on!');
        var optionId = this.get('newOptionId');
        var options = this.get('editOptions');

        var _options$filter = options.filter(function (option) {
          return option.value == optionId;
        }),
            _options$filter2 = _slicedToArray(_options$filter, 1),
            chosenOption = _options$filter2[0];

        if (this.get('offeredService') && !chosenOption.all_day_service && this.get('offeredService.all_day_service')) {
          this.set('showAllDayErrorMessage', true);
          return;
        }

        this.send('editRecurringAppointments', this.get('selectedRecurringAppointments'), optionId, chosenOption.label);
        this.set('selectedRecurringAppointments', []);
        this.send('editMultipleAppointments', this.get('selectedAppointments'), optionId, chosenOption.label);
        this.set('selectedAppointments', []);
        this.send('editMultipleAppointmentRequests', this.get('selectedAppointmentRequests'), optionId, chosenOption.label);
        this.set('selectedAppointmentRequests', []);
        this.send('editRecurringAddOns', this.get('selectedRecurringAddOns'), optionId, chosenOption.label);
        this.set('selectedRecurringAddOns', []);
        this.set('showEditSidePanel', false);
      },
      deleteRecurringAppointments: function deleteRecurringAppointments(ids) {
        var _this4 = this;

        if (ids.length > 0) {
          this.set('recLoading', true);

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/recurring_appointments/delete_multiple"),
            method: 'DELETE',
            data: {
              ids: ids
            }
          }).done(function () {
            _this4.send('removeRecurringAppointments', ids);

            _this4.set('recLoading', false);

            _this4.get('flashMessage').sendFlashMessage('Awesome! You just removed a bunch of appointments', 'success-love');
          });
        }
      },
      deleteRecurringAddOns: function deleteRecurringAddOns(ids) {
        var _this5 = this;

        if (ids.length > 0) {
          this.set('recLoading', true);

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/recurring_appointments/delete_multiple"),
            method: 'DELETE',
            data: {
              ids: ids,
              offered_service_id: this.get('offeredService.id'),
              is_add_on: true
            }
          }).done(function () {
            _this5.send('removeRecurringAddOns', ids);

            _this5.set('recLoading', false);

            _this5.get('flashMessage').sendFlashMessage('Awesome! You just removed a bunch of appointments', 'success-love');
          });
        }
      },
      deleteMultipleAppointments: function deleteMultipleAppointments(ids) {
        var _this6 = this;

        if (ids.length > 0) {
          this.set('apptLoading', true);

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v1/appointments/delete_multiple"),
            method: 'DELETE',
            data: {
              grouped_appointment_ids: ids,
              is_add_on: this.get('isAddOn'),
              offered_service_id: this.get('offeredService.id')
            }
          }).done(function () {
            _this6.send('removeAppointments', ids);

            _this6.set('apptLoading', false);

            _this6.get('flashMessage').sendFlashMessage('Awesome! You just removed a bunch of appointments', 'success-love');
          });
        }
      },
      deleteAppointmentRequests: function deleteAppointmentRequests(ids) {
        var _this7 = this;

        if (ids.length > 0) {
          this.set('apptRequestLoading', true);

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/appointment_requests/delete_multiple"),
            method: 'DELETE',
            data: {
              ids: ids,
              is_add_on: this.get('isAddOn'),
              offered_service_id: this.get('offeredService.id')
            }
          }).done(function () {
            _this7.send('removeAppointmentRequests', ids);

            _this7.set('apptRequestLoading', false);

            _this7.get('flashMessage').sendFlashMessage('Awesome! You just removed a bunch of appointment requests', 'success-love');
          });
        }
      },
      editRecurringAppointments: function editRecurringAppointments(ids, optionId, optionLabel) {
        var _this8 = this;

        if (ids.length > 0) {
          this.set('recLoading', true);
          var url_suffix = '';

          if (this.get('removeStaff')) {
            url_suffix = 'update_multiple_employees';
          } else if (this.get('removeService')) {
            url_suffix = 'update_multiple_offered_services';
          }

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/recurring_appointments/").concat(url_suffix),
            method: 'POST',
            data: {
              ids: ids,
              optionId: optionId
            }
          }).done(function () {
            _this8.send('removeRecurringAppointments', ids);

            _this8.set('recLoading', false);

            _this8.get('flashMessage').sendFlashMessage("Well done! You just switched a bunch of appointments to ".concat(optionLabel), 'success-love');
          });
        }
      },
      editRecurringAddOns: function editRecurringAddOns(ids, optionId, optionLabel) {
        var _this9 = this;

        if (ids.length > 0) {
          this.set('recLoading', true);

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/recurring_appointments/update_multiple_offered_services"),
            method: 'POST',
            data: {
              ids: ids,
              optionId: optionId,
              is_add_on: true
            }
          }).done(function () {
            _this9.send('removeRecurringAddOns', ids);

            _this9.set('recLoading', false);

            _this9.get('flashMessage').sendFlashMessage("Well done! You just switched a bunch of appointments to ".concat(optionLabel), 'success-love');
          });
        }
      },
      editMultipleAppointments: function editMultipleAppointments(ids, optionId, optionLabel) {
        var _this10 = this;

        if (ids.length > 0) {
          this.set('apptLoading', true);
          var url_suffix = '';

          if (this.get('removeStaff')) {
            url_suffix = 'update_multiple_employees';
          } else if (this.get('removeService')) {
            url_suffix = 'update_multiple_offered_services';
          }

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v1/appointments/").concat(url_suffix),
            method: 'POST',
            data: {
              ids: ids,
              optionId: optionId,
              is_add_on: this.get('isAddOn'),
              offered_service_id: this.get('offeredService.id')
            }
          }).done(function () {
            _this10.send('removeAppointments', ids);

            _this10.set('apptLoading', false);

            _this10.get('flashMessage').sendFlashMessage("Well done! You just switched a bunch of appointments to ".concat(optionLabel), 'success-love');
          });
        }
      },
      editMultipleAppointmentRequests: function editMultipleAppointmentRequests(ids, optionId, optionLabel) {
        var _this11 = this;

        if (ids.length > 0) {
          this.set('apptRequestLoading', true);

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/appointment_requests/update_multiple_offered_services"),
            method: 'POST',
            data: {
              ids: ids,
              optionId: optionId,
              is_add_on: this.get('isAddOn'),
              offered_service_id: this.get('offeredService.id')
            }
          }).done(function () {
            _this11.send('removeAppointmentRequests', ids);

            _this11.get('flashMessage').sendFlashMessage("Well done! You just switched a bunch of appointment requests to ".concat(optionLabel), 'success-love');

            _this11.set('apptRequestLoading', false);
          });
        }
      },
      removeAppointments: function removeAppointments(ids) {
        var appts = this.get('groupedAppointments');
        var result = appts.filter(function (appt) {
          return $.inArray(appt.grouped_appointment.id, ids) < 0;
        });
        this.set('groupedAppointments', result);
      },
      removeAppointmentRequests: function removeAppointmentRequests(ids) {
        var requests = this.get('appointmentRequests');
        var result = requests.filter(function (apptRequest) {
          return $.inArray(apptRequest.appointment_request_limited.id, ids) < 0;
        });
        this.set('appointmentRequests', result);
      },
      removeRecurringAppointments: function removeRecurringAppointments(ids) {
        var appts = this.get('recurringAppointments');
        var result = appts.filter(function (appt) {
          var found_ids = $.grep(appt.ids, function (value) {
            return $.inArray(value, ids) >= 0;
          });
          return found_ids.length == 0;
        });
        this.set('recurringAppointments', result);
      },
      removeRecurringAddOns: function removeRecurringAddOns(ids) {
        var appts = this.get('recurringAppointmentAddOns');
        var result = appts.filter(function (appt) {
          var found_ids = $.grep(appt.ids, function (value) {
            return $.inArray(value, ids) >= 0;
          });
          return found_ids.length == 0;
        });
        this.set('recurringAppointmentAddOns', result);
      },
      deleteAppointment: function deleteAppointment(id) {
        this.send('deleteMultipleAppointments', [id]);
      },
      hideSidePanel: function hideSidePanel() {
        this.set('showEditSidePanel', false);
      },
      showSidePanel: function showSidePanel() {
        this.set('showEditSidePanel', true);
      },
      hideDeleteAppointments: function hideDeleteAppointments() {
        this.hideDeleteFlash();
      },
      showDeleteAppointments: function showDeleteAppointments() {
        var appointmentCount = this.get('selectedAppointments.length') + this.get('selectedRecurringAppointments.length') + this.get('selectedRecurringAddOns.length');
        var requestCount = this.get('selectedAppointmentRequests.length');
        var inflectedText;

        if (appointmentCount > 0) {
          inflectedText = requestCount > 0 ? 'appointments and requests' : 'appointments';
        } else {
          inflectedText = 'requests';
        }

        this.showDeleteFlash("Are you sure you'd like to remove all selected ".concat(inflectedText, "?"), 'confirmDeleteAppointments', 'hideDeleteAppointments');
      }
    }
  });

  _exports.default = _default;
});