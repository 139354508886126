define("frontend/controllers/invalid-browser", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['link'],
    link: null,
    actions: {
      copyLink: function copyLink() {
        $('#link-text').css('display', 'inline');
        $('#link-text').select();
        document.execCommand('copy');
        $('#link-text').css('display', 'none');
        $('.copy-button').tooltip('hide').attr('data-original-title', 'Copied!').tooltip('show');
        setTimeout(function () {
          $('.copy-button').tooltip('hide');
        }, 1000);
      }
    }
  });

  _exports.default = _default;
});