define("frontend/routes/schedule/calendar", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      if (transition.to.queryParams.schedule_type === 'list') {
        return this.transitionTo('schedule');
      }

      if (transition.to.queryParams.hidingSidePanel === 'true') {
        $('.ember-application').addClass('side-panel-hidden');
      } else {
        $('.ember-application').removeClass('side-panel-hidden');
      }
    },
    model: function model() {
      return this.modelFor('schedule');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.notifyPropertyChange('care_provider_id');
      controller.notifyPropertyChange('allServices');
      controller.notifyPropertyChange('careProviderServices');
      controller.notifyPropertyChange('filteredAllServices');
      controller.notifyPropertyChange('filteredCareProviderServices');
    }
  });

  _exports.default = _default;
});