define("frontend/routes/add-client/primary-contact", ["exports", "frontend/routes/base-route", "frontend/mixins/cleanup-on-transition", "frontend/utils/email-type"], function (_exports, _baseRoute, _cleanupOnTransition, _emailType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = _baseRoute.default.extend(_cleanupOnTransition.default, {
    addClientSaving: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    currentUser: Ember.inject.service(),
    model: function model(params) {
      var _this = this;

      var householdId = params.householdId,
          searchedEmail = params.searchedEmail;

      if (householdId) {
        return this.store.findRecord('household', householdId).then(function (household) {
          var householdUser = household.get('owner');

          if (householdUser.get('addresses.length') == 0) {
            householdUser.get('addresses').pushObject(_this.buildAddress());
          }

          if (householdUser.get('phone_numbers.length') == 0) {
            householdUser.get('phone_numbers').pushObject(_this.buildPhone());
          }

          return householdUser;
        });
      } else {
        var invoice_frequency = null;

        try {
          invoice_frequency = this.get('currentUser.user.company.default_invoice_frequency.name');
        } catch (err) {
          invoice_frequency = null;
        }

        var user = this.store.createRecord('user');
        var householdUser = this.store.createRecord('household-user', {
          user: user,
          invoice_frequency: invoice_frequency
        });
        var defaultEmail = this.store.createRecord('email', {
          address: searchedEmail,
          email_type: _emailType.default.PREFERRED
        });
        householdUser.get('emails').pushObject(defaultEmail);
        householdUser.get('phone_numbers').pushObject(this.buildPhone());
        householdUser.get('addresses').pushObject(this.buildAddress());
        return householdUser;
      }
    },
    buildAddress: function buildAddress() {
      var user = this.get('currentUser.user');
      var state = user.get('company.state') || user.get('client_accounts.firstObject.company_state');
      var country = user.get('company.country') || user.get('client_accounts.firstObject.country');
      return this.store.createRecord('address', {
        state: state,
        country: country
      });
    },
    buildPhone: function buildPhone() {
      return this.store.createRecord('phone-number');
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('anySidePanelActive', false);
    },
    actions: {
      goNext: function goNext(user) {
        var _this2 = this;

        this.set('saving', true);
        this.get('addClientSaving').saveUser(user).then(function (savedUser) {
          return _this2.transitionTo('add-client.secondary-contact', savedUser.get('household.id'));
        }).finally(function () {
          _this2.set('saving', false);
        });
      }
    }
  });

  _exports.default = _default;
});