define("frontend/templates/admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iqvzi+J9",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"if\",[[24,[\"currentUser\",\"user\",\"isSuperAdmin\"]]],null,{\"statements\":[[0,\"  \"],[4,\"mobile-header-flex\",null,[[\"showAdd\"],[false]],{\"statements\":[[7,\"div\",true],[10,\"class\",\"header-text\"],[8],[0,\"Admin\"],[9]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"sidebar-nav\",null,[[\"collapsed\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"admin-page\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"link-header\"],[8],[0,\"\\n\"],[4,\"paper-select\",null,[[\"label\",\"options\",\"selected\",\"onChange\"],[\"\",[24,[\"adminOptions\"]],[24,[\"selectedAdminOption\"]],[28,\"action\",[[23,0,[]],\"onSelectedAction\"],null]]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"admin-select-option\"],[8],[0,\"\\n          \"],[1,[23,1,[\"label\"]],false],[0,\"\\n\"],[4,\"if\",[[23,1,[\"showRedDot\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\",true],[10,\"class\",\"red-dot\"],[8],[0,\" \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"        \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/admin.hbs"
    }
  });

  _exports.default = _default;
});