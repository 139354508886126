define("frontend/routes/remove-recurring-appointment-group", ["exports", "frontend/mixins/transition-back-route", "frontend/routes/base-route"], function (_exports, _transitionBackRoute, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_transitionBackRoute.default, {
    model: function model(params) {
      this._super.apply(this, arguments);

      return this.store.findRecord('recurring-appointment-group', params.id);
    },
    actions: {
      cancel: function cancel(recurringAppointmentGroup, backHouseholdId) {
        var backRoute = this.get('controller.backRoute');
        var backId = this.get('controller.backId');
        var queryParams = {
          backRoute: 'client-list.household.appointments',
          backHouseholdId: backHouseholdId
        };

        if (backRoute === 'edit-recurring-appointment-group.index') {
          this.transitionBack(backRoute, recurringAppointmentGroup.get('id'), {
            queryParams: queryParams
          });
        } else {
          var householdId = recurringAppointmentGroup.get('recurring_appointments.firstObject.pet.household');
          this.transitionBack(backRoute, householdId, backId);
        }
      },
      remove: function remove(recurringAppointmentGroup) {
        var _this = this;

        var firstRecurringAppointment = recurringAppointmentGroup.get('recurring_appointments.firstObject');
        var controller = Ember.getOwner(this).lookup('controller:remove-recurring-appointment-group');

        if (controller.get('saving')) {
          return;
        }

        controller.set('saving', true);
        recurringAppointmentGroup.destroyRecord().then(function () {
          if (firstRecurringAppointment) {
            return _this.transitionTo('client-list.household.appointments', firstRecurringAppointment.get('pet.household.id'));
          } else {
            return _this.transitionTo('client-list');
          }
        }).then(function () {
          window.location.reload();
        }).finally(function () {
          controller.set('saving', false);
        });
      }
    }
  });

  _exports.default = _default;
});