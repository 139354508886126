define("frontend/components/remove-cancellation-fee-policy-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    serviceCategory: null,
    cancellationFeePolicy: null,
    loading: false,
    didInsertElement: function didInsertElement() {
      this.set('loading', false);
    },
    actions: {
      denyRemove: function denyRemove() {
        this.get('denyRemove')();
      },
      confirmRemove: function confirmRemove() {
        this.set('loading', true);
        this.get('confirmRemove')();
      }
    }
  });

  _exports.default = _default;
});