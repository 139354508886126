define("frontend/components/document-list/document-list-item", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    classNameBindings: ['swipedLeft'],
    media: inject.service(),
    showMenu: false,
    isHovering: false,
    handleMouseEnter: function handleMouseEnter() {
      this.set('isHovering', true);
    },
    handleMouseLeave: function handleMouseLeave() {
      this.set('isHovering', false);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var offClickHandler = this.get('clickedOutsideElement').bind(this);
      this.set('offClickHandler', offClickHandler);
      (0, _jquery.default)(document).on('click', offClickHandler);
      this.element.addEventListener('mouseenter', this.handleMouseEnter.bind(this));
      this.element.addEventListener('mouseleave', this.handleMouseLeave.bind(this));
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)(document).off('click', this.get('offClickHandler'));
      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
      this.element.removeEventListener('mouseleave', this.handleMouseLeave);
    },
    clickedOutsideElement: function clickedOutsideElement(event) {
      var $element = (0, _jquery.default)(this.element);
      var $target = (0, _jquery.default)(event.target);

      if (!$target.closest($element).length) {
        this.set('showMenu', false);
        this.set('isHovering', false);
      }
    },
    formattedDate: computed('document.created_at', function () {
      return moment.parseZone(this.get('document.created_at')).format('MMM D, YYYY');
    }),
    actions: {
      toggleMenu: function toggleMenu() {
        this.toggleProperty('showMenu');
      },
      downloadDocument: function downloadDocument() {
        window.open(this.get('document.url'), this.get('target'));
        this.toggleProperty('showMenu');
      },
      renameTemplate: function renameTemplate() {
        this.set('originalFileName', this.get('document.filename'));
        this.set('showRenameModal', true);
        this.toggleProperty('showMenu');
      },
      closeRenameModal: function closeRenameModal() {
        this.set('document.filename', this.get('originalFileName'));
        this.set('originalFileName', null);
        this.set('showRenameModal', false);
      },
      onRenameModalClose: function onRenameModalClose() {
        _jquery.default.ajax({
          url: "/api/v2/user_documents/".concat(this.get('document.id')),
          method: 'PUT',
          data: {
            filename: this.get('document.filename')
          }
        });

        this.set('originalFileName', null);
        this.set('showRenameModal', false);
      },
      remove: function remove() {
        this.remove.apply(this, arguments);
        this.toggleProperty('showMenu');
      }
    }
  });

  _exports.default = _default;
});