define("frontend/components/staff-summary-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    staffCompensation: 0,
    tipCompensation: 0,
    classNames: ['staff-summary-header', 'col-md-12'],
    completedAppointments: 0,
    activeStaff: 0,
    totalCompensation: Ember.computed('staffCompensation', 'tipCompensation', 'surchargeFeeCompensation', function () {
      return this.get('staffCompensation') + this.get('tipCompensation') + this.get('surchargeFeeCompensation');
    })
  });

  _exports.default = _default;
});