define("frontend/controllers/admin/companies", ["exports", "frontend/config/environment", "frontend/mixins/admin/approvable-table", "frontend/mixins/autocomplete/highlight-first-match", "jquery"], function (_exports, _environment, _approvableTable, _highlightFirstMatch, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend(_approvableTable.default, _highlightFirstMatch.default, {
    session: inject.service(),
    accountSession: inject.service(),
    selectedCompany: null,
    showServiceFeeModal: false,
    updateServiceFeeModalText: computed('selectedCompany', function () {
      return "Are you sure you want to turn ".concat(this.get('selectedCompany.service_fees_enabled') ? 'OFF' : 'ON', " service fees for ").concat(this.get('selectedCompany.name'), "?");
    }),
    updatePetParentsActivatedModalInfoText: computed('selectedCompany', function () {
      return "Turning ON this toggle will mean that welcome emails will be released to all pet parent clients for <span class=\"bold\">".concat(this.get('selectedCompany.name'), "</span>. Pet parents with existing PetPocketbook accounts will be prompted to verify their connection with this provider the next time they login and will also be able to see any and all activity from this company, including appointments already booked on the calendar.");
    }),
    updateQuickbooksModalText: computed('selectedCompany', function () {
      return "Are you sure you want to ".concat(this.get('selectedCompany.quickbooks_access') ? 'disallow' : 'allow', " <span class=\"bold\">").concat(this.get('selectedCompany.name'), "</span> to access our QuickBooks beta?");
    }),
    updateQuickbooksModalButtonText: computed('selectedCompany', function () {
      return this.get('selectedCompany.quickbooks_access') ? 'Disallow' : 'Allow';
    }),
    filteredRecords: computed('model.@each.{name,preferred_name}', 'filter', function () {
      var _this = this;

      if (this.get('filter') !== '') {
        return this.get('model').filter(function (company) {
          var ownerMatch = false;
          company.owners.forEach(function (owner) {
            if (owner.name.toLowerCase().match(_this.get('filter').toLowerCase())) {
              ownerMatch = true;
            }
          });
          return ownerMatch || company.name.toLowerCase().match(_this.get('filter').toLowerCase()) || company.preferred_name && company.preferred_name.toLowerCase().match(_this.get('filter').toLowerCase());
        });
      } else {
        return this.get('model');
      }
    }),
    actions: {
      impersonate: function impersonate(user_email) {
        var _this2 = this;

        var store = this.get('session.store');

        _jquery.default.post("".concat(_environment.default.APP.host, "/api/v2/impersonate_session"), {
          user: {
            user_email: user_email
          }
        }).then(function (response) {
          store.clear().then(function () {
            store.persist(response).then(function () {
              _this2.get('accountSession').clearCompany();
            });
          });
        });
      },
      showServiceFeeModal: function showServiceFeeModal(company) {
        this.set('selectedCompany', company);
        this.set('showServiceFeeModal', true);
      },
      closeServiceFeeModal: function closeServiceFeeModal() {
        this.set('selectedCompany', null);
        this.set('showServiceFeeModal', false);
      },
      showQuickbooksModal: function showQuickbooksModal(company) {
        this.set('selectedCompany', company);
        this.set('showQuickbooksModal', true);
      },
      closeQuickbooksModal: function closeQuickbooksModal() {
        this.set('selectedCompany', null);
        this.set('showQuickbooksModal', false);
      },
      toggleServiceFees: function toggleServiceFees() {
        var _this3 = this;

        this.set('showServiceFeeModal', false);

        _jquery.default.post("".concat(_environment.default.APP.host, "/api/v2/companies/").concat(this.get('selectedCompany.id'), "/toggle_service_fees")).then(function () {
          Ember.set(_this3.get('selectedCompany'), 'service_fees_enabled', !_this3.get('selectedCompany.service_fees_enabled'));

          _this3.set('selectedCompany', null);
        });
      },
      toggleFeature: function toggleFeature(company, feature_name) {
        _jquery.default.post("".concat(_environment.default.APP.host, "/api/v2/companies/").concat(company.id, "/toggle_feature"), {
          feature_name: feature_name
        }).then(function () {
          window.location.reload();
        });
      },
      showPetParentsActivationModal: function showPetParentsActivationModal(company) {
        this.set('selectedCompany', company);
        this.set('showPetParentsActivatedModal', true);
      },
      closePetParentsActivationModal: function closePetParentsActivationModal() {
        this.set('selectedCompany', null);
        this.set('showPetParentsActivatedModal', false);
      },
      togglePetParentsActivated: function togglePetParentsActivated() {
        var _this4 = this;

        this.set('showPetParentsActivatedModal', false);
        Ember.$.post("".concat(_environment.default.APP.host, "/api/v2/companies/").concat(this.get('selectedCompany.id'), "/toggle_pet_parents_activated"), {
          send_welcome_emails: true
        }).then(function () {
          Ember.set(_this4.get('selectedCompany'), 'pet_parents_activated', !_this4.get('selectedCompany.pet_parents_activated'));

          _this4.set('selectedCompany', null);
        });
      },
      togglePetParentsActivatedWithoutEmails: function togglePetParentsActivatedWithoutEmails() {
        var _this5 = this;

        this.set('showPetParentsActivatedModal', false);
        Ember.$.post("".concat(_environment.default.APP.host, "/api/v2/companies/").concat(this.get('selectedCompany.id'), "/toggle_pet_parents_activated"), {
          send_welcome_emails: false
        }).then(function () {
          Ember.set(_this5.get('selectedCompany'), 'pet_parents_activated', !_this5.get('selectedCompany.pet_parents_activated'));

          _this5.set('selectedCompany', null);
        });
      },
      showFreeTrialModal: function showFreeTrialModal(company) {
        this.set('freeTrialModalOpen', true);
        this.set('selectedCompany', company);
        this.set('originalFreeTrialEnds', company.free_trial_ends);
      },
      closeFreeTrialModal: function closeFreeTrialModal() {
        this.set('selectedCompany.free_trial_ends', this.get('originalFreeTrialEnds'));
        this.set('selectedCompany', null);
        this.set('originalFreeTrialEnds', null);
        this.set('freeTrialModalOpen', false);
      },
      updateFreeTrial: function updateFreeTrial(company) {
        var _this6 = this;

        _jquery.default.post("".concat(_environment.default.APP.host, "/api/v2/companies/").concat(company.id, "/update_free_trial"), {
          end_date: company.free_trial_ends
        }).then(function () {
          _this6.set('selectedCompany', null);

          _this6.set('originalFreeTrialEnds', null);

          _this6.set('freeTrialModalOpen', false);
        });
      },
      showHubspotModal: function showHubspotModal(company) {
        this.set('hubspotModalOpen', true);
        this.set('selectedCompany', company);
        this.set('originalHubspotId', company.hubspot_id);
      },
      closeHubpotModal: function closeHubpotModal() {
        this.set('selectedCompany.hubspot_id', this.get('originalHubspotId'));
        this.set('selectedCompany', null);
        this.set('originalHubspotId', null);
        this.set('hubspotModalOpen', false);
      },
      updateHubspotId: function updateHubspotId(company) {
        var _this7 = this;

        _jquery.default.post("".concat(_environment.default.APP.host, "/api/v2/companies/").concat(company.id, "/update_hubspot_id"), {
          hubspot_id: company.hubspot_id
        }).then(function () {
          _this7.set('selectedCompany', null);

          _this7.set('originalHubspotId', null);

          _this7.set('hubspotModalOpen', false);
        });
      },
      triggerStripeVerification: function triggerStripeVerification(company) {
        this.set('selectedCompany', company);
        this.set('showStripeVerificationModal', true);
      },
      closeStripeVerificationModal: function closeStripeVerificationModal() {
        this.set('selectedCompany', null);
        this.set('showStripeVerificationModal', false);
      },
      confirmStripeVerification: function confirmStripeVerification(company) {
        var _this8 = this;

        _jquery.default.post("".concat(_environment.default.APP.host, "/api/v2/admin/stripe_verification"), {
          company_id: company.id
        }).then(function () {
          _this8.set('selectedCompany', null);

          _this8.set('showStripeVerificationModal', false);
        });
      }
    }
  });

  _exports.default = _default;
});