define("frontend/components/tab-side-panel/side-panel-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'a',
    classNames: ['tab-side-panel-item'],
    classNameBindings: ['active:tab-side-panel-item--active'],
    active: false
  });

  _exports.default = _default;
});