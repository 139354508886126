define("frontend/routes/edit-client/remove-pet", ["exports", "frontend/routes/base-route", "frontend/config/environment"], function (_exports, _baseRoute, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = _baseRoute.default.extend({
    addClientSaving: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    currentUser: inject.service(),
    model: function model(params) {
      return this.store.findRecord('pet', params.pet_id);
    },
    setupController: function setupController(controller) {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('currentUser.user.isPetParent')) {
        jQuery.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointment_check/has_future_appointments?pet_id=").concat(controller.model.id),
          method: 'GET'
        }).done(function (response) {
          var hasFutureAppointments = response.hasFutureAppointments;

          if (hasFutureAppointments) {
            controller.set('futureAppointments', true);
          } else {
            controller.set('futureAppointments', false);
          }
        }).fail(function () {
          _this.get('flashMessage').sendFlashMessage("We were unable to load some information. Please refresh the page.", 'error');
        });
      } else {
        jQuery.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointment_check/future_appointments_with_current_care_provider?pet_id=").concat(controller.model.id),
          method: 'GET'
        }).done(function (response) {
          var futureAppointmentsWithCurrentCareProvider = response.futureAppointmentsWithCurrentCareProvider;

          if (futureAppointmentsWithCurrentCareProvider) {
            controller.set('futureAppointments', true);
          } else {
            controller.set('futureAppointments', false);
          }
        }).fail(function () {
          _this.get('flashMessage').sendFlashMessage("We were unable to load some information. Please refresh the page.", 'error');
        });
      }
    },
    transitionToPet: function transitionToPet() {
      var pet = this.get('controller.model');
      this.transitionTo('edit-client.pet', pet);
    },
    transitionToList: function transitionToList(params) {
      return this.transitionTo('client-list.household', params['household.id']);
    },
    transitionToPortal: function transitionToPortal() {
      window.location.href = '/portal/profile';
    },
    actions: {
      remove: function remove() {
        var _this2 = this;

        if (this.get('saving')) {
          return;
        }

        this.set('saving', true);
        var pet = this.get('controller.model');
        var params = pet.getProperties('household.id');
        pet.destroyRecord().then(function () {
          if (_this2.get('currentUser').user.isPetParent) {
            return _this2.transitionToPortal().then(function () {
              _this2.set('saving', false);
            });
          } else {
            return _this2.transitionToList(params).then(function () {
              _this2.set('saving', false);
            });
          }
        });
      },
      cancel: function cancel() {
        this.transitionToPet();
      }
    }
  });

  _exports.default = _default;
});