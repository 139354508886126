define("frontend/routes/edit-client", ["exports", "frontend/routes/base-route", "frontend/mixins/transition-back-route"], function (_exports, _baseRoute, _transitionBackRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = _baseRoute.default.extend(_transitionBackRoute.default, {
    queryParams: {
      editSection: {
        refreshModel: false
      }
    },
    addClientSaving: inject.service(),
    flashMessage: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    model: function model(params) {
      var household_id = params.household_id;
      return this.store.findRecord('household', household_id);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('saving', false);
    },
    // The below code performing some action on a model relationship is here likely due to how we are saving these types
    // of records. This could result in duplicate records being displayed on the UI or other potential issues. If you
    // notice odd behaviors with a relationship that is saved as part of this component try adding it to rollbackRecordModels
    rollbackRecordModels: ['phone_number', 'address', 'email', 'vaccination', 'pet_prescription'],
    rollbackAttributesRecords: function rollbackAttributesRecords() {
      var _this = this;

      this.get('rollbackRecordModels').forEach(function (modelName) {
        _this.store.peekAll(modelName).filterBy('hasDirtyAttributes', true).forEach(function (m) {
          return m.rollbackAttributes();
        });
      });
    },
    removeNewRecords: function removeNewRecords() {
      var _this2 = this;

      this.get('rollbackRecordModels').forEach(function (modelName) {
        _this2.store.peekAll(modelName).filter(function (m) {
          return m.get('id') === undefined;
        }).forEach(function (m) {
          return m.deleteRecord();
        });
      });
    },
    reloadDirtyRecords: function reloadDirtyRecords() {
      var _this3 = this;

      this.get('rollbackRecordModels').forEach(function (modelName) {
        // If the model id is null we cannot reload it so make sure that an id actually exists before we reload anything
        _this3.store.peekAll(modelName).filter(function (m) {
          return m.get('id');
        }).forEach(function (m) {
          return m.reload();
        });
      });
    },
    rollbackHousehold: function rollbackHousehold(household) {
      household.rollbackAttributes();
      household.get('users').forEach(function (householdUser) {
        if (householdUser.get('id') && householdUser.get('user.id')) {
          householdUser.rollbackAttributes();
          householdUser.get('user.content').rollbackAttributes();
        }
      });
      household.get('pets').forEach(function (pet) {
        return pet.rollbackAttributes();
      });
      this.rollbackAttributesRecords.call(this);
    },
    actions: {
      clickPetOrUser: function clickPetOrUser(petOrUser) {
        if (petOrUser.get('modelName') === 'pet') {
          this.transitionTo('edit-client.pet', petOrUser);
        } else {
          var household = this.get('controller.model');

          if (household.get('owner.id') === petOrUser.get('id')) {
            this.transitionTo('edit-client.primary-contact', household);
          } else {
            this.transitionTo('edit-client.secondary-contact', household);
          }
        }
      },
      createPetOrUser: function createPetOrUser(petOrUser) {
        var household = this.get('controller.model');
        this.rollbackHousehold(household);

        if (petOrUser === 'pet') {
          this.transitionTo('edit-client.add-pet', household);
        } else {
          this.transitionTo('edit-client.add-secondary-contact', household);
        }
      },
      save: function save() {
        var _this4 = this;

        var household = this.get('controller.model');
        var allEmails = [];
        household.get('users').map(function (user) {
          user.get('emails').forEach(function (email) {
            if (!email.get('isDeleted') && !Ember.isBlank(email.get('address'))) {
              allEmails.push(email.get('address').toLowerCase());
            }
          });
        });
        var emails = [];
        var duplicateEmail = false;
        allEmails.forEach(function (address) {
          if (Ember.isBlank(address)) {
            return;
          }

          address = address.toLowerCase();

          if (emails.includes(address)) {
            duplicateEmail = true;

            _this4.get('flashMessage').sendFlashMessage("Sorry, an email can\u2019t belong to multiple contacts! Please remove ".concat(address, " from one of your human profiles before proceeding."), 'user-error');

            return;
          }

          emails.push(address);
        });

        if (duplicateEmail) {
          return;
        }

        this.set('saving', true);
        var userPromises = household.get('users').map(function (user) {
          return user.save();
        });
        var petPromises = household.get('pets').map(function (pet) {
          return pet.save();
        });
        var promises = [].concat(_toConsumableArray(userPromises), _toConsumableArray(petPromises));
        return Ember.RSVP.Promise.all(promises).then(function () {
          _this4.removeNewRecords.call(_this4);

          _this4.reloadDirtyRecords.call(_this4);

          _this4.rollbackAttributesRecords.call(_this4);

          var backRoute = _this4.get('controller.backRoute');

          var backId = _this4.get('controller.backId');

          var backHouseholdId = _this4.get('controller.backHouseholdId');

          _this4.get('controller.model').reload();

          _this4.transitionBack(backRoute, backHouseholdId, backId).then(function () {
            _this4.set('saving', false);
          });
        });
      },
      transitionBack: function transitionBack() {
        var backHouseholdId = this.get('controller.backHouseholdId');
        var backRoute = this.get('controller.backRoute');
        var backId = this.get('controller.backId');
        this.transitionBack(backRoute, backHouseholdId, backId);
      },
      redirectToSearch: function redirectToSearch(email) {
        this.transitionTo('add-client.client-search', {
          queryParams: {
            email: email
          }
        });
      },
      cancel: function cancel() {
        var household = this.get('controller.model');
        this.rollbackHousehold(household);
        this.send('transitionBack');
      }
    }
  });

  _exports.default = _default;
});