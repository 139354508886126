define("frontend/components/multi-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['multi-select'],
    options: [],
    selectedValues: [],
    selectAllByDefault: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('selectAllByDefault')) {
        this.set('selectedValues', []);
        this.get('selectedValues').pushObjects(this.get('options').mapBy('value'));
        this.selectedValuesChanged(this.get('selectedValues'));
      }
    },
    actions: {
      selectOption: function selectOption(value) {
        this.get('selectedValues').pushObject(value);
        this.selectedValuesChanged(this.get('selectedValues'));
      },
      deselectOption: function deselectOption(value) {
        this.get('selectedValues').removeObject(value);
        this.selectedValuesChanged(this.get('selectedValues'));
      }
    }
  });

  _exports.default = _default;
});