define("frontend/templates/components/adaptive-paper-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Que6YS8C",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"layout-row\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"layout-column layout-align-center flex-90\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"mobile-switch-label\"],[8],[14,1],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"layout-column flex-10\"],[8],[0,\"\\n      \"],[1,[28,\"paper-switch\",null,[[\"disabled\",\"value\",\"onChange\"],[[24,[\"disabled\"]],[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"paper-switch\",null,[[\"disabled\",\"value\",\"onChange\"],[[24,[\"disabled\"]],[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[4,\"if\",[[24,[\"clarificationNote\"]]],null,{\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"mobile-clarification\"],[8],[0,\"\\n      \"],[1,[22,\"clarificationNote\"],true],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[\"switch-clarification \",[28,\"if\",[[24,[\"requiredAutoPayDisabled\"]],\"required-auto-pay-disabled\"],null]]]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"border-side\"],[8],[0,\"\\n        \"],[1,[28,\"inline-svg\",[\"assets/images/info-icon.svg\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[0,\"\\n        \"],[1,[22,\"clarificationNote\"],true],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/adaptive-paper-switch.hbs"
    }
  });

  _exports.default = _default;
});