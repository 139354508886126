define("frontend/templates/components/client-list/pet-output/no-output-fields", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZKq3J55J",
    "block": "{\"symbols\":[],\"statements\":[[4,\"doo-error\",null,[[\"classNames\",\"smallDoo\"],[\"no-notes\",true]],{\"statements\":[[0,\"  You haven't added any notes for \"],[1,[24,[\"model\",\"name\"]],false],[0,\" yet!\\n\"],[4,\"if\",[[24,[\"currentUser\",\"user\",\"isAdmin\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[8],[4,\"link-to\",null,[[\"query\",\"route\",\"models\"],[[28,\"hash\",null,[[\"backRoute\",\"backHouseholdId\",\"backId\"],[\"client-list.household.pet\",[24,[\"model\",\"household\",\"id\"]],[24,[\"model\",\"id\"]]]]],\"edit-client.pet\",[28,\"array\",[[24,[\"model\",\"household\"]],[24,[\"model\"]]],null]]],{\"statements\":[[0,\"add notes\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/client-list/pet-output/no-output-fields.hbs"
    }
  });

  _exports.default = _default;
});