define("frontend/components/custom-pricing-modal/offered-service-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend({
    currentUser: inject.service(),
    store: inject.service(),
    offeredServices: computed('currentUser.user.company', function () {
      return this.get('store').query('offered-service', {}, {
        reload: true
      });
    }),
    sortedOfferedServices: computed.sort('offeredServices', function (a, b) {
      if (a.get('service_category_position') > b.get('service_category_position')) {
        return 1;
      } else if (a.get('service_category_position') < b.get('service_category_position')) {
        return -1;
      } else {
        if (a.get('position') > b.get('position')) {
          return 1;
        } else if (a.get('position') < b.get('position')) {
          return -1;
        }

        return 0;
      }
    }),
    offeredServicePrices: computed('offeredServiceClientPrices.@each.price', 'sortedOfferedServices.[]', function () {
      var _this = this;

      return this.get('sortedOfferedServices').map(function (offeredService) {
        var offeredServiceClientPrice = _this.findCustomPrice(_this.get('offeredServiceClientPrices'), offeredService);

        return Ember.Object.create({
          offeredServiceClientPrice: offeredServiceClientPrice,
          offeredService: offeredService
        });
      });
    }),
    findCustomPrice: function findCustomPrice(offeredServiceClientPrices, offeredService) {
      return offeredServiceClientPrices.find(function (oscp) {
        return oscp.get('offered_service.id') === offeredService.get('id');
      });
    },
    actions: {
      onClose: function onClose() {
        this.get('onClose')();
      },
      onEditPrice: function onEditPrice(price) {
        this.get('onEditPrice')(price);
      },
      resetAllPrices: function resetAllPrices() {
        this.get('resetAllPrices')();
      }
    }
  });

  _exports.default = _default;
});