define("frontend/components/message-thread/message", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['message-thread--message'],
    classNameBindings: ['sentByMe:right-side-message', 'staffMessage:staff-message', 'isPending'],
    currentUser: inject.service(),
    store: inject.service(),
    message: null,
    isPlainTextMessage: computed.equal('message.message_type', 'PlainTextMessage'),
    isTypingIndicatorMessage: computed.equal('message.message_type', 'TypingIndicatorMessage'),
    isReportCardMessage: computed.equal('message.message_type', 'GroupedReportCardMessage'),
    isAppointmentRequestAnsweredMessage: computed.equal('message.message_type', 'AppointmentRequestAnsweredMessage'),
    isAppointmentRequestMessage: computed.equal('message.message_type', 'AppointmentRequestMessage'),
    isImageMessage: computed.equal('message.message_type', 'ImageMessage'),
    isCancelledAppointmentMessage: computed.equal('message.message_type', 'CancelledAppointmentMessage'),
    isCancelledAppointmentRequestMessage: computed.equal('message.message_type', 'CancelledRequestMessage'),
    isModifyAppointmentRequestMessage: computed.equal('message.message_type', 'ModifyRequestMessage'),
    isModifiedAppointmentMessage: computed.equal('message.message_type', 'ModifiedAppointmentMessage'),
    avatarPlaceholder: 'assets/images/staff/placeholder-human-image@3x.png',
    isPetParent: false,
    notPetParent: computed.not('isPetParent'),
    staffMessage: computed.and('message.sent_by_staff', 'notPetParent'),
    viewHouseholdsModalOpen: false,
    households: [],
    isLoadingHouseholds: false,
    sentByMe: computed('message.sent_by_id', function () {
      return this.get('message.sent_by_id') == this.get('currentUser.user.id');
    }),
    messageSentTime: computed('message.created_at', function () {
      return moment(this.get('message.created_at')).format('h:mmA');
    }),
    avatarImage: computed('message.sent_by_avatar', function () {
      if (this.get('message.sent_by_avatar')) {
        return this.get('message.sent_by_avatar');
      } else {
        return this.get('avatarPlaceholder');
      }
    }),
    showImage: computed('isReportCardMessage', 'message.report_card_photo_url', function () {
      return this.get('isReportCardMessage') && this.get('message.report_card_photo_url');
    }),
    isPending: computed('isAppointmentRequestMessage', 'message.pending', function () {
      return (this.get('isAppointmentRequestMessage') || this.get('isModifyAppointmentRequestMessage') || this.get('isModifiedAppointmentMessage')) && this.get('message.pending');
    }),
    didRender: function didRender() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('showImage')) {
        Ember.run.scheduleOnce('afterRender', function () {
          var width = (0, _jquery.default)(_this.element.querySelectorAll('.message-box')).css('width');
          (0, _jquery.default)(_this.element.querySelectorAll('.report-card-image .cover-photo')).css('width', width);
          (0, _jquery.default)(_this.element.querySelectorAll('.report-card-image .cover-photo')).css('height', width);
          (0, _jquery.default)(_this.element.querySelectorAll('.report-card-image .cover-photo')).css('background-image', "url(\"".concat(_this.get('message.report_card_photo_url'), "\")"));
        });
      }
    },
    click: function click() {
      if (this.get('isReportCardMessage')) {
        this.send('viewReportCard');
      } else if (this.get('isAppointmentRequestAnsweredMessage') && this.get('message.was_approved')) {
        this.send('viewAppointmentDetails');
      } else if (this.get('message.pending')) {
        this.send('goToAppointmentRequest');
      }
    },
    actions: {
      toggleViewHousesHoldsModal: function toggleViewHousesHoldsModal() {
        this.toggleProperty('viewHouseholdsModalOpen');

        if (this.get('viewHouseholdsModalOpen')) {
          this.send('getHouseholds');
        }
      },
      getHouseholds: function getHouseholds() {
        var _this2 = this;

        this.set('isLoadingHouseholds', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/messages/households_by_message"),
          method: 'GET',
          data: {
            message_id: this.get('message.id')
          }
        }).then(function (response) {
          _this2.get('store').pushPayload(response);

          var filteredResponses = _this2.get('store').peekAll('household').filter(function (household) {
            return response.household.some(function (_ref) {
              var id = _ref.id;
              return id === parseInt(household.get('id'));
            });
          });

          _this2.set('households', filteredResponses);

          _this2.set('isLoadingHouseholds', false);
        });
      },
      viewReportCard: function viewReportCard() {
        this.get('viewReportCard')(this.get('message'));
      },
      viewAppointmentDetails: function viewAppointmentDetails() {
        this.get('viewAppointmentDetails')(this.get('message'));
      },
      goToAppointmentRequest: function goToAppointmentRequest() {
        this.get('goToAppointmentRequest')(this.get('message'));
      },
      openPdfModal: function openPdfModal(imageUrl) {
        this.set('showPdfModal', true);
        this.set('pdfUrl', imageUrl);
      },
      closePdfModal: function closePdfModal() {
        this.set('showPdfModal', false);
        this.set('pdfUrl', null);
      }
    }
  });

  _exports.default = _default;
});