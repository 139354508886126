define("frontend/components/edit-categories-time-frame-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    store: inject.service(),
    selectedCategory: null,
    addingCategory: false,
    timeFrames: computed.alias('selectedCategory.time_frames'),
    activeTimeFrames: computed.filterBy('timeFrames', 'markedForDeletion', false),
    changeAppointmentMethod: 'future',
    windowOptions: [{
      label: '15 min',
      value: '15'
    }, {
      label: '30 min',
      value: '30'
    }, {
      label: '45 min',
      value: '45'
    }, {
      label: '1 hour',
      value: '60'
    }, {
      label: '1 hour 30min',
      value: '90'
    }, {
      label: '2 hours',
      value: '120'
    }, {
      label: '2 hours 30min',
      value: '150'
    }, {
      label: '3 hours',
      value: '180'
    }],
    selectedTimeWindow: computed('selectedCategory', 'selectedCategory.time_window_in_minutes', function () {
      var _this = this;

      var options = this.get('windowOptions');
      var selected = options.filter(function (option) {
        return _this.get('selectedCategory.time_window_in_minutes') == option.value;
      });
      return selected.get('firstObject');
    }),
    timeWindowClarification: computed('selectedTimeWindow', function () {
      var timeWindow = this.get('selectedTimeWindow');

      if (timeWindow) {
        var startTime = moment('2019-11-11 10:00 AM', 'YYYY-MM-DD hh:mm a').subtract(timeWindow.value, 'minutes').format('h:mmA');
        var endTime = moment('2019-11-11 10:00 AM', 'YYYY-MM-DD hh:mm a').add(timeWindow.value, 'minutes').format('h:mmA');
        return "".concat(startTime, " - ").concat(endTime);
      } else {
        return;
      }
    }),
    sortedTimeFrames: computed.sort('activeTimeFrames', function (x, y) {
      if (x.get('start_time') == y.get('start_time')) {
        if (moment(x.get('end_time'), 'hh:mm a') > moment(y.get('end_time'), 'hh:mm a')) {
          return 1;
        } else {
          return -1;
        }
      } else {
        if (moment(x.get('start_time'), 'hh:mm a') > moment(y.get('start_time'), 'hh:mm a')) {
          return 1;
        } else {
          return -1;
        }
      }
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('changeAppointmentMethod', 'future'); // Do not switch this to a forEach loop because it will change the array
      // while you're working on it

      var tfs = this.get('selectedCategory.time_frames').map(function (tf) {
        return tf;
      });

      for (var i = 0; i < tfs.length; i++) {
        tfs[i].rollbackAttributes();
      }
    },
    actions: {
      cancel: function cancel() {
        this.get('cancel')();
      },
      addCategory: function addCategory() {
        this.get('addCategory')(this.get('timeFrames'), this.get('selectedCategory.time_window_in_minutes'), this.get('selectedCategory.use_time_blocks'), this.get('selectedCategory.show_to_pet_parents'));
      },
      toggleAppointmentMethodModal: function toggleAppointmentMethodModal() {
        this.toggleProperty('showChangeAppointmentMethodModal');
      },
      openHeadsUpModal: function openHeadsUpModal() {
        if (this.get('changeAppointmentMethod') == 'none') {
          this.send('updateCategoryHours');
        } else {
          this.set('showHeadsUpModal', true);
          this.send('toggleAppointmentMethodModal');
        }
      },
      closeHeadsUpModal: function closeHeadsUpModal() {
        this.set('showHeadsUpModal', false);
      },
      updateCategoryHours: function updateCategoryHours() {
        this.get('updateCategoryHours')(this.get('changeAppointmentMethod'));
      },
      toggleUsingTimeBlocks: function toggleUsingTimeBlocks() {
        this.toggleProperty('selectedCategory.use_time_blocks');
      },
      toggleShowToPetParents: function toggleShowToPetParents() {
        this.toggleProperty('selectedCategory.show_to_pet_parents');
      },
      createTimeFrame: function createTimeFrame() {
        var newTimeFrame = this.get('store').createRecord('time-frame', {
          service_category: this.get('selectedCategory'),
          days_of_week: [0, 1, 2, 3, 4, 5, 6]
        });
        this.set('selectedTimeFrame', newTimeFrame);
        this.set('showTimeBlockModal', true);
        this.set('newTimeFrame', true);
      },
      selectTimeFrame: function selectTimeFrame(timeFrame) {
        this.set('newTimeFrame', false);
        this.set('selectedTimeFrame', timeFrame);
        this.set('selectedTimeFrameOriginalStart', timeFrame.get('start_time'));
        this.set('selectedTimeFrameOriginalEnd', timeFrame.get('end_time'));
        this.set('selectedTimeFrameOriginalDows', timeFrame.get('days_of_week'));
        this.set('showTimeBlockModal', true);
      },
      removeTimeFrame: function removeTimeFrame(timeFrame) {
        timeFrame.set('markedForDeletion', true);
      },
      saveTimeFrame: function saveTimeFrame() {
        if (this.get('newTimeFrame')) {
          var timeFrame = this.get('selectedTimeFrame');
          this.get('timeFrames').pushObject(timeFrame);
        }

        this.set('showTimeBlockModal', false);
      },
      closeTimeBlockModal: function closeTimeBlockModal() {
        if (this.get('newTimeFrame')) {
          this.get('selectedTimeFrame').rollbackAttributes();
        } else {
          var timeFrame = this.get('selectedTimeFrame');
          timeFrame.set('start_time', this.get('selectedTimeFrameOriginalStart'));
          timeFrame.set('end_time', this.get('selectedTimeFrameOriginalEnd'));
          timeFrame.set('days_of_week', this.get('selectedTimeFrameOriginalDows'));
        }

        this.set('showTimeBlockModal', false);
      },
      changeTimeWindow: function changeTimeWindow(option) {
        this.set('selectedCategory.time_window_in_minutes', option.value);
      }
    }
  });

  _exports.default = _default;
});