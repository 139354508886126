define("frontend/components/load-more-button", ["exports", "frontend/components/ppb-button"], function (_exports, _ppbButton) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _ppbButton.default.extend({
    classNames: ['load-more-button'],
    color: 'red-white',
    numAppointments: 0
  });

  _exports.default = _default;
});