define("frontend/ember-gestures/recognizers/threshold-swipe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    include: [],
    exclude: [],
    // eslint-disable-next-line no-undef
    options: {
      threshold: 150,
      direction: typeof Hammer === 'undefined' ? '' : Hammer.DIRECTION_HORIZONTAL
    },
    recognizer: 'pan'
  };
  _exports.default = _default;
});