define("frontend/templates/components/payments-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7eSSL9gh",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"payments-widget/sent-header\",null,[[\"selectOption\",\"paymentType\"],[[28,\"action\",[[23,0,[]],\"selectPaymentType\"],null],[24,[\"paymentType\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"payments-content\"],[8],[0,\"\\n  \"],[1,[28,\"payments-widget/payments-table\",null,[[\"extraHeaders\",\"paymentType\",\"paymentHeaders\",\"isFetching\"],[[24,[\"extraHeaders\"]],[24,[\"paymentType\"]],[24,[\"paymentHeaders\"]],[24,[\"isFetching\"]]]]],false],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"isFetching\"]]],null,{\"statements\":[[0,\"    \"],[1,[22,\"svg-loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/payments-widget.hbs"
    }
  });

  _exports.default = _default;
});