define("frontend/templates/client-list/household/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wNkbdc5B",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"mobile-header-flex\",null,[[\"type\",\"showBack\",\"showHamburger\",\"showAdd\",\"clickBack\"],[\"double-header\",true,false,false,[28,\"route-action\",[\"transitionToHouseholdUser\",[24,[\"model\",\"household\"]]],null]]],{\"statements\":[[0,\"    \"],[7,\"header\",true],[10,\"class\",\"header-text\"],[8],[0,\"\\n      \"],[7,\"div\",true],[8],[1,[22,\"firstNames\"],false],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"bottom-text\"],[8],[1,[24,[\"model\",\"household\",\"owner\",\"last_name\"]],false],[0,\" family\"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"message-thread\",null,[[\"messages\",\"company\",\"household\",\"goToAppointmentRequest\"],[[24,[\"model\",\"messages\"]],[24,[\"model\",\"company\"]],[24,[\"model\",\"household\"]],[28,\"route-action\",[\"goToAppointmentRequest\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/client-list/household/messages.hbs"
    }
  });

  _exports.default = _default;
});