define("frontend/routes/invoices-summary/payment-detail", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model: function model(_ref) {
      var paymentId = _ref.paymentId;
      return this.store.findRecord('invoice-payment', paymentId);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('saving', false);
      controller.set('editing', false);
    },
    actions: {
      goBack: function goBack() {
        this.transitionTo('invoices-summary', this.get('controller.model.household_id'));
      }
    }
  });

  _exports.default = _default;
});