define("frontend/components/stripe-dropin", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    integration: null,
    user: null,
    reloadAfterSave: false,
    company: false,
    flashMessage: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    saving: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      // eslint-disable-next-line new-cap
      var stripe = Stripe(_environment.default.stripe_api_key);
      var elements = stripe.elements();
      var style = {
        base: {
          fontFamily: "'Gotham A', 'Gotham B', helvetica, arial, sans-serif",
          fontSize: '16px',
          color: '#32325d'
        }
      };
      var card = elements.create('card', {
        hidePostalCode: true,
        style: style
      });
      var errorDiv = (0, _jquery.default)('#card-errors');
      card.mount('#card-container');
      card.addEventListener('change', function (_ref) {
        var error = _ref.error;

        if (error) {
          errorDiv.text(error.message);
        } else {
          errorDiv.text('');
        }
      });
      var form = document.getElementById('creditCardForm');
      form.addEventListener('submit', function (event) {
        event.preventDefault();

        if (_this.get('saving')) {
          return;
        }

        _this.set('saving', true);

        if (_this.get('onSaving')) {
          _this.get('onSaving')();
        }

        stripe.createToken(card).then(function (_ref2) {
          var token = _ref2.token,
              error = _ref2.error;

          if (error) {
            errorDiv.text(error.message);

            if (_this.get('onFinishedSaving')) {
              _this.get('onFinishedSaving')();
            }

            _this.set('saving', false);
          } else {
            var url = '';

            if (_this.get('company')) {
              url = "".concat(_environment.default.APP.host, "/api/v2/companies/").concat(_this.get('currentUser.user.company.id'), "/create_stripe_payment_method");
            } else {
              url = "".concat(_environment.default.APP.host, "/api/v2/users/").concat(_this.get('user.id'), "/create_stripe_payment_method");
            }

            _jquery.default.ajax({
              url: url,
              method: 'POST',
              data: {
                stripe_token: token,
                payment_method: 'card',
                id: _this.get('user.id'),
                set_auto_pay: true
              }
            }).done(function () {
              Ember.getOwner(_this).lookup('controller:application').send('notify', {
                message: 'Payment info saved',
                type: 'success'
              });
              (0, _jquery.default)('#submitButton').prop('disabled', false);

              if (_this.get('reloadAfterSave')) {
                location.reload();
              }
            }).fail(function (data) {
              if (data && data.responseText) {
                var parsed = JSON.parse(data.responseText);

                if (parsed.message) {
                  _this.get('flashMessage').error(parsed.message);

                  (0, _jquery.default)('#submitButton').prop('disabled', false);
                } else {
                  _this.get('flashMessage').errorWithRefresh('Unexpected error paying invoice');
                }
              } else {
                _this.get('flashMessage').errorWithRefresh('Unexpected error paying invoice');
              }
            }).always(function () {
              if (_this.get('onFinishedSaving')) {
                _this.get('onFinishedSaving')();
              }

              _this.set('saving', false);
            });
          }
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var integration = this.get('integration');

      if (this.get('integration')) {
        integration.teardown(function () {
          _this2.set('integration', null);
        });
      }
    }
  });

  _exports.default = _default;
});