define("frontend/components/flash-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['flash-notification'],
    classNameBindings: ['green:flash-notification--green', 'yellow:flash-notification--yellow', 'red:flash-notification--red', 'blue:flash-notification--blue'],
    color: 'green',
    green: computed.equal('color', 'green'),
    red: computed.equal('color', 'red'),
    yellow: computed.equal('color', 'yellow'),
    blue: computed.equal('color', 'blue'),
    showOverlay: false,
    dismissAfter: 0,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      var dismissAfter = this.get('dismissAfter');

      if (dismissAfter > 0) {
        Ember.run.later(function () {
          if (!_this.get('isDestroyed')) {
            _this.get('dismiss')();
          }
        }, dismissAfter);
      }
    },
    click: function click() {
      if (this.get('dismiss')) {
        this.get('dismiss')();
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get('dismiss')) {
        this.get('dismiss')();
      }

      this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});