define("frontend/utils/user-role", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    owner: {
      id: 'Owner',
      label: 'Owner'
    },
    manager: {
      id: 'Manager',
      label: 'Administrator'
    },
    employee: {
      id: 'Employee',
      label: 'Staff'
    },
    senior_staff: {
      id: 'Senior_Staff',
      label: 'Senior Staff'
    },
    roleName: function roleName(userRole) {
      if (!userRole) {
        return undefined;
      }

      return this[userRole.toLowerCase()].label;
    }
  };
  _exports.default = _default;
});