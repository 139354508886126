define("frontend/routes/edit-single-appointment/index", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('appointmentDateSidePanelOpen', false);
      controller.set('assignTimeSidePanelOpen', false);
      controller.set('showDeleteAppointmentFlash', false);
      var appointment = model.appointments.get('firstObject');
      var appointmentTime = moment.parseZone(appointment.get('service_starts_at')).clone().format('h:mm A');
      controller.set('appointmentTime', appointmentTime);
      controller.set('showRequestOrApproveModal', false);
    }
  });

  _exports.default = _default;
});