define("frontend/templates/components/quickbooks-confirmation-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hUQ8utc1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,[[\"customContainerClassNames\"],[\"quickbooks-confirmation-modal\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"header-image\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"assets/images/diverging-paths.svg\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"Are you sure?\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"By proceeding, we will be importing your invoice and payment data from PetPocketbook \"],[7,\"span\",true],[10,\"class\",\"bold\"],[8],[0,\"as of \"],[1,[28,\"format-date\",[[24,[\"effectiveDate\"]],\"MMMM D, YYYY\"],null],false],[9],[0,\" into the QuickBooks account that you connect. Once begun, the import cannot be paused or undone.\\n  \"],[7,\"br\",true],[8],[9],[7,\"br\",true],[8],[9],[0,\"If you have not read \"],[7,\"a\",true],[10,\"href\",\"https://help.petpocketbook.com/article/166-quickbooks-integration-faqs\"],[10,\"target\",\"_blank\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"our FAQs about this integration\"],[9],[0,\", we recommend doing so before proceeding so that there are no surprises!\"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"control-panel\"],[8],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"color\",\"onclick\",\"class\"],[\"red\",[28,\"action\",[[23,0,[]],\"proceed\"],null],\"proceed-button\"]],{\"statements\":[[0,\"      proceed\\n\"]],\"parameters\":[]},null],[4,\"ppb-button\",null,[[\"color\",\"onclick\",\"class\"],[\"red-white\",[28,\"action\",[[23,0,[]],\"onClose\"],null],\"dismiss-button\"]],{\"statements\":[[0,\"      cancel\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/quickbooks-confirmation-modal.hbs"
    }
  });

  _exports.default = _default;
});