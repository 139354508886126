define("frontend/templates/components/datepicker-side-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "afRU9slo",
    "block": "{\"symbols\":[],\"statements\":[[4,\"right-side-panel\",null,null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"      \"],[4,\"ppb-button\",null,[[\"color\",\"classNames\",\"onclick\"],[\"red-white\",\"cancel-button\",[28,\"action\",[[23,0,[]],\"close\"],null]]],{\"statements\":[[7,\"img\",true],[10,\"src\",\"/assets/images/back-chevron.svg\"],[8],[9]],\"parameters\":[]},null],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"header-text\"],[8],[1,[22,\"headerText\"],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"hasSave\"]]],null,{\"statements\":[[4,\"ppb-button\",null,[[\"onclick\",\"color\",\"disabled\",\"classNames\"],[[28,\"action\",[[23,0,[]],\"save\"],null],\"red-white\",[24,[\"isInvalid\"]],\"save-button\"]],{\"statements\":[[0,\"          Save\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"img\",false],[12,\"class\",\"close-button pull-right\"],[12,\"src\",\"assets/images/staff/x.svg\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"close\"],null]]],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"header-text\"],[8],[1,[22,\"headerText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"content-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"datepicker-container\"],[8],[0,\"\\n      \"],[1,[28,\"bootstrap-datepicker\",null,[[\"value\",\"changeDate\"],[[24,[\"date\"]],[28,\"action\",[[23,0,[]],\"changeDate\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/datepicker-side-panel.hbs"
    }
  });

  _exports.default = _default;
});