define("frontend/controllers/companies/edit", ["exports", "frontend/config/environment", "ember-cp-validations", "jquery"], function (_exports, _environment, _emberCpValidations, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'model.name': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'model.preferred_name': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'model.email': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    'model.phone': [(0, _emberCpValidations.validator)('international-phone-number', {
      presence: true,
      ignoreBlank: true
    })]
  });

  var _default = Ember.Controller.extend(Validations, {
    newCompaniesController: Ember.inject.controller('companies'),
    saving: computed.alias('newCompaniesController.saving'),
    hasAddress: computed.notEmpty('model.zipcode'),
    timeZone: inject.service(),
    flashMessage: inject.service(),
    timeZoneOptions: computed.readOnly('timeZone.timeZoneOptions'),
    legalEntityOptions: [{
      label: 'No',
      value: false
    }, {
      label: 'Yes',
      value: true
    }],
    weekStartsOnOptions: [{
      label: 'Sunday through Saturday',
      value: 'Sunday'
    }, {
      label: 'Monday through Sunday',
      value: 'Monday'
    }],
    nameInvalid: computed('model.name', 'model.legal_entity', function () {
      return this.get('model.legal_entity') && !this.get('validations.attrs.model.name.isValid');
    }),
    preferredNameInvalid: computed('model.preferred_name', 'model.legal_entity', function () {
      return !this.get('model.legal_entity') && !this.get('validations.attrs.model.preferred_name.isValid');
    }),
    emailInvalid: computed('model.email', function () {
      return !this.get('validations.attrs.model.email.isValid');
    }),
    phoneInvalid: computed('model.phone', function () {
      return !this.get('validations.attrs.model.phone.isValid');
    }),
    canSave: computed('nameInvalid', 'preferredNameInvalid', 'emailInvalid', 'phoneInvalid', function () {
      return !this.get('nameInvalid') && !this.get('preferredNameInvalid') && !this.get('emailInvalid') && !this.get('phoneInvalid');
    }),
    selectedLegalStatus: computed('model.legal_entity', function () {
      var _this = this;

      return this.get('legalEntityOptions').find(function (item) {
        return item.value === _this.get('model.legal_entity');
      });
    }),
    selectedTimeZone: computed('model.time_zone', function () {
      var _this2 = this;

      return this.get('timeZoneOptions').find(function (item) {
        return item.value === _this2.get('model.time_zone');
      });
    }),
    selectedCalendarWeek: computed('model.week_starts_on', function () {
      var _this3 = this;

      return this.get('weekStartsOnOptions').find(function (item) {
        return item.value === _this3.get('model.week_starts_on');
      });
    }),
    disableLegalStatus: computed('model.has_stripe_account', function () {
      return this.get('model.has_stripe_account');
    }),
    actions: {
      save: function save() {
        var _this4 = this;

        if (this.get('saving')) {
          return;
        }

        if (!this.get('canSave')) {
          return;
        }

        this.set('saving', true);
        this.get('model').save().then(function () {
          return _this4.transitionToRoute('index');
        }).finally(function () {
          return _this4.set('saving', false);
        });
      },
      uploadedPhoto: function uploadedPhoto(photo) {
        this.set('model.logo_url', photo);
      },
      changeLegalStatus: function changeLegalStatus(value) {
        this.set('model.legal_entity', value.value);
      },
      changePhoneNumber: function changePhoneNumber(value) {
        this.set('model.phone', value);
      },
      changeTimeZone: function changeTimeZone(value) {
        this.set('model.time_zone', value.value);
      },
      changeCalendarWeek: function changeCalendarWeek(value) {
        this.set('model.week_starts_on', value.value);
      },
      updateLegalStatus: function updateLegalStatus() {
        this.set('showUpdateLegalStatusModal', true);
      },
      closeLegalStatusModal: function closeLegalStatusModal() {
        this.set('showUpdateLegalStatusModal', false);
      },
      confirmUpdateLegalStatus: function confirmUpdateLegalStatus() {
        var _this5 = this;

        if (!this.get('updatedLegalStatus')) {
          var company_id = this.get('model.id');
          this.set('updatingLegalStatus', true);

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/companies/").concat(company_id, "/notify_support_for_legal_status_change"),
            method: 'POST',
            data: {
              company_id: company_id
            }
          }).done(function (response) {
            _this5.set('updatingLegalStatus', false);

            _this5.set('showUpdateLegalStatusModal', false);

            _this5.get('flashMessage').sendFlashMessage(response.message, 'Success');
          });

          this.set('updatedLegalStatus', true);
        }
      }
    }
  });

  _exports.default = _default;
});