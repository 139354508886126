define("frontend/models/grouped-report-card", ["exports", "ember-data", "frontend/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = _emberData.default.Model.extend({
    ajax: inject.service(),
    petBorderColor: _emberData.default.attr('string'),
    draft: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    notes: _emberData.default.attr('string'),
    // appt_id is only used when creating a new grouped report card
    appt_id: _emberData.default.attr(),
    newImage: _emberData.default.attr('string'),
    newImageType: _emberData.default.attr('string'),
    removeImage: _emberData.default.attr('string'),
    appointments: _emberData.default.hasMany('appointments', {
      async: false
    }),
    coverPhoto: _emberData.default.belongsTo('grouped-report-card-image', {
      async: false
    }),
    groupedReportCardImages: _emberData.default.hasMany('grouped-report-card-image', {
      async: false
    }),
    groupedReportCardChecklists: _emberData.default.hasMany('grouped-report-card-checklist', {
      async: false
    }),
    reportCardImagesWithoutCoverPhoto: computed('groupedReportCardImages.[]', 'coverPhoto.id', function () {
      var images = [];
      var coverPhotoId = this.get('coverPhoto.id');
      this.get('groupedReportCardImages').forEach(function (rci) {
        if (rci.get('id') === coverPhotoId) {
          return;
        }

        images.push(rci);
      });
      return images.sortBy('created_at');
    }),
    sortedChecklists: computed('groupedReportCardChecklists.[]', function () {
      var checklists = this.get('groupedReportCardChecklists');
      return checklists.sortBy('pet.name');
    }),
    send: function send() {
      var url = "/api/v2/grouped_report_cards/".concat(this.get('id'), "/send");
      return this.get('ajax').request(url, {
        method: 'POST'
      });
    },
    downloadImage: function downloadImage(image) {
      window.location = "".concat(_environment.default.APP.host, "/api/v2/grouped_report_cards/").concat(this.get('id'), "/download_image?image_id=").concat(image.get('id'));
    }
  });

  _exports.default = _default;
});