define("frontend/components/appointment-missing-info-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend({
    countryInfo: inject.service(),
    currentUser: inject.service(),
    states: computed.readOnly('countryInfo.states'),
    household: null,
    showPets: false,
    showAddresses: false,
    isPetParent: false,
    willInsertElement: function willInsertElement() {
      this.set('isSaving', false);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('household')) {
        if (this.get('household.pets.firstObject.id')) {
          this.set('showPets', false);
        }

        if (this.get('household.owner.addresses.firstObject.id')) {
          this.set('showAddresses', false);
        }
      }
    },
    infoText: computed('showPets', 'showAddresses', function () {
      if (this.get('showPets') && this.get('showAddresses')) {
        return 'We need to know who the pet is and where they live before you can book an appointment!';
      } else if (this.get('showPets')) {
        return 'We need to know who the pet is before you can book an appointment!';
      } else {
        return 'We need to know where they live before you can book an appointment!';
      }
    }),
    actions: {
      onSubmit: function onSubmit() {
        this.set('isSaving', true);
        this.get('onSubmit')();
      },
      onClose: function onClose() {
        this.get('onClose')();
      },
      changeState: function changeState(state) {
        this.set('household.owner.addresses.firstObject.state', state);
      },
      addPet: function addPet() {
        this.get('addPet')();
      },
      deletePet: function deletePet(pet) {
        this.get('household.pets').removeObject(pet);
      },
      highlightFirstStateMatch: function highlightFirstStateMatch(api) {
        if (api && api.results && api.results.length) {
          return api.results[0];
        }

        return null;
      }
    }
  });

  _exports.default = _default;
});