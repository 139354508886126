define("frontend/routes/admin", ["exports", "frontend/routes/base-route", "jquery"], function (_exports, _baseRoute, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model: function model() {
      return _jquery.default.ajax({
        url: '/api/v2/admin/unapproved',
        method: 'GET'
      });
    }
  });

  _exports.default = _default;
});