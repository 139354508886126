define("frontend/controllers/invoices-summary/payment-detail", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    deletedAdjustments: [],
    invoicesSummary: inject.controller('invoices-summary'),
    addingAdjustment: false,
    companyId: computed.readOnly('company.company_id'),
    editing: false,
    actions: {
      updateDescription: function updateDescription() {
        var _this = this;

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/invoice_payments/").concat(this.get('model.id'), "/update_description"),
          method: 'PUT',
          data: {
            description: this.get('model.description')
          }
        }).done(function () {
          _this.get('model').reload();
        });
      },
      removePayment: function removePayment() {
        this.set('showMoreOptions', false);
        this.get('invoicesSummary').send('deletePayment', this.get('model'));
      },
      cancel: function cancel() {
        this.set('showMoreOptions', false);
        this.get('model').rollbackAttributes();
        this.set('editing', false);
      },
      save: function save() {
        var _this2 = this;

        this.set('showMoreOptions', false);
        this.set('saving', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/invoice_payments/").concat(this.get('model.id'), "/update_payment"),
          method: 'PUT',
          data: {
            invoice_payment: {
              amount: this.get('model.amount'),
              tip_amount: this.get('model.tip_amount'),
              description: this.get('model.description'),
              household_id: this.get('model.household_id'),
              payment_date: this.get('model.payment_date'),
              payment_method: this.get('model.payment_method')
            }
          }
        }).done(function (response) {
          _this2.transitionToRoute('invoices-summary.payment-detail', _this2.get('model.household_id'), response.invoice_payment.id);

          _this2.send('refresh');
        });
      },
      edit: function edit() {
        this.set('editing', true);
        this.set('showMoreOptions', false);
      },
      print: function print() {
        this.set('showMoreOptions', false);
        var w = window.open();

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/invoice_payments/").concat(this.get('model.id')),
          method: 'GET',
          dataType: 'html'
        }).done(function (response) {
          w.document.open();
          w.document.write(response);
          w.document.close();
          $(w).load(function () {
            w.print();
          });
        }).fail(function () {// TODO: Show error dialog?
        });
      }
    }
  });

  _exports.default = _default;
});