define("frontend/initializers/accounting", ["exports", "accounting"], function (_exports, _accounting) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  function initialize() {
    _accounting.default.settings.currency.format = {
      pos: '%s%v',
      // for positive values, eg. "$1.00" (required)
      neg: '(%s%v)',
      // for negative values, eg. "$(1.00)" [optional]
      zero: '%s%v' // for zero values, eg. "$0" [optional]

    };
  }

  var _default = {
    name: 'accounting',
    initialize: initialize
  };
  _exports.default = _default;
});