define("frontend/templates/components/client-profile-info-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FmVqdTXS",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"client-profile-info-item \",[28,\"if\",[[24,[\"lastItem\"]],\"last-item\"],null]]]],[8],[0,\"\\n  \"],[7,\"a\",true],[10,\"class\",\"client-profile-info-item-icon\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n    \"],[7,\"img\",true],[11,\"src\",[29,[[22,\"iconPath\"]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"client-profile-info-item-label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"valueAction\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",false],[12,\"class\",\"client-profile-info-item-value\"],[12,\"style\",[29,[\"font-size: \",[22,\"fontSize\"],\"; font-weight: \",[22,\"fontWeight\"],\"; color: \",[22,\"valueColor\"],\";\"]]],[3,\"action\",[[23,0,[]],[24,[\"valueAction\"]]]],[8],[0,\"\\n        \"],[7,\"div\",true],[8],[1,[22,\"value\"],false],[9],[0,\"\\n        \"],[7,\"img\",true],[10,\"class\",\"contact-info-field__go-to-link\"],[10,\"src\",\"assets/images/go-to-link.png\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"client-profile-info-item-value\"],[11,\"style\",[29,[\"font-size: \",[22,\"fontSize\"],\"; font-weight: \",[22,\"fontWeight\"],\"; color: \",[22,\"valueColor\"],\";\"]]],[8],[0,\"\\n        \"],[1,[22,\"value\"],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/client-profile-info-item.hbs"
    }
  });

  _exports.default = _default;
});