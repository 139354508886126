define("frontend/templates/components/holiday-surcharge-holiday-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "K17AdcXE",
    "block": "{\"symbols\":[\"holiday\"],\"statements\":[[4,\"each\",[[24,[\"holidayDates\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"holiday-surcharge-holiday-list-item\",null,[[\"holidaySurchargePolicy\",\"holidayName\",\"shortenedName\",\"icon\",\"holidayDate\",\"showSwitches\",\"defaultSelectedDates\"],[[24,[\"holidaySurchargePolicy\"]],[23,1,[\"label\"]],[23,1,[\"shortenedName\"]],[23,1,[\"iconName\"]],[23,1,[\"date\"]],[24,[\"showSwitches\"]],[23,1,[\"defaultSelected\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/holiday-surcharge-holiday-list.hbs"
    }
  });

  _exports.default = _default;
});