define("frontend/routes/client-list/household/billing", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = _baseRoute.default.extend({
    currentUser: inject.service(),
    cookies: inject.service(),
    model: function model() {
      var household = this._super.apply(this, arguments);

      var invoiceFrequencies = this.store.query('invoice-frequency', {
        reload: true
      });
      return Ember.RSVP.hash({
        household: household,
        invoiceFrequencies: invoiceFrequencies
      });
    },
    afterModel: function afterModel() {
      if (this.get('cookies').exists('clientListAddedUser')) {
        this.set('addedUser', true);
        this.get('cookies').clear('clientListAddedUser', {
          path: '/'
        });
      } else {
        this.set('addedUser', false);
      }
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('anySidePanelActive', false);
      controller.set('selectedAutoPay', null);
    },
    actions: {
      reloadOwner: function reloadOwner() {
        this.get('controller.model.household').reload();
      },
      reloadHousehold: function reloadHousehold() {
        this.get('controller.model.household').reload();
      }
    }
  });

  _exports.default = _default;
});