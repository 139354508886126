define("frontend/models/invoice-frequency", ["exports", "ember-data", "frontend/utils/invoice-frequency-options"], function (_exports, _emberData, _invoiceFrequencyOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    payment_reminder_frequency: _emberData.default.attr('string'),
    displayName: computed('name', function () {
      var name = this.get('name');

      switch (name) {
        case _invoiceFrequencyOptions.default.DAILY:
          return 'Daily';

        case _invoiceFrequencyOptions.default.BIWEEKLY:
          return 'Every other week';

        case _invoiceFrequencyOptions.default.SEMIMONTHLY:
          return '1st and 15th each month';

        case _invoiceFrequencyOptions.default.WEEKLY:
          return 'Weekly';

        case _invoiceFrequencyOptions.default.MONTHLY:
          return 'Monthly';

        default:
          return name;
      }
    })
  });

  _exports.default = _default;
});