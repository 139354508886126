define("frontend/models/pet_appointment", ["exports", "frontend/models/appointment"], function (_exports, _appointment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _appointment.default.extend({});

  _exports.default = _default;
});