define("frontend/controllers/edit-recurring-appointment-group/index", ["exports", "frontend/utils/scroll-util", "frontend/utils/generate-dates", "moment"], function (_exports, _scrollUtil, _generateDates, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    nonAddOnOfferedServices: computed('model.offeredServices.[]', function () {
      return this.get('model.offeredServices').filter(function (offeredService) {
        return !offeredService.get('is_add_on');
      });
    }),
    sortedOfferedServices: computed.sort('nonAddOnOfferedServices', function (a, b) {
      if (a.get('service_category_position') > b.get('service_category_position')) {
        return 1;
      } else if (a.get('service_category_position') < b.get('service_category_position')) {
        return -1;
      } else {
        if (a.get('position') > b.get('position')) {
          return 1;
        } else if (a.get('position') < b.get('position')) {
          return -1;
        }

        return 0;
      }
    }),
    recurringAppointmentGroup: computed.alias('model.recurringAppointmentGroup'),
    recurringAppointments: computed.alias('recurringAppointmentGroup.recurring_appointments'),
    monthlyRecurringAppointment: computed.equal('recurringAppointments.firstObject.frequency', 'monthly'),
    endDateEndsOnDate: computed.equal('selectedEndDateOption', 'date'),
    editingExistingAppointment: false,
    sidePanelOpen: computed.notEmpty('sidePanelAppointment'),
    deletedRecurringAppointments: [],
    editRecurringAppointmentGroup: inject.controller(),
    backRoute: computed.readOnly('editRecurringAppointmentGroup.backRoute'),
    backHouseholdId: computed.readOnly('editRecurringAppointmentGroup.backHouseholdId'),
    uniquePets: computed.readOnly('recurringAppointmentGroup.pets'),
    saving: computed.readOnly('editRecurringAppointmentGroup.saving'),
    showErrors: false,
    requireDateError: false,
    currentUser: Ember.inject.service(),
    invalidAppointments: computed('sortedRepeatWeekDays.[]', function () {
      var noDates = this.get('sortedRepeatWeekDays.length') === 0;
      var noAppointmentsOnDate = this.get('sortedRepeatWeekDays').any(function (sortedRepeatWeekDay) {
        return sortedRepeatWeekDay.allAppointments.length === 0;
      });
      return noDates || noAppointmentsOnDate;
    }),
    validAppointments: computed.not('invalidAppointments'),
    repeatsOptions: [{
      value: 'weekly',
      label: 'Weekly'
    }, {
      value: 'biweekly',
      label: 'Every other week'
    }],
    weekDays: computed('startDate', function () {
      var start = (0, _moment.default)().startOf('week');
      var end = (0, _moment.default)().endOf('week');

      if (this.get('startDate')) {
        start = (0, _moment.default)(this.get('startDate')).startOf('week');
        end = (0, _moment.default)(this.get('startDate')).endOf('week');
      }

      var days = (0, _generateDates.default)(start, end);
      return days;
    }),
    sortedWeekDays: computed('weekDays.[]', function () {
      var weekDays = this.get('weekDays');
      return weekDays.sort(function (x, y) {
        if ((0, _moment.default)(x).day() == 0) {
          return 1;
        } else if ((0, _moment.default)(y).day() == 0) {
          return -1;
        } else {
          if (x > y) {
            return 1;
          } else {
            return -1;
          }
        }
      });
    }),
    assignStaffSidePanelHeader: computed('sidePanelAppointment.date', function () {
      return "".concat((0, _moment.default)(this.get('sidePanelAppointment.date')).format('dddd'), " appointment options");
    }),
    headerDateFormat: computed('monthlyRecurringAppointment', function () {
      if (this.get('monthlyRecurringAppointment')) {
        return '[1st of each month]';
      }

      return 'dddd[s]';
    }),
    sortDateAsc: function sortDateAsc(firstDate, secondDate) {
      var momentFirstDate = (0, _moment.default)(firstDate).day();
      var momentSecondDate = (0, _moment.default)(secondDate).day();

      if (momentFirstDate == 0) {
        momentFirstDate = 7;
      }

      if (momentSecondDate == 0) {
        momentSecondDate = 7;
      }

      if (momentFirstDate > momentSecondDate) {
        return 1;
      } else if (momentFirstDate < momentSecondDate) {
        return -1;
      } else {
        return 0;
      }
    },
    dateWithAppointments: function dateWithAppointments(date) {
      var appointments = this.get('recurringAppointments').filter(function (appointment) {
        return (0, _moment.default)(appointment.get('start_date')).day() === (0, _moment.default)(date).day();
      });
      var appointmentsByPet = {};
      appointments.forEach(function (appointment) {
        var petId = appointment.get('pet.id');

        if (!appointmentsByPet[petId]) {
          appointmentsByPet[petId] = [];
        }

        appointmentsByPet[petId].pushObject(appointment);
      });
      var firstPetId = appointments.get('firstObject.pet.id');
      return {
        date: date,
        appointments: appointmentsByPet[firstPetId],
        allAppointments: appointments
      };
    },
    startDateFieldText: computed('startDate', function () {
      var startDate = this.get('startDate');

      if (startDate) {
        return (0, _moment.default)(startDate, 'YYYY-MM-DD').format('dddd, MMM D, YYYY');
      }

      return null;
    }),
    sidePanelStartDate: computed('startDate', function () {
      var startDate = this.get('startDate');

      if (startDate) {
        return startDate;
      }

      return (0, _moment.default)().format('YYYY-MM-DD');
    }),
    sortedRepeatWeekDays: computed('selectedRepeatWeekDays.[]', 'recurringAppointments.[]', 'recurringAppointments.@each.recurring_appointment_add_on_services.[]', function () {
      return this.get('selectedRepeatWeekDays').sort(this.sortDateAsc).map(this.dateWithAppointments.bind(this));
    }),
    selectedDaysOfWeek: computed('selectedRepeatWeekDays.[]', function () {
      return this.get('selectedRepeatWeekDays').map(function (day) {
        return (0, _moment.default)(day).day();
      }).sort();
    }),
    endDateOptions: computed('endDate', 'endDateEndsOnDate', function () {
      var endDate = (0, _moment.default)(this.get('endDate'), 'YYYY-MM-DD').format('dddd, MMM D, YYYY');

      if (this.get('endDateEndsOnDate')) {
        return [{
          value: 'never',
          label: 'Never'
        }, {
          value: 'date',
          label: endDate
        }];
      } else {
        return [{
          value: 'never',
          label: 'Never'
        }, {
          value: 'date',
          label: 'On date'
        }];
      }
    }),
    offeredServiceOptions: computed.map('sortedOfferedServices', function (offeredService) {
      return {
        label: offeredService.get('nameWithDuration'),
        value: offeredService.get('id')
      };
    }),
    allActiveAppointments: computed('activeAppointment', 'recurringAppointments.[]', function () {
      var activeAppointment = this.get('activeAppointment');
      var groupAppointments = this.get('recurringAppointments').filterBy('recurring_appointment_group_id', activeAppointment.get('recurring_appointment_group_id'));
      return groupAppointments.filter(function (recurringAppointment) {
        return recurringAppointment.get('offered_service_id') === activeAppointment.get('offered_service_id') && recurringAppointment.get('careProvider.id') === activeAppointment.get('careProvider.id') && recurringAppointment.get('start_date') === activeAppointment.get('start_date') && recurringAppointment.get('preferred_start_time') === activeAppointment.get('preferred_start_time');
      });
    }),
    madeChanges: computed('selectedRepeatOption', 'selectedDaysOfWeek.[]', 'recurringAppointments.[]', 'recurringAppointments.@each.{offered_service_id,careProvider.id,start_date,preferred_start_time,frequency}', 'startDate', function () {
      var _this = this;

      var originalDoWs = this.get('originalDaysOfWeek');
      var selectedDoWs = this.get('selectedDaysOfWeek');

      if (originalDoWs.length !== selectedDoWs.length || !originalDoWs.every(function (value, index) {
        return value === selectedDoWs[index];
      })) {
        return true;
      }

      var recurringAppointments = this.get('recurringAppointments');

      if (recurringAppointments.get('length') != this.get('originalRecurringAppointmentCount')) {
        return true;
      }

      if (recurringAppointments.toArray().some(function (appt) {
        return appt.get('frequency') !== _this.get('selectedRepeatOption');
      })) {
        return true;
      }

      var startDate = this.get('startDate');

      if (startDate && recurringAppointments.sortBy('start_date').get('firstObject.start_date') !== startDate) {
        return true;
      }

      var hasChanges = false;
      recurringAppointments.forEach(function (ra) {
        return hasChanges = hasChanges || ra.get('hasDirtyAttributes');
      });
      return hasChanges;
    }),
    actions: {
      clickDay: function clickDay(day) {
        var _this2 = this;

        if (this.get('selectedRepeatWeekDays').includes(day)) {
          var _this$dateWithAppoint = this.dateWithAppointments(day),
              allAppointments = _this$dateWithAppoint.allAppointments;

          allAppointments.forEach(function (appointment) {
            _this2.get('deletedRecurringAppointments').pushObject(appointment);

            appointment.deleteRecord();
          });
          this.get('selectedRepeatWeekDays').removeObject(day);
        } else {
          var _this$dateWithAppoint2 = this.dateWithAppointments(day),
              _allAppointments = _this$dateWithAppoint2.allAppointments;

          _allAppointments.forEach(function (appointment) {
            _this2.get('deletedRecurringAppointments').removeObject(appointment);
          });

          this.get('selectedRepeatWeekDays').pushObject(day);
        }
      },
      closeAppointmentDateSidePanel: function closeAppointmentDateSidePanel() {
        this.set('appointmentDateSidePanelOpen', false);
      },
      openAppointmentDateSidePanel: function openAppointmentDateSidePanel() {
        this.set('appointmentDateSidePanelOpen', true);
      },
      selectEndDate: function selectEndDate(value) {
        var onDateSelected = value === 'date';
        this.set('endSidePanelOpen', onDateSelected);

        if (!onDateSelected) {
          this.set('endDate', (0, _moment.default)().format('YYYY-MM-DD'));
        }
      },
      addNewAppointment: function addNewAppointment(date) {
        var careProvider = this.get('model.careProviders').filterBy('id', this.get('currentUser.user.id')).get('firstObject');
        var offered_service = this.get('sortedOfferedServices.firstObject');
        this.set('activeAppointment', null);
        this.set('editingExistingAppointment', false);
        var time = offered_service.get('all_day_service') ? '12:00 AM' : null;
        var addOnServices = [];
        this.set('sidePanelAppointment', {
          date: date,
          careProvider: careProvider,
          time: time,
          offered_service: offered_service,
          addOnServices: addOnServices
        });
      },
      editExistingAppointment: function editExistingAppointment(appointment, date) {
        this.set('editingExistingAppointment', true);
        this.set('activeAppointment', appointment);
        var careProviderId = appointment.get('careProvider.id');
        var careProvider = this.get('model.careProviders').filterBy('id', careProviderId).get('firstObject');

        if (!date) {
          date = appointment.get('start_date');
        }

        var time = appointment.get('time');
        var offered_service = appointment.get('offered_service');
        var timeFrameStart = appointment.get('timeFrameStart');
        var timeFrameEnd = appointment.get('timeFrameEnd');
        var setByCustomWindow = appointment.get('set_by_custom_window');
        var positionsPreviousPets = appointment.get('positions_previous_pets');
        var ras = this.get('recurringAppointments').filter(function (ra) {
          return (0, _moment.default)(appointment.get('start_date')).day() === (0, _moment.default)(ra.get('start_date')).day() && appointment.get('offered_service.id') === ra.get('offered_service.id') && appointment.get('careProvider.id') === ra.get('careProvider.id') && appointment.get('preferred_start_time') === ra.get('preferred_start_time') && appointment.get('timeFrameStart') === ra.get('timeFrameStart') && appointment.get('timeFrameEnd') === ra.get('timeFrameEnd');
        });
        var addOnServices = {};
        ras.forEach(function (ra) {
          ra.get('recurring_appointment_add_on_services').forEach(function (raaos) {
            var osId = raaos.get('offered_service.id');

            if (!addOnServices[osId]) {
              addOnServices[osId] = Ember.Object.create({
                offered_service: raaos.get('offered_service'),
                petIds: [],
                petNames: []
              });
            }

            addOnServices[osId].petIds.pushObject(ra.get('pet.id'));
            addOnServices[osId].petNames.pushObject(ra.get('pet.name'));
          });
        });
        addOnServices = Object.values(addOnServices);
        this.set('sidePanelAppointment', {
          date: date,
          careProvider: careProvider,
          time: time,
          offered_service: offered_service,
          timeFrameStart: timeFrameStart,
          timeFrameEnd: timeFrameEnd,
          setByCustomWindow: setByCustomWindow,
          positionsPreviousPets: positionsPreviousPets,
          addOnServices: addOnServices
        });
      },
      removeAppointment: function removeAppointment() {
        var _this3 = this;

        var allActiveAppointments = this.get('allActiveAppointments');
        allActiveAppointments.forEach(function (appointment) {
          _this3.get('deletedRecurringAppointments').pushObject(appointment);

          _this3.get('recurringAppointments').removeObject(appointment);

          appointment.deleteRecord();
        });
        this.send('hideSidePanel');
      },
      changeEndDate: function changeEndDate(date) {
        this.send('closeEndSidePanel');
        this.set('endDate', (0, _moment.default)(date).format('YYYY-MM-DD'));
      },
      closeEndSidePanel: function closeEndSidePanel() {
        this.set('endSidePanelOpen', false);
      },
      openEndSidePanel: function openEndSidePanel() {
        this.set('endSidePanelOpen', true);
      },
      changeStartDate: function changeStartDate(date) {
        var _this4 = this;

        this.set('requireDateError', false);
        this.send('closeStartSidePanel');
        var startDate = (0, _moment.default)(date).format('YYYY-MM-DD');
        this.set('startDate', startDate);
        var start = (0, _moment.default)(startDate).startOf('week').add(1, 'day');
        var end = (0, _moment.default)(startDate).endOf('week').add(1, 'day');
        var days = (0, _generateDates.default)(start, end);
        this.set('weekDays', days);
        var oldDates = this.get('selectedRepeatWeekDays');
        this.set('selectedRepeatWeekDays', []);
        oldDates.forEach(function (oldDay) {
          days.forEach(function (newDay) {
            if ((0, _moment.default)(oldDay).day() == (0, _moment.default)(newDay).day()) {
              _this4.send('clickDay', newDay);
            }
          });
        });
      },
      closeStartSidePanel: function closeStartSidePanel() {
        this.set('startSidePanelOpen', false);
      },
      openStartSidePanel: function openStartSidePanel() {
        this.set('startSidePanelOpen', true);
      },
      updateAppointment: function updateAppointment(appointmentProps) {
        var _this5 = this;

        var preferred_start_time = _moment.default.utc(appointmentProps.time, 'h:mm A');

        var props = {
          preferred_start_time: preferred_start_time,
          offered_service: appointmentProps.offered_service,
          careProvider: appointmentProps.careProvider,
          timeFrameStart: appointmentProps.timeFrameStart,
          timeFrameEnd: appointmentProps.timeFrameEnd,
          set_by_custom_window: appointmentProps.setByCustomWindow,
          positions_previous_pets: appointmentProps.positionsPreviousPets
        };
        var allActiveAppointments = this.get('allActiveAppointments');
        allActiveAppointments.forEach(function (appointment) {
          appointment.setProperties(props);
          var petAddOns = appointmentProps.addOnServices.filter(function (addOnService) {
            return addOnService.petIds.includes(appointment.get('pet.id'));
          });
          var newAddOns = petAddOns.map(function (addOnService) {
            return _this5.store.createRecord('recurring-appointment-add-on-service', {
              offered_service: addOnService.offered_service,
              recurring_appointment: appointment
            });
          });
          appointment.setProperties({
            recurring_appointment_add_on_services: newAddOns
          });
        });
        this.send('hideSidePanel');
      },
      createRecurringAddOnService: function createRecurringAddOnService(pets) {
        var petIds = pets.map(function (pet) {
          return pet.get('id');
        });
        var petNames = pets.map(function (pet) {
          return pet.get('name');
        });
        return Ember.Object.create({
          offered_service: null,
          petIds: petIds,
          petNames: petNames
        });
      },
      createAppointment: function createAppointment(appointmentProps) {
        var _this6 = this;

        var careProvider = appointmentProps.careProvider,
            date = appointmentProps.date,
            time = appointmentProps.time,
            timeFrameStart = appointmentProps.timeFrameStart,
            timeFrameEnd = appointmentProps.timeFrameEnd,
            setByCustomWindow = appointmentProps.setByCustomWindow,
            positionsPreviousPets = appointmentProps.positionsPreviousPets,
            addOnServices = appointmentProps.addOnServices;
        date = (0, _moment.default)(date).format('YYYY-MM-DD');
        time = _moment.default.utc(time, 'h:mm A').format();
        var offered_service = appointmentProps.offered_service;
        var day_of_week = (0, _moment.default)(date).day();
        this.get('uniquePets').forEach(function (pet) {
          var recurringAppointment = _this6.store.createRecord('recurring-appointment', {
            careProvider: careProvider,
            preferred_start_time: time,
            offered_service: offered_service,
            day_of_week: day_of_week,
            recurrence_ends_on_type: _this6.get('selectedEndDateOption'),
            frequency: _this6.get('selectedRepeatOption'),
            start_date: date,
            pet: pet,
            timeFrameStart: timeFrameStart,
            timeFrameEnd: timeFrameEnd,
            set_by_custom_window: setByCustomWindow,
            positions_previous_pets: positionsPreviousPets
          });

          var petAddOns = addOnServices.filter(function (addOnService) {
            return addOnService.petIds.includes(pet.get('id'));
          });
          petAddOns.forEach(function (addOnService) {
            var apptAddOnService = _this6.store.createRecord('recurring-appointment-add-on-service', {
              offered_service: addOnService.offered_service,
              recurring_appointment: recurringAppointment
            });

            recurringAppointment.get('recurring_appointment_add_on_services').pushObject(apptAddOnService);
          });

          _this6.get('recurringAppointments').pushObject(recurringAppointment);
        });
        this.send('hideSidePanel');
      },
      hideSidePanel: function hideSidePanel() {
        this.set('editingExistingAppointment', false);
        this.set('sidePanelAppointment', null);
        this.set('activeAppointment', null);
      },
      save: function save() {
        this.set('showErrors', true);
        this.set('requireDateError', false);
        var valid = this.get('validAppointments');
        var madeChanges = this.get('madeChanges');
        var madeChangesToEndDate = this.get('endDate') != this.get('originalEndDate');

        if (!madeChanges && !madeChangesToEndDate) {
          this.send('cancel', this.get('model.recurringAppointmentGroup'), this.get('backRoute'), this.get('backHouseholdId'));
          return;
        }

        if (valid && (madeChanges && this.get('startDate') || !madeChanges)) {
          var _this$get;

          (_this$get = this.get('editRecurringAppointmentGroup')).send.apply(_this$get, ['save'].concat(Array.prototype.slice.call(arguments)));
        } else {
          if (!this.get('startDate')) {
            this.set('requireDateError', true);
          }

          _scrollUtil.default.scrollTopWithHeader('.red-validation-error, .assign-staff-date--error');
        }
      }
    }
  });

  _exports.default = _default;
});