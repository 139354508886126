define("frontend/components/appointment-staff-time", ["exports", "ember-data", "jquery"], function (_exports, _emberData, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['appointment-staff-time'],
    classNameBindings: ['active'],
    appointment: null,
    active: false,
    showService: false,
    offeredService: null,
    allRecurringAppointments: null,
    date: null,
    store: inject.service(),
    addOnServices: computed('appointment.addOnServices.[]', 'appointment.recurring_appointment_add_on_services.[]', 'allRecurringAppointments.[]', function () {
      if (this.get('allRecurringAppointments')) {
        var appointment = this.get('appointment');
        var ras = this.get('allRecurringAppointments').filter(function (ra) {
          return moment(appointment.get('start_date')).day() === moment(ra.get('start_date')).day() && appointment.get('offered_service.id') === ra.get('offered_service.id') && appointment.get('careProvider.id') === ra.get('careProvider.id') && appointment.get('preferred_start_time') === ra.get('preferred_start_time') && appointment.get('timeFrameStart') === ra.get('timeFrameStart') && appointment.get('timeFrameEnd') === ra.get('timeFrameEnd');
        });
        var addOnServices = {};
        ras.forEach(function (ra) {
          ra.get('recurring_appointment_add_on_services').forEach(function (raaos) {
            var osId = raaos.get('offered_service.id');

            if (!addOnServices[osId]) {
              addOnServices[osId] = {
                offered_service: raaos.get('offered_service'),
                petIds: [],
                petNames: []
              };
            }

            addOnServices[osId].petIds.pushObject(ra.get('pet.id'));
            addOnServices[osId].petNames.pushObject(ra.get('pet.name'));
          });
        });
        return Object.values(addOnServices);
      } else {
        return this.get('appointment.addOnServices') || this.get('appointment.recurring_appointment_add_on_services');
      }
    }),
    appointmentsWithRecurrence: computed('service.appointmentsWithRecurrence.[]', 'pets.[]', function () {
      var _this = this;

      return this.get('service.appointmentsWithRecurrence').reject(function (appointment) {
        return _this.get('pets').mapBy('id').indexOf(appointment.get('pet.id')) >= 0;
      });
    }),
    recurringAppointmentGroup: computed('date', 'appointment.date', 'appointment.start_date', 'appointment.time', 'appointment.careProvider.id', function () {
      var time = this.get('appointment.time');
      var care_provider_id = this.get('appointment.careProvider.id');
      var date = this.get('date');

      var promise = _jquery.default.ajax({
        url: '/api/v2/recurring_appointments/slot',
        method: 'GET',
        data: {
          time: time,
          care_provider_id: care_provider_id,
          date: date
        }
      }).then(function (resp) {
        return resp;
      });

      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    recurringGroupedAppointments: computed('recurringAppointmentGroup.[]', 'appointment.positionsPreviousPets', 'pets.[]', function () {
      var _this2 = this;

      var groupedAppointments = this.get('recurringAppointmentGroup');

      if (!groupedAppointments.isFulfilled) {
        return;
      }

      groupedAppointments = groupedAppointments.map(function (groupedAppt) {
        var selected = false;
        var appointments = groupedAppt.map(function (appt) {
          if (parseInt(_this2.get('appointment.id')) == appt.recurring_appointment_id) {
            selected = true;
          }

          return {
            recurring_appointment_id: appt.recurring_appointment_id,
            pet: _this2.get('store').findRecord('pet', appt.pet.id),
            offered_service_color_hex: appt.offered_service_color_hex
          };
        });
        var petNames = groupedAppt.map(function (appt) {
          return appt.pet.name;
        });
        petNames = petNames.join('/');
        return {
          appointments: appointments,
          petNames: petNames,
          selected: selected
        };
      });

      if (this.get('appointment.positions_previous_pets')) {
        groupedAppointments = groupedAppointments.reject(function (ga) {
          return ga.appointments.map(function (a) {
            return a.recurring_appointment_id;
          }).includes(parseInt(_this2.get('appointment.id')));
        });
      }

      return this.addInAppointment(groupedAppointments);
    }),
    service: computed('date', 'appointment.date', 'appointment.careProvider.id', 'appointment.time', function () {
      var _this3 = this;

      var care_provider_id = this.get('appointment.careProvider.id');
      var start_date = this.get('date') || this.get('appointment.date') || this.get('appointment.start_date');
      var end_date = this.get('date') || this.get('appointment.date') || this.get('appointment.start_date');
      var promise = this.get('store').query('service', {
        care_provider_id: care_provider_id,
        start_date: start_date,
        end_date: end_date
      }).then(function (services) {
        return services.filter(function (service) {
          return _this3.get('appointment.time') === service.get('starts_at_time');
        }).get('firstObject');
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    oneTimeGroupedAppointments: computed('service', 'appointment.positionsPreviousPets', 'pets.[]', function () {
      var groupedAppointments = this.get('service.sortedGroupedAppointmentsWithoutCanceled');
      groupedAppointments = groupedAppointments.map(function (groupedAppt) {
        var appointments = groupedAppt.get('sortedAppointments').map(function (appt) {
          return {
            pet: appt.get('pet'),
            offered_service_color_hex: appt.get('offered_service_color_hex')
          };
        });
        var petNames = groupedAppt.get('sortedAppointments').map(function (appt) {
          return appt.get('pet.name');
        });
        petNames = petNames.join('/');
        return {
          appointments: appointments,
          petNames: petNames
        };
      });
      return this.addInAppointment(groupedAppointments);
    }),
    addInAppointment: function addInAppointment(groupedAppointments) {
      var _this4 = this;

      var appt = this.get('appointment');
      var previousPets = appt.positionsPreviousPets || appt.get('positions_previous_pets');

      if (!previousPets) {
        return groupedAppointments;
      }

      var position = 0;

      if (previousPets == 'first') {
        position = 0;
      } else if (previousPets == 'last') {
        position = groupedAppointments.length;
      } else {
        groupedAppointments.forEach(function (groupedAppt, index) {
          if (groupedAppt.petNames == previousPets) {
            position = index + 1;
          }
        });
      }

      var appointments = this.get('pets').map(function (pet) {
        return {
          pet: pet,
          offered_service_color_hex: _this4.get('offeredService.color_hex')
        };
      });
      groupedAppointments.insertAt(position, {
        appointments: appointments,
        selected: true
      });
      return groupedAppointments;
    }
  });

  _exports.default = _default;
});