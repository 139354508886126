define("frontend/controllers/client-list/household/billing", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    queryParams: ['oauth_state_id'],
    oauth_state_id: null,
    oauthStatePresent: computed.notEmpty('oauth_state_id'),
    plaidCookiePresent: computed.notEmpty('plaidCookie'),
    plaidRedirect: computed.and('oauthStatePresent', 'plaidCookiePresent'),
    anySidePanelActive: false,
    saving: false,
    showSendContractsModal: false,
    errorHandler: inject.service(),
    currentUser: inject.service(),
    flashMessage: inject.service(),
    cookies: inject.service(),
    plaidCookie: computed('cookies', function () {
      return this.get('cookies').read('plaidLinkToken');
    }),
    actions: {
      saveHouseholdUser: function saveHouseholdUser() {
        var _this = this;

        var reload = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.get('model.household.owner').save().then(function () {
          if (reload) {
            _this.get('model.household').reload();
          }
        }).catch(function (error) {
          _this.get('errorHandler').handle(error);
        });
      },
      setSaving: function setSaving() {
        this.set('saving', true);
      },
      closeSendContractsModal: function closeSendContractsModal() {
        this.set('showSendContractsModal', false);
      },
      sendContracts: function sendContracts() {
        var _this2 = this;

        var householdId = this.get('model.household.id');
        this.set('showSendContractsModal', false);

        _jquery.default.ajax({
          url: "/api/v2/client_contracts/send_client_contracts",
          method: 'POST',
          data: {
            household_id: householdId
          }
        }).then(function () {
          _this2.set('model.household.esignature_status', 'sent');

          _this2.get('flashMessage').sendFlashMessage("Your company agreements have been successfully sent to the ".concat(_this2.get('model.household.owner.last_name'), " family"));
        });
      },
      redirectToMicrodeposits: function redirectToMicrodeposits() {
        this.transitionToRoute('microdeposits', this.get('model.household.owner.emd5'));
      }
    }
  });

  _exports.default = _default;
});