define("frontend/components/invoice-item-header/invoice-date-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['invoice-date-number']
  });

  _exports.default = _default;
});