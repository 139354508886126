define("frontend/templates/components/request-or-approve-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gEJpXAsq",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,[[\"onClose\"],[[28,\"action\",[[23,0,[]],[24,[\"onClose\"]]],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content centered\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[1,[28,\"inline-svg\",[\"assets/images/cashregister.svg\"],null],false],[9],[0,\"\\n      \"],[7,\"header\",true],[8],[0,\"Request or Approve?\"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red-white-border\",\"send request to staff\",[28,\"action\",[[23,0,[]],\"requestAppointments\"],null]]]],false],[0,\"\\n    \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red-white-border\",[24,[\"approveText\"]],[28,\"action\",[[23,0,[]],\"approveAppointments\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"ppb-modal-cancel\"],[8],[0,\"\\n    \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red-white\",\"cancel\",[28,\"action\",[[23,0,[]],[24,[\"onClose\"]]],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/request-or-approve-modal.hbs"
    }
  });

  _exports.default = _default;
});