define("frontend/templates/edit-client/add-pet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r+5z2ZPF",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"add-pet add-edit-page\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"showAddPetPrompt\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"add-client/add-pet-prompt\",null,[[\"yes\",\"skip\"],[[28,\"action\",[[23,0,[]],\"addPet\"],null],[28,\"action\",[[23,0,[]],\"skipAddPet\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"unless\",[[28,\"media\",[\"isMobile\"],null],\"pull-left\"],null],\" text-center\"]]],[8],[0,\"\\n      \"],[1,[28,\"avatar-upload\",null,[[\"placeholder\",\"photo\",\"uploadedPhoto\"],[[24,[\"avatarPlaceholder\"]],[24,[\"model\",\"avatar_url\"]],[28,\"action\",[[23,0,[]],\"uploadedPhoto\"],null]]]],false],[0,\"\\n\"],[4,\"unless\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"full-name\"],[8],[1,[24,[\"model\",\"name\"]],false],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"id\",\"tab-side-panel-wormhole-container\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\\n\"],[4,\"add-client/pet-inputs\",null,[[\"onSubmit\",\"pet\"],[[28,\"route-action\",[\"save\",[24,[\"model\"]]],null],[24,[\"model\"]]]],{\"statements\":[[0,\"      \"],[1,[28,\"adaptive-navigation-buttons\",null,[[\"clickRight\",\"clickLeft\",\"rightText\",\"leftText\"],[[23,1,[\"onSubmit\"]],[28,\"route-action\",[\"cancel\"],null],\"Save\",\"Cancel\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/edit-client/add-pet.hbs"
    }
  });

  _exports.default = _default;
});