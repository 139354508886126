define("frontend/components/amount-to-pay-by-service", ["exports", "ember-data", "ember-cp-validations", "jquery"], function (_exports, _emberData, _emberCpValidations, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var Validations = (0, _emberCpValidations.buildValidations)({
    'amount': [(0, _emberCpValidations.validator)('number', {
      allowString: true
    }), (0, _emberCpValidations.validator)('presence', {
      presence: true
    })],
    'additional_dog_amount': [(0, _emberCpValidations.validator)('number', {
      allowString: true
    }), (0, _emberCpValidations.validator)('additional-dog-amount')]
  });
  var computed = Ember.computed,
      service = Ember.inject.service;

  var _default = Ember.Component.extend({
    classNames: ['amount-to-pay-by-service'],
    store: service(),
    compensationType: null,
    offeredServiceAmounts: [],
    isInvalid: computed.not('isValid'),
    options: [{
      label: 'Yes',
      value: true
    }, {
      label: 'No',
      value: false
    }],
    saveClicked: false,
    // Makes an API call for all offered services and fills in pre existing offeredServiceAmounts
    offeredServiceAmountsCopy: computed('offeredServiceAmounts', function () {
      var _this = this;

      var hasVariablePrices = this.get('offeredServiceAmounts.length') > 0;
      var osas = this.get('offeredServiceAmounts') || [];
      var promise = this.get('store').query('offered-service', {}, {
        reload: true
      }).then(function (offeredServices) {
        var amounts = [];
        offeredServices.forEach(function (offeredService) {
          var osa = osas.findBy('offered_service_id', +offeredService.id);

          if (!osa) {
            var amount = hasVariablePrices ? 0 : null;
            var additional_dog_amount = null;
            var offeredServiceAmount = Ember.Object.extend(Validations).create(Ember.getOwner(_this).ownerInjection(), {
              offered_service_id: +offeredService.id,
              offered_service_name: offeredService.get('nameWithDuration'),
              position: offeredService.get('position'),
              is_add_on: offeredService.get('is_add_on'),
              amount: amount,
              additional_dog_amount: additional_dog_amount,
              employee: _this.get('employee')
            });
            amounts.pushObject(offeredServiceAmount);
          } else {
            var osaWithValidation = Ember.Object.extend(Validations).create(Ember.getOwner(_this).ownerInjection(), osa);
            amounts.push(osaWithValidation);
          }
        });
        return amounts;
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    isValid: computed('offeredServiceAmountsCopy.@each.amount', 'offeredServiceAmountsCopy.@each.additional_dog_amount', function () {
      if (!this.get('offeredServiceAmountsCopy.content')) {
        return false;
      }

      return this.get('offeredServiceAmountsCopy').every(function (offeredServiceAmount) {
        return offeredServiceAmount.get('validations.isValid');
      });
    }),
    actions: {
      selectDifferForAdditionalDogs: function selectDifferForAdditionalDogs(option) {
        this.set('employee.differs_for_additional_dogs', option);

        if (option === false) {
          var offeredServiceAmounts = this.get('offeredServiceAmountsCopy');
          offeredServiceAmounts.forEach(function (offeredServiceAmount) {
            Ember.set(offeredServiceAmount, 'additional_dog_amount', 0);
          });
        }
      },
      close: function close() {
        this.close();
      },
      save: function save() {
        if (this.get('isValid')) {
          var offeredServiceAmounts = this.get('offeredServiceAmountsCopy.content').map(function (offeredServiceAmount) {
            var offered_service_id = offeredServiceAmount.offered_service_id,
                offered_service_name = offeredServiceAmount.offered_service_name;
            var amount = Number(offeredServiceAmount.amount);
            var additional_dog_amount = Number(offeredServiceAmount.additional_dog_amount);
            return Ember.Object.create({
              offered_service_id: offered_service_id,
              offered_service_name: offered_service_name,
              amount: amount,
              additional_dog_amount: additional_dog_amount
            });
          });
          this.save(offeredServiceAmounts);
        } else {
          this.set('saveClicked', true);

          var _jQuery$first = (0, _jquery.default)('.red-validation-error').first(),
              _jQuery$first2 = _slicedToArray(_jQuery$first, 1),
              ele = _jQuery$first2[0];

          if (!ele) {
            return;
          }

          ele.scrollIntoView({
            block: 'center'
          });
        }
      }
    }
  });

  _exports.default = _default;
});