define("frontend/components/company-payment-method-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    company: null,

    /**
     * Determines whether the modal allowing to choose Plaid or manual routing/account entry is shown
     */
    showModal: true,

    /**
     * Determines whether the manual routing/account entry is shown
     */
    showBankAccountModal: false,
    didInsertElement: function didInsertElement() {
      if (!this.get('company.allows_plaid')) {
        this.set('showModal', false);
        this.set('showBankAccountModal', true);
      }
    },
    actions: {
      onClose: function onClose() {
        this.get('onClose')();
        this.set('showModal', false);
        this.set('showBankAccountModal', false);
      },
      saveBankAccount: function saveBankAccount(token, account_id, nickname) {
        var manual_bank_account = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
        this.get('saveBankAccount')(token, account_id, nickname, manual_bank_account);
        this.set('showModal', false);
        this.set('showBankAccountModal', false);
      },
      manualBankAccountSelected: function manualBankAccountSelected() {
        this.set('showModal', false);
        this.set('showBankAccountModal', true);
      },
      onPlaidOpen: function onPlaidOpen() {
        this.set('showBankAccountModal', false);
      }
    }
  });

  _exports.default = _default;
});