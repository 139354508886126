define("frontend/transforms/paypalme-link", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (serialized) {
        serialized = serialized.replace(/https:\/\/paypal\.me\//ig, '');
      }

      return serialized;
    },
    serialize: function serialize(deserialized) {
      if (Ember.isBlank(deserialized)) {
        deserialized = null;
      }

      if (deserialized) {
        deserialized = "https://paypal.me/".concat(deserialized);
      }

      return deserialized;
    }
  });

  _exports.default = _default;
});