define("frontend/routes/invoices-summary/invoice-detail", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model: function model(_ref) {
      var _this = this;

      var invoiceId = _ref.invoiceId;
      // Async relationships are cached
      Ember.run.scheduleOnce('afterRender', function () {
        return _this.store.findRecord('invoice-detail', invoiceId);
      });
      return this.store.findRecord('invoice', invoiceId);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('saving', false);
      controller.set('alreadyResent', false);
      controller.set('editingAdjustment', null);
      controller.get('deletedAdjustments', []);
    },
    actions: {
      goBack: function goBack() {
        this.transitionTo('invoices-summary.index', this.get('controller.model.household.id'));
      }
    }
  });

  _exports.default = _default;
});