define("frontend/controllers/schedule", ["exports", "frontend/utils/calendar-util", "frontend/config/environment", "frontend/utils/date-util", "moment", "jquery"], function (_exports, _calendarUtil, _environment, _dateUtil, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var inFuture = _dateUtil.default.inFuture;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    queryParams: ['hidingSidePanel'],
    scheduleDragActionService: inject.service(),
    router: inject.service(),
    store: inject.service(),
    media: inject.service(),
    ajax: inject.service(),
    selectedAppointment: null,
    reportCard: null,
    reportCardAppointment: null,
    searchValue: '',
    hidingSidePanel: false,
    currentRouteName: Ember.computed.readOnly('router.currentRouteName'),
    onCalendarRoute: computed.equal('currentRouteName', 'schedule.calendar'),
    scheduleCalendar: Ember.inject.controller('schedule.calendar'),
    currentUser: inject.service(),
    showAppointmentInFutureFlash: false,
    appointmentInFutureFlashAction: null,
    calendarOptions: inject.service(),
    showingCalendarOptions: computed.alias('calendarOptions.showingCalendarOptions'),
    onRequestsPage: computed.equal('care_provider_id', 'requests'),
    pendingRequestsCount: 0,
    highlightWindowStart: null,
    highlightWindowEnd: null,
    appointmentReportCardQueue: [],
    groupedAppointmentQueue: [],
    services: computed.alias('model.services'),
    printButtonUrl: 'assets/images/printer-gray.svg',
    showPrintModal: false,
    showPrintDetails: false,
    searchFieldPlaceholder: "Search schedule by pet name",
    loadingCalendarDates: true,
    groupedReportCardComponent: computed('groupedReportCard.draft', function () {
      return this.get('groupedReportCard.draft') ? 'edit-grouped-report-card' : 'view-grouped-report-card';
    }),
    onWeekCalendar: computed('currentRouteName', 'scheduleCalendar.selectedCareProvider', function () {
      return this.get('currentRouteName') === 'schedule.calendar' && this.get('scheduleCalendar.selectedCareProvider') !== 'all';
    }),
    groupedAppointment: computed('selectedGroupedAppointment', 'selectedAppointment.grouped_appointment', function () {
      return Ember.isBlank(this.get('selectedGroupedAppointment')) ? this.get('selectedAppointment.grouped_appointment') : this.get('selectedGroupedAppointment');
    }),
    staffWithAll: computed('staff.@each.label', 'currentUser.user.canViewAppointmentRequests', 'pendingRequestsCount', function () {
      var staff = _toConsumableArray(this.get('staff'));

      staff.unshift({
        label: 'All staff',
        value: 'all'
      });
      staff.unshift({
        label: 'Cancellations',
        value: 'cancellations'
      });

      if (this.get('media.isMobile') && this.get('currentUser.user.canViewAppointmentRequests')) {
        var label = this.get('pendingRequestsCount') > 0 ? "Requests <div class=\"request-count\">".concat(this.get('pendingRequestsCount'), "</div>") : 'Requests';
        staff.unshift({
          label: label,
          value: 'requests'
        });
      }

      return staff;
    }),
    staffWithOnlyCurrent: computed('currentUser.user.id', 'currentUser.user.company.staff_appointment_approval', 'media.isMobile', function () {
      var staff = [{
        label: 'My schedule',
        value: this.get('currentUser.user.id')
      }, {
        label: 'Cancellations',
        value: 'cancellations'
      }];

      if (this.get('media.isMobile') && this.get('currentUser.user.company.staff_appointment_approval')) {
        var label = this.get('pendingRequestsCount') > 0 ? "Requests <div class=\"request-count\">".concat(this.get('pendingRequestsCount'), "</div>") : 'Requests';
        staff.unshift({
          label: label,
          value: 'requests'
        });
      }

      return staff;
    }),
    staff: computed('model.staff.[]', 'model.staff.@each.deleted', 'model.staff.@each.fullName', function () {
      if (!this.get('model.staff')) {
        return [];
      }

      var staff = this.get('model.staff').map(function (staff) {
        return {
          label: "".concat(staff.get('fullName')),
          value: staff.id
        };
      });
      return staff.sort(function (a, b) {
        if (a.label > b.label) {
          return 1;
        }

        if (b.label > a.label) {
          return -1;
        }

        return 0;
      });
    }),
    isToday: computed('date', function () {
      var selectedDay = (0, _moment.default)(this.get('date')).startOf('day');
      var today = (0, _moment.default)().startOf('day');
      return selectedDay.diff(today, 'days') === 0;
    }),
    isThisWeek: computed('date', function () {
      var selectedWeek = (0, _moment.default)(this.get('date')).startOf('week');
      var thisWeek = (0, _moment.default)().startOf('week');
      return selectedWeek.diff(thisWeek, 'weeks') === 0;
    }),
    warnAppointmentInFuture: function warnAppointmentInFuture(actionTaken) {
      this.set('showAppointmentInFutureFlash', true);
      this.set('appointmentInFutureFlashAction', actionTaken);
    },
    hideWarningAppointmentInFuture: function hideWarningAppointmentInFuture() {
      this.set('showAppointmentInFutureFlash', false);
      this.set('appointmentInFutureFlashAction', null);
    },
    // Handles both complete and uncomplete
    // TODO: Consider moving to service
    setComplete: function setComplete(appointment, completed) {
      var _this = this;

      if (appointment.get('updatingCompleteStatus')) {
        return;
      }

      appointment.set('updatingCompleteStatus', true);

      var handleCompleting = function handleCompleting(finishedAtLat, finishedAtLong) {
        var groupedAppointment = _this.get('store').peekRecord('grouped-appointment', appointment.get('grouped_appointment.id'));

        var data = {
          appointments: []
        };
        groupedAppointment.get('appointments').forEach(function (appt) {
          data.appointments.push({
            id: appt.get('id'),
            completed: completed,
            finished_at_latitude: finishedAtLat,
            finished_at_longitude: finishedAtLong
          });
        });
        return _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointments/complete"),
          method: 'POST',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify(data)
        }).then(function (response) {
          response.appointments.forEach(function (apptResponse) {
            if (apptResponse.error) {
              Ember.getOwner(_this).lookup('controller:application').send('notify', {
                message: apptResponse.error,
                type: 'user-error'
              });
            }
          });
          var reloadPromises = groupedAppointment.get('appointments').map(function (appt) {
            return appt.reload().then(function () {
              var isCompleted = appt.get('completed');
              var showReportCard = appt.get('show_report_card_on_completion');

              if (isCompleted && showReportCard) {
                _this.get('groupedAppointmentQueue').push(appt.get('grouped_appointment'));
              }
            });
          });
          return Ember.RSVP.all(reloadPromises).then(function () {
            if (_this.get('groupedAppointmentQueue.length') > 0) {
              _this.startGroupedReportCardQueue();
            } else {
              _this.send('closeAppointmentDetails');
            }

            appointment.set('updatingCompleteStatus', false);
          });
        }).fail(function (error) {
          var message = 'Unexpected error marking appointment complete. Please try again';
          var type = 'error';

          if (error && error.responseText) {
            var parsed = JSON.parse(error.responseText);

            if (parsed.errors) {
              message = parsed.errors.appointments;
            } else if (parsed.message) {
              message = parsed.message;
            }
          }

          Ember.getOwner(_this).lookup('controller:application').send('notify', {
            message: message,
            type: type
          });
          appointment.set('updatingCompleteStatus', false);
        });
      }; // make sure that we do not show gps pins if only marking complete...


      var gpsEnabled = appointment.get('offered_service.service_category.enable_gps_on_check_in');
      var startedAt = appointment.get('started_at');
      var checkInRequired = appointment.get('offered_service.service_category.show_check_in_check_out_appointment');
      var startsAt = (0, _moment.default)(appointment.get('service.starts_at'));
      var duration = appointment.get('offered_service.duration_in_minutes');
      var endTime = appointment.get('time_frame_end_time');
      var apptStartsAt;

      if (endTime) {
        apptStartsAt = (0, _moment.default)("".concat(startsAt.format('YYYY-MM-DD'), " ").concat(endTime), 'YYYY-MM-DD h:mmA');
      } else {
        apptStartsAt = startsAt;
      }

      var careProvider = appointment.get('service.care_provider.id');
      var user = this.get('currentUser.user.id');
      var inTimeWindow = (0, _moment.default)().isBefore(apptStartsAt.add(duration, 'minutes')); // make sure to check if inside the time window on right side conditional

      if (careProvider === user && !this.get('media.isDesktop') && (checkInRequired && gpsEnabled && startedAt || !checkInRequired && gpsEnabled && inTimeWindow)) {
        navigator.geolocation.getCurrentPosition(function (position) {
          return handleCompleting(position.coords.latitude, position.coords.longitude);
        }, function () {
          return handleCompleting(null, null);
        });
      } else {
        handleCompleting(null, null);
      }
    },
    checkAppointment: function checkAppointment(actionToCall) {
      var _this2 = this;

      if (this.get('currentUser.user.isImpersonated')) {
        this.send(actionToCall);
      } else {
        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointment_check?id=").concat(this.get('selectedAppointment.id')),
          method: 'GET',
          dataType: 'json',
          contentType: 'application/json'
        }).then(function (response) {
          if (response.closed) {
            Ember.getOwner(_this2).lookup('controller:application').send('notify', {
              message: response.error,
              type: 'user-error'
            });
          } else {
            _this2.send(actionToCall);
          }
        });
      }
    },
    checkGroupedAppointment: function checkGroupedAppointment(actionToCall) {
      var _this3 = this;

      if (this.get('currentUser.user.isImpersonated')) {
        this.send(actionToCall);
      } else {
        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointment_check?id=").concat(this.get('selectedGroupedAppointment.appointments.firstObject.id')),
          method: 'GET',
          dataType: 'json',
          contentType: 'application/json'
        }).then(function (response) {
          if (response.closed) {
            Ember.getOwner(_this3).lookup('controller:application').send('notify', {
              message: response.error,
              type: 'user-error'
            });
          } else {
            _this3.send(actionToCall);
          }
        });
      }
    },
    checkService: function checkService(actionToCall) {
      var _this4 = this;

      if (this.get('currentUser.user.isImpersonated')) {
        this.send(actionToCall);
      } else {
        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/service_check?id=").concat(this.get('selectedService.id')),
          method: 'GET',
          dataType: 'json',
          contentType: 'application/json'
        }).then(function (response) {
          if (response.closed) {
            Ember.getOwner(_this4).lookup('controller:application').send('notify', {
              message: response.error,
              type: 'user-error'
            });
          } else {
            _this4.send(actionToCall);
          }
        });
      }
    },
    queueGroupedAppointment: function queueGroupedAppointment(groupedAppointment) {
      this.get('groupedAppointmentQueue').push(groupedAppointment);
    },
    startGroupedReportCardQueue: function startGroupedReportCardQueue() {
      var queue = this.get('groupedAppointmentQueue');
      var uniqueQueue = [];
      queue.forEach(function (ga) {
        var alreadyExists = uniqueQueue.some(function (value) {
          return value.get('id') == ga.get('id');
        });

        if (!alreadyExists) {
          uniqueQueue.push(ga);
        }
      });
      this.set('groupedAppointmentQueue', uniqueQueue);
      this.nextReportCard();
    },
    nextReportCard: function nextReportCard() {
      var _this5 = this;

      var groupedAppointment = this.get('groupedAppointmentQueue').shift();

      if (groupedAppointment) {
        var appt = groupedAppointment.get('appointments.firstObject');

        if (appt.get('grouped_report_card')) {
          this.send('openReportCardModal', appt.get('grouped_report_card'));
        } else {
          var grc = this.get('store').createRecord('grouped_report_card', {
            appt_id: appt.get('id')
          });
          grc.save().then(function () {
            groupedAppointment.get('appointments').forEach(function (groupedApptAppt) {
              groupedApptAppt.set('grouped_report_card', grc);
            });

            _this5.send('openReportCardModal', grc);
          });
        }
      } else {
        this.set('groupedReportCard', null);
      }
    },
    transitionToEdit: function transitionToEdit(appointmentIds) {
      var queryParams = {
        backRoute: this.get('currentRouteName'),
        ids: appointmentIds,
        backDate: this.get('date'),
        backCareProviderId: this.get('care_provider_id')
      };
      this.transitionToRoute('edit-single-appointment.index', {
        queryParams: queryParams
      });
    },
    actions: {
      cancelAppointmentInFutureFlash: function cancelAppointmentInFutureFlash() {
        this.hideWarningAppointmentInFuture();
      },
      todayAppointmentInFutureFlash: function todayAppointmentInFutureFlash() {
        this.send('today');
        this.send('cancelAppointmentInFutureFlash');
      },
      addInstructions: function addInstructions() {
        this.set('showAddInstructionsModal', true);
      },
      saveInstructions: function saveInstructions(appointment) {
        var _this6 = this;

        this.set('showAddInstructionsModal', false);
        appointment.save().catch(function () {
          appointment.rollbackAttributes();
          Ember.getOwner(_this6).lookup('controller:application').send('notify', {
            message: 'An unexpected error occurred, unable to save instructions',
            type: 'error'
          });
        });
      },
      cancelAddInstructions: function cancelAddInstructions() {
        this.set('showAddInstructionsModal', false);
        this.get('selectedAppointment').rollbackAttributes();
      },
      removeInstructions: function removeInstructions(appointment) {
        var _this7 = this;

        this.set('showAddInstructionsModal', false);
        appointment.set('special_instructions', '');
        appointment.save().catch(function () {
          Ember.getOwner(_this7).lookup('controller:application').send('notify', {
            message: 'An unexpected error occurred, unable to remove instructions',
            type: 'error'
          });
        });
      },
      clickPet: function clickPet(pet) {
        this.transitionToRoute('client-list.household.pet', pet.get('household.id'), pet.get('id'), {
          queryParams: {
            backDate: this.get('date'),
            backCareProviderId: this.get('care_provider_id')
          }
        });
      },
      denyDeleteService: function denyDeleteService() {
        this.set('showDeleteServiceFlash', false);
      },
      startAppointment: function startAppointment(appointment) {
        var _this8 = this;

        if (inFuture((0, _moment.default)(appointment.get('time'), 'YYYY-MM-DD'))) {
          this.warnAppointmentInFuture('starting');
          return;
        }

        appointment.set('updatingCompleteStatus', true);

        var handleStarting = function handleStarting(startedAtLatitude, startedAtLongitude) {
          var groupedAppointment = _this8.get('store').peekRecord('grouped-appointment', appointment.get('grouped_appointment.id'));

          var data = {
            appointments: []
          };
          groupedAppointment.get('appointments').forEach(function (appt) {
            data.appointments.push({
              id: appt.get('id'),
              start: true,
              started_at_latitude: startedAtLatitude,
              started_at_longitude: startedAtLongitude
            });
          });

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/appointments/start"),
            method: 'POST',
            dataType: 'json',
            contentType: 'application/json',
            data: JSON.stringify(data)
          }).then(function (response) {
            var reloadPromises = [];
            groupedAppointment.get('appointments').forEach(function (appt) {
              reloadPromises.push(appt.reload());
            });
            Ember.RSVP.all(reloadPromises).then(function () {
              appointment.set('updatingCompleteStatus', false);
            });
          }).fail(function () {
            var message = 'Unexpected error starting appointment. Please try again';
            Ember.getOwner(_this8).lookup('controller:application').send('notify', {
              message: message,
              type: 'error'
            });
            appointment.set('updatingCompleteStatus', false);
          });
        };

        if (appointment.get('offered_service.service_category.enable_gps_on_check_in') && !this.get('media.isDesktop')) {
          navigator.geolocation.getCurrentPosition(function (position) {
            return handleStarting(position.coords.latitude, position.coords.longitude);
          }, function () {
            return handleStarting(null, null);
          });
        } else {
          handleStarting(null, null);
        }
      },
      undoStartAppointment: function undoStartAppointment(appointment) {
        var _this9 = this;

        var groupedAppointment = this.get('store').peekRecord('grouped-appointment', appointment.get('grouped_appointment.id'));
        appointment.set('updatingCompleteStatus', true);
        var data = {
          appointments: []
        };
        groupedAppointment.get('appointments').forEach(function (appt) {
          data.appointments.push({
            id: appt.get('id'),
            start: false
          });
        });

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointments/start"),
          method: 'POST',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify(data)
        }).then(function (response) {
          var reloadPromises = [];
          groupedAppointment.get('appointments').forEach(function (appt) {
            reloadPromises.push(appt.reload());
          });
          Ember.RSVP.all(reloadPromises).then(function () {
            appointment.set('updatingCompleteStatus', false);
          });
        }).fail(function () {
          var message = 'Unexpected error unstarting appointment. Please try again';
          Ember.getOwner(_this9).lookup('controller:application').send('notify', {
            message: message,
            type: 'error'
          });
          appointment.set('updatingCompleteStatus', false);
        });
      },
      deleteAppointment: function deleteAppointment() {
        this.checkAppointment('setShowDeleteAppointmentModal');
        this.set('methodOfDeletion', 'appointment details delete button');
      },
      cancelAppointment: function cancelAppointment() {
        this.checkAppointment('setShowCancelAppointmentModal');
      },
      restoreAppointment: function restoreAppointment() {
        var _this10 = this;

        if (this.get('restoringAppointment')) {
          return;
        }

        this.set('restoringAppointment', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointments/").concat(this.get('selectedAppointment.id'), "/restore"),
          method: 'POST'
        }).then(function (response) {
          _this10.get('selectedAppointment').reload().then(function (reloadedAppt) {
            var groupedAppointment = reloadedAppt.get('grouped_appointment');
            var allAppointmentsNotCanceled = groupedAppointment.get('appointments').filter(function (appt) {
              return !appt.get('canceled_at');
            }).length === groupedAppointment.get('appointments.length');

            if (allAppointmentsNotCanceled) {
              groupedAppointment.set('canceled', false);
            }

            if (response.removed_cancellation_fee) {
              _this10.set('showRemovedCancellationFeeModal', true);
            } else {
              var appt = _this10.get('selectedAppointment');

              var message = "You've successfully restored ".concat(appt.get('pet.name'), "'s ").concat(appt.get('offered_service.name'), " on ").concat((0, _moment.default)(appt.get('service.starts_at')).format('ddd, MMM D'), ", to ").concat(appt.get('service.care_provider.fullName'), "'s schedule");
              Ember.getOwner(_this10).lookup('controller:application').send('notify', {
                message: message,
                type: 'success-fireworks'
              });
            }

            _this10.send('closeAppointmentDetails');

            _this10.set('restoringAppointment', false);
          });
        }).fail(function () {
          var message = 'Unexpected error restoring appointment. Please try again';
          Ember.getOwner(_this10).lookup('controller:application').send('notify', {
            message: message,
            type: 'error'
          });

          _this10.set('restoringAppointment', false);
        });
      },
      closeRemovedCancellationFeeModal: function closeRemovedCancellationFeeModal() {
        this.set('showRemovedCancellationFeeModal', false);
      },
      setShowDeleteAppointmentModal: function setShowDeleteAppointmentModal() {
        this.set('showDeleteAppointmentModal', true);
      },
      setShowDeleteServiceFlash: function setShowDeleteServiceFlash() {
        this.set('showDeleteServiceFlash', true);
      },
      setShowCancelAppointmentModal: function setShowCancelAppointmentModal() {
        this.set('showCancelAppointmentModal', true);
      },
      confirmDeleteAppointment: function confirmDeleteAppointment() {
        var _this11 = this;

        this.set('showDeleteAppointmentModal', false);

        if (this.get('deletingAppointment')) {
          return;
        }

        this.set('deletingAppointment', true);
        this.get('selectedAppointment').destroyRecord({
          adapterOptions: {
            method: this.get('methodOfDeletion')
          }
        }).then(function () {
          _this11.send('closeAppointmentDetails');
        }).catch(function () {
          _this11.get('selectedAppointment').rollbackAttributes();
        }).finally(function () {
          _this11.set('deletingAppointment', false);

          _this11.set('methodOfDeletion', null);
        });
      },
      confirmArchiveGroupedAppointment: function confirmArchiveGroupedAppointment(groupedAppointment, apptIds) {
        var _this12 = this;

        this.set('showDeleteAppointmentModal', false);

        if (this.get('deletingAppointment')) {
          return;
        }

        this.set('deletingAppointment', true);
        var appts = groupedAppointment.get('appointments').filter(function (appt) {
          return apptIds.includes(appt.get('id'));
        });
        var promises = appts.map(function (appt) {
          return appt.destroyRecord({
            adapterOptions: {
              method: 'dragging to trash can'
            }
          });
        });
        Ember.RSVP.Promise.all(promises).then(function () {
          _this12.send('closeAppointmentDetails');
        }).finally(function () {
          _this12.set('deletingAppointment', false);

          _this12.set('selectedGroupedAppointment', null);
        });
      },
      confirmCancelAppointment: function confirmCancelAppointment(apptIds, cancellationFee) {
        var _this13 = this;

        this.set('showCancelAppointmentModal', false);

        if (this.get('cancelingAppointment')) {
          return;
        }

        this.set('cancelingAppointment', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointments/cancel_multiple"),
          method: 'POST',
          data: {
            appointment_ids: apptIds,
            cancellation_fee: cancellationFee
          }
        }).then(function () {
          var appointment = _this13.get('selectedAppointment');

          if (appointment) {
            appointment.reload().then(function (reloadedAppt) {
              var groupedAppointment = reloadedAppt.get('grouped_appointment');
              var allAppointmentsCanceled = groupedAppointment.get('appointments').filter(function (appt) {
                return appt.get('canceled_at');
              }).length === groupedAppointment.get('appointments.length');

              if (allAppointmentsCanceled) {
                groupedAppointment.set('canceled', true);
              }
            });

            _this13.send('closeAppointmentDetails');

            _this13.set('cancelingAppointment', false);
          } else {
            throw 'Must select an appointment to cancel.';
          }
        }).fail(function () {
          var message = 'Unexpected error canceling appointment. Please try again';
          Ember.getOwner(_this13).lookup('controller:application').send('notify', {
            message: message,
            type: 'error'
          });

          _this13.set('cancelingAppointment', false);
        });
      },
      confirmCancelGroupedAppointment: function confirmCancelGroupedAppointment(groupedAppointment, apptIds, cancellationFee, cancellationNote) {
        var _this14 = this;

        this.set('showCancelAppointmentModal', false);

        if (this.get('cancelingAppointment')) {
          return;
        }

        this.set('cancelingAppointment', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointments/cancel_multiple"),
          method: 'POST',
          data: {
            appointment_ids: apptIds,
            cancellation_fee: cancellationFee,
            cancellation_notes: cancellationNote
          }
        }).then(function () {
          var appts = groupedAppointment.get('appointments').filter(function (appt) {
            return apptIds.includes(appt.get('id'));
          });
          var reloadPromises = [];
          appts.forEach(function (appointment) {
            reloadPromises.push(appointment.reload());
          });
          Ember.RSVP.all(reloadPromises).then(function () {
            var allAppointmentsCanceled = appts.filter(function (appt) {
              return appt.get('canceled_at');
            }).length === groupedAppointment.get('appointments.length');

            if (allAppointmentsCanceled) {
              groupedAppointment.set('canceled', true);
            }

            _this14.send('closeAppointmentDetails');

            _this14.set('cancelingAppointment', false);
          });
        }).fail(function () {
          var message = 'Unexpected error canceling appointment. Please try again';
          Ember.getOwner(_this14).lookup('controller:application').send('notify', {
            message: message,
            type: 'error'
          });

          _this14.set('cancelingAppointment', false);
        });
      },
      denyDeleteAppointment: function denyDeleteAppointment() {
        this.set('showDeleteAppointmentModal', false);
        this.set('methodOfDeletion', null);
      },
      denyCancelAppointment: function denyCancelAppointment() {
        this.set('showCancelAppointmentModal', false);
      },
      confirmDeleteService: function confirmDeleteService() {
        var _this15 = this;

        this.set('showDeleteServiceFlash', false);

        if (this.get('deletingService')) {
          return;
        }

        this.set('deletingService', true);
        this.get('selectedService').destroyRecord().then(function () {
          _this15.send('closeAppointmentDetails');
        }).catch(function () {
          _this15.get('selectedService').rollbackAttributes();
        }).finally(function () {
          _this15.set('deletingService', false);
        });
      },
      editAppointment: function editAppointment() {
        this.checkAppointment('checkShouldShowModifyModal');
      },
      checkShouldShowModifyModal: function checkShouldShowModifyModal() {
        if (this.get('groupedAppointment.appointments.length') > 1) {
          this.set('showModifyAppointmentModal', true);
        } else {
          this.transitionToEdit([this.get('selectedAppointment.id')]);
        }
      },
      closeModifyAppointmentModal: function closeModifyAppointmentModal() {
        this.set('showModifyAppointmentModal', false);
      },
      modifyAppointment: function modifyAppointment(appointmentIds) {
        this.transitionToEdit(appointmentIds);
        this.set('showModifyAppointmentModal', false);
      },
      modifyAppointmentRequest: function modifyAppointmentRequest(appointmentRequest) {
        var queryParams = {
          backRoute: this.get('currentRouteName'),
          requestId: appointmentRequest.get('id')
        };
        this.transitionToRoute('edit-single-appointment.index', {
          queryParams: queryParams
        });
      },
      setPendingRequestsCount: function setPendingRequestsCount(pendingRequestsCount) {
        this.set('pendingRequestsCount', pendingRequestsCount);
      },
      updateCalendarHighlight: function updateCalendarHighlight() {
        var _this16 = this;

        this.set('loadingCalendarDates', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointment_requests/pending_dates"),
          method: 'GET'
        }).done(function (response) {
          _this16.set('pendingRequestDates', response.pending_dates);

          _this16.set('loadingCalendarDates', false);
        });
      },
      clickSearch: function clickSearch() {
        this.toggleProperty('searching');
        this.set('searchValue', '');
        Ember.run.scheduleOnce('afterRender', function () {
          (0, _jquery.default)('.search-input').focus();
        });
      },
      clickDragAndDrop: function clickDragAndDrop() {
        var _this17 = this;

        this.toggleProperty('dragAndDropActivated');
        this.set('showDragAndDropFlashNote', true);
        Ember.run.later(function () {
          _this17.set('showDragAndDropFlashNote', false);
        }, 2000);
      },
      clickFilter: function clickFilter() {
        this.get('requestListReference').showFilterOptions();
      },
      deleteAppointmentOrService: function deleteAppointmentOrService(obj) {
        this.get('scheduleDragActionService').stopDragging();
        this.set('methodOfDeletion', 'dragging to trash can');

        if (obj.constructor.modelName === 'service') {
          this.set('selectedService', obj);
          this.checkService('setShowDeleteServiceFlash');
        } else {
          this.set('selectedGroupedAppointment', obj);
          this.checkGroupedAppointment('setShowDeleteAppointmentModal');
        }
      },
      dragDropCancelAppointment: function dragDropCancelAppointment(obj) {
        this.get('scheduleDragActionService').stopDragging();
        this.set('selectedGroupedAppointment', obj);
        this.checkGroupedAppointment('setShowCancelAppointmentModal');
      },
      transitionToNew: function transitionToNew() {
        var selectedHouseholdId = null;
        var filter = '';
        var date = this.get('date');
        var backRoute = this.get('currentRouteName');
        var queryParams = {
          selectedHouseholdId: selectedHouseholdId,
          filter: filter,
          date: date,
          backRoute: backRoute
        };
        this.transitionToRoute('add-appointment.index', {
          queryParams: queryParams
        });
      },
      goToRequests: function goToRequests() {
        this.set('care_provider_id', 'requests');
      },
      hideSidePanel: function hideSidePanel() {
        this.set('hidingSidePanel', true);
        $('.ember-application').addClass('side-panel-hidden');

        _calendarUtil.default.resizeExistingHeaders();
      },
      showSidePanel: function showSidePanel() {
        this.set('hidingSidePanel', false);
        $('.ember-application').removeClass('side-panel-hidden');

        _calendarUtil.default.resizeExistingHeaders();
      },
      nextDay: function nextDay() {
        var nextDay = (0, _moment.default)(this.get('date')).add(1, 'day');
        this.send('changeDate', nextDay);
      },
      previousDay: function previousDay() {
        var prevDay = (0, _moment.default)(this.get('date')).subtract(1, 'day');
        this.send('changeDate', prevDay);
      },
      nextWeek: function nextWeek() {
        var nextWeek = (0, _moment.default)(this.get('date')).add(1, 'week');
        this.send('changeDate', nextWeek);
      },
      previousWeek: function previousWeek() {
        var prevWeek = (0, _moment.default)(this.get('date')).subtract(1, 'week');
        this.send('changeDate', prevWeek);
      },
      today: function today() {
        this.send('changeDate', (0, _moment.default)());
      },
      changeDate: function changeDate(momentDate) {
        this.send('closeAppointmentDetails');
        this.set('date', momentDate.format('YYYY-MM-DD'));
      },
      closeAppointmentDetails: function closeAppointmentDetails() {
        this.set('selectedAppointment', null);
      },
      markComplete: function markComplete(appointment) {
        if (inFuture((0, _moment.default)(appointment.get('time'), 'YYYY-MM-DD'))) {
          this.warnAppointmentInFuture('completing');
        } else {
          this.setComplete(appointment, true);
        }
      },
      markUncomplete: function markUncomplete(appointment) {
        if (inFuture((0, _moment.default)(appointment.get('time'), 'YYYY-MM-DD'))) {
          this.warnAppointmentInFuture('completing');
        } else {
          this.setComplete(appointment, false);
        }
      },
      closeGroupedReportCard: function closeGroupedReportCard() {
        this.nextReportCard();
        this.send('closeAppointmentDetails');
      },
      openReportCardModal: function openReportCardModal(groupedReportCard) {
        this.set('groupedReportCard', groupedReportCard);
      },
      startDraggingRequest: function startDraggingRequest(appointmentRequest) {
        this.set('highlightWindowStart', appointmentRequest.get('time_frame_start_time'));
        this.set('highlightWindowEnd', appointmentRequest.get('time_frame_end_time'));
      },
      endDraggingRequest: function endDraggingRequest() {
        this.set('highlightWindowStart', null);
        this.set('highlightWindowEnd', null);
      },
      hoverPrinterButton: function hoverPrinterButton(hovered) {
        if (hovered) {
          this.set('printButtonUrl', 'assets/images/printer-black.svg');
        } else {
          this.set('printButtonUrl', 'assets/images/printer-gray.svg');
        }
      },
      onOpenPrintModal: function onOpenPrintModal() {
        this.set('showPrintModal', true);
      },
      onClosePrintModal: function onClosePrintModal() {
        this.set('showPrintModal', false);
      },
      onShowPrintDetails: function onShowPrintDetails() {
        this.set('showPrintDetails', true);
        this.send('printSchedule');
      },
      onHidePrintDetails: function onHidePrintDetails() {
        this.set('showPrintDetails', false);
        this.send('printSchedule');
      },
      printSchedule: function printSchedule() {
        var _this18 = this;

        var w = window.open();
        var weekType = this.get('currentUser.user.company.weekType');
        var care_provider_id = this.get('care_provider_id');
        var schedule_type = this.get('currentRouteName') == 'schedule.calendar' ? 'calendar' : 'list';
        var show_details = this.get('showPrintDetails');
        var start_date, end_date;

        if (schedule_type === 'calendar') {
          start_date = care_provider_id === 'all' ? this.get('date') : (0, _moment.default)(this.get('date')).startOf(weekType).format('Y-M-D');
          end_date = care_provider_id === 'all' ? this.get('date') : (0, _moment.default)(this.get('date')).endOf(weekType).format('Y-M-D');
        } else {
          start_date = this.get('date');
          end_date = this.get('date');
        } // When testing on dev remove dataType: 'html'


        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/services/print"),
          method: 'GET',
          dataType: 'html',
          data: {
            care_provider_id: care_provider_id,
            start_date: start_date,
            end_date: end_date,
            schedule_type: schedule_type,
            show_details: show_details
          }
        }).done(function (response) {
          _this18.send('onClosePrintModal');

          w.document.open();
          w.document.write(response);
          w.document.close();

          w.onload = function () {
            setTimeout(function () {
              w.print();
              w.close();
            }, 500);
          };
        });
      },
      setRequestListChild: function setRequestListChild(requestListReference) {
        this.set('requestListReference', requestListReference);
      }
    }
  });

  _exports.default = _default;
});