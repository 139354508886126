define("frontend/templates/components/day-of-week-selector", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oa4LvJgZ",
    "block": "{\"symbols\":[\"dayOfWeekOption\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"options\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"daysOfWeek\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",[29,[\"day-of-week-option \",[28,\"if\",[[23,1,[\"selected\"]],\"selected\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"clickDayOfWeek\",[23,1,[\"value\"]]],null]]],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"dow-label\"],[8],[0,\"\\n        \"],[1,[23,1,[\"label\"]],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showDaysError\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"selected-text error\"],[8],[0,\"\\n    Please select at least one day of the week!\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"selectionTextEnabled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"selected-text\"],[8],[0,\"\\n    You've selected \"],[1,[22,\"dayOfWeekLongDisplay\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/day-of-week-selector.hbs"
    }
  });

  _exports.default = _default;
});