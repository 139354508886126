define("frontend/templates/invoices-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fJLTaI/D",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"invoices-page\"],[8],[0,\"\\n  \"],[1,[22,\"loading-spinner\"],false],[0,\"\\n\\n\"],[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"mobile-header-flex\",null,[[\"showSearch\",\"clickSearch\",\"showAdd\"],[true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"clickedIntoSearch\"]]],null],true],null],false]],{\"statements\":[[0,\"      \"],[7,\"header\",true],[10,\"class\",\"header-text\"],[8],[0,\"Invoices\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"sidebar-nav\"],false],[0,\"\\n\"],[4,\"new-header\",null,[[\"flex\",\"hasClose\",\"hasSideNav\"],[true,false,true]],{\"statements\":[[0,\"      \"],[7,\"h1\",true],[10,\"class\",\"header-text\"],[8],[0,\"Invoices\"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"id\",\"header-wormhole\"],[8],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/invoices-loading.hbs"
    }
  });

  _exports.default = _default;
});