define("frontend/components/message-thread/household-select/distribution-list-select", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;
  var COMPANY_PLACEHOLDER = 'assets/images/settings/company-placeholder.png';

  var _default = Ember.Component.extend({
    classNames: ['message-thread--distribution-list-select'],
    currentUser: inject.service(),
    companyPlaceholder: COMPANY_PLACEHOLDER,
    firestore: inject.service(),
    firestoreListener: null,
    avatarImg: computed('currentUser.user.staff_companies.[]', function () {
      return this.get('currentUser.user.company.logo_url') || this.get('companyPlaceholder');
    }),
    hasNewMessage: computed('currentUser.user.distribution_list_last_seen', 'lastMessageTime', function () {
      if (!this.get('lastMessageTime')) {
        return false;
      }

      var lastSeen = this.get('currentUser.user.distribution_list_last_seen');
      var lastMessage = this.get('lastMessageTime');

      if (!lastSeen) {
        return true;
      }

      return moment(lastSeen).unix() < lastMessage;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      var self = this;
      var collection = this.get('firestore').getCollection("".concat(_environment.default.environment, "_distribution_list_times"));

      if (collection) {
        var firestoreListener = collection.doc("".concat(self.get('currentUser.user.company.id'))).onSnapshot(function (doc) {
          if (doc.exists) {
            var lastMessageTime = self.get('lastMessageTime');

            if (lastMessageTime) {
              lastMessageTime = moment.unix(lastMessageTime).format();
            }

            self.set('lastMessageTime', doc.data().last_message_sent_at);
          } else {
            self.set('lastMessageTime', null);
          }
        });
        this.set('firestoreListener', firestoreListener);
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('firestoreListener')) {
        this.get('firestoreListener')();
      }
    }
  });

  _exports.default = _default;
});