define("frontend/templates/components/date-callout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ugrrk4XJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"mobile-text-input border-top\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"mobile-input-label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n    \"],[1,[28,\"input\",null,[[\"value\",\"type\",\"classNames\"],[[24,[\"value\"]],\"date\",\"mobile-input-value\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"input-callout\",null,[[\"tabindex\",\"label\",\"callout\",\"inputActivated\"],[[24,[\"tabindex\"]],[24,[\"label\"]],[24,[\"callout\"]],[24,[\"inputActivated\"]]]],{\"statements\":[[0,\"    \"],[1,[28,\"input\",null,[[\"type\",\"value\",\"tabindex\",\"placeholder\"],[\"date\",[24,[\"value\"]],[24,[\"tabindex\"]],\"m/d/yyyy\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"and\",[[28,\"and\",[[24,[\"hover\"]],[24,[\"hint\"]]],null],[24,[\"showHint\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"alert-hint\",null,[[\"hint\",\"deprecatedOldStyling\"],[[24,[\"hint\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/date-callout.hbs"
    }
  });

  _exports.default = _default;
});