define("frontend/templates/confirm-email", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lIqIxvU9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"confirm-email-container\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"pay-logo center-block\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"assets/images/pay-logo.png\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"container static-pages\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"balloons-container\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"src\",\"assets/images/balloons.png\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-center all-set\"],[8],[0,\"\\n      You're all set!\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-center join-pack\"],[8],[0,\"\\n      Thanks for confirming your email with PetPocketbook!\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"text-center small-info\"],[8],[0,\"\\n      You’ll be receiving your pet's bills via email from \"],[7,\"span\",true],[10,\"class\",\"bolded\"],[8],[0,\"hello@petpocketbook.com\"],[9],[0,\".  Please add us to your contacts list or address book so that your bills don’t get filtered out as spam!\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/confirm-email.hbs"
    }
  });

  _exports.default = _default;
});