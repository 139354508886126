define("frontend/templates/components/invoices-widget/progress-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UZOserY0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"progress\"],[11,\"style\",[29,[\"width: \",[22,\"width\"],\"px\"]]],[8],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/invoices-widget/progress-bar.hbs"
    }
  });

  _exports.default = _default;
});