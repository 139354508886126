define("frontend/templates/components/remove-service-category-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NlvC71PR",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,[[\"onClose\"],[[28,\"action\",[[23,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"remove-service-category-modal\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content centered\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[1,[28,\"inline-svg\",[\"assets/images/diverging-paths.svg\"],null],false],[9],[0,\"\\n        \"],[7,\"header\",true],[8],[0,\"Are you sure?\"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",true],[10,\"class\",\"info-text\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"text-center confirm-text\"],[8],[0,\"\\n          You are about to remove one or more categories that already has services. By proceeding, all services under removed categories will automatically be reclassified as Miscellaneous.\\n        \"],[9],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"text-center confirm-button\"],[8],[0,\"\\n          \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red\",\"Proceed\",[28,\"action\",[[23,0,[]],\"proceed\"],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n\\n        \"],[7,\"div\",true],[10,\"class\",\"text-center delete-button\"],[8],[0,\"\\n          \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red-white\",\"Cancel\",[28,\"action\",[[23,0,[]],\"cancel\"],null]]]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/remove-service-category-modal.hbs"
    }
  });

  _exports.default = _default;
});