define("frontend/controllers/edit-client/add-secondary-contact", ["exports", "frontend/controllers/edit-client/edit-client-page-base"], function (_exports, _editClientPageBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _editClientPageBase.default.extend({
    valid: computed.readOnly('model.validations.isValid'),
    saveDisabled: computed.not('valid'),
    application: Ember.inject.controller(),
    showSecondaryContactPrompt: true,
    actions: {
      uploadedPhoto: function uploadedPhoto(photo) {
        this.set('model.user.avatar_url', photo);
      }
    }
  });

  _exports.default = _default;
});