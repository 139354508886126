define("frontend/templates/edit-client/remove-pet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rcijlvUU",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"yes-skip-prompt remove-pet-prompt\"],[8],[0,\"\\n  \"],[7,\"img\",true],[10,\"class\",\"avatar-image\"],[11,\"src\",[24,[\"model\",\"avatarOrPlaceholder\"]]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[1,[22,\"title\"],false],[9],[0,\"\\n  \"],[7,\"p\",true],[10,\"class\",\"clarification\"],[8],[1,[22,\"clarification\"],true],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"button-container\"],[8],[0,\"\\n    \"],[4,\"ppb-button\",null,[[\"tabIndex\",\"onclick\",\"color\"],[\"1\",[28,\"route-action\",[\"cancel\"],null],\"red-white-border\"]],{\"statements\":[[0,\"cancel\"]],\"parameters\":[]},null],[0,\"\\n    \"],[4,\"ppb-button\",null,[[\"tabIndex\",\"onclick\",\"color\"],[\"2\",[28,\"route-action\",[\"remove\"],null],\"red\"]],{\"statements\":[[0,\"remove\"]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/edit-client/remove-pet.hbs"
    }
  });

  _exports.default = _default;
});