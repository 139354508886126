define("frontend/templates/components/dismissable-message-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "almZZZB6",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ppb-modal\",null,[[\"overlayClassNames\",\"customContainerClassNames\"],[[24,[\"overlayClassNames\"]],[24,[\"customContainerClassNames\"]]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"dismissable-message-modal--image\"],[8],[0,\"\\n\"],[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"      \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"img\",true],[11,\"src\",[29,[[22,\"image\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"header\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[1,[22,\"header\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"p\",true],[8],[1,[22,\"message\"],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"control-panel\"],[8],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"color\",\"onclick\",\"class\"],[\"red\",[28,\"action\",[[23,0,[]],\"onClose\"],null],\"dismissable-message-modal--ok\"]],{\"statements\":[[0,\"      Got it!\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[28,\"dismiss-message\",null,[[\"onDismissedMessage\",\"messageName\",\"class\"],[[28,\"action\",[[23,0,[]],\"onDismissedMessage\"],null],[24,[\"messageName\"]],\"dismissable-message-modal--dismiss\"]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/dismissable-message-modal.hbs"
    }
  });

  _exports.default = _default;
});