define("frontend/routes/sign-up", ["exports", "frontend/mixins/scroll-top"], function (_exports, _scrollTop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_scrollTop.default, {
    activate: function activate() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', function () {
        $('.ember-application').addClass('no-body-padding');
      });
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      Ember.run.scheduleOnce('afterRender', function () {
        $('.ember-application').removeClass('no-body-padding');
      });
    }
  });

  _exports.default = _default;
});