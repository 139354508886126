define("frontend/routes/edit-client/remove-household-user", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = _baseRoute.default.extend({
    addClientSaving: inject.service(),
    currentUser: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    model: function model(params) {
      return this.store.findRecord('household-user', params.household_user_id);
    },
    transitionToList: function transitionToList(household) {
      return this.transitionTo('client-list.household.household-user', household.get('id'), household.get('owner.id'));
    },
    transitionToUser: function transitionToUser() {
      var household = this.get('controller.model.household');
      this.transitionTo('edit-client.secondary-contact', household);
    },
    actions: {
      remove: function remove() {
        var _this = this;

        if (this.get('saving')) {
          return;
        }

        this.set('saving', true);
        var householdUser = this.get('controller.model');
        var household = this.get('controller.model.household');
        householdUser.destroyRecord().then(function () {
          if (_this.get('currentUser.user.isPetParent')) {
            window.location = '/portal/profile';
          } else {
            window.location = "/client-list/".concat(household.get('id'), "/household-user/").concat(household.get('owner.id'));
          }
        });
      },
      cancel: function cancel() {
        this.transitionToUser();
      }
    }
  });

  _exports.default = _default;
});