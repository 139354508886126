define("frontend/components/tooltip-question-mark", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'div',
    classNames: ['tooltip-container'],
    classNameBindings: ['color'],
    tooltipText: null,
    position: 'top',
    color: 'yellow',
    openWithClick: false,
    htmlToRender: '',
    didRender: function didRender() {
      this._super.apply(this, arguments);

      this.initializeTooltip();
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);

      this.updateTooltipContent();
    },
    initializeTooltip: function initializeTooltip() {
      var elementId = this.get('elementId');
      var htmlToRender = this.get('htmlToRender');
      var tooltipText = this.get('tooltipText');
      $("#".concat(elementId)).tooltip({
        title: htmlToRender ? htmlToRender : tooltipText,
        placement: this.get('position'),
        template: "<div class=\"tooltip ".concat(this.get('color'), "\" role=\"tooltip\"><div class=\"tooltip-arrow\"></div><div class=\"tooltip-inner\"></div></div>"),
        trigger: 'manual',
        container: 'body',
        html: true
      });
      this.setupEventListeners();
    },
    updateTooltipContent: function updateTooltipContent() {
      var elementId = this.get('elementId');
      var htmlToRender = this.get('htmlToRender');
      var tooltipText = this.get('tooltipText');
      var $element = $("#".concat(elementId));

      if ($element.data('bs.tooltip')) {
        $element.attr('data-original-title', htmlToRender ? htmlToRender : tooltipText);
      } else {
        this.initializeTooltip();
      }
    },
    setupEventListeners: function setupEventListeners() {
      var elementId = this.get('elementId');

      if (this.get('openWithClick')) {
        $("#".concat(elementId)).on('click', function () {
          $("#".concat(elementId)).tooltip('toggle');
          $(document).on('click', function (event) {
            if (!$(event.target).closest("#".concat(elementId)).length) {
              $("#".concat(elementId)).tooltip('hide');
            }
          });
        });
      } else {
        $("#".concat(elementId)).on('mouseenter', function () {
          $("#".concat(elementId)).tooltip('show');
        }).on('mouseleave', function () {
          $("#".concat(elementId)).tooltip('hide');
        });
      }
    }
  });

  _exports.default = _default;
});