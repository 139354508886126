define("frontend/controllers/edit-recurring-appointment-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    queryParams: ['backRoute', 'backHouseholdId', 'backId'],
    backRoute: null,
    backHouseholdId: null,
    backId: null,
    saving: false,
    media: inject.service(),
    notSaving: computed.not('saving'),
    notMobile: computed.not('media.isMobile'),
    hasClose: computed.and('notSaving', 'notMobile'),
    indexController: inject.controller('edit-recurring-appointment-group.index'),
    sidePanelOpen: computed.readOnly('indexController.sidePanelOpen'),
    showCloseButton: computed('hasClose', 'sidePanelOpen', function () {
      return this.get('hasClose') && !this.get('sidePanelOpen');
    })
  });

  _exports.default = _default;
});