define("frontend/models/vaccination", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend({
    isEmpty: computed.empty('name'),
    name: _emberData.default.attr('string', {
      defaultValue: null
    }),
    expires_on: _emberData.default.attr('string', {
      defaultValue: null
    }),
    expiresOnInvalid: computed('expires_on', 'name', function () {
      return this.get('name') && !moment(this.get('expires_on'), 'YYYY-MM-DD', true).isValid();
    }),
    nameInvalid: computed('expires_on', 'name', function () {
      return !this.get('name') && moment(this.get('expires_on'), 'YYYY-MM-DD', true).isValid() && this.get('expires_on');
    }),
    nameErrors: computed('nameInvalid', function () {
      if (this.get('nameInvalid')) {
        return [{
          message: '* required'
        }];
      } else {
        return [];
      }
    }),
    expired: computed('expires_on', function () {
      return moment().isAfter(moment(this.get('expires_on'), 'YYYY-MM-DD'));
    })
  });

  _exports.default = _default;
});