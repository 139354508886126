define("frontend/components/company-bank-account-modal", ["exports", "frontend/config/environment", "ember-cp-validations"], function (_exports, _environment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'routingNumber': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('length', {
      min: 6,
      max: 9
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true
    })],
    'accountNumber': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('length', {
      min: 4
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    routingNumber: '',
    accountNumber: '',
    confirmAccountNumber: '',
    flashMessage: Ember.inject.service(),
    showClose: true,
    checkErrors: function checkErrors(accountNumber, confirmAccountNumber) {
      this.set('routingError', false);
      this.set('accountError', false);
      this.set('confirmError', false);
      var errors = false;

      if (!this.get('validations.attrs.routingNumber.isValid')) {
        this.set('routingError', true);
        errors = true;
      }

      if (!this.get('validations.attrs.accountNumber.isValid')) {
        this.set('accountError', true);
        errors = true;
      }

      if (accountNumber != confirmAccountNumber) {
        this.set('confirmError', true);
        errors = true;
      }

      return errors;
    },
    actions: {
      close: function close() {
        this.close();
      },
      save: function save() {
        var _this = this;

        var countryIsoCode = this.get('company.country_iso_code');
        var countryCurrency = this.get('company.country_currency');
        var routingNumber = this.get('routingNumber');
        var accountNumber = this.get('accountNumber');
        var confirmAccountNumber = this.get('confirmAccountNumber');

        if (this.checkErrors(accountNumber, confirmAccountNumber)) {
          return;
        }

        this.set('submitDisabled', true); // eslint-disable-next-line new-cap

        var stripe = Stripe(_environment.default.stripe_api_key);
        stripe.createToken('bank_account', {
          country: countryIsoCode,
          currency: countryCurrency,
          routing_number: routingNumber,
          account_number: accountNumber,
          account_holder_name: '',
          account_holder_type: 'individual'
        }).then(function (result) {
          if (result.error) {
            _this.get('flashMessage').sendFlashMessage(result.error.message, 'user-error');

            _this.set('submitDisabled', false);
          } else {
            _this.get('setBankAccount')(result.token, null, "****-".concat(accountNumber.substr(accountNumber.length - 4)), 'manual_bank_account');

            _this.set('submitDisabled', false);

            _this.get('close')();
          }
        });
      }
    }
  });

  _exports.default = _default;
});