define("frontend/templates/add-client", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OuPJiJhG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"new-header\",null,[[\"cancel\",\"hasClose\"],[[28,\"route-action\",[\"cancel\",[24,[\"backRoute\"]],[24,[\"backHouseholdId\"]]],null],[24,[\"hasClose\"]]]],{\"statements\":[[0,\"  \"],[7,\"h1\",true],[10,\"id\",\"header-text-wormhole-container\"],[10,\"class\",\"header-text\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"id\",\"header-back-wormhole-container\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"id\",\"header-next-wormhole-container\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[28,\"eq\",[[24,[\"currentAddRoute\"]],[24,[\"searchClientsStep\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"header-progress-bar\",null,[[\"value\",\"maxValue\"],[[24,[\"currentStepValue\"]],[24,[\"totalSteps\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"add-edit-container\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"saving\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"loading-spinner\",null,[[\"message\"],[\"Saving...\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/add-client.hbs"
    }
  });

  _exports.default = _default;
});