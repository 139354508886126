define("frontend/templates/components/message-thread/new-message-household-select/household-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Q5m6hoCI",
    "block": "{\"symbols\":[\"pet\"],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"new-message-household-selection \",[28,\"if\",[[24,[\"selected\"]],\"selected\"],null],\" \",[28,\"if\",[[24,[\"disabled\"]],\"disabled\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"clickedHousehold\"],null]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"top-line\"],[8],[0,\"\\n    \"],[1,[24,[\"household\",\"owner\",\"fullName\"]],false],[4,\"if\",[[24,[\"household\",\"secondaryContact\"]]],null,{\"statements\":[[0,\", \"],[1,[24,[\"household\",\"secondaryContact\",\"fullName\"]],false]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"ppb-checkbox\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"selected\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"inline-svg\",[\"assets/images/check-complete.svg\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[1,[28,\"inline-svg\",[\"assets/images/check-blank.svg\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"pets-container\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"household\",\"pets\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"pet-row\"],[8],[0,\"\\n        \"],[1,[28,\"img-inner-border\",null,[[\"src\"],[[23,1,[\"avatarOrPlaceholder\"]]]]],false],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"pet-name\"],[8],[0,\"\\n          \"],[1,[23,1,[\"name\"]],false],[0,\"\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/message-thread/new-message-household-select/household-option.hbs"
    }
  });

  _exports.default = _default;
});