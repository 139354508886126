define("frontend/templates/components/report-card-image-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/VPD21hK",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-button\",null,[[\"onclick\",\"onMouseEnter\",\"onMouseLeave\"],[[28,\"action\",[[23,0,[]],\"openImageSelect\"],null],[28,\"action\",[[23,0,[]],\"hoveringPhoto\",true],null],[28,\"action\",[[23,0,[]],\"hoveringPhoto\",false],null]]],{\"statements\":[[4,\"if\",[[24,[\"isUploading\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"loading-spinner\",null,[[\"message\"],[\"\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"inline-svg\",[[24,[\"iconUrl\"]]],null],false],[0,\"\\n\"],[4,\"unless\",[[24,[\"iconOnly\"]]],null,{\"statements\":[[0,\"      Add photo\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"input\",true],[10,\"style\",\"display: none\"],[10,\"name\",\"filename\"],[10,\"accept\",\"image/*\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/report-card-image-upload.hbs"
    }
  });

  _exports.default = _default;
});