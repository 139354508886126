define("frontend/components/resizable-textarea", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['resizable-textarea'],
    label: null,
    placeholder: null,
    rows: 4,
    keyDown: function keyDown(event) {
      if (this.get('enterAction')) {
        if (event.keyCode == 13 && event.shiftKey) {
          event.preventDefault();
          this.get('enterAction')();
        }
      }
    }
  });

  _exports.default = _default;
});