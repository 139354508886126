define("frontend/services/schedule-drag-action-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Service.extend({
    showingCancel: false,
    showingArchive: false,
    showingAnyDropZone: computed.or('showingCancel', 'showingArchive'),
    showingOneDropZone: computed('showingCancel', 'showingArchive', function () {
      var showingCancel = this.get('showingCancel');
      var showingArchive = this.get('showingArchive');
      return showingCancel && !showingArchive || showingArchive && !showingCancel;
    }),
    showingBothDropZones: computed.and('showingCancel', 'showingArchive'),
    stopDragging: function stopDragging() {
      this.set('showingCancel', false);
      this.set('showingArchive', false);
    }
  });

  _exports.default = _default;
});