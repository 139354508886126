define("frontend/components/message-thread", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer;

  var _default = Ember.Component.extend({
    classNames: ['messages-thread'],
    classNameBindings: ['showingCompanySelection', 'hasSidePanel'],
    currentUser: inject.service(),
    store: inject.service(),
    infinity: inject.service(),
    flashMessage: inject.service(),
    messages: [],
    isPetParent: computed.alias('currentUser.user.isPetParent'),
    noMessages: computed.equal('messages.length', 0),
    typedMessage: '',
    household: null,
    company: null,
    showingCompanySelection: false,
    firestore: inject.service(),
    firestoreListener: null,
    hasSidePanel: true,
    isTypingTimer: null,
    typingMessages: [],
    isInDistributionListThread: false,
    showMenu: false,
    showHeader: false,
    datedMessages: computed('messages.[]', function () {
      var datedMessages = {};
      var messages = this.get('messages');
      messages.forEach(function (message) {
        var date = moment(message.get('created_at')).startOf('day').format('YYYY-MM-DD');

        if (!datedMessages[date]) {
          datedMessages[date] = [];
        }

        datedMessages[date].push(message);
      });
      var messageObjects = [];
      var sortedDates = Object.keys(datedMessages).sort(this.sortDates);
      sortedDates.forEach(function (date) {
        var sortedMessages = datedMessages[date].sortBy('created_at');
        sortedMessages.forEach(function (message, index) {
          var nextMessage = sortedMessages[index + 1];

          if (index !== sortedMessages.length && nextMessage) {
            if (moment(nextMessage.get('created_at')).minute() === moment(message.get('created_at')).minute() && nextMessage.get('sent_by_id') === message.get('sent_by_id')) {
              message.set('combined', true);
            }
          }
        });
        messageObjects.push(Ember.Object.create({
          date: date,
          messages: sortedMessages
        }));
      });
      return messageObjects;
    }),
    lastMessageTime: computed('messages.[]', function () {
      var messages = this.get('messages').sortBy('created_at');
      var lastMessage = messages.get('lastObject');

      if (lastMessage) {
        return lastMessage.get('created_at');
      }
    }),
    firstMessageTime: computed('messages.[]', function () {
      var messages = this.get('messages').sortBy('created_at');
      var firstMessage = messages.get('firstObject');

      if (firstMessage) {
        return firstMessage.get('created_at');
      }
    }),
    householdAndCompanyObserver: observer('household.id', 'company.id', function () {
      this.scrollToBottomOfThread();
      this.recordLastSeen();
      this.subscribeToFirestore();
      this.subscribeToTypingEvents();
    }),
    whileTyping: observer('typedMessage', function () {
      var _this = this;

      if (this.get('typedMessage').length > 0) {
        if (!this.get('isTypingTimer')) {
          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/messages/is_typing"),
            method: 'POST',
            data: {
              household_id: this.get('household.id'),
              company_id: this.get('company.id'),
              is_typing: true
            }
          });
        }

        if (this.get('isTypingTimer')) {
          clearTimeout(this.get('isTypingTimer'));
        }

        this.set('isTypingTimer', setTimeout(function () {
          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/messages/is_typing"),
            method: 'POST',
            data: {
              household_id: _this.get('household.id'),
              company_id: _this.get('company.id'),
              is_typing: false
            }
          });

          _this.set('isTypingTimer', null);
        }, 5000));
      }
    }),
    subscribeToTypingEvents: function subscribeToTypingEvents() {
      if (this.get('firestoreTypingListener')) {
        this.get('firestoreTypingListener')();
      }

      var collection = this.get('firestore').getCollection("".concat(_environment.default.environment, "_user_is_typing"));
      var self = this;

      if (collection) {
        var firestoreTypingListener = collection.doc("".concat(this.get('company.id'), "_").concat(this.get('household.id'))).onSnapshot(function (doc) {
          if (doc.exists) {
            var store = self.get('store');
            var typingMessages = Object.entries(doc.data()).filter(function (_ref) {
              var _ref2 = _slicedToArray(_ref, 2),
                  id = _ref2[0],
                  isTyping = _ref2[1];

              return id !== self.get('currentUser.user.id') && isTyping;
            }).map(function (_ref3) {
              var _ref4 = _slicedToArray(_ref3, 1),
                  id = _ref4[0];

              return store.findRecord('user', id).then(function (user) {
                return store.createRecord('message', {
                  message_type: 'TypingIndicatorMessage',
                  sent_by_id: id,
                  created_at: moment().toString(),
                  sent_by_name: user.get('fullName'),
                  sent_by_avatar: user.get('avatar_url'),
                  sent_by_staff: !Ember.isBlank(user.get('company.id'))
                });
              });
            });
            Ember.RSVP.Promise.all(typingMessages).then(function (resolvedMessages) {
              self.set('typingMessages', resolvedMessages);
              self.scrollToBottomOfThread();
            });
          }
        });
        this.set('firestoreTypingListener', firestoreTypingListener);
      }
    },
    didInsertElement: function didInsertElement() {
      this.scrollToBottomOfThread();
      this.recordLastSeen();
      this.subscribeToFirestore();
      this.subscribeToTypingEvents();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('firestoreListener')) {
        this.get('firestoreListener')();
      }
    },
    sortDates: function sortDates(a, b) {
      var momentA = moment(a);
      var momentB = moment(b);

      if (momentA.isBefore(momentB)) {
        return -1;
      }

      return 1;
    },
    scrollToBottomOfThread: function scrollToBottomOfThread() {
      Ember.run.scheduleOnce('afterRender', function () {
        var messageThread = document.getElementById('messages-area');

        if (messageThread) {
          messageThread.scrollTop = messageThread.scrollHeight;
        }
      });
    },
    recordLastSeen: function recordLastSeen() {
      var _this2 = this;

      var inDistThread = this.get('isInDistributionListThread');

      _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/messages/record_last_seen"),
        method: 'POST',
        data: {
          viewing_distribution_list: inDistThread,
          company_id: this.get('company.id'),
          household_id: this.get('household.id')
        }
      }).then(function (response) {
        setTimeout(function () {
          if (inDistThread) {
            _this2.set('currentUser.user.distribution_list_last_seen', response.last_seen);
          } else {
            _this2.set('household.user_last_seen_message_thread', response.last_seen);
          }
        }, 3000);
      });
    },
    subscribeToFirestore: function subscribeToFirestore() {
      if (this.get('firestoreListener')) {
        this.get('firestoreListener')();
      }

      var self = this;
      var collection = this.get('firestore').getCollection("".concat(_environment.default.environment, "_message_times"));

      if (collection) {
        var firestoreListener = collection.doc("".concat(self.get('company.id'), "_").concat(self.get('household.id'))).onSnapshot(function (doc) {
          if (doc.exists) {
            _jquery.default.ajax({
              url: "".concat(_environment.default.APP.host, "/api/v2/messages"),
              method: 'GET',
              data: {
                household_id: self.get('household.id'),
                company_id: self.get('company.id'),
                last_message_time: self.get('lastMessageTime')
              }
            }).then(function (response) {
              var hasNewMessages = false;
              response.messages.forEach(function (message) {
                var messageIds = self.get('messages').map(function (message) {
                  return parseInt(message.get('id'));
                });

                if (!messageIds.includes(message.id)) {
                  var store = self.get('store');
                  self.get('messages').pushObject(store.createRecord('message', message));
                  hasNewMessages = true;
                }
              });

              if (hasNewMessages) {
                self.scrollToBottomOfThread();
                self.recordLastSeen();
              }
            });
          }
        });
        this.set('firestoreListener', firestoreListener);
      }
    },
    actions: {
      sendMessage: function sendMessage() {
        var _this3 = this;

        var message = this.get('typedMessage');

        if (!message) {
          return;
        }

        this.set('typedMessage', '');
        var data = {
          message: message,
          household_id: this.get('household.id'),
          sent_by_staff: !this.get('isPetParent'),
          company_id: this.get('company.id'),
          show_in_grouped_messages: false
        };

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/messages/plain_text"),
          method: 'POST',
          data: data
        }).then(function (response) {
          if (_this3.get('callbackAfterSentMessage')) {
            _this3.get('callbackAfterSentMessage')();
          }

          var store = _this3.get('store');

          _this3.get('messages').pushObject(store.createRecord('message', response));

          _this3.scrollToBottomOfThread();

          _this3.get('household').set('user_last_seen_message_thread', moment());
        });
      },
      sendImageMessage: function sendImageMessage(file, finishedUploadingCallback) {
        var _this4 = this;

        this.set('uploadingPhoto', true);
        var fileType = file.type;
        var splitFileType = fileType.split('/');
        var fileName = file.name;

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/messages/create_upload_url"),
          method: 'POST',
          data: {
            file_type: fileType
          }
        }).then(function (response) {
          var formData = new FormData();

          for (var key in response.fields) {
            var value = response.fields[key];
            formData.set(key, value);
          }

          return file.upload(response.url, {
            data: response.fields
          }).then(function () {
            return response.fields.key;
          });
        }).then(function (key) {
          var data = {
            image_url: key,
            household_id: _this4.get('household.id'),
            sent_by_staff: !_this4.get('isPetParent'),
            company_id: _this4.get('company.id'),
            show_in_grouped_messages: false,
            file_name: fileName,
            file_type: splitFileType[0] == 'application' ? splitFileType[1] : splitFileType[0]
          };
          return _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/messages/image"),
            method: 'POST',
            data: data
          }).then(function (response) {
            if (_this4.get('callbackAfterSentMessage')) {
              _this4.get('callbackAfterSentMessage')();
            } else {
              var store = _this4.get('store');

              _this4.get('messages').pushObject(store.createRecord('message', response));

              _this4.scrollToBottomOfThread();

              _this4.get('household').set('user_last_seen_message_thread', moment());
            }

            Ember.run.schedule('afterRender', function () {
              if (finishedUploadingCallback) {
                finishedUploadingCallback();
              }
            });

            _this4.set('uploadingPhoto', false);
          });
        });
      },
      loadPastMessages: function loadPastMessages() {
        var _this5 = this;

        var messageThread = document.getElementById('messages-area');
        var scrollHeight = messageThread.scrollHeight;

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/messages"),
          method: 'GET',
          data: {
            household_id: this.get('household.id'),
            company_id: this.get('company.id'),
            first_message_time: this.get('firstMessageTime')
          }
        }).then(function (response) {
          if (response.messages.length == 0) {
            _this5.get('messages').set('reachedInfinity', true);
          } else {
            response.messages.forEach(function (message) {
              var messageIds = _this5.get('messages').map(function (message) {
                return parseInt(message.get('id'));
              });

              if (!messageIds.includes(message.id)) {
                var store = _this5.get('store');

                _this5.get('messages').pushObject(store.createRecord('message', message));
              }
            });
            Ember.run.scheduleOnce('afterRender', function () {
              var messageThread = document.getElementById('messages-area');

              if (messageThread) {
                messageThread.scrollTop = messageThread.scrollHeight - scrollHeight;
              }
            });
          }
        });
      },
      viewReportCard: function viewReportCard(message) {
        var _this6 = this;

        this.set('showReportCard', true);
        this.set('loadingReportCard', true);
        this.get('store').findRecord('appointment', message.get('appointment_id')).then(function (appointment) {
          _this6.set('selectedReportCard', appointment.get('grouped_report_card'));

          _this6.set('loadingReportCard', false);
        });
      },
      closeReportCard: function closeReportCard() {
        this.set('showReportCard', false);
        this.set('selectedReportCard', null);
      },
      viewAppointmentDetails: function viewAppointmentDetails(message) {
        var _this7 = this;

        this.set('showAppointmentDetails', true);
        this.set('loadingAppointmentDetails', true);
        this.get('store').findRecord('appointment-request', message.get('appointment_request_id')).then(function (request) {
          _this7.set('selectedAppointmentRequest', request);

          _this7.set('loadingAppointmentDetails', false);
        });
      },
      closeAppointmentDetails: function closeAppointmentDetails() {
        this.set('showAppointmentDetails', false);
        this.set('selectedAppointmentRequest', null);
      },
      goToAppointmentRequest: function goToAppointmentRequest(message) {
        this.get('goToAppointmentRequest')(message.get('appointment_request_id'), message.get('company_id'), this.get('household').get('id'));
      },
      clickMoreOptions: function clickMoreOptions() {
        this.toggleProperty('showMenu');
      },
      transitionToHousehold: function transitionToHousehold() {
        this.get('transitionToHousehold')(this.get('household.id'));
        this.send('clickMoreOptions');
      },
      clickedArchiveThreadButton: function clickedArchiveThreadButton() {
        this.set('showArchiveThreadModal', true); // let message = "Archiving this thread will hide it from your inbox. When you receive a new message from this family, the thread will reappear along with your full conversation history.";
        // let no = (() => new Ember.RSVP.Promise((resolve) => resolve()));
        // this.get('flashMessage').confirm(this.archiveThread.bind(this), no, message, 'archive', 'just kidding');
      },
      closeArchiveThreadModal: function closeArchiveThreadModal() {
        this.send('clickMoreOptions');
        this.set('showArchiveThreadModal', false);
      },
      archiveThread: function archiveThread() {
        var _this8 = this;

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/messages/archive_thread"),
          method: 'POST',
          data: {
            household_id: this.get('household.id')
          }
        }).then(function () {
          _this8.send('clickMoreOptions');

          _this8.get('transitionBackToInbox')().then(function () {
            window.location.reload();
          });
        });
      }
    }
  });

  _exports.default = _default;
});