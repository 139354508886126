define("frontend/components/mobile-action-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mobile-action-bar'],
    householdUser: null,
    showContactPickerModal: false,
    contactPrompt: null,
    contactPickerInfo: [],
    clickEmail: function clickEmail() {
      var emails = this.get('householdUser.emails');
      var emailsCount = emails.get('length');

      if (emailsCount === 1) {
        this.onEmailSelected(emails.get('firstObject'));
      } else if (emailsCount > 1) {
        this.populateContactInfo('onEmailSelected', 'Which email would you like to reach?', emails, function (model) {
          var type = model.get('email_type');
          var address = model.get('address');
          return {
            type: type,
            address: address,
            model: model
          };
        });
      }
    },
    clickPhone: function clickPhone() {
      var phones = this.get('householdUser.phone_numbers');
      var phonesCount = phones.get('length');
      var mobilePhones = phones.filter(function (phone) {
        return phone.get('isMobile');
      });

      if (phonesCount > 1 || mobilePhones.get('length') > 0) {
        this.set('showPhonePickerModal', true);
      } else {
        this.callPhone(phones.get('firstObject'));
      }
    },
    clickMessage: function clickMessage() {
      this.get('onMessageSelected')();
    },
    populateContactInfo: function populateContactInfo(contactAction, contactPrompt, models, modelMarshaller) {
      this.set('showContactPickerModal', true);
      this.set('contactAction', contactAction);
      this.set('contactPrompt', contactPrompt);
      var contacts = [];
      models.forEach(function (model) {
        contacts.push(modelMarshaller(model));
      });
      this.set('contactPickerInfo', contacts);
    },
    actions: {
      onClose: function onClose() {
        this.set('showContactPickerModal', false);
        this.set('contactPrompt', null);
      },
      closePhonePickerModal: function closePhonePickerModal() {
        this.set('showPhonePickerModal', false);
      },
      clickAddress: function clickAddress() {
        this.clickAddress();
      },
      onEmailSelected: function onEmailSelected(email) {
        this.get('onEmailSelected')(email);
      },
      textPhone: function textPhone(phone) {
        this.get('textPhone')(phone);
      },
      callPhone: function callPhone(phone) {
        this.get('callPhone')(phone);
      },
      onMessageSelected: function onMessageSelected(message) {
        this.get('onMessageSelected')(message);
      }
    }
  });

  _exports.default = _default;
});