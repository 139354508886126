define("frontend/models/weekend-surcharge-policy", ["exports", "ember-data", "frontend/utils/string-util"], function (_exports, _emberData, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend({
    service_category_id: _emberData.default.attr(),
    service_category: _emberData.default.belongsTo('service-category'),
    days_of_week: _emberData.default.attr(),
    amount: _emberData.default.attr('number'),
    amount_type: _emberData.default.attr(),
    employee_compensation_type: _emberData.default.attr('string', {
      defaultValue: 'percentage'
    }),
    employee_compensation_amount: _emberData.default.attr('number'),
    charge_per_day: _emberData.default.attr('boolean'),
    dayOfWeekDisplay: computed('days_of_week.[]', function () {
      var dows = this.get('days_of_week');

      if (dows.length == 7) {
        return 'Mon - Sun';
      } else if (dows.length === 5 && dows.sort().every(function (value, index) {
        return value === [1, 2, 3, 4, 5][index];
      })) {
        return 'Mon - Fri';
      } else {
        var dayMap = [['Mon', 1], ['Tue', 2], ['Wed', 3], ['Thu', 4], ['Fri', 5], ['Sat', 6], ['Sun', 0]];
        var selectedDays = [];
        dayMap.forEach(function (day) {
          if (dows.includes(day[1])) {
            selectedDays.push(day[0]);
          }
        });
        return _stringUtil.default.joinWithAnd(selectedDays);
      }
    }),
    dayOfWeekLongDisplay: computed('days_of_week.[]', function () {
      var dows = this.get('days_of_week');

      if (dows.length == 7) {
        return 'Monday through Sunday';
      } else if (dows.length === 5 && dows.sort().every(function (value, index) {
        return value === [1, 2, 3, 4, 5][index];
      })) {
        return 'Monday through Friday';
      } else if (dows.length === 3 && dows.sort().every(function (value, index) {
        return value === [0, 5, 6][index];
      })) {
        return 'Friday through Sunday';
      } else {
        var dayMap = [['Monday', 1], ['Tuesday', 2], ['Wednesday', 3], ['Thursday', 4], ['Friday', 5], ['Saturday', 6], ['Sunday', 0]];
        var selectedDays = [];
        dayMap.forEach(function (day) {
          if (dows.includes(day[1])) {
            selectedDays.push(day[0]);
          }
        });
        return _stringUtil.default.joinWithAnd(selectedDays);
      }
    })
  });

  _exports.default = _default;
});