define("frontend/templates/components/payment-method-modal/choose-payment-method", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "flEgBGTw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"content centered\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[1,[28,\"inline-svg\",[\"assets/images/cashregister.svg\"],null],false],[9],[0,\"\\n    \"],[7,\"header\",true],[8],[0,\"Choose payment method\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showCard\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red-white-border\",\"credit / debit card\",[28,\"action\",[[23,0,[]],[24,[\"clickCreditCard\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"showAch\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red-white-border\",\"bank transfer\",[28,\"action\",[[23,0,[]],[24,[\"clickBankTransfer\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"ppb-modal-cancel\"],[8],[0,\"\\n  \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red-white\",\"cancel\",[28,\"action\",[[23,0,[]],[24,[\"onClose\"]]],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/payment-method-modal/choose-payment-method.hbs"
    }
  });

  _exports.default = _default;
});