define("frontend/templates/components/header-with-line-and-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "y3cpvQYt",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header-with-line-and-text\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"background-line\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"centered-text\"],[8],[0,\"\\n    \"],[1,[22,\"text\"],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"background-line\"],[8],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/header-with-line-and-text.hbs"
    }
  });

  _exports.default = _default;
});