define("frontend/controllers/companies/pricing", ["exports", "frontend/config/environment", "frontend/services/flash-message", "jquery"], function (_exports, _environment, _flashMessage, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Controller.extend({
    sidePanelOpen: false,
    selectedOfferedService: null,
    companies: inject.controller(),
    saving: computed.alias('companies.saving'),
    overrideSavingMessage: computed.alias('companies.overrideSavingMessage'),
    newService: computed.empty('selectedOfferedService.id'),
    addingNewService: computed.and('sidePanelOpen', 'newService'),
    currentUser: Ember.inject.service(),
    company: computed.readOnly('currentUser.user.company'),
    flashMessage: Ember.inject.service(),
    dragAndDropActivated: false,
    serviceCategoriesWithServices: computed('model.serviceCategories.[]', 'model.offeredServices.[]', function () {
      var offeredServices = this.get('model.offeredServices');
      return this.get('model.serviceCategories').map(function (category) {
        var services = offeredServices.filter(function (service) {
          return service.get('service_category_id') == category.get('id');
        });
        services = services.sortBy('position');
        Ember.set(category, 'services', services);
        return category;
      });
    }),
    addCategoryWidth: computed('serviceCategoriesWithServices.[]', function () {
      var totalCategories = this.get('serviceCategoriesWithServices.length');

      if (totalCategories == 8) {
        return false;
      } else {
        if (totalCategories % 2 == 0) {
          return 'full-width';
        } else {
          return 'half-width';
        }
      }
    }),
    actions: {
      dragStart: function dragStart() {
        var _this = this;

        // The run later is in case you're trying to drag a service blocked by the scroll area
        Ember.run.later(function () {
          _this.set('dragScrollActivated', true);
        }, 0);
      },
      dragEnd: function dragEnd() {
        this.set('dragScrollActivated', false);
      },
      clickDragAndDrop: function clickDragAndDrop() {
        var _this2 = this;

        this.toggleProperty('dragAndDropActivated');
        this.set('showDragAndDropFlashNote', true);
        Ember.run.later(function () {
          _this2.set('showDragAndDropFlashNote', false);
        }, 2000);
      },
      createOfferedService: function createOfferedService(serviceCategory) {
        var company_id = this.get('company.id');
        var newOfferedService = this.store.createRecord('offered-service', {
          capacity: 30,
          company_id: company_id,
          service_category: serviceCategory,
          show_to_pet_parents: this.get('company.pet_parent_appointment_requests_enabled')
        });
        this.set('selectedOfferedService', newOfferedService);
        this.set('sidePanelOpen', true);
      },
      editOfferedService: function editOfferedService(offeredService) {
        var _this3 = this;

        if (this.get('selectedOfferedService')) {
          this.get('selectedOfferedService').rollbackAttributes();
        }

        this.set('selectedOfferedService', offeredService);
        var price_start_date = offeredService.get('price_start_date');

        if (price_start_date && moment(price_start_date) > moment()) {
          this.set('selectedOfferedService.effectiveDate', moment.utc(price_start_date).format('MMMM D, YYYY'));
          this.set('selectedOfferedService.price', offeredService.get('latest_price'));
          this.set('selectedOfferedService.incremental_price', offeredService.get('latest_incremental_price'));
        }

        if (this.get('selectedOfferedService.all_day_service') && !this.get('selectedOfferedService.has_appointments')) {
          this.set('offeredServiceLoading', true);

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/offered_services/").concat(this.get('selectedOfferedService.id'), "/has_any_appointments"),
            method: 'GET'
          }).then(function (response) {
            _this3.set('selectedOfferedService.has_any_appointments', response.has_any_appointments);

            _this3.set('offeredServiceLoading', false);
          });
        }

        this.set('sidePanelOpen', true);
      },
      cancelSidePanel: function cancelSidePanel() {
        this.get('selectedOfferedService').rollbackAttributes();
        this.set('sidePanelOpen', false);
        this.set('selectedOfferedService', null);
      },
      repositionOfferedServices: function repositionOfferedServices() {
        var _this4 = this;

        var sorted_offered_service_ids = this.get('model.offeredServices').mapBy('id').filter(function (id) {
          return id != null;
        });

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/offered_services/reposition"),
          method: 'PUT',
          data: {
            sorted_offered_service_ids: sorted_offered_service_ids
          }
        }).then(function (offeredServices) {
          _this4.store.pushPayload(offeredServices);
        });
      },
      deleteOfferedService: function deleteOfferedService(offeredService) {
        var _this5 = this;

        this.set('sidePanelOpen', false);
        this.set('selectedOfferedService', null);
        this.set('overrideSavingMessage', 'Loading...');
        this.set('saving', true);
        this.transitionToRoute('companies.remove-service', offeredService.id).then(function () {
          _this5.set('overrideSavingMessage', null);

          _this5.set('saving', false);
        });
      },
      saveOfferedService: function saveOfferedService(offeredService) {
        var _this6 = this;

        offeredService.save().then(function () {
          _this6.store.query('offered-service', {}, {
            reload: true
          }).then(function (offeredServices) {
            _this6.set('model.offeredServices', offeredServices);

            _this6.set('selectedOfferedService', null);

            _this6.set('sidePanelOpen', false);
          });
        }).catch(function (err) {
          var hasExpectedError = err.errors != null && err.errors.length > 0 && err.errors[0].status !== '500';

          if (hasExpectedError) {
            _this6.get('flashMessage').errorWithRefresh(err.errors[0].detail);
          } else {
            _this6.get('flashMessage').error(_flashMessage.UNEXPECTED_ERROR_MESSAGE);
          }

          _this6.set('saving', false);
        });
      },
      toggleSelectingCategories: function toggleSelectingCategories() {
        this.toggleProperty('selectingCategories');
      },
      redirectToSettings: function redirectToSettings(categoryId) {
        return this.transitionToRoute('companies.category-settings', this.get('company.id'), 'time-blocks', {
          queryParams: {
            categoryId: categoryId
          }
        });
      },
      toggleRequestsModal: function toggleRequestsModal() {
        this.toggleProperty('petParentRequestsModal');
      },
      saveRequestsModal: function saveRequestsModal() {
        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/companies/").concat(this.get('company.id'), "/enable_pet_parent_requests"),
          method: 'POST',
          data: {
            enabled: this.get('company.pet_parent_appointment_requests_enabled')
          }
        }).then(function () {
          window.location.reload();
        });
      }
    }
  });

  _exports.default = _default;
});