define("frontend/controllers/add-client", ["exports", "frontend/controllers/add-edit-client"], function (_exports, _addEditClient) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = _addEditClient.default.extend({
    addClientPrefix: 'add-client',
    queryParams: ['backRoute', 'backHouseholdId', 'backId'],
    backRoute: null,
    backHouseholdId: null,
    backId: null,
    session: inject.service(),
    accountSession: inject.service(),
    media: inject.service(),
    isPetParent: computed('session.data.authenticated.tokenData', function () {
      return this.get('accountSession').isPetParent();
    }),
    currentAddRoute: computed('currentRouteName', 'addClientPrefix', function () {
      return this.get('currentRouteName').replace("".concat(this.get('addClientPrefix')), '').replace('.', '');
    }),
    totalSteps: computed('addSteps.length', function () {
      return this.get('addSteps.length') - 1;
    }),
    currentStepValue: computed('addSteps.[]', 'currentAddRoute', function () {
      var currentRouteWithPrefix = "add-client.".concat(this.get('currentAddRoute'));
      return this.get('addSteps').indexOf(currentRouteWithPrefix);
    }),
    addSteps: computed('searchClientsStep', 'primaryContactStep', 'secondaryContactStep', 'petStep', 'billingStep', 'documentsStep', 'addClientPrefix', function () {
      return ["".concat(this.get('addClientPrefix'), ".").concat(this.get('searchClientsStep')), "".concat(this.get('addClientPrefix'), ".").concat(this.get('primaryContactStep')), "".concat(this.get('addClientPrefix'), ".").concat(this.get('secondaryContactStep')), "".concat(this.get('addClientPrefix'), ".").concat(this.get('petStep')), "".concat(this.get('addClientPrefix'), ".").concat(this.get('billingStep')), "".concat(this.get('addClientPrefix'), ".").concat(this.get('documentsStep'))];
    }),
    hasClose: computed('media.isMobile', 'currentRouteName', 'steps.[]', 'saving', 'primaryContactStep', 'isPetParent', function () {
      if (this.get('media.isMobile')) {
        var cancelRoutes = [this.get('searchClientsStep')];
        return cancelRoutes.includes(this.get('currentAddRoute')) && !this.get('saving'); // pet parents cannot close out of the add client flow... if they're here they are completing new account setup
      } else if (this.get('isPetParent')) {
        return false;
      } else {
        return !this.get('saving');
      }
    })
  });

  _exports.default = _default;
});