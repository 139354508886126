define("frontend/validators/international-phone-number", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var InternationalPhoneNumber = _base.default.extend({
    countryInfo: inject.service(),
    validate: function validate(value, options, model) {
      if (!value) {
        if (options.presence) {
          return '*required';
        } else {
          return true;
        }
      } //            company owner                   admin creating company                                     user editing personal address


      var country = model.get('address.country') || model.get('companyCountry') || model.get('vet.country') || this.get('countryInfo.currentUserCountry');

      if (country === 'United States' || country == 'Canada') {
        var stripped = value.replace(/[^0-9]/g, '');
        var usRegex = /^\d{10}$/;

        if (!usRegex.test(stripped)) {
          return '*please enter a valid 10 digit phone number';
        }
      } else if (country === 'Australia') {
        var _stripped = value.replace(/[^0-9]/g, '');

        if (_stripped[0] != '0') {
          return '*phone numbers should start with 0';
        }

        var auRegex = /^\d{10}$/;

        if (!auRegex.test(_stripped)) {
          return '*please enter a valid 10 digit phone number';
        }
      }

      return true;
    }
  }); // InternationalPostalCode.reopenClass({
  //   /**
  //    * Define attribute specific dependent keys for your validator
  //    *
  //    * [
  //    * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
  //    * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
  //    * ]
  //    *
  //    * @param {String}  attribute   The attribute being evaluated
  //    * @param {Unknown} options     Options passed into your validator
  //    * @return {Array}
  //    */
  //   getDependentsFor(/* attribute, options */) {
  //     return [];
  //   }
  // });


  var _default = InternationalPhoneNumber;
  _exports.default = _default;
});