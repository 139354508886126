define("frontend/templates/components/add-on-service-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PT67uxrt",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"mobile-select\",null,[[\"label\",\"value\",\"borderBottom\",\"borderTop\",\"passFullOptionBack\",\"setValueOnChange\",\"selectOption\",\"options\"],[[24,[\"dropdownLabel\"]],[24,[\"selectedOption\",\"value\"]],true,false,true,false,[28,\"action\",[[23,0,[]],\"changeAddOnService\"],null],[24,[\"filteredOptions\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"paper-select\",null,[[\"required\",\"label\",\"renderInPlace\",\"options\",\"selected\",\"searchPlaceholder\",\"searchEnabled\",\"dropdownClass\",\"searchField\",\"onChange\"],[false,[24,[\"dropdownLabel\"]],true,[24,[\"filteredOptions\"]],[24,[\"selectedOption\"]],\"Type to filter\",[28,\"gt\",[[24,[\"options\",\"length\"]],5],null],\"search-enabled white-background-search\",\"label\",[28,\"action\",[[23,0,[]],\"changeAddOnService\"],null]]],{\"statements\":[[0,\"      \"],[1,[28,\"capitalize-text\",[[23,1,[\"label\"]]],null],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"remove-button\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"removeAddOnService\"],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n  \"],[1,[28,\"inline-svg\",[\"assets/images/clear.svg\"],null],false],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/add-on-service-dropdown.hbs"
    }
  });

  _exports.default = _default;
});