define("frontend/components/client-invoices-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['client-invoices-list'],
    actions: {
      clickInvoice: function clickInvoice() {
        this.get('clickInvoice').apply(void 0, arguments);
      },
      clickPayment: function clickPayment() {
        this.get('clickPayment').apply(void 0, arguments);
      },
      deletePayment: function deletePayment() {
        this.get('deletePayment').apply(void 0, arguments);
      }
    }
  });

  _exports.default = _default;
});