define("frontend/templates/components/button-popout-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7+kwPSj0",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"popout-dropdown\",null,[[\"placeholder\",\"options\",\"selectOption\"],[[24,[\"placeholder\"]],[24,[\"options\"]],[28,\"action\",[[23,0,[]],\"selectOption\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/button-popout-dropdown.hbs"
    }
  });

  _exports.default = _default;
});