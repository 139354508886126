define("frontend/templates/components/report-card-checklist-pet-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "qPZ1C39I",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",[29,[\"pet-select \",[28,\"if\",[[24,[\"isSelected\"]],\"selected\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"selectPet\"],null]]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"pet-icon\"],[8],[0,\"\\n    \"],[1,[28,\"img-inner-border\",null,[[\"src\"],[[24,[\"checklist\",\"pet\",\"avatarOrPlaceholder\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"pet-name\"],[8],[0,\"\\n    \"],[1,[24,[\"checklist\",\"pet\",\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"checkbox\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"assets/images/check_mark.png\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/report-card-checklist-pet-select.hbs"
    }
  });

  _exports.default = _default;
});