define("frontend/components/grouped-appointment-list-item", ["exports", "frontend/utils/string-util"], function (_exports, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['grouped-appointment-list-item'],
    classNameBindings: ['swipedLeft', 'swipedLeftOneButton', 'swipedLeftThreeButton', 'groupedAppointment.completed:completed', 'hasHoverButtons:has-hover-buttons', 'showCanceledAppointments:showing-canceled-appts'],
    checkStartedAppointment: inject.service(),
    media: inject.service(),
    groupedAppointment: null,
    showCanceledAppointments: false,
    canceledAt: computed.alias('groupedAppointment.appointments.firstObject.canceled_at'),
    canceledBecauseOfRequest: computed.alias('groupedAppointment.appointments.firstObject.canceled_because_of_request'),
    showReportCardActions: computed('groupedAppointment.appointments.[]', function () {
      var appointments = this.get('groupedAppointment.appointments');
      var showReportCard = false;
      appointments.toArray().some(function (appointment) {
        if (appointment.get('grouped_report_card.id') && !appointment.get('grouped_report_card.draft')) {
          showReportCard = true;
          return true;
        }
      });
      return showReportCard;
    }),
    swipedLeftOneButton: computed.and('swipedLeft', 'showReportCardActions'),
    swipedLeftThreeButton: computed('swipedLeft', 'showReportCardActions', 'isPetParent', 'showCanceledAppointments', 'cancellationNote', function () {
      var swipedLeft = this.get('swipedLeft');

      if (!swipedLeft || this.get('showReportCardActions')) {
        return false;
      } else if (this.get('showCanceledAppointments') && this.get('cancellationNote')) {
        return true;
      }

      return !this.get('isPetParent') && !this.get('showCanceledAppointments');
    }),
    doNotShowReportCardActions: computed.not('showReportCardActions'),
    petNames: computed('groupedAppointment.petNames', function () {
      if (this.get('groupedAppointment.petNames').length >= 30 && this.get('media.isMobile')) {
        return "all ".concat(this.get('familyName'), " family pets");
      } else {
        return this.get('groupedAppointment.petNames');
      }
    }),
    isStartedButNotFinished: computed('groupedAppointment.appointments.[]', function () {
      var isStarted = false;
      this.get('groupedAppointment.appointments').forEach(function (appt) {
        if (appt.get('started_at') && !appt.get('finished_at')) {
          isStarted = true;
        }
      });
      return isStarted;
    }),
    addOnServices: computed('groupedAppointment.appointments.@each.add_on_services.[]', function () {
      var appts = this.get('groupedAppointment.appointments'); // eslint-disable-next-line no-undef

      var addOnServices = {};
      appts.forEach(function (appt) {
        appt.get('appointment_add_on_services').forEach(function (service) {
          if (!addOnServices[service.get('offered_service.id')]) {
            addOnServices[service.get('offered_service.id')] = {
              offered_service: service.get('offered_service'),
              petNames: []
            };
          }

          addOnServices[service.get('offered_service.id')].petNames.pushObject(appt.get('pet.name'));
        });
      });
      return Object.values(addOnServices);
    }),
    addOnServicesTooltipText: computed('addOnServices.[]', function () {
      var _this = this;

      var addOns = this.get('addOnServices');
      var text = '<div class="tooltip-header">add-ons:</div>';
      addOns.forEach(function (addOn) {
        text += "<div class=\"tooltip-add-on-service\">+ ".concat(addOn.offered_service.get('nameWithDuration'));

        if (addOn.petNames.length != _this.get('groupedAppointment.appointments.length')) {
          text += " for <span class=\"bold\">".concat(_stringUtil.default.joinWithAnd(addOn.petNames), "</span>");
        }

        text += '</div>';
      });
      return text;
    }),
    click: function click(event) {
      event.stopPropagation();

      if (this.get('swipedLeft')) {
        this.closeMobileButtons();
      } else {
        this.openMobileButtons();
      }
    },
    openMobileButtons: function openMobileButtons() {
      if (this.get('media.isMobile') && this.get('hasHoverButtons') && !this.get('bulkEditing')) {
        this.set('swipedLeft', true);
      }
    },
    closeMobileButtons: function closeMobileButtons() {
      if (this.get('media.isMobile') && this.get('hasHoverButtons')) {
        this.set('swipedLeft', false);
      }
    },
    shouldBeBlue: computed('groupedAppointment.service.starts_at', function () {
      var today = moment().tz(this.get('groupedAppointment.time_zone')).startOf('day');
      var date = moment(this.get('groupedAppointment.service.starts_at')).startOf('day');
      return [0, 1].includes(date.diff(today, 'days'));
    }),
    isUpcoming: computed('groupedAppointment.service.starts_at', function () {
      var today = moment().tz(this.get('groupedAppointment.time_zone')).startOf('day');
      var date = moment(this.get('groupedAppointment.service.starts_at'));
      return today.isBefore(date);
    }),
    isPastDue: computed('groupedAppointment.service.starts_at', 'groupedAppointment.service.starts_at_time', function () {
      var now = moment().tz(this.get('groupedAppointment.time_zone'));

      if (this.get('groupedAppointment.offered_service.all_day_service')) {
        return now > moment(this.get('groupedAppointment.service.starts_at')).add(1, 'day');
      }

      var day = moment(this.get('groupedAppointment.service.starts_at')).format('YYYY-MM-DD');
      var pickupWindow = this.get('groupedAppointment.pickup_window');

      if (pickupWindow) {
        var _pickupWindow$split = pickupWindow.split(' - '),
            _pickupWindow$split2 = _slicedToArray(_pickupWindow$split, 2),
            endTime = _pickupWindow$split2[1];

        return now > moment("".concat(day, " ").concat(endTime), 'YYYY-MM-DD h:mmA').add(this.get('groupedAppointment.offered_service.duration_in_minutes'), 'minutes').add(1, 'hour');
      } else {
        var time = this.get('groupedAppointment.service.starts_at_time');
        return now > moment("".concat(day, " ").concat(time), 'YYYY-MM-DD h:mm A').add(this.get('groupedAppointment.offered_service.duration_in_minutes'), 'minutes').add(1, 'hour');
      }
    }),
    hasHoverButtons: computed('bulkEditing', 'readOnly', 'isPetParent', 'groupedAppointment.can_delete', 'groupedAppointment.completed', 'isUpcoming', 'groupedAppointment.showCancellations', 'showReportCardActions', function () {
      if (this.get('readOnly')) {
        return false;
      }

      if (!this.get('isPetParent') && this.get('groupedAppointment.can_delete')) {
        return true;
      }

      var isCompleted = this.get('groupedAppointment.completed');
      var hasReportCard = this.get('showReportCardActions');
      return (this.get('showCanceledAppointments') || this.get('groupedAppointment.showRequests')) && (isCompleted && hasReportCard || !isCompleted && this.get('isUpcoming'));
    }),
    pets: computed('groupedAppointment.appointments.@each.pets.[]', function () {
      return this.get('groupedAppointment.appointments').map(function (appt) {
        return appt.get('pet');
      });
    }),
    hasStartedAppointments: computed('groupedAppointment.appointments.[]', function () {
      return this.get('checkStartedAppointment').hasStartedAppointment(this.get('groupedAppointment'));
    }),
    doesNotHaveStartedAppointments: computed.not('hasStartedAppointments'),
    specialInstructions: computed('groupedAppointment.special_instructions.[]', 'pets.[]', function () {
      var instructions = this.get('groupedAppointment.special_instructions');
      instructions = instructions.filter(function (instruction) {
        return instruction.note;
      });
      var apptCount = this.get('pets.length');

      if (apptCount > 1) {
        var uniqueNotes = {};
        instructions.forEach(function (instruction) {
          if (uniqueNotes[instruction.note]) {
            uniqueNotes[instruction.note].pushObject(instruction.name);
          } else {
            uniqueNotes[instruction.note] = [instruction.name];
          }
        });
        var notes = '';
        Object.keys(uniqueNotes).forEach(function (key) {
          notes += "For ".concat(_stringUtil.default.joinWithAnd(uniqueNotes[key]), ": ").concat(key, "\n");
        });
        return notes;
      } else if (instructions.length == 1) {
        return instructions[0].note;
      }
    }),
    cancellationNote: computed('groupedAppointment.cancellation_notes.[]', 'pets.[]', function () {
      var notes = this.get('groupedAppointment.cancellation_notes');
      notes = notes.filter(function (note) {
        return note.note;
      });
      var apptCount = this.get('pets.length');

      if (apptCount > 1) {
        var uniqueNotes = {};
        notes.forEach(function (note) {
          if (uniqueNotes[note.note]) {
            uniqueNotes[note.note].pushObject(note.name);
          } else {
            uniqueNotes[note.note] = [note.name];
          }
        });

        if (uniqueNotes.length > 1) {
          var noteString = '';
          Object.keys(uniqueNotes).forEach(function (key) {
            noteString += "For ".concat(_stringUtil.default.joinWithAnd(uniqueNotes[key]), ": ").concat(key, "\n");
          });
          return noteString;
        } else {
          return Object.keys(uniqueNotes)[0];
        }
      } else if (notes.length == 1) {
        return notes[0].note;
      }
    }),
    startsAtDate: computed('groupedAppointment.time_zone', 'groupedAppointment.service.starts_at', function () {
      return moment(this.get('groupedAppointment.service.starts_at')).tz(this.get('groupedAppointment.time_zone'));
    }),
    startsAtTime: computed('groupedAppointment.time_zone', 'groupedAppointment.service.starts_at', function () {
      return moment(this.get('groupedAppointment.service.starts_at')).tz(this.get('groupedAppointment.time_zone')).format('h:mm A');
    }),
    isNotPetParent: computed.not('isPetParent'),
    canArchiveAppointment: computed.and('isNotPetParent', 'doesNotHaveStartedAppointments'),
    actions: {
      cancelAppointment: function cancelAppointment() {
        this.cancelAppointment(this.get('groupedAppointment'));
      },
      modifyAppointment: function modifyAppointment() {
        this.modifyAppointment(this.get('groupedAppointment'));
      },
      archiveAppointment: function archiveAppointment() {
        this.archiveAppointment(this.get('groupedAppointment'));
      },
      restoreAppointment: function restoreAppointment() {
        this.restoreAppointment(this.get('groupedAppointment'));
      },
      selectAppointment: function selectAppointment() {
        this.selectAppointment(this.get('groupedAppointment'));
      },
      showReportCard: function showReportCard() {
        var _this2 = this;

        this.get('groupedAppointment.appointments').toArray().some(function (appointment) {
          if (appointment.get('grouped_report_card')) {
            _this2.showReportCard(appointment.get('grouped_report_card'));

            _this2.set('swipedLeft', false);

            return true;
          }
        });
      },
      toggleCancellationNotes: function toggleCancellationNotes() {
        this.toggleProperty('showCancellationNoteModal');
      }
    }
  });

  _exports.default = _default;
});