define("frontend/mixins/country-serializer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    serialize: function serialize(snapshot, options) {
      var amsSnapshot = this._super(snapshot, options);

      if (amsSnapshot.country) {
        amsSnapshot.country = amsSnapshot.country.replace(' ', '');
      }

      return amsSnapshot;
    }
  });

  _exports.default = _default;
});