define("frontend/components/client-billing-inputs", ["exports", "frontend/utils/invoice-frequency-options", "ember-data", "frontend/config/environment", "jquery"], function (_exports, _invoiceFrequencyOptions, _emberData, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ["billing-inputs"],
    store: inject.service(),
    stripeInfo: inject.service(),
    flashMessage: inject.service(),
    media: inject.service(),
    isMobile: computed.readOnly("media.isMobile"),
    noBillDeliveryRecipients: computed.readOnly("household.noBillDeliveryRecipients"),
    saving: false,
    showCardPanel: false,
    currentUser: inject.service(),
    settings: null,
    household: null,
    fetchingCustomerInfo: computed.readOnly("paymentMethods.isPending"),
    plaidRedirect: false,
    company: computed("settings.company_id", function () {
      var id = this.get("settings.company_id");

      if (id) {
        var promise = this.get("store").find("company", id);
        return _emberData.default.PromiseObject.create({
          promise: promise
        });
      }
    }),
    companyId: computed.readOnly("settings.company_id"),
    ownerUserId: computed("household", "household.owner", function () {
      if (this.get("household.owner")) {
        return this.get("household.owner").get("id");
      }

      return null;
    }),
    paymentMethods: computed("settings", "settings.company_id", function () {
      if (this.get("settings.company_id")) {
        var userId = this.get("ownerUserId");
        var companyId = this.get("settings.company_id");

        if (userId) {
          var promise = this.get("stripeInfo").fetchCustomerInfo(userId, companyId);
          return _emberData.default.PromiseObject.create({
            promise: promise
          });
        }
      }

      return null;
    }),
    invoiceFrequencyOptions: computed("company.invoice_frequencies.[]", "settings.invoice_frequency", "settings.biweeklyStart", "settings.biweeklyEnd", function () {
      var _this = this;

      if (!this.get("company.invoice_frequencies")) {
        return [];
      }

      var invoiceFrequencies = this.get("company.invoice_frequencies").map(function (invoiceFrequency) {
        var label = invoiceFrequency.get("displayName");
        var value = invoiceFrequency.get("name");

        var biweeklyStart = _this.get("company.biweeklyStart");

        var biweeklyEnd = _this.get("company.biweeklyEnd");

        var id = invoiceFrequency.get("id");

        if (value === _invoiceFrequencyOptions.default.BIWEEKLY) {
          label += " (".concat(biweeklyStart, " - ").concat(biweeklyEnd, ")");
        }

        return {
          value: value,
          label: label,
          id: id
        };
      }).sort(function (a, b) {
        return a.id - b.id;
      });

      if (this.get("hasCustomInvoiceFrequency")) {
        var currentFrequency = this.get("settings.invoice_frequency");
        var invoiceFrequency = this.get("store").createRecord("invoice-frequency", {
          name: currentFrequency
        });
        var label = invoiceFrequency.get("displayName");
        var value = invoiceFrequency.get("name");
        var biweeklyStart = this.get("company.biweeklyStart");
        var biweeklyEnd = this.get("company.biweeklyEnd");

        if (value === _invoiceFrequencyOptions.default.BIWEEKLY) {
          label += " (".concat(biweeklyStart, " - ").concat(biweeklyEnd, ")");
        }

        invoiceFrequencies = [{
          value: value,
          label: label
        }];
      }

      return invoiceFrequencies;
    }),
    hasCustomInvoiceFrequency: computed("company.invoice_frequencies.[]", "settings.invoice_frequency", function () {
      var currentFrequency = this.get("settings.invoice_frequency");

      if (!currentFrequency) {
        return false;
      }

      var companyFrequencies = this.get("company.invoice_frequencies");

      if (!companyFrequencies) {
        return true;
      }

      var found = companyFrequencies.filter(function (invoiceFrequency) {
        return invoiceFrequency.get("name") == currentFrequency;
      });
      return found.length == 0;
    }),
    selectedInvoiceFrequency: computed("invoiceFrequencyOptions", "settings.invoice_frequency", function () {
      var options = this.get("invoiceFrequencyOptions");
      var settings = this.get("settings");

      if (settings) {
        return options.find(function (opt) {
          return opt.value == settings.invoice_frequency;
        });
      }
    }),
    customInvoiceFrequencyTooltipText: computed("company.displayName", function () {
      return "You are on a custom billing frequency. Please contact ".concat(this.get("company.displayName"), " if you have any questions!");
    }),
    timePeriodClarify: computed("settings.invoice_frequency", function () {
      switch (this.get("settings.invoice_frequency")) {
        case "monthly":
          return "on the first of each month";

        case "semimonthly":
          return "on the 1st and 15th of each month";

        case "biweekly":
          return "every other Monday morning";

        case "weekly":
          return "every Monday morning";

        case "daily":
          return "every morning";

        default:
          return null;
      }
    }),
    invoiceFrequencyClarify: computed("timePeriodClarify", function () {
      var timePeriodClarify = this.get("timePeriodClarify");

      if (timePeriodClarify) {
        return "Invoice emails will be sent ".concat(timePeriodClarify, " to:");
      } else {
        return "You have selected not to accrue how much this client owes you. No bills will be generated.";
      }
    }),
    savedCardInfo: computed("paymentMethods.card", function () {
      var customer_info = this.get("paymentMethods");

      if (customer_info.get("card")) {
        return {
          brand: customer_info.get("card.brand"),
          last_four: customer_info.get("card.last_four")
        };
      } else {
        return null;
      }
    }),
    savedBankAccountInfo: computed("paymentMethods.bank_account", function () {
      var customer_info = this.get("paymentMethods");

      if (customer_info.get("bank_account")) {
        return {
          brand: customer_info.get("bank_account.bank_name"),
          last_four: customer_info.get("bank_account.last_four"),
          verified: customer_info.get("bank_account.verified")
        };
      } else {
        return null;
      }
    }),
    showSavedCard: computed("savedCardInfo", "paymentMethods.default", function () {
      return this.get("paymentMethods.default") === "card" && this.get("savedCardInfo");
    }),
    showSavedBankAccount: computed("savedBankAccountInfo", "paymentMethods.default", function () {
      return this.get("paymentMethods.default") === "bank_account" && this.get("savedBankAccountInfo");
    }),
    actions: {
      setSaving: function setSaving() {
        if (this.setSaving) {
          this.setSaving();
        }
      },
      closePaymentMethodModal: function closePaymentMethodModal() {
        this.set("showingPaymentMethodModal", false);
        this.set("plaidRedirect", false);
      },
      changeInvoiceFrequency: function changeInvoiceFrequency(invoiceFrequency) {
        var _this2 = this;

        this.set("previousInvoiceFrequency", this.get("settings.invoice_frequency"));
        this.set("settings.invoice_frequency", invoiceFrequency.value);
        var householdId = this.get("household.id");
        var companyId = this.get("company.id");
        this.set("loadingStartBillingDate", true);
        this.set("showInvoiceFrequencyEffectiveDate", true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(householdId, "/billing_start_date?company_id=").concat(companyId, "&invoice_frequency=").concat(invoiceFrequency.value),
          method: "GET"
        }).done(function (response) {
          _this2.set("newBillingPeriodStartDate", moment(response.start_date));

          _this2.set("loadingStartBillingDate", false);
        });
      },
      closeInvoiceFrequencyEffectiveDate: function closeInvoiceFrequencyEffectiveDate() {
        var successful = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (!successful) {
          this.set("settings.invoice_frequency", this.get("previousInvoiceFrequency"));
        }

        this.set("showInvoiceFrequencyEffectiveDate", false);
      },
      activateSidePanel: function activateSidePanel() {
        this.set("anySidePanelActive", true);
      },
      deactivateSidePanel: function deactivateSidePanel() {
        this.set("anySidePanelActive", false);

        if (this.reloadOwner) {
          this.reloadOwner();
        }
      },
      saveBillDeliveryOptions: function saveBillDeliveryOptions(add_or_remove, email) {
        var householdId = this.get("household.id");
        var company_id = this.get("company.id");
        var method = add_or_remove == "add" ? "POST" : "DELETE";

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(householdId, "/bill_recipient"),
          method: method,
          data: {
            email: email,
            company_id: company_id
          }
        });
      },
      savePaymentMethod: function savePaymentMethod(token, paymentMethod) {
        var _this3 = this;

        this.set("plaidRedirect", false);
        this.set("showingPaymentMethodModal", false);
        var userId = this.get("ownerUserId");
        this.set("updatingPaymentMethod", true);
        this.get("stripeInfo").createPaymentMethod(userId, token, paymentMethod).then(function () {
          _this3.notifyPropertyChange("paymentMethods");

          _this3.set("updatingPaymentMethod", false);
        }).catch(function (result) {
          _this3.get("flashMessage").sendFlashMessage(result.payload.message, "user-error");

          _this3.set("showingPaymentMethodModal", true);

          _this3.set("updatingPaymentMethod", false);
        });
      },
      showRemovePaymentMethod: function showRemovePaymentMethod() {
        this.set("showRemovePaymentMethod", true);
      },
      redirectToMicrodeposits: function redirectToMicrodeposits() {
        this.get("redirectToMicrodeposits")();
      },
      confirmRemovePaymentMethod: function confirmRemovePaymentMethod() {
        var _this4 = this;

        var householdId = this.get("household.id");
        var defaultMethod = this.get("paymentMethods.default");
        var payment_method_id = this.get("paymentMethods.".concat(defaultMethod, ".id"));
        var brand = this.get("paymentMethods.".concat(defaultMethod, ".brand"));
        var lastFour = this.get("paymentMethods.".concat(defaultMethod, ".last_four"));
        this.set("showRemovePaymentMethod", false);
        this.set("updatingPaymentMethod", true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(householdId, "/stripe_payment_method"),
          method: "DELETE",
          data: {
            payment_method_id: payment_method_id
          }
        }).then(function () {
          _this4.set("updatingPaymentMethod", false);

          _this4.notifyPropertyChange("paymentMethods");

          _this4.get("flashMessage").success("You've removed ".concat(brand, " ending ").concat(lastFour, " from your account"));
        });
      },
      cancelRemovePaymentMethod: function cancelRemovePaymentMethod() {
        this.set("showRemovePaymentMethod", false);
      }
    }
  });

  _exports.default = _default;
});