define("frontend/components/send-documents-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _Ember$Component$exte;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var computed = Ember.computed;

  var _default = Ember.Component.extend((_Ember$Component$exte = {
    company: null,
    household: null,
    careProviderAddedNewClient: null,
    clientContracts: null,
    leftButtonText: computed('careProviderAddedNewClient', function () {
      return this.get('careProviderAddedNewClient') ? 'skip' : 'cancel';
    })
  }, _defineProperty(_Ember$Component$exte, "clientContracts", computed('company.client_contracts.[]', 'signatureRequests.[]', 'careProviderAddedNewClient', function () {
    var signatureRequests = this.get('signatureRequests');
    var careProviderAddedNewClient = this.get('careProviderAddedNewClient');
    var clientContracts = this.get('company.client_contracts').map(function (contract) {
      return Ember.Object.create({
        id: contract.get('id'),
        document_name: contract.get('document_name'),
        created_at: contract.get('created_at'),
        selected: careProviderAddedNewClient ? true : false,
        sent: signatureRequests.isAny('client_contract_id', parseInt(contract.get('id')))
      });
    });
    var numChoices = clientContracts.filterBy('sent', false).length;

    if (numChoices === 1) {
      clientContracts.filterBy('sent', false).firstObject.set('selected', true);
    }

    return clientContracts;
  })), _defineProperty(_Ember$Component$exte, "description", computed('clientContracts.length', 'household.owner.last_name', 'careProviderAddedNewClient', function () {
    var clientContractsLength = this.get('clientContracts.length');
    var lastName = this.get('household.owner.last_name');
    var careProviderAddedNewClient = this.get('careProviderAddedNewClient');

    if (careProviderAddedNewClient) {
      return "Choose documents that you\u2019d like to send to the ".concat(lastName, " family with your invite. All templates are selected by default.");
    } else if (clientContractsLength === 1) {
      return "Are you sure you want to send the below document to the ".concat(lastName, " family?");
    } else {
      return "Select one or more documents that you\u2019d like to send to the ".concat(lastName, " family.");
    }
  })), _defineProperty(_Ember$Component$exte, "canSendDocuments", computed('clientContracts.@each.selected', function () {
    return this.get('clientContracts').isAny('selected', true);
  })), _defineProperty(_Ember$Component$exte, "selectedContractIds", computed('clientContracts.@each.selected', function () {
    return this.get('clientContracts').filterBy('selected').mapBy('id');
  })), _Ember$Component$exte));

  _exports.default = _default;
});