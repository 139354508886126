define("frontend/services/current-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service,
      isEmpty = Ember.isEmpty;

  var _default = Ember.Service.extend({
    session: service('session'),
    store: service(),
    load: function load() {
      var _this = this;

      var userId = this.get('session.data.authenticated.tokenData.user_id');

      if (this.get('user')) {
        return Ember.RSVP.resolve(this.get('user'));
      }

      if (!isEmpty(userId)) {
        return this.get('store').findRecord('user', userId).then(function (user) {
          _this.set('user', user);
        });
      } else {
        return Ember.RSVP.resolve();
      }
    },
    reload: function reload() {
      var _this2 = this;

      var userId = this.get('session.data.authenticated.tokenData.user_id');

      if (this.get('user') && !isEmpty(userId)) {
        return this.get('store').findRecord('user', userId).then(function (user) {
          _this2.set('user', user);
        });
      }
    }
  });

  _exports.default = _default;
});