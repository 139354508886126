define("frontend/components/index-page", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Component.extend({
    router: inject.service(),
    media: inject.service(),
    didInsertElement: function didInsertElement() {
      var offset = (0, _jquery.default)('#top').offset().top;

      if (!this.get('media.isMobile')) {
        (0, _jquery.default)(window).on('scroll', function () {
          if ((0, _jquery.default)(window).scrollTop() > offset - 10) {
            (0, _jquery.default)('#top').addClass('fixed');
            (0, _jquery.default)('#welcome nav').addClass('hidden');
          } else {
            (0, _jquery.default)('#top').removeClass('fixed');
            (0, _jquery.default)('#welcome nav').removeClass('hidden');
          }
        });
      }

      (0, _jquery.default)('#top a.scroll-link').click(function () {
        var viewPort = (0, _jquery.default)('html, body');
        viewPort.on('scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove', function () {
          if (viewPort.is(':animated')) {
            viewPort.stop();
          }
        });
        viewPort.stop(true).animate({
          scrollTop: (0, _jquery.default)(_jquery.default.attr(this, 'href')).offset().top
        }, 500);
        return false;
      });
      (0, _jquery.default)('body').scrollspy({
        target: '#top'
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)('#top a').off('click');
      (0, _jquery.default)(window).off('scroll');
      (0, _jquery.default)('html, body').off('scroll mousedown wheel DOMMouseScroll mousewheel keyup touchmove');
    },
    actions: {
      sendMessage: function sendMessage() {
        var props = this.getProperties('name', 'email', 'subject', 'message');
        this.setProperties({
          name: '',
          email: '',
          subject: '',
          message: ''
        });
        this.get('sendMessage')(props);
      },
      transitionToSignUp: function transitionToSignUp() {
        this.get('router').transitionTo('sign-up');
      },
      transitionToCovidLetter: function transitionToCovidLetter() {
        this.get('router').transitionTo('covid-letter');
      },
      submitBetaRequest: function submitBetaRequest() {
        var props = this.getProperties('name', 'email');
        this.setProperties({
          name: '',
          email: ''
        });
        this.get('submitBetaRequest')(props);
      }
    }
  });

  _exports.default = _default;
});