define("frontend/components/offered-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['offered-service'],
    attributeBindings: ['style'],
    classNameBindings: ['faded', 'selected'],
    media: inject.service(),
    offeredService: null,
    overridePrices: false,
    priceOverride: null,
    incrementalPriceOverride: null,
    faded: false,
    petsSelectedLength: 0,
    descriptionExpanded: false,
    showPrices: true,
    alwaysShowEffectiveDate: false,
    addOnServices: [],
    maxDescriptionLength: computed('media.isMobile', function () {
      if (this.get('media.isMobile')) {
        return 90;
      } else {
        return 130;
      }
    }),
    hasLongDescription: computed('maxDescriptionLength', 'offeredService.description', function () {
      return this.get('offeredService.description.length') > this.get('maxDescriptionLength');
    }),
    style: computed('offeredService.color_hex', function () {
      var color = this.get('offeredService.color_hex');

      if (this.get('offeredService.is_add_on')) {
        color = 'DBDCDE';
      }

      return "border: 1px solid #".concat(color);
    }),
    price: computed('offeredService.latest_price', 'priceOverride', function () {
      var priceOverride = this.get('priceOverride');
      var price = this.get('offeredService.latest_price');
      var overridePrices = this.get('overridePrices');

      if (overridePrices) {
        return priceOverride;
      } else {
        return price;
      }
    }),
    incrementalPrice: computed('offeredService.latest_incremental_price', 'incrementalPriceOverride', function () {
      var incrementalPriceOverride = this.get('incrementalPriceOverride');
      var incrementalPrice = this.get('offeredService.latest_incremental_price');
      var overridePrices = this.get('overridePrices');

      if (overridePrices) {
        return incrementalPriceOverride;
      } else {
        return incrementalPrice;
      }
    }),
    hasIncrementalPrice: computed('price', 'incrementalPrice', function () {
      return this.get('price') != this.get('incrementalPrice');
    }),
    pricePerVisit: computed('petsSelectedLength', 'price', 'incrementalPrice', function () {
      return this.get('price') + (this.get('petsSelectedLength') - 1) * this.get('incrementalPrice');
    }),
    showEffective: computed('overridePrices', 'offeredService.end_date', 'offeredService.start_date', function () {
      if (this.get('overridePrices')) {
        return false;
      }

      if (this.get('alwaysShowEffectiveDate')) {
        return true;
      }

      var startDate = this.get('offeredService.price_start_date');
      var endDate = this.get('offeredService.price_end_date');
      return !endDate && startDate && moment(startDate) > moment();
    }),
    effectiveDate: computed('offeredService.price_start_date', 'offeredService.company_time_zone', function () {
      return moment.tz(this.get('offeredService.price_start_date'), this.get('offeredService.company_time_zone')).format('MMM D');
    }),
    actions: {
      toggleDescriptionExpansion: function toggleDescriptionExpansion() {
        this.toggleProperty('descriptionExpanded');
      },
      clickOfferedServiceAddOn: function clickOfferedServiceAddOn(addOnService) {
        if (this.get('clickOfferedServiceAddOn')) {
          this.get('clickOfferedServiceAddOn')(addOnService);
        }
      }
    }
  });

  _exports.default = _default;
});