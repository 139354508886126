define("frontend/components/invoice-receipt/invoice-summary-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'header',
    classNames: ['invoice-summary-header', 'invoice-summary-line-item'],
    classNameBindings: ['total:invoice-summary-header-total'],
    total: false
  });

  _exports.default = _default;
});