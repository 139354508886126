define("frontend/templates/components/document-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hono120X",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[7,\"input\",true],[10,\"style\",\"display: none;\"],[10,\"name\",\"filename\"],[10,\"accept\",\"*\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/document-upload.hbs"
    }
  });

  _exports.default = _default;
});