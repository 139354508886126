define("frontend/components/pet-inputs/pet-vaccinations", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['layout-column', 'flex-100', 'pet-vaccinations'],
    classNameBindings: ['hasVaccinations'],
    store: inject.service(),
    ajax: inject.service(),
    allVaccinations: computed('pet.pet_type', function () {
      var pet_type_id = this.get('pet.pet_type_id');
      var url = "/api/v2/vaccinations?pet_type_id=".concat(pet_type_id);
      var promise = this.get('ajax').request(url).then(function (resp) {
        return resp.vaccinations;
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    hasVaccinations: computed('pet.vaccinations.@each.dirtyType', function () {
      var vaccinations = this.get('pet.vaccinations');
      var nonDeletedVaccinations = vaccinations.filter(function (v) {
        return v.dirtyType !== "deleted";
      });
      return nonDeletedVaccinations.get("length");
    }),
    actions: {
      updateVaccinations: function updateVaccinations() {
        if (this.get('pet.vaccinations.length') === 0) {
          this.send('addAnotherVaccination');
        }

        this.set('showingModal', true);
      },
      addAnotherVaccination: function addAnotherVaccination() {
        var newVaccination = this.get('store').createRecord('vaccination');
        this.get('pet.vaccinations').pushObject(newVaccination);
      }
    }
  });

  _exports.default = _default;
});