define("frontend/templates/components/client-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+q+qKFCJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"summary\",\"isFulfilled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"summary-text\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[24,[\"summary\",\"household_count\"]],1],null]],null,{\"statements\":[[0,\"      You have \"],[7,\"span\",true],[10,\"class\",\"household-count\"],[8],[1,[24,[\"summary\",\"household_count\"]],false],[9],[0,\" client family\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      You have \"],[7,\"span\",true],[10,\"class\",\"household-count\"],[8],[1,[24,[\"summary\",\"household_count\"]],false],[9],[0,\" client families\\n\"]],\"parameters\":[]}],[4,\"if\",[[28,\"eq\",[[24,[\"summary\",\"pet_count\"]],1],null]],null,{\"statements\":[[0,\"      with \"],[7,\"span\",true],[10,\"class\",\"pet-count\"],[8],[1,[24,[\"summary\",\"pet_count\"]],false],[9],[0,\" pet\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      with \"],[7,\"span\",true],[10,\"class\",\"pet-count\"],[8],[1,[24,[\"summary\",\"pet_count\"]],false],[9],[0,\" pets\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[22,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]}],[7,\"div\",true],[10,\"class\",\"girl-dog\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"search-input-container\"],[8],[0,\"\\n  \"],[1,[28,\"search-input\",null,[[\"value\",\"debounce\",\"placeholder\"],[[24,[\"filter\"]],true,\"Search by human or pet name\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"currentUser\",\"user\",\"canAddEditClients\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"helper-text\"],[8],[0,\"Can’t find someone you’re looking for?\"],[9],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"color\",\"click\"],[\"red-white\",[28,\"route-action\",[\"transitionToAddClient\"],null]]],{\"statements\":[[0,\"    add a new client\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/client-summary.hbs"
    }
  });

  _exports.default = _default;
});