define("frontend/templates/components/text-input-callout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "xrNaNcFu",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"mobile-text-input\",null,[[\"readonly\",\"disabled\",\"label\",\"value\",\"borderBottom\",\"borderTop\",\"type\"],[[24,[\"readonly\"]],[24,[\"disabled\"]],[24,[\"label\"]],[24,[\"value\"]],[24,[\"borderBottom\"]],[24,[\"borderTop\"]],[24,[\"type\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"input-callout\",null,[[\"tabindex\",\"clickCallout\",\"label\",\"callout\",\"inputActivated\"],[[24,[\"tabindex\"]],[28,\"action\",[[23,0,[]],\"clickCallout\"],null],[24,[\"label\"]],[24,[\"callout\"]],[24,[\"inputActivated\"]]]],{\"statements\":[[0,\"    \"],[1,[28,\"input\",null,[[\"type\",\"tabindex\",\"readonly\",\"value\",\"focus-out\",\"focus-in\"],[[24,[\"type\"]],[24,[\"tabindex\"]],[24,[\"readonly\"]],[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"focusOut\"],null],[28,\"action\",[[23,0,[]],\"focusIn\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"and\",[[28,\"and\",[[24,[\"hover\"]],[24,[\"hint\"]]],null],[24,[\"showHint\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"alert-hint\",null,[[\"hint\",\"deprecatedOldStyling\"],[[24,[\"hint\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/text-input-callout.hbs"
    }
  });

  _exports.default = _default;
});