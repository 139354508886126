define("frontend/components/invoice-receipt/adjustments-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['invoice-summary'],
    adjustmentsSorting: ['updated_at:desc'],
    sortedManualAdjustments: computed.sort('manualAdjustments', 'adjustmentsSorting'),
    multipleWeekendSurcharges: computed.gt('groupedWeekendSurcharges.length', 1),
    multipleOffHoursSurcharges: computed.gt('groupedOffHoursSurcharges.length', 1),
    multipleLateBookingSurcharges: computed.gt('groupedLateBookingSurcharges.length', 1),
    multipleHolidaySurcharges: computed.gt('groupedHolidaySurchargesholidaySurcharges.length', 1),
    multipleCancellationFees: computed.gt('cancellationFees.length', 1),
    indentAdjustments: computed.or('multipleWeekendSurcharges', 'multipleLateBookingSurcharges', 'multipleHolidaySurcharges', 'multipleOffHoursSurcharges', 'multipleCancellationFees'),
    manualAdjustments: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return !adjustment.get('surcharge_policy_type') && !adjustment.get('is_cancellation_fee') && !adjustment.get('is_sales_tax');
      });
    }),
    hasNonTaxableAdjustments: computed('adjustments.@each.apply_sales_tax', function () {
      return this.get('adjustments').some(function (adjustment) {
        return !adjustment.get('apply_sales_tax');
      });
    }),
    showLowerInvoiceSummarySection: computed('salesTaxTotal', 'hasNonTaxableAdjustments', function () {
      var hasNonTaxableAdjustments = this.get('hasNonTaxableAdjustments') || false;
      var salesTaxTotal = this.get('salesTaxTotal') || 0;
      return hasNonTaxableAdjustments || salesTaxTotal > 0;
    }),
    salesTaxAdjustment: computed('adjustments.[]', function () {
      var adjustments = this.get('adjustments').filter(function (adjustment) {
        return adjustment.get('is_sales_tax');
      });
      return adjustments.get('firstObject');
    }),
    salesTaxRate: computed('salesTaxAdjustment', function () {
      var salesTaxAdjustment = this.get('salesTaxAdjustment');
      var rate = salesTaxAdjustment ? salesTaxAdjustment.get('sales_tax_rate') || 0 : 0;
      return "Estimated sales tax (".concat(rate, "%)");
    }),
    // OFF HOURS SURCHARGES
    offHoursSurcharges: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return adjustment.get('isOffHoursSurcharge');
      });
    }),
    offHoursSurchargesTotal: computed('offHoursSurcharges.[]', function () {
      return this.get('offHoursSurcharges').reduce(function (a, b) {
        return a + parseFloat(b.get('amount'));
      }, 0);
    }),
    offHoursSurchargesTooltip: computed('offHoursSurcharges.[]', function () {
      var groupedSurcharges = this.groupSurcharges(this.get('offHoursSurcharges'));
      var text = "".concat(this.get('offHoursSurcharges.firstObject.surcharge_policy_description'), " applied to:\n\n");
      groupedSurcharges.forEach(function (surcharge, index) {
        if (index > 0) {
          text += '\n';
        }

        text += "".concat(surcharge.get('label'));
      });
      return text;
    }),
    // WEEKEND SURCHARGES
    weekendSurcharges: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return adjustment.get('isWeekendSurcharge');
      });
    }),
    weekendSurchargesTotal: computed('weekendSurcharges.[]', function () {
      return this.get('weekendSurcharges').reduce(function (a, b) {
        return a + parseFloat(b.get('amount'));
      }, 0);
    }),
    weekendSurchargesTooltip: computed('weekendSurcharges.[]', function () {
      var groupedSurcharges = this.groupSurcharges(this.get('weekendSurcharges'));
      var text = "".concat(this.get('weekendSurcharges.firstObject.surcharge_policy_description'), " applied to:\n\n");
      groupedSurcharges.forEach(function (surcharge, index) {
        if (index > 0) {
          text += '\n';
        }

        text += "".concat(surcharge.get('label'));
      });
      return text;
    }),
    // HOLIDAY SURCHARGES
    holidaySurcharges: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return adjustment.get('isHolidaySurcharge');
      });
    }),
    holidaySurchargesTotal: computed('holidaySurcharges.[]', function () {
      return this.get('holidaySurcharges').reduce(function (a, b) {
        return a + parseFloat(b.get('amount'));
      }, 0);
    }),
    holidaySurchargesTooltip: computed('holidaySurcharges.[]', function () {
      var groupedSurcharges = this.groupSurcharges(this.get('holidaySurcharges'));
      var text = "".concat(this.get('holidaySurcharges.firstObject.surcharge_policy_description'), " applied to:\n\n");
      groupedSurcharges.forEach(function (surcharge, index) {
        if (index > 0) {
          text += '\n';
        }

        text += "".concat(surcharge.get('label'));
      });
      return text;
    }),
    // LATE BOOKING SURCHARGES
    lateBookingSurcharges: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return adjustment.get('isLateBookingSurcharge');
      });
    }),
    lateBookingSurchargesTotal: computed('lateBookingSurcharges.[]', function () {
      return this.get('lateBookingSurcharges').reduce(function (a, b) {
        return a + parseFloat(b.get('amount'));
      }, 0);
    }),
    lateBookingSurchargesTooltip: computed('lateBookingSurcharges.[]', function () {
      var groupedSurcharges = this.groupSurcharges(this.get('lateBookingSurcharges'));
      var text = "".concat(this.get('lateBookingSurcharges.firstObject.surcharge_policy_description'), " applied to:\n\n");
      groupedSurcharges.forEach(function (surcharge, index) {
        if (index > 0) {
          text += '\n';
        }

        text += "".concat(surcharge.get('label'));
      });
      return text;
    }),
    // CANCELLATION SURCHARGES
    cancellationFees: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return adjustment.get('is_cancellation_fee');
      });
    }),
    cancellationFeesTotal: computed('cancellationFees.[]', function () {
      return this.get('cancellationFees').reduce(function (a, b) {
        return a + parseFloat(b.get('amount'));
      }, 0);
    }),
    cancellationFeesTooltip: computed('cancellationFees.[]', function () {
      var groupedSurcharges = this.groupSurcharges(this.get('cancellationFees'));
      var text = "".concat(this.get('cancellationFees.firstObject.surcharge_policy_description'), " applied to:\n\n");
      groupedSurcharges.forEach(function (surcharge, index) {
        if (index > 0) {
          text += '\n';
        }

        text += "".concat(surcharge.get('label'));
      });
      return text;
    }),
    groupedCancellationFees: computed('cancellationFees.[]', function () {
      return this.groupSurcharges(this.get('cancellationFees'));
    }),
    groupedWeekendSurcharges: computed('weekendSurcharges.[]', function () {
      return this.groupSurcharges(this.get('weekendSurcharges'));
    }),
    groupedOffHoursSurcharges: computed('offHoursSurcharges.[]', function () {
      return this.groupSurcharges(this.get('offHoursSurcharges'));
    }),
    groupedLateBookingSurcharges: computed('lateBookingSurcharges.[]', function () {
      return this.groupSurcharges(this.get('lateBookingSurcharges'));
    }),
    groupedHolidaySurcharges: computed('holidaySurcharges.[]', function () {
      return this.groupSurcharges(this.get('holidaySurcharges'));
    }),
    groupSurcharges: function groupSurcharges(surcharges) {
      var groupedSurcharges = {};
      surcharges.forEach(function (surcharge) {
        var key = "".concat(surcharge.get('appointment_date'), " ").concat(surcharge.get('offered_service_name'));

        if (!groupedSurcharges[key]) {
          groupedSurcharges[key] = Ember.Object.create({
            appointment_date: surcharge.get('appointment_date'),
            appointment_sortable_date: surcharge.get('appointment_sortable_date'),
            categoryName: surcharge.get('appointment_category_name'),
            label: key,
            amount: 0,
            adjustments: [],
            selected: false
          });
        }

        groupedSurcharges[key].amount += parseFloat(surcharge.get('amount'));
        groupedSurcharges[key].adjustments.push(surcharge);
      });
      return Object.values(groupedSurcharges);
    }
  });

  _exports.default = _default;
});