define("frontend/components/google-address-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['google-address-link'],
    attributeBindings: ['href', 'target', 'rel'],
    tagName: 'a',
    target: 'blank',
    rel: 'noopener',
    address: null,
    href: computed('address', function () {
      var address = encodeURIComponent(this.get('address'));
      return "https://maps.google.com?q=".concat(address);
    })
  });

  _exports.default = _default;
});