define("frontend/templates/admin/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ABhziQCl",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"impersonate\"],[8],[0,\"\\n  \"],[7,\"label\",true],[8],[0,\"Email\"],[9],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"name\",\"placeholder\",\"value\",\"class\"],[\"impersonate\",\"email\",[24,[\"impersonateEmail\"]],\"form-control\"]]],false],[0,\"\\n  \"],[4,\"ppb-button\",null,[[\"color\",\"click\"],[\"green\",[28,\"action\",[[23,0,[]],\"impersonate\"],null]]],{\"statements\":[[0,\"Impersonate\"]],\"parameters\":[]},null],[0,\"\\n\"],[9],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/admin/index.hbs"
    }
  });

  _exports.default = _default;
});