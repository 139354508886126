define("frontend/components/quill-viewer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      var Font = Quill.import('formats/font');
      Font.whitelist = ['sans-serif', 'serif', 'monospace', 'gotham', 'gotham-rounded', 'gotham-narrow', 'courier-new'];
      Quill.register(Font, true);
      this.quill = new Quill('#quill-viewer', {
        theme: 'snow',
        modules: {
          toolbar: false
        },
        readOnly: true
      });
      this.updateQuillContents();
    },
    deltaObserver: Ember.observer('delta', function () {
      this.updateQuillContents();
    }),
    updateQuillContents: function updateQuillContents() {
      var delta = this.get('delta');

      if (delta) {
        this.quill.setContents(JSON.parse(delta));
      } else {
        this.quill.setContents([]);
      }
    }
  });

  _exports.default = _default;
});