define("frontend/initializers/export-application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;

  // sets the ember application in a well known place for access via the outside world (i.e. braintree)
  function initialize(application) {
    window.ppb = application;
  }

  var _default = {
    name: 'export-application',
    initialize: initialize
  };
  _exports.default = _default;
});