define("frontend/controllers/inbox", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    infinity: inject.service(),
    media: inject.service(),
    router: inject.service(),
    currentRouteName: computed.readOnly('router.currentRouteName'),
    onThread: computed.equal('currentRouteName', 'inbox.household'),
    onInbox: computed.equal('currentRouteName', 'inbox.index'),
    onDistributionListThread: computed.equal('currentRouteName', 'inbox.distribution-list'),
    queryParams: ['filter'],
    filter: '',
    selectedHousehold: null,
    noHouseholds: computed.equal('model.allHouseholds.length', 0),
    noHouseholdsWithMessages: computed.equal('model.householdsWithMessages.length', 0),
    showingNewMessagePage: false,
    selectedHouseholds: [],
    specificDatesSelected: false,
    selectedDistributionList: '',
    familiesWithAppointmentsText: '',
    familiesWithAppointmentsTitle: 'Families with appointments on...',
    messagesForDistributionThreadInfModel: null,
    showMoreOptions: false,
    sortedHouseholdsWithMessages: computed('model.householdsWithMessages.[]', 'model.householdsWithMessages.@each.last_message_time', function () {
      var sortedContent = this.get('model.householdsWithMessages.content').sort(function (householdA, householdB) {
        var firstMessageTime = householdA.get('last_message_time');
        var secondMessageTime = householdB.get('last_message_time');

        if (firstMessageTime == secondMessageTime) {
          householdA.get('id') < householdB.get('id') ? 1 : -1;
        }

        firstMessageTime < secondMessageTime ? 1 : -1;
      });
      this.get('infinity').replace(this.get('model.householdsWithMessages'), sortedContent);
      return this.get('model.householdsWithMessages');
    }),
    showNoHouseholdsError: computed('filter', 'noHouseholds', 'noHouseholdsWithMessages', 'showingNewMessagePage', function () {
      return this.get('showingNewMessagePage') && this.get('noHouseholds') || !this.get('showingNewMessagePage') && this.get('noHouseholdsWithMessages');
    }),
    showNoError: computed('showingNewMessagePage', 'noHouseholdsWithMessages', function () {
      return !this.get('showingNewMessagePage') && this.get('noHouseholdsWithMessages') && !this.get('filter');
    }),
    sidePanelClass: computed('selectedHouseholds.[]', function () {
      if (this.get('selectedHouseholds.length') > 0) {
        return 'show-top-bar';
      }

      return '';
    }),
    showSidePanel: computed('onInbox', 'media.isMobile', 'selectedHouseholdsForNewMessage', 'showingNewMessagePage', 'noHouseholdsWithMessages', 'filter', function () {
      if (!this.get('showingNewMessagePage') && this.get('noHouseholdsWithMessages') && !this.get('media.isMobile') && !this.get('filter')) {
        return false;
      }

      return (this.get('onInbox') || !this.get('media.isMobile')) && !this.get('selectedHouseholdsForNewMessage');
    }),
    messagesForSingleHousehold: computed('showingNewMessagePage', 'selectedHouseholds.[]', function () {
      if (this.get('showingNewMessagePage') && this.get('selectedHouseholds.length') == 1) {
        var company_id = this.get('currentUser.user.company.id');
        return this.get('infinity').model('message', {
          household_id: this.get('selectedHouseholds.firstObject.id'),
          company_id: company_id,
          perPage: 20,
          startingPage: 1
        });
      }
    }),
    messagesForDistributionThread: computed('showingNewMessagePage', 'selectedDistributionList', 'selectedHouseholds.[]', function () {
      // This will recalculate anytime more households are fetched causing it
      // to flash a blank screen, so we're caching it instead
      if (this.get('messagesForDistributionThreadInfModel')) {
        return this.get('messagesForDistributionThreadInfModel');
      }

      if (this.get('showingNewMessagePage') && this.get('selectedDistributionList') !== '') {
        var company_id = this.get('currentUser.user.company.id');
        var infModel = this.get('infinity').model('distribution-message', {
          company_id: company_id,
          perPage: 20,
          startingPage: 1
        });
        this.set('messagesForDistributionThreadInfModel', infModel);
        return infModel;
      }
    }),
    actions: {
      selectHousehold: function selectHousehold(household) {
        this.transitionToRoute('inbox.household', household.get('id'));
      },
      selectDistributionList: function selectDistributionList() {
        this.set('selectedHousehold', null);
        this.transitionToRoute('inbox.distribution-list');
      },
      clickedHouseholdForNewMessage: function clickedHouseholdForNewMessage(household) {
        var selectedHouseholds = this.get('selectedHouseholds');
        var found = false;
        selectedHouseholds.forEach(function (selectedHousehold) {
          if (selectedHousehold.get('id') == household.get('id')) {
            selectedHouseholds.removeObject(household);
            found = true;
          }
        });

        if (!found) {
          selectedHouseholds.pushObject(household);
        }
      },
      callbackAfterSentMessage: function callbackAfterSentMessage() {
        var _this = this;

        var householdId = this.get('selectedHouseholds.firstObject.id');
        this.transitionToRoute('inbox.household', householdId).then(function () {
          _this.set('showingNewMessagePage', false);

          _this.set('selectedHouseholds', []);

          _this.send('parentRefresh');
        });
      },
      clickedNewMessage: function clickedNewMessage() {
        this.toggleProperty('showingNewMessagePage');
        this.set('filter', '');
        this.set('selectedHouseholdsForNewMessage', false);
        this.set('selectedHouseholds', []);

        if (!this.get('showingNewMessagePage')) {
          this.send('parentRefresh');
        }
      },
      transitionBackToInbox: function transitionBackToInbox() {
        this.set('selectedHouseholdsForNewMessage', false);
        this.set('showingNewMessagePage', false);
        this.set('selectedDistributionList', '');
        this.set('selectedHouseholds', []);
        this.send('parentRefresh');
        this.send('transitionToInbox');
      },
      transitionBackToDistributionListThread: function transitionBackToDistributionListThread() {
        this.set('selectedHousehold', null);
        this.set('selectedHouseholdsForNewMessage', false);
        this.set('showingNewMessagePage', false);
        this.set('selectedDistributionList', '');
        this.set('selectedHouseholds', []);
        this.send('parentRefresh');
        this.send('transitionToDistributionList');
      },
      mobileClickedDone: function mobileClickedDone() {
        var _this2 = this;

        this.toggleProperty('showingNewMessagePage');
        var households = this.get('selectedHouseholds');
        var length = households.get('length');

        if (length == 1) {
          var householdId = households.get('firstObject.id');
          this.transitionToRoute('inbox.household', householdId).then(function () {
            _this2.set('showingNewMessagePage', false);

            _this2.set('selectedHouseholds', []);

            _this2.send('parentRefresh');
          });
        } else if (length > 1) {
          this.set('selectedHouseholdsForNewMessage', true);
        }
      },
      transitionBackToNewMessage: function transitionBackToNewMessage() {
        this.toggleProperty('showingNewMessagePage');
        this.set('selectedHouseholdsForNewMessage', false);
      },
      mobileTransitionToHousehold: function mobileTransitionToHousehold() {
        this.send('mobileToggleMoreOptions');
        this.send('goToHousehold', this.get('selectedHousehold'));
      },
      mobileArchiveThread: function mobileArchiveThread() {
        var _this3 = this;

        this.send('mobileToggleMoreOptions');

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/messages/archive_thread"),
          method: 'POST',
          data: {
            household_id: this.get('selectedHousehold')
          }
        }).then(function () {
          _this3.send('transitionBackToInbox');
        });
      },
      mobileToggleMoreOptions: function mobileToggleMoreOptions() {
        this.toggleProperty('showMoreOptions');
      }
    }
  });

  _exports.default = _default;
});