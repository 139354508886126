define("frontend/templates/components/client-list/pet-output/medical-mobile-action-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XGyq39u9",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"address\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"medical-mobile-action-bar__item\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"clickAddress\",[24,[\"address\"]]],null]],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n    \"],[1,[28,\"inline-svg\",[\"assets/images/directions-icon.svg\"],null],false],[0,\"Directions\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"email\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"medical-mobile-action-bar__item\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"clickEmail\",[24,[\"email\"]]],null]],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n    \"],[1,[28,\"inline-svg\",[\"assets/images/email-icon.svg\"],null],false],[0,\"Email\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"phoneNumber\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"medical-mobile-action-bar__item\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"clickPhoneNumber\",[24,[\"phoneNumber\"]]],null]],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n    \"],[1,[28,\"inline-svg\",[\"assets/images/call-icon.svg\"],null],false],[0,\"Call\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/client-list/pet-output/medical-mobile-action-bar.hbs"
    }
  });

  _exports.default = _default;
});