define("frontend/components/appointment-requests-list/appointment-request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['appointment-request-item'],
    classNameBindings: ['appointmentRequest.isDragging:dragging'],
    appointmentRequest: null,
    requestType: null,
    currentUser: inject.service(),
    addOnServices: computed.alias('appointmentRequest.add_on_services'),
    isPendingRequest: computed('requestType', function () {
      return ['pending', 'all', 'unassigned'].includes(this.get('requestType'));
    }),
    requestedOnDate: computed('appointmentRequest.created_at', function () {
      return this.formatDate(this.get('appointmentRequest.created_at'));
    }),
    requestedOnTime: computed('appointmentRequest.created_at', function () {
      return moment.parseZone(this.get('appointmentRequest.created_at')).format('h:mmA');
    }),
    approvedAtDate: computed('appointmentRequest.approved_at', function () {
      return this.formatDate(this.get('appointmentRequest.approved_at'));
    }),
    approvedAtTime: computed('appointmentRequest.approved_at', function () {
      return moment.parseZone(this.get('appointmentRequest.approved_at')).format('h:mmA');
    }),
    declinedAtDate: computed('appointmentRequest.declined_at', function () {
      return this.formatDate(this.get('appointmentRequest.declined_at'));
    }),
    declinedAtTime: computed('appointmentRequest.declined_at', function () {
      return moment.parseZone(this.get('appointmentRequest.declined_at')).format('h:mmA');
    }),
    startsAtDate: computed('appointmentRequest.starts_at', function () {
      return this.formatDate(this.get('appointmentRequest.starts_at'));
    }),
    startsAtTime: computed('appointmentRequest.starts_at', function () {
      if (this.get('appointmentRequest.time_frame_start_time')) {
        return this.get('appointmentRequest.time_frame_start_time');
      } else {
        return moment.parseZone(this.get('appointmentRequest.starts_at')).format('h:mmA');
      }
    }),
    isUnassigned: computed('currentUser.user.company.staff_appointment_approval', 'appointmentRequest.care_provider', function () {
      return this.get('currentUser.user.company.staff_appointment_approval') && !this.get('appointmentRequest.care_provider.fullName');
    }),
    formatDate: function formatDate(dateString) {
      var date = moment.parseZone(dateString).startOf('day');
      var today = moment().startOf('day');

      if (date.diff(today, 'days') === 0) {
        return 'today';
      } else if (date.diff(today, 'days') === 1) {
        return 'tomorrow';
      } else if (date.diff(today, 'days') === -1) {
        return 'yesterday';
      } else {
        return date.format('ddd, MMM D');
      }
    },
    actions: {
      modifyAppointmentRequest: function modifyAppointmentRequest() {
        if (this.get('isPendingRequest')) {
          this.get('modifyAppointmentRequest')(this.get('appointmentRequest'));
        }
      },
      dragRequestStart: function dragRequestStart() {
        this.set('appointmentRequest.isDragging', true);
        this.get('startDraggingRequest')(this.get('appointmentRequest'));
      },
      dragRequestEnd: function dragRequestEnd() {
        this.set('appointmentRequest.isDragging', false);
        this.get('endDraggingRequest')();
      }
    }
  });

  _exports.default = _default;
});