define("frontend/templates/add-appointment", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5xCm7sRV",
    "block": "{\"symbols\":[],\"statements\":[[4,\"new-header\",null,[[\"cancel\",\"hasClose\"],[[28,\"route-action\",[\"cancel\"],null],[24,[\"hasClose\"]]]],{\"statements\":[[0,\"  \"],[7,\"h1\",true],[10,\"class\",\"header-text\"],[8],[0,\"Add appointment\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"id\",\"header-back-wormhole-container\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"id\",\"header-next-wormhole-container\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"add-edit-container\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"saving\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"loading-spinner\",null,[[\"message\"],[\"Saving...\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"redirecting\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"loading-spinner\",null,[[\"message\"],[\"Redirecting...\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[22,\"outlet\"],false],[0,\"\\n  \"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/add-appointment.hbs"
    }
  });

  _exports.default = _default;
});