define("frontend/models/doctor", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend({
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    approved_at: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    fullName: computed('first_name', 'last_name', function () {
      return "".concat(this.get('first_name'), " ").concat(this.get('last_name'));
    }),
    name: computed.alias('fullName'),
    veterinarian_clinic: _emberData.default.belongsTo('veterinarian-clinic'),
    created_by_name: _emberData.default.attr('string'),
    created_by_email: _emberData.default.attr('string'),
    vet_names: _emberData.default.attr()
  });

  _exports.default = _default;
});