define("frontend/components/input-callout", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['input-callout'],
    classNameBindings: ['inputActivated:input-activated'],
    attributeBindings: ['fixedTabIndex:tabindex'],
    fixedTabIndex: computed('inputActivated', 'tabindex', function () {
      if (!this.get('inputActivated')) {
        return this.get('tabindex');
      } else {
        return null;
      }
    }),
    inputDeactivated: computed.not('inputActivated'),
    inputActivated: false,
    inputAction: 'focus',
    inputSelector: 'input',
    didInsertElement: function didInsertElement() {
      var _this = this;

      (0, _jquery.default)(this.element).on('focus', function () {
        _this.send('clickCallout');
      });
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(this.element).off('focus');
    },
    actions: {
      clickCallout: function clickCallout() {
        var _this2 = this;

        if (this.get('clickCallout')) {
          this.get('clickCallout')();
        } else {
          Ember.run.scheduleOnce('afterRender', function () {
            (0, _jquery.default)(_this2.element.querySelectorAll(_this2.get('inputSelector'))).trigger(_this2.get('inputAction'));
          });
          this.set('inputActivated', true);
        }
      },
      clickCalloutLabel: function clickCalloutLabel() {
        if (this.get('clickCalloutLabel')) {
          this.get('clickCalloutLabel')();
        }
      }
    }
  });

  _exports.default = _default;
});