define("frontend/controllers/client-list/household/appointments", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var REPEATING_TYPE = 'repeating';
  var SCHEDULE_TYPE = 'schedule';
  var CANCELLATION_TYPE = 'canceled';
  var PREFERENCES_TYPE = 'preferences';
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    media: inject.service(),
    removingAppointment: null,
    removingRecurringAppointmentGroup: null,
    queryParams: ['appointmentType'],
    appointmentType: SCHEDULE_TYPE,
    onRepeating: computed.equal('appointmentType', REPEATING_TYPE),
    onSchedule: computed.equal('appointmentType', SCHEDULE_TYPE),
    onCancellations: computed.equal('appointmentType', CANCELLATION_TYPE),
    onPreferences: computed.equal('appointmentType', PREFERENCES_TYPE),
    owner: computed.readOnly('model.household.owner'),
    showPetNames: computed.gt('model.household.pets.length', 1),
    notMobile: computed.not('media.isMobile'),
    readOnly: computed.not('currentUser.user.canAddEditAppointments'),
    currentUser: Ember.inject.service(),
    selectedGroupedAppointments: [],
    groupedAppointmentsToCancel: [],
    showPets: false,
    showAddresses: false,
    bulkEditing: false,
    hasSelectedGroupedAppointments: computed.notEmpty('selectedGroupedAppointments'),
    showBulkEditingMenu: computed.and('bulkEditing', 'hasSelectedGroupedAppointments'),
    selectedReportCard: null,
    currentGroupedAppointmentToCancel: computed('groupedAppointmentsToCancel.[]', function () {
      return this.get('groupedAppointmentsToCancel.firstObject');
    }),
    removeNewRecords: function removeNewRecords() {
      this.store.peekAll('address').filter(function (a) {
        return a.get('id') == undefined;
      }).forEach(function (a) {
        return a.deleteRecord();
      });
      this.store.peekAll('pet').filter(function (p) {
        return p.get('id') == undefined;
      }).forEach(function (p) {
        return p.deleteRecord();
      });
    },
    reloadDirtyRecords: function reloadDirtyRecords() {
      this.store.peekAll('address').filterBy('hasDirtyAttributes').forEach(function (a) {
        return a.reload();
      });
      this.store.peekAll('pet').filterBy('hasDirtyAttributes').forEach(function (p) {
        return p.reload();
      });
    },
    actions: {
      denyRemoveAppointment: function denyRemoveAppointment() {
        this.set('removingAppointment', null);
      },
      denyRemoveMultipleAppointments: function denyRemoveMultipleAppointments() {
        this.set('removingMultipleAppointments', false);
      },
      denyCancelMultipleAppointments: function denyCancelMultipleAppointments() {
        this.set('cancelingMultipleAppointments', false);
      },
      clickBulkRemove: function clickBulkRemove(method) {
        if (this.get('hasSelectedGroupedAppointments')) {
          if (method == 'archive') {
            this.set('removingMultipleAppointments', true);
          } else {
            this.set('cancelingMultipleAppointments', true);
          }
        } else {
          this.set('showNoAppointmentsSelectedModal', true);
        }
      },
      clickSchedule: function clickSchedule() {
        this.set('appointmentType', SCHEDULE_TYPE);
      },
      clickRepeating: function clickRepeating() {
        this.set('appointmentType', REPEATING_TYPE);
      },
      clickCancellations: function clickCancellations() {
        this.set('appointmentType', CANCELLATION_TYPE);
      },
      clickPreferences: function clickPreferences() {
        this.set('appointmentType', PREFERENCES_TYPE);
      },
      showModifyApptModal: function showModifyApptModal(groupedAppointment) {
        if (groupedAppointment.get('appointments.length') === 1) {
          var ids = [groupedAppointment.get('appointments.firstObject.id')];
          this.send('modifyAppointment', ids);
        } else {
          this.set('selectedGroup', groupedAppointment);
          this.set('showModifyApptModal', true);
        }
      },
      closeModifyAppointmentModal: function closeModifyAppointmentModal() {
        this.set('showModifyApptModal', false);
        this.set('selectedGroup', null);
      },
      modifyAppointment: function modifyAppointment(appointmentIds) {
        this.send('transitionToEditSingleAppointment', appointmentIds);
        this.set('showModifyApptModal', false);
        this.set('selectedGroup', null);
      },
      confirmRemoveAppointment: function confirmRemoveAppointment() {
        var _this = this;

        if (this.get('destroying')) {
          return;
        }

        this.set('destroying', true);
        this.get('removingAppointment').destroyRecord({
          adapterOptions: {
            method: 'remove button in upcoming appointments list'
          }
        }).finally(function () {
          _this.set('removingAppointment', null);

          _this.set('destroying', false);
        });
      },
      confirmRemoveMultipleAppointments: function confirmRemoveMultipleAppointments() {
        this.set('removingMultipleAppointments', false);
        var ids = this.get('selectedGroupedAppointments').map(function (groupedAppt) {
          return groupedAppt.get('appointments').map(function (appt) {
            return appt.get('id');
          });
        });
        ids = ids.flat();

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v1/appointments/delete_multiple"),
          method: 'DELETE',
          data: {
            appointment_ids: ids
          }
        }).done(function () {
          window.location.reload();
        });
      },
      confirmCancelMultipleAppointments: function confirmCancelMultipleAppointments(cancellationNote) {
        var _this2 = this;

        var appointment_ids = this.get('selectedGroupedAppointments').map(function (groupedAppt) {
          return groupedAppt.get('appointments').map(function (appt) {
            return appt.get('id');
          });
        });
        appointment_ids = appointment_ids.flat();

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointments/cancel_multiple_grouped_appointments"),
          method: 'POST',
          data: {
            appointment_ids: appointment_ids,
            cancellation_note: cancellationNote
          }
        }).then(function (response) {
          var apptsWithFees = response.appointments_with_cancellation_fees;

          if (apptsWithFees.length == 0) {
            window.location.reload();
            return;
          }

          var groupedAppointments = _this2.get('selectedGroupedAppointments');

          var groupedAppointmentsWithFees = groupedAppointments.filter(function (groupedAppt) {
            var apptIds = groupedAppt.get('appointments').map(function (appt) {
              return appt.get('id');
            });
            var found = false;
            apptsWithFees.forEach(function (apptId) {
              found = found || apptIds.includes(apptId.toString());
            });
            return found;
          });

          _this2.set('groupedAppointmentsToCancel', groupedAppointmentsWithFees);

          _this2.set('bulkCancellationNote', cancellationNote);

          _this2.get('groupedAppointmentList').openCancelAppointmentModal(groupedAppointmentsWithFees.get('firstObject'), _this2.get('bulkCancellationNote'));

          _this2.set('cancelingMultipleAppointments', false);
        });
      },
      removeAppointment: function removeAppointment(appointment) {
        if (this.get('removingRecurringAppointmentGroup') === null && this.get('removingAppointment') === null) {
          this.set('removingAppointment', appointment);
        }
      },
      selectAppointment: function selectAppointment(groupedAppointment) {
        var selectedAppointments = this.get('selectedGroupedAppointments');
        var found = false;
        selectedAppointments.forEach(function (selectedAppt) {
          if (selectedAppt.get('id') == groupedAppointment.get('id')) {
            selectedAppointments.removeObject(groupedAppointment);
            found = true;
          }
        });

        if (!found) {
          selectedAppointments.pushObject(groupedAppointment);
        }
      },
      clickAddAppointment: function clickAddAppointment() {
        var household = this.get('model.household');
        var numPets = household.get('pets.length');
        var numAddresses = household.get('owner.addresses.length');

        if (numPets == 0 || numAddresses == 0) {
          if (numPets == 0) {
            this.send('addPet');
            this.set('showPets', true);
          }

          if (numAddresses == 0) {
            var state = this.get('currentUser.user.company.state');
            household.get('owner.addresses').pushObject(this.store.createRecord('address', {
              state: state
            }));
            this.set('showAddresses', true);
          }

          this.set('showAppointmentModal', true);
        } else {
          this.send('transitionToAddAppointment', household);
        }
      },
      addPet: function addPet() {
        var _this3 = this;

        var household = this.get('model.household');

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(household.get('id'), "/get_companies"),
          method: 'GET',
          data: {
            id: household.get('id')
          }
        }).done(function (response) {
          var pet = _this3.store.createRecord('pet');

          pet.set('company_notes', response.company_notes);
          household.get('pets').pushObject(pet);
        });
      },
      closeAppointmentInfoModal: function closeAppointmentInfoModal() {
        this.removeNewRecords.call(this);
        this.set('showAppointmentModal', false);
        this.set('loading', false);
      },
      saveHousehold: function saveHousehold() {
        var _this4 = this;

        var userPromise = this.get('model.household.owner').save();
        var petPromises = this.get('model.household.pets').map(function (pet) {
          return pet.save();
        });
        var promises = [userPromise].concat(_toConsumableArray(petPromises));
        return Ember.RSVP.Promise.all(promises).then(function () {
          _this4.removeNewRecords.call(_this4);

          _this4.reloadDirtyRecords.call(_this4);

          _this4.send('transitionToAddAppointment', _this4.get('model.household'));
        });
      },
      nextGroupedAppointmentToCancel: function nextGroupedAppointmentToCancel() {
        var groupedAppointmentsToCancel = this.get('groupedAppointmentsToCancel');

        if (groupedAppointmentsToCancel.length == 0) {
          return;
        }

        groupedAppointmentsToCancel.removeAt(0, 1);
        this.set('groupedAppointmentsToCancel', groupedAppointmentsToCancel);

        if (groupedAppointmentsToCancel.length > 0) {
          this.get('groupedAppointmentList').openCancelAppointmentModal(groupedAppointmentsToCancel.get('firstObject'), this.get('bulkCancellationNote'));
        } else {
          window.location.reload();
        }
      },
      setGroupedAppointmentListChild: function setGroupedAppointmentListChild(childReference) {
        this.set('groupedAppointmentList', childReference);
      },
      cancelAppointmentWithFee: function cancelAppointmentWithFee() {
        this.send('nextGroupedAppointmentToCancel');
      },
      clickBulkEditing: function clickBulkEditing() {
        this.toggleProperty('bulkEditing');
      },
      closeNoAppointmentsSelectedModal: function closeNoAppointmentsSelectedModal() {
        this.set('showNoAppointmentsSelectedModal', false);
      },
      showReportCard: function showReportCard(groupedReportCard) {
        this.set('selectedReportCard', groupedReportCard);
      },
      closeReportCard: function closeReportCard() {
        this.set('selectedReportCard', null);
      }
    }
  });

  _exports.default = _default;
});