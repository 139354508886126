define("frontend/components/message-thread/selected-households-top-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selectedHouseholds: [],
    onMultipleHouseholdThread: false,
    actions: {
      removeHousehold: function removeHousehold(household) {
        this.get('removeHousehold')(household);
      }
    }
  });

  _exports.default = _default;
});