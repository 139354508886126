define("frontend/components/edit-grouped-report-card", ["exports", "frontend/config/environment", "frontend/mixins/age-calculator", "jquery"], function (_exports, _environment, _ageCalculator, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer;

  var _default = Ember.Component.extend(_ageCalculator.default, {
    currentUser: inject.service(),
    media: inject.service(),
    flashMessage: inject.service(),
    polling: inject.service(),
    showSentEmailConfirmation: false,
    saveIconUrl: 'assets/images/report-card/save-icon.svg',
    uploadingPhoto: false,
    showPhotos: false,
    mediaIsNotMobile: computed.not('media.isMobile'),
    selectedMapThumbnail: false,
    mobileGpsMapUrl: null,
    originalValues: [],
    appointments: computed.alias('groupedReportCard.appointments'),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.setMobileGpsMapUrl();

      if (this.get('firstAppointment.started_at')) {
        this.set('currentTime', moment());
        this.get('polling').add('time update', this, function () {
          _this.set('currentTime', moment());
        });
      }
    },
    setMobileGpsMapObserver: observer('media.isMobile', 'firstAppointment', function () {
      this.setMobileGpsMapUrl();
    }),
    setMobileGpsMapUrl: function setMobileGpsMapUrl() {
      var _this2 = this;

      var deviceRatio = window.devicePixelRatio || 1;
      var width = screen.width * deviceRatio;
      var height = 280 * deviceRatio;
      var id = this.get('firstAppointment.id');

      if (id) {
        var url = "".concat(_environment.default.APP.host, "/api/v2/appointments/").concat(id, "/check_in_gps_map?width=").concat(width, "&height=").concat(height);

        _jquery.default.ajax({
          url: url,
          method: 'GET'
        }).then(function (response) {
          _this2.set('mobileGpsMapUrl', response.url);
        });
      }
    },
    firstAppointment: computed('groupedReportCard.appointments', function () {
      return this.get('groupedReportCard.appointments.firstObject');
    }),
    timerRunning: computed('firstAppointment.started_at', 'firstAppointment.finished_at', function () {
      return this.get('firstAppointment.started_at') && !this.get('firstAppointment.finished_at');
    }),
    hasCoverPhoto: computed.notEmpty('groupedReportCard.coverPhoto'),
    hasAdditionalPhotos: computed.notEmpty('groupedReportCard.reportCardImagesWithoutCoverPhoto'),
    hasPhotos: computed.or('hasCoverPhoto', 'hasAdditionalPhotos'),
    inflectedPhotoText: computed('groupedReportCard.groupedReportCardImages.[]', function () {
      var length = this.get('groupedReportCard.groupedReportCardImages.length');

      if (length === 0) {
        return '';
      } else if (length === 1) {
        return 'update photo';
      } else {
        return "update photos <span class=\"photos-length\">".concat(length, "</span>");
      }
    }),
    showDesktopCoverPhoto: computed('groupedReportCard.coverPhoto', 'selectedMapThumbnail', function () {
      return this.get('groupedReportCard.coverPhoto') && !this.get('selectedMapThumbnail');
    }),
    showMobileCoverPhoto: computed('groupedReportCard.coverPhoto', 'media.isMobile', 'selectedMapThumbnail', function () {
      return this.get('groupedReportCard.coverPhoto') && this.get('media.isMobile') && !this.get('selectedMapThumbnail');
    }),
    showMobileDuration: computed.and('firstAppointment.started_at', 'media.isMobile', 'firstAppointment.finished_at'),
    hasStartedLatitude: computed.notEmpty('firstAppointment.started_at_latitude'),
    hasFinishedLatitude: computed.notEmpty('firstAppointment.finished_at_latitude'),
    showDesktopGpsMap: computed.or('hasStartedLatitude', 'hasFinishedLatitude'),
    showMobileGpsMap: computed('hasStartedLatitude', 'hasFinishedLatitude', 'media.isMobile', function () {
      return this.get('media.isMobile') && (this.get('hasStartedLatitude') || this.get('hasFinishedLatitude'));
    }),
    modalContainerClassNames: computed('showSentEmailConfirmation', 'showPhotos', 'groupedReportCard.coverPhoto', 'hasStartedLatitude', 'hasFinishedLatitude', function () {
      if (this.get('showSentEmailConfirmation')) {
        return 'report-card--modal report-card--email-sent';
      } else if (this.get('showPhotos')) {
        return 'report-card--modal report-card--photos-modal';
      } else if (this.get('groupedReportCard.coverPhoto') || this.get('hasStartedLatitude') || this.get('hasFinishedLatitude')) {
        return 'report-card--modal has-cover-photo';
      } else {
        return 'report-card--modal';
      }
    }),
    notesContainerClassNames: computed('timerRunning', 'firstAppointment.offered_service.service_category.should_show_report_card_buttons', 'firstAppointment.started_at', function () {
      var showButtons = this.get('firstAppointment.offered_service.service_category.should_show_report_card_buttons');

      if (this.get('timerRunning')) {
        if (showButtons) {
          return 'no-completed-with-buttons';
        } else {
          return 'no-completed-without-buttons';
        }
      } else {
        if (this.get('firstAppointment.started_at')) {
          if (showButtons) {
            return 'completed-with-duration-and-buttons';
          } else {
            return 'completed-with-duration-and-no-buttons';
          }
        } else {
          if (showButtons) {
            return 'completed-without-duration-and-with-buttons';
          } else {
            return 'completed-without-duration-and-without-buttons';
          }
        }
      }
    }),
    petAvatars: computed('groupedReportCard.appointments.[]', function () {
      return this.get('groupedReportCard.appointments').map(function (appt) {
        var petAvatar = appt.get('pet.avatar_url');
        var petType = appt.get('pet.pet_type');
        var defaultAvatar = "assets/images/placeholders/".concat(petType, ".svg");
        return petAvatar || defaultAvatar;
      });
    }),
    peedImageUrl: computed('groupedReportCard.groupedReportCardChecklists.@each.peed', 'isHoveringPeed', function () {
      if (this.get('groupedReportCard.groupedReportCardChecklists').any(function (checklist) {
        return checklist.get('peed');
      })) {
        return 'assets/images/report-card/peed-icon-selected.svg';
      } else {
        if (this.get('isHoveringPeed')) {
          return 'assets/images/report-card/peed-icon-deselected-hover.svg';
        } else {
          return 'assets/images/report-card/peed-icon-deselected.svg';
        }
      }
    }),
    poopedImageUrl: computed('groupedReportCard.groupedReportCardChecklists.@each.pooped', 'isHoveringPooped', function () {
      if (this.get('groupedReportCard.groupedReportCardChecklists').any(function (checklist) {
        return checklist.get('pooped');
      })) {
        return 'assets/images/report-card/poop-icon-selected.svg';
      } else {
        if (this.get('isHoveringPooped')) {
          return 'assets/images/report-card/poop-icon-deselected-hover.svg';
        } else {
          return 'assets/images/report-card/poop-icon-deselected.svg';
        }
      }
    }),
    providedWaterImageUrl: computed('groupedReportCard.groupedReportCardChecklists.@each.providedWater', 'isHoveringWater', function () {
      if (this.get('groupedReportCard.groupedReportCardChecklists').any(function (checklist) {
        return checklist.get('providedWater');
      })) {
        return 'assets/images/report-card/water-icon-selected.svg';
      } else {
        if (this.get('isHoveringWater')) {
          return 'assets/images/report-card/water-icon-deselected-hover.svg';
        } else {
          return 'assets/images/report-card/water-icon-deselected.svg';
        }
      }
    }),
    fedTreatImageUrl: computed('groupedReportCard.groupedReportCardChecklists.@each.fedTreat', 'isHoveringTreat', function () {
      if (this.get('groupedReportCard.groupedReportCardChecklists').any(function (checklist) {
        return checklist.get('fedTreat');
      })) {
        return 'assets/images/report-card/bone-icon-selected.svg';
      } else {
        if (this.get('isHoveringTreat')) {
          return 'assets/images/report-card/bone-icon-deselected-hover.svg';
        } else {
          return 'assets/images/report-card/bone-icon-deselected.svg';
        }
      }
    }),
    checklistSelectPetIcon: computed('selectedChecklistAttr', function () {
      switch (this.get('selectedChecklistAttr')) {
        case 'peed':
          return 'assets/images/report-card/peed-icon-selected.svg';

        case 'pooped':
          return 'assets/images/report-card/poop-icon-selected.svg';

        case 'fedTreat':
          return 'assets/images/report-card/bone-icon-selected.svg';

        case 'providedWater':
          return 'assets/images/report-card/water-icon-selected.svg';
      }
    }),
    checklistSelectTitle: computed('selectedChecklistAttr', function () {
      switch (this.get('selectedChecklistAttr')) {
        case 'peed':
          return 'peed';

        case 'pooped':
          return 'pooped';

        case 'fedTreat':
          return 'got treats';

        case 'providedWater':
          return 'got water';
      }
    }),
    notesLabel: computed('appointments.[]', function () {
      return "How did ".concat(this.get('petNames'), " do?");
    }),
    petNames: computed('appointments.[]', function () {
      var petNames = 'everyone';

      if (this.get('appointments.length') == 1) {
        petNames = this.get('appointments.firstObject.pet.name');
      }

      if (this.get('appointments.length') == 2) {
        petNames = this.get('appointments').map(function (appt) {
          return appt.get('pet.name');
        });
        petNames.sort();
        petNames = petNames.join(' & ');
      }

      return petNames;
    }),
    petAgeLabel: computed('groupedReportCard.pet.birthday', function () {
      if (this.get('groupedReportCard.pet.birthday')) {
        return this.calculateAge(this.get('groupedReportCard.pet.birthday'));
      }
    }),
    durationTime: computed('firstAppointment.started_at', 'currentTime', function () {
      var now = this.get('currentTime') || moment();
      var start = moment(this.get('firstAppointment.started_at'));
      var duration = moment.duration(now.diff(start));
      var hours = duration.hours();

      if (hours < 10) {
        hours = "0".concat(hours);
      }

      var minutes = duration.minutes();

      if (minutes < 10) {
        minutes = "0".concat(minutes);
      }

      var seconds = duration.seconds();

      if (seconds < 10) {
        seconds = "0".concat(seconds);
      }

      return "".concat(hours, ":").concat(minutes, ":").concat(seconds);
    }),
    actions: {
      toggleBasicChecklist: function toggleBasicChecklist(attr) {
        var checklists = this.get('groupedReportCard.groupedReportCardChecklists');

        if (checklists.length >= 2) {
          var originalValues = checklists.map(function (checklist) {
            return {
              id: checklist.get('id'),
              value: checklist.get(attr)
            };
          });
          this.set('originalValues', originalValues);

          if (!checklists.any(function (checklist) {
            return checklist.get(attr);
          })) {
            checklists.forEach(function (checklist) {
              return checklist.set(attr, true);
            });
          }

          this.set('selectedChecklistAttr', attr);
        } else {
          var checklist = checklists.get('firstObject');
          checklist.toggleProperty(attr);
        }
      },
      closeChecklistSelect: function closeChecklistSelect() {
        this.set('selectedChecklistAttr', null);
        this.set('originalValues', []);
      },
      cancelChecklistSelect: function cancelChecklistSelect() {
        var _this3 = this;

        var attr = this.selectedChecklistAttr;
        this.originalValues.forEach(function (originalValue) {
          var checklists = _this3.groupedReportCard.get('groupedReportCardChecklists');

          var list = checklists.filter(function (checklist) {
            return checklist.get('id') == originalValue.id;
          }).firstObject;
          list.set(attr, originalValue.value);
        });
        this.send('closeChecklistSelect');
      },
      save: function save() {
        var _this4 = this;

        this.set('saving', true);
        this.set('savingDraft', true);
        this.get('groupedReportCard').save().then(function () {
          _this4.send('onClose');

          _this4.set('saving', false);

          _this4.set('savingDraft', false);
        });
      },
      sendOrStop: function sendOrStop() {
        if (this.get('timerRunning')) {
          this.get('finishAppointment')(this.get('firstAppointment'));
        } else {
          this.send('send');
        }
      },
      send: function send() {
        var _this5 = this;

        this.set('saving', true);
        this.set('sending', true);
        this.get('groupedReportCard').save().then(function () {
          _this5.get('groupedReportCard').send().then(function () {
            // DO NOT RELOAD THE groupedReportCard HERE.
            // See comment in closeSentEmailConfirmation for more details
            if (_this5.get('currentUser.user').shouldSeeMessage('report-card-email-confirmation')) {
              _this5.set('showSentEmailConfirmation', true);
            } else {
              _this5.send('onClose');
            }

            _this5.set('saving', false);

            _this5.set('sending', false);
          });
        });
      },
      onClose: function onClose() {
        // This reload is necessary because the sending of the email will cause the report card to leave draft state. When
        // a report card is no longer in draft state it does not see the care-provider-report-card modal but instead the
        // view-report-card component. Since the selectedAppointmentReportCard has not been removed from the schedule route
        // at this point when the groupedReportCard gets reloaded the state changes and the confirmation, if shown, immediately flashes
        // to the view-report-card modal. If you don't reload then when you go back to an appointment that you JUST sent the
        // card to we don't know that the report card state has changed. We could set this ourselves manually in the front-end
        // but other state may change in the future and a simple GET of a report card should be a cheap operation.
        this.get('groupedReportCard').reload();
        this.set('showSentEmailConfirmation', false);
        this.onClose();
      },
      clickCloseOrSkip: function clickCloseOrSkip() {
        var grc = this.get('groupedReportCard');
        var checklistsChanged = grc.get('groupedReportCardChecklists').filter(function (checklist) {
          return checklist.get('hasDirtyAttributes');
        });

        if (grc.get('hasDirtyAttributes') || checklistsChanged.length > 0) {
          this.set('showRevertOrSaveModal', true);
        } else {
          this.onClose();
        }
      },
      revertGroupedReportCardAndClose: function revertGroupedReportCardAndClose() {
        var grc = this.get('groupedReportCard');
        grc.rollbackAttributes();
        grc.get('groupedReportCardChecklists').forEach(function (checklist) {
          return checklist.rollbackAttributes();
        });
        this.set('showRevertOrSaveModal', false);
        this.onClose();
      },
      onDismissedEmailConfirmation: function onDismissedEmailConfirmation() {
        this.get('currentUser.user').reload();
        this.set('showSentEmailConfirmation', false);
        this.send('onClose');
      },
      hoveringPeed: function hoveringPeed(hovering) {
        var elem = (0, _jquery.default)('.report-card-basic-checklist--peed button');
        var isMobile = this.get('media.isMobile');
        this.set('isHoveringPeed', hovering);

        if (hovering && !isMobile) {
          elem.attr('data-original-title', 'Peed').tooltip('show');
        } else if (!isMobile) {
          elem.tooltip('hide');
        }
      },
      hoveringPooped: function hoveringPooped(hovering) {
        var elem = (0, _jquery.default)('.report-card-basic-checklist--pooped button');
        var isMobile = this.get('media.isMobile');
        this.set('isHoveringPooped', hovering);

        if (hovering && !isMobile) {
          elem.attr('data-original-title', 'Pooped').tooltip('show');
        } else if (!isMobile) {
          elem.tooltip('hide');
        }
      },
      hoveringWatered: function hoveringWatered(hovering) {
        var elem = (0, _jquery.default)('.report-card-basic-checklist--provided-water button');
        var isMobile = this.get('media.isMobile');
        this.set('isHoveringWater', hovering);

        if (hovering && !isMobile) {
          elem.attr('data-original-title', 'Refilled water').tooltip('show');
        } else if (!isMobile) {
          elem.tooltip('hide');
        }
      },
      hoveringFed: function hoveringFed(hovering) {
        var elem = (0, _jquery.default)('.report-card-basic-checklist--fed-treat button');
        var isMobile = this.get('media.isMobile');
        this.set('isHoveringTreat', hovering);

        if (hovering && !isMobile) {
          elem.attr('data-original-title', 'Had a treat').tooltip('show');
        } else if (!isMobile) {
          elem.tooltip('hide');
        }
      },
      hoveringSaved: function hoveringSaved(hovering) {
        if (hovering) {
          this.set('saveIconUrl', 'assets/images/report-card/save-icon-hover.svg');
        } else {
          this.set('saveIconUrl', 'assets/images/report-card/save-icon.svg');
        }
      },
      addedPhotoToReportCard: function addedPhotoToReportCard(file, finishedUploadingCallback) {
        var _this6 = this;

        this.set('uploadingPhoto', true);
        var fileType = file.type;
        var splitFileType = fileType.split('/');
        var groupedReportCardID = this.get('groupedReportCard.id');

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/grouped_report_cards/").concat(groupedReportCardID, "/create_upload_url"),
          method: 'POST',
          data: {
            file_type: fileType
          }
        }).then(function (response) {
          var formData = new FormData();

          for (var key in response.fields) {
            var value = response.fields[key];
            formData.set(key, value);
          }

          return file.upload(response.url, {
            data: response.fields
          }).then(function () {
            return response.fields.key;
          }).catch(function (e) {
            Bugsnag.notify("Error uploading image - ".concat(groupedReportCardID, " - error: ").concat(e));

            _this6.get('flashMessage').errorWithoutRefresh('There was an error uploading your image.');

            _this6.set('groupedReportCard.newImage', null);

            _this6.set('uploadingPhoto', false);
          });
        }).then(function (key) {
          _this6.set('groupedReportCard.newImage', key);

          _this6.set('groupedReportCard.newImageType', splitFileType[0] == 'application' ? splitFileType[1] : splitFileType[0]);

          return _this6.get('groupedReportCard').save().then(function () {
            Ember.run.schedule('afterRender', function () {
              if (finishedUploadingCallback) {
                finishedUploadingCallback();
              }
            });
          });
        }).fail(function () {
          _this6.get('flashMessage').errorWithoutRefresh('NOPE: There was an error uploading your image.');

          _this6.set('groupedReportCard.newImage', null);

          _this6.set('uploadingPhoto', false);
        }).done(function () {
          _this6.set('groupedReportCard.newImage', null);

          _this6.set('uploadingPhoto', false);
        });
      },
      showPhotos: function showPhotos() {
        this.set('showPhotos', true);
        this.set('selectedPhotoUrl', null);
        this.set('selectedPhotoUrl', this.get('groupedReportCard.coverPhoto.url'));
      },
      hidePhotos: function hidePhotos() {
        this.set('showPhotos', false);
      },
      changeCoverPhoto: function changeCoverPhoto(photo, finishedChangingCallback) {
        this.set('groupedReportCard.coverPhoto', photo);
        this.get('groupedReportCard').save().then(function () {
          finishedChangingCallback();
        });
      },
      removePhoto: function removePhoto(photo, onPhotoRemovedCallback) {
        this.set('groupedReportCard.removeImage', photo.get('id'));
        this.get('groupedReportCard').save().then(function () {
          onPhotoRemovedCallback();
        });
      },
      clickMapThumbnail: function clickMapThumbnail() {
        this.set('selectedMapThumbnail', true);
      },
      clickCoverPhotoThumbnail: function clickCoverPhotoThumbnail() {
        this.set('selectedMapThumbnail', false);
      }
    }
  });

  _exports.default = _default;
});