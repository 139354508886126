define("frontend/components/invoice-receipt/client-appointment-group", ["exports", "ember-data", "jquery"], function (_exports, _emberData, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['appointment-group'],
    collapsed: true,
    showGroup: computed.not('collapsed'),
    incompleteAppointments: computed.filterBy('groupedAppointments', 'completed', false),
    completeAppointments: computed.filterBy('groupedAppointments', 'completed', true),
    household: null,
    overriddenCost: false,
    groupedAppointments: null,
    wasClosedAndHasSalesTax: null,
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);

      this.notifyPropertyChange('editingInvoice');
    },
    allAppointments: computed('groupedAppointments.@each.appointments.[]', function () {
      var appts = [];
      this.get('groupedAppointments').forEach(function (groupedAppointment) {
        groupedAppointment.get('appointments').forEach(function (appt) {
          appts.push(appt);
        });
      });
      return appts;
    }),
    groupedAddOnServicesByOfferedService: computed('groupedAppointments.@each.appointments.[]', function () {
      var addOnServicesByOfferedService = {};
      this.get('groupedAppointments').forEach(function (groupedAppointment) {
        var groupedAppointmentAddOns = {};
        var appointments = groupedAppointment.get('appointments');
        appointments.forEach(function (appointment) {
          var add_ons = appointment.get('appointment_add_on_services') || [];
          add_ons.forEach(function (add_on) {
            var osId = add_on.get('offered_service.id');

            if (groupedAppointmentAddOns[osId] == null) {
              groupedAppointmentAddOns[osId] = {
                offered_service: add_on.get('offered_service'),
                pets: [],
                appointments: []
              };
            }

            groupedAppointmentAddOns[osId].appointments.push(appointment);
            groupedAppointmentAddOns[osId].pets.push(appointment.get('pet'));
          });
        });
        Object.entries(groupedAppointmentAddOns).forEach(function (entry) {
          var _addOnServicesByOffer;

          var key = "".concat(entry[0], "-").concat(entry[1].appointments.length);

          if (addOnServicesByOfferedService[key] == null) {
            addOnServicesByOfferedService[key] = {
              offeredServiceId: entry[0],
              offeredService: entry[1].offered_service,
              pets: entry[1].pets,
              groupedAppointments: [],
              appointments: []
            };
          }

          (_addOnServicesByOffer = addOnServicesByOfferedService[key].appointments).push.apply(_addOnServicesByOffer, _toConsumableArray(entry[1].appointments));

          addOnServicesByOfferedService[key].groupedAppointments.push(groupedAppointment);
        });
      });
      var entries = Object.entries(addOnServicesByOfferedService).sort(function (x, y) {
        return x[0].localeCompare(y[0]);
      });
      entries = entries.map(function (entry) {
        return {
          key: entry[0],
          info: entry[1]
        };
      });
      return entries;
    }),
    cost: computed('allAppointments.@each.cost', 'overriddenCost', function () {
      if (this.get('overriddenCost') !== false) {
        return this.get('overriddenCost');
      }

      if (this.get('overriddenCost') == null) {
        return false;
      }

      return this.get('allAppointments').reduce(function (acc, appointment) {
        return acc += appointment.get('completed') ? appointment.get('cost') : 0;
      }, 0);
    }),
    effectivePrice: computed('invoice.id', 'invoice.household.id', 'offeredService.id', function () {
      var offeredServiceId = this.get('offeredService.id');
      var householdId = this.get('invoice.household.id');
      var invoiceId = this.get('invoice.id');

      var promise = _jquery.default.ajax({
        url: "/api/v2/effective_offered_service_prices?offered_service_id=".concat(offeredServiceId, "&household_id=").concat(householdId, "&invoice_id=").concat(invoiceId),
        method: 'GET'
      });

      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    totalPrice: computed('effectivePrice.price', 'effectivePrice.incremental_price', 'pets.[]', function () {
      var price = parseFloat(this.get('effectivePrice.price'));
      var incPrice = parseFloat(this.get('effectivePrice.incremental_price'));
      var petCount = this.get('pets').length;
      return price + (petCount - 1) * incPrice;
    }),
    hasIncrementalPrice: computed('effectivePrice.incremental_price', function () {
      return this.get('effectivePrice.incremental_price') != this.get('effectivePrice.price') && this.get('pets').length > 1;
    }),
    appointmentsSorting: ['time'],
    sortedGroupedAppointments: computed.sort('groupedAppointments', 'appointmentsSorting'),
    groupedAppointmentsByDate: computed('sortedGroupedAppointments.[]', function () {
      var sortedGroupedAppointments = this.get('sortedGroupedAppointments');
      var groupedByDate = {};
      sortedGroupedAppointments.forEach(function (ga) {
        var date = moment.parseZone(ga.get('time')).format('YYYY-MM-DD');

        if (groupedByDate[date]) {
          groupedByDate[date].pushObject(ga);
        } else {
          groupedByDate[date] = [ga];
        }
      });
      return Object.values(groupedByDate);
    }),
    pets: computed('allAppointments.@each.pet', function () {
      return this.get('allAppointments').mapBy('pet').reduce(function (acc, pet) {
        if (acc.mapBy('id').indexOf(pet.get('id')) < 0) {
          acc.push(pet);
        }

        return acc;
      }, []);
    })
  });

  _exports.default = _default;
});