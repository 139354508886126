define("frontend/controllers/companies/integrations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    company: computed.alias('currentUser.user.company'),
    actions: {
      goToQuickbooks: function goToQuickbooks() {
        this.transitionToRoute('companies.integrations.quickbooks', this.get('currentUser.user.company.id'));
      }
    }
  });

  _exports.default = _default;
});