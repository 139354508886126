define("frontend/templates/components/invoice-receipt/appointments-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+F6yV7hi",
    "block": "{\"symbols\":[\"entry\"],\"statements\":[[7,\"h2\",true],[8],[0,\"Services breakdown\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"appointment-list-content\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"groupedAppointmentsByOfferedService\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"component\",[[24,[\"appointmentComponent\"]]],[[\"clickAppointment\",\"invoice\",\"loadingCost\",\"overriddenCost\",\"editingInvoice\",\"offeredService\",\"groupedAppointments\"],[[28,\"action\",[[23,0,[]],[24,[\"clickAppointment\"]]],null],[24,[\"invoice\"]],[24,[\"loadingCost\"]],[28,\"if\",[[24,[\"editingInvoice\"]],[28,\"get\",[[24,[\"appointmentsAmount\"]],[23,1,[\"key\"]]],null],false],null],[24,[\"editingInvoice\"]],[23,1,[\"info\",\"groupedAppointments\",\"firstObject\",\"appointments\",\"firstObject\",\"offered_service\"]],[23,1,[\"info\",\"groupedAppointments\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/invoice-receipt/appointments-list.hbs"
    }
  });

  _exports.default = _default;
});