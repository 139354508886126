define("frontend/templates/components/ppb-paper-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hQI7MCff",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"paper-input-resizable\",null,[[\"textarea\",\"label\",\"value\",\"onChange\",\"passThru\"],[[24,[\"textarea\"]],[24,[\"label\"]],[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null],[24,[\"passThru\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"clarificationNote\"]]],null,{\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"mobile-clarification\"],[8],[0,\"\\n      \"],[1,[22,\"clarificationNote\"],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"clarification\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"border-side\"],[8],[0,\"\\n        \"],[1,[28,\"inline-svg\",[\"assets/images/info-icon.svg\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"text\"],[8],[0,\"\\n        \"],[1,[22,\"clarificationNote\"],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/ppb-paper-input.hbs"
    }
  });

  _exports.default = _default;
});