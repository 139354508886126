define("frontend/components/client-list/pet-output/medical-output", ["exports", "frontend/components/client-list/pet-output"], function (_exports, _petOutput) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _petOutput.default.extend({
    classNames: ['medical-output'],
    emptyVet: computed.not('vet.id'),
    emptyInsurance: computed.not('insurance.id'),
    active: false,
    hasVet: computed.not('emptyVet'),
    hasInsurance: computed.not('emptyInsurance'),
    hasVaccinations: computed.gt('vaccinations.length', 0),
    hasPrescriptions: computed.gt('petPrescriptions.length', 0),
    hasEmergencyContacts: computed.gt('emergencyContacts.length', 0),
    actions: {
      clickAddress: function clickAddress(address) {
        var encodedAddress = encodeURIComponent(address);
        window.location.href = "https://maps.google.com?q=".concat(encodedAddress);
      },
      callPhoneNumber: function callPhoneNumber(phone) {
        window.location.href = "tel:".concat(phone);
      },
      textPhoneNumber: function textPhoneNumber(phone) {
        window.location.href = "sms:".concat(phone);
      },
      clickEmail: function clickEmail(email) {
        window.location.href = "mailto:".concat(email);
      }
    }
  });

  _exports.default = _default;
});