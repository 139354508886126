define("frontend/serializers/offered-service", ["exports", "ember-data", "active-model-adapter"], function (_exports, _emberData, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend(_emberData.default.EmbeddedRecordsMixin, {
    serializeIntoHash: function serializeIntoHash(data, type, record, options) {
      var root = Ember.String.underscore(type.modelName);
      data[root] = this.serialize(record, options);
    },
    attrs: {
      company: {
        embedded: 'always'
      }
    }
  });

  _exports.default = _default;
});