define("frontend/routes/portal/invoices-summary", ["exports", "frontend/routes/base-route", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model: function model() {
      return _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/users/get_client_companies?all=true"),
        method: 'GET'
      });
    }
  });

  _exports.default = _default;
});