define("frontend/components/invoice-frequency-effective-date-modal", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    timePeriodClarify: '',
    billOptions: computed('billingPeriodStartDate', 'billingPeriodEndDate', 'nextBillingPeriodStartDate', 'nextBillingPeriodEndDate', function () {
      var currentStartDate = moment(this.get('billingPeriodStartDate')).format('M/D');
      var currentEndDate = moment(this.get('billingPeriodEndDate')).format('M/D');
      var currentBillingPeriod = "Current bill (".concat(currentStartDate, " - ").concat(currentEndDate, ")");
      var nextStartDate = moment(this.get('nextBillingPeriodStartDate')).format('M/D');
      var nextEndDate = moment(this.get('nextBillingPeriodEndDate')).format('M/D');
      var nextBillingPeriod = "Next bill (".concat(nextStartDate, " - ").concat(nextEndDate, ")");
      return [{
        label: currentBillingPeriod,
        value: 'current'
      }, {
        label: nextBillingPeriod,
        value: 'next'
      }];
    }),
    selectedBillOption: computed('billOptions', function () {
      return this.get('billOptions.firstObject');
    }),
    helperText: computed('selectedBillOption', 'loadingStartBillingDate', function () {
      if (this.get('loadingStartBillingDate')) {
        return;
      }

      if (this.get('selectedBillOption').value == 'current') {
        var startDate = this.get('newBillingPeriodStartDate');
        var endDate = this.endBillingDate(moment());
        var currentEndDate = moment(this.get('billingPeriodEndDate')).format('M/D');

        if (endDate == currentEndDate) {
          return "Your current billing period will still end on ".concat(moment(this.get('billingPeriodEndDate')).format('dddd M/D'), ", and future bills will be issued ").concat(this.get('timePeriodClarify'), ".");
        } else {
          return "Your current billing period will change to ".concat(startDate.format('M/D'), " - ").concat(this.endBillingDate(moment()), ", and future bills will close ").concat(this.get('timePeriodClarify'), ".");
        }
      } else {
        var _startDate = moment(this.get('nextBillingPeriodStartDate'));

        return "Your next billing period will change to ".concat(_startDate.format('M/D'), " - ").concat(this.endBillingDate(_startDate), ", and future bills will close ").concat(this.get('timePeriodClarify'), ". The current bill will not be affected.");
      }
    }),
    endBillingDate: function endBillingDate(startDate) {
      switch (this.get('invoiceFrequency')) {
        case 'monthly':
          return startDate.endOf('month').format('M/D');

        case 'semimonthly':
          if (parseInt(startDate.format('D')) <= 14) {
            return startDate.startOf('month').add('13', 'days').format('M/D');
          }

          return startDate.endOf('month').format('M/D');

        case 'biweekly':
          {
            var endDate = moment(this.get('company.billing_period')).add('2', 'weeks').subtract('1', 'day');

            if (endDate < startDate) {
              return endDate.add('2', 'weeks').format('M/D');
            }

            return endDate.format('M/D');
          }

        case 'weekly':
          return startDate.endOf('isoweek').format('M/D');

        case 'daily':
          return startDate.endOf('day').format('M/D');

        default:
          return null;
      }
    },
    actions: {
      changeBillOption: function changeBillOption(billOption) {
        this.set('selectedBillOption', billOption);
      },
      onClose: function onClose() {
        if (this.get('saving')) {
          return;
        }

        this.get('onClose')();
      },
      onSubmit: function onSubmit() {
        var _this = this;

        this.set('saving', true);
        var householdId = this.get('household.id');
        var company_id = this.get('company.id');
        var invoice_frequency = this.get('invoiceFrequency');

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(householdId, "/update_invoice_frequency"),
          method: 'POST',
          data: {
            invoice_frequency: invoice_frequency,
            company_id: company_id,
            billing_period: this.get('selectedBillOption.value')
          }
        }).then(function () {
          _this.set('saving', false);

          _this.get('onClose')(true);
        }).fail(function () {
          _this.get('flashMessage').error('Oops! Something went wrong.');

          _this.set('saving', false);
        });
      }
    }
  });

  _exports.default = _default;
});