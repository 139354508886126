define("frontend/components/time-blocks-modal", ["exports", "frontend/utils/string-util"], function (_exports, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    serviceCategory: null,
    timeFrame: null,
    timeDropdownOptions: {
      interval: 30,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      minTime: '6:00AM',
      maxTime: '11:30PM',
      startTime: '6:00AM',
      timeFormat: 'h:mm p',
      momentFormat: 'h:mm A'
    },
    daysOfWeekOptions: [{
      value: 1,
      label: 'M',
      day: 'Monday'
    }, {
      value: 2,
      label: 'T',
      day: 'Tuesday'
    }, {
      value: 3,
      label: 'W',
      day: 'Wednesday'
    }, {
      value: 4,
      label: 'Th',
      day: 'Thursday'
    }, {
      value: 5,
      label: 'F',
      day: 'Friday'
    }, {
      value: 6,
      label: 'Sa',
      day: 'Saturday'
    }, {
      value: 0,
      label: 'Su',
      day: 'Sunday'
    }],
    didInsertElement: function didInsertElement() {
      this.set('loading', false);
    },
    daysOfWeek: computed('timeFrame.days_of_week.[]', function () {
      var selectedDaysOfWeek = this.get('timeFrame.days_of_week');
      var options = this.get('daysOfWeekOptions');
      return options.map(function (option) {
        var selected = selectedDaysOfWeek.includes(option.value);
        return {
          value: option.value,
          label: option.label,
          day: option.day,
          selected: selected
        };
      });
    }),
    selectedDays: computed('daysOfWeek.[]', function () {
      var daysOfWeek = this.get('daysOfWeek').filter(function (dow) {
        return dow.selected;
      });
      return _stringUtil.default.joinWithAnd(daysOfWeek.map(function (dow) {
        return dow.day;
      }));
    }),
    showTimeError: computed('timeFrame.start_time', 'timeFrame.end_time', function () {
      var startTime = this.get('timeFrame.start_time');
      var endTime = this.get('timeFrame.end_time');

      if (!startTime || !endTime) {
        return false;
      }

      if (moment("2019-11-11 ".concat(startTime)) >= moment("2019-11-11 ".concat(endTime))) {
        return true;
      }

      return false;
    }),
    showDaysError: computed('timeFrame.days_of_week.[]', function () {
      return this.get('timeFrame.days_of_week.length') == 0;
    }),
    offHoursTooltipText: computed('serviceCategory', function () {
      return "You\u2019ll be able to set an automated surcharge for ".concat(this.get('serviceCategory.shortened_name'), "s occurring in off-hour windows in your policies & fees.");
    }),
    actions: {
      cancel: function cancel() {
        this.get('onClose')();
      },
      clickDayOfWeek: function clickDayOfWeek(option) {
        var selectedDaysOfWeek = this.get('timeFrame.days_of_week');

        if (selectedDaysOfWeek.includes(option)) {
          selectedDaysOfWeek = selectedDaysOfWeek.filter(function (dow) {
            return dow != option;
          });
        } else {
          selectedDaysOfWeek.pushObject(option);
        }

        this.set('timeFrame.days_of_week', selectedDaysOfWeek);
      },
      changeOffHoursToggle: function changeOffHoursToggle() {
        this.toggleProperty('timeFrame.is_off_hours');
      },
      removeTimeFrame: function removeTimeFrame() {
        this.get('removeTimeFrame')(this.get('timeFrame'));
        this.get('onClose')();
      },
      save: function save() {
        if (this.get('showTimeError') || this.get('showDaysError')) {
          return;
        }

        this.set('loading', true);
        this.get('save')();
      }
    }
  });

  _exports.default = _default;
});