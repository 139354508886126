define("frontend/components/prescriptions-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      onClose: function onClose() {
        this.get('onClose')();
      },
      onSubmit: function onSubmit() {
        this.get('onSubmit')(this.get('prescription'));
      }
    }
  });

  _exports.default = _default;
});