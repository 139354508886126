define("frontend/templates/components/tip-success-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r6KcunGA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,[[\"customContainerClassNames\"],[\"payment-success-modal\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[1,[28,\"inline-svg\",[\"assets/images/fireworks.svg\"],null],false],[9],[0,\"\\n      \"],[7,\"header\",true],[8],[0,\"\\n        Thanks for tipping!\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"layout-row info-text\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"layout-column flex\"],[8],[0,\"\\n        You’ve successfully submitted a tip of \"],[1,[28,\"format-money\",[[24,[\"amountPaid\"]]],[[\"precision\"],[2]]],false],[0,\" to \"],[1,[24,[\"company\",\"displayName\"]],false],[0,\".\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"text-center stacked-button bottom-button\"],[8],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"color\",\"onclick\"],[\"red\",[28,\"action\",[[23,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"        Got it!\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/tip-success-modal.hbs"
    }
  });

  _exports.default = _default;
});