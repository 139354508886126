define("frontend/utils/invoice-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DRAFT = 'draft';
  var SENT = 'sent';
  var DUE = 'due';
  var PAID = 'paid';
  var _default = {
    DRAFT: DRAFT,
    SENT: SENT,
    DUE: DUE,
    PAID: PAID
  };
  _exports.default = _default;
});