define("frontend/components/bill-reminders", ["exports", "frontend/utils/payment-reminder-options"], function (_exports, _paymentReminderOptions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['bill-reminders'],
    companyInvoiceFrequencies: null,
    anySidePanelActive: false,
    editingBillReminders: false,
    frequencyName: computed('companyInvoiceFrequencies.@each.payment_reminder_frequency', function () {
      var firstFrequency = this.get('companyInvoiceFrequencies.firstObject.payment_reminder_frequency');
      var allSame = this.get('companyInvoiceFrequencies').every(function (invoiceFrequency) {
        return invoiceFrequency.get('payment_reminder_frequency') === firstFrequency;
      });

      if (allSame) {
        return _paymentReminderOptions.default.lookupByValue(firstFrequency).label;
      } else {
        return 'Custom';
      }
    }),
    actions: {
      closeSidePanel: function closeSidePanel() {
        this.closeSidePanel();
      },
      openSidePanel: function openSidePanel() {
        if (this.get('anySidePanelActive')) {
          return;
        }

        this.openSidePanel();
      }
    }
  });

  _exports.default = _default;
});