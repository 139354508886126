define("frontend/templates/components/appointment-times-autocomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+TueD50G",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"paper-time-picker\",null,[[\"placeholder\",\"value\",\"onChange\",\"options\"],[[24,[\"placeholder\"]],[24,[\"selectedStartTime\"]],[28,\"action\",[[23,0,[]],\"onChange\"],null],[24,[\"options\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[28,\"media\",[\"isMobile\"],null],[24,[\"selectedStartTime\"]]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"appt-times-autocomplete-label\"],[8],[0,\"\\n    Starts at\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/appointment-times-autocomplete.hbs"
    }
  });

  _exports.default = _default;
});