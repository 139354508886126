define("frontend/models/off-hours-surcharge-policy", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    service_category_id: _emberData.default.attr(),
    service_category: _emberData.default.belongsTo('service-category'),
    time_frames: _emberData.default.hasMany('time-frame'),
    amount: _emberData.default.attr('number'),
    amount_type: _emberData.default.attr(),
    employee_compensation_type: _emberData.default.attr('string', {
      defaultValue: 'percentage'
    }),
    employee_compensation_amount: _emberData.default.attr('number')
  });

  _exports.default = _default;
});