define("frontend/templates/components/calendar-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ksx0gKMd",
    "block": "{\"symbols\":[\"careProvider\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header-text\"],[8],[0,\"Calendar view options\"],[9],[0,\"\\n  \"],[7,\"a\",false],[12,\"class\",\"close-button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"close\"],null]]],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"assets/images/staff/x.svg\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"calendar-options-content\"],[8],[0,\"\\n  \"],[7,\"h2\",true],[8],[0,\"For all staff view\"],[9],[0,\"\\n\"],[4,\"paper-switch\",null,[[\"value\",\"onChange\"],[[24,[\"hidingInactiveStaff\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"hidingInactiveStaff\"]]],null]],null]]],{\"statements\":[[0,\"    hide inactive staff\\n\"]],\"parameters\":[]},null],[4,\"sortable-objects\",null,[[\"sortableObjectList\",\"inPlace\",\"sortEndAction\"],[[24,[\"careProviders\"]],true,[28,\"action\",[[23,0,[]],\"sortEndAction\"],null]]],{\"statements\":[[4,\"each\",[[24,[\"careProviders\"]]],null,{\"statements\":[[4,\"draggable-object\",null,[[\"content\",\"isSortable\"],[[23,1,[]],true]],{\"statements\":[[0,\"        \"],[1,[28,\"calendar-options/care-provider\",null,[[\"careProvider\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/calendar-options.hbs"
    }
  });

  _exports.default = _default;
});