define("frontend/services/inbox-has-unread-messages", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;

  var _default = Ember.Service.extend({
    router: service(),
    hasUnreadMessages: false,
    currentUser: service(),
    firestore: service(),
    flashMessage: service(),
    firestoreListener: null,
    lastMessageTime: null,
    startTracking: function startTracking() {
      if (this.get('currentUser.user.isStaff')) {
        return;
      }

      if (this.get('firestoreListener')) {
        this.get('firestoreListener')();
      }

      var collectionName = "".concat(_environment.default.environment, "_staff_has_unread_messages");
      var docName = "".concat(this.get('currentUser.user.company.id'), "_").concat(this.get('currentUser.user.id'));

      if (this.get('currentUser.user.isPetParent')) {
        collectionName = "".concat(_environment.default.environment, "_pet_parent_has_unread_messages");
        docName = "".concat(this.get('currentUser.user.id'));
      }

      var self = this;
      var collection = this.get('firestore').getCollection(collectionName);

      if (collection) {
        var firestoreListener = collection.doc(docName).onSnapshot(function (doc) {
          if (doc.exists) {
            self.set('hasUnreadMessages', doc.data().unread_messages);
            var lastMessageTime = doc.data().last_message;
            var storedTime = self.get('lastMessageTime');

            if (lastMessageTime) {
              if (storedTime && storedTime < lastMessageTime) {
                var routeName = self.get('router.currentRouteName');

                if (!routeName.includes('inbox') && !routeName.includes('portal.messages')) {
                  self.get('flashMessage').newMessage(function () {
                    if (self.get('currentUser.user.isPetParent')) {
                      window.location.href = '/portal/messages';
                    } else {
                      window.location.href = '/inbox';
                    }
                  }, function () {
                    /* noop */
                  }, 'You have a new message!', 'Go to inbox', 'Dismiss');
                }
              }

              self.set('lastMessageTime', lastMessageTime);
            } else {
              self.set('lastMessageTime', moment().unix());
            }
          } else {
            self.set('hasUnreadMessages', false);
          }
        });
        this.set('firestoreListener', firestoreListener);
      }
    }
  });

  _exports.default = _default;
});