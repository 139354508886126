define("frontend/components/message-thread/company-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['message-thread--company-select'],
    companies: [],
    selectedCompany: null,
    actions: {
      companySelected: function companySelected(company) {
        this.get('companySelected')(company.get('id'));
      }
    }
  });

  _exports.default = _default;
});