define("frontend/templates/components/avatar-upload", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dyVey6ul",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",false],[12,\"class\",\"upload-image\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"openImageSelect\"]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"photo\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\",true],[10,\"class\",\"photo\"],[11,\"src\",[29,[[22,\"photo\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"img\",true],[10,\"class\",\"photo placeholder\"],[11,\"src\",[29,[[22,\"placeholder\"]]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"canUpload\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"upload-image-overlay\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"input\",true],[10,\"style\",\"display: none\"],[10,\"name\",\"filename\"],[10,\"accept\",\"image/*\"],[10,\"type\",\"file\"],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/avatar-upload.hbs"
    }
  });

  _exports.default = _default;
});