define("frontend/components/percentage-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: 'percentage-bar',
    label: '',
    value: 0,
    total: 0,
    attributeBindings: ['style'],
    style: computed('value', 'total', function () {
      return "width: ".concat(this.get('value') / this.get('total') * 100, "%;");
    })
  });

  _exports.default = _default;
});