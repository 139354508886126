define("frontend/controllers/add-client/documents", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    removingDocument: null,
    currentUser: inject.service(),
    hasContracts: computed.gt('model.contracts.length', 0),
    hasMultipeContracts: computed.gt('model.contracts.length', 1),
    signedContracts: computed.equal('model.household.esignature_status', 'signed'),
    contractsNotSent: computed.equal('model.household.esignature_status', 'none'),
    showContractsAlert: computed('hasContracts', 'signedContracts', function () {
      return this.get('hasContracts') && !this.get('signedContracts');
    }),
    clickedContractButton: false,
    emptyDocuments: computed('model.documents', function () {
      var documents = this.get('model.documents');
      return documents.length == 0;
    }),
    shouldSeeHouseholdContractAlert: computed('model.household.id', 'currentUser.user.households_contract_alerts_dismissed.[]', function () {
      return this.get('showContractsAlert') && this.get('currentUser.user').shouldSeeHouseholdContractAlert(parseInt(this.get('model.household.id')));
    }),
    clientContractsAvailableToSend: computed('currentUser.user.company.client_contracts.[]', 'model.signatureRequests.[]', function () {
      var signatureRequests = this.get('model.signatureRequests');
      var clientContracts = this.get('currentUser.user.company.client_contracts').map(function (contract) {
        return Ember.Object.create({
          id: contract.get('id'),
          sent: signatureRequests.isAny('client_contract_id', parseInt(contract.get('id')))
        });
      });
      var numChoices = clientContracts.filterBy('sent', false).length;
      return numChoices > 0;
    }),
    actions: {
      toggleSendDocumentsModal: function toggleSendDocumentsModal() {
        this.toggleProperty('showSendDocumentsModal');
      },
      denyRemoveDocument: function denyRemoveDocument() {
        this.set('removingDocument', null);
      },
      confirmRemoveDocument: function confirmRemoveDocument() {
        var _this = this;

        var household = this.get('model.household');
        var doc = this.get('removingDocument');

        _jquery.default.ajax({
          url: "/api/v2/households/".concat(household.get('id'), "/user_documents/").concat(doc.id),
          method: 'DELETE'
        }).then(function () {
          _this.send('reload');
        }).done(function () {
          _this.set('removingDocument', null);
        });
      },
      removeDocument: function removeDocument(doc) {
        this.set('removingDocument', doc);
      },
      activateUploadFilePicker: function activateUploadFilePicker() {
        $('input').click();
      },
      uploadDocument: function uploadDocument(doc, fileName) {
        var _this2 = this;

        var household = this.get('model.household');
        this.set('uploading', true);

        _jquery.default.ajax({
          url: "/api/v2/households/".concat(household.get('id'), "/user_documents"),
          method: 'POST',
          data: {
            user_document: {
              document: doc,
              filename: fileName
            }
          }
        }).then(function () {
          _this2.send('reload');
        }).done(function () {
          _this2.set('uploading', false);
        });
      },
      sendContracts: function sendContracts() {
        var _this3 = this;

        var householdId = this.get('model.household.id');
        this.set('sendingContracts', true);

        _jquery.default.ajax({
          url: "/api/v2/client_contracts/send_client_contracts",
          method: 'POST',
          data: {
            household_id: householdId,
            send_email: true
          }
        }).then(function () {
          _this3.set('justSentContracts', true);

          _this3.set('model.household.esignature_status', 'sent');

          _this3.set('clickedContractButton', true);

          _this3.set('sendingContracts', false);
        });
      },
      sendClientContracts: function sendClientContracts(clientContractIds) {
        var householdId = this.get('model.household.id');

        _jquery.default.ajax({
          url: "/api/v2/client_contracts/send_specific_contracts_to_household",
          method: 'POST',
          data: {
            contract_ids: clientContractIds,
            household_id: householdId
          }
        }).then(function () {
          window.location.reload();
        });
      },
      sendContractReminder: function sendContractReminder() {
        var _this4 = this;

        var householdId = this.get('model.household.id');
        this.set('sendingContracts', true);

        _jquery.default.ajax({
          url: "/api/v2/client_contracts/send_client_contract_reminder",
          method: 'POST',
          data: {
            household_id: householdId
          }
        }).then(function () {
          _this4.set('justSentContracts', false);

          _this4.set('clickedContractButton', true);

          _this4.set('sendingContracts', false);
        });
      },
      sendReminder: function sendReminder() {
        var _this5 = this;

        var householdId = this.get('model.household.id');
        this.set('sendingContracts', true);

        _jquery.default.ajax({
          url: "/api/v2/client_contracts/send_reminder",
          method: 'POST',
          data: {
            household_id: householdId
          }
        }).then(function () {
          _this5.set('justSentContracts', false);

          _this5.set('clickedContractButton', true);

          _this5.set('sendingContracts', false);
        });
      },
      denyCancelRequest: function denyCancelRequest() {
        this.set('selectedSignatureRequest', null);
      },
      cancelRequest: function cancelRequest(signatureRequest) {
        var archive = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
        this.set('selectedSignatureRequest', signatureRequest);
        this.set('isArchiving', archive);
      },
      confirmCancelRequest: function confirmCancelRequest() {
        var signatureRequest = this.get('selectedSignatureRequest');

        _jquery.default.ajax({
          url: "/api/v2/client_contracts/cancel_request",
          method: 'POST',
          data: {
            signature_request_id: signatureRequest.id
          }
        }).then(function () {
          window.location.reload();
        });
      },
      emailPDF: function emailPDF(signatureRequestId) {
        _jquery.default.ajax({
          url: "/api/v2/client_contracts/email_pdf",
          method: 'POST',
          data: {
            signature_request_id: signatureRequestId
          }
        }).then(function () {
          window.location.reload();
        });
      },
      dismissAgreements: function dismissAgreements() {
        var householdId = parseInt(this.get('model.household.id'));
        this.get('currentUser.user').addHouseholdIdToDismissedContracts(householdId);

        _jquery.default.ajax({
          url: '/api/v2/user_dismissed_contracts_alert',
          method: 'POST',
          data: {
            household_id: householdId
          }
        });
      }
    }
  });

  _exports.default = _default;
});