define("frontend/components/mobile-collapse-dropdown", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['link-container', 'collapse-dropdown'],
    classNameBindings: ['active'],
    active: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _jquery.default)(this.element.querySelectorAll('.header-link')).click(function (e) {
        var dropdownClosed = (0, _jquery.default)(e.target).hasClass('active') && !_this.get('active');

        _this.set('active', dropdownClosed);
      });
      (0, _jquery.default)(this.element).focusout(function () {
        _this.set('active', false);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)(this.element).off('focusout');
      (0, _jquery.default)(this.element.querySelectorAll('.header-link')).off('click');
    }
  });

  _exports.default = _default;
});