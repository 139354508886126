define("frontend/templates/components/compensation-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fz7nYxzZ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"and\",[[28,\"gt\",[[24,[\"apptValue\"]],0],null],[28,\"gt\",[[24,[\"tipValue\"]],0],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"total-percentage-bar\"],[11,\"style\",[29,[\"width: \",[22,\"totalWidth\"],\"%\"]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"appt-bar inner-percentage-bar\"],[11,\"style\",[29,[\"width: \",[22,\"apptWidth\"],\"%\"]]],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"tip-bar inner-percentage-bar\"],[11,\"style\",[29,[\"width: \",[22,\"tipWidth\"],\"%\"]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"percentage-bar-amount\"],[8],[0,\"\\n    \"],[1,[22,\"label\"],true],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[28,\"gt\",[[24,[\"tipValue\"]],0],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"user-bar\",null,[[\"color\",\"value\",\"total\",\"label\"],[\"dark-blue\",[24,[\"tipValue\"]],[24,[\"total\"]],[24,[\"label\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"user-bar\",null,[[\"color\",\"value\",\"total\",\"label\"],[\"blue\",[24,[\"apptValue\"]],[24,[\"total\"]],[28,\"format-money\",[[24,[\"apptValue\"]]],[[\"precision\"],[2]]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/compensation-bar.hbs"
    }
  });

  _exports.default = _default;
});