define("frontend/templates/components/activity-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uEYu6QjQ",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"toggleActivityLog\"],null]],[11,\"class\",[29,[\"activity-log-icon \",[28,\"if\",[[24,[\"collapsed\"]],\"collapsed-icon\",\"uncollapsed-icon\"],null]]]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"hasUnreadLogs\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"inline-svg\",[\"assets/images/unread-activity-log-icon.svg\"],null],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"inline-svg\",[\"assets/images/read-activity-log-icon.svg\"],null],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"showActivityLog\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"activity-log-side-panel\",null,[[\"logs\",\"closeActivityLog\"],[[24,[\"logs\"]],[28,\"action\",[[23,0,[]],\"closeActivityLog\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/activity-log.hbs"
    }
  });

  _exports.default = _default;
});