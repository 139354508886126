define("frontend/controllers/portal/appointments", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var SCHEDULE_TYPE = 'schedule';
  var PENDING_TYPE = 'pending';
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    reactApp: inject.service(),
    household: computed.readOnly('currentUser.user.household'),
    queryParams: ['appointmentType', 'appointmentId', 'showReportCard'],
    showRequestAppointmentModal: false,
    appointmentType: SCHEDULE_TYPE,
    appointmentId: null,
    showReportCard: false,
    onSchedule: computed.equal('appointmentType', SCHEDULE_TYPE),
    onPending: computed.equal('appointmentType', PENDING_TYPE),
    selectedReportCard: computed.oneWay('model.reportCardAppointment.report_card'),
    selectedReportCardAppointment: computed.oneWay('model.reportCardAppointment'),
    allCategoriesRequireSigning: computed.readOnly('currentUser.allCategoriesRequireSigning'),
    sendModifyEmail: function sendModifyEmail(groupedAppointment) {
      var petNames = this.get('currentUser.user.household.petNames');
      var companyEmail = groupedAppointment.get('service.company_email');
      var lastName = this.get('currentUser.user.household.owner.last_name');
      var subject = "Request to modify appointment for ".concat(petNames, " ").concat(lastName);
      var userName = this.get('currentUser.user.fullName');
      var service = groupedAppointment.get('offered_service.nameWithDuration');
      var apptDate = moment(groupedAppointment.get('service.starts_at')).format('dddd, MMMM Do');
      var pickupWindow = groupedAppointment.get('pickup_window') ? "".concat(groupedAppointment.get('pickup_window'), " arrival") : groupedAppointment.get('service.starts_at_time');
      var body = "Hi!%0D%0A%0D%0AI'd like to modify the ".concat(service, " for ").concat(petNames, " on ").concat(apptDate, ", currently scheduled for ").concat(pickupWindow, ".%0D%0A%0D%0AI'd like to change:%0D%0A%0D%0APlease let me know if you can accommodate or if you have any questions!%0D%0A%0D%0AThanks!%0D%0A").concat(userName);
      window.location.href = "mailto:".concat(companyEmail, "?subject=").concat(subject, "&body=").concat(body);
    },
    showTabs: computed('currentUser.user.client_accounts.[]', function () {
      var clientAccounts = this.get('currentUser.user.client_accounts');

      if (clientAccounts) {
        return clientAccounts.any(function (acct) {
          return acct.parent_in_app_booking;
        });
      } else {
        return false;
      }
    }),
    removeNewRecords: function removeNewRecords() {
      this.store.peekAll('address').filter(function (a) {
        return a.get('id') == undefined;
      }).forEach(function (a) {
        return a.deleteRecord();
      });
      this.store.peekAll('pet').filter(function (p) {
        return p.get('id') == undefined;
      }).forEach(function (p) {
        return p.deleteRecord();
      });
    },
    reloadDirtyRecords: function reloadDirtyRecords() {
      this.store.peekAll('address').filterBy('hasDirtyAttributes').forEach(function (a) {
        return a.reload();
      });
      this.store.peekAll('pet').filterBy('hasDirtyAttributes').forEach(function (p) {
        return p.reload();
      });
    },
    actions: {
      requestAppointment: function requestAppointment() {
        if (this.get('allCategoriesRequireSigning')) {
          this.send('showSignContractsModalForSpecificCategory');
        } else {
          var pets = this.get('household.pets');
          var addresses = this.get('household.owner.addresses');

          if (pets.get('length') == 0 || addresses.get('length') == 0) {
            if (pets.get('length') == 0) {
              this.send('addPet');
            }

            if (addresses.get('length') == 0) {
              var state = this.get('currentUser.user.company.state');
              this.get('household').get('owner.addresses').pushObject(this.store.createRecord('address', {
                state: state
              }));
            }

            this.set('showMissingInfoModal', true);
            return;
          }

          var clientAccounts = this.get('currentUser.user.client_accounts');
          var activeClientAccounts = clientAccounts.filter(function (account) {
            return !account.company_deactivated && !account.denied_request && account.pet_parents_activated || account.staff_status == 'Owner';
          });

          if (activeClientAccounts.length == 1) {
            this.send('checkAppointmentRequestFeature', activeClientAccounts[0]);
          } else {
            this.set('showRequestAppointmentModal', true);
          }
        }
      },
      checkAppointmentRequestFeature: function checkAppointmentRequestFeature(clientAccount) {
        if (clientAccount.parent_in_app_booking) {
          window.location.href = this.get('reactApp').getAppointmentRequestUrl(clientAccount.company_id);
        } else {
          this.send('sendRequestAppointmentEmail', clientAccount.company_email);
        }
      },
      sendRequestAppointmentEmail: function sendRequestAppointmentEmail(companyEmail) {
        this.send('closeRequestAppointmentModal');
        var petNames = this.get('household.petNames');
        var lastName = this.get('household.owner.last_name');
        var subject = "Appointment request for ".concat(petNames, " ").concat(lastName);
        var userName = this.get('currentUser.user.fullName');
        var body = "Hi!%0D%0A%0D%0AI'd like to request an appointment for ".concat(petNames, " for the following:%0D%0A%0D%0AService:%0D%0A%0D%0AAppointment date(s) and time frame:%0D%0A%0D%0AAdditional notes:%0D%0A%0D%0APlease let me know if you can accommodate or if you have any questions!%0D%0A%0D%0AThanks!%0D%0A").concat(userName);
        window.location.href = "mailto:".concat(companyEmail, "?subject=").concat(subject, "&body=").concat(body);
      },
      modifyAppointment: function modifyAppointment(groupedAppointment) {
        var _this = this;

        var clientAccounts = this.get('currentUser.user.client_accounts');
        var clientAccount = clientAccounts.filter(function (acct) {
          return acct.company_id == groupedAppointment.get('company_id');
        }).get('firstObject');

        if (clientAccount.parent_in_app_booking) {
          if (groupedAppointment.get('offered_service.all_day_service') || !groupedAppointment.get('offered_service.show_to_pet_parents')) {
            this.set('selectedClientAccount', clientAccount);
            this.set('showAllDayServiceModal', true);
          } else {
            _jquery.default.ajax({
              url: "".concat(_environment.default.APP.host, "/api/v2/grouped_appointments/").concat(groupedAppointment.get('id'), "/check_if_before_cut_off"),
              method: 'GET'
            }).then(function (response) {
              if (response.before_cut_off) {
                var ids = groupedAppointment.get('appointments').map(function (appt) {
                  return appt.get('id');
                });
                window.location = _this.get('reactApp').getAppointmentRequestUrl(groupedAppointment.get('company_id'), null, ids);
              } else {
                _this.set('showTooSoonModal', true);
              }
            });
          }
        } else {
          this.sendModifyEmail(groupedAppointment);
        }
      },
      modifyAppointmentRequest: function modifyAppointmentRequest(appointmentRequest) {
        var _this2 = this;

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointment_requests/").concat(appointmentRequest.get('id'), "/check_if_approved"),
          method: 'GET'
        }).then(function (response) {
          var approved = response.approved;

          if (approved) {
            Ember.getOwner(_this2).lookup('controller:application').send('notify', {
              message: 'This request has already been approved. Please go to your appointments tab to modify this appointment.',
              type: 'user-error'
            });
          } else {
            window.location = _this2.get('reactApp').getAppointmentRequestUrl(appointmentRequest.get('company_id'), appointmentRequest.get('id'));
          }
        });
      },
      closeTooSoonModal: function closeTooSoonModal() {
        this.set('showTooSoonModal', false);
      },
      goToMessenger: function goToMessenger() {
        this.transitionToRoute('portal.messages.company', this.get('selectedClientAccount.company_id'));
        this.set('selectedClientAccount', null);
        this.set('showAllDayServiceModal', false);
      },
      closeAllDayServiceModal: function closeAllDayServiceModal() {
        this.set('selectedClientAccount', null);
        this.set('showAllDayServiceModal', false);
      },
      closeRequestAppointmentModal: function closeRequestAppointmentModal() {
        this.set('showRequestAppointmentModal', false);
      },
      clickSchedule: function clickSchedule() {
        this.set('appointmentType', SCHEDULE_TYPE);
      },
      clickPending: function clickPending() {
        this.set('appointmentType', PENDING_TYPE);
      },
      closeMissingInfoModal: function closeMissingInfoModal() {
        this.removeNewRecords.call(this);
        this.set('showMissingInfoModal', false);
      },
      addPet: function addPet() {
        var _this3 = this;

        var household = this.get('currentUser.user.household');

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(household.get('id'), "/get_companies"),
          method: 'GET',
          data: {
            id: household.get('id')
          }
        }).done(function (response) {
          var pet = _this3.store.createRecord('pet');

          pet.set('company_notes', response.company_notes);
          household.get('pets').pushObject(pet);
        });
      },
      saveHousehold: function saveHousehold() {
        var _this4 = this;

        var petPromises = this.get('currentUser.user.household.pets').map(function (pet) {
          return pet.save();
        });
        var userPromise = this.get('currentUser.user.household.owner').save();
        var promises = [userPromise].concat(_toConsumableArray(petPromises));
        return Ember.RSVP.Promise.all(promises).then(function () {
          _this4.removeNewRecords.call(_this4);

          _this4.reloadDirtyRecords.call(_this4);

          _this4.send('requestAppointment');

          _this4.set('showMissingInfoModal', false);
        });
      },
      showReportCard: function showReportCard(groupedReportCard) {
        this.set('selectedReportCard', groupedReportCard);
      },
      closeReportCard: function closeReportCard() {
        this.set('selectedReportCard', null);
      }
    }
  });

  _exports.default = _default;
});