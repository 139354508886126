define("frontend/components/invoices-widget/draft-header", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['invoices-widget-header', 'draft-header'],
    tagName: 'header',
    currentUser: inject.service(),
    flashMessage: inject.service(),
    sentDraftEmail: false,
    actions: {
      downloadDrafts: function downloadDrafts() {
        var _this = this;

        if (this.get('sentDraftEmail')) {
          return;
        }

        this.set('sentDraftEmail', true);

        _jquery.default.ajax({
          url: 'api/v2/invoices/download_drafts',
          method: 'GET'
        }).done(function () {
          _this.get('flashMessage').success("You'll receive an email with your draft invoices shortly");
        });
      }
    }
  });

  _exports.default = _default;
});