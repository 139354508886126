define("frontend/routes/client-list/household/messages", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = _baseRoute.default.extend({
    infinity: inject.service(),
    currentUser: inject.service(),
    router: inject.service(),
    model: function model() {
      var household = this._super.apply(this, arguments);

      var household_id = household.get('id');
      var company_id = this.get('currentUser.user.company.id');
      var company = this.store.findRecord('company', company_id);
      var messages = this.get('infinity').model('message', {
        household_id: household_id,
        company_id: company_id,
        perPage: 20,
        startingPage: 1
      });
      return Ember.RSVP.hash({
        household: household,
        messages: messages,
        company: company
      });
    },
    actions: {
      goToAppointmentRequest: function goToAppointmentRequest(requestId, companyId, householdId) {
        var queryParams = {
          backRoute: this.get('router.currentRouteName'),
          backHouseholdId: householdId,
          requestId: requestId
        };
        this.transitionTo('edit-single-appointment.index', {
          queryParams: queryParams
        });
      }
    }
  });

  _exports.default = _default;
});