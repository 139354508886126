define("frontend/models/appointment", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = _emberData.default.Model.extend({
    timeZone: inject.service(),
    special_instructions: _emberData.default.attr(),
    street: _emberData.default.attr(),
    city: _emberData.default.attr(),
    state: _emberData.default.attr(),
    zipcode: _emberData.default.attr(),
    recurring_appointment: _emberData.default.belongsTo('recurring-appointment', {
      async: true
    }),
    recurring_appointment_id: _emberData.default.attr(),
    deleted_at: _emberData.default.attr(),
    canceled_at: _emberData.default.attr(),
    canceled_because_of_request: _emberData.default.attr('boolean'),
    completed: _emberData.default.attr(),
    care_provider: _emberData.default.belongsTo('care-provider', {
      async: true
    }),
    created_at: _emberData.default.attr(),
    updated_at: _emberData.default.attr(),
    offered_service_name: _emberData.default.attr('string'),
    offered_service_color_hex: _emberData.default.attr('string'),
    appointment_detail: _emberData.default.belongsTo('appointment-detail', {
      async: true
    }),
    cost: _emberData.default.attr('number'),
    time: _emberData.default.attr('string'),
    service_starts_at: _emberData.default.attr('string'),
    user_id: _emberData.default.attr(),
    user: _emberData.default.belongsTo('user'),
    can_delete: _emberData.default.attr('boolean'),
    pickup_window: _emberData.default.attr(''),
    company_id: _emberData.default.attr(''),
    company_time_zone: _emberData.default.attr('string'),
    time_frame_start_time: _emberData.default.attr('string'),
    time_frame_end_time: _emberData.default.attr('string'),
    set_by_custom_window: _emberData.default.attr('boolean'),
    grouped_appointment: _emberData.default.belongsTo('grouped-appointment', {
      async: true
    }),
    started_at: _emberData.default.attr('string'),
    finished_at: _emberData.default.attr('string'),
    started_at_latitude: _emberData.default.attr('string'),
    started_at_longitude: _emberData.default.attr('string'),
    finished_at_latitude: _emberData.default.attr('string'),
    finished_at_longitude: _emberData.default.attr('string'),
    gps_markers_report_card_entry_desktop_url: _emberData.default.attr('string'),
    gps_markers_report_card_view_desktop_url: _emberData.default.attr('string'),
    show_report_card_on_completion: _emberData.default.attr('boolean'),
    service: _emberData.default.belongsTo('service', {
      async: true
    }),
    offered_service: _emberData.default.belongsTo('offered_service', {
      async: true
    }),
    appointment_add_on_services: _emberData.default.hasMany('appointment-add-on-service', {
      async: false
    }),
    pet: _emberData.default.belongsTo('pet', {
      async: true
    }),
    grouped_report_card: _emberData.default.belongsTo('grouped-report-card', {
      async: false
    }),
    updatingCompleteStatus: false,
    endTime: computed('time', 'offered_service.duration_in_minutes', function () {
      return _moment.default.parseZone(this.get('time')).add(this.get('offered_service.duration_in_minutes'), 'minutes').format();
    }),
    isRecurring: computed('recurring_appointment_id', function () {
      return this.get('recurring_appointment_id') != null;
    }),
    instructions: computed('special_instructions', 'pet.internal_user_notes', 'pet.client_notes', function () {
      var html = '';

      if (this.get('special_instructions')) {
        html = '<h3>Appointment Instructions</h3>';
        html += "<p>".concat(this.get('special_instructions'), "</p>");
      }

      var userNotes = this.get('pet').get('client_notes');

      if (userNotes) {
        html += '<h3>Client Instructions</h3>';
        html += "<p>".concat(userNotes, "</p>");
      }

      var internalNotes = this.get('pet').get('internal_user_notes');

      if (internalNotes) {
        html += '<h3>Internal Notes</h3>';
        html += "<p>".concat(internalNotes, "</p>");
      }

      if (html === '') {
        return false;
      } else {
        return html;
      }
    })
  });

  _exports.default = _default;
});