define("frontend/components/toggle-with-conditional-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['toggle-with-conditional-input'],
    toggleIsOn: false,
    toggleDisabled: false,
    media: inject.service(),
    inputLabel: null,
    inputValue: null,
    defaultStartingValue: null,
    inputOnChange: null,
    showInputErrorMessage: false,
    inputErrorMessage: '',
    clarification: null,
    inputValidation: null,
    inputValueChanged: null,
    isMobile: computed.readOnly("media.isMobile"),
    didRender: function didRender() {
      this._super.apply(this, arguments);

      var valueIsTruthy = !!this.get('inputValue');
      if (valueIsTruthy) this.set('toggleIsOn', true);
    },
    actions: {
      handleToggleOnChange: function handleToggleOnChange(toggleOn) {
        this.set('toggleIsOn', toggleOn);

        if (!toggleOn) {
          this.set('inputValue', null);
        } else {
          this.set('inputValue', this.get('defaultStartingValue'));
        }
      },
      handleInputOnChange: function handleInputOnChange() {},
      handleInputChange: function handleInputChange(valueOrEvent) {
        var value = valueOrEvent;

        if (valueOrEvent !== null && valueOrEvent !== void 0 && valueOrEvent.target) {
          value = value.target.value;
        }

        this.set('inputValue', value);
        var valid = this.get('inputValidation')(value);
        this.set('showInputErrorMessage', !valid);
        if (this.get('inputValueChanged') && value) this.get('inputValueChanged')(value);
      },
      handleInputKeyPress: function handleInputKeyPress(event) {
        var input = event.target;
        var inputValue = parseFloat(input.value);
        var decimalPlaces = (inputValue.toString().split('.')[1] || '').length;

        if (decimalPlaces >= 3 && event.key !== '.' && !event.metaKey && !event.ctrlKey) {
          event.preventDefault();
        }
      }
    }
  });

  _exports.default = _default;
});