define("frontend/services/flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.UNEXPECTED_ERROR_MESSAGE = void 0;
  var UNEXPECTED_ERROR_MESSAGE = 'Unexpected error occured. Please try again.';
  _exports.UNEXPECTED_ERROR_MESSAGE = UNEXPECTED_ERROR_MESSAGE;

  var _default = Ember.Service.extend({
    error: function error(message) {
      this.sendFlashMessage(message, 'user-error');
    },
    errorWithRefresh: function errorWithRefresh(message) {
      this.sendFlashMessage(message, 'error');
    },
    errorWithoutRefresh: function errorWithoutRefresh(message) {
      this.sendFlashMessage(message, 'error-without-refresh');
    },
    success: function success(message) {
      this.sendFlashMessage(message, 'success');
    },
    confirm: function confirm(yes, no, message) {
      var yesText = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 'Yes';
      var noText = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : 'No';
      var type = 'confirm';
      Ember.getOwner(this).lookup('controller:application').send('notify', {
        message: message,
        type: type,
        yesText: yesText,
        noText: noText,
        yes: yes,
        no: no
      });
    },
    newMessage: function newMessage(yes, no) {
      var params = {
        yes: yes,
        no: no,
        type: 'new-message',
        message: 'You have a new message!',
        yesText: 'Go to inbox',
        noText: 'Dismiss'
      };
      Ember.getOwner(this).lookup('controller:application').send('notify', params);
    },
    sendFlashMessage: function sendFlashMessage(message, type) {
      Ember.getOwner(this).lookup('controller:application').send('notify', {
        message: message,
        type: type
      });
    }
  });

  _exports.default = _default;
});