define("frontend/templates/inbox/household", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EwgGfHsM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"header-first-names\"]],{\"statements\":[[0,\"    \"],[1,[22,\"firstNames\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"ember-wormhole\",null,[[\"to\"],[\"header-family-name\"]],{\"statements\":[[0,\"    \"],[1,[24,[\"household\",\"petNames\"]],false],[0,\" \\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"unless\",[[24,[\"showingNewMessagePage\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"message-thread\",null,[[\"messages\",\"company\",\"showingCompanySelection\",\"household\",\"showHeader\",\"transitionBackToInbox\",\"transitionToHousehold\",\"goToAppointmentRequest\"],[[24,[\"model\",\"messages\"]],[24,[\"currentUser\",\"user\",\"company\"]],[24,[\"hasMultipleCompanies\"]],[24,[\"household\"]],true,[28,\"action\",[[23,0,[]],\"transitionBackToInbox\"],null],[28,\"route-action\",[\"goToHousehold\"],null],[28,\"route-action\",[\"goToAppointmentRequest\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/inbox/household.hbs"
    }
  });

  _exports.default = _default;
});