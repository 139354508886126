define("frontend/templates/components/adaptive-text-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "r2VR2idx",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"mobile-text-input\",null,[[\"readonly\",\"borderBottom\",\"borderTop\",\"label\",\"value\"],[[24,[\"readonly\"]],[24,[\"borderBottom\"]],[24,[\"borderTop\"]],[24,[\"label\"]],[24,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"label\",true],[8],[1,[22,\"label\"],false],[9],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"placeholder\",\"readonly\",\"value\"],[[24,[\"placeholder\"]],[24,[\"readonly\"]],[24,[\"value\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/adaptive-text-input.hbs"
    }
  });

  _exports.default = _default;
});