define("frontend/components/manual-payment-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    company: null,
    actions: {
      onClose: function onClose() {
        this.get('onClose')();
      }
    }
  });

  _exports.default = _default;
});