define("frontend/templates/components/dropdown-callout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IdpnWL3u",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"mobile-select\",null,[[\"selectOption\",\"chooseText\",\"label\",\"options\",\"value\",\"borderTop\",\"borderBottom\",\"disabled\"],[[28,\"action\",[[23,0,[]],\"selectOption\"],null],[24,[\"chooseText\"]],[24,[\"label\"]],[24,[\"options\"]],[24,[\"value\"]],[24,[\"borderTop\"]],[24,[\"borderBottom\"]],[24,[\"disabled\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"input-callout\",null,[[\"clickCalloutLabel\",\"tabindex\",\"label\",\"callout\",\"inputActivated\",\"inputSelector\"],[[28,\"action\",[[23,0,[]],\"focusIn\"],null],[24,[\"tabindex\"]],[24,[\"label\"]],[24,[\"callout\"]],[24,[\"inputActivated\"]],\".popout-dropdown .ember-power-select-trigger\"]],{\"statements\":[[0,\"    \"],[1,[28,\"popout-dropdown\",null,[[\"tabindex\",\"options\",\"open\",\"selectedValue\",\"selectOption\",\"disabled\"],[[24,[\"tabindex\"]],[24,[\"options\"]],[28,\"action\",[[23,0,[]],\"open\"],null],[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"selectOption\"],null],[24,[\"disabled\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"and\",[[28,\"and\",[[24,[\"hover\"]],[24,[\"hint\"]]],null],[24,[\"showHint\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"alert-hint\",null,[[\"hint\"],[[24,[\"hint\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/dropdown-callout.hbs"
    }
  });

  _exports.default = _default;
});