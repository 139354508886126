define("frontend/components/input-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    validationObject: null,
    classNames: ['input-validation'],
    classNameBindings: ['showError:red-validation-error'],
    selector: 'input',
    showError: false,
    didValidate: false,
    didBlur: false,
    validateOnFocusOut: true,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('didValidate') || this.get('didBlur')) {
        this.set('showError', this.get('isInvalid'));
      }

      if ($(this.get('fullSelector')).length === 0) {
        return;
      }

      if (this.get('validateOnFocusOut') && !this.get('didBlur')) {
        $(this.get('fullSelector')).off('blur');
        $(this.get('fullSelector')).blur(function () {
          _this.set('didBlur', true);

          _this.set('showError', _this.get('isInvalid'));
        });
      }
    },
    errorMessage: computed('validationObject.message', function () {
      return this.get('validationObject.message');
    }),
    fullSelector: computed('selector', 'elementId', function () {
      var selector = this.get('selector');
      return "#".concat(this.get('elementId'), " ").concat(selector);
    }),
    isInvalid: computed('validationObject.isInvalid', function () {
      return this.get('validationObject.isInvalid');
    }),
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      $(this.get('fullSelector')).off('blur');
    }
  });

  _exports.default = _default;
});