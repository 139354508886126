define("frontend/templates/components/appointment-requests-list/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jjmBLYAc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"not\",[[28,\"media\",[\"isMobile\"],null]],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"header-text\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showDropdown\"]]],null],[28,\"not\",[[24,[\"showDropdown\"]]],null]],null]],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"dropdown-arrow down\"],[8],[9],[0,\"\\n    \"],[1,[22,\"headerTitle\"],false],[0,\" Requests\\n    \"],[7,\"span\",true],[10,\"class\",\"total-count-container\"],[8],[0,\"\\n      \"],[1,[22,\"totalCount\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showDropdown\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"appointment-requests-list/header-dropdown\",null,[[\"options\",\"selectedOption\",\"close\",\"selectOption\"],[[24,[\"requestTypeOptions\"]],[24,[\"selectedRequestType\"]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showDropdown\"]]],null],false],null],[28,\"action\",[[23,0,[]],\"selectHeaderOption\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/appointment-requests-list/header.hbs"
    }
  });

  _exports.default = _default;
});