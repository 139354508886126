define("frontend/validators/international-postal-code", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var InternationalPostalCode = _base.default.extend({
    countryInfo: inject.service(),
    validate: function validate(value, options, model) {
      //            company owner                   admin creating company                                     user editing personal address
      var country = model.get('address.country') || model.get('companyCountry') || model.get('vet.country') || this.get('countryInfo.currentUserCountry');

      if (country === 'United States') {
        var usRegex = /^\d{5}$/;

        if (!usRegex.test(value)) {
          return 'Please use a 5 digit zip code';
        }
      } else if (country === 'Australia') {
        var _usRegex = /^\d{4}$/;

        if (!_usRegex.test(value)) {
          return 'Please use a 4 digit postcode';
        }
      } else if (country === 'Canada') {
        var cadRegex = /^([a-zA-Z]\d[a-zA-z]( )?\d[a-zA-Z]\d)$/;

        if (!cadRegex.test(value)) {
          return 'Please use a 6-character alphanumeric postal code';
        }
      }

      return true;
    }
  });

  InternationalPostalCode.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor: function getDependentsFor() {
      return [];
    }
  });
  var _default = InternationalPostalCode;
  _exports.default = _default;
});