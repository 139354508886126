define("frontend/components/adaptive-text-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['adaptive-text-input'],
    classNameBindings: ['dollar'],
    dollar: false,
    borderTop: false,
    borderBottom: true
  });

  _exports.default = _default;
});