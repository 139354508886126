define("frontend/components/payment-method-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var ModalSteps = {
    CHOOSE_PAYMENT_METHOD: 'choose_payment_method',
    BANK_TRANSFER: 'bank_transfer',
    ADD_CREDIT_CARD: 'add_credit_card'
  };

  var _default = Ember.Component.extend({
    classNames: ['payment-method-modal'],
    step: ModalSteps.CHOOSE_PAYMENT_METHOD,
    choosePaymentMethod: computed.equal('step', ModalSteps.CHOOSE_PAYMENT_METHOD),
    bankTransfer: computed.equal('step', ModalSteps.BANK_TRANSFER),
    addCreditCard: computed.equal('step', ModalSteps.ADD_CREDIT_CARD),
    plaidRedirect: false,
    oauthEnabled: false,
    accountHolderName: null,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('showAch') && !this.get('showCard') || this.get('plaidRedirect')) {
        this.set('step', ModalSteps.BANK_TRANSFER);
      }

      if (this.get('showCard') && !this.get('showAch')) {
        this.set('step', ModalSteps.ADD_CREDIT_CARD);
      }
    },
    actions: {
      onClose: function onClose() {
        this.get('onClose')();
      },
      saveCard: function saveCard(token) {
        this.get('savePaymentMethod')(token, 'card');
      },
      saveBankAccount: function saveBankAccount(token) {
        this.get('savePaymentMethod')(token, 'bank_account');
      },
      clickCreditCard: function clickCreditCard() {
        this.set('step', ModalSteps.ADD_CREDIT_CARD);
      },
      clickBankTransfer: function clickBankTransfer() {
        this.set('step', ModalSteps.BANK_TRANSFER);
      },
      setBankAccount: function setBankAccount() {}
    }
  });

  _exports.default = _default;
});