define("frontend/services/holiday-dates-service", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    holidays: {
      'default': [{
        label: 'Martin Luther King Jr. Day',
        shortenedName: 'MLK Day',
        iconName: 'mlk.svg',
        date: '2024-01-15',
        defaultSelected: ['2024-01-13', '2024-01-14', '2024-01-15']
      }, {
        label: "President's Day",
        iconName: 'presidents.svg',
        date: '2024-02-19',
        defaultSelected: ['2024-02-17', '2024-02-18', '2024-02-19']
      }, {
        label: 'Easter',
        iconName: 'easter.svg',
        date: '2024-03-31',
        defaultSelected: ['2024-03-29', '2024-03-30', '2024-03-31', '2024-04-01']
      }, {
        label: 'Memorial Day',
        iconName: 'memorial.svg',
        date: '2024-05-27',
        defaultSelected: ['2024-05-25', '2024-05-26', '2024-05-27']
      }, {
        label: 'Juneteenth',
        iconName: 'juneteenth.svg',
        date: '2024-06-19',
        defaultSelected: ['2024-06-18', '2024-06-19', '2024-06-20']
      }, {
        label: 'Independence Day',
        shortenedName: 'July 4th',
        iconName: 'fireworks-icon.svg',
        date: '2024-07-04',
        defaultSelected: ['2024-07-04', '2024-07-05', '2024-07-06', '2024-07-07']
      }, {
        label: 'Labor Day',
        iconName: 'labor_day.svg',
        date: '2024-09-02',
        defaultSelected: ['2024-08-31', '2024-09-01', '2024-09-02']
      }, {
        label: 'Columbus Day',
        iconName: 'columbus.svg',
        date: '2024-10-14',
        defaultSelected: ['2024-10-12', '2024-10-13', '2024-10-14']
      }, {
        label: 'Veterans Day',
        iconName: 'veterans.svg',
        date: '2024-11-11',
        defaultSelected: ['2024-11-09', '2024-11-10', '2024-11-11']
      }, {
        label: 'Thanksgiving',
        iconName: 'thanksgiving.svg',
        date: '2024-11-28',
        defaultSelected: ['2024-11-28', '2024-11-29', '2024-11-30', '2024-12-01']
      }, {
        label: 'Christmas',
        iconName: 'christmas.svg',
        date: '2024-12-25',
        defaultSelected: ['2024-12-23', '2024-12-24', '2024-12-25']
      }, {
        label: "New Year's",
        iconName: 'new_years.svg',
        date: '2025-01-01',
        defaultSelected: ['2024-12-30', '2024-12-31', '2025-01-01']
      }],
      'Canada': [{
        label: "Family Day",
        iconName: 'family.svg',
        date: '2024-02-19',
        defaultSelected: ['2024-02-17', '2024-02-18', '2024-02-19']
      }, {
        label: 'Easter',
        iconName: 'easter.svg',
        date: '2024-03-31',
        defaultSelected: ['2024-03-29', '2024-03-30', '2024-03-31', '2024-04-01']
      }, {
        label: 'Victoria Day',
        iconName: 'victoria.svg',
        date: '2024-05-20',
        defaultSelected: ['2024-05-18', '2024-05-19', '2024-05-20']
      }, {
        label: 'Canada Day',
        iconName: 'fireworks-icon.svg',
        date: '2024-07-01',
        defaultSelected: ['2024-06-29', '2024-06-30', '2024-07-01']
      }, {
        label: 'Labor Day',
        iconName: 'labor_day.svg',
        date: '2024-09-02',
        defaultSelected: ['2024-08-31', '2024-09-01', '2024-09-02']
      }, {
        label: 'Thanksgiving',
        iconName: 'thanksgiving.svg',
        date: '2024-10-14',
        defaultSelected: ['2024-10-11', '2024-10-12', '2024-10-13', '2024-10-14']
      }, {
        label: 'Christmas',
        iconName: 'christmas.svg',
        date: '2024-12-25',
        defaultSelected: ['2024-12-23', '2024-12-24', '2024-12-25']
      }, {
        label: "New Year's",
        iconName: 'new_years.svg',
        date: '2025-01-01',
        defaultSelected: ['2024-12-30', '2024-12-31', '2025-01-01']
      }],
      'Australia': [{
        label: 'Easter',
        iconName: 'easter.svg',
        date: '2024-03-31',
        defaultSelected: ['2024-03-29', '2024-03-30', '2024-03-31', '2024-04-01']
      }, {
        label: 'Anzac Day',
        iconName: 'memorial.svg',
        date: '2024-04-25',
        defaultSelected: ['2024-04-25']
      }, {
        label: 'Australia Day',
        iconName: 'fireworks-icon.svg',
        date: '2024-01-26',
        defaultSelected: ['2024-01-26', '2024-01-27', '2024-01-28']
      }, {
        label: 'Christmas',
        iconName: 'christmas.svg',
        date: '2024-12-25',
        defaultSelected: ['2024-12-23', '2024-12-24', '2024-12-25']
      }, {
        label: "New Year's",
        iconName: 'new_years.svg',
        date: '2025-01-01',
        defaultSelected: ['2024-12-30', '2024-12-31', '2025-01-01']
      }]
    },
    auProvinceHolidays: {
      'NSW': [{
        label: 'Labor Day',
        iconName: 'labor_day.svg',
        date: '2024-10-07',
        defaultSelected: ['2024-10-05', '2024-10-06', '2024-10-07']
      }, {
        label: "King's Birthday",
        iconName: 'victoria.svg',
        date: '2024-06-10',
        defaultSelected: ['2024-06-08', '2024-06-09', '2024-06-10']
      }],
      'ACT': [{
        label: 'Labor Day',
        iconName: 'labor_day.svg',
        date: '2024-10-07',
        defaultSelected: ['2024-10-05', '2024-10-06', '2024-10-07']
      }, {
        label: "King's Birthday",
        iconName: 'victoria.svg',
        date: '2024-06-10',
        defaultSelected: ['2024-06-08', '2024-06-09', '2024-06-10']
      }],
      'SA': [{
        label: 'Labor Day',
        iconName: 'labor_day.svg',
        date: '2024-10-07',
        defaultSelected: ['2024-10-05', '2024-10-06', '2024-10-07']
      }, {
        label: "King's Birthday",
        iconName: 'victoria.svg',
        date: '2024-06-10',
        defaultSelected: ['2024-06-08', '2024-06-09', '2024-06-10']
      }],
      'QLD': [{
        label: 'Labor Day',
        iconName: 'labor_day.svg',
        date: '2024-05-06',
        defaultSelected: ['2024-05-04', '2024-05-05', '2024-05-06']
      }, {
        label: "King's Birthday",
        iconName: 'victoria.svg',
        date: '2024-10-07',
        defaultSelected: ['2024-10-05', '2024-10-06', '2024-10-07']
      }],
      'NT': [{
        label: 'Labor Day',
        iconName: 'labor_day.svg',
        date: '2024-05-06',
        defaultSelected: ['2024-05-04', '2024-05-05', '2024-05-06']
      }, {
        label: "King's Birthday",
        iconName: 'victoria.svg',
        date: '2024-06-10',
        defaultSelected: ['2024-06-08', '2024-06-09', '2024-06-10']
      }],
      'VIC': [{
        label: 'Labor Day',
        iconName: 'labor_day.svg',
        date: '2024-03-10',
        defaultSelected: ['2024-03-08', '2024-03-09', '2024-03-10']
      }, {
        label: "King's Birthday",
        iconName: 'victoria.svg',
        date: '2024-06-10',
        defaultSelected: ['2024-06-08', '2024-06-09', '2024-06-10']
      }],
      'TAS': [{
        label: 'Labor Day',
        iconName: 'labor_day.svg',
        date: '2024-03-10',
        defaultSelected: ['2024-03-08', '2024-03-09', '2024-03-10']
      }, {
        label: "King's Birthday",
        iconName: 'victoria.svg',
        date: '2024-06-10',
        defaultSelected: ['2024-06-08', '2024-06-09', '2024-06-10']
      }],
      'WA': [{
        label: 'Labor Day',
        iconName: 'labor_day.svg',
        date: '2024-03-03',
        defaultSelected: ['2024-03-01', '2024-03-02', '2024-03-03']
      }, {
        label: "King's Birthday",
        iconName: 'victoria.svg',
        date: '2024-09-23',
        defaultSelected: ['2024-09-21', '2024-09-22', '2024-09-23']
      }]
    },
    getHolidays: function getHolidays(country, stateOrProvince) {
      var allHolidays = this.get('holidays');
      var holidays = allHolidays[country] || allHolidays['default'];

      if (country == 'Australia' && stateOrProvince) {
        holidays = holidays.concat(this.get('auProvinceHolidays')[stateOrProvince]);
      }

      return holidays.sortBy('date');
    }
  });

  _exports.default = _default;
});