define("frontend/templates/components/dismiss-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+DGkVHvr",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-button\",null,[[\"onclick\"],[[28,\"action\",[[23,0,[]],\"onDismissMessage\"],null]]],{\"statements\":[[0,\"  Don't show me again!\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/dismiss-message.hbs"
    }
  });

  _exports.default = _default;
});