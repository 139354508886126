define("frontend/components/create-company", ["exports", "frontend/config/environment", "ember-cp-validations", "jquery"], function (_exports, _environment, _emberCpValidations, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;
  var observer = Ember.observer;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'companyName': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'companyCountry': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'companyStreet': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'companyCity': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'companyState': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'companyZip': [(0, _emberCpValidations.validator)('international-postal-code', {
      presence: true,
      ignoreBlank: true
    })],
    'companyEmail': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    'companyPhone': [(0, _emberCpValidations.validator)('international-phone-number', {
      presence: true,
      ignoreBlank: true
    })],
    'companyTimeZone': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'ownerFirstName': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'ownerLastName': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'ownerEmail': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    'ownerPhone': [(0, _emberCpValidations.validator)('international-phone-number', {
      presence: true,
      ignoreBlank: true
    })],
    'ownerAddressStreet': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'ownerAddressCity': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'ownerAddressState': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })],
    'ownerAddressZipcode': [(0, _emberCpValidations.validator)('international-postal-code', {
      presence: true,
      ignoreBlank: true
    })],
    'freeTrialEnds': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('length', {
      is: 10
    })],
    'hubspotId': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    countryInfo: Ember.inject.service(),
    flashMessage: Ember.inject.service(),
    createDisabled: false,
    companyCountry: 'United States',
    timeZone: inject.service(),
    timeZoneOptions: computed('companyCountry', function () {
      return this.get('timeZone').timeZoneOptionsForCountry(this.companyCountry);
    }),
    selectedTimeZone: computed('companyTimeZone', function () {
      var _this = this;

      return this.get('timeZoneOptions').find(function (item) {
        return item.value === _this.get('companyTimeZone');
      });
    }),
    updateCountryService: observer('companyCountry', function () {
      this.set('countryInfo.country', this.get('companyCountry'));
    }),
    willDestroyElement: function willDestroyElement() {
      this.set('countryInfo.country', null);
    },
    actions: {
      changeTimeZone: function changeTimeZone(value) {
        this.set('companyTimeZone', value.value);
      },
      createCompany: function createCompany() {
        var _this2 = this;

        this.set('createDisabled', true);
        this.set('didValidate', true);

        if (!this.get('validations.isValid')) {
          this.set('createDisabled', false);
          return;
        }

        var country = this.get('companyCountry');

        if (country) {
          country = country.replace(' ', '');
        }

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/companies"),
          method: 'POST',
          data: {
            company_name: this.get('companyName'),
            company_address_street: this.get('companyStreet'),
            company_address_city: this.get('companyCity'),
            company_address_state: this.get('companyState'),
            company_address_zip: this.get('companyZip'),
            company_address_country: country,
            company_email: this.get('companyEmail'),
            company_phone: this.get('companyPhone'),
            time_zone: this.get('companyTimeZone'),
            owner_first: this.get('ownerFirstName'),
            owner_last: this.get('ownerLastName'),
            owner_email: this.get('ownerEmail'),
            owner_phone: this.get('ownerPhone'),
            owner_address_street: this.get('ownerAddressStreet'),
            owner_address_city: this.get('ownerAddressCity'),
            owner_address_state: this.get('ownerAddressState'),
            owner_address_zip: this.get('ownerAddressZipcode'),
            free_trial_ends: this.get('freeTrialEnds'),
            hubspot_id: this.get('hubspotId')
          }
        }).done(function () {
          _this2.set('createDisabled', false);

          _this2.get('flashMessage').sendFlashMessage('Congrats on the new Company!!!!', 'Success');

          window.location.reload();
        }).fail(function (data) {
          if (data && data.responseText) {
            var parsed = JSON.parse(data.responseText);

            if (parsed.message) {
              _this2.get('flashMessage').sendFlashMessage(parsed.message, 'user-error');

              _this2.set('createDisabled', false);
            } else {
              _this2.get('flashMessage').sendFlashMessage('Unexpected error adding company', 'error');
            }
          } else {
            _this2.get('flashMessage').sendFlashMessage('Unexpected error adding company', 'error');
          }
        });
      }
    }
  });

  _exports.default = _default;
});