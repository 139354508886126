define("frontend/templates/components/no-appointments-selected-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cHGfIltM",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,[[\"onClose\",\"customContainerClassNames\"],[[28,\"action\",[[23,0,[]],\"onClose\"],null],\"no-appointments-selected-modal\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[1,[28,\"inline-svg\",[\"assets/images/bandage.svg\"],null],false],[9],[0,\"\\n      \"],[7,\"header\",true],[8],[0,\"\\n        Oops!\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"layout-row description\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"layout-column flex\"],[8],[0,\"\\n        Please select one or more appointments first before attempting to bulk archive or mark as canceled!\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"text-center stacked-button top-button\"],[8],[0,\"\\n      \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red\",\"Got it!\",[28,\"action\",[[23,0,[]],\"onClose\"],null]]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/no-appointments-selected-modal.hbs"
    }
  });

  _exports.default = _default;
});