define("frontend/templates/components/no-clients-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "G9Htmq8Z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[8],[0,\"You haven’t added any clients yet!\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n  To add a client, you’ll need to have ready some basic info on the humans, the pets, as well as how you want to bill them, and you’re off!\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"girl-dog\"],[8],[9],[0,\"\\n\"],[4,\"add-button\",null,[[\"route\"],[\"add-client.client-search\"]],{\"statements\":[[0,\"  Add new client\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/no-clients-error.hbs"
    }
  });

  _exports.default = _default;
});