define("frontend/templates/components/removable-readonly-chips", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fKaWlOSM",
    "block": "{\"symbols\":[\"chip\",\"&default\"],\"statements\":[[4,\"paper-chips\",null,[[\"content\",\"readOnly\",\"removeItem\",\"placeholder\"],[[24,[\"content\"]],true,[28,\"action\",[[23,0,[]],[24,[\"removeItem\"]]],null],[24,[\"placeholder\"]]]],{\"statements\":[[0,\"  \"],[14,2,[[23,1,[]]]],[0,\"\\n  \"],[7,\"button\",true],[10,\"class\",\"md-chip-remove\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"removeItem\",[23,1,[]]],null]],null]],[10,\"aria-hidden\",\"true\"],[10,\"tabindex\",\"-1\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[10,\"type\",\"button\"],[8],[0,\"\\n    \"],[1,[28,\"paper-icon\",null,[[\"icon\",\"size\"],[\"clear\",18]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/removable-readonly-chips.hbs"
    }
  });

  _exports.default = _default;
});