define("frontend/templates/components/flash-message-private-browsing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "BgpC6qLq",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"flash-message\"],[10,\"class\",\"alert fade in error private\"],[10,\"role\",\"alert\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"message-container\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"message\"],[8],[0,\"Please exit private browser and try again.\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/flash-message-private-browsing.hbs"
    }
  });

  _exports.default = _default;
});