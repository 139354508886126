define("frontend/routes/add-client/secondary-contact", ["exports", "frontend/routes/base-route", "frontend/mixins/cleanup-on-transition", "frontend/utils/email-type"], function (_exports, _baseRoute, _cleanupOnTransition, _emailType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = _baseRoute.default.extend(_cleanupOnTransition.default, {
    addClientSaving: inject.service(),
    flashMessage: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    model: function model(params) {
      var _this = this;

      var household_id = params.household_id;
      return this.store.findRecord('household', household_id).then(function (household) {
        var secondaryContact = household.get('secondaryContact');

        if (secondaryContact) {
          if (secondaryContact.get('addresses.length') === 0) {
            secondaryContact.get('addresses').pushObject(_this.buildAddress(household));
          }

          return secondaryContact;
        } else {
          var user = _this.store.createRecord('user');

          var householdUser = _this.store.createRecord('household-user', {
            household: household,
            user: user
          });

          var defaultEmail = _this.store.createRecord('email', {
            email_type: _emailType.default.PREFERRED
          });

          var defaultPhone = _this.store.createRecord('phone-number');

          householdUser.get('phone_numbers').pushObject(defaultPhone);
          householdUser.get('emails').pushObject(defaultEmail);
          householdUser.get('addresses').pushObject(_this.buildAddress(household));
          return householdUser;
        }
      });
    },
    buildAddress: function buildAddress(household) {
      var address = household.get('owner.addresses.firstObject');

      if (address) {
        var _this$store$createRec;

        return this.store.createRecord('address', (_this$store$createRec = {
          street: address.get('street'),
          city: address.get('city'),
          state: address.get('state'),
          country: address.get('country'),
          zipcode: address.get('zipcode')
        }, _defineProperty(_this$store$createRec, "country", address.get('country')), _defineProperty(_this$store$createRec, "address_type", address.get('address_type')), _defineProperty(_this$store$createRec, "notes", address.get('notes')), _this$store$createRec));
      } else {
        var user = this.get('currentUser.user');
        var state = user.get('company.state') || user.get('client_accounts.firstObject.company_state');
        var country = user.get('company.country') || user.get('client_accounts.firstObject.country');
        return this.store.createRecord('address', {
          state: state,
          country: country
        });
      }
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('anySidePanelActive', false);
      var newUser = model.get('isNew');
      controller.set('showSecondaryContactPrompt', newUser);
    },
    actions: {
      goBack: function goBack(model) {
        var householdId = model.get('household.id');
        this.transitionTo('add-client.primary-contact', {
          queryParams: {
            householdId: householdId
          }
        });
      },
      goNext: function goNext(user) {
        var _this2 = this;

        this.set('saving', true);
        var ownerEmails = user.get('household.owner.emails');
        var emails = user.get('emails');
        var duplicateEmail = false;
        emails.forEach(function (email) {
          ownerEmails.forEach(function (ownerEmail) {
            if (email.get('address').toLowerCase() == ownerEmail.get('address').toLowerCase()) {
              duplicateEmail = true;

              _this2.get('flashMessage').sendFlashMessage("Sorry, humans can't share emails! Please remove ".concat(email.get('address'), " from ").concat(user.get('household.owner.getFirstName'), "'s profile and save before adding a new human with this email."), 'user-error');

              _this2.set('saving', false);

              return;
            }
          });
        });

        if (duplicateEmail) {
          return;
        }

        this.get('addClientSaving').saveUser(user).then(function (savedUser) {
          return _this2.transitionTo('add-client.pet', savedUser.get('household.id'));
        }).finally(function () {
          _this2.set('saving', false);
        });
      }
    }
  });

  _exports.default = _default;
});