define("frontend/components/invoice-receipt/invoice-summary", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['invoice-summary'],
    currentUser: Ember.inject.service(),
    wasClosedAndHasSalesTax: null,
    servicesTotals: null,
    manualAdjustments: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return !adjustment.get('surcharge_policy_type') && !adjustment.get('is_cancellation_fee') && !adjustment.get('is_sales_tax');
      });
    }),
    offHoursSurcharges: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return adjustment.get('isOffHoursSurcharge');
      });
    }),
    offHoursSurchargesTotal: computed('offHoursSurcharges.[]', function () {
      return this.get('offHoursSurcharges').reduce(function (a, b) {
        return a + parseFloat(b.get('amount'));
      }, 0);
    }),
    offHoursSurchargesTooltip: computed('offHoursSurcharges.[]', function () {
      var groupedSurcharges = this.groupSurcharges(this.get('offHoursSurcharges'));
      var text = "".concat(this.get('offHoursSurcharges.firstObject.surcharge_policy_description'), " applied to:\n\n");
      groupedSurcharges.forEach(function (surcharge, index) {
        if (index > 0) {
          text += '\n';
        }

        text += "".concat(surcharge.get('label'));
      });
      return text;
    }),
    weekendSurcharges: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return adjustment.get('isWeekendSurcharge');
      });
    }),
    weekendSurchargesTotal: computed('weekendSurcharges.[]', function () {
      return this.get('weekendSurcharges').reduce(function (a, b) {
        return a + parseFloat(b.get('amount'));
      }, 0);
    }),
    weekendSurchargesTooltip: computed('weekendSurcharges.[]', function () {
      var groupedSurcharges = this.groupSurcharges(this.get('weekendSurcharges'));
      var text = "".concat(this.get('weekendSurcharges.firstObject.surcharge_policy_description'), " applied to:\n\n");
      groupedSurcharges.forEach(function (surcharge, index) {
        if (index > 0) {
          text += '\n';
        }

        text += "".concat(surcharge.get('label'));
      });
      return text;
    }),
    holidaySurcharges: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return adjustment.get('isHolidaySurcharge');
      });
    }),
    holidaySurchargesTotal: computed('holidaySurcharges.[]', function () {
      return this.get('holidaySurcharges').reduce(function (a, b) {
        return a + parseFloat(b.get('amount'));
      }, 0);
    }),
    holidaySurchargesTooltip: computed('holidaySurcharges.[]', function () {
      var groupedSurcharges = this.groupSurcharges(this.get('holidaySurcharges'));
      var text = "".concat(this.get('holidaySurcharges.firstObject.surcharge_policy_description'), " applied to:\n\n");
      groupedSurcharges.forEach(function (surcharge, index) {
        if (index > 0) {
          text += '\n';
        }

        text += "".concat(surcharge.get('label'));
      });
      return text;
    }),
    lateBookingSurcharges: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return adjustment.get('isLateBookingSurcharge');
      });
    }),
    lateBookingSurchargesTotal: computed('lateBookingSurcharges.[]', function () {
      return this.get('lateBookingSurcharges').reduce(function (a, b) {
        return a + parseFloat(b.get('amount'));
      }, 0);
    }),
    lateBookingSurchargesTooltip: computed('lateBookingSurcharges.[]', function () {
      var groupedSurcharges = this.groupSurcharges(this.get('lateBookingSurcharges'));
      var text = "".concat(this.get('lateBookingSurcharges.firstObject.surcharge_policy_description'), " applied to:\n\n");
      groupedSurcharges.forEach(function (surcharge, index) {
        if (index > 0) {
          text += '\n';
        }

        text += "".concat(surcharge.get('label'));
      });
      return text;
    }),
    cancellationFees: computed('adjustments.[]', function () {
      return this.get('adjustments').filter(function (adjustment) {
        return adjustment.get('is_cancellation_fee');
      });
    }),
    cancellationFeesTotal: computed('cancellationFees.[]', function () {
      return this.get('cancellationFees').reduce(function (a, b) {
        return a + parseFloat(b.get('amount'));
      }, 0);
    }),
    cancellationFeesTooltip: computed('cancellationFees.[]', function () {
      var groupedSurcharges = this.groupSurcharges(this.get('cancellationFees'));
      var text = "".concat(this.get('cancellationFees.firstObject.surcharge_policy_description'), " applied to:\n\n");
      groupedSurcharges.forEach(function (surcharge, index) {
        if (index > 0) {
          text += '\n';
        }

        text += "".concat(surcharge.get('label'));
      });
      return text;
    }),
    groupSurcharges: function groupSurcharges(surcharges) {
      var groupedSurcharges = {};
      surcharges.forEach(function (surcharge) {
        var key = "".concat(surcharge.get('appointment_date'), " ").concat(surcharge.get('offered_service_name'));

        if (!groupedSurcharges[key]) {
          groupedSurcharges[key] = Ember.Object.create({
            date: surcharge.get('appointment_date'),
            categoryName: surcharge.get('appointment_category_name'),
            label: key,
            amount: 0,
            adjustments: []
          });
        }

        groupedSurcharges[key].amount += parseFloat(surcharge.get('amount'));
        groupedSurcharges[key].adjustments.push(surcharge);
      });
      return Object.values(groupedSurcharges).sortBy('date');
    }
  });

  _exports.default = _default;
});