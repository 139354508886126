define("frontend/templates/components/invoices-widget/invoices-group-sent-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YumXV2ut",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"isSent\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"invoices-widget/progress-bar\",null,[[\"value\",\"maxValue\"],[[24,[\"invoicesGroup\",\"amount_paid\"]],[24,[\"invoicesGroup\",\"amount\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[8],[0,\"Sent on \"],[1,[28,\"format-date\",[[24,[\"sendDate\"]],\"MMMM D, YYYY\"],null],false],[9],[0,\"\\n\"],[1,[28,\"collapse-button\",null,[[\"collapsed\",\"click\"],[[24,[\"collapsed\"]],[28,\"action\",[[23,0,[]],\"toggleCollapsed\"],null]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"total-amount\"],[8],[0,\"\\n  \"],[1,[28,\"format-money\",[[24,[\"invoicesGroup\",\"amount\"]]],null],false],[0,\" \"],[7,\"span\",true],[10,\"class\",\"regular-text\"],[8],[4,\"if\",[[24,[\"isPaid\"]]],null,{\"statements\":[[0,\"paid\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"isSent\"]]],null,{\"statements\":[[0,\"billed\"]],\"parameters\":[]},{\"statements\":[[0,\"outstanding\"]],\"parameters\":[]}]],\"parameters\":[]}],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/invoices-widget/invoices-group-sent-header.hbs"
    }
  });

  _exports.default = _default;
});