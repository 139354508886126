define("frontend/models/company-user-profile", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    household_user: _emberData.default.belongsTo('household-user', {
      async: true
    }),
    company_id: _emberData.default.attr('number'),
    email_for_appointment_reminder: _emberData.default.attr('boolean'),
    email_on_appointment_started: _emberData.default.attr('boolean'),
    email_on_appointment_stopped: _emberData.default.attr('boolean'),
    email_on_direct_message_send: _emberData.default.attr('boolean'),
    email_on_group_announcement_send: _emberData.default.attr('boolean'),
    email_on_report_card_send: _emberData.default.attr('boolean'),
    email_on_schedule_change: _emberData.default.attr('boolean'),
    service_categories_with_check_in_check_out: _emberData.default.attr()
  });

  _exports.default = _default;
});