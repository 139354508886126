define("frontend/templates/components/view-grouped-report-card/notes", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lVBBe4gJ",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"notes\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"view-report-card--notes\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"view-report-card--notes-content\"],[8],[0,\"\\n      \"],[1,[22,\"notes\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/view-grouped-report-card/notes.hbs"
    }
  });

  _exports.default = _default;
});