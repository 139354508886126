define("frontend/templates/sign-up/success", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SedHUeQ6",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"sign-up-success-page\"],[8],[0,\"\\n  \"],[1,[28,\"inline-svg\",[\"assets/images/fireworks.svg\"],null],false],[0,\"\\n  \"],[7,\"header\",true],[8],[0,\"Hooray!\"],[9],[0,\"\\n  \"],[7,\"p\",true],[8],[0,\"Thanks for your interest in PetPocketbook!\"],[7,\"br\",true],[8],[9],[0,\" Someone will be in touch shortly via email to discuss next steps. We look forward to making life easier for you on the business side of things, when you join our pack!\"],[9],[0,\"\\n  \"],[7,\"img\",true],[10,\"class\",\"pack-img\"],[10,\"src\",\"assets/images/pack@3x.png\"],[10,\"srcset\",\"assets/images/pack.png 191w, assets/images/pack@2x.png 382w, assets/images/pack@3x.png 573w\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"sign-up-success-bottom\"],[8],[0,\"\\n    \"],[4,\"ppb-button\",null,[[\"style\",\"classNames\",\"click\"],[\"red\",\"back-main\",[28,\"action\",[[23,0,[]],\"transitionHome\"],null]]],{\"statements\":[[0,\"Back to main\"]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/sign-up/success.hbs"
    }
  });

  _exports.default = _default;
});