define("frontend/components/staff-header", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var CUSTOM = 'custom';
  var computed = Ember.computed,
      service = Ember.inject.service;

  var _default = Ember.Component.extend({
    classNames: ['staff-header'],
    payFrequency: null,
    payPeriod: null,
    timeZone: service(),
    customStartDate: null,
    customEndDate: null,
    hasCustomDate: computed.notEmpty('customStartDate'),
    setInitialPayPeriod: false,
    selectedPayPeriod: computed('payFrequencyOptions.firstObject.value', function () {
      return this.get('payFrequencyOptions.firstObject.value');
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments); // Make sure we don't set the initial pay period until we've gotten the
      // company's pay frequency otherwise it will set it to the last week


      if (!this.get('setInitialPayPeriod') && this.get('payFrequency') && this.get('payFrequencyOptions').length > 0) {
        this.send('changePayPeriod', this.get('selectedPayPeriod'));
        this.set('setInitialPayPeriod', true);
      }
    },
    payFrequencyWeeks: function payFrequencyWeeks(currentMoment) {
      var startWeek = currentMoment.clone().startOf('isoweek');
      var endWeek = currentMoment.clone().endOf('isoweek');
      var twoWeeksAgo = startWeek.clone().subtract('week', 2);
      var options = [];

      while (startWeek.diff(twoWeeksAgo, 'weeks') > 0) {
        var startFormat = startWeek.format('M/D');
        var endFormat = endWeek.format('M/D');
        var value = "".concat(startWeek.format('YYYY-MM-DD'), ".").concat(endWeek.format('YYYY-MM-DD'));

        if (startWeek.diff(twoWeeksAgo, 'weeks') === 2) {
          options.push({
            label: "Current pay period ".concat(startFormat, " - ").concat(endFormat),
            value: value
          });
        } else if (startWeek.diff(twoWeeksAgo, 'weeks') === 1) {
          options.push({
            label: "Previous pay period ".concat(startFormat, " - ").concat(endFormat),
            value: value
          });
        }

        startWeek.subtract(1, 'week');
        endWeek.subtract(1, 'week');
      }

      return options;
    },
    payFrequencyBiWeekly: function payFrequencyBiWeekly(currentMoment, payPeriod) {
      var startWeek = currentMoment.clone().startOf('isoweek');
      var endWeek = currentMoment.clone().add(1, 'week').endOf('isoweek');
      var numWeeksPast = currentMoment.clone().startOf('isoweek').diff((0, _moment.default)(payPeriod), 'weeks'); // If the current week is not the end of the pay period

      if (numWeeksPast % 2 != 0) {
        startWeek = currentMoment.clone().subtract(1, 'week').startOf('isoweek');
        endWeek = currentMoment.clone().endOf('isoweek');
      } else {
        startWeek = currentMoment.clone().startOf('isoweek');
        endWeek = currentMoment.clone().add(1, 'week').endOf('isoweek');
      }

      var fourWeeksAgo = startWeek.clone().subtract(4, 'weeks');
      var options = [];

      while (startWeek.diff(fourWeeksAgo, 'weeks') > 0) {
        var startFormat = startWeek.format('M/D');
        var endFormat = endWeek.format('M/D');
        var value = "".concat(startWeek.format('YYYY-MM-DD'), ".").concat(endWeek.format('YYYY-MM-DD'));

        if (startWeek.diff(fourWeeksAgo, 'weeks') === 4) {
          options.push({
            label: "Current pay period ".concat(startFormat, " - ").concat(endFormat),
            value: value
          });
        } else if (startWeek.diff(fourWeeksAgo, 'weeks') === 2) {
          options.push({
            label: "Previous pay period ".concat(startFormat, " - ").concat(endFormat),
            value: value
          });
        }

        startWeek.subtract(2, 'week');
        endWeek.subtract(2, 'week');
      }

      return options;
    },
    payFrequencySemiMonthly: function payFrequencySemiMonthly(currentMoment) {
      var startMonth = currentMoment.clone().startOf('month');
      var midMonth = currentMoment.clone().date(15);
      var endMonth = currentMoment.clone().endOf('month');
      var options = [];

      if (currentMoment.date() <= 14) {
        midMonth.subtract(1, 'day');
        var value = "".concat(startMonth.format('YYYY-MM-DD'), ".").concat(midMonth.format('YYYY-MM-DD'));
        options.push({
          label: "Current pay period ".concat(startMonth.format('M/D'), " - ").concat(midMonth.format('M/D')),
          value: value
        });
        var pastMidMonth = currentMoment.clone().subtract(1, 'month').date(15);
        var pastEndMonth = currentMoment.clone().subtract(1, 'month').endOf('month');
        value = "".concat(pastMidMonth.format('YYYY-MM-DD'), ".").concat(pastEndMonth.format('YYYY-MM-DD'));
        options.push({
          label: "Previous pay period ".concat(pastMidMonth.format('M/D'), " - ").concat(pastEndMonth.format('M/D')),
          value: value
        });
      } else {
        var _value = "".concat(midMonth.format('YYYY-MM-DD'), ".").concat(endMonth.format('YYYY-MM-DD'));

        options.push({
          label: "Current pay period ".concat(midMonth.format('M/D'), " - ").concat(endMonth.format('M/D')),
          value: _value
        });
        midMonth.subtract(1, 'day');
        _value = "".concat(startMonth.format('YYYY-MM-DD'), ".").concat(midMonth.format('YYYY-MM-DD'));
        options.push({
          label: "Previous pay period ".concat(startMonth.format('M/D'), " - ").concat(midMonth.format('M/D')),
          value: _value
        });
      }

      return options;
    },
    payFrequencyMonthly: function payFrequencyMonthly(currentMoment) {
      var startMonth = currentMoment.clone().startOf('month');
      var endMonth = currentMoment.clone().endOf('month');
      var oneMonthAgoStart = startMonth.clone().subtract('month', 1);
      var oneMonthAgoEnd = startMonth.clone().subtract('month', 1).endOf('month');
      var options = [];
      var value = "".concat(startMonth.format('YYYY-MM-DD'), ".").concat(endMonth.format('YYYY-MM-DD'));
      options.push({
        label: "Current pay period ".concat(startMonth.format('M/D'), " - ").concat(endMonth.format('M/D')),
        value: value
      });
      value = "".concat(oneMonthAgoStart.format('YYYY-MM-DD'), ".").concat(oneMonthAgoEnd.format('YYYY-MM-DD'));
      options.push({
        label: "Previous pay period ".concat(oneMonthAgoStart.format('M/D'), " - ").concat(oneMonthAgoEnd.format('M/D')),
        value: value
      });
      return options;
    },
    customLabel: computed('customStartDate', 'customEndDate', function () {
      var momentStart = (0, _moment.default)(this.get('customStartDate'), 'YYYY-MM-DD');
      var momentEnd = (0, _moment.default)(this.get('customEndDate'), 'YYYY-MM-DD');

      if (this.get('customStartDate')) {
        return "Custom (".concat(momentStart.format('M/D'), " - ").concat(momentEnd.format('M/D'), ")");
      }

      return 'Custom';
    }),
    payFrequencyOptions: computed('payFrequency', 'payPeriod', 'customLabel', function () {
      var currentMoment = this.get('timeZone.companyMoment');
      var payFrequency = this.get('payFrequency');
      var payPeriod = this.get('payPeriod');
      var periods;

      switch (payFrequency) {
        case 'semi_monthly':
          periods = this.payFrequencySemiMonthly(currentMoment);
          break;

        case 'bi_weekly':
          periods = this.payFrequencyBiWeekly(currentMoment, payPeriod);
          break;

        case 'monthly':
          periods = this.payFrequencyMonthly(currentMoment);
          break;

        default:
          periods = this.payFrequencyWeeks(currentMoment);
      }

      var start = currentMoment.clone().subtract(4, 'weeks').startOf('day');
      var value = "".concat(start.format('YYYY-MM-DD'), ".").concat(currentMoment.endOf('day').format('YYYY-MM-DD'));
      periods.push({
        label: 'Last 4 weeks',
        value: value
      });
      start = currentMoment.clone().subtract(3, 'months');
      value = "".concat(start.format('YYYY-MM-DD'), ".").concat(currentMoment.endOf('day').format('YYYY-MM-DD'));
      periods.push({
        label: 'Last 3 months',
        value: value
      });
      value = CUSTOM;
      var customLabel = this.get('customLabel');
      periods.push({
        label: customLabel,
        value: value
      });
      return periods;
    }),
    exportButtonClassNames: computed('showSearch', function () {
      return this.get('showSearch') ? 'export-button' : 'export-button without-search';
    }),
    actions: {
      addEmployee: function addEmployee() {
        this.addEmployee();
      },
      showCustomDateRangeModal: function showCustomDateRangeModal() {
        this.set('customStartDateCopy', this.get('customStartDate'));
        this.set('customEndDateCopy', this.get('customEndDate'));
        this.set('showingCustomDateRangeModal', true);
      },
      submitCustomDateRange: function submitCustomDateRange(startDate, endDate) {
        var momentStart = (0, _moment.default)(startDate, 'YYYY-MM-DD');
        var momentEnd = (0, _moment.default)(endDate, 'YYYY-MM-DD');
        this.set('customStartDate', startDate);
        this.set('customEndDate', endDate);
        this.changePayPeriod(momentStart.format('YYYY-MM-DD'), momentEnd.format('YYYY-MM-DD'));
        this.set('showingCustomDateRangeModal', false);
        this.set('previousPayPeriod', 'custom');
      },
      closeCustomDateRangeModal: function closeCustomDateRangeModal() {
        this.set('showingCustomDateRangeModal', false);
        this.set('selectedPayPeriod', this.get('previousPayPeriod'));
      },
      exportData: function exportData() {
        this.get('exportData')();
      },
      changePayPeriod: function changePayPeriod(value, previousValue) {
        if (value === CUSTOM) {
          this.set('previousPayPeriod', previousValue);
          this.set('showingCustomDateRangeModal', true);
        } else {
          var _value$split = value.split('.'),
              _value$split2 = _slicedToArray(_value$split, 2),
              startDate = _value$split2[0],
              endDate = _value$split2[1];

          this.set('customStartDate', null);
          this.set('customEndDate', null);
          this.changePayPeriod(startDate, endDate);
        }
      }
    }
  });

  _exports.default = _default;
});