define("frontend/adapters/care-provider", ["exports", "frontend/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    namespace: 'api/v2'
  });

  _exports.default = _default;
});