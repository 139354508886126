define("frontend/templates/components/invoices-widget/invoice-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m/dfS2LK",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"invoice-item-header\",null,[[\"invoice\",\"invoiceAmount\"],[[24,[\"invoice\"]],[24,[\"invoice\",\"amount\"]]]]],false],[0,\"\\n\"],[1,[28,\"household-select/household-select-with-account-balance-option\",null,[[\"household\",\"petIds\"],[[24,[\"invoice\",\"household\"]],[24,[\"invoice\",\"pet_ids\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/invoices-widget/invoice-item.hbs"
    }
  });

  _exports.default = _default;
});