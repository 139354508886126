define("frontend/components/side-panel-callout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['side-panel-callout'],
    classNameBindings: ['active', 'shiftLeft'],
    active: false
  });

  _exports.default = _default;
});