define("frontend/templates/components/view-grouped-report-card/care-provider-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kmh06G0R",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\",true],[11,\"src\",[29,[[24,[\"firstAppointment\",\"care_provider\",\"avatarOrPlaceholder\"]]]]],[10,\"class\",\"view-report-card--care-provider-avatar\"],[8],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"view-report-card--title\"],[8],[0,\"\\n  \"],[1,[24,[\"firstAppointment\",\"care_provider\",\"firstNameWithLastInitial\"]],false],[0,\" completed the visit!\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"view-report-card--date\"],[8],[0,\"\\n  \"],[1,[22,\"formattedDate\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/view-grouped-report-card/care-provider-details.hbs"
    }
  });

  _exports.default = _default;
});