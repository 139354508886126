define("frontend/templates/components/view-grouped-report-card/basic-checklist", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rmjuhFOV",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"multipet\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"header-with-name\"],[8],[0,\"\\n    \"],[1,[24,[\"checklist\",\"pet\",\"name\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"icon-row\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"checklist\",\"peed\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"view-report-card--basic-checklist-peed\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"src\",\"assets/images/report-card/peed.png\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"checklist\",\"pooped\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"view-report-card--basic-checklist-pooped\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"src\",\"assets/images/report-card/pooped.png\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"checklist\",\"providedWater\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"view-report-card--basic-checklist-provided-water\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"src\",\"assets/images/report-card/provided-water.png\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"checklist\",\"fedTreat\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"view-report-card--basic-checklist-fed-treat\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"src\",\"assets/images/report-card/fed-treat.png\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/view-grouped-report-card/basic-checklist.hbs"
    }
  });

  _exports.default = _default;
});