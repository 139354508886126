define("frontend/mixins/appointment-request", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Mixin.create({
    appointmentRequestData: inject.service(),
    household: computed.alias('appointmentRequestData.household'),
    company_id: computed.alias('appointmentRequestData.company_id'),
    selectedPets: computed.alias('appointmentRequestData.selectedPets'),
    offeredService: computed.alias('appointmentRequestData.offeredService'),
    visitsPerDay: computed.alias('appointmentRequestData.visitsPerDay'),
    dates: computed.alias('appointmentRequestData.dates'),
    appointments: computed.alias('appointmentRequestData.appointments'),
    modifyingAppointments: computed.alias('appointmentRequestData.modifyingAppointments'),
    modifyingAppointmentRequest: computed.alias('appointmentRequestData.modifyingAppointmentRequest'),
    specialInstructions: computed.alias('appointmentRequestData.specialInstructions'),
    redirectedFromMessage: computed.alias('appointmentRequestData.redirectedFromMessage')
  });

  _exports.default = _default;
});