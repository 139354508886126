define("frontend/templates/components/invoice-item-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UUSrmyle",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"invoice-tag\",null,[[\"invoice\"],[[24,[\"invoice\"]]]]],false],[0,\"\\n\"],[1,[28,\"invoice-item-header/invoice-date-number\",null,[[\"invoice\"],[[24,[\"invoice\"]]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"uncomplete-appointments-warning\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"invoice\",\"has_uncompleted_appointments\"]]],null,{\"statements\":[[4,\"tooltip-question-mark\",null,[[\"tooltipText\"],[\"There are past appointments on this draft that have not yet been marked complete!\"]],{\"statements\":[[0,\"      \"],[1,[28,\"inline-svg\",[\"assets/images/yellow-warning.svg\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[9],[0,\"\\n\"],[7,\"div\",true],[11,\"class\",[29,[\"invoice-amount \",[28,\"if\",[[24,[\"invoice\",\"isVoided\"]],\"grayed-out\"],null]]]],[8],[0,\"\\n  \"],[1,[28,\"format-money\",[[24,[\"invoiceAmount\"]]],[[\"precision\"],[2]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/invoice-item-header.hbs"
    }
  });

  _exports.default = _default;
});