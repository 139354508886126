define("frontend/components/adaptive-side-panel-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    borderTop: false,
    borderBottom: true,
    actions: {
      selectOption: function selectOption() {
        if (this.get('selectOption')) {
          this.get('selectOption').apply(void 0, arguments);
        }
      }
    }
  });

  _exports.default = _default;
});