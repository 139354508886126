define("frontend/templates/components/payment-method-modal/add-credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "gUvACPfy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"content centered\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[1,[28,\"inline-svg\",[\"assets/images/card.svg\"],null],false],[9],[0,\"\\n    \"],[7,\"header\",true],[8],[0,\"Add card\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"payment-form/stripe-card\",null,[[\"setCard\"],[[28,\"action\",[[23,0,[]],\"setCard\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"bottom\"],[8],[0,\"\\n  \"],[1,[28,\"ppb-button\",null,[[\"disabled\",\"style\",\"text\",\"click\"],[[24,[\"saving\"]],\"red-white\",\"cancel\",[28,\"action\",[[23,0,[]],[24,[\"onClose\"]]],null]]]],false],[0,\"\\n  \"],[1,[28,\"ppb-button\",null,[[\"disabled\",\"style\",\"text\",\"click\"],[[24,[\"saving\"]],\"red\",\"add\",[28,\"action\",[[23,0,[]],\"saveCard\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/payment-method-modal/add-credit-card.hbs"
    }
  });

  _exports.default = _default;
});