define("frontend/templates/components/flash-message-new-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rCUZ9fLt",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"flash-notification\",null,[[\"color\",\"showOverlay\",\"dismissAfter\",\"dismiss\"],[[24,[\"color\"]],[24,[\"showOverlay\"]],[24,[\"dismissAfter\"]],[28,\"action\",[[23,0,[]],\"no\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"flash-notification__message\"],[8],[0,\"\\n    \"],[1,[28,\"inline-svg\",[\"assets/images/heart-icon.svg\"],null],false],[0,\"\\n    \"],[14,1],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"button-container new-message-buttons\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"        \"],[4,\"ppb-button\",null,[[\"color\",\"classNames\",\"onclick\"],[\"pink\",\"top-button\",[28,\"action\",[[23,0,[]],\"no\"],null]]],{\"statements\":[[1,[22,\"noText\"],false]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"color\",\"classNames\",\"onclick\"],[\"red\",\"bottom-button\",[28,\"action\",[[23,0,[]],\"yes\"],null]]],{\"statements\":[[0,\"          \"],[1,[22,\"yesText\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"        \"],[4,\"ppb-button\",null,[[\"color\",\"onclick\"],[\"red-white-border\",[28,\"action\",[[23,0,[]],\"yes\"],null]]],{\"statements\":[[1,[22,\"yesText\"],false]],\"parameters\":[]},null],[0,\"\\n        \"],[4,\"ppb-button\",null,[[\"color\",\"onclick\"],[\"gray\",[28,\"action\",[[23,0,[]],\"no\"],null]]],{\"statements\":[[1,[22,\"noText\"],false]],\"parameters\":[]},null],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/flash-message-new-message.hbs"
    }
  });

  _exports.default = _default;
});