define("frontend/components/mobile-side-panel-callout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mobile-side-panel-callout'],
    label: null
  });

  _exports.default = _default;
});