define("frontend/controllers/companies/remove-staff", ["exports", "ember-data", "frontend/config/environment", "jquery"], function (_exports, _emberData, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    companies: inject.controller(),
    store: inject.service(),
    router: inject.service(),
    flashMessage: inject.service(),
    saving: computed.alias('companies.saving'),
    currentRouteName: Ember.computed.readOnly('router.currentRouteName'),
    showReactivateContainer: true,
    employee: computed('model.employee_id', function () {
      var id = this.get('model.employee_id');
      var promise = this.get('store').find('employee', id);
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    employeeName: computed('model.employee_user', function () {
      var employee = this.get('model.employee_user');
      return "".concat(employee.first_name, " ").concat(employee.last_name);
    }),
    employeeAvatar: computed('model.employee_user.avatar.thumb.url', function () {
      var avatarUrl = this.get('model.employee_user.avatar.thumb.url');
      return avatarUrl ? avatarUrl : 'assets/images/staff/placeholder-human-image@3x.png';
    }),
    employeeOptions: computed('model.employees', function () {
      var _this = this;

      var employees = this.get('model.employees');
      employees = employees.filter(function (employee) {
        return employee.id != _this.get('model.employee_id');
      });
      return $.map(employees, function (employee) {
        return {
          label: employee.name,
          value: employee.id
        };
      });
    }),
    hasAppointments: computed('model.grouped_appointments', 'model.recurring_appointments', function () {
      var appts = this.get('model.grouped_appointments');
      var rec_appts = this.get('model.recurring_appointments');
      return appts.length > 0 || rec_appts.length > 0;
    }),
    actions: {
      transitionToStaff: function transitionToStaff() {
        this.transitionToRoute('staff').then(function () {
          window.location.reload();
        });
      },
      transitionToSingleEdit: function transitionToSingleEdit(id) {
        var queryParams = {
          backRoute: this.get('currentRouteName'),
          backHouseholdId: this.get('model.company_id'),
          backId: this.get('model.employee_id'),
          ids: [id]
        };
        this.transitionToRoute('edit-single-appointment.index', {
          queryParams: queryParams
        });
      },
      transitionToRecurringEdit: function transitionToRecurringEdit(id) {
        var queryParams = {
          backRoute: this.get('currentRouteName'),
          backHouseholdId: this.get('model.company_id'),
          backId: this.get('model.employee_id')
        };
        this.transitionToRoute('edit-recurring-appointment-group.index', id, {
          queryParams: queryParams
        });
      },
      remove: function remove() {
        var _this2 = this;

        this.set('saving', true);
        this.get('employee').get('content').destroyRecord().then(function () {
          return _this2.transitionToRoute('staff').then(function () {
            _this2.set('saving', false);

            _this2.get('flashMessage').sendFlashMessage("You\u2019ve successfully removed ".concat(_this2.get('employeeName'), " as a member of your team"), 'success-love');

            window.location.reload();
          });
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('staff');
      },
      clickDeactivate: function clickDeactivate() {
        this.set('showDeactivateFlash', true);
      },
      hideDeactivate: function hideDeactivate() {
        this.set('showDeactivateFlash', false);
      },
      clickReactivate: function clickReactivate() {
        this.set('showReactivateFlash', true);
      },
      hideReactivate: function hideReactivate() {
        this.set('showReactivateFlash', false);
      },
      closeReactivateContainer: function closeReactivateContainer() {
        this.set('showReactivateContainer', false);
      },
      confirmReactivate: function confirmReactivate() {
        var _this3 = this;

        this.set('showReactivateFlash', false);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/employees/").concat(this.get('model.employee_id'), "/reactivate"),
          method: 'POST'
        }).done(function () {
          _this3.get('flashMessage').sendFlashMessage("You\u2019ve successfully reactivated ".concat(_this3.get('employeeName'), "'s account"), 'success-love');

          _this3.set('model.employee_deactivated', false);
        });
      },
      confirmDeactivate: function confirmDeactivate() {
        var _this4 = this;

        this.set('showDeactivateFlash', false);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/employees/").concat(this.get('model.employee_id'), "/deactivate"),
          method: 'POST'
        }).done(function () {
          _this4.get('flashMessage').sendFlashMessage("You\u2019ve successfully deactivated ".concat(_this4.get('employeeName'), "'s account"), 'success-love');

          _this4.set('model.employee_deactivated', true);
        });
      }
    }
  });

  _exports.default = _default;
});