define("frontend/models/care_provider", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var USER_PLACEHOLDER = 'assets/images/staff/placeholder-human-image@3x.png';
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend({
    first_name: _emberData.default.attr('string'),
    preferred_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    avatar_url: _emberData.default.attr('string'),
    calendar_order: _emberData.default.attr('number'),
    avatarOrPlaceholder: computed('avatar_url', function () {
      return this.get('avatar_url') || USER_PLACEHOLDER;
    }),
    getFirstName: computed('first_name', 'preferred_name', function () {
      return Ember.isBlank(this.get('preferred_name')) ? this.get('first_name') : this.get('preferred_name');
    }),
    fullName: computed('getFirstName', 'last_name', function () {
      return "".concat(this.get('getFirstName'), " ").concat(this.get('last_name'));
    }),
    firstNameWithLastInitial: computed('getFirstName', 'last_name', function () {
      var lastName = this.get('last_name') || '';
      return "".concat(this.get('getFirstName'), " ").concat(lastName.charAt(0), ".");
    })
  });

  _exports.default = _default;
});