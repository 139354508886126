define("frontend/utils/appointment-searcher", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    numVisitsInServices: function numVisitsInServices(petNameSearchValue, services) {
      var _this = this;

      var countCanceled = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      return services.reduce(function (allAppointmentsCount, service) {
        var appointmentsCount = _this.numVisitsWithPetNameService(petNameSearchValue, service, countCanceled);

        return allAppointmentsCount += appointmentsCount;
      }, 0);
    },
    numVisitsWithPetNameService: function numVisitsWithPetNameService(petNameSearchValue, service, countCanceled) {
      return service.get('grouped_appointments').reduce(function (appointmentsCount, groupedAppointment) {
        var includesPet = false;
        groupedAppointment.get('appointments').forEach(function (appt) {
          var petName = appt.get('pet.name');
          var petNamePart = petName.slice(0, petNameSearchValue.length);

          if (countCanceled && !appt.get('canceled_at') || !countCanceled && appt.get('canceled_at')) {
            return appointmentsCount;
          }

          if (petNamePart.toLowerCase().indexOf(petNameSearchValue.toLowerCase()) >= 0) {
            includesPet = true;
          }
        });
        return includesPet ? appointmentsCount + 1 : appointmentsCount;
      }, 0);
    },
    numPetsInServices: function numPetsInServices(petNameSearchValue, services) {
      var countCanceled = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
      var apptIds = [];
      services.forEach(function (service) {
        service.get('appointments').forEach(function (appt) {
          if (countCanceled && appt.get('canceled_at') || !countCanceled && !appt.get('canceled_at')) {
            apptIds.push(appt.get('id'));
          }
        });
      });
      return apptIds.length;
    }
  };
  _exports.default = _default;
});