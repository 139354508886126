define("frontend/components/invite-client-modal", ["exports", "frontend/utils/email-type"], function (_exports, _emailType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;
  var EMAIL_REGEX = /.+@.+\..+/;
  var DUPLICATE_EMAIL_ERROR = 'This email already exists!';

  var _default = Ember.Component.extend({
    firstName: null,
    lastName: null,
    email: null,
    duplicateEmail: inject.service(),
    emailErrors: [],
    emailType: _emailType.default.PREFERRED,
    emailValidation: [{
      message: '*must be a valid email',
      validate: function validate(inputValue) {
        return EMAIL_REGEX.test(inputValue) || !inputValue || inputValue.trim() === '';
      }
    }],
    actions: {
      onClose: function onClose() {
        this.get('onClose')();
      },
      onSubmit: function onSubmit() {
        var _this = this;

        this.set('checkingEmail', true);
        var email = this.get('email');
        var firstName = this.get('firstName');
        var lastName = this.get('lastName');
        var emailType = this.get('emailType');
        this.get('duplicateEmail').check(email, null).then(function (response) {
          if (response.duplicate) {
            _this.set('emailErrors', [DUPLICATE_EMAIL_ERROR]);

            _this.set('emailIsTouched', true);
          } else {
            _this.set('emailErrors', []);

            _this.get('onSubmit')(email, firstName, lastName, emailType);
          }
        }).finally(function () {
          _this.set('checkingEmail', false);
        });
      }
    }
  });

  _exports.default = _default;
});