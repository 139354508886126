define("frontend/mixins/add-side-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    adding: false,
    editing: false,
    sidePanelObject: {},
    model: null,
    showAddSidePanel: function showAddSidePanel(sidePanelObject) {
      if (this.get('anySidePanelActive')) {
        return;
      }

      this.set('adding', true);
      this.showSidePanel(sidePanelObject);
    },
    showSidePanel: function showSidePanel(sidePanelObject) {
      var model = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      this.set('sidePanelObject', sidePanelObject);
      this.set('model', model);
      this.activateSidePanel();
    },
    showEditSidePanel: function showEditSidePanel(sidePanelObject, model) {
      if (this.get('anySidePanelActive')) {
        return;
      }

      this.set('editing', true);
      this.showSidePanel(sidePanelObject, model);
    },
    actions: {
      cancel: function cancel() {
        this.send('deactivateSidePanel');
      },
      update: function update(sidePanelObject) {
        var model = this.get('model');
        model.setProperties(sidePanelObject);
        this.send('deactivateSidePanel');
      },
      // eslint-disable-next-line no-unused-vars
      remove: function remove(sidePanelObject) {
        var model = this.get('model');
        model.deleteRecord();
        this.send('deactivateSidePanel');
      },
      deactivateSidePanel: function deactivateSidePanel() {
        this.set('sidePanelActivated', false);
        this.set('adding', false);
        this.set('editing', false);
        this.deactivateSidePanel();
      }
    }
  });

  _exports.default = _default;
});