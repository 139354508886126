define("frontend/components/text-input-callout", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    inputActivated: false,
    classNames: ['text-input-callout'],
    classNameBindings: ['dollar:dollar-callout-input', 'percent:percent-callout-input'],
    tabindex: 0,
    dollar: false,
    percent: false,
    hint: null,
    hover: false,
    hasError: false,
    type: 'text',
    borderTop: true,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _jquery.default)(this.element).hover(function () {
        _this.set('hover', true);
      }, function () {
        _this.set('hover', false);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)(this.element).off('hover');
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      this.send('resetActivated');
    },
    actions: {
      focusIn: function focusIn() {
        this.set('focused', true);
      },
      focusOut: function focusOut() {
        this.set('focused', false);
        this.send('resetActivated');
      },
      clickCallout: function clickCallout() {
        var _this2 = this;

        if (this.click) {
          this.click();
        }

        this.set('inputActivated', true);
        Ember.run.scheduleOnce('afterRender', function () {
          (0, _jquery.default)(_this2.element.querySelectorAll('input')).trigger('focus');
        });
      },
      resetActivated: function resetActivated() {
        if (this.get('focused')) {
          return;
        }

        this.set('focused', false);
        this.set('inputActivated', this.get('value.length') > 0 || this.get('value') > 0);
      }
    }
  });

  _exports.default = _default;
});