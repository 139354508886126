define("frontend/components/document-list/mobile-document-list-item", ["exports", "frontend/components/document-list/document-list-item"], function (_exports, _documentListItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _documentListItem.default.extend({});

  _exports.default = _default;
});