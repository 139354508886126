define("frontend/transforms/null-if-zero", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      if (Number(serialized) === 0) {
        return null;
      } else {
        return Number(serialized);
      }
    },
    serialize: function serialize(deserialized) {
      if (deserialized == null) {
        return 0;
      } else {
        return Number(deserialized);
      }
    }
  });

  _exports.default = _default;
});