define("frontend/templates/components/avatar-images-with-names/avatar-images", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "IGSwwFFo",
    "block": "{\"symbols\":[\"avatar\"],\"statements\":[[4,\"each\",[[24,[\"avatars\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"img-inner-border\",null,[[\"src\",\"tooltipColor\",\"tooltipText\"],[[23,1,[\"image\"]],\"blue\",[23,1,[\"name\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/avatar-images-with-names/avatar-images.hbs"
    }
  });

  _exports.default = _default;
});