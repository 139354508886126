define("frontend/templates/components/pet-inputs/pet-vaccinations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "9FR+S97C",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"hasVaccinations\"]]],null,{\"statements\":[[4,\"paper-input-readonly\",null,[[\"label\"],[\"vaccinations\"]],{\"statements\":[[0,\"    \"],[1,[28,\"pet-inputs/vaccinations-table\",null,[[\"vaccinations\"],[[24,[\"pet\",\"vaccinations\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"a\",true],[10,\"class\",\"update-vaccinations\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"updateVaccinations\"],null]],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"update vaccinations\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"fake-input\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"updateVaccinations\"],null]],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"vaccinations\"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"showingModal\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"vaccinations-modal\",null,[[\"onClose\",\"onSubmit\",\"addAnotherVaccination\",\"allVaccinations\",\"vaccinations\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showingModal\"]]],null],false],null],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showingModal\"]]],null],false],null],[28,\"action\",[[23,0,[]],\"addAnotherVaccination\"],null],[24,[\"allVaccinations\"]],[24,[\"pet\",\"vaccinations\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/pet-inputs/pet-vaccinations.hbs"
    }
  });

  _exports.default = _default;
});