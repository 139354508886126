define("frontend/controllers/admin/veterinarian-clinic-groups", ["exports", "frontend/mixins/admin/approvable-table", "frontend/mixins/autocomplete/highlight-first-match"], function (_exports, _approvableTable, _highlightFirstMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Controller.extend(_approvableTable.default, _highlightFirstMatch.default, {
    modelName: 'vet-group',
    store: inject.service(),
    actions: {
      saveVetGroup: function saveVetGroup(vetGroup) {
        this.send('save', vetGroup);
        this.set('editingVetGroup', null);
      },
      rollbackVetGroup: function rollbackVetGroup(vetGroup) {
        vetGroup.rollbackAttributes();
        this.set('editingVetGroup', null);
      },
      closeEditingVetGroupModal: function closeEditingVetGroupModal() {
        this.set('selectedVetGroup', null);
        this.set('editingVetGroup', false);
      },
      uploadedVetGroupPhoto: function uploadedVetGroupPhoto(photo) {
        this.set('editingVetGroup.logo_url', photo);
      }
    }
  });

  _exports.default = _default;
});