define("frontend/templates/components/user-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aFamEQj/",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"percentage-bar\",null,[[\"value\",\"total\"],[[24,[\"fixedValue\"]],[24,[\"total\"]]]]],false],[7,\"span\",true],[10,\"class\",\"percentage-bar-amount\"],[8],[1,[22,\"label\"],true],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/user-bar.hbs"
    }
  });

  _exports.default = _default;
});