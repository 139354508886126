define("frontend/components/search-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['search-input'],
    classNameBindings: ['style'],
    searchButton: false,
    debounce: false,
    debounceTime: 1000,
    actions: {
      search: function search() {
        if (this.search) {
          this.search(this.get('value'));
        }
      },
      onInput: function onInput(e) {
        var _this = this;

        var target = e.target;
        Ember.run.debounce(function () {
          _this.set('value', target.value);
        }, this.get('debounceTime'));
      }
    }
  });

  _exports.default = _default;
});