define("frontend/components/ember-timepicker", ["exports", "ember-timepicker/components/ember-timepicker"], function (_exports, _emberTimepicker) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _emberTimepicker.default;
    }
  });
});