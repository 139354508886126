define("frontend/routes/portal/profile", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    actions: {
      transitionToEditHousehold: function transitionToEditHousehold(household, petOrUser) {
        var self = this;
        household.get('users').forEach(function (user) {
          var phoneNumbers = user.get('phone_numbers');

          if (phoneNumbers.get('length') == 0) {
            var defaultPhone = self.store.createRecord('phone-number');
            user.get('phone_numbers').pushObject(defaultPhone);
          }
        });
        var queryParams = {
          backRoute: 'portal.profile'
        };

        if (petOrUser.get('modelName') === 'household-user') {
          if (petOrUser.get('isHouseholdOwner')) {
            this.transitionTo('edit-client.primary-contact', household, {
              queryParams: queryParams
            });
          } else {
            this.transitionTo('edit-client.secondary-contact', household, {
              queryParams: queryParams
            });
          }
        } else {
          this.transitionTo('edit-client.pet', household, petOrUser, {
            queryParams: queryParams
          });
        }
      }
    }
  });

  _exports.default = _default;
});