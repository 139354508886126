define("frontend/components/customize-time-window-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    serviceCategory: null,
    timeFrame: null,
    timeDropdownOptions: {
      interval: 30,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      minTime: '6:00AM',
      maxTime: '11:30PM',
      startTime: '6:00AM',
      timeFormat: 'h:mmp',
      momentFormat: 'h:mmA'
    },
    currentTime: null,
    timeFrameStart: null,
    timeFrameEnd: null,
    showTimeError: computed('timeFrameStart', 'timeFrameEnd', function () {
      var startTime = this.get('timeFrameStart');
      var endTime = this.get('timeFrameEnd');

      if (!startTime || !endTime) {
        return false;
      }

      if (moment("2019-11-11 ".concat(startTime), 'YYYY-MM-DD h:mmA').isSameOrAfter(moment("2019-11-11 ".concat(endTime), 'YYYY-MM-DD h:mmA'))) {
        return true;
      }

      return false;
    }),
    showBetweenError: computed('currentTime', 'timeFrameStart', 'timeFrameEnd', function () {
      var time = moment("2019-11-11 ".concat(this.get('currentTime')), 'YYYY-MM-DD h:mmA');
      var startTime = moment("2019-11-11 ".concat(this.get('timeFrameStart')), 'YYYY-MM-DD h:mmA');
      var endTime = moment("2019-11-11 ".concat(this.get('timeFrameEnd')), 'YYYY-MM-DD h:mmA');

      if (time.isSameOrAfter(startTime) && time.isSameOrBefore(endTime)) {
        return false;
      }

      return true;
    }),
    actions: {
      cancel: function cancel() {
        this.get('onClose')(false);
      },
      update: function update() {
        if (this.get('showTimeError') || this.get('showBetweenError')) {
          return;
        }

        this.get('onClose')(true);
      }
    }
  });

  _exports.default = _default;
});