define("frontend/templates/staff-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g6hGldPo",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"staff-search staff-new\"],[8],[0,\"\\n\"],[4,\"new-header\",null,[[\"cancel\"],[[28,\"route-action\",[\"transitionToStaff\"],null]]],{\"statements\":[[0,\"    \"],[7,\"h1\",true],[10,\"class\",\"header-text\"],[8],[0,\"Add new staff member\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n    \"],[1,[28,\"user-search-searchbar\",null,[[\"staff\",\"defaultEmail\",\"setEmail\",\"search\"],[true,[24,[\"email\"]],[28,\"action\",[[23,0,[]],\"setEmail\"],null],[28,\"action\",[[23,0,[]],\"search\"],null]]]],false],[0,\"\\n    \"],[1,[28,\"user-search-list\",null,[[\"staff\",\"searchTerm\",\"searchNow\"],[true,[24,[\"filter\"]],[24,[\"searchNow\"]]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/staff-search.hbs"
    }
  });

  _exports.default = _default;
});