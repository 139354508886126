define("frontend/components/list-right-side-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['list-right-side-panel'],
    classNameBindings: ['right-side-panel-collapsed']
  });

  _exports.default = _default;
});