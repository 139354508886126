define("frontend/templates/components/invoices-widget/invoices-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "llVNdOsl",
    "block": "{\"symbols\":[\"invoice\"],\"statements\":[[4,\"if\",[[24,[\"isDraft\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"invoices-widget/invoices-group-draft-header\",null,[[\"invoicesGroup\",\"collapsed\",\"toggleCollapsed\"],[[24,[\"invoicesGroup\"]],[24,[\"collapsed\"]],[28,\"action\",[[23,0,[]],\"toggleCollapsed\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"invoices-widget/invoices-group-sent-header\",null,[[\"invoicesGroup\",\"isPaid\",\"isSent\",\"collapsed\",\"toggleCollapsed\"],[[24,[\"invoicesGroup\"]],[24,[\"isPaid\"]],[24,[\"isSent\"]],[24,[\"collapsed\"]],[28,\"action\",[[23,0,[]],\"toggleCollapsed\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[0,\"\\n\"],[4,\"unless\",[[24,[\"collapsed\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"invoices\"]]],null,{\"statements\":[[4,\"link-to\",null,[[\"route\",\"models\"],[\"invoices-summary.invoice-detail\",[28,\"array\",[[23,1,[\"household\",\"id\"]],[23,1,[\"id\"]]],null]]],{\"statements\":[[0,\"      \"],[1,[28,\"invoices-widget/invoice-item\",null,[[\"invoice\",\"invoiceType\"],[[23,1,[]],[24,[\"invoiceType\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[4,\"unless\",[[24,[\"reachedInfinity\"]]],null,{\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"custom-infinity-loader\",null,[[\"infinityLoad\",\"scrollableArea\"],[[28,\"action\",[[23,0,[]],\"infinityLoad\"],null],\".invoices-content\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"custom-infinity-loader\",null,[[\"infinityLoad\",\"scrollableArea\"],[[28,\"action\",[[23,0,[]],\"infinityLoad\"],null],[28,\"concat\",[\"#\",[24,[\"uid\"]],\" .invoices-content\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/invoices-widget/invoices-group.hbs"
    }
  });

  _exports.default = _default;
});