define("frontend/components/late-notifications-modal", ["exports", "frontend/mixins/money-formater"], function (_exports, _moneyFormater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend(_moneyFormater.default, {
    store: inject.service(),
    serviceCategory: null,
    notificationType: null,
    selectedTimingOption: computed('notificationType', 'arrivalTiming', 'checkoutTiming', function () {
      var options = this.get('timingOptions');
      var selectedValue = this.get('notificationType') === 'arrival' ? this.get('arrivalTiming') : this.get('checkoutTiming');
      var selected = options.filter(function (option) {
        return selectedValue == option.value;
      });
      return selected.get('firstObject');
    }),
    exampleAlertTime: computed('notificationType', 'arrivalTiming', 'checkoutTiming', function () {
      var startTime = moment('9AM', 'hA');
      var selectedValue = this.get('notificationType') === 'arrival' ? this.get('arrivalTiming') : this.get('checkoutTiming');
      var alertTime = moment(startTime).add(selectedValue, 'minutes');

      if (this.get('notificationType') != 'arrival') {
        alertTime = alertTime.add(30, 'minutes');
      }

      return alertTime.format('h:mmA');
    }),
    headerIcon: computed('notificationType', function () {
      return this.get('notificationType') === 'arrival' ? "assets/images/modal-icons/icon-alarmclock.svg" : "assets/images/modal-icons/icon-timer.svg";
    }),
    actions: {
      cancel: function cancel() {
        if (this.get('loading')) {
          return;
        }

        this.get('onClose')();
      },
      changeTiming: function changeTiming(option) {
        if (this.get('notificationType') === 'arrival') {
          this.set('arrivalTiming', option.value);
        } else {
          this.set('checkoutTiming', option.value);
        }
      },
      save: function save() {
        if (this.get('notificationType') === 'arrival') {
          this.get('serviceCategory').updateCheckInAlertTiming();
        } else {
          this.get('serviceCategory').updateCheckOutAlertTiming();
        }

        this.get('onClose')(false);
      }
    }
  });

  _exports.default = _default;
});