define("frontend/services/time-zone", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var TIME_ZONES = {
    'Atlantic Time (Canada)': '-04:00',
    'Eastern Time (US & Canada)': '-05:00',
    'Central Time (US & Canada)': '-06:00',
    'Mountain Time (US & Canada)': '-07:00',
    'Pacific Time (US & Canada)': '-08:00',
    'Alaska': '-09:00',
    'Hawaii': '-10:00',
    'Perth': '+08:00',
    'Darwin': '+09:30',
    'Adelaide': '+09:30',
    'Brisbane': '+10:00',
    'Sydney': '+10:00'
  };
  var TIME_ZONES_DST = {
    'Atlantic Time (Canada)': '-03:00',
    'Eastern Time (US & Canada)': '-04:00',
    'Central Time (US & Canada)': '-05:00',
    'Mountain Time (US & Canada)': '-06:00',
    'Pacific Time (US & Canada)': '-07:00',
    'Alaska': '-08:00',
    'Hawaii': '-09:00',
    'Perth': '+08:00',
    'Darwin': '+09:30',
    'Adelaide': '+10:30',
    'Brisbane': '+10:00',
    'Sydney': '+11:00'
  };
  var TIME_ZONE_LABELS = {
    'Atlantic Time (Canada)': 'Atlantic Time (Canada)',
    'Eastern Time (US & Canada)': 'Eastern Time (US & Canada)',
    'Central Time (US & Canada)': 'Central Time (US & Canada)',
    'Mountain Time (US & Canada)': 'Mountain Time (US & Canada)',
    'Pacific Time (US & Canada)': 'Pacific Time (US & Canada)',
    'Alaska': 'Alaska',
    'Hawaii': 'Hawaii',
    'Perth': 'Australian Western Standard Time',
    'Darwin': 'Australian Central Standard Time',
    'Adelaide': 'Australian Central Daylight Time',
    'Brisbane': 'Australian Eastern Standard Time',
    'Sydney': 'Australian Eastern Daylight Time'
  };
  var TIME_ZONES_PER_COUNTRY = {
    'United States': ['Eastern Time (US & Canada)', 'Central Time (US & Canada)', 'Mountain Time (US & Canada)', 'Pacific Time (US & Canada)', 'Alaska', 'Hawaii'],
    'Canada': ['Atlantic Time (Canada)', 'Eastern Time (US & Canada)', 'Central Time (US & Canada)', 'Mountain Time (US & Canada)', 'Pacific Time (US & Canada)'],
    'Australia': ['Perth', 'Darwin', 'Adelaide', 'Brisbane', 'Sydney']
  };

  var _default = Ember.Service.extend({
    currentUser: Ember.inject.service(),
    offset: computed('currentUser.user.company.time_zone', function () {
      var tz = this.get('currentUser.user.company.time_zone');

      if (!tz) {
        tz = 'Eastern Time (US & Canada)';
      }

      return TIME_ZONES[tz];
    }),
    timeZoneOptions: computed('currentUser.user.company.country', function () {
      return TIME_ZONES_PER_COUNTRY[this.get('currentUser.user.company.country')].map(function (timeZone) {
        return {
          value: timeZone,
          label: TIME_ZONE_LABELS[timeZone]
        };
      });
    }),
    timeZoneOptionsForCountry: function timeZoneOptionsForCountry(country) {
      if (!Object.keys(TIME_ZONES_PER_COUNTRY).includes(country)) {
        return [];
      }

      return TIME_ZONES_PER_COUNTRY[country].map(function (timeZone) {
        return {
          value: timeZone,
          label: TIME_ZONE_LABELS[timeZone]
        };
      });
    },
    offsetDSTAware: function offsetDSTAware(time) {
      var tz = this.get('currentUser.user.company.time_zone');

      if (!tz) {
        tz = 'Eastern Time (US & Canada)';
      }

      if ((0, _moment.default)(time).endOf('day').isDST()) {
        return TIME_ZONES_DST[tz];
      } else {
        return TIME_ZONES[tz];
      }
    },
    parseZone: function parseZone(time) {
      var offset = this.offsetDSTAware(time);
      time = "".concat(time).concat(offset);
      return _moment.default.parseZone(time).toISOString();
    },
    companyMoment: computed('offset', function () {
      return (0, _moment.default)().utcOffset(this.get('offset'));
    })
  });

  _exports.default = _default;
});