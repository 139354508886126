define("frontend/components/household-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    households: null,
    filter: '',
    classNames: ['household-select'],
    showAccountBalance: false,
    uniqueHouseholds: Ember.computed('households.[]', function () {
      var uniqueIds = [];
      return this.get('households').filter(function (household) {
        if (uniqueIds.includes(household.get('id'))) {
          return false;
        } else {
          uniqueIds.pushObject(household.get('id'));
          return true;
        }
      });
    }),
    actions: {
      infinityLoad: function infinityLoad() {
        this.infinityLoad.apply(this, arguments);
      },
      selectHousehold: function selectHousehold(household) {
        this.selectHousehold(household);
      },
      transitionToAddClient: function transitionToAddClient() {
        this.transitionToAddClient();
      }
    }
  });

  _exports.default = _default;
});