define("frontend/templates/components/contact-picker-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YIeNHTEb",
    "block": "{\"symbols\":[\"contact\"],\"statements\":[[4,\"ppb-modal-scrollable\",null,null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",\"contact-picker-back\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"onClose\"],null]]],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"assets/images/back-chevron.svg\"],[8],[9],[0,\" Back\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"contact-picker-modal\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"alt\",\"Phonebook\"],[10,\"src\",\"/assets/images/phonebook.svg\"],[8],[9],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"contact-picker-modal-prompt\"],[8],[1,[22,\"contactPrompt\"],false],[9],[0,\"\\n\"],[4,\"each\",[[24,[\"contacts\"]]],null,{\"statements\":[[4,\"ppb-button\",null,[[\"class\",\"onclick\"],[\"contact-picker-modal-contact-info blue-border\",[28,\"action\",[[23,0,[]],\"contact\",[23,1,[\"model\"]]],null]]],{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"contact-picker-modal-contact-info-type\"],[8],[0,\"\\n          \"],[1,[23,1,[\"type\"]],false],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"contact-picker-modal-contact-info-address\"],[8],[0,\"\\n          \"],[1,[23,1,[\"address\"]],false],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/contact-picker-modal.hbs"
    }
  });

  _exports.default = _default;
});