define("frontend/controllers/admin/email-look-up", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Controller.extend({
    petParentVerificationModal: false,
    searchEmail: '',
    searchFirstName: '',
    searchLastName: '',
    selectedPetParentEmail: null,
    session: inject.service(),
    currentUser: inject.service(),
    accountSession: inject.service(),
    userInfos: null,
    foundUsers: null,
    actions: {
      closeDeletePetParentVerificationModal: function closeDeletePetParentVerificationModal() {
        this.set('petParentVerificationModal', false);
        this.set('selectedPetParentEmail', null);
      },
      deletePetParent: function deletePetParent() {
        var email = this.get('selectedPetParentEmail');

        _jquery.default.post("".concat(_environment.default.APP.host, "/api/v2/admin/delete_pet_parent"), {
          email: email
        }).then(function () {
          window.location.reload();
        }).catch(function () {
          alert("Something went wrong deleting ".concat(email));
        });
      },
      impersonate: function impersonate(user_email) {
        var _this = this;

        var store = this.get('session.store');

        _jquery.default.post("".concat(_environment.default.APP.host, "/api/v2/impersonate_session"), {
          user: {
            user_email: user_email
          }
        }).then(function (response) {
          store.clear().then(function () {
            store.persist(response).then(function () {
              _this.get('accountSession').clearCompany();

              window.location.href = '/';
            });
          });
        });
      },
      search: function search() {
        var _this2 = this;

        var email = this.get('searchEmail');
        var first = this.get('searchFirstName');
        var last = this.get('searchLastName');

        _jquery.default.post("".concat(_environment.default.APP.host, "/api/v2/users/user_search"), {
          email: email,
          first: first,
          last: last
        }).then(function (response) {
          _this2.set('foundUsers', response.found);

          _this2.set('userInfos', response.user_infos);
        });
      },
      triggerDeletePetParentVerification: function triggerDeletePetParentVerification(user_email) {
        this.set('petParentVerificationModal', true);
        this.set('selectedPetParentEmail', user_email);
      },
      clickedIssueRefund: function clickedIssueRefund(user_email) {
        this.set('showPaymentRefundModal', true);
        this.set('selectedPetParentEmail', user_email);
      },
      closeRefundModal: function closeRefundModal() {
        this.set('showPaymentRefundModal', false);
        this.set('selectedPetParentEmail', null);
      }
    }
  });

  _exports.default = _default;
});