define("frontend/components/recurring-service-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['service-box'],
    attributeBindings: ['service-id', 'style'],
    classNameBindings: ['selected'],
    service: null,
    showTime: false,
    selected: true,
    style: computed('showTime', function () {
      var pixelHeight = 105;
      return Ember.String.htmlSafe("height: ".concat(pixelHeight, "px;"));
    })
  });

  _exports.default = _default;
});