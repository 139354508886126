define("frontend/controllers/application", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Controller.extend({
    phoneTypes: [{
      name: 'Mobile',
      id: 1
    }, {
      name: 'Work',
      id: 2
    }, {
      name: 'Home',
      id: 3
    }],
    timeZones: ['Eastern Time (US & Canada)', 'Central Time (US & Canada)', 'Mountain Time (US & Canada)', 'Pacific Time (US & Canada)', 'Alaska', 'Hawaii'],
    type: 'success',
    showAcceptTOSModal: false,
    showTOSDeclined: false,
    flashMessage: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    inboxHasUnreadMessages: Ember.inject.service(),
    hasUnreadMessages: Ember.computed.alias('inboxHasUnreadMessages.hasUnreadMessages'),
    onIndex: computed.equal('currentRouteName', 'index'),
    onMessagesTab: computed.equal('currentRouteName', 'portal.messages.company'),
    onGettingPaidPage: computed.equal('currentRouteName', 'companies.getting-paid'),
    showBeacons: computed('currentUser.user', 'currentUser.user.isPetParent', 'onMessagesTab', function () {
      return !this.get('currentUser.user') || this.get('currentUser.user.isPetParent') && !this.get('onMessagesTab');
    }),
    showEpaymentsReminderModal: computed('currentUser.user.showEpaymentsReminder', 'onGettingPaidPage', function () {
      if (this.get('onGettingPaidPage')) {
        return null;
      }

      return this.get('currentUser.user.showEpaymentsReminder');
    }),
    showHolidayUpdateReminderModal: computed('currentUser.user.showHolidayUpdateReminder', 'onGettingPaidPage', function () {
      return this.get('currentUser.user.showHolidayUpdateReminder');
    }),
    dismissHolidaysModal: function dismissHolidaysModal(completed, callback) {
      var _this = this;

      _jquery.default.ajax({
        url: "/api/v2/users/".concat(this.get('currentUser.user.id'), "/dismiss_holidays_reminder"),
        method: 'PUT',
        data: {
          completed: completed
        }
      }).done(function () {
        _this.set('currentUser.user.showHolidayUpdateReminder', false);

        if (callback) {
          callback();
        }
      }).fail(function () {
        _this.get('flashMessage').error('Unexpected error dismissing modal. Please try again');
      });
    },
    goToCompanyPolicies: function goToCompanyPolicies() {
      this.transitionToRoute('companies.category-settings', this.get('currentUser.user.company.id'), 'policies', {
        queryParams: {
          showHolidayPolicyModal: true
        }
      });
    },
    actions: {
      closeNotification: function closeNotification() {
        this.set('notification', null);
      },
      reloadModel: function reloadModel(retryTransition) {
        this.send('reload', retryTransition);
      },
      refresh: function refresh() {
        window.location.reload();
      },
      notify: function notify(options) {
        this.set('yes', options.yes);
        this.set('no', options.no);
        this.set('notification', options);
      },
      yes: function yes() {
        this.get('yes')();
        this.set('notification', null);
      },
      no: function no() {
        this.get('no')();
        this.set('notification', null);
      },
      declineTOS: function declineTOS() {
        this.set('showTOSDeclined', true);
      },
      acceptTOS: function acceptTOS() {
        var _this2 = this;

        _jquery.default.ajax({
          url: "/api/v1/users/".concat(this.get('currentUser.user.id'), "/accept_tos"),
          method: 'PUT'
        }).done(function () {
          _this2.set('showAcceptTOSModal', false);

          _this2.set('showTOSDeclined', false);

          _this2.set('currentUser.user.accept_new_tos', false);
        }).fail(function () {
          _this2.get('flashMessage').error('Unexpected error updating terms of service acceptance. Please try again');
        });
      },
      openBeacon: function openBeacon() {
        // eslint-disable-next-line no-undef,new-cap
        Beacon('open');
      },
      goToClientBilling: function goToClientBilling() {
        this.transitionToRoute('companies.getting-paid', this.get('currentUser.user.company'));
      },
      goToPolicies: function goToPolicies() {
        this.dismissHolidaysModal(true, this.goToCompanyPolicies.bind(this));
      },
      permenantlyDismissModal: function permenantlyDismissModal() {
        this.dismissHolidaysModal(true, null);
      },
      dismissHolidaysReminder: function dismissHolidaysReminder() {
        this.dismissHolidaysModal(false, null);
      },
      dismissEpaymentsReminder: function dismissEpaymentsReminder() {
        var _this3 = this;

        _jquery.default.ajax({
          url: "/api/v2/users/".concat(this.get('currentUser.user.id'), "/dismiss_epayments_reminder"),
          method: 'PUT'
        }).done(function () {
          _this3.set('currentUser.user.showEpaymentsReminder', false);
        }).fail(function () {
          _this3.get('flashMessage').error('Unexpected error dismissing modal. Please try again');
        });
      }
    }
  });

  _exports.default = _default;
});