define("frontend/controllers/single-appointment-date", ["exports", "moment", "frontend/config/environment", "jquery"], function (_exports, _moment, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    needsDate: true,
    timesUpdatedAt: Ember.computed('model.times.@each.time', function () {
      // eslint-disable-next-line new-cap
      var now = new _moment.default();
      this.parentController.set('timesUpdatedAt', now);
      return now;
    }),
    appointmentTimes: Ember.computed('model', 'model.date', 'parentController.fields.care_provider_id', 'parentController.fields.offered_service_id', function () {
      var times = [];
      var fields = this.parentController.get('fields');

      _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v1/services/available_times"),
        method: 'GET',
        data: {
          care_provider_id: fields.care_provider_id,
          offered_service_id: fields.offered_service_id,
          date: this.model.date
        }
      }).done(function (data) {
        for (var i = 0; i < data.length; i++) {
          times.pushObject(data[i]);
        }
      });

      return times;
    }),
    showLink: Ember.computed('model', 'model.appt_count', 'parentController', 'parentController.appointmentComplete', function () {
      return this.get('model.appt_count') < 10 && this.get('parentController.appointmentComplete');
    })
  });

  _exports.default = _default;
});