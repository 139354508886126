define("frontend/components/scroll-listener", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ROOT_ELEMENT = '.page-wrap';

  var _default = Ember.Component.extend({
    enabled: true,
    classToListenTo: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('enabled')) {
        var element = this.get('classToListenTo') ? ".".concat(this.get('classToListenTo')) : ROOT_ELEMENT;
        (0, _jquery.default)(element).on('scroll', function () {
          _this.get('onScroll')();
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('enabled')) {
        var element = this.get('classToListenTo') ? ".".concat(this.get('classToListenTo')) : ROOT_ELEMENT;
        (0, _jquery.default)(element).off('scroll');
      }
    }
  });

  _exports.default = _default;
});