define("frontend/controllers/add-client/pet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    valid: computed.readOnly('pet.validations.isValid'),
    nextDisabled: computed.not('valid'),
    application: inject.controller(),
    queryParams: ['petIndex'],
    anySidePanelActive: false,
    petIndex: 0,
    addClientSaving: inject.service(),
    currentUser: inject.service(),
    session: inject.service(),
    accountSession: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    showAddPetPrompt: false,
    isPetParent: computed('session.data.authenticated.tokenData', function () {
      return this.get('accountSession').isPetParent();
    }),
    pet: computed('model.pets.[]', 'petIndex', 'currentUser.user.client_accounts.[]', function () {
      var household = this.get('model');
      var pet = household.get('pets').objectAt(this.get('petIndex'));

      if (pet) {
        return pet;
      } else {
        var _pet = this.store.createRecord('pet', {
          household: this.get('model'),
          pet_type: 'Dog'
        });

        if (this.get('isPetParent')) {
          var companies = this.get('currentUser.user.client_accounts');

          if (companies && companies.length > 0) {
            var companyNotes = [];
            companies.forEach(function (company) {
              companyNotes.pushObject({
                company_name: company.company_name,
                company_id: company.company_id,
                notes: null
              });
            });

            _pet.set('company_notes', companyNotes);
          }
        }

        return _pet;
      }
    }),
    avatarPlaceholder: computed('pet', 'pet.pet_type', function () {
      var pet_type = this.get('pet.pet_type');

      if (!pet_type) {
        pet_type = 'Dog';
      }

      return "assets/images/placeholders/".concat(pet_type, ".svg");
    }),
    isFirstPet: function isFirstPet() {
      return this.get('petIndex') === 0;
    },
    goToPreviousPet: function goToPreviousPet() {
      this.set('showAddPetPrompt', false);
      this.decrementProperty('petIndex');
      this.get('model.pets').filterBy('id', null).invoke('deleteRecord');
      this.focusInputCallout();
    },
    // TODO Fix this in the input callouts themselves
    focusInputCallout: function focusInputCallout() {
      Ember.run.scheduleOnce('afterRender', function () {
        $('.input-callout').focus();
        Ember.run.scheduleOnce('afterRender', function () {
          $('textarea,input').blur();
        });
      });
    },
    goToNextPet: function goToNextPet() {
      this.incrementProperty('petIndex');
      this.set('birthdayMask', null);

      if (this.isNewPet()) {
        $('.page-wrap').animate({
          scrollTop: 0
        }, 'slow');
        this.set('showAddPetPrompt', true);
      }
    },
    isNewPet: function isNewPet() {
      return !this.get('model.pets').objectAt(this.get('petIndex'));
    },
    actions: {
      uploadedPhoto: function uploadedPhoto(photo) {
        this.set('pet.avatar_url', photo);
      },
      addPet: function addPet() {
        this.set('showAddPetPrompt', false);
      },
      skipAddPet: function skipAddPet() {
        var _this = this;

        var householdId = this.get('model.id');

        if (this.get('isPetParent') && !this.get('currentUser.user.canViewClientPortalInvoices')) {
          this.transitionToRoute('portal.profile').then(function () {
            _this.set('showAddPetPrompt', false);
          });
        } else {
          this.get('pet').rollbackAttributes();

          if (this.get('isPetParent') || this.get('currentUser.user.isAdmin')) {
            this.transitionToRoute('add-client.billing-settings', householdId).then(function () {
              _this.set('showAddPetPrompt', false);
            });
          } else {
            this.set('saving', true);
            this.transitionToRoute('add-client.documents', householdId).then(function () {
              _this.set('saving', false);

              _this.set('showAddPetPrompt', false);
            });
          }
        }
      },
      goBack: function goBack() {
        if (this.isFirstPet()) {
          this.send('transitionToHuman');
        } else {
          this.goToPreviousPet();
        }
      },
      goNext: function goNext(pet) {
        var _this2 = this;

        this.set('saving', true);
        this.get('addClientSaving').savePet(pet).then(function () {
          pet.get('emergency_contacts').forEach(function (ec) {
            if (ec.get('hasDirtyAttributes')) {
              ec.rollbackAttributes();
            }
          });
          pet.get('vaccinations').forEach(function (m) {
            if (m.get('hasDirtyAttributes')) {
              m.rollbackAttributes();
            }
          });
          pet.get('pet_prescriptions').forEach(function (m) {
            if (m.get('hasDirtyAttributes')) {
              m.rollbackAttributes();
            }
          });
          return _this2.get('model').reload();
        }).then(function () {
          _this2.goToNextPet();
        }).finally(function () {
          _this2.set('saving', false);
        });
      }
    }
  });

  _exports.default = _default;
});