define("frontend/templates/profile/edit-about-me", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "DyR2B0l+",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"mobile-header-text-wormhole-container\"]],{\"statements\":[[0,\"    About me\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"add-edit-page\"],[8],[0,\"\\n\"],[4,\"add-client/client-inputs\",null,[[\"onSubmit\",\"showDefaultSchedule\",\"household\",\"isOnAboutMePage\",\"householdUser\"],[[28,\"action\",[[23,0,[]],\"save\",[24,[\"model\"]]],null],[24,[\"model\",\"canViewAllSchedule\"]],[24,[\"model\",\"household\"]],true,[24,[\"model\"]]]],{\"statements\":[[0,\"    \"],[1,[28,\"adaptive-navigation-buttons\",null,[[\"clickRight\",\"clickLeft\",\"showLeft\",\"leftText\",\"rightText\"],[[23,1,[\"onSubmit\"]],[28,\"route-action\",[\"cancel\"],null],[28,\"not\",[[28,\"media\",[\"isMobile\"],null]],null],\"Cancel\",\"Save\"]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/profile/edit-about-me.hbs"
    }
  });

  _exports.default = _default;
});