define("frontend/models/time-frame", ["exports", "ember-data", "frontend/utils/string-util"], function (_exports, _emberData, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend({
    description: _emberData.default.attr('string'),
    start_time: _emberData.default.attr('string'),
    end_time: _emberData.default.attr('string'),
    is_off_hours: _emberData.default.attr('boolean', {
      default: false
    }),
    service_category_id: _emberData.default.attr(),
    service_category: _emberData.default.belongsTo('service-category'),
    days_of_week: _emberData.default.attr(),
    markedForDeletion: false,
    noSpacesStartTime: computed('start_time', function () {
      return this.get('start_time').replace(' ', '');
    }),
    noSpacesEndTime: computed('end_time', function () {
      return this.get('end_time').replace(' ', '');
    }),
    dayOfWeekDisplay: computed('days_of_week.[]', function () {
      var dows = this.get('days_of_week');

      if (dows.length == 7) {
        return 'Mon - Sun';
      } else if (dows.length === 5 && dows.sort().every(function (value, index) {
        return value === [1, 2, 3, 4, 5][index];
      })) {
        return 'Mon - Fri';
      } else {
        var dayMap = [['Mon', 1], ['Tue', 2], ['Wed', 3], ['Thu', 4], ['Fri', 5], ['Sat', 6], ['Sun', 0]];
        var selectedDays = [];
        dayMap.forEach(function (day) {
          if (dows.includes(day[1])) {
            selectedDays.push(day[0]);
          }
        });
        return _stringUtil.default.joinWithAnd(selectedDays);
      }
    }),
    dayOfWeekLongDisplay: computed('days_of_week.[]', function () {
      var dows = this.get('days_of_week');

      if (dows.length == 7) {
        return 'Monday through Sunday';
      } else if (dows.length === 5 && dows.sort().every(function (value, index) {
        return value === [1, 2, 3, 4, 5][index];
      })) {
        return 'Monday through Friday';
      } else {
        var dayMap = [['Monday', 1], ['Tuesday', 2], ['Wednesday', 3], ['Thursday', 4], ['Friday', 5], ['Saturday', 6], ['Sunday', 0]];
        var selectedDays = [];
        dayMap.forEach(function (day) {
          if (dows.includes(day[1])) {
            selectedDays.push(day[0]);
          }
        });
        return _stringUtil.default.joinWithAnd(selectedDays);
      }
    })
  });

  _exports.default = _default;
});