define("frontend/templates/components/flash-message-get-started", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7Nvb54fm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"id\",\"flash-message\"],[10,\"class\",\"alert fade in info\"],[10,\"role\",\"alert\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"message-container\"],[8],[0,\"\\n    \"],[7,\"p\",true],[10,\"class\",\"message\"],[8],[0,\"\\n      Welcome to PetPocketbook!\\n      \"],[7,\"hr\",true],[10,\"class\",\"visible-xs\"],[8],[9],[0,\"\\n      \"],[7,\"a\",false],[12,\"class\",\"btn\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"getStarted\"]],[8],[0,\"Let’s get started\"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/flash-message-get-started.hbs"
    }
  });

  _exports.default = _default;
});