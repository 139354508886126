define("frontend/components/bill-delivery-options", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['add-with-side-panel', 'bill-delivery-options'],
    classNameBindings: ['noBillRecipients:empty'],
    household: null,
    householdEmails: [],
    noBillRecipients: computed.equal('household.bill_recipient_emails.length', 0),
    actions: {
      addEmail: function addEmail(email) {
        this.get('household.bill_recipient_emails').pushObject(email);
        this.set('showingBillDeliveryModal', false);
        this.get('save')(this.get('household'));
      },
      removeEmail: function removeEmail(email) {
        this.get('household.bill_recipient_emails').removeObject(email);
        this.get('save')(this.get('household'));
      }
    }
  });

  _exports.default = _default;
});