define("frontend/components/client-list/pet-output/section-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'header',
    active: false,
    classNames: ['section-header'],
    classNameBindings: ['active']
  });

  _exports.default = _default;
});