define("frontend/models/recurring-appointment-group", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    recurring_appointments: _emberData.default.hasMany('recurring-appointment', {
      async: true
    }),
    changes_start_on: _emberData.default.attr('string'),
    offered_service: _emberData.default.belongsTo('offered-service', {
      async: true
    }),
    start_date: _emberData.default.attr('string'),
    end_date: _emberData.default.attr('string'),
    made_changes: _emberData.default.attr('boolean'),
    pets: _emberData.default.hasMany('pet', {
      async: true
    })
  });

  _exports.default = _default;
});