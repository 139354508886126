define("frontend/components/appointment-details", ["exports", "frontend/config/environment", "moment", "jquery"], function (_exports, _environment, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject,
      debounce = Ember.run.debounce;

  var _default = Ember.Component.extend({
    ajax: inject.service(),
    media: inject.service(),
    polling: inject.service(),
    classNames: ['appointment-details'],
    appointment: null,
    appointmentDetails: computed.readOnly('appointment.appointment_detail'),
    updatingAppointment: computed.readOnly('appointment.updatingCompleteStatus'),
    currentUser: inject.service(),
    flashMessage: inject.service(),
    store: inject.service(),
    isDog: computed.equal('appointment.pet.pet_type_id', '1'),
    isCat: computed.equal('appointment.pet.pet_type_id', '2'),
    archiveIconUrl: 'assets/images/archive-icon.svg',
    modifyIconUrl: 'assets/images/edit-icon-red.svg',
    reportCardIconUrl: computed('appointment.grouped_report_card.draft', function () {
      if (!this.get('appointment.grouped_report_card') || this.get('appointment.grouped_report_card.draft')) {
        return 'assets/images/report-card/finish-report-card.svg';
      }

      return 'assets/images/report-card/view-report-card-icon.svg';
    }),
    reportCardActionLabel: computed('appointment.grouped_report_card.draft', function () {
      if (!this.get('appointment.grouped_report_card')) {
        return 'Start';
      }

      return this.get('appointment.grouped_report_card.draft') ? 'Finish' : 'View';
    }),
    month: computed('appointment.service.starts_at', function () {
      return _moment.default.parseZone(this.get('appointment.service.starts_at')).startOf('day').format('MMM');
    }),
    day: computed('appointment.service.starts_at', function () {
      return _moment.default.parseZone(this.get('appointment.service.starts_at')).startOf('day').format('D');
    }),
    dayOfWeek: computed('appointment.service.starts_at', function () {
      return _moment.default.parseZone(this.get('appointment.service.starts_at')).startOf('day').format('ddd');
    }),
    startEndTime: computed('appointment.service.starts_at', 'appointment.offered_service.duration_in_minutes', function () {
      var start = _moment.default.parseZone(this.get('appointment.service.starts_at'));

      var duration = this.get('appointment.offered_service.duration_in_minutes');

      var end = _moment.default.parseZone(this.get('appointment.service.starts_at')).add(duration, 'minutes');

      return "".concat(start.format('h:mmA'), " - ").concat(end.format('h:mmA'));
    }),
    petImageStyle: computed('appointment.offered_service_color_hex', function () {
      var colorHex = this.get('appointment.offered_service_color_hex');
      return Ember.String.htmlSafe("border: 4px solid #".concat(colorHex, "; border-radius: 100px;"));
    }),
    currentTime: (0, _moment.default)(),
    showCanceledAppointmentControls: computed.and('currentUser.user.canAddEditAppointments', 'appointment.canceled_at'),
    showPrimaryAppointmentControls: computed.not('appointment.canceled_at'),
    notUpdatingCompleteStatus: computed.not('appointment.updatingCompleteStatus'),
    appointmentNotStarted: computed.empty('appointment.started_at'),
    appointmentNotFinished: computed.empty('appointment.finished_at'),
    appointmentStartedNotFinished: computed.and('appointment.started_at', 'appointmentNotFinished'),
    petAvatarBorderWidth: computed('appointmentStartedNotFinished', function () {
      return this.get('appointmentStartedNotFinished') ? '0px' : '4px';
    }),
    showReportCardButton: computed('appointment.updatingCompleteStatus', 'appointment.started_at', 'appointment.completed', function () {
      if (this.get('appointment.updatingCompleteStatus')) {
        return false;
      }

      if (this.get('appointment.completed') || this.get('appointment.started_at')) {
        return true;
      }

      return false;
    }),
    showEditAppointmentActions: computed('currentUser.user.canAddEditAppointments', 'appointment.started_at', 'appointment.completed', function () {
      if (!this.get('currentUser.user.canAddEditAppointments')) {
        return false;
      }

      return !(this.get('appointment.started_at') && !this.get('appointment.completed')) || this.get('appointment.started_at') && this.get('appointment.finished_at') && !this.get('appointment.completed');
    }),
    durationTime: computed('appointment.started_at', 'currentTime', function () {
      var now = this.get('currentTime');
      var start = (0, _moment.default)(this.get('appointment.started_at'));

      var duration = _moment.default.duration(now.diff(start));

      var hours = duration.hours();

      if (hours < 10) {
        hours = "0".concat(hours);
      }

      var minutes = duration.minutes();

      if (minutes < 10) {
        minutes = "0".concat(minutes);
      }

      var seconds = duration.seconds();

      if (seconds < 10) {
        seconds = "0".concat(seconds);
      }

      return "".concat(hours, ":").concat(minutes, ":").concat(seconds);
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('currentTime', (0, _moment.default)());
      this.get('polling').add('time update', this, function () {
        _this.set('currentTime', (0, _moment.default)());

        _this.updateDurationProgress();
      });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);

      debounce(this, 'updateDurationProgress', 150, true);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('polling').stop('time update');
    },
    updateDurationProgress: function updateDurationProgress() {
      if (this.get('appointmentStartedNotFinished')) {
        var start = (0, _moment.default)(this.get('appointment.started_at'));
        var diff = (0, _moment.default)().diff(start, 'minutes'); // duration can be zero - don't allow start/finish on 0 duration appointments
        // how does this work for all day appointments? - ????

        var duration = this.get('appointment.offered_service.duration_in_minutes');

        if (duration > 0) {
          var percentageComplete = diff / duration;

          if (percentageComplete > 1) {
            (0, _jquery.default)(this.element.querySelectorAll('.duration-overlay--progress-circle')).css('stroke-dashoffset', 0);
          } else {
            var pixelsCompleted = percentageComplete * 275;
            (0, _jquery.default)(this.element.querySelectorAll('.duration-overlay--progress-circle')).css('stroke-dashoffset', 275 - pixelsCompleted);
          }
        }
      }
    },
    actions: {
      edit: function edit() {
        this.edit();
      },
      clickPet: function clickPet() {
        this.clickPet(this.get('appointment.pet'));
      },
      delete: function _delete() {
        this.delete();
      },
      cancel: function cancel() {
        this.cancel();
      },
      restore: function restore() {
        this.restore();
      },
      addInstructions: function addInstructions() {
        this.addInstructions();
      },
      closeAppointmentDetails: function closeAppointmentDetails() {
        this.closeAppointmentDetails();
      },
      markComplete: function markComplete() {
        this.markComplete(this.get('appointment'));
      },
      markUncomplete: function markUncomplete() {
        this.markUncomplete(this.get('appointment'));
      },
      requestHousehold: function requestHousehold() {
        var _this2 = this;

        var householdId = this.get('appointment.pet.household.id');
        var email = this.get('appointment.pet.household.request_sent_to');

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(householdId, "/add_company"),
          method: 'POST',
          data: {
            id: householdId,
            email: email
          }
        }).done(function () {
          _this2.get('store').findRecord('household', householdId).then(function (household) {
            household.set('client_status', 'pending');
          });

          _this2.get('flashMessage').sendFlashMessage("Your request to connect was sent to ".concat(email), 'Success');
        });
      },
      archiveHovering: function archiveHovering(hovering) {
        if (hovering) {
          this.set('archiveIconUrl', 'assets/images/archive-icon-hover.svg');
        } else {
          this.set('archiveIconUrl', 'assets/images/archive-icon.svg');
        }
      },
      modifyHovering: function modifyHovering(hovering) {
        if (hovering) {
          this.set('modifyIconUrl', 'assets/images/edit-icon-red-hover.svg');
        } else {
          this.set('modifyIconUrl', 'assets/images/edit-icon-red.svg');
        }
      },
      reportCardHovering: function reportCardHovering(hovering) {
        if (hovering) {
          if (!this.get('appointment.grouped_report_card') || this.get('appointment.grouped_report_card.draft')) {
            this.set('reportCardIconUrl', 'assets/images/report-card/finish-report-card-hover.svg');
          } else {
            this.set('reportCardIconUrl', 'assets/images/report-card/view-report-card-hover.svg');
          }
        } else {
          if (!this.get('appointment.grouped_report_card') || this.get('appointment.grouped_report_card.draft')) {
            this.set('reportCardIconUrl', 'assets/images/report-card/finish-report-card.svg');
          } else {
            this.set('reportCardIconUrl', 'assets/images/report-card/view-report-card-icon.svg');
          }
        }
      },
      handleReportCard: function handleReportCard() {
        var _this3 = this;

        if (this.get('appointment.grouped_report_card')) {
          this.openReportCard(this.get('appointment.grouped_report_card'));
        } else {
          var grc = this.get('store').createRecord('grouped_report_card', {
            appt_id: this.get('appointment.id')
          });
          grc.save().then(function () {
            _this3.get('appointment.grouped_appointment.appointments').forEach(function (appt) {
              appt.set('grouped_report_card', grc);
            });

            _this3.openReportCard(grc);
          });
        }
      },
      startAppointment: function startAppointment() {
        this.startAppointment(this.get('appointment'));
        this.set('currentTime', (0, _moment.default)());
      },
      undoStartAppointment: function undoStartAppointment() {
        this.undoStartAppointment(this.get('appointment'));
      }
    }
  });

  _exports.default = _default;
});