define("frontend/templates/components/password-toggle", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "yqScLpeV",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[22,\"wrapperClazz\"]],[8],[0,\"\\n  \"],[7,\"button\",true],[11,\"class\",[22,\"buttonClazz\"]],[10,\"tabindex\",\"-1\"],[10,\"data-click\",\"pw-btn\"],[10,\"data-pw\",\"show\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[10,\"type\",\"button\"],[8],[0,\"Show\"],[9],[0,\"\\n  \"],[1,[28,\"input\",null,[[\"value\",\"type\",\"id\",\"class\",\"tabindex\",\"maxlength\",\"autocomplete\",\"placeholder\"],[[24,[\"password\"]],\"password\",[24,[\"inputId\"]],[24,[\"inputClazz\"]],[24,[\"tabindex\"]],[24,[\"maxlength\"]],[24,[\"autocomplete\"]],[24,[\"placeholder\"]]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/password-toggle.hbs"
    }
  });

  _exports.default = _default;
});