define("frontend/components/add-on-service-pick-pets-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    appointments: [],
    pets: [],
    appointmentsInfo: computed('appointments.[]', 'pets.[]', function () {
      if (this.get('appointments.length') > 0) {
        return this.get('appointments').map(function (appt) {
          return {
            id: appt.get('id'),
            avatarOrPlaceholder: appt.get('pet').get('avatarOrPlaceholder'),
            pet_name: appt.get('pet').get('name'),
            appointment: appt,
            selected: true
          };
        });
      } else {
        return this.get('pets').map(function (pet) {
          return {
            id: pet.get('id'),
            avatarOrPlaceholder: pet.get('avatarOrPlaceholder'),
            pet_name: pet.get('name'),
            pet: pet,
            selected: true
          };
        });
      }
    }),
    actions: {
      createAddOnServices: function createAddOnServices() {
        var selectedAppts = this.get('appointmentsInfo').filter(function (appt) {
          return appt.selected;
        });

        if (this.get('appointments.length') > 0) {
          var appointments = selectedAppts.map(function (appt) {
            return appt.appointment;
          });
          this.get('createAddOnServices')(appointments);
        } else {
          var pets = selectedAppts.map(function (appt) {
            return appt.pet;
          });
          this.get('createAddOnServices')(pets);
        }
      },
      onClose: function onClose() {
        this.get('close')();
      }
    }
  });

  _exports.default = _default;
});