define("frontend/models/distribution_message", ["exports", "frontend/models/message"], function (_exports, _message) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _message.default.extend({});

  _exports.default = _default;
});