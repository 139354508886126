define("frontend/routes/portal/invoices-summary/company", ["exports", "frontend/routes/base-route", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    deactivate: function deactivate() {
      this.set('controller.showAddPaymentModal', false);
    },
    model: function model(_ref) {
      var company_id = _ref.company_id;
      var company = this.store.findRecord('company', company_id);

      var settings = _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/users/get_client_companies?all=true"),
        method: 'GET'
      });

      return Ember.RSVP.hash({
        company: company,
        settings: settings
      });
    }
  });

  _exports.default = _default;
});