define("frontend/components/document-upload", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MAX_FILE_SIZE_MB = 5.0;

  var _default = Ember.Component.extend({
    classNames: ['document-upload'],
    type: null,
    contractAcceptedFileTypes: ['pdf', 'doc', 'docx'],
    onlyAcceptContractFiles: false,
    csvOnly: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      var applicationController = Ember.getOwner(this).lookup('controller:application');
      var self = this;
      (0, _jquery.default)(this.element.querySelectorAll('[name="filename"]')).on('change', function (evt) {
        var reader = new FileReader();

        reader.onload = function (e) {
          var fileToUpload = e.srcElement.result;
          Ember.run(function () {
            var mb = _this.getMb(fileToUpload.length);

            var fileName = (0, _jquery.default)(_this.element.querySelectorAll('[name="filename"]'))[0].files[0].name;
            var extension = fileName.split('.').pop().toLowerCase();
            var acceptedFileTypes = self.get('contractAcceptedFileTypes');

            if (mb > MAX_FILE_SIZE_MB) {
              applicationController.send('notify', {
                message: "File size must be less than ".concat(MAX_FILE_SIZE_MB, " MB"),
                type: 'user-error'
              });
            } else if (self.get('onlyAcceptContractFiles') && acceptedFileTypes.indexOf(extension) == -1) {
              applicationController.send('notify', {
                message: 'Sorry! Please upload your documents in PDF or Word.',
                type: 'user-error'
              });
            } else if (self.get('csvOnly') && extension !== 'csv') {
              applicationController.send('notify', {
                message: 'Sorry! Please upload your documents in CSV format.',
                type: 'user-error'
              });
            } else {
              var options = {
                type: _this.get('type')
              };

              _this.upload(fileToUpload, fileName, options);
            }
          });
        };

        reader.onerror = function () {
          applicationController.send('notify', {
            message: 'Oops! Something went wrong with your upload. Please try again!',
            type: 'error'
          });
        };

        return reader.readAsDataURL(evt.target.files[0]);
      });
    },
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(this.element.querySelectorAll('[name="filename"]')).off('change');
    },
    click: function click(e) {
      if (e.target.tagName !== 'INPUT') {
        (0, _jquery.default)(this.element.querySelectorAll('[name="filename"]')).click();
      }
    },
    getMb: function getMb(size) {
      return size / 4 * 3 / 1000000;
    }
  });

  _exports.default = _default;
});