define("frontend/templates/client-list/household-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+11ldO/a",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"mobile-header-flex\",null,[[\"showAdd\",\"clickAdd\"],[[24,[\"currentUser\",\"user\",\"isAdmin\"]],[28,\"route-action\",[\"transitionToAddClient\"],null]]],{\"statements\":[[0,\"    \"],[1,[28,\"search-input\",null,[[\"value\",\"debounce\",\"placeholder\"],[[24,[\"filter\"]],true,\"Search by human or pet\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"new-header\",null,[[\"classNames\",\"hasClose\",\"hasSideNav\"],[\"client-list-header\",false,true]],{\"statements\":[[0,\"    \"],[7,\"h1\",true],[10,\"class\",\"header-text\"],[8],[0,\"Clients\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"id\",\"header-wormhole\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[1,[22,\"household-not-found-error\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/client-list/household-error.hbs"
    }
  });

  _exports.default = _default;
});