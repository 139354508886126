define("frontend/templates/components/household-select/household-select-with-account-balance-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iUmDwBiZ",
    "block": "{\"symbols\":[\"pet\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"household-header\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[\"household-user-names \",[28,\"unless\",[[24,[\"household\",\"active\"]],\"inactive-household\"],null]]]],[8],[0,\"\\n    \"],[1,[24,[\"household\",\"owner\",\"fullName\"]],false],[4,\"if\",[[28,\"gt\",[[24,[\"household\",\"users\",\"length\"]],1],null]],null,{\"statements\":[[0,\",\\n      \"],[1,[24,[\"household\",\"secondaryContact\",\"fullName\"]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"unless\",[[24,[\"household\",\"active\"]]],null,{\"statements\":[[0,\"      \"],[7,\"span\",true],[10,\"class\",\"inactive-tag\"],[8],[0,\"\\n        (inactive)\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"account-balance\"],[8],[0,\"\\n    \"],[1,[28,\"format-money\",[[24,[\"accountBalance\"]]],[[\"precision\"],[2]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"pets\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"sortedPets\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"avatar-with-name\",null,[[\"imageUrl\",\"innerBorder\",\"name\"],[[23,1,[\"avatarOrPlaceholder\"]],true,[23,1,[\"name\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/household-select/household-select-with-account-balance-option.hbs"
    }
  });

  _exports.default = _default;
});