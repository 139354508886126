define("frontend/models/service", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = _emberData.default.Model.extend({
    timeZone: inject.service(),
    starts_at: _emberData.default.attr('string'),
    all_day_service: _emberData.default.attr('boolean'),
    care_provider: _emberData.default.belongsTo('care-provider', {
      async: true
    }),
    appointments: _emberData.default.hasMany('appointment', {
      async: true
    }),
    grouped_appointments: _emberData.default.hasMany('grouped-appointment', {
      async: true
    }),
    company_name: _emberData.default.attr('string'),
    company_email: _emberData.default.attr('string'),
    appointmentsWithRecurrence: computed('sortedGroupedAppointmentsWithoutCanceled.@each.appointmentIds', function () {
      var appts = [];
      this.get('sortedGroupedAppointmentsWithoutCanceled').forEach(function (groupedAppt) {
        // Trigger for intermediary computed property
        groupedAppt.get('appointmentIds');
        groupedAppt.get('appointments').forEach(function (appt) {
          if (appt.get('isRecurring')) {
            appts.push(appt);
          }
        });
      });
      return appts;
    }),
    max_offered_service_duration: computed('sortedGroupedAppointmentsWithoutCanceled.@each.offeredServiceDuratonInMinutes', function () {
      var durations = this.get('sortedGroupedAppointmentsWithoutCanceled').map(function (ga) {
        return ga.get('offered_service.duration_in_minutes');
      });
      return Math.max.apply(Math, _toConsumableArray(durations));
    }),
    sortedGroupedAppointments: computed('grouped_appointments.[]', 'grouped_appointments.@each.position', 'grouped_appointments.@each.hasUndeletedAppts', function () {
      var groupedAppts = this.get('grouped_appointments').filter(function (groupedAppt) {
        return groupedAppt.get('hasUndeletedAppts') == true;
      });
      return groupedAppts.sortBy('position', 'id');
    }),
    sortedGroupedAppointmentsWithoutCanceled: computed('sortedGroupedAppointments.[]', 'sortedGroupedAppointments.@each.{canceled}', function () {
      return this.get('sortedGroupedAppointments').filter(function (groupedAppt) {
        return !groupedAppt.get('canceled');
      });
    }),
    sortedCanceledGroupedAppointments: computed('sortedGroupedAppointments.[]', 'sortedGroupedAppointments.@each.{canceled}', function () {
      return this.get('sortedGroupedAppointments').filter(function (groupedAppt) {
        return groupedAppt.get('canceled');
      });
    }),
    // Intermediary computed property for nested arrays of services
    sortedGroupedAppointmentsWithoutCanceledIds: computed('sortedGroupedAppointments.[]', 'sortedGroupedAppointments.@each.id', 'sortedGroupedAppointments.@each.{canceled}', function () {
      return this.get('sortedGroupedAppointments').filter(function (groupedAppt) {
        return !groupedAppt.get('canceled');
      }).mapBy('id').join(', ');
    }),
    // Intermediary computed property for nested arrays of services
    sortedCanceledGroupedAppointmentsIds: computed('sortedGroupedAppointments.[]', 'sortedGroupedAppointments.@each.id', 'sortedGroupedAppointments.@each.{canceled}', function () {
      return this.get('sortedGroupedAppointments').filter(function (groupedAppt) {
        return groupedAppt.get('canceled');
      }).mapBy('id').join(', ');
    }),
    pixelHeight: computed('max_offered_service_duration', function () {
      var duration = this.get('max_offered_service_duration');

      if (duration < 30 || !duration) {
        duration = 30;
      }

      var heightCalc = duration / 60.0 * 128 - 3;
      return heightCalc;
    }),
    pixelStartsAt: computed('starts_at', function () {
      var minutes = parseInt((0, _moment.default)(this.get('starts_at')).format('mm')) / 60.0;
      var startsAtCalc = minutes * 128.0 + 2;
      return "".concat(startsAtCalc, "px");
    }),
    starts_at_time: computed('starts_at', function () {
      return _moment.default.parseZone(this.get('starts_at')).format('h:mm A');
    }),
    starts_at_date: Ember.computed('starts_at', function () {
      return _moment.default.parseZone(this.get('starts_at')).format('YYYY-MM-DD');
    })
  });

  _exports.default = _default;
});