define("frontend/templates/components/activated-field-text", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "maAv4mZ/",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[11,\"class\",[29,[\"mobile-text-input \",[28,\"if\",[[24,[\"borderBottom\"]],\"border-bottom\"],null],\" \",[28,\"if\",[[24,[\"borderTop\"]],\"border-top\"],null]]]],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"mobile-input-label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"mobile-input-value\"],[8],[1,[22,\"fieldText\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"clarificationText\"]]],null,{\"statements\":[[4,\"adaptive-clarification\",null,[[\"borderBottom\",\"borderTop\"],[false,false]],{\"statements\":[[0,\"      \"],[1,[22,\"clarificationText\"],true],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"input-label visible\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"field\"],[8],[0,\"\\n    \"],[1,[22,\"fieldText\"],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/activated-field-text.hbs"
    }
  });

  _exports.default = _default;
});