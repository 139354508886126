define("frontend/components/removed-auto-bill-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      selectOption: function selectOption(option) {
        this.get('onClose')(option);
      }
    }
  });

  _exports.default = _default;
});