define("frontend/components/client-summary", ["exports", "frontend/config/environment", "ember-data", "jquery"], function (_exports, _environment, _emberData, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['client-summary'],
    currentUser: Ember.inject.service(),
    summary: computed(function () {
      var promise = _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/households/summary"),
        method: 'GET'
      });

      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    })
  });

  _exports.default = _default;
});