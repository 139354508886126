define("frontend/components/week-select/day-select", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['day-select'],
    classNameBindings: ['active'],
    active: false,
    day: null,
    format: 'dd',
    weekDay: computed('day', 'format', function () {
      return (0, _moment.default)(this.get('day'), 'YYYY-MM-DD').format(this.get('format'));
    })
  });

  _exports.default = _default;
});