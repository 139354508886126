define("frontend/components/epayments-reminder-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    currentUser: inject.service(),
    reminderType: null,
    dismissable: computed.equal('reminderType', 'dismissable'),
    trialDaysLeft: computed('currentUser.user.company.free_trial_ends', function () {
      var trialEnds = this.get('currentUser.user.company.free_trial_ends');
      return moment(trialEnds).diff(moment(), 'days');
    }),
    actions: {
      onClose: function onClose() {
        this.dismissModal();
      },
      goToSetup: function goToSetup() {
        this.goToSetup();
      },
      openBeacon: function openBeacon() {
        this.openBeacon();
      }
    }
  });

  _exports.default = _default;
});