define("frontend/templates/components/mobile-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jQ+bKsKd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mobile-open-select-container\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"openSelect\"],null]],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mobile-input-label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"mobile-input-value\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"selectedLabel\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"mobile-label\"],[8],[1,[22,\"selectedLabel\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"placeholderLabel\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"mobile-label placeholder\"],[8],[1,[22,\"placeholderLabel\"],false],[9],[0,\"\\n    \"]],\"parameters\":[]},null]],\"parameters\":[]}],[4,\"unless\",[[24,[\"disabled\"]]],null,{\"statements\":[[0,\"      \"],[7,\"img\",true],[10,\"class\",\"dropdown-arrow\"],[10,\"src\",\"assets/images/down-arrow@3x.png\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"if\",[[24,[\"selectOpen\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"mobile-select/select-list\",null,[[\"chooseText\",\"options\",\"value\",\"clickOption\"],[[28,\"if\",[[24,[\"chooseText\"]],[24,[\"chooseText\"]],[24,[\"defaultChooseText\"]]],null],[24,[\"options\"]],[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"clickOption\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/mobile-select.hbs"
    }
  });

  _exports.default = _default;
});