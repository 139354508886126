define("frontend/components/dropdown-callout", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['dropdown-callout'],
    inputActivated: false,
    tabindex: 0,
    value: null,
    hint: null,
    hover: false,
    showHint: false,
    neverDisableInput: false,
    clickedCallout: false,
    disabled: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      (0, _jquery.default)(this.element).hover(function () {
        _this.set('hover', true);
      }, function () {
        _this.set('hover', false);
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)(this.element).off('hover');
    },
    willRender: function willRender() {
      this.send('resetActivated');
    },
    actions: {
      selectOption: function selectOption(value) {
        this.set('hover', false);

        if (this.selectOption) {
          this.selectOption(value);
        }
      },
      open: function open() {
        if (this.get('open')) {
          this.get('open').apply(void 0, arguments);
        }
      },
      resetActivated: function resetActivated() {
        var hasSelectedValue = this.get('value.length') > 0 || typeof this.get('value') === 'boolean' || typeof this.get('value') === 'number' || this.get('clickedCallout') || this.get('neverDisableInput');
        this.set('inputActivated', hasSelectedValue);
      },
      focusIn: function focusIn() {
        var _this2 = this;

        // This is a hack for opening and closing dropdown callouts
        Ember.run.scheduleOnce('afterRender', function () {
          Ember.run.later(function () {
            (0, _jquery.default)(_this2.element.querySelectorAll('.popout-dropdown .ember-power-select-trigger')).get(0).dispatchEvent(new MouseEvent('click'));
          }, 50);
        });
        this.set('clickedCallout', true);
        this.send('resetActivated');
      },
      focusOut: function focusOut() {
        this.set('clickedCallout', false);
        this.send('resetActivated');
      }
    }
  });

  _exports.default = _default;
});