define("frontend/components/photo-upload", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var inject = Ember.inject;
  var MAX_FILE_SIZE_MB = 15;

  var _default = Ember.Component.extend({
    classNames: ['photo-upload'],
    photo: null,
    placeholder: null,
    flashMessage: inject.service(),
    canUpload: true,
    aspectRatio: undefined,
    compressionRatio: 0.7,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.parseImage = this.parseImage.bind(this);
      (0, _jquery.default)(this.element.querySelectorAll('[name="filename"]')).on('change', function (evt) {
        var _evt$target$files = _slicedToArray(evt.target.files, 1),
            file = _evt$target$files[0];

        var mb = _this.getMb(file.size);

        var flashMessage = _this.get('flashMessage');

        if (mb > MAX_FILE_SIZE_MB) {
          flashMessage.error("Please upload an image smaller than ".concat(MAX_FILE_SIZE_MB, "MB!"));
        } else {
          _this.parseImage(evt.target.files[0]);
        }
      });
    },
    parseImage: function parseImage(file) {
      var _this2 = this;

      var aspectRatio = this.get('aspectRatio'); // We're only disabling webP file formats right now but should probably add
      // other restrictions later on

      if (file.type == 'image/webp') {
        this.get('flashMessage').error('Please try uploading your image in a different file format!');
        (0, _jquery.default)(this.element.querySelectorAll('[name="filename"]')).prop('value', '');
        return;
      }

      loadImage(file, function (imgOrCanvasOrEvent) {
        if (imgOrCanvasOrEvent.type === 'error' || imgOrCanvasOrEvent.tagName !== 'CANVAS') {
          _this2.get('flashMessage').errorWithoutRefresh('There was a problem uploading your image.');
        } else {
          var base64data = imgOrCanvasOrEvent.toDataURL(file.type, _this2.get('compressionRatio'));

          _this2.uploadedPhoto(base64data);
        }
      }, {
        // should be set to canvas : true to activate auto fix orientation
        canvas: true,
        orientation: true,
        aspectRatio: aspectRatio
      });
    },
    getMb: function getMb(size) {
      return size / 1000000;
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)(this.element.querySelectorAll('[name="filename"]')).off('change');
    },
    actions: {
      openImageSelect: function openImageSelect() {
        if (!this.get('canUpload')) {
          return;
        }

        (0, _jquery.default)("#".concat(this.elementId, " [name=\"filename\"]")).click();
      }
    }
  });

  _exports.default = _default;
});