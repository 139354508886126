define("frontend/controllers/companies/remove-service", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    companies: inject.controller(),
    store: inject.service(),
    router: inject.service(),
    saving: computed.alias('companies.saving'),
    currentRouteName: Ember.computed.readOnly('router.currentRouteName'),
    offeredService: computed('model.offered_service.id', function () {
      var id = this.get('model.offered_service.id');
      var promise = this.get('store').find('offered_service', id);
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    serviceName: computed('model.offered_service', function () {
      return "".concat(this.get('model.offered_service.name'), " (").concat(this.get('model.offered_service_duration'), ")");
    }),
    offeredServiceOptions: computed('model.offered_services', 'offeredService.is_add_on', function () {
      var _this = this;

      var services = this.get('model.offered_services');
      services = services.filter(function (service) {
        return service.offered_service.id != _this.get('model.offered_service.id');
      });

      if (this.get('offeredService.is_add_on')) {
        services = services.filter(function (service) {
          return service.offered_service.is_add_on;
        });
      } else {
        services = services.filter(function (service) {
          return !service.offered_service.is_add_on;
        });
      }

      return $.map(services, function (service) {
        return {
          label: "".concat(service.offered_service.name, " (").concat(service.offered_service.duration_display, ")"),
          value: service.offered_service.id,
          all_day_service: service.offered_service.all_day_service
        };
      });
    }),
    hasAppointments: computed('offeredService.is_add_on', 'model.grouped_appointments', 'model.recurring_appointments', 'model.appointment_requests', function () {
      if (this.get('offeredService.is_add_on')) {
        return this.get('addOnHasAppointments');
      } else {
        var appts = this.get('model.grouped_appointments') || [];
        var rec_appts = this.get('model.recurring_appointments') || [];
        var appt_requests = this.get('model.appointment_requests') || [];
        return appts.length > 0 || rec_appts.length > 0 || appt_requests.length > 0;
      }
    }),
    addOnHasAppointments: computed('model.recurring_appointment_add_ons', 'model.grouped_appointments', 'model.appointment_requests', function () {
      var appts = this.get('model.grouped_appointments') || [];
      var rec_appts = this.get('model.recurring_appointment_add_ons') || [];
      var appt_requests = this.get('model.appointment_requests') || [];
      return appts.length > 0 || rec_appts.length > 0 || appt_requests.length > 0;
    }),
    actions: {
      transitionToPricing: function transitionToPricing() {
        this.transitionToRoute('companies.pricing');
      },
      transitionToSingleEdit: function transitionToSingleEdit(id) {
        var queryParams = {
          backRoute: this.get('currentRouteName'),
          backHouseholdId: this.get('model.company_id'),
          backId: this.get('model.offered_service.id'),
          ids: [id]
        };
        this.transitionToRoute('edit-single-appointment.index', {
          queryParams: queryParams
        });
      },
      transitionToRecurringEdit: function transitionToRecurringEdit(id) {
        var queryParams = {
          backRoute: this.get('currentRouteName'),
          backHouseholdId: this.get('model.company_id'),
          backId: this.get('model.offered_service.id')
        };
        this.transitionToRoute('edit-recurring-appointment-group.index', id, {
          queryParams: queryParams
        });
      },
      transitionToRequestEdit: function transitionToRequestEdit(id) {
        var queryParams = {
          backRoute: this.get('currentRouteName'),
          backHouseholdId: this.get('model.company_id'),
          backId: this.get('model.offered_service.id'),
          requestId: id
        };
        this.transitionToRoute('edit-single-appointment.index', {
          queryParams: queryParams
        });
      },
      remove: function remove() {
        var _this2 = this;

        this.set('saving', true);
        this.get('offeredService').get('content').destroyRecord().then(function () {
          window.location.href = "/companies/".concat(_this2.get('model.company_id'), "/pricing");
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('companies.pricing');
      }
    }
  });

  _exports.default = _default;
});