define("frontend/app", ["exports", "frontend/resolver", "ember-load-initializers", "frontend/config/environment", "jquery"], function (_exports, _resolver, _emberLoadInitializers, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var App = Ember.Application.extend({
    modulePrefix: _environment.default.modulePrefix,
    podModulePrefix: _environment.default.podModulePrefix,
    Resolver: _resolver.default
  });

  if (Bugsnag) {
    (0, _jquery.default)(document).ajaxComplete(function (event, jqXHR) {
      var lastResponse = jqXHR.responseJSON;
      Bugsnag.metaData = {
        lastResponse: lastResponse
      };
    });
  }

  (0, _emberLoadInitializers.default)(App, _environment.default.modulePrefix);
  var _default = App;
  _exports.default = _default;
});