define("frontend/components/invoices-widget/sent-header-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['sent-header-dropdown'],
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      $(window).click(function (_ref) {
        var target = _ref.target;
        var $target = $(target);

        if ($target.parent().hasClass('sent-header') || $target.hasClass('dropdown-arrow')) {
          return;
        }

        _this.close();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      $(window).off('click');
    },
    actions: {
      selectOption: function selectOption(option) {
        this.get('selectOption')(option);
      }
    }
  });

  _exports.default = _default;
});