define("frontend/components/payments-widget/sent-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['payments-widget-header', 'sent-header'],
    tagName: 'header',
    options: ['all', 'card', 'bank', 'manual'],
    headerText: computed('paymentType', function () {
      return "".concat(this.get('paymentType'), " payments");
    }),
    actions: {
      selectOption: function selectOption(option) {
        this.set('showDropdown', false);
        this.get('selectOption')(option);
      }
    }
  });

  _exports.default = _default;
});