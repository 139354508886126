define("frontend/components/ppb-modal-scrollable", ["exports", "frontend/components/ppb-modal"], function (_exports, _ppbModal) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = _ppbModal.default.extend({
    media: inject.service(),
    targetAttachment: 'none',
    customContainerClassNames: '',
    wrapperClassNames: computed('media.isMobile', function () {
      var classNames = ['ppb-modal-scrollable-wrapper'];

      if (this.get('media.isMobile')) {
        classNames.push('mobile');
      }

      return classNames;
    }),
    containerClassNames: computed('media.isMobile', 'customContainerClassNames', function () {
      var classNames = ['ppb-modal', this.get('customContainerClassNames')];

      if (this.get('media.isMobile')) {
        classNames.push('mobile');
      }

      return classNames;
    }),
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      $('body').css('overflow', 'hidden');
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      $('body').css('overflow', 'auto');
    }
  });

  _exports.default = _default;
});