define("frontend/templates/components/bill-reminders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TbY7acIM",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"text-input-callout\",null,[[\"label\",\"value\",\"readonly\",\"hint\",\"showHint\",\"click\"],[\"Bill reminders\",[24,[\"frequencyName\"]],true,[28,\"hints-helper\",[\"gettingPaid.billReminders\"],null],true,[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"editingBillReminders\"]]],null],true],null]]]],false],[0,\"\\n\"],[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"mobile-gray-helper\",null,[[\"helperText\"],[[28,\"hints-helper\",[\"gettingPaid.billReminders\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"editingBillReminders\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"bill-reminders/bill-reminders-sidepanel\",null,[[\"companyInvoiceFrequencies\",\"close\"],[[24,[\"companyInvoiceFrequencies\"]],[28,\"action\",[[23,0,[]],\"closeSidePanel\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/bill-reminders.hbs"
    }
  });

  _exports.default = _default;
});