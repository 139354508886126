define("frontend/templates/components/client-profile-section-separator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ZpRKT+cz",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"client-profile-section-separator\"],[11,\"id\",[22,\"id\"]],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"client-profile-section-separator-title\"],[8],[0,\"\\n    \"],[1,[22,\"title\"],false],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"client-profile-section-separator-line\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[28,\"and\",[[24,[\"action\"]],[24,[\"actionIcon\"]]],null]],null,{\"statements\":[[0,\"    \"],[7,\"a\",false],[12,\"class\",\"edit-icon\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[24,[\"action\"]]]],[8],[0,\"\\n      \"],[7,\"img\",true],[11,\"src\",[22,\"actionIcon\"]],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/client-profile-section-separator.hbs"
    }
  });

  _exports.default = _default;
});