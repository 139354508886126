define("frontend/routes/portal/messages/index", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model: function model() {
      var _this$_super = this._super.apply(this, arguments),
          settings = _this$_super.settings;

      if (settings.get('length') > 0) {
        settings = settings.sortBy('last_message_time').reverse();
        var firstId = settings.get('firstObject.company_id');

        if (firstId) {
          return this.transitionTo('portal.messages.company', firstId);
        }
      }
    }
  });

  _exports.default = _default;
});