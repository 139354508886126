define("frontend/models/grouped-appointment", ["exports", "ember-data", "frontend/utils/string-util"], function (_exports, _emberData, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend({
    appointments: _emberData.default.hasMany('appointment', {
      async: true
    }),
    service: _emberData.default.belongsTo('service', {
      async: true
    }),
    offered_service: _emberData.default.belongsTo('offered-service', {
      async: true
    }),
    canceled: _emberData.default.attr('boolean'),
    can_delete: _emberData.default.attr('boolean'),
    company_id: _emberData.default.attr(),
    time_zone: _emberData.default.attr('string'),
    time: _emberData.default.attr('string'),
    special_instructions: _emberData.default.attr(),
    cancellation_notes: _emberData.default.attr(),
    cancelled_by: _emberData.default.attr(),
    selected: _emberData.default.attr('boolean'),
    position: _emberData.default.attr(),
    pickup_window: computed.alias('appointments.firstObject.pickup_window'),
    completed: computed('appointments.@each.completed', function () {
      return this.get('appointments').isEvery('completed', true);
    }),
    hasUndeletedAppts: computed('appointments.@each.isDeleted', function () {
      return this.get('appointments').filter(function (appt) {
        return !appt.get('isDeleted');
      }).length > 0;
    }),
    petNames: computed('sortedAppointments.@each.pet', function () {
      return _stringUtil.default.joinWithAnd(this.get('sortedAppointments').map(function (appt) {
        return appt.get('pet.name');
      }));
    }),
    offeredServiceDuratonInMinutes: computed.alias('offered_service.duration_in_minutes'),
    sortedAppointments: computed('appointments.[]', 'appointments.@each.canceled_at', 'appointments.@each.grouped_appointment', function () {
      var _this = this;

      // To cancel appointments from the schedule we reload the appointment but
      // not the grouped appointment so we need to make sure the appointments
      // still adhere to the canceled flag of the grouped appointment
      return this.get('appointments').filter(function (appt) {
        if (_this.get('canceled')) {
          return appt.get('canceled_at');
        } else {
          return !appt.get('canceled_at');
        }
      }).sortBy('pet.name');
    }),
    // Used as an intermediary computed property
    appointmentIds: computed('sortedAppointments.@each.id', function () {
      return this.get('sortedAppointments').mapBy('id').join(', ');
    }),
    hasStartedAppointments: computed('appointments.@each.started_at', function () {
      return this.get('appointments').toArray().some(function (appointment) {
        return appointment.get('started_at');
      });
    }),
    hasStartedAndUnfinishedAppointments: computed('appointments.@each.started_at', 'appointments.@each.finished_at', function () {
      return this.get('appointments').toArray().some(function (appointment) {
        return appointment.get('started_at') && !appointment.get('finished_at');
      });
    })
  });

  _exports.default = _default;
});