define("frontend/templates/components/svg-loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iHOTBJ3i",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[7,\"div\",true],[10,\"class\",\"loading-text\"],[8],[14,1],[9]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"loader\"],[8],[0,\"\\n  \"],[7,\"svg\",true],[10,\"class\",\"circular\"],[10,\"viewBox\",\"25 25 50 50\"],[8],[0,\"\\n    \"],[7,\"circle\",true],[10,\"class\",\"path\"],[10,\"cx\",\"50\"],[10,\"cy\",\"50\"],[10,\"r\",\"20\"],[10,\"fill\",\"none\"],[10,\"stroke-width\",\"2\"],[10,\"stroke-miterlimit\",\"10\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/svg-loading-spinner.hbs"
    }
  });

  _exports.default = _default;
});