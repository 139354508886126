define("frontend/components/quickbooks-effective-date-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    effectiveDate: null,
    passThruParams: computed(function () {
      var date = moment().format('YYYY-MM-DD');
      return {
        max: date
      };
    }),
    actions: {
      close: function close() {
        this.get('onClose')();
      },
      connect: function connect() {
        this.get('connect')(this.get('effectiveDate'));
      }
    }
  });

  _exports.default = _default;
});