define("frontend/templates/components/client-invoices-list/invoice-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Ni/+OVpr",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"invoice-item-header\",null,[[\"invoice\",\"invoiceAmount\"],[[24,[\"invoice\"]],[24,[\"invoice\",\"amount\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"active\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"blue-highlight\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"invoice-content\"],[8],[0,\"\\n  \"],[1,[28,\"invoice-tag\",null,[[\"invoice\"],[[24,[\"invoice\"]]]]],false],[0,\"\\n  \"],[1,[28,\"invoice-activity-date\",null,[[\"invoice\",\"showSettledOnDetails\"],[[24,[\"invoice\"]],false]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/client-invoices-list/invoice-item.hbs"
    }
  });

  _exports.default = _default;
});