define("frontend/templates/components/avatar-images-with-names", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JK6wB+Pw",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"avatar-images-with-names/avatar-images\",null,[[\"avatarables\"],[[24,[\"sortedAvatarables\"]]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"avatar-images-with-names__names\"],[8],[0,\"\\n  \"],[1,[22,\"names\"],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/avatar-images-with-names.hbs"
    }
  });

  _exports.default = _default;
});