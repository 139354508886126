define("frontend/validators/last-four-ssn", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var LastFourSsn = _base.default.extend({
    validate: function validate(value, options, model) {
      if (model.get('company.requires_last_four_ssn')) {
        if (!value || value === '' || value.length !== 4 || value !== '****' && !/^\d+$/.test(value)) {
          return options.message || 'Must be valid last 4 digits of a SSN';
        }
      }

      return true;
    }
  });

  LastFourSsn.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor: function getDependentsFor() {
      return [];
    }
  });
  var _default = LastFourSsn;
  _exports.default = _default;
});