define("frontend/templates/components/payments-widget/sent-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2VDGF3wA",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",false],[12,\"class\",[29,[\"payment-type-option \",[28,\"if\",[[28,\"eq\",[[24,[\"paymentType\"]],[23,1,[]]],null],\"selected\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"selectOption\",[23,1,[]]]],[8],[0,\"\\n      \"],[1,[23,1,[]],false],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"header-text\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showDropdown\"]]],null],true],null]],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n    \"],[1,[22,\"headerText\"],false],[7,\"div\",true],[10,\"class\",\"dropdown-arrow down\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showDropdown\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"payments-widget/sent-header-dropdown\",null,[[\"close\",\"selectOption\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showDropdown\"]]],null],false],null],[28,\"action\",[[23,0,[]],\"selectOption\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/payments-widget/sent-header.hbs"
    }
  });

  _exports.default = _default;
});