define("frontend/templates/components/message-thread/household-select/distribution-list-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "be6LN/6S",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"household-selection \",[28,\"if\",[[28,\"and\",[[28,\"not\",[[28,\"media\",[\"isMobile\"],null]],null],[24,[\"selected\"]]],null],\"selected\"],null]]]],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"hasNewMessage\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"red-dot\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"household-avatars\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"border\"],[8],[9],[0,\"\\n    \"],[7,\"img\",true],[10,\"class\",\"primary-avatar\"],[11,\"src\",[29,[[22,\"avatarImg\"]]]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"right-side-info\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"top-line-info\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"household-name\"],[8],[0,\"\\n        Group Announcements\\n        \"],[7,\"img\",true],[10,\"src\",\"assets/images/pushpin.svg\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"last-message-time\"],[8],[0,\"\\n        \"],[1,[28,\"date-or-time-format\",[[24,[\"thread\",\"created_at\"]]],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"last-message-line\"],[8],[0,\"\\n      \"],[1,[24,[\"thread\",\"message\"]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/message-thread/household-select/distribution-list-select.hbs"
    }
  });

  _exports.default = _default;
});