define("frontend/templates/components/search-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wGmq/RCh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"unless\",[[24,[\"searchButton\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"graySearchIcon\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\",true],[10,\"src\",\"assets/images/gray-search-icon@3x.png\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[7,\"img\",true],[10,\"src\",\"assets/images/search-icon@3x.png\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[]},null],[4,\"if\",[[24,[\"debounce\"]]],null,{\"statements\":[[0,\"  \"],[7,\"input\",true],[11,\"value\",[22,\"value\"]],[11,\"placeholder\",[22,\"placeholder\"]],[11,\"onInput\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"onInput\"],null]],null]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"input\",null,[[\"value\",\"placeholder\",\"action\"],[[24,[\"value\"]],[24,[\"placeholder\"]],\"search\"]]],false],[0,\"\\n\"]],\"parameters\":[]}],[4,\"if\",[[24,[\"searchButton\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",\"search-button\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"search\"]],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"src\",\"assets/images/white-search-icon.svg\"],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/search-input.hbs"
    }
  });

  _exports.default = _default;
});