define("frontend/controllers/portal/profile", ["exports", "frontend/utils/scroll-util"], function (_exports, _scrollUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;
  var NOTES_SCROLL = '.notes-output header';
  var MEDICAL_SCROLL = '.medical-output header';
  var BEHAVIORAL_SCROLL = '.behavioral-output header';
  var TEXT_HEIGHT = 20;
  var MARGIN_TOP = 40;

  var _default = Ember.Controller.extend({
    queryParams: ['addedClient'],
    addedClient: false,
    currentUser: inject.service(),
    media: inject.service(),
    household: computed.readOnly('currentUser.user.household'),
    selectedSection: 'notes',
    notesActive: computed.equal('selectedSection', 'notes'),
    medicalActive: computed.equal('selectedSection', 'medical'),
    behavioralActive: computed.equal('selectedSection', 'behavioral'),
    selectedUser: computed('household', 'household.owner', function () {
      if (this.get('household')) {
        return this.get('household.owner');
      } else {
        return null;
      }
    }),
    mobileNavText: 'Family Profile',
    selectedHuman: computed('selectedUser', function () {
      return this.get('selectedUser.modelName') == 'household-user';
    }),
    selectedPet: computed('selectedUser', function () {
      return this.get('selectedUser.modelName') == 'pet';
    }),
    scrollTo: function scrollTo(selector) {
      var headerHeight = document.querySelector('.new-header').clientHeight - MARGIN_TOP;
      var topOfElement = document.querySelector(selector).offsetTop - headerHeight;
      document.querySelector('.page-wrap').scroll({
        top: topOfElement
      });
    },
    actions: {
      clickPetOrUser: function clickPetOrUser(petOrUser) {
        this.set('selectedUser', petOrUser);

        if (this.get('selectedPet')) {
          if (this.get('selectedUser.hasCompanyNotes')) {
            this.set('selectedSection', 'notes');
          } else if (this.get('selectedUser.hasBehavioral')) {
            this.set('selectedSection', 'behavioral');
          } else {
            this.set('selectedSection', 'medical');
          }
        }
      },
      clickSection: function clickSection(section) {
        this.set('selectedSection', section);
      },
      clickMedical: function clickMedical() {
        this.scrollTo(MEDICAL_SCROLL);
        var self = this;
        setTimeout(function () {
          self.set('selectedSection', 'medical');
        }, 50);
      },
      clickBehavioral: function clickBehavioral() {
        this.scrollTo(BEHAVIORAL_SCROLL);
        var self = this;
        setTimeout(function () {
          self.set('selectedSection', 'behavioral');
        }, 50);
      },
      clickNotes: function clickNotes() {
        this.scrollTo(NOTES_SCROLL);
        var self = this;
        setTimeout(function () {
          self.set('selectedSection', 'notes');
        }, 50);
      },
      closeSuccessModal: function closeSuccessModal() {
        this.set('addedClient', false);
      },
      scroll: function scroll() {
        if (this.get('media.isMobile')) {
          return;
        }

        var headerHeight = document.querySelector('.new-header').clientHeight - TEXT_HEIGHT;

        if (_scrollUtil.default.isScrolledIntoView(NOTES_SCROLL, headerHeight)) {
          this.set('selectedSection', 'notes');
        } else if (_scrollUtil.default.isScrolledIntoView(BEHAVIORAL_SCROLL, headerHeight)) {
          this.set('selectedSection', 'behavioral');
        } else if (_scrollUtil.default.isScrolledIntoView(MEDICAL_SCROLL, headerHeight)) {
          this.set('selectedSection', 'medical');
        }
      },
      setMobileNavText: function setMobileNavText(text) {
        this.set('mobileNavText', text);
      }
    }
  });

  _exports.default = _default;
});