define("frontend/routes/invoices", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = _baseRoute.default.extend({
    queryParams: {
      filter: {
        refreshModel: true,
        replace: true
      }
    },
    infinity: inject.service(),
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);

      if (this.get('controller')) {
        this.set('controller.loadingRightSidePanel', true);
      }
    },
    model: function model(params) {
      return this.get('infinity').model('household', {
        perPage: 10,
        startingPage: 1,
        filter: params.filter,
        include_account_balance: true
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('loadingRightSidePanel', false);
    },
    actions: {
      loading: function loading() {
        return !this.get('controller');
      },
      selectHousehold: function selectHousehold(household) {
        this.transitionTo('invoices-summary', household.id);
      },
      selectHouseholdById: function selectHouseholdById(householdId) {
        this.transitionTo('invoices-summary', householdId);
      },
      clickInvoice: function clickInvoice(invoice) {
        this.transitionTo('invoices-summary.invoice-detail', invoice.get('household.id'), invoice.id);
      },
      clickPayment: function clickPayment(payment) {
        this.transitionTo('invoices-summary.payment-detail', payment.get('household_id'), payment.id);
      },
      parentInfinityLoad: function parentInfinityLoad() {
        this._infinityLoad();
      }
    }
  });

  _exports.default = _default;
});