define("frontend/templates/components/flash-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HUl5LgqP",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[14,1],[0,\"\\n\"],[4,\"if\",[[24,[\"showOverlay\"]]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"overlay-container\"]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"overlay\"],[8],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/flash-notification.hbs"
    }
  });

  _exports.default = _default;
});