define("frontend/components/invoices-widget/invoices-group", ["exports", "frontend/utils/invoice-type"], function (_exports, _invoiceType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['invoices-group'],
    tagName: 'section',
    store: inject.service(),
    collapsed: false,
    classNameBindings: ['collapsed'],
    isDraft: computed.equal('invoiceType', _invoiceType.default.DRAFT),
    isSent: computed.equal('invoiceType', _invoiceType.default.SENT),
    isDue: computed.equal('invoiceType', _invoiceType.default.DUE),
    isPaid: computed.equal('invoiceType', _invoiceType.default.PAID),
    page: 1,
    per: 10,
    reachedInfinity: false,
    isFetching: false,
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.set('invoices', []);
    },
    queryInvoices: function queryInvoices() {
      var invoicesGroup = this.get('invoicesGroup');
      var date = invoicesGroup.get('end_date');
      var invoice_type = this.get('invoiceType');
      var page = this.get('page');
      var per = this.get('per');
      return this.get('store').query('invoice', {
        page: page,
        per: per,
        date: date,
        invoice_type: invoice_type
      });
    },
    actions: {
      toggleCollapsed: function toggleCollapsed() {
        this.toggleProperty('collapsed');
      },
      clickInvoice: function clickInvoice() {
        this.get('clickInvoice').apply(void 0, arguments);
      },
      infinityLoad: function infinityLoad() {
        var _this = this;

        if (this.get('isFetching')) {
          return;
        }

        this.set('isFetching', true);
        this.queryInvoices().then(function (invoices) {
          _this.set('reachedInfinity', _this.get('page') >= invoices.get('meta.total_pages'));

          _this.get('invoices').pushObjects(invoices.toArray());
        }).finally(function () {
          _this.set('isFetching', false);

          _this.incrementProperty('page');
        });
      }
    }
  });

  _exports.default = _default;
});