define("frontend/mixins/admin/approvable-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

  function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Mixin.create({
    filter: '',
    sortBy: null,
    sortAsc: true,
    ajax: inject.service(),
    infinity: inject.service(),
    flashMessage: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    modelOptions: {},
    totalUnapproved: null,
    submitReplace: function submitReplace(url, replace, old) {
      var _this = this;

      this.set('editingReplace', null);
      this.set('savedReplace', null);
      this.get('ajax').request(url, {
        method: 'PUT',
        data: {
          new_id: replace.get('id')
        }
      }).then(function () {
        old.deleteRecord();
      }).catch(function () {
        _this.get('flashMessage').error('Unable to replace!');
      });
    },
    filteredRecords: computed('filter', function () {
      var _this2 = this;

      var options = {
        perPage: 25,
        filter: this.get('filter')
      };
      options = _objectSpread(_objectSpread({}, options), this.get('modelOptions'));
      var records = this.get('infinity').model(this.get('modelName'), options);
      records.then(function (res) {
        var unapprovedCount = res.meta.total_unapproved;
        if (unapprovedCount) _this2.set('totalUnapproved', " - ".concat(unapprovedCount, " unapproved"));
      });
      return records;
    }),
    sortedRecords: computed.sort('filteredRecords', ['filteredRecords.[]', 'sortBy', 'sortAsc'], function (x, y) {
      if (this.sortBy) {
        var first, second;

        if (this.sortBy == 'vet_names') {
          first = x.get(this.sortBy).map(function (vet) {
            return vet.toLowerCase();
          });
          second = y.get(this.sortBy).map(function (vet) {
            return vet.toLowerCase();
          });
        } else {
          first = x.get(this.sortBy);

          if (first) {
            first = first.toLowerCase();
          }

          second = y.get(this.sortBy);

          if (second) {
            second = second.toLowerCase();
          }
        }

        if (this.sortAsc) {
          return first < second ? -1 : 1;
        } else {
          return first < second ? 1 : -1;
        }
      } else {
        return 0;
      }
    }),
    approvedRecords: computed('modelName', function () {
      return this.get('store').query(this.get('modelName'), {});
    }),
    actions: {
      approve: function approve(record) {
        record.set('approved_at', moment());
        record.save();
      },
      search: function search(e) {
        var _this3 = this;

        Ember.run.debounce(function () {
          _this3.set('filter', e.target.value);
        }, 10);
      },
      onCloseReplace: function onCloseReplace() {
        this.set('savedReplace', null);
        this.set('editingReplace', null);
      },
      unapprove: function unapprove(record) {
        record.set('approved_at', null);
        record.save();
      },
      save: function save(record) {
        var _this4 = this;

        record.set('editing', false);
        record.save().catch(function () {
          _this4.get('flashMessage').error('Unable to save!');
        });
      },
      remove: function remove(record) {
        var _this5 = this;

        record.destroyRecord().then(function () {
          _this5.get('flashMessage').success("You've removed ".concat(record.get('name')));
        });
      },
      rollback: function rollback(record) {
        record.set('editing', false);
        record.rollbackAttributes();
      },
      sort: function sort(key) {
        if (this.sortBy == key) {
          this.toggleProperty('sortAsc');
        } else {
          this.set('sortBy', key);
          this.set('sortAsc', true);
        }
      }
    }
  });

  _exports.default = _default;
});