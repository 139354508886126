define("frontend/templates/portal/messages/company", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YGudjbIP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"mobile-header-flex\",null,[[\"showAdd\",\"type\"],[false,\"white\"]],{\"statements\":[[0,\"    \"],[7,\"h1\",true],[10,\"class\",\"header-text\"],[8],[0,\"Messages\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"new-header\",null,[[\"flex\",\"classNames\",\"hasClose\",\"hasSideNav\"],[true,\"client-list-header\",false,true]],{\"statements\":[[0,\"    \"],[7,\"h1\",true],[10,\"class\",\"header-text\"],[8],[0,\"Messages\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"],[4,\"if\",[[24,[\"showReportCard\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"view-grouped-report-card\",null,[[\"groupedReportCard\",\"loading\",\"onClose\"],[[24,[\"selectedReportCard\"]],[24,[\"loadingReportCard\"]],[28,\"action\",[[23,0,[]],\"closeReportCard\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"message-thread\",null,[[\"messages\",\"company\",\"showingCompanySelection\",\"hasSidePanel\",\"household\",\"callbackAfterSentMessage\",\"goToAppointmentRequest\"],[[24,[\"model\",\"messages\"]],[24,[\"model\",\"company\"]],[24,[\"hasMultipleCompanies\"]],[24,[\"hasMultipleCompanies\"]],[24,[\"household\"]],[28,\"action\",[[23,0,[]],\"onSentMessage\"],null],[28,\"route-action\",[\"goToAppointmentRequest\"],null]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"hasMultipleCompanies\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"message-thread/company-select\",null,[[\"companies\",\"selectedCompany\",\"companySelected\"],[[24,[\"companies\"]],[24,[\"model\",\"company\"]],[28,\"action\",[[23,0,[]],\"switchCompany\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/portal/messages/company.hbs"
    }
  });

  _exports.default = _default;
});