define("frontend/services/add-client-saving", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Service.extend({
    errorHandler: inject.service(),
    saving: false,
    savePet: function savePet(pet) {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        pet.save().then(function (savedPet) {
          resolve(savedPet);
        }).catch(function (error) {
          _this.get('errorHandler').handle(error);

          reject(error);
        });
      });
    },
    saveUser: function saveUser(user) {
      var _this2 = this;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        user.save().then(function (savedUser) {
          savedUser.get('phone_numbers').filterBy('id', null).invoke('deleteRecord');
          savedUser.get('emails').filterBy('id', null).invoke('deleteRecord');
          savedUser.get('addresses').filterBy('id', null).invoke('deleteRecord');
          resolve(savedUser);
        }).catch(function (error) {
          _this2.get('errorHandler').handle(error);

          reject(error);
        });
      });
    }
  });

  _exports.default = _default;
});