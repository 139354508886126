define("frontend/components/accept-tos-modal", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['modal', 'fade', 'in'],
    attributeBindings: ['dataBackdrop:data-backdrop'],
    dataBackdrop: 'static',
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)(this.element).modal('show');
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      (0, _jquery.default)(this.element).modal('hide');
    },
    actions: {
      acceptTOS: function acceptTOS() {
        this.get('acceptTOS')();
      },
      declineTOS: function declineTOS() {
        this.get('declineTOS')();
      }
    }
  });

  _exports.default = _default;
});