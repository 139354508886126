define("frontend/templates/add-client/client-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7JPgqWjo",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showInviteClient\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"invite-client-modal\",null,[[\"email\",\"onSubmit\",\"onClose\"],[[24,[\"searchedEmail\"]],[28,\"action\",[[23,0,[]],\"inviteClient\"],null],[28,\"action\",[[23,0,[]],\"closeInviteClient\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"add-client/header-text\",null,[[\"text\"],[\"Search\"]]],false],[0,\"\\n\\n\"],[1,[28,\"user-search-searchbar\",null,[[\"defaultEmail\",\"setEmail\",\"search\"],[[24,[\"email\"]],[28,\"action\",[[23,0,[]],\"setEmail\"],null],[28,\"action\",[[23,0,[]],\"search\"],null]]]],false],[0,\"\\n\"],[1,[28,\"user-search-list\",null,[[\"searchTerm\",\"searchNow\",\"showInviteClient\",\"transitionToPrimary\"],[[24,[\"filter\"]],[24,[\"searchNow\"]],[28,\"action\",[[23,0,[]],\"triggerInviteClient\"],null],[28,\"action\",[[23,0,[]],\"transitionToPrimary\"],null]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/add-client/client-search.hbs"
    }
  });

  _exports.default = _default;
});