define("frontend/components/custom-pricing-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    offeredService: computed.readOnly('editingPrice.offeredService'),
    clientPrice: computed.alias('editingPrice.offeredServiceClientPrice'),
    actions: {
      onClose: function onClose() {
        if (this.get('clientPrice')) {
          this.get('clientPrice').rollbackAttributes();
        }

        this.get('onClose')();
      },
      editPrice: function editPrice(offeredServicePrice) {
        this.set('editingPrice', offeredServicePrice); // existingPrice is used to destroy when we reset prices

        this.set('existingPrice', this.get('clientPrice'));
        var price = this.get('offeredService.price');
        var incremental_price = this.get('offeredService.incremental_price');
        var effective_date = 'current';

        if (this.get('clientPrice')) {
          price = this.get('clientPrice.price');
          incremental_price = this.get('clientPrice.incremental_price');
          effective_date = this.get('clientPrice.effective_date');
        }

        offeredServicePrice.set('offeredServiceClientPrice', this.get('store').createRecord('offered-service-client-price', {
          price: price,
          incremental_price: incremental_price,
          effective_date: effective_date,
          offered_service: this.get('offeredService'),
          household: this.get('household'),
          existingPrice: this.get('existingPrice')
        }));
      },
      closeEditPrice: function closeEditPrice() {
        this.get('clientPrice').rollbackAttributes();
        this.set('editingPrice', null);
      },
      resetPrices: function resetPrices() {
        var _this = this;

        this.set('saving', true);
        this.get('clientPrice').rollbackAttributes();
        this.get('existingPrice').destroyRecord().then(function () {
          return _this.get('household.content').reload();
        }).finally(function () {
          _this.set('saving', false);
        });
        this.set('editingPrice', null);
      },
      resetAllPrices: function resetAllPrices() {
        var _this2 = this;

        this.set('saving', true);
        var promises = [];
        this.get('household.offered_service_client_prices').forEach(function (price) {
          promises.pushObject(price.destroyRecord());
        });
        Ember.RSVP.Promise.all(promises).then(function () {
          return _this2.get('household.content').reload();
        }).finally(function () {
          _this2.set('saving', false);
        });
      },
      savePrice: function savePrice() {
        var _this3 = this;

        var clientPrice = this.get('clientPrice'); // Don't allow creation of a new custom price for the current offered service price

        if (this.get('clientPrice.price') === this.get('offeredService.price') && this.get('clientPrice.incremental_price') === this.get('offeredService.incremental_price') && this.get('clientPrice.isNew')) {
          clientPrice.rollbackAttributes();
        } else {
          this.set('saving', true);
          clientPrice.save().then(function () {
            return _this3.get('household.content').reload();
          }).finally(function () {
            _this3.set('saving', false);
          });
        }

        this.set('editingPrice', null);
      }
    }
  });

  _exports.default = _default;
});