define("frontend/routes/add-staff", ["exports", "frontend/mixins/cleanup-on-transition", "frontend/utils/email-type", "frontend/routes/base-route", "moment"], function (_exports, _cleanupOnTransition, _emailType, _baseRoute, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_cleanupOnTransition.default, {
    model: function model(params) {
      var searchedEmail = params.searchedEmail;
      var offered_service_amounts = [];
      var user = this.store.createRecord('user');
      var start_date = (0, _moment.default)().format('YYYY-MM-DD');

      if (searchedEmail) {
        var defaultEmail = this.store.createRecord('email', {
          address: searchedEmail,
          email_type: _emailType.default.PREFERRED
        });
        user.get('emails').pushObject(defaultEmail);
      }

      return this.store.createRecord('employee', {
        offered_service_amounts: offered_service_amounts,
        user: user,
        start_date: start_date
      });
    },
    actions: {
      transitionToStaff: function transitionToStaff() {
        var employeeFilter = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'all';
        this.transitionTo('staff.index', {
          queryParams: {
            employeeFilter: employeeFilter
          }
        });
      },
      redirectToAddedUser: function redirectToAddedUser(_, staffId) {
        var _this = this;

        this.store.findAll('employee').then(function (employees) {
          employees.forEach(function (employee) {
            if (employee.id == staffId) {
              _this.transitionTo('edit-staff', employee);
            }
          });
        });
      },
      redirectToUser: function redirectToUser(_, userId) {
        var _this2 = this;

        this.store.findAll('employee').then(function (employees) {
          employees.forEach(function (employee) {
            if (employee.get('user').get('id') == userId) {
              _this2.transitionTo('staff.index', {
                queryParams: {
                  employeeFilter: userId
                }
              });

              return;
            }
          });
        });
      },
      redirectToSearch: function redirectToSearch(email) {
        this.transitionTo('staff-search', {
          queryParams: {
            email: email
          }
        });
      }
    }
  });

  _exports.default = _default;
});