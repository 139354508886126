define("frontend/controllers/staff-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['householdId', 'email'],
    householdId: null,
    email: null,
    actions: {
      setEmail: function setEmail(email) {
        this.set('filter', email);
      },
      search: function search() {
        this.set('searchNow', true);
      }
    }
  });

  _exports.default = _default;
});