define("frontend/templates/components/input-callout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "VPdppske",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\",true],[11,\"class\",[29,[\"input-label \",[28,\"if\",[[24,[\"inputActivated\"]],\"visible\"],null]]]],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"inputActivated\"]]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"callout\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"clickCalloutLabel\"],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[1,[22,\"callout\"],false],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/input-callout.hbs"
    }
  });

  _exports.default = _default;
});