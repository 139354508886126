define("frontend/templates/components/mobile-text-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zdzXyxNd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"mobile-input-label\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"readonly\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"mobile-input-value readonly\"],[8],[1,[22,\"value\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[28,\"input\",null,[[\"value\",\"readonly\",\"disabled\",\"type\",\"classNames\"],[[24,[\"value\"]],[24,[\"readonly\"]],[24,[\"disabled\"]],[24,[\"type\"]],\"mobile-input-value\"]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/mobile-text-input.hbs"
    }
  });

  _exports.default = _default;
});