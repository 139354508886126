define("frontend/routes/invoices-summary", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = _baseRoute.default.extend({
    infinity: inject.service(),
    model: function model(_ref) {
      var household_id = _ref.household_id;
      var household = this.store.findRecord('household', household_id);
      var invoiceListItems = this.get('infinity').model('invoice-list-item', {
        household_id: household_id,
        perPage: 10,
        startingPage: 1
      });
      return Ember.RSVP.hash({
        household: household,
        invoiceListItems: invoiceListItems
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('deletingPayment', null);
    },
    actions: {
      cancel: function cancel() {
        var _this = this;

        this.set('controller.transitioning', true);
        this.transitionTo('invoices').finally(function () {
          return _this.set('controller.transitioning', false);
        });
      },
      clickInvoice: function clickInvoice(invoice) {
        this.transitionTo('invoices-summary.invoice-detail', invoice.id);
      },
      clickPayment: function clickPayment(payment) {
        this.transitionTo('invoices-summary.payment-detail', payment.id);
      },
      refresh: function refresh() {
        this.get('controller.model.household').reload();
        this.refresh();
      },
      goBack: function goBack() {
        if (this.get('controller.backRoute')) {
          this.transitionTo(this.get('controller.backRoute'), this.get('controller.backHouseholdId'), this.get('controller.backId'));
        } else {
          var queryParams = {
            clickedIntoSearch: false
          };
          this.transitionTo('invoices', {
            queryParams: queryParams
          });
        }
      },
      clickGear: function clickGear() {
        this.transitionTo('client-list.household.billing', this.get('controller.model.household'));
      }
    }
  });

  _exports.default = _default;
});