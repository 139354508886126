define("frontend/templates/inbox/distribution-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "g253lWeA",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"ember-wormhole\",null,[[\"to\"],[\"header-first-names\"]],{\"statements\":[[0,\"    Group Announcements\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[1,[28,\"message-thread\",null,[[\"messages\",\"company\",\"showingCompanySelection\",\"isInDistributionListThread\"],[[24,[\"model\"]],[24,[\"currentUser\",\"user\",\"company\"]],false,true]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/inbox/distribution-list.hbs"
    }
  });

  _exports.default = _default;
});