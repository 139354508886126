define("frontend/components/activity-log-side-panel", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      observer = Ember.observer;

  var _default = Ember.Component.extend({
    classNames: ['activity-log-side-panel'],
    activityLogs: Ember.inject.service(),
    storedLogDates: [],
    unreadAfter: computed.alias('activityLogs.unreadAfter'),
    lastSeen: null,
    loadedAll: computed.alias('activityLogs.loadedAll'),
    loadingMore: computed.not('loadedAll'),
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments); // eslint-disable-next-line no-undef


      this.set('uniqueLogIds', new Set());
      this.get('filterChanged');
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.element.addEventListener('mouseenter', this.handleMouseEnter);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
    },
    filterChanged: observer('activityLogs.filter', function () {
      this.set('storedLogDates', []); // eslint-disable-next-line no-undef

      this.set('uniqueLogIds', new Set());
    }),
    logDates: computed('logs', 'logs.[]', function () {
      var logs = this.get('logs');
      var keys = Object.keys(logs);
      var uniqueLogIds = this.get('uniqueLogIds');
      keys.sort().reverse();
      var logDates = this.get('storedLogDates');
      keys.forEach(function (date) {
        var logDate = logDates.filter(function (storedLog) {
          return storedLog.date == date;
        });

        if (logDate.length > 0) {
          logDate = logDate[0];
          logs[date].forEach(function (log) {
            if (logDate.logs.filter(function (logDateLog) {
              return logDateLog.id == log.id;
            }).length == 0) {
              logDate.logs.pushObject(log);
            }
          });
        } else {
          var newLogDate = {
            date: date,
            logs: []
          };
          logs[date].forEach(function (log) {
            if (!uniqueLogIds.has(log.id)) {
              uniqueLogIds.add(log.id);
              newLogDate.logs.pushObject(log);
            }
          });
          logDates.pushObject(newLogDate);
        }
      });
      logDates.forEach(function (logDate, index) {
        return logDates.set("".concat(index, ".logs"), logDate.logs.sortBy('created_at').reverse());
      });

      if (logDates[0]) {
        var lastSeen = logDates[0].logs[0].created_at;

        if (this.get('lastSeen') != lastSeen) {
          this.set('lastSeen', lastSeen);

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/activity_logs/record_last_seen"),
            method: 'POST',
            data: {
              last_seen: lastSeen
            }
          });
        }
      }

      return logDates;
    }),
    handleMouseEnter: function handleMouseEnter(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
    },
    actions: {
      closeActivityLog: function closeActivityLog() {
        this.get('closeActivityLog')();
      },
      loadMoreLogs: function loadMoreLogs() {
        var activityLogs = this.get('activityLogs');

        if (activityLogs.loadedAll) {
          return;
        }

        this.get('activityLogs').pullLogs();
      }
    }
  });

  _exports.default = _default;
});