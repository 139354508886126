define("frontend/components/request-cut-off-policy-modal", ["exports", "frontend/mixins/money-formater"], function (_exports, _moneyFormater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend(_moneyFormater.default, {
    store: inject.service(),
    serviceCategory: null,
    cutOffPolicy: null,
    afterMethod: computed.equal('cutOffPolicy.method', 'after'),
    withinMethod: computed.equal('cutOffPolicy.method', 'within'),
    timeDropdownOptions: {
      interval: 30,
      dynamic: false,
      dropdown: true,
      scrollbar: true,
      minTime: '12:00AM',
      maxTime: '11:30PM',
      startTime: '12:00AM',
      timeFormat: 'h:mm p',
      momentFormat: 'h:mm A'
    },
    daysBeforeOptions: [{
      label: 'on the day of',
      value: '0'
    }, {
      label: 'the day before',
      value: '1'
    }, {
      label: '2 days before',
      value: '2'
    }, {
      label: '3 days before',
      value: '3'
    }, {
      label: '4 days before',
      value: '4'
    }, {
      label: '5 days before',
      value: '5'
    }, {
      label: '6 days before',
      value: '6'
    }, {
      label: '7 days before',
      value: '7'
    }, {
      label: '8 days before',
      value: '8'
    }, {
      label: '9 days before',
      value: '9'
    }, {
      label: '10 days before',
      value: '10'
    }, {
      label: '11 days before',
      value: '11'
    }, {
      label: '12 days before',
      value: '12'
    }, {
      label: '13 days before',
      value: '13'
    }, {
      label: '14 days before',
      value: '14'
    }, {
      label: '30 days before',
      value: '30'
    }],
    selectedDaysBefore: computed('cutOffPolicy.days_before', function () {
      var _this = this;

      var options = this.get('daysBeforeOptions');
      var selected = options.filter(function (option) {
        return _this.get('cutOffPolicy.days_before') == option.value;
      });
      return selected.get('firstObject');
    }),
    withinOptions: [{
      label: '30 minutes',
      value: '30'
    }, {
      label: '1 hour',
      value: '60'
    }, {
      label: '1 hour 30 minutes',
      value: '90'
    }, {
      label: '2 hours',
      value: '120'
    }, {
      label: '4 hours',
      value: '240'
    }, {
      label: '6 hours',
      value: '360'
    }, {
      label: '8 hours',
      value: '480'
    }, {
      label: '10 hours',
      value: '600'
    }, {
      label: '12 hours',
      value: '720'
    }, {
      label: '24 hours',
      value: '1440'
    }, {
      label: '48 hours',
      value: '2880'
    }, {
      label: '3 days',
      value: '4320'
    }, {
      label: '4 days',
      value: '5760'
    }, {
      label: '5 days',
      value: '7200'
    }, {
      label: '6 days',
      value: '8640'
    }, {
      label: '7 days',
      value: '10080'
    }, {
      label: '8 days',
      value: '11520'
    }, {
      label: '9 days',
      value: '12960'
    }, {
      label: '10 days',
      value: '14400'
    }, {
      label: '11 days',
      value: '15840'
    }, {
      label: '12 days',
      value: '17280'
    }, {
      label: '13 days',
      value: '18720'
    }, {
      label: '14 days',
      value: '20160'
    }, {
      label: '30 days',
      value: '43200'
    }],
    selectedWithinHours: computed('cutOffPolicy.within_minutes', function () {
      var _this2 = this;

      var options = this.get('withinOptions');
      var selected = options.filter(function (option) {
        return _this2.get('cutOffPolicy.within_minutes') == option.value;
      });
      return selected.get('firstObject');
    }),
    didInsertElement: function didInsertElement() {
      this.set('loading', false);
    },
    timeError: computed('afterMethod', 'cutOffPolicy.time', 'triedToSave', function () {
      if (this.get('triedToSave')) {
        var time = this.get('cutOffPolicy.time');

        if (!time && this.get('afterMethod')) {
          return '* required';
        }
      }

      return false;
    }),
    actions: {
      cancel: function cancel() {
        if (this.get('loading')) {
          return;
        }

        this.get('cutOffPolicy').rollbackAttributes();
        this.get('onClose')();
      },
      changeDaysBefore: function changeDaysBefore(option) {
        this.set('cutOffPolicy.days_before', option.value);
      },
      changeWithinHours: function changeWithinHours(option) {
        this.set('cutOffPolicy.within_minutes', option.value);
      },
      save: function save() {
        var _this3 = this;

        this.set('triedToSave', true);

        if (this.get('timeError')) {
          return;
        }

        this.set('loading', true);

        if (this.get('newCutOffPolicy')) {
          this.get('cutOffPolicy').save().then(function () {
            _this3.get('onClose')();
          });
        } else {
          // Updates will destroy the record and create a new one which Ember data
          // doesn't like
          var policy = this.get('cutOffPolicy');
          Ember.$.ajax({
            url: "/api/v2/request_cut_off_policies/".concat(this.get('cutOffPolicy.id')),
            method: 'PUT',
            data: {
              request_cut_off_policy: {
                service_category_id: policy.get('service_category.id'),
                time: policy.get('time'),
                within_minutes: policy.get('within_minutes'),
                method: policy.get('method'),
                days_before: policy.get('days_before')
              }
            }
          }).then(function (response) {
            _this3.get('cutOffPolicy').deleteRecord();

            _this3.get('store').findRecord('request-cut-off-policy', response.request_cut_off_policy.id).then(function (newPolicy) {
              newPolicy.set('service_category', _this3.get('serviceCategory'));

              _this3.set('serviceCategory.request_cut_off_policy', newPolicy);

              _this3.get('onClose')();
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});