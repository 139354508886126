define("frontend/templates/components/document-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YtdY4Rxh",
    "block": "{\"symbols\":[\"combinedDocument\"],\"statements\":[[7,\"tr\",true],[8],[0,\"\\n  \"],[7,\"th\",true],[8],[0,\"document\"],[9],[0,\"\\n  \"],[7,\"th\",true],[8],[0,\"status\"],[9],[0,\"\\n  \"],[7,\"th\",true],[8],[0,\"last activity date\"],[9],[0,\"\\n  \"],[7,\"th\",true],[8],[0,\"action\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[4,\"each\",[[24,[\"combinedDocuments\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[23,1,[\"document_type\"]],\"user_document\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"document-list/document-list-item\",null,[[\"document\",\"removeDocument\",\"readOnly\"],[[23,1,[]],[28,\"action\",[[23,0,[]],[24,[\"removeDocument\"]]],null],[24,[\"readOnly\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"document-list/signature-request-list-item\",null,[[\"signatureRequest\",\"remove\",\"sendReminder\",\"cancelRequest\",\"emailPDF\",\"readOnly\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"remove\"],null],[28,\"action\",[[23,0,[]],[24,[\"sendReminder\"]]],null],[28,\"action\",[[23,0,[]],[24,[\"cancelRequest\"]]],null],[28,\"action\",[[23,0,[]],[24,[\"emailPDF\"]]],null],[24,[\"readOnly\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/document-list.hbs"
    }
  });

  _exports.default = _default;
});