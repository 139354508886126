define("frontend/components/address-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['address-modal'],
    currentUser: Ember.inject.service(),
    actions: {
      edit: function edit() {
        if (this.edit) {
          this.edit();
        }
      },
      back: function back() {
        if (this.back) {
          this.back();
        }
      }
    }
  });

  _exports.default = _default;
});