define("frontend/routes/add-client/client-search", ["exports", "frontend/routes/base-route", "frontend/mixins/cleanup-on-transition"], function (_exports, _baseRoute, _cleanupOnTransition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = _baseRoute.default.extend(_cleanupOnTransition.default, {
    addClientSaving: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    actions: {
      goNext: function goNext(user) {
        var _this = this;

        this.set('saving', true);
        this.get('addClientSaving').saveUser(user).then(function (savedUser) {
          return _this.transitionTo('add-client.primary-contact', savedUser.get('household.id'));
        }).finally(function () {
          _this.set('saving', false);
        });
      }
    }
  });

  _exports.default = _default;
});