define("frontend/components/user-selection-wheel/selection-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['selection-item'],
    media: inject.service(),
    circleDiameter: 200,
    degrees: 35,
    classNameBindings: ['selected'],
    attributeBindings: ['style'],
    selected: false,
    index: 0,
    style: computed('circleDiameter', 'index', function () {
      var diameter = this.get('circleDiameter');
      var degrees = this.get('degrees');
      var radius = diameter / 2;
      var index = parseInt(this.get('index'));
      var left = radius + Math.sin(this.toRadians(degrees * index * -1)) * radius * -1 - 22.5;
      var top = radius + Math.cos(this.toRadians(degrees * index)) * radius - 22.5;

      if (!this.get('media.isMobile')) {
        left += 25;
      }

      return "left: ".concat(left, "px; top: ").concat(top, "px;");
    }),
    toRadians: function toRadians(angle) {
      return angle * (Math.PI / 180.0);
    }
  });

  _exports.default = _default;
});