define("frontend/templates/components/staff-summary-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wJ9uVBkd",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"col-md-4\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"staff-summary-header-value compensation-value\"],[8],[0,\"\\n    \"],[7,\"div\",true],[8],[0,\"\\n      \"],[1,[28,\"format-money\",[[24,[\"totalCompensation\"]]],[[\"precision\"],[2]]],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"staff-summary-header-label\"],[8],[0,\"staff compensation\"],[9],[0,\"\\n\"],[4,\"if\",[[28,\"gt\",[[24,[\"tipCompensation\"]],0],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"tip-value\"],[8],[0,\"(incl. \"],[1,[28,\"format-money\",[[24,[\"tipCompensation\"]]],[[\"precision\"],[2]]],false],[0,\" tip allocated)\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"col-md-4\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"staff-summary-header-left-bar\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"staff-summary-header-value completed-appointments-value\"],[8],[1,[22,\"completedAppointments\"],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"staff-summary-header-label\"],[8],[0,\"completed appointments\"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"col-md-4\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"staff-summary-header-left-bar\"],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"staff-summary-header-value active-staff-value\"],[8],[1,[22,\"activeStaff\"],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"staff-summary-header-label\"],[8],[0,\"active staff\"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/staff-summary-header.hbs"
    }
  });

  _exports.default = _default;
});