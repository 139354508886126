define("frontend/templates/components/invoice-receipt/invoice-summary-line-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fhdO+0Be",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"adjustment-type\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"discount\"]]],null,{\"statements\":[[0,\"    -\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    +\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"description\"],[8],[0,\"\\n  \"],[1,[22,\"description\"],false],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"tooltipText\"]]],null,{\"statements\":[[4,\"tooltip-question-mark\",null,[[\"tooltipText\",\"color\"],[[24,[\"tooltipText\"]],\"gray\"]],{\"statements\":[[0,\"    \"],[1,[28,\"inline-svg\",[\"assets/images/tooltip-icons/info-icon-gray.svg\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"amount\"],[8],[0,\"\\n  \"],[1,[28,\"format-money\",[[24,[\"amount\"]]],[[\"precision\"],[2]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/invoice-receipt/invoice-summary-line-item.hbs"
    }
  });

  _exports.default = _default;
});