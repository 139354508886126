define("frontend/routes/users/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function hasStorage() {
    try {
      localStorage.setItem('num', 1);
      localStorage.removeItem('num');
      return true;
    } catch (exception) {
      return false;
    }
  }

  var _default = Ember.Route.extend({
    session: Ember.inject.service(),
    redirect: function redirect() {
      if (this.get('session.isAuthenticated')) {
        this.transitionTo('index');
      }
    },
    actions: {
      willTransition: function willTransition() {
        Ember.getOwner(this).lookup('controller:users/login').set('privateBrowsing', false);
      }
    },
    activate: function activate() {
      this._super.apply(this, arguments);

      if (hasStorage() === false) {
        Ember.getOwner(this).lookup('controller:users/login').set('privateBrowsing', true);
      }
    }
  });

  _exports.default = _default;
});