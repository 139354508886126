define("frontend/components/holiday-surcharge-holiday-list-item", ["exports", "frontend/mixins/dates-to-ranges"], function (_exports, _datesToRanges) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend(_datesToRanges.default, {
    classNames: ['holiday-list-item'],
    showSwitches: true,
    holidayName: null,
    shortenedName: null,
    icon: null,
    holidayDate: null,
    defaultSelectedDates: [],
    toggleIsOn: computed('holidaySurchargePolicy.dates.[]', 'datesToShow.[]', function () {
      var dates = this.get('datesToShow');
      var selectedDates = this.get('holidaySurchargePolicy.dates');

      if (selectedDates) {
        return dates.any(function (date) {
          return selectedDates.includes(date.format('YYYY-MM-DD'));
        });
      }
    }),
    iconUrl: computed('icon', function () {
      return "assets/images/holiday-icons/".concat(this.get('icon'));
    }),
    holidayDisplayName: computed('holidayName', 'shortenedName', function () {
      return this.get('shortenedName') || this.get('holidayName');
    }),
    datesToShow: computed('holidayDate', function () {
      var datesToShow = [];
      var middleDate = moment(this.get('holidayDate'));
      datesToShow.push(middleDate);

      for (var i = 1; i <= 3; i++) {
        datesToShow.push(middleDate.clone().add(i, 'day'));
        datesToShow.unshift(middleDate.clone().subtract(i, 'day'));
      }

      return datesToShow;
    }),
    switchLabel: computed('holidayName', 'datesToShow.[]', 'holidaySurchargePolicy.dates.[]', function () {
      var _this = this;

      var selectedMomentDates = this.get('datesToShow').filter(function (date) {
        return _this.get('holidaySurchargePolicy.dates').includes(date.format('YYYY-MM-DD'));
      });
      var selectedDates = selectedMomentDates.map(function (date) {
        return date.format('YYYY-MM-DD');
      });
      var label = this.get('holidayName');

      if (selectedDates.length > 0) {
        label += " <span class=\"dates-range\">(".concat(this.datesToRanges(selectedDates).join(', '), ")</span>");
      }

      return label;
    }),
    actions: {
      toggleHoliday: function toggleHoliday() {
        var _this2 = this;

        if (this.get('toggleIsOn')) {
          var dates = this.get('datesToShow');
          dates.forEach(function (date) {
            _this2.get('holidaySurchargePolicy.dates').removeObject(date.format('YYYY-MM-DD'));
          });
        } else {
          this.get('defaultSelectedDates').forEach(function (date) {
            _this2.get('holidaySurchargePolicy.dates').pushObject(date);
          });
        }
      }
    }
  });

  _exports.default = _default;
});