define("frontend/components/payments-widget", ["exports", "frontend/config/environment", "frontend/utils/payment-type", "jquery"], function (_exports, _environment, _paymentType, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['payments-widget'],
    classNameBindings: ['paymentType'],
    paymentType: _paymentType.default.ALL,
    isAll: computed.equal('invoiceType', _paymentType.default.ALL),
    isCard: computed.equal('invoiceType', _paymentType.default.Card),
    isBank: computed.equal('invoiceType', _paymentType.default.BANK),
    isManual: computed.equal('invoiceType', _paymentType.default.MANUAL),
    isFetching: false,
    extraHeaders: [],
    paymentHeaders: [],
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      this.send('queryHeaders');
    },
    actions: {
      queryHeaders: function queryHeaders() {
        var _this = this;

        if (this.get('isFetching')) {
          return;
        }

        this.set('isFetching', true);
        var url = "".concat(_environment.default.APP.host, "/api/v2/invoice_payments/headers?payment_type=").concat(this.get('paymentType'));

        _jquery.default.ajax({
          url: url,
          method: 'GET'
        }).then(function (headers) {
          _this.set('paymentHeaders', headers.headers);

          _this.set('extraHeaders', headers.extra_headers);

          _this.set('isFetching', false);
        });
      },
      selectPaymentType: function selectPaymentType(paymentType) {
        if (this.get('paymentType') !== paymentType) {
          this.set('paymentType', paymentType);
          this.set('paymentHeaders', []);
          this.set('reachedInfinity', false);
          this.send('queryHeaders');
        }
      }
    }
  });

  _exports.default = _default;
});