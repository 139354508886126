define("frontend/components/text-area-callout", ["exports", "frontend/components/text-input-callout"], function (_exports, _textInputCallout) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _textInputCallout.default.extend({
    classNames: ['text-area-callout']
  });

  _exports.default = _default;
});