define("frontend/routes/portal", ["exports", "frontend/routes/base-route", "jquery"], function (_exports, _baseRoute, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = _baseRoute.default.extend({
    session: inject.service(),
    currentUser: inject.service(),
    flashMessage: inject.service(),
    queryParams: {
      signContract: {
        refreshModel: true
      },
      apptRqstCtgryID: {
        refreshModel: true
      }
    },
    model: function model(params) {
      if (this.get('currentUser.user')) {
        var url = "/api/v2/households/".concat(this.get('currentUser.user.household.id'), "/in_house_signature_requests");

        if (params.signContract) {
          url += "?request_to_sign=".concat(params.signContract);
        }

        return _jquery.default.ajax({
          url: url,
          method: 'GET'
        }).then(function (response) {
          return Ember.RSVP.hash({
            inhouseSignatures: response.signature_requests,
            allCategoriesRequireSigning: response.all_categories_require_signing
          });
        });
      }
    },
    redirect: function redirect() {
      this._super.apply(this, arguments);

      var user = this.get('currentUser.user');

      if (!user) {
        this.transitionTo('users.login');
      }
    },
    actions: {
      refreshModel: function refreshModel() {
        this.refresh();
      },
      showSignContractsModalForSpecificCategory: function showSignContractsModalForSpecificCategory(categoryID) {
        this.set('signatureRequestCategoryID', categoryID);
        var controller = this.controllerFor('portal');
        controller.set('showSignContractsModal', true);
        controller.set('filterSignatureRequestsWithCategoryID', parseInt(categoryID));
      },
      showSignContractsModal: function showSignContractsModal() {
        controller.set('showSignContractsModal', true);
      }
    }
  });

  _exports.default = _default;
});