define("frontend/controllers/companies/paying-staff", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    timeZone: inject.service(),
    payFrequencyOptions: [{
      value: 'weekly',
      label: 'Weekly'
    }, {
      value: 'semi_monthly',
      label: '1st and 15th each month'
    }, {
      value: 'bi_weekly',
      label: 'Every other week'
    }, {
      value: 'monthly',
      label: 'Monthly'
    }],
    payFrequency: computed('model.pay_frequency', {
      set: function set(_key, value) {
        if (value === 'bi_weekly' && this.get('model.pay_period') == null) {
          this.set('payPeriod', this.get('payPeriodOptions.firstObject.id'));
        } else {
          this.set('payPeriod', null);
        }

        this.set('model.pay_frequency', value);
        return this.get('model.pay_frequency');
      },
      get: function get() {
        return this.get('model.pay_frequency');
      }
    }),
    selectedPayFrequency: computed('model.pay_frequency', 'payFrequencyOptions', function () {
      var _this = this;

      var options = this.get('payFrequencyOptions');
      var selected = options.filter(function (option) {
        return _this.get('model.pay_frequency') == option.value;
      });
      return selected.get('firstObject');
    }),
    payPeriodOptions: computed(function () {
      var options;
      var offset = this.get('timeZone').get('offset');
      var startDate = (0, _moment.default)();
      startDate = _moment.default.utc(startDate, 'MM-DD-YYYY').utcOffset(offset).startOf('week').add('day', 1);

      var endDate = _moment.default.utc(startDate, 'MM-DD-YYYY').utcOffset(offset).endOf('week').add('day', 1);

      endDate.add(1, 'week');
      options = [{
        label: "".concat(startDate.format('M/D'), " - ").concat(endDate.format('M/D')),
        value: startDate.format()
      }];
      startDate.subtract(1, 'week');
      endDate.subtract(1, 'week');
      options.push({
        label: "".concat(startDate.format('M/D'), " - ").concat(endDate.format('M/D')),
        value: startDate.format()
      });
      return options;
    }),
    payPeriod: computed('model.pay_period', 'model.pay_frequency', {
      set: function set(key, value) {
        this.set('model.pay_period', value);
        return this.get('model.pay_period');
      },
      get: function get() {
        var _this2 = this;

        if (this.get('model.pay_period')) {
          (function () {
            var offset = _this2.get('timeZone').get('offset');

            var startDate = _moment.default.utc((0, _moment.default)(_this2.get('model.pay_period')), 'MM-DD-YYYY').utcOffset(offset).startOf('week').add('day', 1);

            var found = false;

            while (!found) {
              _this2.get('payPeriodOptions').forEach(function (_ref) {
                var value = _ref.value;

                if (startDate.format() == value) {
                  _this2.set('model.pay_period', value);

                  found = true;
                  return;
                }
              });

              startDate.add(2, 'week');
            }
          })();
        } else {
          this.set('model.pay_period', this.get('payPeriodOptions.firstObject.value'));
        }

        return this.get('model.pay_period');
      }
    }),
    selectedPayPeriod: computed('payPeriod', 'model.pay_period', 'payPeriodOptions', function () {
      var _this3 = this;

      this.get('payPeriod');
      var options = this.get('payPeriodOptions');
      var selected = options.filter(function (option) {
        return _this3.get('model.pay_period') == option.value;
      });
      return selected.get('firstObject');
    }),
    staffApprovalHelpText: computed('model.staff_appointment_approval', function () {
      if (this.get('model.staff_appointment_approval')) {
        return "Your team will need to approve any appointments assigned to them. You’ll still be able to approve appointments on their behalf as well!";
      } else {
        return "By enabling this setting, your team will need to approve any appointments assigned to them. You’ll still be able to approve appointments on their behalf as well!";
      }
    }),
    actions: {
      save: function save() {
        var _this4 = this;

        if (this.get('saving')) {
          return;
        }

        this.set('saving', true);
        this.get('model').save().then(function () {
          return _this4.transitionToRoute('index');
        }).finally(function () {
          _this4.set('saving', false);
        });
      },
      toggleStaffApproval: function toggleStaffApproval(value) {
        this.set('model.staff_appointment_approval', value);
      },
      changePayFrequency: function changePayFrequency(option) {
        this.set('model.pay_frequency', option.value);
      },
      changePayPeriod: function changePayPeriod(option) {
        this.set('model.pay_period', option.value);
      }
    }
  });

  _exports.default = _default;
});