define("frontend/components/accept-company-request", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    company: null,
    household: null,
    buttonsDisabled: false,
    flashMessage: inject.service(),
    companyLogo: computed('company', function () {
      var logo = this.get('company.company_logo');

      if (logo) {
        return logo;
      } else {
        return '/assets/images/settings/company-placeholder.png';
      }
    }),
    didInsertElement: function didInsertElement() {
      this.set('buttonsDisabled', false);
    },
    actions: {
      confirmRequest: function confirmRequest() {
        var _this = this;

        this.set('buttonsDisabled', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/accept_request"),
          method: 'POST',
          data: {
            token: this.get('company.token')
          }
        }).then(function () {
          _this.get('onSave')('accepted');
        }).fail(function () {
          _this.set('buttonsDisabled', false);
        });
      },
      denyRequest: function denyRequest() {
        var _this2 = this;

        this.set('buttonsDisabled', true);

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/deny_request"),
          method: 'POST',
          data: {
            token: this.get('company.token'),
            id: this.get('household.id')
          }
        }).then(function () {
          _this2.get('flashMessage').sendFlashMessage("Thanks for letting us know! We've alerted ".concat(_this2.get('company.company_name'), " that they got the wrong person"), 'success');

          _this2.get('onSave')('denied');

          _this2.set('buttonsDisabled', false);
        }).fail(function () {
          _this2.set('buttonsDisabled', false);
        });
      },
      onClose: function onClose() {}
    }
  });

  _exports.default = _default;
});