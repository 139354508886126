define("frontend/templates/components/message-thread/view-households-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0pp5sTfG",
    "block": "{\"symbols\":[\"household\"],\"statements\":[[4,\"ppb-modal\",null,[[\"customContainerClassNames\",\"onClose\"],[\"messages-thread message-thread-view-households\",[28,\"action\",[[23,0,[]],\"toggleViewHousesHoldsModal\"],null]]],{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"box-shadow\"],[8],[0,\"\\n      \"],[7,\"div\",false],[12,\"class\",\"back\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"toggleViewHousesHoldsModal\"]],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"src\",\"assets/images/back-chevron.svg\"],[8],[9],[0,\" Back\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"messages-date-header\"],[8],[0,\"\\n        \"],[1,[28,\"format-rolling-date\",[[24,[\"message\",\"created_at\"]],\"dddd, MMMM D\",true],null],true],[0,\"\\n      \"],[9],[0,\"\\n      \"],[1,[28,\"message-thread/message\",null,[[\"message\",\"isPetParent\"],[[24,[\"message\"]],false]]],false],[0,\"\\n    \"],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"class\",[29,[\"households-container \",[28,\"if\",[[28,\"media\",[\"isMobile\"],null],\"mobile\"],null]]]],[10,\"id\",\"households\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"warning-text\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"src\",\"assets/images/info-icon-small.svg\"],[8],[9],[0,\"\\n        This message was sent to the following recipients\\n      \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"isLoadingHouseholds\"]]],null,{\"statements\":[[0,\"        \"],[1,[22,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"households\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"household-select/household-select-option\",null,[[\"household\"],[[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/message-thread/view-households-modal.hbs"
    }
  });

  _exports.default = _default;
});