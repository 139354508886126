define("frontend/components/adaptive-summary-note", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['adaptive-summary-note'],
    text: null
  });

  _exports.default = _default;
});