define("frontend/components/invoices-widget", ["exports", "frontend/config/environment", "ember-data", "frontend/utils/invoice-type", "jquery"], function (_exports, _environment, _emberData, _invoiceType, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['invoices-widget'],
    classNameBindings: ['invoiceType'],
    invoiceType: _invoiceType.default.DRAFT,
    isDraft: computed.equal('invoiceType', _invoiceType.default.DRAFT),
    isSent: computed.equal('invoiceType', _invoiceType.default.SENT),
    isPaid: computed.equal('invoiceType', _invoiceType.default.PAID),
    isDue: computed.equal('invoiceType', _invoiceType.default.DUE),
    isSentPaidOrDue: computed.or('isSent', 'isPaid', 'isDue'),
    store: inject.service(),
    invoiceGroups: computed('store', 'invoiceType', function () {
      var promise = _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/invoices/groups?invoice_type=").concat(this.get('invoiceType')),
        method: 'GET'
      }).then(function (invoiceGroups) {
        return Object.keys(invoiceGroups).map(function (end_date) {
          return Ember.Object.create({
            end_date: end_date.split(' ')[0],
            amount: invoiceGroups[end_date].total_amount_owed,
            amount_paid: invoiceGroups[end_date].amount_paid,
            sales_tax: invoiceGroups[end_date].sales_tax
          });
        });
      });

      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    actions: {
      clickInvoice: function clickInvoice() {
        this.get('clickInvoice').apply(void 0, arguments);
      }
    }
  });

  _exports.default = _default;
});