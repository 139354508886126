define("frontend/services/account-session", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Service.extend({
    currentUser: inject.service(),
    session: inject.service(),
    cookies: inject.service(),
    switchAccounts: function switchAccounts(new_company_id) {
      var redirectHref = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '/';
      this.get('cookies').write('ccontrol_id', new_company_id, {
        path: '/',
        maxAge: 31536000
      });
      var store = this.get('session.store');
      return _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/users/").concat(this.get('currentUser.user.id'), "/change_current_company"),
        method: 'POST',
        data: {
          new_company_id: new_company_id
        }
      }).done(function (response) {
        store.clear().then(function () {
          return store.persist(response).then(function () {
            return window.location.href = redirectHref;
          });
        });
      });
    },
    hasCompany: function hasCompany() {
      return this.get('cookies').exists('ccontrol_id');
    },
    getCurrentCompanyId: function getCurrentCompanyId() {
      return this.get('cookies').read('ccontrol_id');
    },
    clearCompany: function clearCompany() {
      this.get('cookies').clear('ccontrol_id', {
        path: '/'
      });
    },
    clearCompanyWithoutRefresh: function clearCompanyWithoutRefresh() {
      sessionStorage.setItem('cancelRefreshOnCookieChange', true);
      this.clearCompany();
    },
    isPetParent: function isPetParent() {
      return this.get('cookies').read('ccontrol_id') == '0';
    },
    signOut: function signOut() {
      this.get('cookies').clear('ccontrol_id', {
        path: '/'
      });
      return this.get('session').invalidate();
    }
  });

  _exports.default = _default;
});