define("frontend/services/firestore", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    getCollection: function getCollection(collection_name) {
      // eslint-disable-next-line no-undef
      if (typeof firebase !== 'undefined' && typeof firebase.firestore !== 'undefined') {
        // eslint-disable-next-line no-undef
        var firestore = firebase.firestore();
        firestore.settings({
          timestampsInSnapshots: true
        });
        return firestore.collection(collection_name);
      }
    }
  });

  _exports.default = _default;
});