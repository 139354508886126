define("frontend/components/mobile-select/select-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['select-list'],
    hasBackButton: false,
    actions: {
      clickOption: function clickOption() {
        this.clickOption.apply(this, arguments);
      },
      clickBack: function clickBack() {
        this.get('clickBack')();
      }
    }
  });

  _exports.default = _default;
});