define("frontend/components/mut-address-side-panel", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'address.street': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'address.city': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'address.state': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'address.zipcode': (0, _emberCpValidations.validator)('international-postal-code')
  });

  var _default = Ember.Component.extend(Validations, {
    classNames: ['mut-address-side-panel'],
    countryInfo: inject.service(),
    flashMessage: inject.service(),
    didValidate: false,
    isInvalid: computed.not('validations.isValid'),
    hasCountry: false,
    changeCountryButtonClass: computed('changeCountryText', function () {
      return "readonly-country--".concat(this.get('changeCountryText'));
    }),
    changeCountryButtonDisabled: computed.equal('changeCountryText', 'pending'),
    changeCountryText: 'change',
    countryOptions: computed('countryInfo.countries.[]', function () {
      return this.get('countryInfo.countries').map(function (country) {
        return {
          value: country,
          label: country
        };
      });
    }),
    stateOptions: computed('countryInfo.states.[]', function () {
      return this.get('countryInfo.states').map(function (state) {
        return {
          value: state,
          label: state
        };
      });
    }),
    stateOrProvince: computed('address.selectedCountry', function () {
      if (this.get('address.selectedCountry') == 'Canada') {
        return 'Province';
      } else {
        return 'State';
      }
    }),
    zipOrPostal: computed('address.selectedCountry', function () {
      if (this.get('address.selectedCountry') == 'Canada') {
        return 'Postal code';
      } else if (this.get('address.selectedCountry') == 'Australia') {
        return 'Postcode';
      } else {
        return 'Zip code';
      }
    }),
    actions: {
      clickDone: function clickDone() {
        if (this.get('isInvalid')) {
          this.set('didValidate', true);
          return;
        }

        this.get('clickDone')();
      },
      requestCountryChange: function requestCountryChange() {
        var _this = this;

        this.get('address').requestCountryChange().then(function () {
          _this.set('changeCountryText', 'pending');

          _this.get('flashMessage').sendFlashMessage("Our support team just got your country change request. We'll be in touch shortly", 'Success');
        });
      }
    }
  });

  _exports.default = _default;
});