define("frontend/components/add-client/secondary-contact-prompt", ["exports", "frontend/components/yes-skip-prompt"], function (_exports, _yesSkipPrompt) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _yesSkipPrompt.default.extend({
    classNames: ['secondary-contact-prompt']
  });

  _exports.default = _default;
});