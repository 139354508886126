define("frontend/components/appointment-group-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['appointment-group-select'],
    actions: {
      selectTime: function selectTime(time) {
        if (this.get('selectTime')) {
          this.get('selectTime')(time);
        } else {
          this.set('selectedTime', time);
        }
      }
    }
  });

  _exports.default = _default;
});