define("frontend/components/vet-modal", ["exports", "frontend/mixins/autocomplete/highlight-first-match", "ember-cp-validations"], function (_exports, _highlightFirstMatch, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'vet.street_address': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'vet.city': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'vet.state': (0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }),
    'vet.zipcode': (0, _emberCpValidations.validator)('international-postal-code')
  });

  var _default = Ember.Component.extend(_highlightFirstMatch.default, Validations, {
    countryInfo: inject.service(),
    isOnAdmin: false,
    isInvalid: computed('isOnAdmin', 'vet.street_address', 'vet.city', 'vet.state', 'vet.zipcode', function () {
      if (!this.get('isOnAdmin')) {
        return !this.get('validations.isValid');
      } else {
        // for now admin users do not get invalid vet address checks due to bug with country values see #174849556
        return false;
      }
    }),
    states: computed('isOnAdmin', function () {
      if (this.get('isOnAdmin')) {
        return this.get('countryInfo.statesAndProvinces');
      } else {
        return this.get('countryInfo.states');
      }
    }),
    stateOrProvince: computed('countryInfo.currentUserCountry', function () {
      if (this.get('countryInfo.currentUserCountry') == 'Canada') {
        return 'province';
      }

      return 'state';
    }),
    zipOrPostal: computed('countryInfo.country', function () {
      if (this.get('countryInfo.currentUserCountry') == 'Canada') {
        return 'postal code';
      }

      if (this.get('countryInfo.currentUserCountry') == 'Australia') {
        return 'postcode';
      }

      return 'zip code';
    }),
    zipCodeErrors: computed('isOnAdmin', 'validations.attrs.vet.zipcode.messages', function () {
      if (this.get('isOnAdmin')) {
        return [];
      } else {
        return this.get('validations.attrs.vet.zipcode.messages');
      }
    }),
    actions: {
      onClose: function onClose() {
        this.get('onClose')();
      },
      uploadedPhoto: function uploadedPhoto(photo) {
        this.set('vet.logo_url', photo);
      },
      onSubmit: function onSubmit() {
        if (this.get('vet.phone_number.length') !== 10) {
          this.set('vet.phone_number', null);
        }

        if (this.get('isInvalid')) {
          return;
        }

        this.get('onSubmit')(this.get('vet'));
      }
    }
  });

  _exports.default = _default;
});