define("frontend/controllers/verified-bank-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['last_four'],
    last_four: null,
    actions: {
      redirectToPaymentPortal: function redirectToPaymentPortal() {
        if (this.get('model.redirect_to_portal')) {
          this.transitionToRoute('portal.invoices-summary');
        } else {
          this.transitionToRoute('pay', this.get('model.payment_token'));
        }
      }
    }
  });

  _exports.default = _default;
});