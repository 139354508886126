define("frontend/components/message-thread/new-message-household-select/household-option", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    household: null,
    currentUser: inject.service(),
    firestore: inject.service(),
    firestoreListener: null,
    selectedHouseholds: [],
    selected: computed('selectedHouseholds.[]', 'household', function () {
      var _this = this;

      var selected = false;
      this.get('selectedHouseholds').forEach(function (household) {
        if (household.id == _this.get('household.id')) {
          selected = true;
        }
      });
      return selected;
    }),
    names: computed('household.owner', 'household.secondaryContact', function () {
      if (this.get('household.secondaryContact')) {
        return "".concat(this.get('household.owner.first_name'), " & ").concat(this.get('household.secondaryContact.first_name'));
      }

      return this.get('household.owner.fullName');
    }),
    hasNewMessage: computed('household.user_last_seen_message_thread', 'lastMessageTime', function () {
      if (!this.get('lastMessageTime')) {
        return false;
      }

      var lastSeen = this.get('household.user_last_seen_message_thread');
      var lastMessage = this.get('lastMessageTime');

      if (!lastSeen) {
        return true;
      }

      return moment(lastSeen).unix() < lastMessage;
    }),
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this._super.apply(this, arguments);

      var self = this;
      var collection = this.get('firestore').getCollection("".concat(_environment.default.environment, "_message_times"));

      if (collection) {
        var firestoreListener = collection.doc("".concat(self.get('currentUser.user.company.id'), "_").concat(self.get('household.id'))).onSnapshot(function (doc) {
          if (doc.exists) {
            var lastMessageTime = self.get('lastMessageTime');

            if (lastMessageTime) {
              lastMessageTime = moment.unix(lastMessageTime).format();
            }

            _jquery.default.ajax({
              url: "".concat(_environment.default.APP.host, "/api/v2/messages"),
              method: 'GET',
              data: {
                household_id: self.get('household.id'),
                company_id: self.get('currentUser.user.company.id'),
                last_message_time: lastMessageTime
              }
            }).then(function (response) {
              self.set('lastMessageTime', doc.data().staff_last_message);
              var lastMessage = response.messages.sortBy('created_at').get('lastObject');

              if (lastMessage) {
                self.get('household').set('last_message_time', lastMessage.created_at);
                self.get('household').set('last_message_text', lastMessage.message);
              }
            });
          } else {
            self.set('lastMessageTime', null);
          }
        });
        this.set('firestoreListener', firestoreListener);
      }

      Ember.run.scheduleOnce('afterRender', function () {
        var householdId = _this2.get('household.id');

        var numPets = _this2.get('household.pets.length');

        var label = document.getElementById("household-select-label-".concat(householdId));

        if (label) {
          label.style.height = "".concat(18 + 49 * numPets, "px");
        }
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('firestoreListener')) {
        this.get('firestoreListener')();
      }
    },
    actions: {
      clickedHousehold: function clickedHousehold() {
        this.get('clickedHousehold')(this.get('household'));
      }
    }
  });

  _exports.default = _default;
});