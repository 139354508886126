define("frontend/components/service-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['service-box', 'no-drag-cancel'],
    attributeBindings: ['data-service-id', 'data-height', 'style'],
    classNameBindings: ['selected', 'draggable', 'noDragArchive:no-drag-archive'],
    service: null,
    showTime: false,
    draggable: false,
    selected: true,
    flashMessage: Ember.inject.service(),
    'data-height': computed.readOnly('service.pixelHeight'),
    'data-service-id': computed.readOnly('service.id'),
    noDragArchive: computed('service.sortedGroupedAppointmentsWithoutCanceledIds', function () {
      // Trigger computed property
      this.service.get('sortedGroupedAppointmentsWithoutCanceledIds');
      var hasAppointmentStarted = false;
      this.get('service.sortedGroupedAppointmentsWithoutCanceled').forEach(function (groupedAppointment) {
        groupedAppointment.get('appointments').forEach(function (appointment) {
          if (appointment.get('started_at')) {
            hasAppointmentStarted = true;
          }
        });
      });
      return hasAppointmentStarted;
    }),
    style: computed('service.pixelHeight', 'showTime', function () {
      var pixelHeight = this.get('service.pixelHeight');

      if (this.get('showTime')) {
        pixelHeight = 105;
      }

      return Ember.String.htmlSafe("height: ".concat(pixelHeight, "px;"));
    }),
    appointmentsWithRecurrence: computed.readOnly('service.appointmentsWithRecurrence'),
    sortedGroupedAppointments: computed('service.sortedGroupedAppointmentsWithoutCanceled.[]', 'service.sortedCanceledGroupedAppointments.[]', function () {
      if (this.get('onCancelationSchedule')) {
        return this.get('service.sortedCanceledGroupedAppointments');
      } else {
        return this.get('service.sortedGroupedAppointmentsWithoutCanceled');
      }
    }),
    actions: {
      clickAppointment: function clickAppointment(appointment) {
        this.get('clickAppointment')(appointment);
      }
    }
  });

  _exports.default = _default;
});