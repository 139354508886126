define("frontend/routes/inbox/household", ["exports", "frontend/routes/base-route", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = _baseRoute.default.extend({
    infinity: inject.service(),
    currentUser: inject.service(),
    model: function model(_ref) {
      var household_id = _ref.household_id;
      this.set('householdId', household_id);
      var company_id = this.get('currentUser.user.company.id');
      var messages = this.get('infinity').model('message', {
        household_id: household_id,
        company_id: company_id,
        perPage: 20,
        startingPage: 1
      });
      var household = this.store.findRecord('household', household_id);
      return Ember.RSVP.hash({
        messages: messages,
        household: household
      });
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/messages/record_last_seen"),
        method: 'POST',
        data: {
          company_id: this.get('currentUser.user.company.id'),
          household_id: model.household.get('id')
        }
      }).then(function (response) {
        setTimeout(function () {
          model.household.set('user_last_seen_message_thread', response.last_seen);
        }, 3000);
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('selectedHousehold', this.get('householdId'));
    }
  });

  _exports.default = _default;
});