define("frontend/components/birthday-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['layout-row', 'birthday-input'],
    media: inject.service(),
    useDateType: computed.readOnly('media.isMobile'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('birthday') && !this.get('useDateType')) {
        var year = moment(this.get('birthday')).format('YYYY');
        var month = moment(this.get('birthday')).format('MMMM');
        var day = moment(this.get('birthday')).format('D');
        this.set('selectedYear', year);
        this.set('selectedMonth', month);
        this.set('selectedDay', day);
      }
    },
    yearOptions: computed(function () {
      var start = moment();
      var end = moment().subtract(30, 'years');
      var options = [];

      while (start > end) {
        options.push(start.format('YYYY'));
        start.subtract(1, 'year');
      }

      return options;
    }),
    monthOptions: computed(function () {
      var start = moment().startOf('year');
      var end = moment().endOf('year');
      var options = [];

      while (start < end) {
        options.push(start.format('MMMM'));
        start.add(1, 'month');
      }

      return options;
    }),
    dayOptions: computed('selectedMonth', 'selectedYear', function () {
      var selectedMonth = this.get('selectedMonth') || moment().format('MMMM');
      var selectedYear = this.get('selectedYear') || moment().format('YYYY');
      var momentMonth = moment(selectedMonth, 'MMMM').year(selectedYear);
      var start = momentMonth.clone().startOf('month');
      var end = momentMonth.clone().endOf('month');
      var options = [];

      while (start < end) {
        options.push(start.format('D'));
        start.add(1, 'day');
      }

      return options;
    }),
    setDate: function setDate() {
      if (!this.get('selectedMonth') || !this.get('selectedDay') || !this.get('selectedYear')) {
        this.get('onChange')(null);
        return;
      }

      var dateString = "".concat(this.get('selectedMonth'), " ").concat(this.get('selectedDay'), " ").concat(this.get('selectedYear'));
      var formattedDate = moment(dateString, 'MMMM DD YYYY').format('MM/DD/YYYY');
      this.get('onChange')(formattedDate);
    },
    actions: {
      changeDay: function changeDay(day) {
        this.set('selectedDay', day);
        this.setDate();
      },
      changeMonth: function changeMonth(month) {
        this.set('selectedMonth', month);

        if (this.get('dayOptions').indexOf(this.get('selectedDay')) === -1) {
          this.set('selectedDay', null);
        }

        this.setDate();
      },
      changeYear: function changeYear(year) {
        this.set('selectedYear', year);

        if (this.get('dayOptions').indexOf(this.get('selectedDay')) === -1) {
          this.set('selectedDay', null);
        }

        this.setDate();
      }
    }
  });

  _exports.default = _default;
});