define("frontend/templates/components/collapse-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "FMAHHhaa",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"collapsed\"]]],null,{\"statements\":[[0,\"    [+]\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    [-]\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/collapse-button.hbs"
    }
  });

  _exports.default = _default;
});