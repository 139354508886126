define("frontend/components/report-card-checklist-pet-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['report-card-checklist-pet-select'],
    checklist: null,
    attrSelected: null,
    isSelected: computed('checklist.peed', 'checklist.pooped', 'checklist.fedTreat', 'checklist.providedWater', function () {
      return this.checklist.get(this.attrSelected);
    }),
    actions: {
      selectPet: function selectPet() {
        var attr = this.attrSelected;
        var value = this.checklist.get(attr);
        this.checklist.set(attr, !value);
      }
    }
  });

  _exports.default = _default;
});