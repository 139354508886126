define("frontend/models/invoice-payment-refund", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend({
    amount: _emberData.default.attr('number'),
    payment_amount: _emberData.default.attr('number'),
    created_at: _emberData.default.attr('string'),
    payment_brand: _emberData.default.attr('string'),
    payment_last_four: _emberData.default.attr('string'),
    client_name: _emberData.default.attr('string'),
    household_id: _emberData.default.attr('number'),
    pet_names: _emberData.default.attr(),
    invoice_payment: _emberData.default.belongsTo('invoice-payment'),
    payment_date: computed.alias('created_at'),
    net_sales: computed.alias('payment_amount'),
    isRefund: true
  });

  _exports.default = _default;
});