define("frontend/components/invoice-receipt/grouped-appointment-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['invoice-receipt--grouped-appointment-list-item'],
    classNameBindings: ['missed', 'future'],
    missed: computed.not('groupedAppointment.completed'),
    future: computed('groupedAppointment.time', function () {
      var apptTime = this.get('groupedAppointment.time');
      var tomorrow = moment().add(1, 'day').startOf('day');
      return moment(apptTime).isSameOrAfter(tomorrow);
    }),
    futureTooltip: 'This can’t be completed yet because the appointment is in the future!',
    pets: computed('groupedAppointment.appointments.[]', function () {
      return this.get('groupedAppointment.appointments').map(function (appt) {
        return appt.get('pet');
      });
    }),
    petsSorting: ['name'],
    sortedPets: computed.sort('pets', 'petsSorting'),
    petNames: computed('sortedPets.[]', function () {
      return this.get('sortedPets').map(function (pet) {
        return pet.get('name');
      });
    }),
    actions: {
      toggleComplete: function toggleComplete(groupedAppointment) {
        var completed = groupedAppointment.get('completed');
        groupedAppointment.get('appointments').forEach(function (appt) {
          appt.set('completed', !completed);
        });
        groupedAppointment.set('completed', !completed);
      }
    }
  });

  _exports.default = _default;
});