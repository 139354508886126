define("frontend/components/client-list/pet-output/medical-mobile-action-bar-with-multiples", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['medical-mobile-action-bar'],
    emails: [],
    phoneNumbers: [],
    populateContactInfo: function populateContactInfo(contactAction, contactPrompt, models, modelMarshaller) {
      this.set('showContactPickerModal', true);
      this.set('contactAction', contactAction);
      this.set('contactPrompt', contactPrompt);
      var contacts = [];
      models.forEach(function (model) {
        contacts.push(modelMarshaller(model));
      });
      this.set('contactPickerInfo', contacts);
    },
    actions: {
      clickEmailPrompt: function clickEmailPrompt() {
        var emails = this.get('emails');
        var emailsCount = emails.get('length');

        if (emailsCount === 1) {
          this.get('clickEmail')(emails.get('firstObject.address'));
        } else if (emailsCount > 1) {
          this.populateContactInfo('clickEmail', 'Which email would you like to reach?', emails, function (model) {
            var type = model.get('email_type');
            var address = model.get('address');
            return {
              type: type,
              address: address,
              model: model
            };
          });
        }
      },
      clickPhoneNumberPrompt: function clickPhoneNumberPrompt() {
        var phones = this.get('phoneNumbers');
        var phonesCount = phones.get('length');
        var mobilePhones = phones.filter(function (phone) {
          return phone.get('isMobile');
        });

        if (phonesCount > 1 || mobilePhones.get('length') > 0) {
          this.set('showPhonePickerModal', true);
        } else {
          this.get('callPhoneNumber')(phones.get('firstObject.number'));
        }
      },
      clickEmail: function clickEmail() {
        this.get('clickEmail').apply(void 0, arguments);
      },
      callPhoneNumber: function callPhoneNumber() {
        this.get('callPhoneNumber').apply(void 0, arguments);
      },
      textPhoneNumber: function textPhoneNumber() {
        this.get('textPhoneNumber').apply(void 0, arguments);
      },
      clickAddress: function clickAddress() {
        this.get('clickAddress').apply(void 0, arguments);
      },
      onClose: function onClose() {
        this.set('showContactPickerModal', false);
        this.set('contactPrompt', null);
      },
      closePhonePickerModal: function closePhonePickerModal() {
        this.set('showPhonePickerModal', false);
      }
    }
  });

  _exports.default = _default;
});