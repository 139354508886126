define("frontend/components/dismissable-message-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    media: inject.service(),
    customContainerClassNames: computed('containerClass', function () {
      var containerClass = this.get('containerClass');
      var className = 'dismissable-message-modal';

      if (containerClass) {
        className = "".concat(className, " ").concat(containerClass);
      }

      return className;
    }),
    overlayClassNames: computed('media.isMobile', function () {
      return this.get('media.isMobile') ? 'ember-modal-overlay--small-mobile' : '';
    }),
    actions: {
      onClose: function onClose() {
        this.onClose();
      },
      onDismissedMessage: function onDismissedMessage() {
        this.onDismissedMessage();
      }
    }
  });

  _exports.default = _default;
});