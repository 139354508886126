define("frontend/templates/components/household-not-found-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XX4GC/RF",
    "block": "{\"symbols\":[],\"statements\":[[7,\"h1\",true],[10,\"class\",\"household-not-found-error__header-text\"],[8],[0,\"Oops! This client is no longer active!\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"household-not-found-error__helper-text\"],[8],[0,\"Looks like you removed this client from your client list. Don’t worry, you can still access past bills for this client through your \"],[4,\"link-to\",null,[[\"route\"],[\"invoices\"]],{\"statements\":[[0,\"invoices\"]],\"parameters\":[]},null],[0,\" tab.\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"girl-sad household-not-found-error__girl-sad\"],[8],[9],[0,\"\\n\\n\"],[4,\"add-button\",null,[[\"route\"],[\"add-client.client-search\"]],{\"statements\":[[0,\"  Add a new client\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/household-not-found-error.hbs"
    }
  });

  _exports.default = _default;
});