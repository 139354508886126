define("frontend/templates/components/added-auto-bill-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MPcgrvU+",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[4,\"ppb-modal\",null,[[\"customContainerClassNames\"],[\"override-frequency-modal\"]],{\"statements\":[[4,\"paper-form\",null,null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[1,[28,\"inline-svg\",[\"assets/images/fireworks.svg\"],null],false],[9],[0,\"\\n        \"],[7,\"header\",true],[8],[0,\"\\n          Get ready for some billing magic!\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"subheader small\"],[8],[0,\"\\n        You chose to bill your clients \"],[1,[22,\"defaultInvoiceFrequency\"],false],[0,\" by default, so all your clients will be set to \"],[1,[22,\"defaultInvoiceFrequency\"],false],[0,\" billing. This means their bills will magically come together \"],[1,[22,\"defaultLongFormFrequency\"],false],[0,\" based on appointments you’ve completed on your schedule. You’ll still be able to review and edit the drafts, and if a client doesn’t have any billable appointments, they won’t get a bill. \"],[4,\"if\",[[28,\"gt\",[[24,[\"company\",\"invoice_frequencies\",\"length\"]],1],null]],null,{\"statements\":[[0,\"You can also customize billing by client, since you selected multiple billing cycle options.\"]],\"parameters\":[]},null],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"button-container\"],[8],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"classNames\",\"style\",\"click\"],[\"override-all-button\",\"red\",[28,\"action\",[[23,0,[]],\"close\"],null]]],{\"statements\":[[0,\"          \"],[7,\"div\",true],[8],[0,\"\\n            Got it!\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/added-auto-bill-modal.hbs"
    }
  });

  _exports.default = _default;
});