define("frontend/controllers/invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    queryParams: ['filter', 'sentInvoiceType', 'selectedInvoiceType', 'clickedIntoSearch'],
    sentInvoiceType: 'sent',
    filter: '',
    clientListCollapsed: false,
    selectedInvoiceType: 'sent',
    clickedIntoSearch: false,
    headerOptions: [{
      label: 'Invoices',
      value: 'Invoices'
    }, {
      label: 'Payments',
      value: 'Payments'
    }],
    selectedHeader: 'Invoices',
    showInvoices: computed.equal('selectedHeader', 'Invoices'),
    currentUser: inject.service(),
    media: inject.service(),
    init: function init() {
      this._super.apply(this, arguments);

      Ember.run.schedule('afterRender', this, function () {
        this.send('hideClientListIfTablet');
      });
    },
    searching: computed('media.isMobile', 'clickedIntoSearch', function () {
      if (this.get('clickedIntoSearch')) {
        window.scrollTo(0, 0);
      }

      return !this.get('media.isMobile') || this.get('clickedIntoSearch');
    }),
    showPaymentsWidget: computed('currentUser', 'currentUser.user.company', function () {
      return this.get('currentUser.user.company.shouldShowPayments') || this.get('currentUser.user.shouldShowPayments');
    }),
    actions: {
      hideClientListIfTablet: function hideClientListIfTablet() {
        if (this.get('media.isTablet')) {
          this.send('hideClientList');
        }
      },
      hideClientList: function hideClientList() {
        this.set('clientListCollapsed', true);
      },
      showClientList: function showClientList() {
        this.set('clientListCollapsed', false);
      }
    }
  });

  _exports.default = _default;
});