define("frontend/templates/components/offered-service-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MnbPGk+m",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"label\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"percentage-bar-label provided-service-name\"],[8],[1,[22,\"label\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"offeredService\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"percentage-bar-label derived-service-name\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"percentage-bar-label-service-name\"],[8],[7,\"span\",true],[11,\"title\",[29,[[24,[\"offeredService\",\"name\"]]]]],[8],[4,\"if\",[[24,[\"offeredService\",\"is_add_on\"]]],null,{\"statements\":[[0,\"+ \"]],\"parameters\":[]},null],[1,[24,[\"offeredService\",\"name\"]],false],[9],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"percentage-bar-label-service-duration\"],[8],[0,\"(\"],[1,[24,[\"offeredService\",\"durationDisplay\"]],false],[0,\")\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[1,[28,\"percentage-bar\",null,[[\"value\",\"total\"],[[24,[\"fixedValue\"]],[24,[\"total\"]]]]],false],[7,\"span\",true],[10,\"class\",\"percentage-bar-amount\"],[8],[1,[22,\"valueLabel\"],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/offered-service-bar.hbs"
    }
  });

  _exports.default = _default;
});