define("frontend/templates/components/readonly-field-address", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SG6ph4Lp",
    "block": "{\"symbols\":[],\"statements\":[[4,\"readonly-field\",null,[[\"fieldType\",\"label\",\"allowEmpty\"],[\"address\",[24,[\"address\",\"address_type\"]],true]],{\"statements\":[[0,\"  \"],[1,[28,\"google-address-link\",null,[[\"address\"],[[24,[\"address\",\"fullAddress\"]]]]],false],[0,\"\\n  \"],[1,[28,\"google-map\",null,[[\"addressUrl\",\"address\"],[[24,[\"address\",\"static_maps_url\"]],[24,[\"address\",\"fullAddress\"]]]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"address\",\"notes\"]]],null,{\"statements\":[[0,\"    \"],[7,\"label\",true],[10,\"class\",\"readonly-field-address__about-location\"],[8],[0,\"Access instructions\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"readonly-field-address__notes\"],[8],[1,[28,\"format-line-breaks\",[[24,[\"address\",\"notes\"]]],null],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/readonly-field-address.hbs"
    }
  });

  _exports.default = _default;
});