define("frontend/templates/add-staff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eRHhhU5p",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"staff-new\"],[8],[0,\"\\n\"],[4,\"new-header\",null,[[\"cancel\"],[[28,\"route-action\",[\"transitionToStaff\"],null]]],{\"statements\":[[0,\"    \"],[7,\"h1\",true],[10,\"class\",\"header-text\"],[8],[0,\"Add new staff member\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[28,\"add-edit-staff\",null,[[\"defaultEmail\",\"offeredServiceAmounts\",\"model\",\"onSaveUser\",\"onCancel\",\"redirectToUser\",\"redirectToAddedUser\"],[[24,[\"email\"]],[24,[\"model\",\"offered_service_amounts\"]],[24,[\"model\"]],[28,\"route-action\",[\"transitionToStaff\"],null],[28,\"route-action\",[\"transitionToStaff\"],null],[28,\"route-action\",[\"redirectToUser\"],null],[28,\"route-action\",[\"redirectToAddedUser\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/add-staff.hbs"
    }
  });

  _exports.default = _default;
});