define("frontend/controllers/add-client/client-search", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Controller.extend({
    queryParams: ['householdId', 'email'],
    flashMessage: inject.service(),
    currentUser: inject.service(),
    householdId: null,
    email: null,
    showInviteClient: false,
    actions: {
      setEmail: function setEmail(email) {
        this.set('filter', email);
      },
      search: function search() {
        this.set('searchNow', true);
      },
      transitionToPrimary: function transitionToPrimary(searchedEmail) {
        this.transitionToRoute('add-client.primary-contact', {
          queryParams: {
            householdId: null,
            searchedEmail: searchedEmail
          }
        });
      },
      transitionToClientList: function transitionToClientList() {
        this.transitionToRoute('client-list');
      },
      triggerInviteClient: function triggerInviteClient() {
        this.set('searchedEmail', this.get('filter'));
        this.set('showInviteClient', true);
      },
      closeInviteClient: function closeInviteClient() {
        this.set('showInviteClient', false);
      },
      inviteClient: function inviteClient(email, firstName, lastName, emailType) {
        var _this = this;

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/invite_client"),
          method: 'POST',
          data: {
            email: email,
            firstName: firstName,
            lastName: lastName,
            emailType: emailType
          }
        }).done(function (response) {
          var directToDocumentsPage = _this.get('currentUser.user.company.client_contracts').content.length > 0;

          _this.send('redirectToAddedUser', response.household_id, directToDocumentsPage);

          _this.set('showInviteClient', false);

          _this.get('flashMessage').success("Invite sent to ".concat(email));
        });
      }
    }
  });

  _exports.default = _default;
});