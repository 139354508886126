define("frontend/components/alert-hint", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['alert-hint'],
    classNameBindings: ['error:alert-hint--error', 'deprecatedOldStyling'],
    hint: null,
    error: false,
    deprecatedOldStyling: false
  });

  _exports.default = _default;
});