define("frontend/mixins/dates-to-month-ranges", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    datesToMonthRanges: function datesToMonthRanges(dateArray) {
      var months = {};
      var sortedDates = dateArray.sort().map(function (date) {
        return moment(date);
      });
      sortedDates.forEach(function (date) {
        var month = date.format('MMMM');

        if (!months[month]) {
          months[month] = [];
        }

        months[month].push(date.format('DD'));
      });
      var dateRanges = [];
      Object.keys(months).forEach(function (month) {
        var days = months[month].sort().map(function (day) {
          return parseInt(day);
        });
        var ranges = [];

        for (var i = 0; i < days.length; i++) {
          var rangeStart = days[i];
          var rangeEnd = days[i];

          while (i + 1 < days.length && days[i] + 1 == days[i + 1]) {
            rangeEnd = days[i + 1];
            i += 1;
          }

          if (rangeStart == rangeEnd) {
            ranges.push(rangeStart);
          } else {
            ranges.push("".concat(rangeStart, "-").concat(rangeEnd));
          }
        }

        dateRanges.push("".concat(month, " ").concat(ranges.join(', ')));
      });
      return dateRanges.join('<br />');
    }
  });

  _exports.default = _default;
});