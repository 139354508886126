define("frontend/components/assign-staff-side-panel", ["exports", "frontend/config/environment", "ember-data", "ember-cp-validations", "frontend/helpers/truncate-text", "jquery"], function (_exports, _environment, _emberData, _emberCpValidations, _truncateText, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'selectedCareProvider': (0, _emberCpValidations.validator)('presence', true)
  });

  function getOfferedServiceOption(offeredService) {
    var label = "".concat((0, _truncateText.truncateText)([offeredService.get('name'), 40]), " (").concat(offeredService.get('durationDisplay'), ")");
    return {
      value: offeredService,
      label: label
    };
  }

  function getCareProviderOption(careProvider) {
    return {
      value: careProvider,
      label: careProvider.get('fullName')
    };
  }

  var _default = Ember.Component.extend(Validations, {
    classNames: ['assign-staff-side-panel'],
    careProviders: [],
    defaultCareProviderId: null,
    offeredServices: [],
    serviceCategories: [],
    appointmentTimesService: inject.service('appointment-times'),

    /**
     * While this does handle whether or not special notes are shown it also is overloaded with the concept of recurring
     * appointments showing up when this is set to false and one-time appointments showing up when this is set to true.
     *
     * A better name should be created to describe this concept without the need for this comment. Additionally when this
     * is done the conditional logic to show the {{appointment-group-select}} components in the template could be greatly
     * simplified.
     */
    showSpecialNotes: false,
    store: inject.service(),
    showSelectedCareProviderError: computed.and('showErrors', 'selectedCareProviderEmpty'),
    sidePanelAppointment: null,
    date: computed.readOnly('sidePanelAppointment.date'),
    selectedCareProvider: computed.alias('sidePanelAppointment.careProvider'),
    selectedOfferedService: computed.alias('sidePanelAppointment.offered_service'),
    selectedStartTime: computed.alias('sidePanelAppointment.time'),
    specialInstructions: computed.alias('sidePanelAppointment.special_instructions'),
    timeFrameStart: computed.alias('sidePanelAppointment.timeFrameStart'),
    timeFrameEnd: computed.alias('sidePanelAppointment.timeFrameEnd'),
    setByCustomWindow: computed.alias('sidePanelAppointment.setByCustomWindow'),
    selectedPosition: computed.alias('sidePanelAppointment.position'),
    selectedPositionsPreviousPets: computed.alias('sidePanelAppointment.positionsPreviousPets'),
    chargeForLateBooking: computed.alias('sidePanelAppointment.chargeForLateBooking'),
    addOnServices: computed.alias('sidePanelAppointment.addOnServices'),
    selectedCareProviderEmpty: computed.empty('selectedCareProvider'),
    hasErrors: computed.or('selectedCareProviderEmpty', 'selectedStartTimeInvalid'),
    showingRecurring: computed.not('showSpecialNotes'),
    showErrors: false,
    editing: false,
    headerText: null,
    existingAppointment: null,
    showSelectedStartTimeError: computed.and('showErrors', 'selectedStartTimeInvalid'),
    didInsertElement: function didInsertElement() {
      var appointment = this.get('sidePanelAppointment');

      if (appointment && appointment.offered_service) {
        this.set('selectedOfferedServiceOption', getOfferedServiceOption(appointment.offered_service));
      }

      if (appointment && appointment.careProvider) {
        this.set('selectedCareProviderOption', getCareProviderOption(appointment.careProvider));
      }

      if (appointment && appointment.time && appointment.careProvider) {
        this.set('originalStartTime', appointment.time);
        this.set('originalCareProvider', appointment.careProvider);
      }
    },
    selectedStartTimeInvalid: computed('selectedStartTime', function () {
      var strictParsing = true;
      var format = 'h:mm A';
      return Ember.isBlank(this.get('selectedStartTime')) || !moment(this.get('selectedStartTime'), format, strictParsing).isValid();
    }),
    selectedStartTimeObserver: observer('selectedStartTime', 'selectedOfferedService', function () {
      var startTime = this.get('selectedStartTime');

      if (!startTime) {
        return;
      }

      this.set('selectedPositionsPreviousPets', 'first');
      var serviceCategory = this.get('selectedOfferedService.service_category');

      if (!serviceCategory.get('use_time_blocks')) {
        return;
      }

      var startTimeMoment = moment(startTime, 'hh:mm a');

      if (this.get('setByCustomWindow')) {
        var timeFrameStart = moment(this.get('timeFrameStart'), 'h:mmA');
        var timeFrameEnd = moment(this.get('timeFrameEnd'), 'h:mmA');

        if (startTimeMoment >= timeFrameStart && startTimeMoment < timeFrameEnd) {
          return;
        }
      }

      var timeFrames = serviceCategory.get('time_frames');
      var dayOfWeek = moment(this.get('date'), 'YYYY-MM-DD').day();
      var potentialTimeFrames = timeFrames.filter(function (timeFrame) {
        var start = moment(timeFrame.get('start_time'), 'hh:mm a');
        var end = moment(timeFrame.get('end_time'), 'hh:mm a').subtract(1, 'second');
        return timeFrame.get('days_of_week').includes(dayOfWeek) && startTimeMoment >= start && startTimeMoment <= end;
      });
      var showStart = null;
      var showEnd = null;

      if (potentialTimeFrames.length >= 1) {
        showStart = potentialTimeFrames.get('firstObject').get('start_time').replace(' ', '');
        showEnd = potentialTimeFrames.get('firstObject').get('end_time').replace(' ', '');
      } else if (serviceCategory.get('time_window_in_minutes')) {
        var timeWindow = serviceCategory.get('time_window_in_minutes');
        showStart = moment(startTime, 'hh:mm a').subtract(timeWindow, 'minutes').format('h:mmA');
        showEnd = moment(startTime, 'hh:mm a').add(timeWindow, 'minutes').format('h:mmA');
      }

      if (showStart && showEnd) {
        this.set('timeFrameStart', showStart);
        this.set('timeFrameEnd', showEnd);
        this.set('setByCustomWindow', false);
      }
    }),
    preferredStaffSorting: ['position'],
    sortedPreferredStaff: computed.sort('preferredStaff', 'preferredStaffSorting'),
    careProviderOptions: computed.map('careProviders', getCareProviderOption),
    groupedCareProviderOptions: computed('careProviders.[]', 'preferredStaff.[]', function () {
      var _this = this;

      if (this.get('sortedPreferredStaff.length') > 0) {
        var preferred = [];
        var others = [];
        this.get('sortedPreferredStaff').forEach(function (preferredStaff) {
          if (!preferredStaff.get('isNew')) {
            _this.get('careProviderOptions').forEach(function (careProvider) {
              if (careProvider.label == preferredStaff.get('staff_name')) {
                preferred.pushObject(careProvider);
              }
            });
          }
        });
        this.get('careProviderOptions').forEach(function (careProvider) {
          var found = false;

          _this.get('sortedPreferredStaff').forEach(function (preferredStaff) {
            found = found || careProvider.label == preferredStaff.get('staff_name');
          });

          if (!found) {
            others.pushObject(careProvider);
          }
        });
        return [{
          groupName: 'Preferred',
          options: preferred
        }, {
          groupName: 'Others',
          options: others
        }];
      } else {
        return this.get('careProviders').map(getCareProviderOption);
      }
    }),
    nonAddOnOfferedServices: computed('offeredServices.[]', function () {
      return this.get('offeredServices').filter(function (offeredService) {
        return !offeredService.get('is_add_on');
      });
    }),
    addOnOfferedServices: computed('selectedOfferedService.service_category', function () {
      return this.get('selectedOfferedService.service_category.addOnOfferedServices').filter(function (offeredService) {
        return offeredService.get('is_add_on');
      });
    }),
    offeredServiceOptions: computed.map('nonAddOnOfferedServices', getOfferedServiceOption),
    addOnOfferedServiceOptions: computed.map('addOnOfferedServices', getOfferedServiceOption),
    startTimeOptions: computed('selectedCareProvider', 'date', function () {
      return this.get('appointmentTimesService').allTimes();
    }),
    oneTimeAppointmentGroups: computed('date', 'selectedCareProvider.id', function () {
      var care_provider_id = this.get('selectedCareProvider.id');
      var start_date = this.get('date');
      var end_date = this.get('date');
      var promise = this.get('store').query('service', {
        care_provider_id: care_provider_id,
        start_date: start_date,
        end_date: end_date
      });
      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    shownOneTimeAppointmentGroups: computed('oneTimeAppointmentGroups', 'selectedOfferedService.all_day_service', function () {
      if (this.get('selectedOfferedService.all_day_service')) {
        return this.get('oneTimeAppointmentGroups').filter(function (group) {
          return group.get('all_day_service');
        });
      } else {
        return this.get('oneTimeAppointmentGroups').filter(function (group) {
          return !group.get('all_day_service');
        });
      }
    }),
    selectedAppointmentGroup: computed('oneTimeAppointmentGroups.[]', 'selectedStartTime', function () {
      var _this2 = this;

      var ags = this.get('oneTimeAppointmentGroups');
      var group = ags.filter(function (ag) {
        return ag.get('starts_at_time') == _this2.get('selectedStartTime');
      }).get('firstObject');

      if (group) {
        var originalTime = this.get('originalStartTime') == this.get('selectedStartTime');
        var originalProvider = this.get('originalCareProvider') == this.get('selectedCareProvider');
        var previousPets = this.get('selectedPositionsPreviousPets');

        if (previousPets && originalTime && originalProvider) {
          if (previousPets == 'first') {
            this.set('selectedPosition', 0);
          } else {
            var groupedAppts = group.get('sortedGroupedAppointmentsWithoutCanceled');
            var found = false;

            for (var i = 0; i < groupedAppts.length; i++) {
              var groupedAppt = groupedAppts.objectAt(i);
              var petNames = groupedAppt.get('sortedAppointments').mapBy('pet.name').join('/');

              if (petNames == this.get('selectedPositionsPreviousPets')) {
                this.set('selectedPosition', i + 1);
                found = true;
              }
            }

            if (!found) {
              this.set('selectedPosition', 0);
            }
          }
        } else {
          this.set('selectedPosition', 0);
        }
      }

      return group;
    }),
    positionOptions: computed('selectedAppointmentGroup', function () {
      var group = this.get('selectedAppointmentGroup');

      if (!group) {
        return;
      }

      var groupedAppointments = group.get('sortedGroupedAppointmentsWithoutCanceled');
      var options = [{
        label: 'First',
        value: 0
      }];

      if (groupedAppointments.length > 1) {
        for (var i = 0; i < groupedAppointments.length - 1; i++) {
          var appt = groupedAppointments.objectAt(i);
          var apptPetNames = appt.get('sortedAppointments').map(function (appt) {
            return appt.get('pet.name');
          }).join('/');
          options.push({
            label: "After ".concat(apptPetNames),
            value: i + 1
          });
        }
      }

      options.push({
        label: 'Last',
        value: group.get('sortedGroupedAppointmentsWithoutCanceled').length
      });
      return options;
    }),
    selectedPositionLabelForMobile: computed('selectedPosition', 'positionOptions', function () {
      var positionOptions = this.get('positionOptions');
      var selectedPosition = this.get('selectedPosition');
      var foundObject = positionOptions.find(function (option) {
        return option.value === selectedPosition;
      });
      return foundObject;
    }),
    recurringAppointmentGroups: computed('date', 'selectedCareProvider.id', 'selectedOfferedService.all_day_service', function () {
      var care_provider_id = this.get('selectedCareProvider.id');
      var start_date = this.get('date');
      var end_date = this.get('date');
      var all_day_service = this.get('selectedOfferedService.all_day_service');

      var promise = _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/services/?care_provider_id=").concat(care_provider_id, "&recurring=true&start_date=").concat(start_date, "&end_date=").concat(end_date, "&all_day_service=").concat(all_day_service),
        method: 'GET'
      });

      return _emberData.default.PromiseArray.create({
        promise: promise
      });
    }),
    selectedRecurringGroup: computed('recurringAppointmentGroups.[]', 'selectedStartTime', 'existingAppointment', function () {
      var _this3 = this;

      var rgs = this.get('recurringAppointmentGroups');
      var group = rgs.filter(function (rg) {
        return rg.starts_at_time == _this3.get('selectedStartTime');
      }).get('firstObject');

      if (group) {
        var originalTime = this.get('originalStartTime') == this.get('selectedStartTime');
        var originalProvider = this.get('originalCareProvider') == this.get('selectedCareProvider');
        var previousPets = this.get('selectedPositionsPreviousPets');
        var existingAppt = this.get('existingAppointment');

        if (previousPets) {
          if (previousPets == 'first') {
            this.set('selectedPosition', 0);
          } else if (previousPets == 'last') {
            this.set('selectedPosition', group.grouped_appointments.length);
          } else {
            var groupedAppts = group.grouped_appointments;
            var found = false;

            for (var i = 0; i < groupedAppts.length; i++) {
              var groupedAppt = groupedAppts.objectAt(i);
              var petNames = groupedAppt.mapBy('pet.name').join('/');

              if (petNames == this.get('selectedPositionsPreviousPets')) {
                if (i == groupedAppts.length - 1) {
                  this.set('selectedPosition', i);
                } else {
                  this.set('selectedPosition', i + 1);
                }

                found = true;
              }
            }

            if (!found) {
              this.set('selectedPosition', 0);
              this.set('selectedPositionsPreviousPets', 'first');
            }
          }
        } else if (originalTime && originalProvider && existingAppt) {
          var _groupedAppts = group.grouped_appointments;
          var _found = false;

          for (var _i = 0; _i < _groupedAppts.length; _i++) {
            var _groupedAppt = _groupedAppts.objectAt(_i);

            var ids = _groupedAppt.map(function (appt) {
              return appt.recurring_appointment_id;
            });

            if (ids.includes(parseInt(existingAppt.get('id')))) {
              this.set('selectedPosition', _i);
              _found = true;
            }
          }

          if (!_found) {
            this.set('selectedPosition', 0);
            this.set('selectedPositionsPreviousPets', 'first');
          }
        } else {
          this.set('selectedPosition', 0);
          this.set('selectedPositionsPreviousPets', 'first');
        }
      }

      return group;
    }),
    recurringPositionOptions: computed('selectedRecurringGroup', 'existingAppointment', function () {
      var group = this.get('selectedRecurringGroup');

      if (!group) {
        return;
      }

      var groupedAppointments = group.grouped_appointments;
      var existingAppt = this.get('existingAppointment');

      if (existingAppt) {
        var existingApptId = parseInt(existingAppt.get('id'));

        var getApptIds = function getApptIds(appts) {
          return appts.map(function (appt) {
            return appt.recurring_appointment_id;
          });
        };

        groupedAppointments = groupedAppointments.reject(function (ga) {
          return getApptIds(ga).includes(existingApptId);
        });
      }

      var options = [{
        label: 'First',
        value: 0
      }];

      if (groupedAppointments.length > 1) {
        for (var i = 0; i < groupedAppointments.length - 1; i++) {
          var appts = groupedAppointments.objectAt(i);
          var apptPetNames = appts.map(function (appt) {
            return appt.pet.name;
          }).join('/');
          options.push({
            label: "After ".concat(apptPetNames),
            value: i + 1
          });
        }
      }

      options.push({
        label: 'Last',
        value: groupedAppointments.length
      });
      return options;
    }),
    appointment: function appointment() {
      var date = this.get('date');
      var careProvider = this.get('selectedCareProvider');
      var time = this.get('selectedStartTime');
      var special_instructions = this.get('specialInstructions');
      var offered_service = this.get('selectedOfferedService');
      var timeFrameStart = this.get('timeFrameStart');
      var timeFrameEnd = this.get('timeFrameEnd');
      var setByCustomWindow = this.get('setByCustomWindow');
      var positionsPreviousPets = this.get('selectedPositionsPreviousPets');
      var chargeForLateBooking = this.get('chargeForLateBooking');
      var addOnServices = this.get('addOnServices').filter(function (aos) {
        return aos.get('offered_service.id');
      });
      return {
        date: date,
        careProvider: careProvider,
        time: time,
        special_instructions: special_instructions,
        offered_service: offered_service,
        timeFrameStart: timeFrameStart,
        timeFrameEnd: timeFrameEnd,
        setByCustomWindow: setByCustomWindow,
        positionsPreviousPets: positionsPreviousPets,
        chargeForLateBooking: chargeForLateBooking,
        addOnServices: addOnServices
      };
    },
    checkForErrors: function checkForErrors() {
      var _this4 = this;

      if (this.get('hasErrors')) {
        this.set('showErrors', true);
        Ember.run.scheduleOnce('afterRender', function () {
          var _jQuery = (0, _jquery.default)(_this4.element.querySelectorAll('.red-validation-error')),
              _jQuery2 = _slicedToArray(_jQuery, 1),
              ele = _jQuery2[0];

          if (ele) {
            ele.scrollIntoView(false);
          }
        });
        return true;
      }

      return false;
    },
    showTimeWindowText: computed.or('hasAppointmentGroups', 'timeFrameStart'),
    hasAppointmentGroups: computed('showSpecialNotes', 'shownOneTimeAppointmentGroups', 'oneTimeAppointmentGroups.isFulfilled', 'recurringAppointmentGroups.isFulfilled', function () {
      if (this.get('showSpecialNotes') && this.get('oneTimeAppointmentGroups.isFulfilled')) {
        return this.get('shownOneTimeAppointmentGroups.length') > 0;
      } else if (!this.get('showSpecialNotes') && this.get('recurringAppointmentGroups.isFulfilled')) {
        return this.get('recurringAppointmentGroups.length') > 0;
      } else {
        return false;
      }
    }),
    useTimeHelperClass: computed.and('hasAppointmentGroups', 'timeFrameStart'),
    saveAppointmentCallback: function saveAppointmentCallback(createOrUpdate, appt) {
      if (createOrUpdate == "create") {
        this.createAppointment(appt);
      } else {
        this.updateAppointment(appt);
      }
    },
    showAddAddOnServiceButton: computed('addOnServices.[]', 'selectedOfferedService.service_category', function () {
      return this.get('addOnServices.length') == 0 && this.get('selectedOfferedService.service_category.hasAddOnServices');
    }),
    showAddAnotherServiceButton: computed('addOnServices.[]', 'selectedOfferedService.service_category', function () {
      return this.get('addOnServices.length') > 0 && this.get('selectedOfferedService.service_category.hasAddOnServices') && this.get('addOnServices.length') < this.get('addOnOfferedServiceOptions.length');
    }),
    addOnServicesSelected: computed('addOnServices.@each.offered_service', function () {
      return this.get('addOnServices').mapBy('offered_service.id');
    }),
    actions: {
      close: function close() {
        this.close();
      },
      clickCreateAddOnService: function clickCreateAddOnService() {
        if (this.get('pets.length') > 1) {
          this.set('showPickPetsModal', true);
        } else {
          this.send('createNewAddOnService', this.get('pets'));
        }
      },
      closePickPetsModal: function closePickPetsModal() {
        this.set('showPickPetsModal', false);
      },
      createNewAddOnService: function createNewAddOnService(pets) {
        this.set('showPickPetsModal', false);
        var addOnService = this.get('createNewStoreAddOnService')(pets);

        if (this.get('addOnOfferedServiceOptions.length') == 1) {
          addOnService.set('offered_service', this.get('addOnOfferedServiceOptions.firstObject.value'));
        }

        this.get('addOnServices').pushObject(addOnService);
      },
      selectAddOnServiceOption: function selectAddOnServiceOption(addOnService, option) {
        var os = option.value ? option.value : option;
        addOnService.set('offered_service', os);
      },
      selectServiceOption: function selectServiceOption(option) {
        if (option.value.get('service_category_id') != this.get('selectedOfferedService.service_category_id')) {
          this.get('addOnServices').clear();
        }

        this.set('selectedOfferedService', option.value);
        this.set('selectedOfferedServiceOption', option);

        if (this.get('selectedOfferedService.all_day_service')) {
          this.set('selectedStartTime', '12:00 AM');
        } else {
          this.set('selectedStartTime', null);
        }
      },
      selectCareProviderOption: function selectCareProviderOption(option) {
        if (option.value) {
          this.set('selectedCareProvider', option.value);
        } else {
          this.set('selectedCareProvider', option);
        }

        this.set('selectedCareProviderOption', option);
      },
      openCustomizeTimeWindowModal: function openCustomizeTimeWindowModal() {
        this.set('originalTimeWindowStart', this.get('timeFrameStart'));
        this.set('originalTimeWindowEnd', this.get('timeFrameEnd'));
        this.set('showTimeWindowModal', true);
      },
      closeCustomizeTimeWindowModal: function closeCustomizeTimeWindowModal(shouldUpdate) {
        if (shouldUpdate) {
          this.set('setByCustomWindow', true);
        } else {
          this.set('timeFrameStart', this.get('originalTimeWindowStart'));
          this.set('timeFrameEnd', this.get('originalTimeWindowEnd'));
        }

        this.set('originalTimeWindowStart', null);
        this.set('originalTimeWindowEnd', null);
        this.set('showTimeWindowModal', false);
      },
      removeAppointment: function removeAppointment() {
        this.removeAppointment(this.appointment());
      },
      removeAddOnService: function removeAddOnService(addOnService) {
        this.get('addOnServices').removeObject(addOnService);
      },
      saveAppointment: function saveAppointment() {
        var _this5 = this;

        var createOrUpdate = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "create";

        if (!this.checkForErrors()) {
          this.set('saving', true);
          this.set('createOrUpdateAppointment', createOrUpdate);
          var appt = this.appointment();

          if (this.get('showingRecurring')) {
            this.saveAppointmentCallback(createOrUpdate, appt);
          } else {
            _jquery.default.ajax({
              url: "".concat(_environment.default.APP.host, "/api/v2/appointments/late_booking_fee?starts_at=").concat(appt.time, "&date=").concat(appt.date, "&os_id=").concat(appt.offered_service.id),
              method: 'GET'
            }).then(function (response) {
              _this5.set('saving', false);

              if (response.is_late_booking) {
                _this5.set('lateBookingPolicy', response.policy);

                _this5.set('showLateBookingAlertModal', true);
              } else {
                _this5.saveAppointmentCallback(createOrUpdate, appt);
              }
            });
          }
        }
      },
      closeLateBookingAlertModal: function closeLateBookingAlertModal(chargeFee) {
        this.set('chargeForLateBooking', chargeFee);
        this.set('showLateBookingAlertModal', false);
        var createOrUpdate = this.get('createOrUpdateAppointment');
        var appt = this.appointment();
        this.set('createOrUpdateAppointment', null);

        if (createOrUpdate == "create") {
          this.createAppointment(appt);
        } else {
          this.updateAppointment(appt);
        }
      },
      selectOfferedService: function selectOfferedService(offeredService, previousService) {
        if (offeredService.get('service_category_id') != previousService.get('service_category_id')) {
          this.get('addOnServices').clear();
        }

        if (offeredService.get('all_day_service') && !previousService.get('all_day_service')) {
          this.set('selectedStartTime', '12:00 AM');
        }

        if (!offeredService.get('all_day_service') && previousService.get('all_day_service')) {
          this.set('selectedStartTime', null);
        }
      },
      updateSelectedTime: function updateSelectedTime(selectedTime) {
        // The customize time window modal uses timepickers with a different
        // format than the one we're using in this component but when the modal
        // is inserted it somehow overrides this components timepicker's format.
        // This will check to make sure that we are always using the expected
        // format for start times
        var badFormat = 'h:mmA';
        var badMoment = moment(selectedTime, badFormat, true);

        if (badMoment.isValid()) {
          selectedTime = badMoment.format('h:mm A');
        }

        this.set('selectedStartTime', selectedTime);
      },
      selectNewPosition: function selectNewPosition(selected) {
        var selectedPosition = selected;
        if (typeof selected !== 'number') selectedPosition = selected.value;
        var group = this.get('selectedAppointmentGroup');
        var recurringGroup = this.get('selectedRecurringGroup');
        var showingRecurring = this.get('showingRecurring');
        var groupLength = showingRecurring ? recurringGroup.grouped_appointments.length : group.get('sortedGroupedAppointmentsWithoutCanceled').length;

        if (selectedPosition == 0) {
          this.set('selectedPositionsPreviousPets', 'first');
        } else if (selectedPosition == groupLength) {
          this.set('selectedPositionsPreviousPets', 'last');
        } else {
          var petNames = '';

          if (showingRecurring) {
            var groupedAppts = recurringGroup.grouped_appointments;
            var existingAppt = this.get('existingAppointment');

            if (existingAppt) {
              var existingApptId = parseInt(existingAppt.get('id'));

              var getApptIds = function getApptIds(appts) {
                return appts.map(function (appt) {
                  return appt.recurring_appointment_id;
                });
              };

              groupedAppts = groupedAppts.reject(function (ga) {
                return getApptIds(ga).includes(existingApptId);
              });
            }

            var previousAppt = groupedAppts.objectAt(selectedPosition - 1);
            petNames = previousAppt.mapBy('pet.name').sort().join('/');
          } else {
            var _previousAppt = group.get('sortedGroupedAppointmentsWithoutCanceled').objectAt(selectedPosition - 1);

            petNames = _previousAppt.get('sortedAppointments').mapBy('pet.name').join('/');
          }

          this.set('selectedPositionsPreviousPets', petNames);
        }

        this.set('selectedPosition', selectedPosition);
        var positionOptions = this.get('positionOptions');
        var foundObject = positionOptions.find(function (option) {
          return option.value === selectedPosition;
        });
        this.set('selectedPositionLabelForMobile', foundObject);
      }
    }
  });

  _exports.default = _default;
});