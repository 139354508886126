define("frontend/components/emergency-contact-modal", ["exports", "frontend/utils/email-type"], function (_exports, _emailType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var EMAIL_REGEX = /.+@.+\..+/;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend({
    countryInfo: inject.service(),
    store: inject.service(),
    ajax: inject.service(),
    states: computed.readOnly('countryInfo.states'),
    address: computed.alias('emergencyContact.address'),
    editingAddress: null,
    phoneTypes: [{
      label: 'Mobile',
      value: 'Mobile'
    }, {
      label: 'Work',
      value: 'Work'
    }, {
      label: 'Home',
      value: 'Home'
    }, {
      label: 'Other',
      value: 'Other'
    }],
    emailTypes: [{
      label: 'Preferred',
      value: _emailType.default.PREFERRED
    }, {
      label: 'Alternate',
      value: _emailType.default.ALTERNATE
    }],
    emailValidation: [{
      message: '*must be a valid email',
      validate: function validate(inputValue) {
        return EMAIL_REGEX.test(inputValue) || !inputValue || inputValue.trim() === '';
      }
    }],
    emails: computed('emergencyContact.emails.@each.isDeleted', function () {
      return this.get('emergencyContact.emails').filterBy('isDeleted', false);
    }),
    addresses: computed('emergencyContact.addresses.@each.isDeleted', function () {
      return this.get('emergencyContact.addresses').filterBy('isDeleted', false);
    }),
    phoneNumbers: computed('emergencyContact.phone_numbers.@each.isDeleted', function () {
      return this.get('emergencyContact.phone_numbers').filterBy('isDeleted', false);
    }),
    actions: {
      onClose: function onClose() {
        if (this.get('saving')) {
          return;
        }

        this.get('onClose')();
      },
      onSubmit: function onSubmit() {
        var _this = this;

        this.set('saving', true);
        this.get('emergencyContact').save().then(function (emergencyContact) {
          emergencyContact.get('phone_numbers').filterBy('id', null).invoke('deleteRecord');
          emergencyContact.get('emails').filterBy('id', null).invoke('deleteRecord');
          emergencyContact.get('addresses').filterBy('id', null).invoke('deleteRecord');

          _this.get('onSubmit')(emergencyContact);
        }).finally(function () {
          _this.set('saving', false);
        });
      },
      changePhoneType: function changePhoneType(phone, phoneType) {
        phone.set('phone_type', phoneType.value);
      },
      deleteRecord: function deleteRecord(record) {
        record.deleteRecord();
      },
      addPhone: function addPhone() {
        var newPhone = this.get('store').createRecord('emergency-contact-phone-number');
        this.get('emergencyContact.phone_numbers').pushObject(newPhone);
      },
      changeEmailType: function changeEmailType(email, emailType) {
        var hasPreferredEmail = this.get('emails').toArray().some(function (email) {
          return email.get('email_type') === _emailType.default.PREFERRED;
        });

        if (hasPreferredEmail && emailType.value === _emailType.default.PREFERRED) {
          this.get('emails').forEach(function (email) {
            return email.set('email_type', _emailType.default.ALTERNATE);
          });
        }

        email.set('email_type', emailType.value);
        hasPreferredEmail = this.get('emails').toArray().some(function (email) {
          return email.get('email_type') === _emailType.default.PREFERRED;
        });

        if (!hasPreferredEmail) {
          email.set('errs', []);
          this.get('emails').filter(function (filteredEmail) {
            return filteredEmail.get('address') !== email.get('address');
          }).get('firstObject').set('email_type', _emailType.default.PREFERRED);
        }
      },
      deleteEmail: function deleteEmail(email) {
        email.deleteRecord();

        if (email.get('email_type') === _emailType.default.PREFERRED) {
          this.get('emails').get('firstObject').set('email_type', _emailType.default.PREFERRED);
        }
      },
      addEmail: function addEmail() {
        var newEmail = this.get('store').createRecord('emergency-contact-email');
        this.get('emergencyContact.emails').pushObject(newEmail);
      },
      addAddress: function addAddress() {
        var state = this.get('currentUser.user.company.state');
        var newAddress = this.get('store').createRecord('emergency-contact-address', {
          state: state
        });
        this.get('emergencyContact.addresses').pushObject(newAddress);
      },
      rollbackAddress: function rollbackAddress() {
        if (!this.get('editingAddress.isNew')) {
          this.get('editingAddress').rollbackAttributes();
        }

        this.set('editingAddress', null);

        if (this.get('emergencyContact.addresses.length') === 0) {
          this.send('addAddress');
        }
      },
      uploadedEmergencyContactPhoto: function uploadedEmergencyContactPhoto(photo) {
        this.set('emergencyContact.avatar_url', photo);
      },
      submitAddress: function submitAddress() {
        this.set('editingAddress', null);
      }
    }
  });

  _exports.default = _default;
});