define("frontend/components/staff-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['staff-list'],
    tagName: 'ul',
    filter: '',
    selectedStaff: null,
    searchFilter: '',
    allStaff: [],
    sortStaff: function sortStaff(a, b) {
      var fullNameA = "".concat(a.get('getFirstName'), " ").concat(a.get('last_name')).toLowerCase();
      var fullNameB = "".concat(b.get('getFirstName'), " ").concat(b.get('last_name')).toLowerCase();

      if (fullNameA < fullNameB) {
        return -1;
      } else if (fullNameA > fullNameB) {
        return 1;
      } else {
        return 0;
      }
    },
    filteredStaff: computed('searchFilter', 'allStaff', 'allStaff.@each.getFirstName', 'allStaff.@each.last_name', function () {
      var _this = this;

      return this.get('allStaff').filter(function (staff) {
        var filter = _this.get('searchFilter') || '';
        filter = filter.toLowerCase().trim();
        var firstName = "".concat(staff.get('getFirstName'), "}").toLowerCase().slice(0, filter.length);
        var lastName = "".concat(staff.get('last_name')).toLowerCase().slice(0, filter.length);
        var fullName = "".concat(staff.get('getFirstName'), " ").concat(staff.get('last_name')).toLowerCase().slice(0, filter.length);
        return firstName.indexOf(filter) >= 0 || lastName.indexOf(filter) >= 0 || fullName.indexOf(filter) >= 0;
      }).sort(this.sortStaff);
    }),
    actions: {
      clickStaff: function clickStaff(staff) {
        this.clickStaff(staff);
      },
      addEmployee: function addEmployee() {
        this.addEmployee();
      }
    }
  });

  _exports.default = _default;
});