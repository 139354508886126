define("frontend/components/ppb-paper-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ppb-paper-input'],
    disabled: false,
    textarea: false,
    clarificationNote: null,
    label: '',
    actions: {
      onChange: function onChange() {
        this.get('onChange').apply(void 0, arguments);
      }
    }
  });

  _exports.default = _default;
});