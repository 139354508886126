define("frontend/utils/calendar-util", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var SCROLL_SPEED = 6;
  var _default = {
    resizeExistingHeaders: function resizeExistingHeaders() {
      var _this = this;

      Ember.run.scheduleOnce('afterRender', function () {
        $('.column').each(function (index, column) {
          var unfixedHeader = $(column).find('.calendar-header-item:not(.fixed)');
          var fixedHeader = $(column).find('.calendar-header-item.fixed');
          var unfixedWidth = unfixedHeader.width();
          fixedHeader.width(unfixedWidth);

          _this.translateHeaderWithScroll(fixedHeader);
        });
      });
    },
    initHeaders: function initHeaders() {
      var _this2 = this;

      Ember.run.scheduleOnce('afterRender', function () {
        if (!$('.column')) {
          Bugsnag.notify('Notice', 'initHeaders .column is null (see the code for this notify)');
          return;
        }

        $('.column').each(function (index, column) {
          if ($(column).find('.calendar-header-item.fixed').length > 0) {
            return;
          }

          var unfixedHeader = $(column).find('.calendar-header-item');
          var fixedHeader = unfixedHeader.clone();
          fixedHeader.addClass('fixed');
          fixedHeader.width(unfixedHeader.width() + 1);
          fixedHeader.prependTo(column);

          _this2.translateHeaderWithScroll(fixedHeader);
        });
      });
    },
    translateHeaderWithScroll: function translateHeaderWithScroll(header) {
      header.css({
        'transform': "translateX(-".concat($('.calendar-body').scrollLeft(), "px)")
      });
    },
    teardownTimeScrolling: function teardownTimeScrolling() {
      $('.calendar-body').off('scroll');
    },
    initScrollArrows: function initScrollArrows() {
      var setupScrollArrow = function setupScrollArrow(selector, cb) {
        var scrolling = false;
        $(selector).on('mouseover dragenter', function () {
          if (scrolling) {
            return;
          }

          $(selector).addClass('hover');
          scrolling = true;

          var scroll = function scroll() {
            cb();
            window.requestAnimationFrame(function () {
              if (scrolling) {
                scroll();
              }
            });
          };

          scroll();
        });
        $(selector).on('mouseout dragleave', function () {
          $(selector).removeClass('hover');
          scrolling = false;
        });
      };

      setupScrollArrow('.right-scroll', function () {
        $('.calendar-body')[0].scrollLeft += SCROLL_SPEED;
      });
      setupScrollArrow('.left-scroll', function () {
        $('.calendar-body')[0].scrollLeft -= SCROLL_SPEED;
      });
    },
    teardownScrollArrows: function teardownScrollArrows() {
      $('.right-scroll').off('mouseover mouseout dragenter dragleave');
      $('.left-scroll').off('mouseover mouseout dragenter dragleave');
    },
    initScrollHandler: function initScrollHandler(cb) {
      $('.time-column').css({
        'transform': "translateY(-".concat($('.calendar-body').scrollTop(), "px)")
      });
      $('.calendar-body').scroll(function () {
        Ember.run.throttle(function () {
          $('.time-column').css({
            'transform': "translateY(-".concat($('.calendar-body').scrollTop(), "px)")
          });
          $('.calendar-header-item.fixed').css({
            'transform': "translateX(-".concat($('.calendar-body').scrollLeft(), "px)")
          });
          cb();
        }, 100);
      });
    }
  };
  _exports.default = _default;
});