define("frontend/templates/components/pet-tab-pane", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "lsKAnWP2",
    "block": "{\"symbols\":[],\"statements\":[[0,\"Name: \"],[7,\"span\",true],[10,\"class\",\"pet-name\"],[8],[1,[24,[\"pet\",\"name\"]],false],[9],[0,\"\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\nUser Notes: \"],[7,\"span\",true],[10,\"class\",\"pet-user-notes\"],[8],[1,[24,[\"pet\",\"client_notes\"]],false],[9],[0,\"\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\nInternal Notes: \"],[7,\"span\",true],[10,\"class\",\"internal-notes\"],[8],[1,[24,[\"pet\",\"internal_user_notes\"]],false],[9],[0,\"\\n\"],[7,\"hr\",true],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/pet-tab-pane.hbs"
    }
  });

  _exports.default = _default;
});