define("frontend/components/decline-appointment-request-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    currentUser: inject.service(),
    appointmentRequest: null,
    offeredService: computed.alias('appointmentRequest.offered_service'),
    pickupWindow: computed.alias('appointmentRequest.pickupWindow'),
    possessiveName: computed('appointmentRequest.who_requested_first_name', function () {
      var name = this.get('appointmentRequest.who_requested_first_name');

      if (name.slice(-1) == 's') {
        return "".concat(name, "'");
      }

      return "".concat(name, "'s");
    }),
    formattedDate: computed('appointmentRequest.starts_at', function () {
      var date = moment.parseZone(this.get('appointmentRequest.starts_at')).startOf('day');
      var today = moment().startOf('day');

      if (date.diff(today, 'days') === 0) {
        return 'today';
      } else if (date.diff(today, 'days') === 1) {
        return 'tomorrow';
      } else {
        return "on ".concat(date.format('ddd, MMM D'));
      }
    }),
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);

      this.set('decliningAppointment', false);
      this.set('appointmentRequest.declined_note', this.initialNote());
    },
    initialNote: function initialNote() {
      var request = this.get('appointmentRequest');
      return "Hi ".concat(request.get('who_requested_first_name'), "!\nI\u2019m so sorry we won\u2019t be able to fit ").concat(request.get('petNames'), " in ").concat(this.get('formattedDate'), "! Looking forward to seeing them again hopefully soon!\nTake care!\n").concat(this.get('currentUser.user.getFirstName'));
    },
    actions: {
      declineAppointmentRequest: function declineAppointmentRequest() {
        this.set('decliningAppointment', true);
        this.get('declineAppointmentRequest')();
      },
      onClose: function onClose() {
        this.get('close')();
      }
    }
  });

  _exports.default = _default;
});