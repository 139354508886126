define("frontend/components/invoice-receipt", ["exports", "frontend/config/environment", "ember-data", "jquery"], function (_exports, _environment, _emberData, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer;

  var _default = Ember.Component.extend({
    classNames: ['invoice-receipt'],
    classNameBindings: ['editingInvoice'],
    store: inject.service(),
    invoiceDetail: computed.readOnly('invoice.invoice_detail'),
    hasAdjustments: computed.gt('activeAdjustments.length', 0),
    activeAdjustments: computed.filterBy('invoiceDetail.invoice_adjustments', 'markedForDeletion', false),
    editingInvoice: false,
    wasClosedAndHasSalesTax: computed.and('invoice.closed', 'invoiceDetail.hasSalesTax'),
    allAppointmentsAreIncomplete: computed('invoice.invoice_detail.appointments.@each', function () {
      var appointments = this.get('invoice.invoice_detail.appointments');
      if (appointments.length === 0) return false;
      return appointments.isEvery('completed', false);
    }),
    autoDeleteTooltipMessage: "This draft will be deleted automatically at the end of the billing period if you don’t mark complete any appointments on it.",
    computedAdjustmentsAmount: computed('invoice.invoice_detail.invoice_adjustments.@each.{amount,markedForDeletion}', function () {
      var adjustments = this.get('invoice.invoice_detail.invoice_adjustments').filterBy('markedForDeletion', false);
      return adjustments.reduce(function (s, ia) {
        return s += parseFloat(ia.get('amount') || 0);
      }, 0);
    }),
    contentIsLoading: computed.not('invoice.invoice_detail.content.isLoaded'),
    computedAppointmentsAmount: computed('invoice.invoice_detail.appointments.@each.completed', 'invoice.invoice_detail.invoice_adjustments.@each.{amount,markedForDeletion}', function () {
      if (!this.get('invoice.invoice_detail.content.isLoaded')) {
        return {
          isFulfilled: false
        };
      }

      var appointments = this.get('invoice.invoice_detail.appointments').filterBy('completed', true).map(function (appointment) {
        return appointment.get('id');
      });
      var adjustments = this.get('invoice.invoice_detail.invoice_adjustments').filter(function (ia) {
        return !ia.get('markedForDeletion') && !ia.get('is_sales_tax');
      });

      var promise = _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/appointments/calculate_price"),
        method: 'POST',
        dataType: 'json',
        contentType: 'application/json',
        data: JSON.stringify({
          appointments: appointments,
          adjustments: adjustments,
          invoice_id: this.get('invoice.id')
        })
      });

      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    computedAmount: computed('computedAdjustmentsAmount', 'computedAppointmentsAmount.isFulfilled', 'invoiceDetail.total_credit', function () {
      if (!this.get('computedAppointmentsAmount.isFulfilled')) {
        return false;
      }

      var totalAppointmentsPrice = 0;
      var offeredServicePrices = this.get('computedAppointmentsAmount.offered_service_prices');
      var vals = Object.keys(offeredServicePrices).map(function (key) {
        return offeredServicePrices[key];
      });
      totalAppointmentsPrice = vals.reduce(function (a, b) {
        return a + b;
      }, 0);
      return this.get('computedAdjustmentsAmount') + totalAppointmentsPrice - this.get('invoiceDetail.total_credit');
    }),
    salesTaxObserver: observer('computedAppointmentsAmount', 'computedAppointmentsAmount.isFulfilled', function () {
      if (!this.get('computedAppointmentsAmount.isFulfilled')) {
        return;
      }

      var salesTax = this.get('computedAppointmentsAmount.sales_tax');
      var taxRate = this.get('computedAppointmentsAmount.rate');
      var salesTaxAdjustment = this.get('invoice.invoice_detail.invoice_adjustments').filter(function (ia) {
        return ia.get('is_sales_tax');
      }).get('firstObject');

      if (salesTaxAdjustment) {
        if (salesTax <= 0) {
          salesTaxAdjustment.destroyRecord();
        } else {
          salesTaxAdjustment.set('amount', salesTax);
        }
      } else if (salesTax > 0) {
        var ia = this.get('store').createRecord('invoice-adjustment', {
          amount: salesTax,
          description: 'Sales Tax',
          is_sales_tax: true,
          sales_tax_rate: taxRate
        });
        this.get('invoice.invoice_detail.invoice_adjustments').pushObject(ia);
      }
    }),
    actions: {
      clickAppointment: function clickAppointment() {
        if (this.get('clickAppointment')) {
          this.get('clickAppointment').apply(void 0, arguments);
        }
      },
      resend: function resend() {
        this.get('resendInvoice')();
      },
      voidInvoice: function voidInvoice() {
        this.get('voidInvoice')();
      }
    }
  });

  _exports.default = _default;
});