define("frontend/components/invoice-receipt/surcharge-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['adjustment-list-item', 'surcharge-adjustment'],
    classNameBindings: ['editing', 'multipleSurcharges', 'shouldIndent:indent-adjustments'],
    flashMessage: inject.service(),
    multipleSurcharges: computed.gt('groupedSurcharges.length', 1),
    surchargeSorting: ['appointment_sortable_date'],
    sortedGroupedSurcharges: computed.sort('groupedSurcharges', 'surchargeSorting'),
    sortedSurcharges: computed.sort('surcharges', 'surchargeSorting'),
    collapsed: true,
    selectedAdjustment: null,
    shouldIndent: computed('indentAdjustments', 'multipleSurcharges', 'showEdit', function () {
      return this.get('indentAdjustments') && !this.get('multipleSurcharges') && !this.get('showEdit');
    }),
    totalSurcharge: computed('isCancellationFee', 'surcharges.@each.amount', 'groupedSurcharges.[]', 'groupedSurcharges.@each.amount', function () {
      if (this.get('isCancellationFee')) {
        return this.get('surcharges').reduce(function (a, b) {
          return a + parseFloat(b.get('amount'));
        }, 0);
      } else {
        return this.get('groupedSurcharges').reduce(function (a, b) {
          return a + parseFloat(b.get('amount'));
        }, 0);
      }
    }),
    confirmRemoveSurcharge: function confirmRemoveSurcharge() {
      var surcharge = this.get('selectedSurcharge');
      surcharge.get('adjustments').forEach(function (adjustment) {
        adjustment.set('markedForDeletion', true);
      });
      this.set('selectedSurcharge.selected', false);
      this.set('selectedSurcharge', null);
    },
    rejectRemoveSurcharge: function rejectRemoveSurcharge() {
      this.set('selectedSurcharge.selected', false);
      this.set('selectedSurcharge', null);
    },
    actions: {
      removeSurcharge: function removeSurcharge(surcharge) {
        var _this = this;

        surcharge.set('selected', true);
        this.set('selectedSurcharge', surcharge);

        var yes = function yes() {
          return _this.confirmRemoveSurcharge.bind(_this)();
        };

        var no = function no() {
          return _this.rejectRemoveSurcharge.bind(_this)();
        };

        var message = "Are you sure you\u2019d like to remove the $".concat(surcharge.get('amount'), " ").concat(this.get('surchargeType').toLowerCase(), " for your ").concat(surcharge.get('categoryName'), " on ").concat(surcharge.get('appointment_date'), "?");
        this.get('flashMessage').confirm(yes, no, message, 'Remove', 'Cancel');
      }
    }
  });

  _exports.default = _default;
});