define("frontend/components/drag-drop-zone", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['drag-drop-zone'],
    classNameBindings: ['isHovering'],
    store: inject.service(),

    /**
     * Controls whether or not the drop zone is being hovered over to show appropriate styles.
     */
    isHovering: false,

    /**
     * Controls whether the drop zone should take up the full height of space available to it
     */
    isFullHeight: false,

    /**
     * Controls whether the drop zone should take up half the height of space available to it
     */
    isHalfHeight: false,

    /**
     * Controls what prompt is shown to the user for what happens if an element is dropped into this zone
     */
    dropText: '',

    /**
     * Holds a reference to the listener used to adjust drop zone size on screen size adjustments to ensure
     * that when we destroy these elements the listener can be removed and isn't needlessly running.
     */
    adjustDropZoneHeightReference: null,
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      this.setupInteract = this.setupInteract.bind(this);
      this.teardownInteract = this.teardownInteract.bind(this);
      this.setupInteract();

      var adjustDropZoneHeight = function adjustDropZoneHeight() {
        var parentHeight = (0, _jquery.default)(_this2.element).parent().height(); // our drag zone container height is the parent height less the height of the calendar and margins

        var dragContainerHeight = parentHeight - (258 + 24 + 32);

        if (_this2.get('isHalfHeight')) {
          // if there are 2 drop zones then that means there's an extra margin we need to account for
          dragContainerHeight = (dragContainerHeight - 32) / 2;
        }

        (0, _jquery.default)(_this2.element).height(dragContainerHeight);
      };

      adjustDropZoneHeight(); // make sure that we don't do the costly screen size adjustment until the user has finished resizing

      var debouncedScreenSizeListener = function debouncedScreenSizeListener() {
        return Ember.run.debounce(_this2, adjustDropZoneHeight, 100);
      };

      window.addEventListener('resize', debouncedScreenSizeListener, false);
      this.adjustDropZoneHeightReference = debouncedScreenSizeListener;
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.teardownInteract();
      window.removeEventListener('resize', this.adjustDropZoneHeightReference);
    },
    setupInteract: function setupInteract() {
      var _this = this;

      interact("#".concat(this.elementId)).dropzone({
        accept: '.draggable',
        ondragenter: function ondragenter() {
          _this.send('draggedOver');
        },
        ondragleave: function ondragleave() {
          _this.send('draggedOut');
        },
        ondrop: function ondrop(event) {
          var draggableElement = event.relatedTarget;

          var store = _this.get('store');

          if (draggableElement.classList.contains('grouped-appointment-object')) {
            _this.send('dropItem', store.peekAll('grouped-appointment').filterBy('id', draggableElement.getAttribute('data-grouped-appointment-id'))[0]);
          } else {
            _this.send('dropItem', store.peekAll('service').filterBy('id', draggableElement.getAttribute('data-service-id'))[0]);
          }
        }
      });
    },
    teardownInteract: function teardownInteract() {
      interact("#".concat(this.elementId)).unset();
    },
    actions: {
      dropItem: function dropItem(obj) {
        this.set('acceptsDropping', false);
        this.dropItem(obj);
      },
      draggedOut: function draggedOut() {
        this.set('isHovering', false);
      },
      draggedOver: function draggedOver() {
        this.set('isHovering', true);
      }
    }
  });

  _exports.default = _default;
});