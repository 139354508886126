define("frontend/components/yes-skip-prompt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['yes-skip-prompt'],
    actions: {
      skip: function skip() {
        this.skip();
      },
      yes: function yes() {
        this.yes();
      }
    }
  });

  _exports.default = _default;
});