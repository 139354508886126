define("frontend/templates/components/recurring-service-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "p86HIX57",
    "block": "{\"symbols\":[\"grouped_appointment\",\"appointment\",\"apptIndex\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"time\"],[8],[1,[28,\"if\",[[24,[\"service\",\"all_day_service\"]],\"all-day\",[24,[\"service\",\"starts_at_time\"]]],null],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"pets\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"service\",\"grouped_appointments\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"grouped-appointment-object\"],[8],[0,\"\\n\"],[4,\"each\",[[23,1,[]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"pet-item\",null,[[\"recurringAppointment\",\"appointment\",\"addLinkImage\",\"colorHex\"],[true,[23,2,[]],[28,\"gt\",[[23,3,[]],0],null],[23,2,[\"offered_service_color_hex\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2,3]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/recurring-service-box.hbs"
    }
  });

  _exports.default = _default;
});