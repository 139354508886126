define("frontend/templates/components/mobile-text-area", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bmephWR8",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"textarea\",null,[[\"autoresize\",\"rows\",\"value\",\"placeholder\",\"class\"],[true,[24,[\"rows\"]],[24,[\"value\"]],[24,[\"placeholder\"]],\"form-control\"]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/mobile-text-area.hbs"
    }
  });

  _exports.default = _default;
});