define("frontend/templates/components/view-cancellation-note-mobile-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4PA8iinU",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,[[\"onClose\",\"customContainerClassNames\"],[[28,\"action\",[[23,0,[]],\"close\"],null],\"view-cancellation-note-modal\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"title-line\"],[8],[0,\"\\n      REASON FOR CANCELLATION\\n    \"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"cancelledBy\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"by-line\"],[8],[0,\"\\n        from \"],[7,\"span\",true],[10,\"class\",\"name\"],[8],[1,[22,\"cancelledBy\"],false],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"cancellation-note\"],[8],[0,\"\\n      \"],[1,[22,\"cancellationNote\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"close-button\"],[8],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"click\"],[[28,\"action\",[[23,0,[]],\"close\"],null]]],{\"statements\":[[0,\"      \"],[1,[28,\"inline-svg\",[\"assets/images/header/add-sign.svg\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/view-cancellation-note-mobile-modal.hbs"
    }
  });

  _exports.default = _default;
});