define("frontend/templates/components/add-pet-or-human-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0Yrj9+vc",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,[[\"onClose\"],[[28,\"action\",[[23,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content add-pet-or-human-modal\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"\\n      Which would you like to add?\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"choices\"],[8],[0,\"\\n      \"],[7,\"div\",false],[12,\"class\",\"choice left-side\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"clickOption\",\"pet\"],null]]],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"image\"],[8],[0,\"\\n          \"],[7,\"img\",true],[10,\"src\",\"assets/images/placeholders/Dog.svg\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"choice-name\"],[8],[0,\"\\n          pet\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n\\n      \"],[7,\"div\",false],[12,\"class\",\"choice\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"clickOption\",\"human\"],null]]],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"image\"],[8],[0,\"\\n          \"],[7,\"img\",true],[10,\"src\",\"assets/images/staff/placeholder-human-image@3x.png\"],[8],[9],[0,\"\\n        \"],[9],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"choice-name\"],[8],[0,\"\\n          human\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"footer-buttons\"],[8],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"class\",\"onclick\"],[\"red-white\",[28,\"action\",[[23,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"        cancel\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/add-pet-or-human-modal.hbs"
    }
  });

  _exports.default = _default;
});