define("frontend/components/removable-readonly-chips", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['removable-readonly-chips'],
    actions: {
      removeItem: function removeItem(item) {
        this.get('removeItem')(item);
      }
    }
  });

  _exports.default = _default;
});