define("frontend/models/employee", ["exports", "ember-data", "moment"], function (_exports, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend({
    user: _emberData.default.belongsTo('user', {
      async: true
    }),
    start_date: _emberData.default.attr('string'),
    compensation_type: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    min_amount_to_pay: _emberData.default.attr('number'),
    offered_service_amounts: _emberData.default.attr(),
    company_role: _emberData.default.attr(),
    deactivated_at: _emberData.default.attr(),
    deleted_at: _emberData.default.attr(),
    differs_for_additional_dogs: _emberData.default.attr('boolean'),
    formattedStartDate: computed('start_date', function () {
      return (0, _moment.default)(this.get('start_date')).format('MMMM YYYY');
    }),
    deactivated: computed('deactivated_at', function () {
      if (this.get('deactivated_at')) {
        return true;
      }

      return false;
    }),
    deleted: computed('deleted_at', function () {
      if (this.get('deleted_at')) {
        return true;
      }

      return false;
    }),
    compensationTypeName: computed('compensation_type', function () {
      var compensationType = this.get('compensation_type');

      if (compensationType === 'salary') {
        return 'Salary';
      } else if (compensationType === 'per_commission') {
        return '% Commission';
      } else if (compensationType === 'fixed_dollar') {
        return 'Fixed';
      } else {
        return compensationType;
      }
    })
  });

  _exports.default = _default;
});