define("frontend/routes/profile/account", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('didValidate', false);
      controller.set('showCurrentPasswordError', false);
      controller.set('showNewPasswordError', false);
      controller.set('showConfirmPasswordError', false);
    }
  });

  _exports.default = _default;
});