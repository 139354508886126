define("frontend/components/invoice-receipt/add-on-appointment-group", ["exports", "ember-data", "moment", "ember-computed-change-gate/change-gate", "jquery"], function (_exports, _emberData, _moment, _changeGate, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      observer = Ember.observer;

  var _default = Ember.Component.extend({
    classNames: ['appointment-group', 'add-on-appointment-group'],
    collapsed: true,
    showGroup: computed.not('collapsed'),
    groupedAddOnService: null,
    incompleteAppointments: computed.filterBy('groupedAddOnService.info.groupedAppointments', 'completed', false),
    completeAppointments: computed.filterBy('groupedAddOnService.info.groupedAppointments', 'completed', true),
    household: null,
    overriddenCost: false,
    groupedAppointments: computed.alias('groupedAddOnService.info.groupedAppointments'),
    offeredService: computed.alias('groupedAddOnService.info.offeredService'),
    pets: computed.alias('groupedAddOnService.info.pets'),
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);

      this.notifyPropertyChange('editingInvoice');
    },
    allAppointments: computed('groupedAppointments.@each.appointments.[]', function () {
      var appts = [];
      this.get('groupedAppointments').forEach(function (groupedAppointment) {
        groupedAppointment.get('appointments').forEach(function (appt) {
          appts.push(appt);
        });
      });
      return appts;
    }),
    editingInvoiceChanged: observer('editingInvoice', function () {
      this.set('collapsed', !this.get('editingInvoice'));
    }),
    cost: computed('completeAppointments.[]', 'totalPrice', function () {
      return this.get('completeAppointments.length') * this.get('totalPrice');
    }),
    // Even though the appointments aren't changing, this CP is forcing effective price to recompute
    firstAppointment: (0, _changeGate.default)('sortedGroupedAppointments.@each.completed', function () {
      var firstCompletedAppt = this.get('sortedGroupedAppointments').find(function (appt) {
        return appt.get('completed');
      });

      if (firstCompletedAppt) {
        return firstCompletedAppt;
      } else {
        return this.get('sortedGroupedAppointments.firstObject');
      }
    }),
    effectivePrice: computed('invoice.id', 'invoice.household.id', 'offeredService.id', 'firstAppointment.id', function () {
      var offeredServiceId = this.get('offeredService.id');
      var householdId = this.get('invoice.household.id');
      var firstCompleted = this.get('firstAppointment');
      var invoiceId = this.get('invoice.id');
      var date = (0, _moment.default)(firstCompleted.get('time')).format('YYYY-MM-DD');

      var promise = _jquery.default.ajax({
        url: "/api/v2/effective_offered_service_prices?offered_service_id=".concat(offeredServiceId, "&household_id=").concat(householdId, "&date=").concat(date, "&invoice_id=").concat(invoiceId),
        method: 'GET'
      });

      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    totalPrice: computed('effectivePrice.price', 'effectivePrice.incremental_price', 'pets.[]', function () {
      var price = parseFloat(this.get('effectivePrice.price'));
      var incPrice = parseFloat(this.get('effectivePrice.incremental_price'));
      var petCount = this.get('pets').length;
      return price + (petCount - 1) * incPrice;
    }),
    hasIncrementalPrice: computed('effectivePrice.price', 'effectivePrice.incremental_price', function () {
      return this.get('effectivePrice.incremental_price') != this.get('effectivePrice.price') && this.get('pets').length > 1;
    }),
    appointmentsSorting: ['time'],
    sortedGroupedAppointments: computed.sort('groupedAppointments', 'appointmentsSorting')
  });

  _exports.default = _default;
});