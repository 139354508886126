define("frontend/models/recurring-appointment-add-on-service", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    offered_service: _emberData.default.belongsTo('offered-service', {
      async: false
    }),
    recurring_appointment: _emberData.default.belongsTo('recurring-appointment')
  });

  _exports.default = _default;
});