define("frontend/utils/address-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var HOME = 'home';
  var WORK = 'work';
  var OTHER = 'other';
  var _default = {
    HOME: HOME,
    WORK: WORK,
    OTHER: OTHER
  };
  _exports.default = _default;
});