define("frontend/utils/payment-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var ALL = 'all';
  var CARD = 'card';
  var BANK = 'bank';
  var MANUAL = 'manual';
  var _default = {
    ALL: ALL,
    CARD: CARD,
    BANK: BANK,
    MANUAL: MANUAL
  };
  _exports.default = _default;
});