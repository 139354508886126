define("frontend/templates/sign-up", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0bm8MLKw",
    "block": "{\"symbols\":[],\"statements\":[[7,\"section\",true],[10,\"class\",\"sign-up-page\"],[8],[0,\"\\n  \"],[7,\"header\",true],[10,\"class\",\"main-header\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"media\",[\"isDesktop\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[8],[0,\"Already have an account?\"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"ppb-icon-with-border\"],[8],[1,[28,\"inline-svg\",[\"assets/images/ppb-icon.svg\"],null],false],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"    \"],[4,\"link-to\",null,[[\"classNames\",\"route\"],[\"ppb-button red-white-border-thick\",\"users.login\"]],{\"statements\":[[0,\"Login\"]],\"parameters\":[]},null],[0,\"\\n  \"],[9],[0,\"\\n    \"],[7,\"section\",true],[10,\"class\",\"content\"],[8],[0,\"\\n\"],[4,\"if\",[[28,\"media\",[\"isDesktop\"],null]],null,{\"statements\":[[0,\"        \"],[1,[22,\"sign-up-carousel\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[7,\"section\",true],[10,\"class\",\"right-content\"],[8],[0,\"\\n        \"],[1,[22,\"outlet\"],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/sign-up.hbs"
    }
  });

  _exports.default = _default;
});