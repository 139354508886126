define("frontend/templates/components/state-autocomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TcVjF3B3",
    "block": "{\"symbols\":[\"state\",\"autocomplete\"],\"statements\":[[4,\"paper-autocomplete\",null,[[\"options\",\"selected\",\"defaultHighlighted\",\"allowClear\",\"placeholder\",\"noMatchesMessage\",\"onSelectionChange\"],[[24,[\"states\"]],[24,[\"selectedState\"]],[28,\"action\",[[23,0,[]],\"highlightFirstMatch\"],null],[28,\"media\",[\"isDesktop\"],null],\"Select a State...\",\"Oops this state doesn't exist.\",[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"selectedState\"]]],null]],null]]],{\"statements\":[[0,\"  \"],[1,[28,\"paper-autocomplete-highlight\",null,[[\"label\",\"searchText\",\"flags\"],[[28,\"capitalize-text\",[[23,1,[]]],null],[28,\"capitalize-text\",[[23,2,[\"searchText\"]]],null],\"i\"]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/state-autocomplete.hbs"
    }
  });

  _exports.default = _default;
});