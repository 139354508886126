define("frontend/routes/inbox", ["exports", "frontend/routes/base-route", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = _baseRoute.default.extend({
    queryParams: {
      filter: {
        refreshModel: true,
        replace: true
      },
      search_term: null
    },
    filtered: false,
    infinity: inject.service(),
    currentUser: inject.service(),
    router: inject.service(),
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      if (transition.targetName == 'inbox' && !this.get('currentUser.user.canViewInbox')) {
        this.transitionTo('index');
      }

      if (this.get('controller')) {
        this.set('controller.loading', true);
      }
    },
    model: function model(params) {
      var distributionThread = _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/messages/get_distribution_thread"),
        method: 'GET',
        data: {
          company_id: this.get('currentUser.user.company.id')
        }
      });

      var serviceCategories = this.store.query('service-category', {
        company_id: this.get('currentUser.user.company.id')
      });

      if (params.filter !== '') {
        this.set('filtered', true);

        var _householdsWithMessages = this.get('infinity').model('household', {
          perPage: 15,
          startingPage: 1,
          filter: params.filter,
          with_messages: true
        });

        var _allHouseholds = this.get('infinity').model('household', {
          perPage: 15,
          startingPage: 1,
          filter: params.filter
        });

        return Ember.RSVP.hash({
          householdsWithMessages: _householdsWithMessages,
          allHouseholds: _allHouseholds,
          distributionThread: distributionThread,
          serviceCategories: serviceCategories
        });
      }

      this.set('filtered', false);
      var householdsWithMessages = this.get('infinity').model('household', {
        perPage: 15,
        startingPage: 1,
        with_messages: true
      });
      var allHouseholds = this.get('infinity').model('household', {
        perPage: 15,
        startingPage: 1
      });
      return Ember.RSVP.hash({
        householdsWithMessages: householdsWithMessages,
        allHouseholds: allHouseholds,
        distributionThread: distributionThread,
        serviceCategories: serviceCategories
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('loading', false);
      this.set('allHouseholds', model.allHouseholds);
      this.set('householdsWithMessages', model.householdsWithMessages);
    },
    actions: {
      willTransition: function willTransition(transition) {
        this._super.apply(this, arguments);

        if (transition.targetName.search('inbox') === -1) {
          this.get('controller').set('selectedHouseholds', []);
          this.get('controller').set('showingNewMessagePage', false);
        }
      },
      loading: function loading() {
        return !this.get('controller') || this.get('controller.loading');
      },
      parentRefresh: function parentRefresh() {
        this.refresh();
      },
      parentInfinityLoad: function parentInfinityLoad() {
        this._infinityLoad();
      },
      transitionToInbox: function transitionToInbox() {
        this.transitionTo('inbox');
      },
      transitionToDistributionList: function transitionToDistributionList() {
        this.transitionTo('inbox.distribution-list');
      },
      goToAppointmentRequest: function goToAppointmentRequest(requestId, companyId, householdId) {
        var queryParams = {
          backRoute: this.get('router.currentRouteName'),
          backHouseholdId: householdId,
          requestId: requestId
        };
        this.transitionTo('edit-single-appointment.index', {
          queryParams: queryParams
        });
      },
      goToHousehold: function goToHousehold(householdId) {
        var queryParams = {
          backToMessages: true
        };
        this.transitionTo('client-list.household', householdId, {
          queryParams: queryParams
        });
      }
    }
  });

  _exports.default = _default;
});