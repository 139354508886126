define("frontend/controllers/portal/invoices-summary/company/invoice-detail", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    deletedAdjustments: [],
    addingAdjustment: false,
    company: inject.controller('portal.invoices-summary.company'),
    companyId: computed.readOnly('company.company_id'),
    actions: {
      print: function print() {
        var w = window.open();

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/invoices/").concat(this.get('model.id')),
          method: 'GET',
          dataType: 'html'
        }).done(function (response) {
          w.document.open();
          w.document.write(response);
          w.document.close();
          $(w).load(function () {
            w.print();
          });
        }).fail(function () {// TODO: Show error dialog?
        });
      },
      clickAppointment: function clickAppointment() {}
    }
  });

  _exports.default = _default;
});