define("frontend/components/calendar-options", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['calendar-options'],
    calendarOptions: Ember.inject.service('calendarOptions'),
    hidingInactiveStaff: computed.alias('calendarOptions.hidingInactiveStaff'),
    store: Ember.inject.service(),
    careProviders: computed(function () {
      return this.get('store').peekAll('care-provider');
    }),
    actions: {
      close: function close() {
        this.get('close')();
      },
      sortEndAction: function sortEndAction() {
        this.get('careProviders').forEach(function (careProvider, index) {
          careProvider.set('calendar_order', index);
        });
        var sorted_care_provider_ids = this.get('careProviders').mapBy('id');

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/care_providers/reposition"),
          method: 'PUT',
          data: {
            sorted_care_provider_ids: sorted_care_provider_ids
          }
        });
      }
    }
  });

  _exports.default = _default;
});