define("frontend/controllers/edit-single-appointment/index", ["exports", "moment", "frontend/config/environment", "jquery"], function (_exports, _moment, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer;

  var _default = Ember.Controller.extend({
    offeredServices: computed.alias('model.offeredServices'),
    firstAppointment: computed.alias('model.appointments.firstObject'),
    isRequest: computed.notEmpty('model.appointmentRequest'),
    appointmentRequestID: computed.alias('model.appointmentRequest.id'),
    appointments: computed.alias('model.appointments'),
    careProviders: computed.alias('model.careProviders'),
    appointmentDatePanelOpen: false,
    assignTimeSidePanelOpen: false,
    selectedCareProviderId: computed.alias('firstAppointment.selectedCareProviderId'),
    selectedOfferedServiceId: computed.alias('firstAppointment.selectedOfferedServiceId'),
    addOnServices: computed.filterBy('allAddOnServices', 'isDeleted', false),
    anySidePanelOpen: computed.or('appointmentDateSidePanelOpen', 'assignTimeSidePanelOpen'),
    editSingleAppointment: inject.controller(),
    currentUser: inject.service(),
    media: inject.service(),
    backRoute: computed.readOnly('editSingleAppointment.backRoute'),
    backHouseholdId: computed.readOnly('editSingleAppointment.backHouseholdId'),
    originalCareProviderId: computed.alias('editSingleAppointment.originalCareProviderId'),
    originalStartsAt: computed.alias('editSingleAppointment.originalStartsAt'),
    originalOfferedServiceId: computed.alias('editSingleAppointment.originalOfferedServiceId'),
    wasUnassigned: computed.alias('editSingleAppointment.wasUnassigned'),
    allAddOnServices: computed('appointments.@each.appointment_add_on_services.[]', function () {
      var allAddOnServices = [];
      this.get('appointments').forEach(function (appointment) {
        appointment.get('appointment_add_on_services').forEach(function (addOnService) {
          allAddOnServices.pushObject(addOnService);
        });
      });
      return allAddOnServices;
    }),
    addOnServicesWithPets: computed('allAddOnService.[]', 'allAddOnServices.@each.{isDeleted,offered_service}', function () {
      var addOnServices = this.get('allAddOnServices').filter(function (addOnService) {
        return !addOnService.get('isDeleted');
      });
      var addOnServicesWithPets = {};
      addOnServices.forEach(function (addOnService) {
        if (!addOnServicesWithPets[addOnService.get('offered_service.id')]) {
          addOnServicesWithPets[addOnService.get('offered_service.id')] = {
            pets: [],
            appointmentAddOnServices: [],
            offered_service: addOnService.get('offered_service'),
            createdAt: addOnService.get('created_at')
          };
        }

        addOnServicesWithPets[addOnService.get('offered_service.id')].pets.pushObject(addOnService.get('appointment.pet'));
        addOnServicesWithPets[addOnService.get('offered_service.id')].appointmentAddOnServices.pushObject(addOnService);
      });
      return Object.values(addOnServicesWithPets).sortBy('createdAt');
    }),
    addOnServicesSelected: computed('addOnServicesWithPets.@each.offered_service', function () {
      return this.get('addOnServicesWithPets').map(function (addOnServiceWithPet) {
        return addOnServiceWithPet.offered_service.get('id');
      });
    }),
    nonAddOnOfferedServices: computed('offeredServices.[]', function () {
      return this.get('offeredServices').filter(function (offeredService) {
        return !offeredService.get('is_add_on');
      });
    }),
    offeredServiceOptions: computed.map('nonAddOnOfferedServices', function (offeredService) {
      return {
        label: offeredService.get('nameWithDuration'),
        value: offeredService.get('id'),
        position: offeredService.get('position'),
        categoryPosition: offeredService.get('service_category_position'),
        all_day: offeredService.get('all_day_service'),
        serviceCategory: offeredService.get('service_category')
      };
    }),
    sortedOfferedServiceOptions: computed.sort('offeredServiceOptions', function (a, b) {
      if (a.categoryPosition > b.categoryPosition) {
        return 1;
      } else if (a.categoryPosition < b.categoryPosition) {
        return -1;
      } else {
        if (a.position > b.position) {
          return 1;
        } else if (a.position < b.position) {
          return -1;
        }

        return 0;
      }
    }),
    addOnOfferedServices: computed('offeredServices.[]', 'selectedOfferedService.service_category.id', function () {
      var _this = this;

      return this.get('offeredServices').filter(function (offeredService) {
        return offeredService.get('is_add_on') && offeredService.get('service_category.id') == _this.get('selectedOfferedService.serviceCategory.id');
      });
    }),
    addOnOfferedServiceOptions: computed.map('addOnOfferedServices', function (offeredService) {
      return {
        label: offeredService.get('nameWithDuration'),
        value: offeredService.get('id'),
        offeredService: offeredService,
        position: offeredService.get('position'),
        categoryPosition: offeredService.get('service_category_position'),
        all_day: offeredService.get('all_day_service'),
        serviceCategory: offeredService.get('service_category')
      };
    }),
    sortedAddOnOfferedServiceOptions: computed.sort('addOnOfferedServiceOptions', function (a, b) {
      if (a.categoryPosition > b.categoryPosition) {
        return 1;
      } else if (a.categoryPosition < b.categoryPosition) {
        return -1;
      } else {
        if (a.position > b.position) {
          return 1;
        } else if (a.position < b.position) {
          return -1;
        }

        return 0;
      }
    }),
    selectedOfferedService: computed('offeredServiceOptions', 'selectedOfferedServiceId', function () {
      var options = this.get('offeredServiceOptions');
      var selected = this.get('selectedOfferedServiceId');

      if (options.length > 0 && selected) {
        return options.findBy('value', selected);
      }
    }),
    careProviderOptions: computed.map('careProviders', function (careProvider) {
      return {
        value: careProvider.get('id'),
        label: careProvider.get('fullName')
      };
    }),
    preferredStaffSorting: ['position'],
    preferredStaff: computed('isRequest', 'firstAppointment', 'model.appointmentRequest', function () {
      return this.get('isRequest') ? this.get('model.appointmentRequest.household.preferred_staff') : this.get('firstAppointment.pet.household.preferred_staff');
    }),
    sortedPreferredStaff: computed.sort('preferredStaff', 'preferredStaffSorting'),
    requestStaffDeclines: computed('isRequest', 'model.appointmentRequest', function () {
      return this.get('isRequest') ? this.get('model.appointmentRequest.staff_declines') : [];
    }),
    groupedCareProviderOptions: computed('preferredStaff.[]', 'careProviderOptions.[]', function () {
      var _this2 = this;

      if (this.get('preferredStaff.length') > 0) {
        var preferred = [];
        var declined = [];
        var others = [];
        this.get('sortedPreferredStaff').forEach(function (preferredStaff) {
          if (!preferredStaff.get('isNew')) {
            _this2.get('careProviderOptions').forEach(function (careProvider) {
              if (careProvider.label == preferredStaff.get('staff_name')) {
                var found = false;

                _this2.get('requestStaffDeclines').forEach(function (staffDecline) {
                  found = found || preferredStaff.get('staff_name') == staffDecline;
                });

                if (!found) {
                  preferred.pushObject(careProvider);
                }
              }
            });
          }
        });
        this.get('requestStaffDeclines').forEach(function (staffDecline) {
          _this2.get('careProviderOptions').forEach(function (careProvider) {
            if (careProvider.label == staffDecline) {
              declined.pushObject(careProvider);
            }
          });
        });
        this.get('careProviderOptions').forEach(function (careProvider) {
          var found = false;

          _this2.get('sortedPreferredStaff').forEach(function (preferredStaff) {
            found = found || careProvider.label == preferredStaff.get('staff_name');
          });

          _this2.get('requestStaffDeclines').forEach(function (staffDecline) {
            found = found || careProvider.label == staffDecline;
          });

          if (!found) {
            others.pushObject(careProvider);
          }
        });
        var results = [{
          groupName: 'Preferred',
          options: preferred
        }, {
          groupName: 'Others',
          options: others
        }];

        if (declined.length > 0) {
          results.unshift({
            groupName: 'Declined',
            options: declined
          });
        }

        return results;
      } else {
        return this.get('careProviderOptions');
      }
    }),
    selectedCareProvider: computed('careProviderOptions', 'selectedCareProviderId', function () {
      var options = this.get('careProviderOptions');
      var selected = this.get('selectedCareProviderId');

      if (options.length > 0 && selected) {
        return options.findBy('value', selected);
      }
    }),
    appointmentTime: computed('firstAppointment.time', function () {
      return _moment.default.parseZone(this.get('firstAppointment.time')).clone().format('h:mm A');
    }),
    leftButtonText: computed('media.isMobile', 'isRequest', function () {
      if (!this.get('media.isMobile')) {
        if (this.get('isRequest')) {
          return 'Decline';
        } else {
          return 'Archive';
        }
      } else {
        return 'Back';
      }
    }),
    timeWindowClarificationText: computed('firstAppointment.time_frame_start_time', 'firstAppointment.time_frame_end_time', function () {
      var timeFrameStart = this.get('firstAppointment.time_frame_start_time');
      var timeFrameEnd = this.get('firstAppointment.time_frame_end_time');

      if (timeFrameStart && timeFrameEnd) {
        return "You have <span class=\"bold\">".concat(timeFrameStart, " - ").concat(timeFrameEnd, "</span> arrival set as your estimated arrival window for this appointment.");
      }
    }),
    showMiddleButton: computed('staffAppointmentApprovalEnabled', 'isRequest', 'appointmentHasNonNotesChanges', function () {
      return this.get('staffAppointmentApprovalEnabled') && this.get('appointmentHasNonNotesChanges');
    }),
    middleButtonText: computed('isRequest', function () {
      return this.get('isRequest') ? 'Approve' : 'Save';
    }),
    leftButtonColor: computed('media.isMobile', 'isRequest', function () {
      if (this.get('media.isMobile')) {
        return 'red-white';
      } else if (this.get('isRequest')) {
        return 'gray';
      } else {
        return 'red-white-border';
      }
    }),
    leftButtonAction: computed('media.isMobile', 'isRequest', function () {
      if (!this.get('media.isMobile')) {
        if (this.get('isRequest')) {
          return 'declineRequest';
        } else {
          return 'confirmRemove';
        }
      } else {
        return 'cancelEdit';
      }
    }),
    rightButtonText: computed('isRequest', 'staffAppointmentApprovalEnabled', 'media.isMobile', 'appointmentHasNonNotesChanges', function () {
      if (this.get('staffAppointmentApprovalEnabled') && this.get('appointmentHasNonNotesChanges')) {
        return this.get('media.isMobile') ? 'Save' : 'Request';
      } else {
        if (this.get('isRequest')) {
          return 'Approve';
        }

        return 'Save';
      }
    }),
    rightButtonAction: computed('isRequest', 'staffAppointmentApprovalEnabled', 'media.isMobile', 'appointmentHasNonNotesChanges', function () {
      if (this.get('staffAppointmentApprovalEnabled') && this.get('appointmentHasNonNotesChanges')) {
        return 'requestAppointment';
      }

      return 'saveCheck';
    }),
    showRightButton: computed('media.isMobile', 'staffAppointmentApprovalEnabled', 'isRequest', 'appointmentHasNonNotesChanges', function () {
      if (this.get('media.isMobile') && this.get('staffAppointmentApprovalEnabled') && this.get('appointmentHasNonNotesChanges')) {
        return false;
      }

      return true;
    }),
    pickupWindow: function pickupWindow(startTime, serviceCategory) {
      if (!startTime) {
        return {};
      }

      if (!serviceCategory.get('use_time_blocks')) {
        return {};
      }

      var timeFrames = serviceCategory.get('time_frames');
      var startTimeMoment = (0, _moment.default)(startTime, 'hh:mm a');
      var dayOfWeek = (0, _moment.default)(this.get('dateTime')).day();
      var potentialTimeFrames = timeFrames.filter(function (timeFrame) {
        var start = (0, _moment.default)(timeFrame.get('start_time'), 'hh:mm a');
        var end = (0, _moment.default)(timeFrame.get('end_time'), 'hh:mm a').subtract(1, 'second');
        return timeFrame.get('days_of_week').includes(dayOfWeek) && startTimeMoment >= start && startTimeMoment <= end;
      });
      var showStart = null;
      var showEnd = null;

      if (potentialTimeFrames.length >= 1) {
        showStart = potentialTimeFrames.get('firstObject').get('start_time').replace(' ', '');
        showEnd = potentialTimeFrames.get('firstObject').get('end_time').replace(' ', '');
      } else if (serviceCategory.get('time_window_in_minutes')) {
        var timeWindow = serviceCategory.get('time_window_in_minutes');
        showStart = (0, _moment.default)(startTime, 'hh:mm a').subtract(timeWindow, 'minutes').format('h:mmA');
        showEnd = (0, _moment.default)(startTime, 'hh:mm a').add(timeWindow, 'minutes').format('h:mmA');
      }

      if (showStart && showEnd) {
        return {
          timeFrameStart: showStart,
          timeFrameEnd: showEnd
        };
      }
    },
    holidayDatesTooltipText: computed('holidaySurcharges.[]', function () {
      var text = '';
      this.get('holidaySurcharges').forEach(function (holidaySurcharge) {
        text += "".concat(holidaySurcharge.policy, ":\n\n");
        holidaySurcharge.date_counts.forEach(function (dateCount) {
          text += "".concat((0, _moment.default)(dateCount.date, 'YYYY-MM-DD').format('dddd, MMMM D'));

          if (dateCount.count > 1) {
            text += " (x".concat(dateCount.count, ")");
          }

          text += '\n';
        });
        text += '\n';
      });
      return text;
    }),
    weekendDatesTooltipText: computed('weekendSurcharges.[]', function () {
      var text = '';
      this.get('weekendSurcharges').forEach(function (weekendSurcharge) {
        text += "".concat(weekendSurcharge.policy, ":\n\n");
        weekendSurcharge.date_counts.forEach(function (dateCount) {
          text += "".concat((0, _moment.default)(dateCount.date, 'YYYY-MM-DD').format('dddd, MMMM D'));

          if (dateCount.count > 1) {
            text += " (x".concat(dateCount.count, ")");
          }

          text += '\n';
        });
        text += '\n';
      });
      return text;
    }),
    offHoursDatesTooltipText: computed('offHoursSurcharges.[]', function () {
      var text = '';
      this.get('offHoursSurcharges').forEach(function (offHoursSurcharge) {
        text += "".concat(offHoursSurcharge.policy, ":\n\n");
        offHoursSurcharge.date_counts.forEach(function (dateCount) {
          text += "".concat((0, _moment.default)(dateCount.date, 'YYYY-MM-DD').format('dddd, MMMM D'));

          if (dateCount.count > 1) {
            text += " (x".concat(dateCount.count, ")");
          }

          text += '\n';
        });
        text += '\n';
      });
      return text;
    }),
    lateBookingDatesTooltipText: computed('lateBookingSurcharges.[]', function () {
      var text = '';
      this.get('lateBookingSurcharges').forEach(function (lateBookingSurcharge) {
        text += "".concat(lateBookingSurcharge.policy, ":\n\n");
        lateBookingSurcharge.date_counts.forEach(function (dateCount) {
          text += "".concat((0, _moment.default)(dateCount.date, 'YYYY-MM-DD').format('dddd, MMMM D'));

          if (dateCount.count > 1) {
            text += " (x".concat(dateCount.count, ")");
          }

          text += '\n';
        });
        text += '\n';
      });
      return text;
    }),
    estimateCosts: function estimateCosts() {
      var _this3 = this;

      var date = _moment.default.parseZone(this.get('firstAppointment.service_starts_at')).format('YYYY-MM-DD');

      var addOnServices = this.get('addOnServicesWithPets').map(function (addOnServiceWithPet) {
        return {
          pet_ids: addOnServiceWithPet.pets.map(function (pet) {
            return pet.get('id');
          }),
          offered_service_id: addOnServiceWithPet.offered_service.get('id')
        };
      });
      var appointments = [{
        care_provider_id: this.get('selectedCareProvider.value'),
        date: date,
        offered_service_id: this.get('selectedOfferedService.value'),
        time: this.get('appointmentTime'),
        time_frame_start: this.get('firstAppointment.time_frame_start_time'),
        time_frame_end: this.get('firstAppointment.time_frame_end_time'),
        add_on_services: addOnServices.filter(function (addOnService) {
          return addOnService.offered_service_id;
        })
      }];
      var pet_ids = this.get('appointments').map(function (appt) {
        return appt.get('pet.id');
      });
      this.set('loadingCosts', true);

      _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/appointments/estimated_costs"),
        method: 'POST',
        data: {
          appointments: appointments,
          addOnServices: addOnServices,
          pet_ids: pet_ids,
          request_id: this.get('appointmentRequestID')
        }
      }).done(function (response) {
        _this3.set('totalCost', response.total_cost);

        _this3.set('appointmentsCost', response.appointments);

        _this3.set('holidayCosts', response.holiday_surcharge);

        _this3.set('weekendCosts', response.weekend_surcharge);

        _this3.set('offHoursCosts', response.off_hours_surcharge);

        _this3.set('lateBookingCosts', response.late_booking_surcharge);

        _this3.set('offeredServiceEstimatedCosts', response.offered_service_prices);

        _this3.set('holidaySurcharges', response.holiday_dates);

        _this3.set('weekendSurcharges', response.weekend_dates);

        _this3.set('offHoursSurcharges', response.off_hours_dates);

        _this3.set('lateBookingSurcharges', response.late_booking_dates);

        _this3.set('salesTaxCost', response.sales_tax);

        _this3.set('salesTaxPercentage', response.sales_tax_percentage);

        _this3.set('salesTaxTooltipText', response.sales_tax_tooltip_string);

        _this3.set('loadingCosts', false);
      });
    },
    estimatedCostObserver: observer('appointmentTime', 'firstAppointment.service_starts_at', 'selectedCareProvider', 'selectedOfferedService', 'addOnServices.@each.{offered_service}', function () {
      Ember.run.debounce(this, this.estimateCosts, 500);
    }),
    staffAppointmentApprovalEnabled: computed.alias('currentUser.user.company.staff_appointment_approval'),
    appointmentHasNonNotesChanges: computed('originalCareProviderId', 'originalStartsAt', 'originalOfferedServiceId', 'selectedOfferedServiceId', 'firstAppointment.service_starts_at', 'selectedCareProviderId', 'wasUnassigned', function () {
      return this.get('originalCareProviderId') != this.get('selectedCareProviderId') || this.get('originalStartsAt') != this.get('firstAppointment.service_starts_at') || this.get('originalOfferedServiceId') != this.get('selectedOfferedServiceId') || this.get('wasUnassigned');
    }),
    showAddAddOnServiceButton: computed('addOnServices.[]', 'selectedOfferedService.serviceCategory', function () {
      return this.get('addOnServices.length') == 0 && this.get('selectedOfferedService.serviceCategory.hasAddOnServices');
    }),
    allAddOnServicesHaveBeenSelected: computed('addOnServicesWithPets.@each.offered_service', function () {
      var allSelected = this.get('addOnServicesWithPets').every(function (addOnServiceWithPet) {
        return addOnServiceWithPet.offered_service.get('id');
      });
      var allAdded = this.get('addOnServicesWithPets.length') == this.get('sortedAddOnOfferedServiceOptions.length');
      return allSelected && !allAdded;
    }),
    actions: {
      changeAppointmentDate: function changeAppointmentDate(date) {
        this.send('closeAppointmentDateSidePanel'); // We can't just use date because we have to maintain the appointment time
        // and moment doesn't let you set the time of an existing object

        var apptTime = _moment.default.parseZone(this.get('firstAppointment.service_starts_at'));

        var apptDate = (0, _moment.default)(apptTime.format('YYYY-MM-DD'));
        var diff = apptDate.diff(date, 'days');
        var newTime = apptTime.subtract(diff, 'days').format();
        var appointments = this.get('appointments');
        appointments.forEach(function (appt) {
          appt.set('service_starts_at', newTime);
        });
      },
      cancelTimeSidePanel: function cancelTimeSidePanel() {
        this.set('firstAppointment.time_frame_start_time', this.get('originalTimeWindowStart'));
        this.set('firstAppointment.time_frame_end_time', this.get('originalTimeWindowEnd'));
        this.send('closeAssignTimeSidePanel');
      },
      closeAppointmentDateSidePanel: function closeAppointmentDateSidePanel() {
        this.set('originalTimeWindowStart', null);
        this.set('originalTimeWindowEnd', null);
        this.set('appointmentDateSidePanelOpen', false);
      },
      openAppointmentDateSidePanel: function openAppointmentDateSidePanel() {
        var _this4 = this;

        if (this.get('currentUser.user.isImpersonated')) {
          _jquery.default.ajax({
            url: "/api/v2/appointment_check?id=".concat(this.get('firstAppointment.id')),
            method: 'GET',
            dataType: 'json',
            contentType: 'application/json'
          }).then(function (response) {
            if (response.closed) {
              Ember.getOwner(_this4).lookup('controller:application').send('notify', {
                message: 'Impersonated users cant change the date of past billing periods',
                type: 'user-error'
              });
            } else {
              _this4.set('appointmentDateSidePanelOpen', true);
            }
          });
        } else {
          this.set('appointmentDateSidePanelOpen', true);
        }
      },
      openAssignTimeSidePanel: function openAssignTimeSidePanel() {
        this.set('originalTimeWindowStart', this.get('firstAppointment.time_frame_start_time'));
        this.set('originalTimeWindowEnd', this.get('firstAppointment.time_frame_end_time'));
        this.set('assignTimeSidePanelOpen', true);
      },
      closeAssignTimeSidePanel: function closeAssignTimeSidePanel() {
        this.set('assignTimeSidePanelOpen', false);
      },
      changeOfferedService: function changeOfferedService(offeredService) {
        if (this.get('selectedOfferedService.all_day') && !offeredService.all_day) {
          this.set('appointmentTime', null);
        }

        if (this.get('selectedOfferedService.serviceCategory.id') != offeredService.serviceCategory.get('id')) {
          var time = _moment.default.parseZone(this.get('firstAppointment').get('service_starts_at')).format('h:mm A');

          var pickupWindow = this.pickupWindow(time, offeredService.serviceCategory);
          this.set('firstAppointment.time_frame_start_time', pickupWindow.timeFrameStart);
          this.set('firstAppointment.time_frame_end_time', pickupWindow.timeFrameEnd);
          var addOns = this.get('firstAppointment.appointment_add_on_services').toArray();

          for (var i = 0; i < addOns.length; i++) {
            addOns[i].deleteRecord();
          }
        }

        this.get('appointments').forEach(function (appointment) {
          appointment.set('selectedOfferedServiceId', offeredService.value);
        });
      },
      changeCareProvider: function changeCareProvider(careProvider) {
        this.get('appointments').forEach(function (appointment) {
          appointment.set('selectedCareProviderId', careProvider.value);
        });
      },
      updateTime: function updateTime(time, position) {
        var hour = (0, _moment.default)(time, 'h:mm A').hour();
        var minute = (0, _moment.default)(time, 'h:mm A').minute();
        var tzMapping = this.get('currentUser.user.company.time_zone_mapping');
        var newTime = (0, _moment.default)(this.get('firstAppointment.service_starts_at')).tz(tzMapping).hour(hour).minute(minute).format();
        var appointments = this.get('appointments');
        appointments.forEach(function (appt) {
          appt.set('service_starts_at', newTime);
          appt.set('selectedPosition', position);
        });
        this.set('appointmentTime', time);
        this.send('closeAssignTimeSidePanel');
      },
      cancelEdit: function cancelEdit() {
        this.send('cancel', this.get('appointments'));
      },
      confirmRemove: function confirmRemove() {
        this.set('showDeleteAppointmentFlash', true);
      },
      denyDeleteAppointment: function denyDeleteAppointment() {
        this.set('showDeleteAppointmentFlash', false);
      },
      requestAppointment: function requestAppointment() {
        this.send('saveCheck', true);
      },
      openRequestOrApproveModal: function openRequestOrApproveModal() {
        this.set('showRequestOrApproveModal', true);
      },
      closeRequestOrApproveModal: function closeRequestOrApproveModal() {
        this.set('showRequestOrApproveModal', false);
      },
      createAddOnServices: function createAddOnServices(appointments) {
        var _this5 = this;

        appointments.forEach(function (appointment) {
          var service;

          if (_this5.get('addOnOfferedServices.length') == 1) {
            service = _this5.get('store').createRecord('appointment-add-on-service', {
              offered_service: _this5.get('addOnOfferedServices.firstObject'),
              created_at: (0, _moment.default)().format()
            });
          } else {
            service = _this5.get('store').createRecord('appointment-add-on-service', {
              created_at: (0, _moment.default)().format()
            });
          }

          appointment.get('appointment_add_on_services').pushObject(service);
        });
        this.set('showPickPetsModal', false);
      },
      changeAddOnService: function changeAddOnService(addOnServiceWithPet, selected) {
        addOnServiceWithPet.appointmentAddOnServices.forEach(function (addOnService) {
          addOnService.set('offered_service', selected.offeredService);
        });
      },
      removeAddOnService: function removeAddOnService(addOnServiceWithPet) {
        addOnServiceWithPet.appointmentAddOnServices.forEach(function (addOnService) {
          addOnService.deleteRecord();
        });
      },
      saveCheck: function saveCheck() {
        var makeRequest = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;

        if (this.get('appointmentTime')) {
          if (this.get('staffAppointmentApprovalEnabled') && makeRequest) {
            this.send('sendRequestAppointmentToStaff', this.get('appointments'), this.get('model.appointmentRequest.id'));
          } else {
            if (this.get('isRequest')) {
              this.send('approve', this.get('model.appointmentRequest'), this.get('appointments'));
            } else {
              this.send('save', this.get('appointments'));
            }
          }
        } else {
          this.set('hasSaved', true);
        }
      },
      confirmDeclineRequest: function confirmDeclineRequest() {
        this.send('decline', this.get('model.appointmentRequest'));
        this.set('showDeclineModal', false);
      },
      denyDeclineRequest: function denyDeclineRequest() {
        this.set('model.appointmentRequest.declined_note', null);
        this.set('showDeclineModal', false);
      },
      declineRequest: function declineRequest() {
        this.set('showDeclineModal', true);
      },
      closePickPetsModal: function closePickPetsModal() {
        this.set('showPickPetsModal', false);
      },
      addAddOnService: function addAddOnService() {
        if (this.get('appointments.length') > 1) {
          this.set('showPickPetsModal', true);
        } else {
          var service;

          if (this.get('addOnOfferedServices.length') == 1) {
            service = this.get('store').createRecord('appointment-add-on-service', {
              offered_service: this.get('addOnOfferedServices.firstObject'),
              created_at: (0, _moment.default)().format()
            });
          } else {
            service = this.get('store').createRecord('appointment-add-on-service', {
              created_at: (0, _moment.default)().format()
            });
          }

          this.get('firstAppointment.appointment_add_on_services').pushObject(service);
        }
      }
    }
  });

  _exports.default = _default;
});