define("frontend/routes/inbox/distribution-list", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = _baseRoute.default.extend({
    infinity: inject.service(),
    model: function model() {
      return this.get('infinity').model('distribution-message', {
        company_id: this.get('currentUser.user.company.id'),
        perPage: 20,
        page: 1
      });
    }
  });

  _exports.default = _default;
});