define("frontend/templates/components/adaptive-clarification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AvVMZYYy",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"mobile-gray-helper\",null,[[\"borderTop\",\"borderBottom\"],[[24,[\"borderTop\"]],[24,[\"borderBottom\"]]]],{\"statements\":[[0,\"    \"],[14,1],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"p\",true],[10,\"class\",\"clarification\"],[8],[14,1],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/adaptive-clarification.hbs"
    }
  });

  _exports.default = _default;
});