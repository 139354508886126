define("frontend/templates/components/client-list/pet-output/notes-output", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jgne9aId",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"client-list/pet-output/readonly-field-with-icon\",null,[[\"color\",\"icon\",\"label\",\"value\"],[\"gold\",\"info\",\"Internal notes\",[24,[\"internalNotes\"]]]]],false],[0,\"\\n  \"],[1,[28,\"client-list/pet-output/readonly-field-with-icon\",null,[[\"icon\",\"label\",\"value\"],[\"client-notes\",\"Client notes\",[24,[\"clientNotes\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[4,\"client-list/pet-output/section-header\",null,[[\"active\"],[[24,[\"active\"]]]],{\"statements\":[[0,\"Notes\"]],\"parameters\":[]},null],[0,\"\\n  \"],[1,[28,\"readonly-field\",null,[[\"classNames\",\"label\",\"value\"],[\"internal-notes\",\"Internal notes\",[24,[\"internalNotes\"]]]]],false],[0,\"\\n  \"],[1,[28,\"readonly-field\",null,[[\"label\",\"value\"],[\"Client instructions\",[24,[\"clientNotes\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/client-list/pet-output/notes-output.hbs"
    }
  });

  _exports.default = _default;
});