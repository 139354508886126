define("frontend/models/invoice", ["exports", "frontend/utils/invoice-type", "ember-data", "moment"], function (_exports, _invoiceType, _emberData, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend({
    company_invoice_number: _emberData.default.attr('number'),
    start_date: _emberData.default.attr('date'),
    end_date: _emberData.default.attr('date'),
    household: _emberData.default.belongsTo('household', {
      async: true
    }),
    total_amount_owed: _emberData.default.attr('number'),
    amount_paid: _emberData.default.attr('number'),
    amount_outstanding: _emberData.default.attr('number'),
    payment_method: _emberData.default.attr('string'),
    payment_last_four: _emberData.default.attr('string'),
    payment_date: _emberData.default.attr('date'),
    last_sent_date: _emberData.default.attr('date'),
    closed: _emberData.default.attr('boolean'),
    reminder_last_sent_date: _emberData.default.attr('date'),
    has_uncompleted_appointments: _emberData.default.attr('boolean'),
    invoice_detail: _emberData.default.belongsTo('invoice-detail', {
      async: true
    }),
    voided_at: _emberData.default.attr('date'),
    pet_ids: _emberData.default.attr(),
    isDraft: computed.equal('invoiceType', _invoiceType.default.DRAFT),
    isDue: computed.equal('invoiceType', _invoiceType.default.DUE),
    isPaid: computed.equal('invoiceType', _invoiceType.default.PAID),
    isVoided: computed('voided_at', function () {
      return this.get('voided_at') != null;
    }),
    isPartial: computed('total_amount_owed', 'amount_paid', function () {
      return this.get('total_amount_owed') > this.get('amount_paid') && this.get('amount_paid') > 0;
    }),
    sendOnDate: computed('end_date', function () {
      return (0, _moment.default)(this.get('end_date')).add(1, 'day');
    }),
    invoiceType: computed('total_amount_owed', 'amount_paid', 'closed', function () {
      if (!this.get('closed')) {
        return _invoiceType.default.DRAFT;
      } else if (this.get('total_amount_owed') > this.get('amount_paid')) {
        return _invoiceType.default.DUE;
      } else {
        return _invoiceType.default.PAID;
      }
    }),
    amount: computed('total_amount_owed', 'amount_outstanding', 'isDraft', 'isDue', function () {
      if (this.get('isDue')) {
        return this.get('amount_outstanding');
      } else {
        return this.get('total_amount_owed');
      }
    }),
    expectedAmount: computed('invoice_detail.{invoice_adjustments.@each.amount,appointments.@each.cost}', function () {
      var s1 = this.get('invoice_detail.invoice_adjustments').mapBy('amount').reduce(function (a, b) {
        return +a + +b;
      }, 0);
      var s2 = this.get('invoice_detail.appointments').mapBy('cost').reduce(function (a, b) {
        return +a + +b;
      }, 0);
      return s1 + s2;
    })
  });

  _exports.default = _default;
});