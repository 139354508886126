define("frontend/templates/components/add-client/secondary-contact-prompt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0z+I7hcf",
    "block": "{\"symbols\":[],\"statements\":[[7,\"img\",true],[10,\"class\",\"avatar-image\"],[10,\"src\",\"assets/images/staff/placeholder-human-image@3x.png\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"header\"],[8],[0,\"Would you like to add a secondary contact?\"],[9],[0,\"\\n\"],[7,\"p\",true],[10,\"class\",\"clarification\"],[8],[0,\"A secondary contact is someone who’d be considered a co-guardian and may live at the same address as the primary contact.\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"button-container\"],[8],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"tabIndex\",\"onclick\",\"color\"],[\"1\",[28,\"action\",[[23,0,[]],\"skip\"],null],\"red-white-border\"]],{\"statements\":[[0,\"    Skip\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"tabIndex\",\"onclick\",\"color\"],[\"1\",[28,\"action\",[[23,0,[]],\"yes\"],null],\"red\"]],{\"statements\":[[0,\"    Yes\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/add-client/secondary-contact-prompt.hbs"
    }
  });

  _exports.default = _default;
});