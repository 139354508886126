define("frontend/components/add-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['add-button'],
    backRoute: null,
    backHouseholdId: null,
    backId: null
  });

  _exports.default = _default;
});