define("frontend/components/ppb-modal", ["exports", "ember-modal-dialog/components/modal-dialog"], function (_exports, _modalDialog) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = _modalDialog.default.extend({
    media: inject.service(),
    customContainerClassNames: '',
    containerClassNames: computed('media.isMobile', 'customContainerClassNames.[]', function () {
      var classNames = ['ppb-modal', 'ember-modal-dialog', this.get('customContainerClassNames')];

      if (this.get('media.isMobile')) {
        classNames.push('mobile');
      }

      return classNames;
    }),
    hasOverlay: true,
    translucentOverlay: false
  });

  _exports.default = _default;
});