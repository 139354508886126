define("frontend/templates/components/popout-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "h8aUV3A7",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"ppb-search-paper-select\",null,[[\"searchEnabled\",\"searchPlaceholder\",\"searchField\",\"dropdownClass\",\"selected\",\"disabled\",\"options\",\"placeholder\",\"onopen\",\"onChange\"],[[24,[\"searchEnabled\"]],\"Type to filter...\",\"label\",[24,[\"dropdownClass\"]],[24,[\"selectedOption\"]],[24,[\"disabled\"]],[24,[\"options\"]],[24,[\"placeholder\"]],[28,\"action\",[[23,0,[]],\"open\",[24,[\"selectedOption\",\"value\"]]],null],[28,\"action\",[[23,0,[]],\"selectOption\"],null]]],{\"statements\":[[0,\"  \"],[1,[23,1,[\"label\"]],true],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/popout-dropdown.hbs"
    }
  });

  _exports.default = _default;
});