define("frontend/templates/components/drag-drop-zone", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "TnjTzuM1",
    "block": "{\"symbols\":[],\"statements\":[[4,\"draggable-object-target\",null,[[\"dragOverAction\",\"dragOutAction\",\"action\",\"classNames\"],[[28,\"action\",[[23,0,[]],\"draggedOver\"],null],[28,\"action\",[[23,0,[]],\"draggedOut\"],null],[28,\"action\",[[23,0,[]],\"dropItem\"],null],\"drop-zone-target\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"drag-drop-zone-container\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"drop-icon\"],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"drop-text\"],[8],[1,[22,\"dropText\"],false],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/drag-drop-zone.hbs"
    }
  });

  _exports.default = _default;
});