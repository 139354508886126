define("frontend/routes/client-list/household/household-user", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model: function model(params) {
      var household_user_id = params.household_user_id;
      return this.store.findRecord('household-user', household_user_id);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('addressModalOpen', false);
      controller.set('upcomingAppointments', []);
      controller.set('editingUpcomingAppointments', false);
      controller.set('loadingAppointments', false);
      controller.set('showLoadMore', false);
      controller.set('alreadySentRequest', false);
      controller.set('page', 1);
      controller.set('loadingMessages', false);
    },
    backQueryParams: function backQueryParams() {
      var backRoute = this.get('controller.currentRouteName');
      var backHouseholdId = this.get('controller.model.id');
      var backId = this.get('controller.selectedUser.id');
      return {
        backHouseholdId: backHouseholdId,
        backId: backId,
        backRoute: backRoute
      };
    },
    actions: {
      transitionToMessages: function transitionToMessages(household) {
        var _this = this;

        this.get('controller').set('messagesLoading', true);
        var queryParams = this.backQueryParams.call(this);
        this.transitionTo('client-list.household.messages', household, {
          queryParams: queryParams
        }).then(function () {
          _this.get('controller').set('messagesLoading', false);
        });
      }
    }
  });

  _exports.default = _default;
});