define("frontend/templates/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ePrSnxUB",
    "block": "{\"symbols\":[],\"statements\":[[4,\"svg-loading-spinner\",null,null,{\"statements\":[[1,[22,\"message\"],true]],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/loading-spinner.hbs"
    }
  });

  _exports.default = _default;
});