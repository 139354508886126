define("frontend/services/react-app", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Service.extend({
    reactBaseUrl: _environment.default.environment == 'development' ? "http://localhost:3001" : "",
    reportsUrl: computed('reactBaseUrl', function () {
      return "".concat(this.get('reactBaseUrl'), "/react/analytics");
    }),
    companyDocumentsUrl: computed('reactBaseUrl', function () {
      return "".concat(this.get('reactBaseUrl'), "/react/settings/company/documents");
    }),
    companySubscriptionsUrl: computed('reactBaseUrl', function () {
      return "".concat(this.get('reactBaseUrl'), "/react/settings/company/subscriptions");
    }),
    getAppointmentRequestUrl: function getAppointmentRequestUrl(companyId) {
      var requestId = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var apptIds = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var petIds = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      var categoryId = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : null;
      var baseUrl = "".concat(this.get('reactBaseUrl'), "/react/portal/").concat(companyId, "/request-appointments/");

      if (requestId) {
        baseUrl += "?requestId=".concat(requestId);
      }

      if (apptIds) {
        baseUrl += "?apptIds=".concat(apptIds.join(','));
      }

      if (petIds && categoryId) {
        baseUrl += "?petIds=".concat(petIds.join(','), "&categoryId=").concat(categoryId);
      }

      return baseUrl;
    },
    getPetParentDocumentsUrl: function getPetParentDocumentsUrl(householdId) {
      return "".concat(this.get('reactBaseUrl'), "/react/portal/").concat(householdId, "/documents");
    }
  });

  _exports.default = _default;
});