define("frontend/components/appointment-requests-list/header-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['header-dropdown'],
    options: [],
    selectedOption: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      $(window).click(function (_ref) {
        var target = _ref.target;
        var $target = $(target);

        if ($target.parent().is('.request-header, .header-text')) {
          return;
        }

        _this.close();
      });
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      $(window).off('click');
    },
    groupedHeaders: Ember.computed('options.[]', function () {
      return !Array.isArray(this.get('options'));
    }),
    headerEntries: Ember.computed('groupedHeaders', function () {
      if (this.get('groupedHeaders')) {
        return Object.entries(this.get('options'));
      }
    }),
    checkmarkImage: Ember.computed('selectedOption', function () {
      return "assets/images/appointment-requests/check-".concat(this.get('selectedOption'), ".svg");
    }),
    actions: {
      selectOption: function selectOption(option) {
        this.get('selectOption')(option);
      }
    }
  });

  _exports.default = _default;
});