define("frontend/controllers/admin/veterinarian-clinics", ["exports", "ember-data", "frontend/mixins/admin/approvable-table", "frontend/mixins/autocomplete/highlight-first-match"], function (_exports, _emberData, _approvableTable, _highlightFirstMatch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var escapeRegExp = function escapeRegExp(input) {
    return input.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  };

  var _default = Ember.Controller.extend(_approvableTable.default, _highlightFirstMatch.default, {
    modelName: 'veterinarian-clinic',
    modelOptions: {
      all: true
    },
    store: inject.service(),
    vetGroupOptions: computed(function () {
      var promise = this.get('store').query('vet-group', {
        all: true
      }, {
        reload: true
      }).then(function (groups) {
        var mappedClients = groups.map(function (vetGroup) {
          return {
            vetGroup: vetGroup
          };
        });
        mappedClients.pushObject({
          name: 'Create a new group?'
        });
        return mappedClients;
      });
      return _emberData.default.PromiseObject.create({
        promise: promise
      });
    }),
    selectedReplace: computed('savedReplace', function () {
      var vet = this.get('savedReplace');

      if (vet) {
        return "".concat(vet.name, " (").concat(vet.id, ")");
      }
    }),
    debouncedSearchVets: function debouncedSearchVets(_ref) {
      var vetName = _ref.vetName;
      var vets = this.get('store').query('veterinarian_clinic', {
        filter: vetName
      }, {
        reload: true
      });
      this.set('approvedVets', vets);
    },
    actions: {
      submitReplace: function submitReplace(replace) {
        var url = "/api/v2/veterinarian_clinics/".concat(this.get('editingReplace.id'), "/replace");
        this.submitReplace(url, replace, this.get('editingReplace'));
      },
      saveVet: function saveVet(vet) {
        this.send('save', vet);
        this.set('editingVet', null);
        this.set('editingVetGroup', null);
      },
      rollbackVet: function rollbackVet(vet) {
        vet.rollbackAttributes();
        this.set('editingVet', null);
      },
      showRemove: function showRemove(vet) {
        this.set('selectedVetForRemoval', vet);
      },
      confirmRemove: function confirmRemove() {
        this.send('remove', this.get('selectedVetForRemoval'));
        this.set('selectedVetForRemoval', null);
      },
      denyRemove: function denyRemove() {
        this.set('selectedVetForRemoval', null);
      },
      showEditingVetGroupModal: function showEditingVetGroupModal(vet) {
        this.set('selectedVet', vet);
        this.set('editingVetGroup', true);
      },
      closeEditingVetGroupModal: function closeEditingVetGroupModal() {
        this.set('selectedVet', null);
        this.set('editingVetGroup', false);
      },
      closeNewVetGroupModal: function closeNewVetGroupModal() {
        this.get('creatingVetGroup').rollbackAttributes();
        this.set('creatingVetGroup', null);
      },
      createVetGroup: function createVetGroup(name) {
        var editingVetGroup = this.get('store').createRecord('vet-group', {
          name: name
        });
        this.notifyPropertyChange('vetGroupOptions');
        this.set('creatingVetGroup', editingVetGroup);
      },
      uploadedVetGroupPhoto: function uploadedVetGroupPhoto(photo) {
        this.set('creatingVetGroup.logo_url', photo);
      },
      changeVetGroup: function changeVetGroup(option) {
        if (option == null) {
          this.set('selectedVet.vet_group', null);
        } else if (option.vetGroup) {
          this.set('selectedVet.vet_group', option.vetGroup);
        } else {
          this.send('createVetGroup', this.get('vetTerm'));
        }
      },
      searchVetGroups: function searchVetGroups(term) {
        var nameRegExp = new RegExp(escapeRegExp("".concat(term)), 'i', 'g');
        var response = this.get('vetGroupOptions.content').filter(function (c) {
          return !c.vetGroup || nameRegExp.exec(c.vetGroup.get('name'));
        });
        this.set('vetTerm', term);
        return response || [];
      },
      saveVetGroup: function saveVetGroup() {
        var _this = this;

        this.get('creatingVetGroup').save().then(function (vetGroup) {
          _this.set('selectedVet.vet_group', vetGroup);

          _this.set('creatingVetGroup', null);

          _this.notifyPropertyChange('vetGroupOptions');
        });
      },
      searchVets: function searchVets(vetName) {
        Ember.run.debounce(this, this.debouncedSearchVets, {
          vetName: vetName
        }, 500);
      }
    }
  });

  _exports.default = _default;
});