define("frontend/components/client-list/pet-output/medical-mobile-action-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['medical-mobile-action-bar'],
    address: null,
    email: null,
    phone: null,
    actions: {
      clickAddress: function clickAddress() {
        this.get('clickAddress').apply(void 0, arguments);
      },
      clickPhoneNumber: function clickPhoneNumber() {
        this.get('clickPhoneNumber').apply(void 0, arguments);
      },
      clickEmail: function clickEmail() {
        this.get('clickEmail').apply(void 0, arguments);
      }
    }
  });

  _exports.default = _default;
});