define("frontend/components/household-select/household-select-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['household-select-option'],
    classNameBindings: ['selected'],
    selected: false,
    pendingClient: Ember.computed('household.client_status', function () {
      return this.get('deniedClient') || this.get('household.client_status') == 'pending';
    }),
    deniedClient: Ember.computed('household.client_status', function () {
      return this.get('household.client_status') == 'denied';
    })
  });

  _exports.default = _default;
});