define("frontend/components/mobile-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['mobile-header'],
    staff: null,
    searching: false,
    currentUser: Ember.inject.service(),
    activityLogs: Ember.inject.service(),
    hasUnreadLogs: Ember.computed.alias('activityLogs.hasUnreadLogs'),
    inboxHasUnreadMessages: Ember.inject.service(),
    hasUnreadMessages: Ember.computed.alias('inboxHasUnreadMessages.hasUnreadMessages'),
    showRedDot: Ember.computed.or('hasUnreadLogs', 'hasUnreadMessages'),
    didInsertElement: function didInsertElement() {
      $('.ember-application').addClass('no-body-padding');
    },
    willDestroyElement: function willDestroyElement() {
      $('.ember-application').removeClass('no-body-padding');
    },
    actions: {
      clickHamburger: function clickHamburger() {
        Ember.run.scheduleOnce('afterRender', function () {
          $('body').toggleClass('push-active');
        });
      },
      clickAdd: function clickAdd() {
        this.addAppointment();
      },
      selectCareProvider: function selectCareProvider() {
        this.selectCareProvider();
      },
      clickDragAndDrop: function clickDragAndDrop() {
        this.clickDragAndDrop();
      },
      clickFilter: function clickFilter() {
        this.clickFilter();
      }
    }
  });

  _exports.default = _default;
});