define("frontend/components/activity-log-item", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var LOG_ICON_PREFIX = 'assets/images/activity_log_icons';
  var PET_PLACEHOLDER_PREFIX = 'assets/images/placeholders';
  var HUMAN_PLACEHOLDER = 'assets/images/staff/placeholder-human-image@3x.png';
  var COMPANY_PLACEHOLDER = 'assets/images/settings/company-placeholder.png';

  var _default = Ember.Component.extend({
    placeholder: HUMAN_PLACEHOLDER,
    companyPlaceholder: COMPANY_PLACEHOLDER,
    showChangedFields: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      if (this.get('log.currently_updating')) {
        this.set('intervalId', setInterval(function () {
          return _this.pullUpdatingStatus();
        }, 5000));
      }

      this.element.addEventListener('mouseenter', this.handleMouseEnter);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.element.removeEventListener('mouseenter', this.handleMouseEnter);
    },
    handleMouseEnter: function handleMouseEnter(event) {
      event.preventDefault();
      event.stopImmediatePropagation();
    },
    logTypeIcon: computed('log.event_type', function () {
      return "".concat(LOG_ICON_PREFIX, "/").concat(this.get('log.event_type'), ".svg");
    }),
    miniAvatarUrl: computed('log.mini_avatar_url', function () {
      var avatar_url = this.get('log.mini_avatar_url');

      if (avatar_url == 'user_placeholder') {
        return HUMAN_PLACEHOLDER;
      } else if (avatar_url == 'pet_placeholder') {
        return "".concat(PET_PLACEHOLDER_PREFIX, "/").concat(this.get('log.pet_type'), ".svg");
      } else {
        return avatar_url;
      }
    }),
    timeCreated: computed('log', 'log.created_at', function () {
      var time = moment(this.get('log.created_at'));

      if (time.isValid()) {
        return time.format('h:mm A');
      }
    }),
    unread: computed('log', 'log.created_at', function () {
      return this.get('log.created_at') > this.get('unreadAfter');
    }),
    pullUpdatingStatus: function pullUpdatingStatus() {
      var _this2 = this;

      return _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/activity_logs/check_updating_status?id=").concat(this.get('log.id')),
        method: 'GET'
      }).then(function (response) {
        if (!response.still_updating) {
          _this2.set('log.currently_updating', false);

          clearInterval(_this2.get('intervalId'));
        }
      });
    },
    actions: {
      toggleFieldChanges: function toggleFieldChanges() {
        this.set('showChangedFields', !this.get('showChangedFields'));
      }
    }
  });

  _exports.default = _default;
});