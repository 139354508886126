define("frontend/templates/components/invoice-item-header/invoice-date-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0DSuYf7z",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"invoice-number\"],[8],[0,\"#\"],[1,[24,[\"invoice\",\"company_invoice_number\"]],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"invoice-date\"],[8],[0,\"(\"],[1,[28,\"format-date\",[[24,[\"invoice\",\"start_date\"]],\"M/D\"],null],false],[0,\" - \"],[1,[28,\"format-date\",[[24,[\"invoice\",\"end_date\"]],\"M/D\"],null],false],[0,\")\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/invoice-item-header/invoice-date-number.hbs"
    }
  });

  _exports.default = _default;
});