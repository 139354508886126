define("frontend/templates/staff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "UZ7HrK9u",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"mobile-header-flex\",null,[[\"showHamburger\",\"showAdd\",\"showBack\",\"showDone\",\"doneText\",\"clickDone\",\"showSearch\",\"clickSearch\",\"clickBack\"],[[28,\"or\",[[24,[\"notOwner\"]],[24,[\"showHamburger\"]]],null],false,[24,[\"hasSelectedEmployee\"]],[24,[\"showingStaffList\"]],\"Cancel\",[28,\"action\",[[23,0,[]],\"toggleStaffList\"],null],[28,\"and\",[[28,\"not\",[[24,[\"showingStaffList\"]]],null],[24,[\"isOwner\"]]],null],[28,\"action\",[[23,0,[]],\"toggleStaffList\"],null],[28,\"action\",[[23,0,[]],\"backToStaff\"],null]]],{\"statements\":[[0,\"    \"],[7,\"header\",true],[10,\"class\",\"header-text\"],[8],[1,[22,\"headerText\"],false],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[7,\"div\",true],[10,\"class\",\"container-fluid\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"row\"],[8],[0,\"\\n\"],[4,\"unless\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"sidebar-nav\",null,[[\"collapsed\"],[true]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[7,\"div\",true],[10,\"class\",\"staff-content\"],[8],[0,\"\\n      \"],[1,[22,\"outlet\"],false],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/staff.hbs"
    }
  });

  _exports.default = _default;
});