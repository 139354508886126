define("frontend/routes/add-client/pet", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = _baseRoute.default.extend({
    addClientSaving: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    household: null,
    householdId: computed.readOnly('household.id'),
    model: function model(params) {
      var _this = this;

      var household_id = params.household_id,
          petIndex = params.petIndex;
      this.set('petIndex', petIndex);
      return this.store.findRecord('household', household_id).then(function (household) {
        _this.set('household', household);

        return household;
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('anySidePanelActive', false);
    },
    actions: {
      transitionToHuman: function transitionToHuman() {
        var householdId = this.get('householdId');

        if (this.get('household.secondaryContact.id')) {
          this.transitionTo('add-client.secondary-contact', householdId);
        } else {
          this.transitionTo('add-client.primary-contact', {
            queryParams: {
              householdId: householdId
            }
          });
        }
      }
    }
  });

  _exports.default = _default;
});