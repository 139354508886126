define("frontend/templates/components/invoice-receipt/invoice-summary-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dfGRiOfM",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[8],[1,[22,\"headerText\"],false],[9],[7,\"div\",true],[8],[1,[28,\"format-money\",[[24,[\"amount\"]]],[[\"precision\"],[2]]],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/invoice-receipt/invoice-summary-header.hbs"
    }
  });

  _exports.default = _default;
});