define("frontend/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0isEG4v6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"showPage\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"index-page\",null,[[\"sendMessage\",\"submitBetaRequest\"],[[28,\"action\",[[23,0,[]],\"sendMessage\"],null],[28,\"action\",[[23,0,[]],\"submitBetaRequest\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/index.hbs"
    }
  });

  _exports.default = _default;
});