define("frontend/templates/components/masked-callout", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "hsAjPeld",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"  \"],[1,[28,\"mobile-masked-input\",null,[[\"mask\",\"value\",\"label\",\"borderTop\",\"borderBottom\"],[[24,[\"mask\"]],[24,[\"value\"]],[24,[\"label\"]],[24,[\"borderTop\"]],[24,[\"borderBottom\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"input-callout\",null,[[\"tabindex\",\"label\",\"callout\",\"inputActivated\"],[[24,[\"tabindex\"]],[24,[\"label\"]],[24,[\"callout\"]],[24,[\"inputActivated\"]]]],{\"statements\":[[0,\"    \"],[1,[28,\"masked-input\",null,[[\"tabindex\",\"hint\",\"showHint\",\"mask\",\"value\",\"focusOut\",\"focusIn\",\"hiddenInput\"],[[24,[\"tabindex\"]],[24,[\"hint\"]],[24,[\"showHint\"]],[24,[\"mask\"]],[24,[\"value\"]],[28,\"action\",[[23,0,[]],\"focusOut\"],null],[28,\"action\",[[23,0,[]],\"focusIn\"],null],[24,[\"hiddenInput\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[28,\"and\",[[28,\"and\",[[24,[\"hover\"]],[24,[\"hint\"]]],null],[24,[\"showHint\"]]],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"alert-hint\",null,[[\"hint\",\"deprecatedOldStyling\"],[[24,[\"hint\"]],true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/masked-callout.hbs"
    }
  });

  _exports.default = _default;
});