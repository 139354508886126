define("frontend/routes/add-appointment", ["exports", "frontend/mixins/add-appointment", "frontend/mixins/transition-back-route", "frontend/routes/base-route"], function (_exports, _addAppointment, _transitionBackRoute, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend(_transitionBackRoute.default, _addAppointment.default, {
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('redirecting', false);

      if (controller.get('preselectDate')) {
        controller.set('selectedOneTimeDates', [controller.get('date')]);
      }
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.get('addAppointmentData').setup();
    },
    actions: {
      cancel: function cancel() {
        var backRoute = this.get('controller.backRoute');
        var backHouseholdId = this.get('controller.backHouseholdId');
        var backId = this.get('controller.backId');
        this.set('controller.redirecting', true);
        this.transitionBack(backRoute, backHouseholdId, backId);
      }
    }
  });

  _exports.default = _default;
});