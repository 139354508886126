define("frontend/components/select-service-categories-modal", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    flashMessage: inject.service(),
    company: null,
    allCategories: [],
    selectedCategories: [],
    originalCategoryIds: [],
    showTimeBlocksModal: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this.set('loading', true);
      this.set('allCategories', []);
      this.set('selectedCategories', []);

      _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/companies/").concat(this.get('company.id'), "/service_categories"),
        method: 'GET'
      }).then(function (response) {
        _this.set('loading', false);

        _this.set('allCategories', response.all_categories);

        var selectedCategoryIds = response.company_categories.map(function (category) {
          return category.id;
        });

        _this.set('originalCategoryIds', selectedCategoryIds);

        var selectedCategories = response.company_categories.map(function (category) {
          return {
            id: category.id,
            time_frames: []
          };
        });

        _this.set('selectedCategories', selectedCategories);

        _this.set('categoriesWithServices', response.company_categories);
      });
    },
    selectedCategoryIds: computed('selectedCategories.[]', function () {
      return this.get('selectedCategories').map(function (category) {
        return category.id;
      });
    }),
    categoriesWithSelected: computed('allCategories.[]', 'selectedCategoryIds.[]', function () {
      var categories = this.get('allCategories');
      var selected = this.get('selectedCategoryIds');
      categories.forEach(function (category) {
        if (selected.includes(category.id)) {
          Ember.set(category, 'isSelected', true);
        } else {
          Ember.set(category, 'isSelected', false);
        }
      });
      return categories;
    }),
    actions: {
      close: function close() {
        this.close();
      },
      closeRemoveServiceModal: function closeRemoveServiceModal() {
        var selectedCategoryIds = this.get('selectedCategoryIds');
        var selectedCategories = this.get('selectedCategories');
        this.get('categoriesWithServices').forEach(function (category) {
          if (category.has_services && !selectedCategoryIds.includes(category.id)) {
            selectedCategories.pushObject({
              id: category.id,
              timeFrames: []
            });
          }
        });
        this.set('showRemovingServiceModal', false);
      },
      toggleCategory: function toggleCategory(category) {
        var selected = this.get('selectedCategoryIds');

        if (selected.includes(category.id)) {
          if (category.name == 'Miscellaneous') {
            var misc_service = this.get('categoriesWithServices').filter(function (cat) {
              return cat.id == category.id;
            }).get('firstObject');

            if (misc_service && misc_service.has_services) {
              this.get('flashMessage').sendFlashMessage('This category can\'t be removed when there are still services associated with it.', 'error-without-refresh');
              return;
            }
          }

          var selectedCategories = this.get('selectedCategories').filter(function (cat) {
            return cat.id != category.id;
          });
          this.set('selectedCategories', selectedCategories);
        } else {
          var _selectedCategories = this.get('selectedCategories');

          category.time_window_in_minutes = '60';
          category.time_frames = [];

          _selectedCategories.pushObject({
            id: category.id,
            time_frames: [],
            use_time_blocks: false,
            show_to_pet_parents: false
          });

          if (!this.get('originalCategoryIds').includes(category.id)) {
            this.set('lastSelectedCategory', category);
            this.set('showTimeBlocksModal', true);
          }
        }
      },
      closeTimeBlockModal: function closeTimeBlockModal() {
        var category = this.get('lastSelectedCategory');
        var selectedCategories = this.get('selectedCategories').filter(function (cat) {
          return cat.id != category.id;
        });
        this.set('selectedCategories', selectedCategories);
        this.set('lastSelectedCategory', null);
        this.set('showTimeBlocksModal', false);
      },
      addCategory: function addCategory(timeFrames, timeWindow, useTimeBlocks, showToPetParents) {
        var lastCategory = this.get('lastSelectedCategory');
        var selectedCategories = this.get('selectedCategories').filter(function (cat) {
          return cat.id != lastCategory.id;
        });
        selectedCategories.pushObject({
          id: lastCategory.id,
          time_frames: timeFrames,
          time_window_in_minutes: timeWindow,
          use_time_blocks: useTimeBlocks,
          show_to_pet_parents: showToPetParents
        });
        this.set('selectedCategories', selectedCategories);
        this.set('lastSelectedCategory', null);
        this.set('showTimeBlocksModal', false);
      },
      clickedSave: function clickedSave() {
        var removedCategoryWithServices = false;
        var selectedCategoryIds = this.get('selectedCategoryIds');
        this.get('categoriesWithServices').forEach(function (category) {
          if (category.has_services && !selectedCategoryIds.includes(category.id)) {
            removedCategoryWithServices = true;
          }
        });

        if (removedCategoryWithServices) {
          this.set('showRemovingServiceModal', true);
        } else {
          this.send('save');
        }
      },
      save: function save() {
        this.set('loading', true);
        this.set('showRemovingServiceModal', false);
        var selectedCategories = this.get('selectedCategories');

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/companies/").concat(this.get('company.id'), "/set_categories"),
          method: 'POST',
          data: {
            categories: JSON.stringify(selectedCategories)
          }
        }).then(function () {
          window.location.reload();
        });
      }
    }
  });

  _exports.default = _default;
});