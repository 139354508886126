define("frontend/components/calendar-list-week", ["exports", "frontend/components/calendar-list", "frontend/utils/calendar-util", "moment"], function (_exports, _calendarList, _calendarUtil, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = _calendarList.default.extend({
    careProvider: null,
    canDrag: computed.readOnly('currentUser.user.canAddEditAppointments'),
    currentUser: inject.service(),
    company: computed.alias('currentUser.user.company'),
    timeBoxes: computed('services.@each.starts_at', 'daysOfWeek', 'date', function () {
      var _this = this;

      var daysOfWeek = this.get('daysOfWeek');

      _calendarUtil.default.initHeaders();

      return daysOfWeek.map(function (momentDay) {
        var isToday = _moment.default.tz(_this.get('company.time_zone_mapping')).isSame(momentDay, 'day');

        var isWeekend = momentDay.day() === 0 || momentDay.day() === 6;
        return {
          isWeekend: isWeekend,
          isToday: isToday,
          day: momentDay.format(),
          timeBoxes: _this.timeBoxesForDay(momentDay)
        };
      });
    }),
    daysOfWeek: computed('date', 'company.weekType', 'company.time_zone_mapping', function () {
      var weekType = this.get('company.weekType');
      var timeZone = this.get('company.time_zone_mapping');

      if (!timeZone || !weekType) {
        return [];
      }

      var startOfWeek = (0, _moment.default)(this.get('date')).tz(timeZone).startOf(weekType);
      var endOfWeek = (0, _moment.default)(this.get('date')).tz(timeZone).endOf(weekType);
      var daysOfWeek = [];

      while (startOfWeek.isBefore(endOfWeek)) {
        daysOfWeek.push(startOfWeek.clone());
        startOfWeek.add(1, 'day');
      }

      this.scrollToFirstService();
      return daysOfWeek;
    }),
    timesInDay: computed(function () {
      // IMPORTANT this is a non DST switch day so hours don't repeat
      // TODO: Move to util
      var FIXED_DAY = '2017-01-01';
      var startTime = (0, _moment.default)(FIXED_DAY).startOf('day');
      var endTime = (0, _moment.default)(FIXED_DAY).endOf('day');
      var times = [];

      while (startTime < endTime) {
        times.push({
          hour: startTime.format('h A'),
          hidden: false,
          faded: false
        });
        startTime.add(1, 'hour');
      }

      return times;
    }),
    servicesWithAppointments: function servicesWithAppointments(services) {
      var _this2 = this;

      return services.filter(function (service) {
        if (_this2.get('onCancelationSchedule')) {
          return service.get('sortedCanceledGroupedAppointments.length') > 0;
        } else {
          return service.get('sortedGroupedAppointmentsWithoutCanceled.length') > 0;
        }
      });
    },
    servicesInDay: function servicesInDay(services, day) {
      return services.filter(function (service) {
        return !service.get('all_day_service') && parseInt(_moment.default.parseZone(service.get('starts_at')).get('day')) === parseInt(day);
      });
    },
    allDayServicesInDay: function allDayServicesInDay(services, day) {
      return services.filter(function (service) {
        return service.get('all_day_service') && parseInt(_moment.default.parseZone(service.get('starts_at')).get('day')) === parseInt(day);
      });
    },
    servicesAtHour: function servicesAtHour(services, hour) {
      return services.filter(function (service) {
        return parseInt(_moment.default.parseZone(service.get('starts_at')).get('hour')) === parseInt(hour);
      });
    },
    servicesInFifteenMinuteRange: function servicesInFifteenMinuteRange(services, startMinute) {
      return services.filter(function (service) {
        var serviceMinute = parseInt(_moment.default.parseZone(service.get('starts_at')).get('minute'));
        var roundedMinute = serviceMinute + 7;

        if (roundedMinute >= 60) {
          roundedMinute = 59;
        }

        return roundedMinute >= startMinute && roundedMinute < parseInt(startMinute) + 15;
      });
    },
    timeBoxesForDay: function timeBoxesForDay(momentDay) {
      var FIXED_DAY = '2017-01-01';
      var startOfDayTime = (0, _moment.default)(FIXED_DAY).startOf('day');
      var endOfDayTime = (0, _moment.default)(FIXED_DAY).endOf('day');
      var incrementingTime = startOfDayTime.clone();
      var timeBoxes = [];
      var servicesWithAppointments = this.servicesWithAppointments(this.get('services'));
      var servicesInDay = this.servicesInDay(servicesWithAppointments, momentDay.get('day'));
      var allDayServicesInDay = this.allDayServicesInDay(servicesWithAppointments, momentDay.get('day'));
      timeBoxes.push({
        services: allDayServicesInDay
      });

      while (incrementingTime < endOfDayTime) {
        var servicesAtHour = this.servicesAtHour(servicesInDay, incrementingTime.get('hour'));
        var zeroMinuteServices = this.servicesInFifteenMinuteRange(servicesAtHour, 0);
        var fifteenMinuteServices = this.servicesInFifteenMinuteRange(servicesAtHour, 15);
        var thirtyMinuteServices = this.servicesInFifteenMinuteRange(servicesAtHour, 30);
        var fourtyFiveMinuteServices = this.servicesInFifteenMinuteRange(servicesAtHour, 45);
        var hour = incrementingTime.get('hour');
        timeBoxes.push({
          hour: hour,
          zeroMinuteServices: zeroMinuteServices,
          fifteenMinuteServices: fifteenMinuteServices,
          thirtyMinuteServices: thirtyMinuteServices,
          fourtyFiveMinuteServices: fourtyFiveMinuteServices
        });
        incrementingTime.add(1, 'hour');
      }

      return timeBoxes;
    }
  });

  _exports.default = _default;
});