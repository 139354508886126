define("frontend/components/view-report-card/photos", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer;

  var _default = Ember.Component.extend({
    media: inject.service(),
    selectedPhoto: null,
    selectedMapThumbnail: false,
    selectedPhotoUrl: computed('selectedPhoto', 'gpsMapUrl', 'selectedMapThumbnail', function () {
      if (this.get('selectedPhoto') && !this.get('selectedMapThumbnail')) {
        return this.get('selectedPhoto.url');
      } else {
        return this.get('gpsMapUrl');
      }
    }),
    mediaIsNotMobile: computed.not('media.isMobile'),
    hasPhotos: computed.notEmpty('reportCard.reportCardImages'),
    hasAdditionalPhotos: computed.notEmpty('reportCard.reportCardImagesWithoutCoverPhoto'),
    showMobilePhotoCarouselAction: computed.and('media.isMobile', 'hasPhotos'),
    showDesktopPhotoCarouselAction: computed.and('mediaIsNotMobile', 'hasAdditionalPhotos'),
    inflectedPhotoText: computed('reportCard.reportCardImages.length', function () {
      var length = this.get('reportCard.reportCardImages.length');

      if (length === 0) {
        return '';
      } else if (length === 1) {
        return 'See photo';
      } else {
        return "See ".concat(length, " photos");
      }
    }),
    showSavePhotoButton: computed.and('mediaIsNotMobile', 'selectedPhoto'),
    notShowingGpsMap: computed('selectedPhotoUrl', 'gpsMapUrl', function () {
      return this.get('selectedPhotoUrl') !== this.get('gpsMapUrl');
    }),
    gpsMapUrl: null,
    previousPhotoIconUrl: 'assets/images/report-card/scroll-left@3x.png',
    nextPhotoIconUrl: 'assets/images/report-card/scroll-right@3x.png',
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      this.set('selectedPhoto', this.get('reportCard.coverPhoto'));

      if (this.get('media.isMobile')) {
        var deviceRatio = window.devicePixelRatio || 1;
        var width = screen.width * deviceRatio;
        var height = 280 * deviceRatio;
        var id = this.get('appointment.id');
        var url = "".concat(_environment.default.APP.host, "/api/v2/appointments/").concat(id, "/check_in_gps_map?width=").concat(width, "&height=").concat(height);

        _jquery.default.ajax({
          url: url,
          method: 'GET'
        }).then(function (response) {
          _this.set('gpsMapUrl', response.url);
        });
      } else {
        this.set('gpsMapUrl', this.get('appointment.gps_markers_report_card_view_desktop_url'));
      }
    },
    previousButtonHoverObserver: observer('hasPreviousPhoto', function () {
      if (!this.get('hasPreviousPhoto')) {
        this.set('previousPhotoIconUrl', 'assets/images/report-card/scroll-left@3x.png');
      }
    }),
    nextPhotoButtonHoverObserver: observer('hasNextPhoto', function () {
      if (!this.get('hasNextPhoto')) {
        this.set('nextPhotoIconUrl', 'assets/images/report-card/scroll-right@3x.png');
      }
    }),
    hasPreviousPhoto: computed('selectedPhoto.id', 'reportCard.coverPhoto.id', 'reportCard.reportCardImagesWithoutCoverPhoto.[]', 'selectedMapThumbnail', function () {
      var selectedId = this.get('selectedPhoto.id');
      var coverPhotoId = this.get('reportCard.coverPhoto.id');
      var reportCardImages = this.get('reportCard.reportCardImagesWithoutCoverPhoto');
      var currentPhotoIndex = reportCardImages.findIndex(function (rci) {
        return rci.get('id') === selectedId;
      });

      if (this.get('selectedMapThumbnail') || selectedId === coverPhotoId) {
        return false;
      } else if (currentPhotoIndex === 0) {
        // deal with cover photo
        return true;
      } else {
        var previousPhotoIndex = currentPhotoIndex - 1;
        return typeof reportCardImages[previousPhotoIndex] !== 'undefined';
      }
    }),
    hasNextPhoto: computed('selectedPhoto.id', 'reportCard.coverPhoto.id', 'reportCard.reportCardImagesWithoutCoverPhoto.[]', 'selectedMapThumbnail', function () {
      var selectedId = this.get('selectedPhoto.id');
      var coverPhotoId = this.get('reportCard.coverPhoto.id');

      if (this.get('selectedMapThumbnail')) {
        return false;
      } else if (selectedId === coverPhotoId) {
        return true;
      } else {
        var reportCardImages = this.get('reportCard.reportCardImagesWithoutCoverPhoto');
        var currentPhotoIndex = reportCardImages.findIndex(function (rci) {
          return rci.get('id') === selectedId;
        });
        var nextPhotoIndex = currentPhotoIndex + 1;
        return typeof reportCardImages[nextPhotoIndex] !== 'undefined';
      }
    }),
    actions: {
      showMobilePhotoCarousel: function showMobilePhotoCarousel() {
        if (this.showMobilePhotoCarousel) {
          this.showMobilePhotoCarousel();
        }
      },
      hoverPreviousPhoto: function hoverPreviousPhoto(hovering) {
        if (hovering) {
          this.set('previousPhotoIconUrl', 'assets/images/scroll-left-hover@3x.png');
        } else {
          this.set('previousPhotoIconUrl', 'assets/images/report-card/scroll-left@3x.png');
        }
      },
      hoverNextPhoto: function hoverNextPhoto(hovering) {
        if (hovering) {
          this.set('nextPhotoIconUrl', 'assets/images/scroll-right-hover@3x.png');
        } else {
          this.set('nextPhotoIconUrl', 'assets/images/report-card/scroll-right@3x.png');
        }
      },
      goToPreviousPhoto: function goToPreviousPhoto() {
        var selectedId = this.get('selectedPhoto.id');
        var reportCardImages = this.get('reportCard.reportCardImagesWithoutCoverPhoto');
        var currentPhotoIndex = reportCardImages.findIndex(function (rci) {
          return rci.get('id') === selectedId;
        });
        var previousPhotoIndex = currentPhotoIndex - 1;

        if (previousPhotoIndex === -1) {
          this.set('selectedPhoto', this.get('reportCard.coverPhoto'));
        } else {
          if (typeof reportCardImages[previousPhotoIndex] !== 'undefined') {
            this.set('selectedPhoto', reportCardImages[previousPhotoIndex]);
          }
        }
      },
      goToNextPhoto: function goToNextPhoto() {
        var selectedId = this.get('selectedPhoto.id');
        var reportCardImages = this.get('reportCard.reportCardImagesWithoutCoverPhoto');
        var currentPhotoIndex = reportCardImages.findIndex(function (rci) {
          return rci.get('id') === selectedId;
        });

        if (currentPhotoIndex === -1) {
          // dealing with the cover photo
          this.set('selectedPhoto', reportCardImages.get('firstObject'));
        } else {
          var nextPhotoIndex = currentPhotoIndex + 1;

          if (typeof reportCardImages[nextPhotoIndex] !== 'undefined') {
            this.set('selectedPhoto', reportCardImages[nextPhotoIndex]);
          }
        }
      },
      clickMapThumbnail: function clickMapThumbnail() {
        this.set('selectedMapThumbnail', true);
      },
      clickCoverPhotoThumbnail: function clickCoverPhotoThumbnail() {
        this.set('selectedMapThumbnail', false);
        this.set('selectedPhoto', this.get('reportCard.coverPhoto'));
      }
    }
  });

  _exports.default = _default;
});