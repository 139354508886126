define("frontend/templates/components/invoices-widget/draft-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7F5dcLYl",
    "block": "{\"symbols\":[],\"statements\":[[0,\"Draft\\n\"],[4,\"if\",[[24,[\"currentUser\",\"user\",\"company\",\"canDownloadDrafts\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",false],[12,\"class\",[29,[\"download-button \",[28,\"if\",[[24,[\"sentDraftEmail\"]],\"disabled\"],null]]]],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"downloadDrafts\"],null]]],[8],[0,\"\\n    \"],[1,[28,\"inline-svg\",[\"assets/images/download.svg\"],null],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/invoices-widget/draft-header.hbs"
    }
  });

  _exports.default = _default;
});