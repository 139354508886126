define("frontend/routes/add-client/documents", ["exports", "frontend/routes/base-route", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = _baseRoute.default.extend({
    addClientSaving: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    currentUser: inject.service(),
    model: function model(params) {
      var household_id = params.household_id;
      return this.store.findRecord('household', household_id).then(function (household) {
        var documents = _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(household.get('id'), "/user_documents"),
          method: 'GET'
        });

        var signatureRequests = _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/client_contracts/household_signature_requests?household_id=").concat(household.get('id')),
          method: 'GET'
        });

        return Ember.RSVP.hash({
          household: household,
          documents: documents,
          signatureRequests: signatureRequests,
          owner: household.get('owner')
        });
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('anySidePanelActive', false);
      controller.set('uploading', false);
    },
    actions: {
      goBack: function goBack() {
        var household = this.get('controller.model.household');
        var householdId = household.get('id');

        if (this.get('currentUser.user.isAdmin')) {
          return this.transitionTo('add-client.billing-settings', householdId);
        } else {
          var petIndex = household.get('pets.length') - 1;
          return this.transitionTo('add-client.pet', householdId, {
            queryParams: {
              petIndex: petIndex
            }
          });
        }
      },
      finish: function finish(user) {
        var _this = this;

        this.set('saving', true);
        this.get('addClientSaving').saveUser(user).then(function (savedUser) {
          Ember.getOwner(_this).lookup('controller:application').send('notify', {
            message: "Your request to connect was sent to ".concat(savedUser.get('user').get('email')),
            type: 'success'
          });
          return _this.transitionTo('client-list.household.household-user', savedUser.get('household.id'), savedUser.get('id'));
        }).finally(function () {
          _this.set('saving', false);

          window.location.reload();
        });
      },
      reload: function reload() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});