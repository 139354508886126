define("frontend/templates/components/add-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KsUH5dos",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[25,1]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"add-button-text\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"link-to\",null,[[\"query\",\"route\"],[[28,\"hash\",null,[[\"backRoute\",\"backHouseholdId\",\"backId\"],[[24,[\"backRoute\"]],[24,[\"backHouseholdId\"]],[24,[\"backId\"]]]]],[24,[\"route\"]]]],{\"statements\":[],\"parameters\":[]},null],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/add-button.hbs"
    }
  });

  _exports.default = _default;
});