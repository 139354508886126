define("frontend/controllers/edit-client/secondary-contact", ["exports", "frontend/controllers/edit-client/edit-client-page-base", "frontend/config/environment", "jquery"], function (_exports, _editClientPageBase, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = _editClientPageBase.default.extend({
    session: inject.service(),
    accountSession: inject.service(),
    valid: computed.readOnly('model.validations.isValid'),
    application: Ember.inject.controller(),
    editingNotificationSettings: false,
    isPetParent: computed('session.data.authenticated.tokenData', function () {
      return this.get('accountSession').isPetParent();
    }),
    actions: {
      clickEditNotifications: function clickEditNotifications() {
        this.set('editingNotificationSettings', true);
      },
      closeEditNotifications: function closeEditNotifications() {
        this.set('editingNotificationSettings', false);
      },
      uploadedPhoto: function uploadedPhoto(photo) {
        this.set('model.user.avatar_url', photo);
      },
      redirectToRemoveHuman: function redirectToRemoveHuman(secondaryContact) {
        this.transitionToRoute('edit-client.remove-household-user', secondaryContact);
      },
      switchPrimaryContact: function switchPrimaryContact() {
        var _this = this;

        var household = this.get('model');

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(household.id, "/switch_primary_contact"),
          method: 'POST',
          data: {
            id: household.id
          }
        }).then(function () {
          _this.get('model').reload().then(function () {
            _this.transitionToRoute('edit-client.primary-contact', household);
          });
        });
      }
    }
  });

  _exports.default = _default;
});