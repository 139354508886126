define("frontend/templates/components/header-week-date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HeUiPwFp",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"date-container\"],[8],[0,\"\\n  \"],[7,\"div\",false],[12,\"class\",\"arrow previous-arrow\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"previous\"]],[8],[0,\"\\n    \"],[7,\"svg\",true],[10,\"viewBox\",\"0 0 8 11\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[7,\"path\",true],[10,\"d\",\"M7.177 9.778L2.964 5.442l4.203-4.2L5.904 0 .477 5.423 5.894 11z\"],[10,\"fill-rule\",\"evenodd\"],[8],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"span\",true],[10,\"class\",\"month\"],[8],[1,[28,\"format-date\",[[24,[\"momentStartDate\"]],\"MMM\"],null],false],[9],[7,\"span\",true],[10,\"class\",\"day\"],[8],[1,[28,\"format-date\",[[24,[\"momentStartDate\"]],\"D\"],null],false],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"seperator\"],[8],[0,\"-\"],[9],[0,\"\\n  \"],[7,\"span\",true],[10,\"class\",\"month\"],[8],[1,[28,\"format-date\",[[24,[\"momentEndDate\"]],\"MMM\"],null],false],[9],[7,\"span\",true],[10,\"class\",\"day\"],[8],[1,[28,\"format-date\",[[24,[\"momentEndDate\"]],\"D\"],null],false],[9],[0,\"\\n\\n  \"],[7,\"div\",false],[12,\"class\",\"arrow next-arrow\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"next\"]],[8],[0,\"\\n    \"],[7,\"svg\",true],[10,\"width\",\"8\"],[10,\"height\",\"11\"],[10,\"viewBox\",\"0 0 8 11\"],[10,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[8],[7,\"path\",true],[10,\"d\",\"M.823 1.222l4.213 4.336-4.203 4.2L2.096 11l5.427-5.423L2.106 0z\"],[10,\"fill-rule\",\"evenodd\"],[8],[9],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/header-week-date-picker.hbs"
    }
  });

  _exports.default = _default;
});