define("frontend/templates/client-list/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8vcuC1uP",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"mobile-header-flex\",null,[[\"showAdd\",\"clickAdd\"],[[24,[\"currentUser\",\"user\",\"canAddEditClients\"]],[28,\"route-action\",[\"transitionToAddClient\"],null]]],{\"statements\":[[4,\"if\",[[24,[\"currentUser\",\"user\",\"canViewFullClientInfo\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"search-input\",null,[[\"value\",\"debounce\",\"placeholder\"],[[24,[\"filter\"]],true,\"Search by human or pet\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"new-header\",null,[[\"flex\",\"classNames\",\"hasClose\",\"hasSideNav\"],[true,\"client-list-header\",false,true]],{\"statements\":[[0,\"    \"],[7,\"h1\",true],[10,\"class\",\"header-text\"],[8],[0,\"Clients\"],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"id\",\"header-wormhole\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"currentUser\",\"user\",\"canViewFullClientInfo\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"search-input\",null,[[\"value\",\"debounce\",\"placeholder\"],[[24,[\"filter\"]],true,\"Search by human or pet\"]]],false],[0,\"\\n\"],[4,\"if\",[[24,[\"currentUser\",\"user\",\"canAddEditClients\"]]],null,{\"statements\":[[0,\"          \"],[1,[28,\"add-button\",null,[[\"route\",\"backRoute\"],[\"add-client.client-search\",[24,[\"currentRouteName\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[4,\"if\",[[24,[\"noHouseholdsAndNoFilter\"]]],null,{\"statements\":[[0,\"  \"],[1,[22,\"no-clients-error\"],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"    \"],[1,[28,\"client-summary\",null,[[\"filter\"],[[24,[\"filter\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/client-list/index.hbs"
    }
  });

  _exports.default = _default;
});