define("frontend/models/offered-service-client-price", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    offered_service: _emberData.default.belongsTo('offered-service', {
      async: true
    }),
    price: _emberData.default.attr('number'),
    incremental_price: _emberData.default.attr('number'),
    effective_date: _emberData.default.attr(),
    household: _emberData.default.belongsTo('household')
  });

  _exports.default = _default;
});