define("frontend/templates/invoices-summary-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wQTEX+jK",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"invoices-summary-page\"],[8],[0,\"\\n  \"],[1,[22,\"loading-spinner\"],false],[0,\"\\n\\n\"],[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"mobile-header-flex\",null,[[\"type\",\"showBack\",\"showHamburger\",\"showAdd\"],[\"large-white\",false,false,false]],{\"statements\":[[0,\"      \"],[7,\"header\",true],[10,\"class\",\"header-text\"],[8],[0,\"Invoices\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"new-header\",null,[[\"flex\",\"hasClose\"],[true,false]],{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"id\",\"header-back-wormhole-container\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"id\",\"header-next-wormhole-container\"],[8],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"header-text\"],[8],[0,\"Invoices\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/invoices-summary-loading.hbs"
    }
  });

  _exports.default = _default;
});