define("frontend/components/assign-staff-date", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['assign-staff-date'],
    classNameBindings: ['hasAppointments', 'displayError:assign-staff-date--error'],
    format: 'ddd, MMM D',
    date: null,
    addingNewAssignment: false,
    appointments: [],
    hasAppointments: computed.notEmpty('appointments'),
    hasNoAppointments: computed.not('hasAppointments'),
    showError: false,
    displayError: computed.and('showError', 'hasNoAppointments'),
    actions: {
      editExistingAppointment: function editExistingAppointment(appointment) {
        var date = (0, _moment.default)(this.get('date')).format('YYYY-MM-DD');
        this.editExistingAppointment(appointment, date);
      },
      addNewAppointment: function addNewAppointment() {
        var date = (0, _moment.default)(this.get('date')).format('YYYY-MM-DD');
        this.addNewAppointment(date);
      }
    }
  });

  _exports.default = _default;
});