define("frontend/templates/components/edit-notifications-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "imfXg5Uw",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal-scrollable\",null,[[\"onClose\",\"customContainerClassNames\"],[[28,\"action\",[[23,0,[]],\"onClose\"],null],\"edit-notifications-modal\"]],{\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"bottom\"],[8],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"onclick\"],[[28,\"action\",[[23,0,[]],\"onClose\"],null]]],{\"statements\":[[0,\"        \"],[1,[28,\"inline-svg\",[\"assets/images/back-chevron.svg\"],null],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[1,[28,\"inline-svg\",[\"assets/images/modal-icons/email-notifications.svg\"],null],false],[9],[0,\"\\n      \"],[7,\"header\",true],[8],[0,\"email notifications\"],[9],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"notification-inputs\",null,[[\"companyUserProfile\",\"householdUser\"],[[24,[\"companyUserProfile\"]],[24,[\"householdUser\",\"user\"]]]]],false],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/edit-notifications-modal.hbs"
    }
  });

  _exports.default = _default;
});