define("frontend/components/multiple-household-message-thread", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer;

  var _default = Ember.Component.extend({
    classNames: ['messages-thread', 'has-side-panel'],
    currentUser: inject.service(),
    store: inject.service(),
    infinity: inject.service(),
    messages: [],
    noMessages: computed.equal('messages.length', 0),
    specificDatesSelected: false,
    typedMessage: '',
    households: [],
    company: null,
    datedMessages: computed('messages.[]', function () {
      var datedMessages = {};
      var messages = this.get('messages');
      messages.forEach(function (message) {
        var date = moment(message.get('created_at')).startOf('day').format('YYYY-MM-DD');

        if (!datedMessages[date]) {
          datedMessages[date] = [];
        }

        datedMessages[date].push(message);
      });
      var messageObjects = [];
      var sortedDates = Object.keys(datedMessages).sort(this.sortDates);
      sortedDates.forEach(function (date) {
        var sortedMessages = datedMessages[date].sortBy('created_at');
        messageObjects.push(Ember.Object.create({
          date: date,
          messages: sortedMessages
        }));
      });
      return messageObjects;
    }),
    householdsObserver: observer('households.[]', 'selectedDistributionList', function () {
      if (this.get('selectedDistributionList') == '' && this.get('households.count') > 1) {
        this.set('messages', []);
      }
    }),
    didInsertElement: function didInsertElement() {
      this.scrollToBottomOfThread();
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      if (this.get('firestoreListener')) {
        this.get('firestoreListener')();
      }
    },
    sortDates: function sortDates(a, b) {
      var momentA = moment(a);
      var momentB = moment(b);

      if (momentA.isBefore(momentB)) {
        return -1;
      }

      return 1;
    },
    scrollToBottomOfThread: function scrollToBottomOfThread() {
      Ember.run.scheduleOnce('afterRender', function () {
        var messageThread = document.getElementById('messages-area');

        if (messageThread) {
          messageThread.scrollTop = messageThread.scrollHeight;
        }
      });
    },
    actions: {
      sendMessage: function sendMessage() {
        var _this = this;

        var message = this.get('typedMessage');

        if (!message) {
          return;
        }

        this.set('typedMessage', '');
        this.set('sendingMessage', true);
        var household_ids = [];
        var sentToText = null;

        if (this.get('selectedDistributionList') === 'selectedAppointments') {
          sentToText = "Sent to ".concat(this.get('familiesWithAppointmentsTitle').replace('...', '').toLowerCase(), " ").concat(this.get('familiesWithAppointmentsText').replace('...', ''));
        } else if (this.get('selectedDistributionList') === undefined) {
          sentToText = 'Sent to select families';
        } else {
          sentToText = 'Sent to all families';
        }

        household_ids = this.get('households').map(function (_ref) {
          var id = _ref.id;
          return id;
        });
        var data = {
          message: message,
          household_ids: household_ids,
          sent_by_staff: true,
          company_id: this.get('company.id'),
          show_in_grouped_messages: this.get('selectedDistributionList') !== '',
          send_to_all_households: this.get('selectedDistributionList') === 'allHouseholds',
          sent_to: sentToText,
          email_send_required: this.get('specificDatesSelected')
        };

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/messages/plain_text"),
          method: 'POST',
          data: data
        }).then(function () {
          _this.set('sendingMessage', false);

          if (_this.get('selectedDistributionList') === '') {
            _this.get('households').forEach(function (household) {
              household.set('user_last_seen_message_thread', moment());
            });
          }

          _this.get('transitionBackToInbox')();
        });
      },
      sendImageMessage: function sendImageMessage(photo, finishedUploadingCallback) {
        var _this2 = this;

        this.set('uploadingPhoto', true);
        var fileType = photo.type;
        var splitFileType = fileType.split('/');
        var fileName = photo.name;

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/messages/create_upload_url"),
          method: 'POST',
          data: {
            file_type: fileType
          }
        }).then(function (response) {
          var formData = new FormData();

          for (var key in response.fields) {
            var value = response.fields[key];
            formData.set(key, value);
          }

          return photo.upload(response.url, {
            data: response.fields
          }).then(function () {
            return response.fields.key;
          });
        }).then(function (key) {
          var household_ids = [];
          var sentToText = null;

          if (_this2.get('selectedDistributionList') === '') {
            household_ids = _this2.get('households').map(function (household) {
              return household.get('id');
            });
          } else {
            if (_this2.get('selectedDistributionList') === 'selectedAppointments') {
              sentToText = "Sent to ".concat(_this2.get('familiesWithAppointmentsTitle').replace('...', '').toLowerCase(), " ").concat(_this2.get('familiesWithAppointmentsText').replace('...', ''));
            } else {
              sentToText = 'Sent to all families';
            }

            household_ids = _this2.get('households').map(function (_ref2) {
              var id = _ref2.id;
              return id;
            });
          }

          var data = {
            image_url: key,
            household_ids: household_ids,
            sent_by_staff: !_this2.get('isPetParent'),
            company_id: _this2.get('company.id'),
            show_in_grouped_messages: _this2.get('selectedDistributionList') !== '',
            file_name: fileName,
            file_type: splitFileType[0] == 'application' ? splitFileType[1] : splitFileType[0],
            send_to_all_households: _this2.get('selectedDistributionList') === 'allHouseholds',
            sent_to: sentToText,
            email_send_required: _this2.get('specificDatesSelected')
          };
          return _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/messages/image"),
            method: 'POST',
            data: data
          }).then(function () {
            Ember.run.schedule('afterRender', function () {
              if (finishedUploadingCallback) {
                finishedUploadingCallback();
              }
            });

            _this2.set('uploadingPhoto', false);

            if (_this2.get('selectedDistributionList') === '') {
              _this2.get('households').forEach(function (household) {
                household.set('user_last_seen_message_thread', moment());
              });

              _this2.get('transitionBackToInbox')();
            } else {
              window.location.reload();
            }
          });
        });
      }
    }
  });

  _exports.default = _default;
});