define("frontend/controllers/add-bank-account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['manualVerification'],
    manualVerification: false,
    actions: {
      redirectToHooray: function redirectToHooray() {
        this.transitionToRoute('verified-bank-account', this.get('model.emd5'));
      }
    }
  });

  _exports.default = _default;
});