define("frontend/components/payment-form/stripe-card", ["exports", "frontend/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    elementId: 'card-info',
    classNames: ['pay-container', 'center-block'],
    showSavedCard: computed.and('savedCardInfo', 'notChangingCard'),
    notChangingCard: computed.not('changingCard'),
    changingCard: false,
    savedCardInfo: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      // eslint-disable-next-line new-cap
      var stripe = Stripe(_environment.default.stripe_api_key);
      var elements = stripe.elements();
      var style = {
        base: {
          fontSize: '16px',
          color: '#32325d'
        }
      };
      var card = elements.create('card', {
        hidePostalCode: true,
        style: style
      });
      this.set('card', card);
      card.mount('#card-container');
      card.addEventListener('change', function (response) {
        var error = response.error;

        if (error) {
          _this.set('error', error.message);
        } else {
          _this.set('error', null);
        }
      });
      this.get('setCard')(card, stripe);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);

      this.get('card').removeEventListener('change');
    }
  });

  _exports.default = _default;
});