define("frontend/components/delete-appointment-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    month: computed('appointment.service.starts_at', function () {
      return moment(this.get('appointment.service.starts_at')).startOf('day').format('MMM');
    }),
    day: computed('appointment.service.starts_at', function () {
      return moment(this.get('appointment.service.starts_at')).startOf('day').format('D');
    }),
    dayOfWeek: computed('appointment.service.starts_at', function () {
      return moment(this.get('appointment.service.starts_at')).startOf('day').format('ddd');
    }),
    startEndTime: computed('appointment.service.starts_at', 'appointment.offered_service.duration_in_minutes', function () {
      var start = moment.parseZone(this.get('appointment.service.starts_at'));
      var duration = this.get('appointment.offered_service.duration_in_minutes');
      var end = moment.parseZone(this.get('appointment.service.starts_at')).add(duration, 'minutes');
      return "".concat(start.format('h:mmA'), " - ").concat(end.format('h:mmA'));
    }),
    actions: {
      confirm: function confirm() {
        this.get('confirm')();
      },
      onClose: function onClose() {
        this.get('onClose')();
      }
    }
  });

  _exports.default = _default;
});