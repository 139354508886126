define("frontend/controllers/inbox/household", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    currentUser: inject.service(),
    inbox: inject.controller(),
    selectedHousehold: computed.alias('inbox.selectedHousehold'),
    selectedHouseholds: computed.alias('inbox.selectedHouseholds'),
    showingNewMessagePage: computed.alias('inbox.showingNewMessagePage'),
    household: computed.alias('model.household'),
    firstNames: computed('household.owner', 'household.secondaryContact', function () {
      if (this.get('household.secondaryContact')) {
        return "".concat(this.get('household.owner.getFirstName'), " & ").concat(this.get('household.secondaryContact.getFirstName'));
      } else {
        return this.get('household.owner.getFirstName');
      }
    }),
    actions: {
      callbackAfterSentMessage: function callbackAfterSentMessage() {
        var _this = this;

        var householdId = this.get('selectedHouseholds.firstObject.id');
        this.transitionToRoute('inbox.household', householdId).then(function () {
          _this.set('showingNewMessagePage', false);

          _this.set('selectedHouseholds', []);

          _this.send('parentRefresh');
        });
      },
      transitionBackToInbox: function transitionBackToInbox() {
        this.send('parentRefresh');
        this.send('transitionToInbox');
      }
    }
  });

  _exports.default = _default;
});