define("frontend/templates/components/flash-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "7voswdg0",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"flash-notification flash-notification--green\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"flash-notification__message\"],[8],[0,\"\\n    \"],[1,[28,\"inline-svg\",[\"assets/images/connect.svg\"],null],false],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"flash-notification__message__text\"],[8],[1,[22,\"message\"],false],[0,\"!\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/flash-message.hbs"
    }
  });

  _exports.default = _default;
});