define("frontend/components/view-grouped-report-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    loading: false,
    media: inject.service(),
    groupedReportCard: null,
    firstAppointment: computed('groupedReportCard.appointments', function () {
      return this.get('groupedReportCard.appointments.firstObject');
    }),
    modalComponent: computed('showMobilePhotoCarousel', function () {
      return this.get('showMobilePhotoCarousel') ? 'ppb-modal' : 'ppb-modal-scrollable';
    }),
    customContainerClassNames: computed('showMobilePhotoCarousel', 'groupedReportCard.coverPhoto', 'firstAppointment.gps_markers_report_card_view_desktop_url', function () {
      if (this.get('showMobilePhotoCarousel')) {
        return 'view-report-card view-report-card--photos';
      } else if (this.get('groupedReportCard.coverPhoto') || this.get('firstAppointment.gps_markers_report_card_view_desktop_url')) {
        return 'view-report-card has-cover-photo';
      } else {
        return 'view-report-card';
      }
    }),
    isNotMobile: computed.not('media.isMobile'),
    showMobilePhotoCarousel: false,
    showMobileCoverPhoto: computed('media.isMobile', 'groupedReportCard.coverPhoto', 'hasGpsMap', function () {
      return this.get('media.isMobile') && (this.get('groupedReportCard.coverPhoto') || this.get('hasGpsMap'));
    }),
    showDesktopCoverPhoto: computed('isNotMobile', 'groupedReportCard.coverPhoto', 'hasGpsMap', function () {
      return this.get('isNotMobile') && (this.get('groupedReportCard.coverPhoto') || this.get('hasGpsMap'));
    }),
    hasCoverPhoto: computed.notEmpty('groupedReportCard.coverPhoto'),
    hasGpsMap: computed('firstAppointment.started_at_latitude', 'firstAppointment.finished_at_latitude', function () {
      return this.get('firstAppointment.started_at_latitude') !== null || this.get('firstAppointment.finished_at_latitude') !== null;
    }),
    actions: {
      showMobilePhotoCarousel: function showMobilePhotoCarousel() {
        this.set('showMobilePhotoCarousel', true);
      },
      hideMobilePhotoCarousel: function hideMobilePhotoCarousel() {
        this.set('showMobilePhotoCarousel', false);
      }
    }
  });

  _exports.default = _default;
});