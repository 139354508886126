define("frontend/components/invoices-widget/sent-header", ["exports", "frontend/utils/invoice-type"], function (_exports, _invoiceType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['invoices-widget-header', 'sent-header'],
    tagName: 'header',
    headerText: computed('invoiceType', function () {
      if (this.get('invoiceType') === _invoiceType.default.SENT) {
        return 'All sent';
      } else {
        return this.get('invoiceType');
      }
    }),
    actions: {
      selectOption: function selectOption(option) {
        this.set('showDropdown', false);
        this.get('selectOption')(option);
      }
    }
  });

  _exports.default = _default;
});