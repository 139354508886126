define("frontend/components/custom-infinity-loader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['infinity-loader'],
    classNameBindings: ['viewportEntered:in-viewport'],
    infinity: inject.service(),
    actions: {
      didEnterViewport: function didEnterViewport() {
        if (this.get('infinityLoad')) {
          this.get('infinityLoad')();
        } else {
          this.get('infinity').infinityLoad(this.get('infinityModel'));
        }
      }
    }
  });

  _exports.default = _default;
});