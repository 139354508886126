define("frontend/templates/client-list/household/billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HCi/+Pup",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"mobile-header-flex\",null,[[\"type\",\"showHamburger\",\"showDone\",\"showAdd\",\"clickDone\"],[\"large-white\",false,true,false,[28,\"route-action\",[\"transitionToHouseholdUser\",[24,[\"model\",\"household\"]]],null]]],{\"statements\":[[0,\"    \"],[7,\"header\",true],[10,\"class\",\"header-text\"],[8],[1,[24,[\"model\",\"household\",\"owner\",\"last_name\"]],false],[0,\" family\"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[24,[\"showSendContractsModal\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"manually-send-contracts-modal\",null,[[\"onClose\",\"sendContracts\"],[[28,\"action\",[[23,0,[]],\"closeSendContractsModal\"],null],[28,\"action\",[[23,0,[]],\"sendContracts\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"billing-settings-page\"],[8],[0,\"\\n\"],[4,\"if\",[[24,[\"saving\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"loading-spinner\",null,[[\"message\"],[\"Saving...\"]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    \"],[1,[28,\"billing-inputs\",null,[[\"householdUser\",\"selectedAutoPay\",\"showingAutoPayModal\",\"anySidePanelActive\",\"plaidRedirect\",\"allInvoiceFrequencies\",\"saveHouseholdUser\",\"setSaving\",\"redirectToMicrodeposits\",\"savedOfferedServicePrice\"],[[24,[\"model\",\"household\",\"owner\"]],[24,[\"selectedAutoPay\"]],false,[24,[\"anySidePanelActive\"]],[24,[\"plaidRedirect\"]],[24,[\"model\",\"invoiceFrequencies\"]],[28,\"action\",[[23,0,[]],\"saveHouseholdUser\"],null],[28,\"action\",[[23,0,[]],\"setSaving\"],null],[28,\"action\",[[23,0,[]],\"redirectToMicrodeposits\"],null],[28,\"route-action\",[\"reloadHousehold\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/client-list/household/billing.hbs"
    }
  });

  _exports.default = _default;
});