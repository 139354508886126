define("frontend/templates/components/company-information", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "s6mFiKLe",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"company-name\"],[8],[1,[24,[\"company\",\"displayName\"]],false],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"company-name-label\"],[8],[0,\"Company\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/company-information.hbs"
    }
  });

  _exports.default = _default;
});