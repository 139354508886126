define("frontend/templates/components/payment-form/pay-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jyi0npCC",
    "block": "{\"symbols\":[],\"statements\":[[7,\"button\",true],[10,\"class\",\"btn btn-full-width btn-pay center-block\"],[10,\"id\",\"submitButton\"],[11,\"disabled\",[22,\"payButtonDisabled\"]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[10,\"type\",\"submit\"],[8],[0,\"Pay \"],[1,[22,\"formattedPayAmount\"],false],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/payment-form/pay-button.hbs"
    }
  });

  _exports.default = _default;
});