define("frontend/components/notification-inputs", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend({
    session: inject.service(),
    accountSession: inject.service(),
    flashMessage: inject.service(),
    currentUser: inject.service(),
    householdUser: null,
    companyName: null,
    userProfile: computed.alias("companyUserProfile"),
    scheduleAlertsSwitch: computed.alias("userProfile.email_on_schedule_change"),
    appointmentRemindersSwitch: computed.alias("userProfile.email_for_appointment_reminder"),
    checkInAlertsSwitch: computed.alias("userProfile.email_on_appointment_started"),
    checkOutAlertsSwitch: computed.alias("userProfile.email_on_appointment_stopped"),
    reportCardAlertsSwitch: computed.alias("userProfile.email_on_report_card_send"),
    messagingAlertsSwitch: computed.alias("userProfile.email_on_direct_message_send"),
    groupAnnouncementSwitch: computed.alias("userProfile.email_on_group_announcement_send"),
    isPetParent: computed('session.data.authenticated.tokenData', function () {
      return this.get('accountSession').isPetParent();
    }),
    userViewingSelf: computed('householdUser.id', 'currentUser.user.id', function () {
      var householdUserId = this.get('householdUser.id');
      var currentUserId = this.get('currentUser.user.id');
      return householdUserId == currentUserId;
    }),
    serviceCategoriesWithCheckInCheckOut: computed("userProfile.service_categories_with_check_in_check_out", function () {
      function toSentence(array) {
        if (array.length === 0) return '';
        if (array.length === 1) return array[0];
        if (array.length === 2) return array.join(' and ');
        var allButLast = array.slice(0, -1).join(', ');
        var last = array[array.length - 1];
        return "".concat(allButLast, ", and ").concat(last);
      }

      var serviceCategoryNames = this.get('userProfile.service_categories_with_check_in_check_out');
      return toSentence(serviceCategoryNames);
    }),
    showCheckInCheckOut: computed("userProfile.service_categories_with_check_in_check_out", function () {
      var _this$get;

      var length = (_this$get = this.get('userProfile.service_categories_with_check_in_check_out')) === null || _this$get === void 0 ? void 0 : _this$get.length;
      if (length && length > 0) return true;
      return false;
    }),
    updateNotificationSetting: function updateNotificationSetting(toggleName, value) {
      var _this = this;

      _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/notifications/update"),
        method: 'POST',
        data: {
          user_id: this.get("householdUser.id"),
          company_id: this.get("companyUserProfile.company_id"),
          toggle_name: toggleName,
          toggle_value: value
        }
      }).fail(function () {
        var switchName = 'appointmentRemindersSwitch';
        var errorText = 'appointment reminders';

        switch (toggleName) {
          case 'email_for_appointment_reminder':
            switchName = 'appointmentRemindersSwitch';
            errorText = 'appointment reminders';
            break;

          case 'email_on_appointment_started':
            switchName = 'checkInAlertsSwitch';
            errorText = 'check-in alert';
            break;

          case 'email_on_appointment_stopped':
            switchName = 'checkOutAlertsSwitch';
            errorText = 'check-out alert';
            break;

          case 'email_on_report_card_send':
            switchName = 'reportCardAlertsSwitch';
            errorText = 'report cards';
            break;

          case 'email_on_direct_message_send':
            switchName = 'messagingAlertsSwitch';
            errorText = 'messaging';
            break;

          case 'email_on_group_announcement_send':
            switchName = 'groupAnnouncementSwitch';
            errorText = 'group announcements';
            break;

          default:
            break;
        }

        _this.get('flashMessage').error("Unexpected error updating ".concat(errorText, " notification"));

        _this.set(switchName, !value); // Revert the toggle on failure

      });
    },
    actions: {
      onClose: function onClose() {
        this.get('onClose')();
      },
      handleAppointmentRemindersSwitch: function handleAppointmentRemindersSwitch(value) {
        this.set('appointmentRemindersSwitch', value);
        this.updateNotificationSetting('email_for_appointment_reminder', value);
      },
      handleCheckInAlertsSwitch: function handleCheckInAlertsSwitch(value) {
        this.set('checkInAlertsSwitch', value);
        this.updateNotificationSetting('email_on_appointment_started', value);
      },
      handleCheckOutAlertsSwitch: function handleCheckOutAlertsSwitch(value) {
        this.set('checkOutAlertsSwitch', value);
        this.updateNotificationSetting('email_on_appointment_stopped', value);
      },
      handleReportCardAlertsSwitch: function handleReportCardAlertsSwitch(value) {
        this.set('reportCardAlertsSwitch', value);
        this.updateNotificationSetting('email_on_report_card_send', value);
      },
      handleMessagingAlertsSwitch: function handleMessagingAlertsSwitch(value) {
        this.set('messagingAlertsSwitch', value);
        this.updateNotificationSetting('email_on_direct_message_send', value);
      },
      handleGroupAnnouncementSwitch: function handleGroupAnnouncementSwitch(value) {
        this.set('groupAnnouncementSwitch', value);
        this.updateNotificationSetting('email_on_group_announcement_send', value);
      }
    }
  });

  _exports.default = _default;
});