define("frontend/templates/components/schedule-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aCa5tOvk",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"inline-svg\",[\"assets/images/fireworks.svg\"],null],false],[0,\"\\n\"],[4,\"if\",[[24,[\"onCancellations\"]]],null,{\"statements\":[[4,\"if\",[[28,\"eq\",[[24,[\"numAppointments\"]],0],null]],null,{\"statements\":[[0,\"    Hooray! You have no cancellations \"],[1,[28,\"fancy-date\",[[24,[\"date\"]],\"MMM D\"],null],false],[0,\"!\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Hooray! You have no more cancellations!\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[0,\"  Hooray! That’s the end of your day!\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/schedule-footer.hbs"
    }
  });

  _exports.default = _default;
});