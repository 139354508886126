define("frontend/templates/components/message-thread/company-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Qif7kgRk",
    "block": "{\"symbols\":[\"company\",\"company\"],\"statements\":[[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[4,\"each\",[[24,[\"companies\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"message-thread/company-option\",null,[[\"company\",\"companySelected\",\"selected\"],[[23,2,[]],[28,\"action\",[[23,0,[]],\"companySelected\"],null],[28,\"eq\",[[23,2,[\"id\"]],[24,[\"selectedCompany\",\"id\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[4,\"list-right-side-panel\",null,null,{\"statements\":[[4,\"each\",[[24,[\"companies\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"message-thread/company-option\",null,[[\"company\",\"companySelected\",\"selected\"],[[23,1,[]],[28,\"action\",[[23,0,[]],\"companySelected\"],null],[28,\"eq\",[[23,1,[\"id\"]],[24,[\"selectedCompany\",\"id\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/message-thread/company-select.hbs"
    }
  });

  _exports.default = _default;
});