define("frontend/components/document-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['document-list'],
    tagName: 'table',
    combinedDocuments: computed('documents.[]', 'signatureRequests.[]', function () {
      var documents = this.get('documents');
      var signatureRequests = this.get('signatureRequests');
      var pendingSignatureRequests = signatureRequests.filterBy('status', 'pending');
      var completedSignatureRequests = signatureRequests.reject(function (request) {
        return request.status == 'pending';
      });
      var combined = documents.concat(completedSignatureRequests);
      var sorted = combined.sortBy('last_activity_date').reverse();
      return pendingSignatureRequests.concat(sorted);
    }),
    actions: {
      remove: function remove() {
        this.remove.apply(this, arguments);
      }
    }
  });

  _exports.default = _default;
});