define("frontend/components/household-user", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer;

  var _default = Ember.Component.extend({
    classNames: [''],
    currentUser: inject.service(),
    session: inject.service(),
    accountSession: inject.service(),
    store: inject.service(),
    page: 1,
    perPage: 5,
    householdUser: null,
    alreadySentRequest: false,
    household: computed.alias('householdUser.household'),
    showPetNames: computed.gt('householdUser.household.pets.length', 1),
    phoneModalOpen: false,
    messagesLoading: false,
    country: computed('householdUser.household', 'currentUser.user.company', 'isPetParent', 'currentUser.user.client_accounts.[]', function () {
      if (this.get('isPetParent')) {
        return this.get('currentUser.user.client_accounts.firstObject.country');
      } else {
        return this.get('currentUser.user.company.country');
      }
    }),
    shouldSeeHouseholdContractAlert: computed('household.id', 'currentUser.user.households_contract_alerts_dismissed.[]', function () {
      return this.get('currentUser.user').shouldSeeHouseholdContractAlert(parseInt(this.get('household.id')));
    }),
    emailList: computed('householdUser.emails', function () {
      return this.get('householdUser.emails').sortBy('email_type').reverse();
    }),
    isPetParent: computed('session.data.authenticated.tokenData', function () {
      return this.get('accountSession').isPetParent();
    }),
    showEmailAndNumbers: computed('isPetParent', 'currentUser.user.company.id', function () {
      return this.get('isPetParent') || this.get('currentUser.user.canViewClientContactInfo') || this.get('currentUser.user.isAdmin');
    }),
    householdObserver: observer('household', function () {
      this.set('clickedContractButton', false);
    }),
    addressActive: computed.readOnly('householdUser.presentAddress'),
    emailActive: computed.and('showEmailAndNumbers', 'householdUser.presentEmail'),
    phoneActive: computed.and('showEmailAndNumbers', 'householdUser.presentPhone'),
    actions: {
      clickAddress: function clickAddress() {
        this.set('addressModalOpen', true);
      },
      callPhone: function callPhone(phone) {
        window.location.href = "tel:".concat(phone.get('number'));
      },
      textPhone: function textPhone(phone) {
        window.location.href = "sms:".concat(phone.get('number'));
      },
      clickEmail: function clickEmail(email) {
        window.location.href = "mailto:".concat(email);
      },
      closeAddressModal: function closeAddressModal() {
        this.set('addressModalOpen', false);
      },
      openPhoneModal: function openPhoneModal(phoneNumber) {
        this.set('selectedPhoneNumber', [phoneNumber]);
        this.set('phoneModalOpen', true);
      },
      closePhoneModal: function closePhoneModal() {
        this.set('selectedPhoneNumber', null);
        this.set('phoneModalOpen', false);
      },
      resendRequest: function resendRequest() {
        var _this = this;

        var email = this.get('householdUser').get('email');
        var householdId = this.get('householdUser').get('household.id');

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(householdId, "/add_company"),
          method: 'POST',
          data: {
            id: householdId,
            email: email
          }
        }).done(function () {
          _this.set('alreadySentRequest', true);

          _this.set('householdUser.user.household.client_status', 'pending');

          Ember.getOwner(_this).lookup('controller:application').send('notify', {
            message: "Your request to connect was sent to ".concat(email),
            type: 'success'
          });
        });
      },
      sendContracts: function sendContracts() {
        var _this2 = this;

        var householdId = this.get('householdUser.household.id');
        this.set('sendingContracts', true);

        _jquery.default.ajax({
          url: "/api/v2/client_contracts/send_client_contracts",
          method: 'POST',
          data: {
            household_id: householdId,
            send_email: true
          }
        }).then(function () {
          _this2.set('justSentContracts', true);

          _this2.set('householdUser.household.esignature_status', 'sent');

          _this2.set('clickedContractButton', true);

          _this2.set('sendingContracts', false);
        });
      },
      sendContractReminder: function sendContractReminder() {
        var _this3 = this;

        var householdId = this.get('householdUser.household.id');
        this.set('sendingContracts', true);

        _jquery.default.ajax({
          url: "/api/v2/client_contracts/send_client_contract_reminder",
          method: 'POST',
          data: {
            household_id: householdId
          }
        }).then(function () {
          _this3.set('justSentContracts', false);

          _this3.set('clickedContractButton', true);

          _this3.set('sendingContracts', false);
        });
      },
      dismissAgreements: function dismissAgreements() {
        var householdId = parseInt(this.get('householdUser.household.id'));
        this.get('currentUser.user').addHouseholdIdToDismissedContracts(householdId);

        _jquery.default.ajax({
          url: '/api/v2/user_dismissed_contracts_alert',
          method: 'POST',
          data: {
            household_id: householdId
          }
        });
      }
    }
  });

  _exports.default = _default;
});