define("frontend/components/message-thread/new-message-household-select", ["exports", "frontend/config/environment", "frontend/utils/string-util", "jquery"], function (_exports, _environment, _stringUtil, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['message-thread--new-message-household-select'],
    households: [],
    selectedHouseholds: [],
    isSelectingHouseholds: false,
    familiesWithAppointmentsText: '',
    familiesWithAppointmentsTitle: '',
    selectedRecipients: null,
    selectedServiceCategories: [],
    selectedDates: [],
    specificDatesSelected: false,
    step: 1,
    currentUser: inject.service(),
    isLoadingAppts: false,
    actions: {
      closeSelectingHouseholdsModal: function closeSelectingHouseholdsModal() {
        this.set('isSelectingHouseholds', false);

        if (!Ember.isBlank(this.get('selectedServiceCategories'))) {
          this.set('selectedDistributionList', 'selectedAppointments');
        } else {
          this.set('selectedDistributionList', '');
        }
      },
      openSelectingHouseholdsModal: function openSelectingHouseholdsModal() {
        if (!Ember.isBlank(this.get('selectedServiceCategories')) && !Ember.isBlank(this.get('selectedRecipients'))) {
          this.send('selectAppointmentsOnDates');
        } else {
          this.set('isSelectingHouseholds', true);
        }
      },
      changeSelectingHouseholdsModal: function changeSelectingHouseholdsModal() {
        this.set('selectedDistributionList', 'selectedAppointments');

        if (this.get('selectedRecipients') === 'specific-dates') {
          this.set('step', 2);
        } else {
          this.set('step', 1);
        }

        this.set('isSelectingHouseholds', true);
      },
      infinityLoad: function infinityLoad() {
        this.infinityLoad.apply(this, arguments);
      },
      clickedHousehold: function clickedHousehold(household) {
        if (this.get('selectedDistributionList') === '') {
          this.clickedHousehold(household);
        }
      },
      selectAllHouseholds: function selectAllHouseholds() {
        this.set('selectedHouseholds', this.get('households'));
        this.set('selectedDistributionList', 'allHouseholds');
      },
      deselectAllHouseholds: function deselectAllHouseholds() {
        this.set('selectedHouseholds', []);
        this.set('selectedDistributionList', '');
      },
      selectAppointmentsOnDates: function selectAppointmentsOnDates() {
        var _this = this;

        // Fixes an issue with the change button also triggering
        // the regular button toggle
        this.set('selectedDistributionList', 'selectedAppointments');
        this.set('isLoadingAppts', true);
        this.set('isSelectingHouseholds', false);
        var serviceCategoryIds = this.get('selectedServiceCategories').map(function (_ref) {
          var id = _ref.id;
          return id;
        });
        var dateSelection = this.get('selectedRecipients');
        var serviceCategoryNames = this.get('selectedServiceCategories').map(function (serviceCategory) {
          return serviceCategory.get('name');
        });
        var serviceCategoryShortenedNames = serviceCategoryNames.map(function (name) {
          switch (name) {
            case 'Dog Walking':
              return 'walks';

            case 'Dog Hiking':
              return 'hikes';

            case 'Pet Sitting':
              return 'drop-ins';

            case 'Overnights':
              return 'overnights';

            case 'Spa & Grooming':
              return 'grooming appointments';

            case 'Miscellaneous':
              return 'other services';

            default:
              return name.toLowerCase();
          }
        });

        if (dateSelection === 'specific-dates') {
          var dates = this.get('selectedDates');

          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/messages/household_ids_by_dates"),
            method: 'GET',
            data: {
              company_id: this.get('currentUser.user.company.id'),
              dates: dates,
              service_category_ids: serviceCategoryIds
            }
          }).then(function (ids) {
            if (dates.length > 1) {
              var formattedDates = dates.map(function (date) {
                return moment.parseZone(new Date(date).toISOString()).format('M/D');
              });

              _this.set('familiesWithAppointmentsText', _stringUtil.default.joinWithAnd(formattedDates, 'or'));
            } else {
              var _formattedDates = dates.map(function (date) {
                return moment.parseZone(new Date(date).toISOString()).format('dddd, M/D');
              });

              _this.set('familiesWithAppointmentsText', _formattedDates[0]);
            }

            if (serviceCategoryShortenedNames.length === _this.get('serviceCategories.length')) {
              _this.set('familiesWithAppointmentsTitle', 'Families with appointments on...');
            } else {
              _this.set('familiesWithAppointmentsTitle', "Families with ".concat(_stringUtil.default.joinWithAnd(serviceCategoryShortenedNames), " on..."));
            }

            _this.set('specificDatesSelected', true);

            _this.set('selectedDistributionList', 'selectedAppointments');

            _this.set('selectedHouseholds', ids.map(function (id) {
              return {
                id: id
              };
            }));

            _this.set('isLoadingAppts', false);
          });
        } else {
          _jquery.default.ajax({
            url: "".concat(_environment.default.APP.host, "/api/v2/messages/household_ids_by_dates"),
            method: 'GET',
            data: {
              company_id: this.get('currentUser.user.company.id'),
              numberOfMonths: dateSelection,
              service_category_ids: serviceCategoryIds
            }
          }).then(function (ids) {
            _this.set('selectedDistributionList', 'selectedAppointments');

            _this.set('selectedHouseholds', ids.map(function (id) {
              return {
                id: id
              };
            }));

            _this.set('familiesWithAppointmentsText', "in the last ".concat(dateSelection, " months"));

            if (serviceCategoryShortenedNames.length === _this.get('serviceCategories.length')) {
              _this.set('familiesWithAppointmentsTitle', 'Families with appointments on...');
            } else {
              _this.set('familiesWithAppointmentsTitle', "Families with ".concat(_stringUtil.default.joinWithAnd(serviceCategoryShortenedNames), " on..."));
            }

            _this.set('specificDatesSelected', false);

            _this.set('isLoadingAppts', false);

            _this.set('isSelectingHouseholds', false);
          });
        }
      }
    }
  });

  _exports.default = _default;
});