define("frontend/templates/components/staff-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PrZBR1wn",
    "block": "{\"symbols\":[],\"statements\":[[1,[28,\"user-avatar\",null,[[\"user\"],[[24,[\"staff\"]]]]],false],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"staff-name\"],[8],[0,\"\\n  \"],[1,[24,[\"staff\",\"getFirstName\"]],false],[0,\" \"],[1,[24,[\"staff\",\"last_name\"]],false],[4,\"if\",[[24,[\"staff\",\"deactivated\"]]],null,{\"statements\":[[0,\" \"],[7,\"span\",true],[10,\"class\",\"deactivated-info-text\"],[8],[0,\"(deactivated)\"],[9]],\"parameters\":[]},null],[7,\"br\",true],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"staff\",\"staff_status_pending\"]]],null,{\"statements\":[[0,\"    \"],[7,\"span\",true],[10,\"class\",\"staff-pending\"],[8],[0,\"(pending)\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/staff-list-item.hbs"
    }
  });

  _exports.default = _default;
});