define("frontend/components/invoices-widget/invoices-group-sent-header", ["exports", "frontend/components/invoices-widget/invoices-group-header"], function (_exports, _invoicesGroupHeader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _invoicesGroupHeader.default.extend({
    classNames: ['sent'],
    classNameBindings: ['invoicesGroup']
  });

  _exports.default = _default;
});