define("frontend/components/bank-account-modal", ["exports", "frontend/config/environment", "ember-cp-validations", "jquery"], function (_exports, _environment, _emberCpValidations, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'routingNumber': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('length', {
      min: 8,
      max: 9
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true
    })],
    'accountNumber': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('length', {
      min: 4
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    routingNumber: '',
    accountNumber: '',
    confirmAccountNumber: '',
    flashMessage: Ember.inject.service(),
    showClose: true,
    checkErrors: function checkErrors(accountNumber, confirmAccountNumber) {
      this.set('routingError', false);
      this.set('accountError', false);
      this.set('confirmError', false);
      var errors = false;

      if (!this.get('validations.attrs.routingNumber.isValid')) {
        this.set('routingError', true);
        errors = true;
      }

      if (!this.get('validations.attrs.accountNumber.isValid')) {
        this.set('accountError', true);
        errors = true;
      }

      if (accountNumber != confirmAccountNumber) {
        this.set('confirmError', true);
        errors = true;
      }

      return errors;
    },
    actions: {
      close: function close() {
        if (this.close) {
          this.close();
        }
      },
      save: function save() {
        var _this = this;

        var routingNumber = this.get('routingNumber');
        var accountNumber = this.get('accountNumber');
        var confirmAccountNumber = this.get('confirmAccountNumber');

        if (this.checkErrors(accountNumber, confirmAccountNumber)) {
          return;
        } // eslint-disable-next-line new-cap


        var stripe = Stripe(_environment.default.stripe_api_key);
        stripe.createToken('bank_account', {
          country: 'US',
          currency: 'usd',
          routing_number: routingNumber,
          account_number: accountNumber,
          account_holder_name: this.get('accountHolderName'),
          account_holder_type: 'individual'
        }).then(function (result) {
          if (result.error) {
            _this.get('flashMessage').sendFlashMessage(result.error.message, 'user-error');
          } else {
            _this.set('submitDisabled', true);

            _jquery.default.ajax({
              url: "".concat(_environment.default.APP.host, "/api/v2/users/").concat(_this.get('user_id'), "/create_stripe_payment_method"),
              method: 'POST',
              data: {
                stripe_token: result.token,
                payment_method: 'manual_bank_account',
                id: _this.get('user_id'),
                set_auto_pay: false
              }
            }).done(function (response) {
              Ember.getOwner(_this).lookup('route:application').send('redirectToMicrodeposits', response.emd5, !_this.get('showClose'));
            }).fail(function (data) {
              if (data && data.responseText) {
                var parsed = JSON.parse(data.responseText);

                if (parsed.message) {
                  _this.get('flashMessage').sendFlashMessage(parsed.message, 'user-error');

                  _this.set('submitDisabled', false);
                } else {
                  _this.get('flashMessage').sendFlashMessage('Unexpected error adding bank account', 'error');
                }
              } else {
                _this.get('flashMessage').sendFlashMessage('Unexpected error adding bank account', 'error');
              }
            });
          }
        });
      }
    }
  });

  _exports.default = _default;
});