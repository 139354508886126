define("frontend/controllers/remove-recurring-appointment-group", ["exports", "moment", "jquery"], function (_exports, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Controller.extend({
    recurringAppointments: computed.readOnly('model.recurring_appointments'),
    saving: false,
    pet: computed.readOnly('recurringAppointments.firstObject.pet'),
    queryParams: ['backRoute', 'backId', 'backHouseholdId'],
    backHouseholdId: null,
    backRoute: null,
    backId: null,
    recurringAppointmentPets: computed.map('recurringAppointments', function (recurringAppointment) {
      return recurringAppointment.get('pet');
    }),
    uniquePets: computed('recurringAppointmentPets.[]', function () {
      var pets = {};
      this.get('recurringAppointmentPets').forEach(function (pet) {
        pets[pet.get('id')] = pet;
      });

      var values = _jquery.default.map(pets, function (val) {
        return val;
      });

      return values;
    }),
    uniquePetNames: computed('uniquePets.[]', function () {
      return this.get('uniquePets').mapBy('name').uniq();
    }),
    uniqueDays: computed('recurringAppointments.[]', function () {
      var days = this.get('recurringAppointments').map(function (recurringAppointment) {
        return (0, _moment.default)().day(recurringAppointment.get('day_of_week')).format('dddd');
      }).uniq();
      days.sort(function (a, b) {
        return (0, _moment.default)(a, 'dddd').isoWeekday() > (0, _moment.default)(b, 'dddd').isoWeekday();
      });
      return days;
    })
  });

  _exports.default = _default;
});