define("frontend/models/user", ["exports", "ember-data", "ember-cp-validations", "frontend/utils/user-role"], function (_exports, _emberData, _emberCpValidations, _userRole) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var computed = Ember.computed,
      service = Ember.inject.service;
  var AVATAR_PLACEHOLDER = 'assets/images/staff/placeholder-human-image@3x.png'; // Hardcoded ID exceptions are kept up within the PPB Feature Exceptions document.
  // This can be found in the Slack documentation channel.
  // These users are owners / managers but cannot view the invoice tab
  // TODO Determine how to specify admin that should be restricted from certain featuresets

  var RESTRICTED_INVOICE_ADMIN_IDS = ['481', '2540']; // These companies allow their senior staff to add/edit client profiles

  var COMPANYS_WITH_SENIOR_STAFF_CLIENT_PRIVLEGES = ['55', '174', '241', '351', '632']; // These companies allow their basic staff to view all client profiles

  var COMPANYS_WITH_BASIC_STAFF_VIEWING_CLIENT_LIST = []; // These users can see the payments widget even though the company can't

  var BFTD_USERS_WHO_CAN_SEE_PAYMENTS_WIDGET = ['4277']; // These companies don't allow their basic or senior staff to view pet parent contact info

  var COMPANYS_WITHOUT_STAFF_CONTACT_INFO = ['6', '220'];
  var Validations = (0, _emberCpValidations.buildValidations)({
    first_name: (0, _emberCpValidations.validator)('presence', true),
    last_name: (0, _emberCpValidations.validator)('presence', true),
    emails: [(0, _emberCpValidations.validator)('presence', true)],
    user_role: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('inclusion', {
      in: [_userRole.default.owner.id, _userRole.default.employee.id, _userRole.default.manager.id, _userRole.default.senior_staff.id]
    })]
  });

  var _default = _emberData.default.Model.extend(Validations, {
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    first_name: _emberData.default.attr('capitalized-string'),
    last_name: _emberData.default.attr('capitalized-string'),
    preferred_name: _emberData.default.attr('capitalized-string'),
    email: _emberData.default.attr('trimmed-string'),
    emails: _emberData.default.hasMany('email', {
      async: true
    }),
    phone_numbers: _emberData.default.hasMany('phone_number', {
      async: true
    }),
    addresses: _emberData.default.hasMany('address', {
      async: true
    }),
    dob: _emberData.default.attr('string'),
    gender: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    invoice_frequency: _emberData.default.attr(),
    avatar_url: _emberData.default.attr(),
    mini_avatar_url: _emberData.default.attr(),
    user_role: _emberData.default.attr('string'),
    client: _emberData.default.attr(),
    pet_ids: _emberData.default.attr(),
    tos_accepted_at: _emberData.default.attr(),
    accept_new_tos: _emberData.default.attr(),
    calendar_order: _emberData.default.attr(),
    braintree_user_token: _emberData.default.attr(),
    last_four_ssn: _emberData.default.attr('string'),
    auto_pay: _emberData.default.attr('boolean'),
    company: _emberData.default.belongsTo('company', {
      async: true
    }),
    household: _emberData.default.belongsTo('household', {
      async: true
    }),
    household_id: _emberData.default.attr(),
    company_name: _emberData.default.attr('string'),
    countryInfo: service(),
    modelName: 'user',
    staff_status_pending: _emberData.default.attr(),
    changed_password: _emberData.default.attr('boolean'),
    staff_companies: _emberData.default.attr(),
    has_temporary_password: _emberData.default.attr(),
    default_schedule: _emberData.default.attr(),
    has_client_accounts: _emberData.default.attr('boolean'),
    client_accounts: _emberData.default.attr(),
    emd5: _emberData.default.attr(),
    user_dismissed_messages: _emberData.default.attr(),
    company_week_type: _emberData.default.attr(),
    households_contract_alerts_dismissed: _emberData.default.attr(),
    showEpaymentsReminder: _emberData.default.attr(),
    showHolidayUpdateReminder: _emberData.default.attr(),
    distribution_list_last_seen: _emberData.default.attr(),
    company_charges_service_fees: _emberData.default.attr(),
    displayDefaultSchedule: computed('default_schedule', function () {
      if (this.get('default_schedule') == 'self') {
        return 'My schedule';
      } else {
        return 'All staff';
      }
    }),
    firstNameOrPlaceholder: computed('getFirstName', function () {
      return Ember.isBlank(this.get('getFirstName')) ? 'You' : this.get('getFirstName');
    }),
    getFirstName: computed('first_name', 'preferred_name', function () {
      return Ember.isBlank(this.get('preferred_name')) ? this.get('first_name') : this.get('preferred_name');
    }),
    fullAddress: computed('street', 'city', 'state', 'zipcode', function () {
      if (!this.get('street') || !this.get('city') || !this.get('state') || !this.get('zipcode')) {
        return null;
      }

      return "".concat(this.get('street'), ", ").concat(this.get('city'), ", ").concat(this.get('state'), " ").concat(this.get('zipcode'));
    }),
    shortAddress: computed('street', 'city', function () {
      return "".concat(this.get('street'), " (").concat(this.get('city'), ")");
    }),
    userRoleName: computed('user_role', function () {
      return _userRole.default.roleName(this.get('user_role'));
    }),
    role: computed.readOnly('userRoleName'),
    selectedCountry: computed('country', 'countryInfo.states.[]', 'countryInfo.defaultUsState', 'countryInfo.defaultProvince', {
      set: function set(key, value) {
        this.set('country', value);
        return value;
      },
      get: function get() {
        if (!this.get('country')) {
          this.set('country', this.get('currentUser.user.company.country'));
        }

        var states = this.get('states');

        if (states.indexOf(this.get('state')) === -1) {
          if (this.get('country') === 'Canada') {
            this.set('state', this.get('countryInfo.defaultProvince'));
          } else {
            this.set('state', this.get('countryInfo.defaultUsState'));
          }
        }

        return this.get('country');
      }
    }),
    states: computed('country', 'countryInfo.caProvinces.[]', 'countryInfo.usStates.[]', function () {
      if (this.get('country') === 'Canada') {
        return this.get('countryInfo.caProvinces');
      } else {
        return this.get('countryInfo.usStates');
      }
    }),
    defaultState: computed('country', 'countryInfo.defaultUsState', 'countryInfo.defaultProvince', function () {
      if (this.get('country') === 'Canada') {
        return this.get('countryInfo.defaultProvince');
      } else {
        return this.get('countryInfo.defaultUsState');
      }
    }),
    fullName: computed('getFirstName', 'last_name', function () {
      return "".concat(this.get('getFirstName') || '', " ").concat(this.get('last_name') || '');
    }),
    formattedPhone: computed('phone', function () {
      var phone = this.get('phone');

      if (phone && phone.trim() !== '') {
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
      }

      return '';
    }),
    isStaff: computed.equal('user_role', _userRole.default.employee.id),
    isSeniorStaff: computed.equal('user_role', _userRole.default.senior_staff.id),
    isManager: computed.equal('user_role', _userRole.default.manager.id),
    isOwner: computed.equal('user_role', _userRole.default.owner.id),
    isSeniorStaffOrAbove: computed.or('isOwner', 'isManager', 'isSeniorStaff'),
    isAdmin: computed.or('isOwner', 'isManager'),
    isSuperAdmin: computed.equal('company.id', '1'),
    isPetParent: computed.equal('company.id', undefined),
    canViewClientPortalInvoices: computed('isPetParent', 'client_accounts.[]', function () {
      var clientAccounts = this.get('client_accounts');

      if (!clientAccounts) {
        return false;
      }

      return this.get('isPetParent');
    }),
    canViewClientPortalRequests: computed('isPetParent', 'client_accounts.[]', function () {
      var clientAccounts = this.get('client_accounts');

      if (!clientAccounts) {
        return false;
      }

      var canView = false;
      clientAccounts.forEach(function (account) {
        canView = canView || !account.company_deactivated;
      });
      return this.get('isPetParent') && canView;
    }),
    isEmployee: computed('staff_companies', 'staff_companies.[]', function () {
      return this.get('staff_companies').length > 0;
    }),
    canAddEditAppointments: computed.or('isAdmin', 'isSeniorStaff'),
    canViewAllSchedule: computed.or('isAdmin', 'isSeniorStaff'),
    canViewInbox: computed.or('isAdmin', 'isSeniorStaff'),
    canViewAppointmentRequests: computed('isAdmin', 'isSeniorStaff', 'company.pet_parent_appointment_requests_enabled', 'company.staff_appointment_approval', function () {
      return (this.get('isAdmin') || this.get('isSeniorStaff') || this.get('company.staff_appointment_approval')) && this.get('company.pet_parent_appointment_requests_enabled');
    }),
    canViewInvoicesTab: computed('id', 'isAdmin', function () {
      return this.get('isAdmin') && RESTRICTED_INVOICE_ADMIN_IDS.indexOf(this.get('id')) === -1;
    }),
    canAddEditClients: computed('isAdmin', 'isSeniorStaff', 'company.id', function () {
      return this.get('isAdmin') || this.get('isSeniorStaff') && COMPANYS_WITH_SENIOR_STAFF_CLIENT_PRIVLEGES.indexOf(this.get('company.id')) !== -1;
    }),
    canViewFullClientInfo: computed('isStaff', 'isSeniorStaff', 'company.id', function () {
      return this.get('isAdmin') || this.get('isSeniorStaff') || COMPANYS_WITH_BASIC_STAFF_VIEWING_CLIENT_LIST.indexOf(this.get('company.id')) !== -1;
    }),
    canViewClientContactInfo: computed('isSeniorStaffOrAbove', 'company.id', function () {
      return this.get('isSeniorStaffOrAbove') || COMPANYS_WITHOUT_STAFF_CONTACT_INFO.indexOf(this.get('company.id')) === -1;
    }),
    shouldShowPayments: computed('id', function () {
      return BFTD_USERS_WHO_CAN_SEE_PAYMENTS_WIDGET.indexOf(this.get('id')) !== -1;
    }),
    deleteMessage: computed('petNames', function () {
      return "Are you sure you want to remove this client? All appointments for this client's pets will be deleted.";
    }),
    hasUnconfirmedCompany: computed('client_accounts.[]', 'isPetParent', function () {
      var hasUnconfirmed = false;

      if (this.get('isPetParent')) {
        this.get('client_accounts').forEach(function (account) {
          if (account.pet_parents_activated && !account.company_deactivated && !account.accepted && !account.denied_request) {
            hasUnconfirmed = true;
          }
        });
      }

      return hasUnconfirmed;
    }),
    unconfirmedCompany: computed('client_accounts.[]', function () {
      var company = null;
      this.get('client_accounts').forEach(function (account) {
        if (account.pet_parents_activated && !account.accepted && !account.denied_request) {
          company = account;
        }
      });
      return company;
    }),
    latestCompany: computed('client_accounts.[]', function () {
      var acceptedCompanies = this.get('currentUser.user.client_accounts').filter(function (acct) {
        return acct.accepted && !acct.denied_request;
      });

      var _acceptedCompanies$so = acceptedCompanies.sort(function (a, b) {
        return a.created_at < b.created_at ? 1 : -1;
      }),
          _acceptedCompanies$so2 = _slicedToArray(_acceptedCompanies$so, 1),
          newestCompany = _acceptedCompanies$so2[0];

      return newestCompany;
    }),
    hasMultipleAccounts: computed('staff_companies.length', 'has_client_accounts', function () {
      var companiesLength = this.get('staff_companies.length');
      var hasClientAccounts = this.get('has_client_accounts');
      return companiesLength > 1 || companiesLength > 0 && hasClientAccounts;
    }),
    avatarOrPlaceholder: computed('avatar_url', function () {
      return this.get('avatar_url') || AVATAR_PLACEHOLDER;
    }),
    isImpersonated: computed('session.data.authenticated.tokenData.impersonated', function () {
      return this.get('session.data.authenticated.tokenData.impersonated');
    }),
    shouldSeeMessage: function shouldSeeMessage(message) {
      return !this.get('user_dismissed_messages').includes(message);
    },
    shouldSeeHouseholdContractAlert: function shouldSeeHouseholdContractAlert(householdId) {
      return !this.get('households_contract_alerts_dismissed').includes(householdId);
    },
    addHouseholdIdToDismissedContracts: function addHouseholdIdToDismissedContracts(householdId) {
      this.get('households_contract_alerts_dismissed').pushObject(householdId);
    }
  });

  _exports.default = _default;
});