define("frontend/controllers/index", ["exports", "moment", "frontend/config/environment", "jquery"], function (_exports, _moment, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    index: true,
    todaysDate: (0, _moment.default)().format('YYYY-MM-DD'),
    currentUser: Ember.inject.service(),
    flashMessage: Ember.inject.service(),
    showPage: !_environment.default.unstack_enabled,
    actions: {
      submitBetaRequest: function submitBetaRequest(_ref) {
        var _this = this;

        var name = _ref.name,
            email = _ref.email;

        _jquery.default.ajax({
          url: '/api/v1/support_messages/beta_sign_up',
          method: 'POST',
          data: {
            name: name,
            email: email
          }
        }).done(function () {
          _this.get('flashMessage').success('We’ll be in touch shortly. Thanks for your interest!');
        }).fail(function (data) {
          _this.get('flashMessage').error(JSON.parse(data.responseText).errors[0]);
        });
      },
      sendMessage: function sendMessage(_ref2) {
        var _this2 = this;

        var name = _ref2.name,
            email = _ref2.email,
            subject = _ref2.subject,
            message = _ref2.message;

        _jquery.default.ajax({
          url: '/api/v1/support_messages',
          method: 'POST',
          data: {
            name: name,
            email: email,
            subject: subject,
            message: message
          }
        }).done(function () {
          _this2.get('flashMessage').success('Your message is on its way. Thanks for contacting us!');
        }).fail(function (data) {
          _this2.get('flashMessage').error(JSON.parse(data.responseText).errors[0]);
        });
      }
    }
  });

  _exports.default = _default;
});