define("frontend/components/add-adjustment-sidepanel", ["exports", "ember-cp-validations", "frontend/models/invoice-adjustment"], function (_exports, _emberCpValidations, _invoiceAdjustment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;
  var Validations = (0, _emberCpValidations.buildValidations)({
    absAmount: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('number', {
      allowString: true,
      gte: 0
    })],
    adjustmentType: [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });

  var _default = Ember.Component.extend(Validations, {
    adjustmentTypeOptions: [{
      label: 'Additional charge',
      value: _invoiceAdjustment.ADDITIONAL_CHARGE
    }, {
      label: 'Discount',
      value: _invoiceAdjustment.DISCOUNT
    }],
    store: inject.service(),
    isInvalid: computed.not('validations.isValid'),
    currentUser: inject.service(),
    company: computed.alias('currentUser.user.company'),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      if (this.get('adjustment.amount') < 0) {
        this.set('adjustmentType', _invoiceAdjustment.DISCOUNT);
      } else {
        if (this.get('adjustment.apply_sales_tax') === undefined) this.set('adjustment.apply_sales_tax', true);
        this.set('adjustmentType', _invoiceAdjustment.ADDITIONAL_CHARGE);
      }

      this.set('originalAmount', this.get('adjustment.amount'));
      this.set('originalDescription', this.get('adjustment.description'));
    },
    adjustment: computed(function () {
      return this.get('store').createRecord('invoice-adjustment', {
        apply_sales_tax: true
      });
    }),
    isAdditionalCharge: computed('adjustmentType', function () {
      return this.get('adjustmentType') === _invoiceAdjustment.ADDITIONAL_CHARGE;
    }),
    absAmount: computed('adjustment.amount', 'adjustmentType', {
      get: function get() {
        var amount = this.get('adjustment.amount');

        if (this.get('adjustmentType') === _invoiceAdjustment.DISCOUNT && amount > 0 || this.get('adjustmentType') === _invoiceAdjustment.ADDITIONAL_CHARGE && amount < 0) {
          this.set('adjustment.amount', amount * -1);
        }

        return isNaN(this.get('adjustment.amount')) ? this.get('adjustment.amount') : Math.abs(this.get('adjustment.amount'));
      },
      set: function set(key, value) {
        if (this.get('adjustmentType') === _invoiceAdjustment.DISCOUNT) {
          this.set('adjustment.amount', value * -1);
        } else {
          this.set('adjustment.amount', value);
        }

        return value;
      }
    }),
    actions: {
      close: function close() {
        if (!this.get('editing')) {
          this.get('adjustment').rollbackAttributes();
        } else {
          this.set('adjustment.amount', this.get('originalAmount'));
          this.set('adjustment.description', this.get('originalDescription'));
        }

        this.get('close')();
      },
      clickDone: function clickDone() {
        this.set('didValidate', true);

        if (this.get('validations.isValid')) {
          this.get('save')(this.get('adjustment'));
        }
      },
      clickRemove: function clickRemove() {
        this.get('remove')(this.get('adjustment'));
      },
      changeApplySalesTaxSwitch: function changeApplySalesTaxSwitch() {
        this.toggleProperty('adjustment.apply_sales_tax');
      },
      handleTypeChange: function handleTypeChange(type) {
        if (type == 'discount') {
          this.set('adjustment.apply_sales_tax', false);
        } else {
          this.set('adjustment.apply_sales_tax', true);
        }
      }
    }
  });

  _exports.default = _default;
});