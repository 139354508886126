define("frontend/templates/components/change-default-invoice-frequency-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zdH0MbmP",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[4,\"ppb-modal\",null,[[\"customContainerClassNames\"],[\"override-frequency-modal\"]],{\"statements\":[[4,\"paper-form\",null,null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n        \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[1,[28,\"inline-svg\",[\"assets/images/diverging-paths.svg\"],null],false],[9],[0,\"\\n        \"],[7,\"header\",true],[8],[0,\"\\n          Just to confirm...\\n        \"],[9],[0,\"\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"subheader small\"],[8],[0,\"\\n        You’re changing your company’s default bill cycle from \"],[1,[22,\"oldFrequency\"],false],[0,\" to \"],[1,[22,\"newFrequency\"],false],[0,\". This will change all your clients to \"],[1,[22,\"newFrequency\"],false],[0,\" billing for any new drafts created going forward.\\n      \"],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"button-container\"],[8],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"style\",\"classNames\",\"click\"],[\"red\",\"override-all-button\",[28,\"action\",[[23,0,[]],\"selectOption\",true],null]]],{\"statements\":[[0,\"          \"],[7,\"div\",true],[8],[0,\"\\n            Got it\\n          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\"],[4,\"ppb-button\",null,[[\"style\",\"classNames\",\"click\"],[\"red-white\",\"dont-override-button\",[28,\"action\",[[23,0,[]],\"selectOption\",false],null]]],{\"statements\":[[0,\"        Just kidding, keep my old default\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/change-default-invoice-frequency-modal.hbs"
    }
  });

  _exports.default = _default;
});