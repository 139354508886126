define("frontend/routes/companies/remove-service", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    model: function model(params) {
      this._super.apply(this, arguments);

      return _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/offered_services/").concat(params.offeredServiceId, "/has_appointments"),
        method: 'GET'
      });
    }
  });

  _exports.default = _default;
});