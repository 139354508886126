define("frontend/components/grouped-appointment-list", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer;

  var _default = Ember.Component.extend({
    currentUser: inject.service(),
    store: inject.service(),
    media: inject.service(),
    appointmentRequests: [],
    groupedAppointments: [],
    selectedGroupedAppointments: [],
    per_page: 10,
    upcomingPage: 1,
    pastPage: 1,
    loadedOnce: false,
    upcomingReachedInfinity: false,
    lastAppointmentTime: null,
    pastReachedInfinity: false,
    upcomingCount: 0,
    pastCount: 0,
    showCanceledAppointments: false,
    readOnly: false,
    household: null,
    bulkEditing: false,
    showPendingAppointments: false,
    isPetParent: computed.alias('currentUser.user.isPetParent'),
    didInsertElement: function didInsertElement() {
      this.send('resetElement');

      if (this.get('setGroupedAppointmentListChild')) {
        this.get('setGroupedAppointmentListChild')(this);
      }
    },
    shouldResetElement: observer('household.id', 'showCanceledAppointments', 'showPendingAppointments', function () {
      this.send('resetElement');
    }),
    sortedGroupedAppointments: computed('groupedAppointments.[]', function () {
      return this.get('groupedAppointments').sort(function (a, b) {
        var timeOne = moment(a.get('time'));
        var timeTwo = moment(b.get('time'));

        if (timeOne.isAfter(timeTwo)) {
          return 1;
        } else if (timeOne.isBefore(timeTwo)) {
          return -1;
        } else {
          return 0;
        }
      });
    }),
    sortedAppointmentRequests: computed('appointmentRequests.[]', function () {
      return this.get('appointmentRequests').sort(function (a, b) {
        var timeOne = moment(a.get('starts_at'));
        var timeTwo = moment(b.get('starts_at'));

        if (timeOne.isAfter(timeTwo)) {
          return 1;
        } else if (timeOne.isBefore(timeTwo)) {
          return -1;
        } else {
          return 0;
        }
      });
    }),
    sendCancellationEmail: function sendCancellationEmail(groupedAppointment) {
      var petNames = this.get('currentUser.user.household.petNames');
      var companyEmail = groupedAppointment.get('service.company_email');
      var lastName = this.get('currentUser.user.household.owner.last_name');
      var apptDate = moment(groupedAppointment.get('service.starts_at')).format('dddd, MMMM Do');
      var subject = "Request to cancel ".concat(apptDate, " appointment for ").concat(petNames, " ").concat(lastName);
      var userName = this.get('currentUser.user.fullName');
      var service = groupedAppointment.get('offered_service.nameWithDuration');
      var pickupWindow = groupedAppointment.get('pickup_window') ? "".concat(groupedAppointment.get('pickup_window'), " arrival") : groupedAppointment.get('service.starts_at_time');
      var body = "Hi!%0D%0A%0D%0AI'd like to cancel the ".concat(service, " for ").concat(petNames, " on ").concat(apptDate, ", currently scheduled for ").concat(pickupWindow, ".%0D%0A%0D%0APlease let me know if you can accomodate or if you have any questions!%0D%0A%0D%0AThanks!%0D%0A").concat(userName);
      window.location.href = "mailto:".concat(companyEmail, "?subject=").concat(subject, "&body=").concat(body);
    },
    openCancelAppointmentModal: function openCancelAppointmentModal(group, cancellationNote) {
      this.set('showCancelApptModal', true);
      this.set('cancellationNote', cancellationNote);
      this.set('selectedGroup', group);
    },
    removeAppointmentsFromGroup: function removeAppointmentsFromGroup(groupedAppointment, apptIds) {
      if (groupedAppointment.get('appointments.length') == apptIds.length) {
        this.get('groupedAppointments').removeObject(groupedAppointment);
      } else {
        var appts = groupedAppointment.get('appointments');
        apptIds.forEach(function (apptId) {
          appts = appts.filter(function (appt) {
            return appt.get('id') != apptId;
          });
        });
        groupedAppointment.set('appointments', appts);
      }
    },
    removePetsFromRequest: function removePetsFromRequest(appointmentRequest, petIds) {
      if (appointmentRequest.get('pets.length') == petIds.length) {
        this.get('appointmentRequests').removeObject(appointmentRequest);
      } else {
        var pets = appointmentRequest.get('pets');
        petIds.forEach(function (petId) {
          pets = pets.filter(function (pet) {
            return pet.get('id') != petId;
          });
        });
        appointmentRequest.set('pets', pets);
      }
    },
    loadedAppointmentsCallback: function loadedAppointmentsCallback(newAppointments, apptArrayName, past) {
      var _this = this;

      if (this.get('reseting')) {
        this.set('reseting', false);
        this.set('isFetching', false);
        this.set('loadingPastAppointments', false);
        this.send('loadAppointments');
        return;
      }

      var scrollTop = $('.appointments-content').scrollTop();
      var scrollHeight = $('.appointments-content').prop('scrollHeight');
      var clientAccounts = this.get('currentUser.user.client_accounts');
      var loadedApptIds = this.get(apptArrayName).map(function (appt) {
        return parseInt(appt.get('id'));
      });
      newAppointments.forEach(function (appt) {
        if (!loadedApptIds.includes(parseInt(appt.get('id')))) {
          var showCancellations = true;
          var showRequests = true;

          if (_this.get('isPetParent')) {
            var account = clientAccounts.filter(function (acct) {
              return acct.company_id == appt.get('company_id');
            }).get('firstObject');
            showCancellations = account.parent_in_app_booking;
          }

          appt.set('showCancellations', showCancellations);
          appt.set('showRequests', showRequests);

          if (_this.get('lastAppointmentTime')) {
            var lastAppointmentTime = moment(_this.get('lastAppointmentTime'));
            var apptTime = moment(appt.get('service.starts_at'));

            if (apptTime.isAfter(lastAppointmentTime)) {
              _this.set('lastAppointmentTime', appt.get('service.starts_at'));
            }
          } else {
            _this.set('lastAppointmentTime', appt.get('service.starts_at'));
          }

          _this.get(apptArrayName).pushObject(appt);
        }
      });
      var apptsLength = newAppointments.get('length');
      var reachedInfinity = apptsLength < this.get('per_page');

      if (past) {
        this.incrementProperty('pastPage');
        this.set('pastReachedInfinity', reachedInfinity);
        this.set('pastCount', this.get('pastCount') + apptsLength);
      } else {
        this.incrementProperty('upcomingPage');
        this.set('upcomingReachedInfinity', reachedInfinity);
        var upcomingCount = 0;
        newAppointments.forEach(function (newAppt) {
          if (!newAppt.get('completed')) {
            upcomingCount++;
          }
        });
        this.set('upcomingCount', this.get('upcomingCount') + upcomingCount);
      }

      this.set('loadedOnce', true);
      this.set('isFetching', false);
      this.set('loadingPastAppointments', false);

      if (apptsLength > 0) {
        Ember.run.scheduleOnce('afterRender', function () {
          if (past) {
            if (_this.get('media.isMobile')) {
              scrollTop = $('.appointments-content').prop('scrollHeight') - scrollHeight - 3;

              if (_this.get('isPetParent')) {
                scrollTop -= 20;
              }
            } else {
              scrollTop = $('.appointments-content').prop('scrollHeight') - scrollHeight - 6;
            }
          }

          if (_this.get('showCanceledAppointments')) {
            scrollTop -= 16;
          }

          document.querySelector('.appointments-content').scroll({
            top: scrollTop
          });
        });
      }
    },
    actions: {
      resetElement: function resetElement() {
        this.set('groupedAppointments', []);
        this.set('appointmentRequests', []);
        this.set('selectedGroupedAppointments', []);
        this.set('upcomingPage', 1);
        this.set('pastPage', 1);
        this.set('loadedOnce', false);
        this.set('upcomingReachedInfinity', false);
        this.set('pastReachedInfinity', false);
        this.set('upcomingCount', 0);
        this.set('pastCount', 0);
        this.set('lastAppointmentTime', null);

        if (this.get('isFetching')) {
          this.set('reseting', true);
        } else {
          this.send('loadAppointments');
        }
      },
      loadAppointments: function loadAppointments(appointmentType) {
        var _this2 = this;

        if (this.get('isFetching')) {
          return;
        }

        this.set('isFetching', true);
        var per_page = this.get('per_page');
        var past = appointmentType == 'past';
        var page = past ? this.get('pastPage') : this.get('upcomingPage');
        var household_id = this.get('household.id');
        var canceled = this.get('showCanceledAppointments');
        var last_appointment_time = this.get('lastAppointmentTime');

        if (past) {
          this.set('loadingPastAppointments', true);
        }

        if (this.get('showPendingAppointments')) {
          var type = past ? 'expired' : 'pending';
          this.get('store').query('appointment-request', {
            per_page: per_page,
            page: page,
            past: past,
            household_id: household_id,
            type: type
          }).then(function (appointmentRequests) {
            _this2.loadedAppointmentsCallback(appointmentRequests, 'appointmentRequests', past);
          });
        } else {
          this.get('store').query('grouped-appointment', {
            per_page: per_page,
            page: page,
            past: past,
            household_id: household_id,
            canceled: canceled,
            last_appointment_time: last_appointment_time
          }).then(function (groupedAppointments) {
            _this2.loadedAppointmentsCallback(groupedAppointments, 'groupedAppointments', past);
          });
        }
      },
      clickCancelAppointment: function clickCancelAppointment(group) {
        if (this.get('isPetParent') && !group.showCancellations) {
          this.sendCancellationEmail(group);
          return;
        }

        this.openCancelAppointmentModal(group);
      },
      closeCancelAppointmentModal: function closeCancelAppointmentModal() {
        this.set('showCancelApptModal', false);
        this.set('selectedGroup', null);

        if (this.get('nextGroupedAppointmentToCancel')) {
          this.get('nextGroupedAppointmentToCancel')();
        }
      },
      modifyAppointment: function modifyAppointment(groupedAppointment) {
        this.get('modifyAppointment')(groupedAppointment);
      },
      modifyAppointmentRequest: function modifyAppointmentRequest(appointmentRequest) {
        this.get('modifyAppointmentRequest')(appointmentRequest);
      },
      cancelAppointment: function cancelAppointment(groupedAppointment, apptIds, cancellationFee, cancellationNotes) {
        var _this3 = this;

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointments/cancel_multiple"),
          method: 'POST',
          data: {
            appointment_ids: apptIds,
            cancellation_fee: cancellationFee,
            cancellation_notes: cancellationNotes
          }
        }).then(function () {
          _this3.removeAppointmentsFromGroup(groupedAppointment, apptIds);

          _this3.set('showCancelApptModal', false);

          _this3.set('selectedGroup', null);

          if (_this3.get('nextGroupedAppointmentToCancel')) {
            _this3.get('nextGroupedAppointmentToCancel')();
          }
        }).fail(function (response) {
          var message = 'Unexpected error canceling appointment. Please try again';

          if (response.responseJSON.error) {
            message = response.responseJSON.error;
          }

          Ember.getOwner(_this3).lookup('controller:application').send('notify', {
            message: message,
            type: 'error'
          });

          _this3.set('showCancelApptModal', false);

          _this3.set('selectedGroup', null);
        });
      },
      clickAddAppointment: function clickAddAppointment() {
        this.get('clickAddAppointment')();
      },
      openCancelAppointmentRequestModal: function openCancelAppointmentRequestModal(appointmentRequest) {
        this.set('showCancelApptRequestModal', true);
        this.set('selectedAppointmentRequest', appointmentRequest);
      },
      closeCancelAppointmentRequestModal: function closeCancelAppointmentRequestModal() {
        this.set('showCancelApptRequestModal', false);
        this.set('selectedAppointmentRequest', null);
      },
      cancelAppointmentRequest: function cancelAppointmentRequest(appointmentRequest, petIds) {
        var _this4 = this;

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointment_requests/").concat(appointmentRequest.get('id'), "/cancel"),
          method: 'POST',
          data: {
            pet_ids: petIds
          }
        }).then(function () {
          _this4.removePetsFromRequest(appointmentRequest, petIds);

          _this4.set('showCancelApptRequestModal', false);

          _this4.set('selectedAppointmentRequest', null);
        }).fail(function (response) {
          if (response.status === 400) {
            var message = response.responseJSON.message;
            Ember.getOwner(_this4).lookup('controller:application').send('notify', {
              message: message,
              type: 'user-error'
            });

            _this4.removePetsFromRequest(appointmentRequest, petIds);

            _this4.set('showCancelApptRequestModal', false);

            _this4.set('selectedAppointmentRequest', null);
          } else {
            var _message = 'Unexpected error canceling the appointment. Please try again';
            Ember.getOwner(_this4).lookup('controller:application').send('notify', {
              message: _message,
              type: 'error'
            });

            _this4.set('showCancelApptRequestModal', false);

            _this4.set('selectedAppointmentRequest', null);
          }
        });
      },
      openArchiveAppointmentModal: function openArchiveAppointmentModal(group) {
        this.set('showArchiveApptModal', true);
        this.set('selectedGroup', group);
      },
      closeArchiveAppointmentModal: function closeArchiveAppointmentModal() {
        this.set('showArchiveApptModal', false);
        this.set('selectedGroup', null);
      },
      archiveAppointment: function archiveAppointment(groupedAppointment, apptIds) {
        var _this5 = this;

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v1/appointments/delete_multiple"),
          method: 'DELETE',
          data: {
            appointment_ids: apptIds
          }
        }).then(function () {
          _this5.removeAppointmentsFromGroup(groupedAppointment, apptIds);

          _this5.set('showArchiveApptModal', false);

          _this5.set('selectedGroup', null);
        }).fail(function () {
          var message = 'Unexpected error archiving the appointment. Please try again';
          Ember.getOwner(_this5).lookup('controller:application').send('notify', {
            message: message,
            type: 'error'
          });

          _this5.set('showArchiveApptModal', false);

          _this5.set('selectedGroup', null);
        });
      },
      openRestoreAppointmentModal: function openRestoreAppointmentModal(group) {
        this.set('showRestoreApptModal', true);
        this.set('selectedGroup', group);
      },
      closeRestoreAppointmentModal: function closeRestoreAppointmentModal() {
        this.set('showRestoreApptModal', false);
        this.set('selectedGroup', null);
      },
      closeRemovedCancellationFeeModal: function closeRemovedCancellationFeeModal() {
        this.set('showRemovedCancellationFeeModal', false);
      },
      restoreAppointment: function restoreAppointment(groupedAppointment, apptIds) {
        var _this6 = this;

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/appointments/restore_multiple"),
          method: 'POST',
          data: {
            appointment_ids: apptIds
          }
        }).then(function (response) {
          _this6.removeAppointmentsFromGroup(groupedAppointment, apptIds);

          _this6.set('showRestoreApptModal', false);

          _this6.set('selectedGroup', null);

          if (response.removed_cancellation_fee) {
            _this6.set('showRemovedCancellationFeeModal', true);
          }
        }).fail(function () {
          var message = 'Unexpected error restoring the appointment. Please try again';
          Ember.getOwner(_this6).lookup('controller:application').send('notify', {
            message: message,
            type: 'error'
          });

          _this6.set('showRestoreApptModal', false);

          _this6.set('selectedGroup', null);
        });
      },
      selectAppointment: function selectAppointment(groupedAppointment) {
        var selectedGroupedAppointments = this.get('selectedGroupedAppointments');
        var found = false;
        selectedGroupedAppointments.forEach(function (selectedGroupedAppt) {
          if (selectedGroupedAppt.get('id') == groupedAppointment.get('id')) {
            selectedGroupedAppointments.removeObject(groupedAppointment);
            found = true;
          }
        });

        if (!found) {
          selectedGroupedAppointments.pushObject(groupedAppointment);
        }

        this.get('selectAppointment')(groupedAppointment);
      },
      showReportCard: function showReportCard(groupedReportCard) {
        this.showReportCard(groupedReportCard);
      }
    }
  });

  _exports.default = _default;
});