define("frontend/components/appointment-requests-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed,
      observer = Ember.observer;

  var _default = Ember.Component.extend({
    classNames: ['appointment-requests-list'],
    currentUser: inject.service(),
    store: inject.service(),
    infinity: inject.service(),
    media: inject.service(),
    appointmentRequests: [],
    totalRequestCount: null,
    per_page: 10,
    upcomingPage: 1,
    pastPage: 1,
    loadedOnce: false,
    upcomingReachedInfinity: false,
    pastReachedInfinity: false,
    upcomingCount: 0,
    pastCount: 0,
    household: null,
    isPetParent: false,
    showDropdown: false,
    requestType: null,
    selectedDate: null,
    onRequestsPage: false,
    dateSetByComponent: false,
    showingPending: computed.equal('requestType', 'pending'),
    didInsertElement: function didInsertElement() {
      this.get('setRequestListChild')(this);

      if (!this.get('currentUser.user.isStaff') && this.get('currentUser.user.company.staff_appointment_approval')) {
        this.set('requestType', 'all');
      } else {
        this.set('requestType', 'pending');
      }

      this.send('resetElement');
    },
    dateObserver: observer('selectedDate', function () {
      if (this.get('dateSetByComponent')) {
        this.toggleProperty('dateSetByComponent');
      } else {
        this.send('resetElement');
      }
    }),
    requestTypeOptions: computed('currentUser.user.company.staff_appointment_approval', 'currentUser.user.isStaff', 'media.isMobile', function () {
      if (!this.get('currentUser.user.isStaff') && this.get('currentUser.user.company.staff_appointment_approval')) {
        return {
          'pending': ['all', 'unassigned'],
          'replied': ['approved', 'declined', 'expired']
        };
      } else {
        return ['pending', 'approved', 'declined', 'expired'];
      }
    }),
    requestTypesAreGrouped: computed('requestTypeOptions.[]', function () {
      return !Array.isArray(this.get('requestTypeOptions'));
    }),
    approvedRequestCount: computed('appointmentRequests.@each.isDeleted', function () {
      return this.get('appointmentRequests').filter(function (request) {
        return request.get('isDeleted');
      }).length;
    }),
    groupedAppointmentRequests: computed('appointmentRequests.[]', 'appointmentRequests.@each.isDeleted', 'selectedDate', function () {
      if (!this.get('loadedOnce')) {
        return;
      }

      var groupedAppointmentRequests = {};
      var appointmentRequests = this.get('appointmentRequests').filter(function (request) {
        return !request.get('isDeleted');
      });
      appointmentRequests.forEach(function (request) {
        var date = moment.parseZone(request.get('starts_at')).format('YYYY-MM-DD');

        if (!groupedAppointmentRequests[date]) {
          groupedAppointmentRequests[date] = [];
        }

        groupedAppointmentRequests[date].push(request);
      });
      var selectedDate = this.get('selectedDate');

      if (selectedDate && !groupedAppointmentRequests[selectedDate]) {
        groupedAppointmentRequests[selectedDate] = [];
      }

      var dates = Object.keys(groupedAppointmentRequests).sort(function (a, b) {
        var timeOne = moment.parseZone(a, 'YYYY-MM-DD');
        var timeTwo = moment.parseZone(b, 'YYYY-MM-DD');

        if (timeOne.isAfter(timeTwo)) {
          return 1;
        } else if (timeOne.isBefore(timeTwo)) {
          return -1;
        } else {
          return 0;
        }
      });
      return dates.map(function (date) {
        var requests = groupedAppointmentRequests[date].sort(function (a, b) {
          var timeOne = moment.parseZone(a.get('starts_at'));
          var timeTwo = moment.parseZone(b.get('starts_at'));

          if (timeOne.isAfter(timeTwo)) {
            return 1;
          } else if (timeOne.isBefore(timeTwo)) {
            return -1;
          } else {
            var requestedTimeOne = moment(a.get('created_at'));
            var requestedTimeTwo = moment(b.get('created_at'));

            if (requestedTimeOne.isAfter(timeTwo)) {
              return -1;
            } else if (requestedTimeTwo.isBefore(timeTwo)) {
              return 1;
            } else {
              return 0;
            }
          }
        });
        return {
          date: date,
          requests: requests
        };
      });
    }),
    selectedDateAppointments: computed('selectedDate', 'groupedAppointmentRequests.[]', function () {
      var _this = this;

      var groupedRequests = this.get('groupedAppointmentRequests');

      if (groupedRequests && groupedRequests.length > 0) {
        var today = groupedRequests.filter(function (groupedRequest) {
          return groupedRequest.date == _this.get('selectedDate');
        });

        if (today.length > 0) {
          return today.get('firstObject').requests.length;
        }
      }

      return 0;
    }),
    selectedDateDayOfWeek: computed('selectedDate', function () {
      return moment(this.get('selectedDate'), 'YYYY-MM-DD').format('dddd');
    }),
    noPendingRequests: computed('upcomingReachedInfinity', 'totalRequestCount', 'approvedRequestCount', 'requestType', function () {
      return this.get('upcomingReachedInfinity') && this.get('totalRequestCount') - this.get('approvedRequestCount') == 0 && this.get('requestType') == 'pending';
    }),
    requestCount: computed('totalRequestCount', 'approvedRequestCount', function () {
      return this.get('totalRequestCount') - this.get('approvedRequestCount');
    }),
    showFilterOptions: function showFilterOptions() {
      this.get('headerReference').showFilterOptions();
    },
    actions: {
      resetElement: function resetElement() {
        // We're deleting approved pending requests to remove them from the list
        // without having to reload the entire list. We need to rollback that
        // flag when we switch to another section since we're reloading the entire
        // list anyway
        this.get('appointmentRequests').forEach(function (request) {
          return request.rollbackAttributes();
        });
        this.set('appointmentRequests', []);
        this.set('upcomingPage', 1);
        this.set('pastPage', 1);
        this.set('loadedOnce', false);
        this.set('upcomingReachedInfinity', false);
        this.set('pastReachedInfinity', false);
        this.set('upcomingCount', 0);
        this.set('pastCount', 0);

        if (this.get('isFetching')) {
          this.set('reseting', true);
        } else {
          this.send('loadAppointmentRequests');
        }
      },
      loadAppointmentRequests: function loadAppointmentRequests(appointmentType) {
        var _this2 = this;

        if (this.get('isFetching')) {
          return;
        }

        this.set('isFetching', true);
        var per_page = this.get('per_page');
        var past = appointmentType == 'past';
        var page = past ? this.get('pastPage') : this.get('upcomingPage');
        var type = this.get('requestType');
        var date = this.get('selectedDate');

        if (past) {
          this.set('loadingPastAppointments', true);
        }

        this.get('store').query('appointment-request', {
          per_page: per_page,
          page: page,
          past: past,
          type: type,
          date: date
        }).then(function (appointmentRequests) {
          if (_this2.get('reseting')) {
            _this2.set('reseting', false);

            _this2.set('isFetching', false);

            _this2.set('loadingPastAppointments', false);

            _this2.send('loadAppointmentRequests');

            return;
          }

          _this2.set('totalRequestCount', appointmentRequests.meta.total_count);

          if (type == 'pending' && _this2.get('setPendingRequestsCount')) {
            _this2.get('setPendingRequestsCount')(appointmentRequests.meta.total_count);
          }

          var scrollTop = $('.requests-list').scrollTop();

          var apptRequestIds = _this2.get('appointmentRequests').map(function (apptRequest) {
            return parseInt(apptRequest.get('id'));
          });

          appointmentRequests.forEach(function (apptRequest) {
            if (!apptRequestIds.includes(parseInt(apptRequest.get('id')))) {
              _this2.get('appointmentRequests').pushObject(apptRequest);
            }
          });

          _this2.notifyPropertyChange('appointmentRequests');

          var apptsLength = appointmentRequests.get('length');
          var reachedInfinity = apptsLength < per_page;

          if (past) {
            _this2.incrementProperty('pastPage');

            _this2.set('pastReachedInfinity', reachedInfinity);

            _this2.set('pastCount', _this2.get('pastCount') + apptsLength);
          } else {
            _this2.incrementProperty('upcomingPage');

            _this2.set('upcomingReachedInfinity', reachedInfinity);

            _this2.set('upcomingCount', _this2.get('upcomingCount') + apptsLength);
          } // If we're loading for the first time we want to scroll to the date
          // otherwise leave it scrolled to where the user scrolled


          var loadedAlready = _this2.get('loadedOnce');

          if (type == 'pending' || type == 'all') {
            Ember.run.scheduleOnce('afterRender', function () {
              if (loadedAlready) {
                document.querySelector('.requests-list').scroll({
                  top: scrollTop
                });
              } else {
                var ele = document.getElementById(date);

                if (ele) {
                  ele.scrollIntoView();
                }
              }
            });
          }

          _this2.set('loadedOnce', true);

          _this2.set('isFetching', false);

          _this2.set('loadingPastAppointments', false);
        });
      },
      modifyAppointmentRequest: function modifyAppointmentRequest(appointmentRequest) {
        this.get('modifyAppointmentRequest')(appointmentRequest);
      },
      selectHeaderOption: function selectHeaderOption(requestType) {
        this.set('requestType', requestType);
        this.send('resetElement');
      },
      goToRequests: function goToRequests() {
        this.get('goToRequests')();
        var date = this.get('selectedDate');
        Ember.run.scheduleOnce('afterRender', function () {
          var ele = document.getElementById(date);

          if (ele) {
            ele.scrollIntoView();
          }
        });
      },
      switchDate: function switchDate(date) {
        this.set('dateSetByComponent', true);
        this.set('selectedDate', date);
      },
      startDraggingRequest: function startDraggingRequest(appointmentRequest) {
        this.get('startDraggingRequest')(appointmentRequest);
      },
      endDraggingRequest: function endDraggingRequest() {
        this.get('endDraggingRequest')();
      },
      setHeaderChild: function setHeaderChild(headerReference) {
        this.set('headerReference', headerReference);
      }
    }
  });

  _exports.default = _default;
});