define("frontend/components/payments-widget/payment-method-icon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    tagName: '',
    paymentMethod: null,
    iconUrl: computed('paymentMethod', function () {
      return "assets/images/".concat(this.get('paymentMethod'), ".svg");
    })
  });

  _exports.default = _default;
});