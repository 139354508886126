define("frontend/components/day-of-week-selector", ["exports", "frontend/utils/string-util"], function (_exports, _stringUtil) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['day-of-week-selector'],
    selectionTextEnabled: true,
    selectedDaysOfWeek: [],
    daysOfWeekOptions: [{
      value: 1,
      label: 'M',
      day: 'Monday'
    }, {
      value: 2,
      label: 'T',
      day: 'Tuesday'
    }, {
      value: 3,
      label: 'W',
      day: 'Wednesday'
    }, {
      value: 4,
      label: 'Th',
      day: 'Thursday'
    }, {
      value: 5,
      label: 'F',
      day: 'Friday'
    }, {
      value: 6,
      label: 'Sa',
      day: 'Saturday'
    }, {
      value: 0,
      label: 'Su',
      day: 'Sunday'
    }],
    daysOfWeek: computed('selectedDaysOfWeek.[]', function () {
      var selectedDaysOfWeek = this.get('selectedDaysOfWeek');
      var options = this.get('daysOfWeekOptions');
      return options.map(function (option) {
        var selected = selectedDaysOfWeek.includes(option.value);
        return {
          value: option.value,
          label: option.label,
          day: option.day,
          selected: selected
        };
      });
    }),
    dayOfWeekLongDisplay: computed('selectedDaysOfWeek.[]', function () {
      var dows = this.get('selectedDaysOfWeek');

      if (dows.length == 7) {
        return 'Monday through Sunday';
      } else if (dows.length === 5 && dows.sort().every(function (value, index) {
        return value === [1, 2, 3, 4, 5][index];
      })) {
        return 'Monday through Friday';
      } else {
        var dayMap = [['Mondays', 1], ['Tuesdays', 2], ['Wednesdays', 3], ['Thursdays', 4], ['Fridays', 5], ['Saturdays', 6], ['Sundays', 0]];
        var selectedDays = [];
        dayMap.forEach(function (day) {
          if (dows.includes(day[1])) {
            selectedDays.push(day[0]);
          }
        });
        return _stringUtil.default.joinWithAnd(selectedDays);
      }
    }),
    showDaysError: computed('selectedDaysOfWeek.[]', function () {
      return this.get('selectedDaysOfWeek.length') == 0;
    }),
    actions: {
      clickDayOfWeek: function clickDayOfWeek(option) {
        var selectedDaysOfWeek = this.get('selectedDaysOfWeek');

        if (selectedDaysOfWeek.includes(option)) {
          selectedDaysOfWeek = selectedDaysOfWeek.filter(function (dow) {
            return dow != option;
          });
        } else {
          selectedDaysOfWeek.pushObject(option);
        }

        this.set('selectedDaysOfWeek', selectedDaysOfWeek);
      }
    }
  });

  _exports.default = _default;
});