define("frontend/components/google-map", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['google-map'],
    classNameBindings: ['hidden'],
    googleMapsGeocode: Ember.inject.service(),
    zoom: 15,
    disableDefaultUI: false,
    lat: null,
    lng: null,
    markerLat: null,
    markerLng: null,
    addressUrl: null,
    href: Ember.computed('address', function () {
      if (this.get('address')) {
        var address = encodeURIComponent(this.get('address'));
        return "https://maps.google.com?q=".concat(address);
      }
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);

      if (_environment.default.environment === 'development') {
        return;
      }

      if (this.get('addressUrl')) {
        return;
      }

      if (this.get('lat') != null && this.get('lng') != null) {
        Ember.run.scheduleOnce('afterRender', function () {
          _this.renderMap(_this.get('lat'), _this.get('lng'), _this.get('markerLat'), _this.get('markerLng'));
        });
      } else if (this.get('address')) {
        this.renderMapWithAddress(this.get('address'));
      }
    },
    renderMapWithAddress: function renderMapWithAddress(address) {
      var _this2 = this;

      this.set('hidden', true);
      this.get('googleMapsGeocode').geocode(address).then(function (response) {
        Ember.run.scheduleOnce('afterRender', function () {
          if (_this2.isDestroyed) {
            return;
          }

          var location = response.location;
          var lat = location.lat();
          var lng = location.lng();

          _this2.renderMap(lat, lng);
        });

        _this2.set('hidden', false);
      });
    },
    renderMap: function renderMap(lat, lng) {
      var markerLat = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : null;
      var markerLng = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
      var center = new google.maps.LatLng(lat, lng);
      var map = new google.maps.Map((0, _jquery.default)(this.element)[0], {
        zoom: this.get('zoom'),
        center: center,
        zoomControl: false,
        scrollwheel: false,
        disableDefaultUI: this.get('disableDefaultUI')
      });
      var position = center;

      if (markerLat && markerLng) {
        position = new google.maps.LatLng(markerLat, markerLng);
      }

      new google.maps.Marker({
        map: map,
        position: position
      });
    }
  });

  _exports.default = _default;
});