define("frontend/components/vet-list-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    vet: null,
    actions: {
      clickedVet: function clickedVet(vet) {
        this.get('clickedVet')(vet);
      }
    }
  });

  _exports.default = _default;
});