define("frontend/controllers/schedule/calendar", ["exports", "ember-computed-change-gate/change-gate", "frontend/utils/appointment-searcher", "moment"], function (_exports, _changeGate, _appointmentSearcher, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    queryParams: ['hidingInactiveStaff'],
    showAddInstructionsModal: false,
    removingAll: false,
    showRemovingAllFlash: false,
    timeZone: inject.service(),
    serviceActions: inject.service(),
    schedule: inject.controller(),
    date: computed.readOnly('schedule.date'),
    currentUser: inject.service(),
    company: computed.alias('currentUser.user.company'),
    weekType: computed.alias('company.weekType'),
    // TODO When I update the UI so that only 1 week can be selected at a time, this won't be needed anymore
    // changeGate only notifies listeners to update when it changes
    startWeekDate: (0, _changeGate.default)('date', 'weekType', function () {
      if (!this.get('weekType')) {
        return;
      }

      return (0, _moment.default)(this.get('date')).startOf(this.get('weekType')).format('YYYY-MM-DD');
    }),
    care_provider_id: computed.alias('schedule.care_provider_id'),
    selectedAppointment: computed.alias('schedule.selectedAppointment'),
    staff: computed.readOnly('schedule.staff'),
    searchValue: computed.readOnly('schedule.searchValue'),
    calendarOptions: Ember.inject.service('calendarOptions'),
    showingCalendarOptions: computed.alias('calendarOptions.showingCalendarOptions'),
    hidingInactiveStaff: computed.alias('calendarOptions.hidingInactiveStaff'),
    currentRouteName: computed.readOnly('schedule.currentRouteName'),
    highlightWindowStart: computed.alias('schedule.highlightWindowStart'),
    highlightWindowEnd: computed.alias('schedule.highlightWindowEnd'),
    services: computed.alias('schedule.services'),
    numCareProviderVisits: computed('filteredCareProviderServices.@each.appointments', 'searchValue', function () {
      var careProviderServices = this.get('filteredCareProviderServices');
      var petNameSearchValue = this.get('searchValue');
      return _appointmentSearcher.default.numVisitsInServices(petNameSearchValue, careProviderServices);
    }),
    numAllVisits: computed('filteredAllServices.@each.appointments', 'searchValue', function () {
      var services = this.get('filteredAllServices');
      var petNameSearchValue = this.get('searchValue');
      return _appointmentSearcher.default.numVisitsInServices(petNameSearchValue, services);
    }),
    numCanceledVisits: computed('filteredCareProviderServices.@each.appointments', 'searchValue', function () {
      var services = this.get('filteredCareProviderServices');
      var petNameSearchValue = this.get('searchValue');
      var countCanceledAppointments = true;
      return _appointmentSearcher.default.numVisitsInServices(petNameSearchValue, services, countCanceledAppointments);
    }),
    numCareProviderPets: computed('filteredCareProviderServices.@each.appointments', 'filteredCareProviderServices.@each.{sortedCanceledGroupedAppointmentsIds,sortedGroupedAppointmentsWithoutCanceledIds}', 'searchValue', function () {
      var services = this.get('filteredCareProviderServices');
      var petNameSearchValue = this.get('searchValue');
      return _appointmentSearcher.default.numPetsInServices(petNameSearchValue, services);
    }),
    numAllPets: computed('filteredAllServices.@each.appointments', 'filteredAllServices.@each.{sortedCanceledGroupedAppointmentsIds,sortedGroupedAppointmentsWithoutCanceledIds}', 'searchValue', function () {
      var services = this.get('filteredAllServices');
      var petNameSearchValue = this.get('searchValue');
      return _appointmentSearcher.default.numPetsInServices(petNameSearchValue, services);
    }),
    numCanceledPets: computed('filteredCareProviderServices.@each.appointments', 'filteredCareProviderServices.@each.{sortedCanceledGroupedAppointmentsIds,sortedGroupedAppointmentsWithoutCanceledIds}', 'searchValue', function () {
      var services = this.get('filteredCareProviderServices');
      var petNameSearchValue = this.get('searchValue');
      var countCanceledAppointments = true;
      return _appointmentSearcher.default.numPetsInServices(petNameSearchValue, services, countCanceledAppointments);
    }),
    selectedCareProvider: computed('care_provider_id', 'model.staff', function () {
      if (this.get('care_provider_id') === 'all') {
        return 'all';
      } else if (this.get('care_provider_id') == 'cancellations') {
        return 'cancellations';
      } else {
        return this.get('model.staff').filterBy('id', this.get('care_provider_id')).get('firstObject');
      }
    }),
    prettyDate: computed('date', function () {
      return (0, _moment.default)(this.get('date'), 'YYYY-MM-DD').format('ddd, MMM D YYYY');
    }),
    isToday: computed('date', function () {
      var date = (0, _moment.default)(this.get('date'), 'YYYY-MM-DD').startOf('day');
      var today = (0, _moment.default)().startOf('day');
      return date.diff(today, 'days') === 0;
    }),
    allServices: computed('services.[]', function () {
      var startDate = this.get('date');
      var endDate = this.get('date');
      var timeZone = this.get('company.time_zone_mapping');
      window.scrollTo(0, 0);
      return this.store.peekAll('service').filter(function (service) {
        return _moment.default.parseZone(service.get('starts_at')).isSameOrAfter(_moment.default.tz(startDate, timeZone).startOf('day')) && _moment.default.parseZone(service.get('starts_at')).isSameOrBefore(_moment.default.tz(endDate, timeZone).endOf('day')) && service.get('sortedGroupedAppointmentsWithoutCanceled.length') > 0;
      });
    }),
    filteredAllServices: computed('allServices.@each.appointments', 'searchValue', function () {
      var filter = this.get('searchValue').toLowerCase();
      var services = this.get('allServices');
      services = services.filter(function (service) {
        return service.get('sortedGroupedAppointmentsWithoutCanceled.length') > 0;
      });

      if (filter.trim() === '') {
        return services;
      }

      return services.filter(function (service) {
        return service.get('sortedGroupedAppointmentsWithoutCanceled').any(function (groupedAppointment) {
          return groupedAppointment.get('appointments').any(function (appointment) {
            return appointment.get('pet.name').slice(0, filter.length).toLowerCase().indexOf(filter) >= 0;
          });
        });
      });
    }),
    careProviderServices: computed('services.[]', 'startWeekDate', 'weekType', function () {
      var startDate = this.get('startWeekDate');
      var endDate = (0, _moment.default)(this.get('startWeekDate')).endOf(this.get('weekType')).format('YYYY-MM-DD');
      var care_provider_id = this.get('care_provider_id');
      window.scrollTo(0, 0);
      return this.store.peekAll('service').filter(function (service) {
        var showService = (0, _moment.default)(service.get('starts_at')).isSameOrAfter((0, _moment.default)(startDate).startOf('day')) && (0, _moment.default)(service.get('starts_at')).isSameOrBefore((0, _moment.default)(endDate).endOf('day'));

        if (care_provider_id == 'cancellations') {
          return showService;
        } else {
          return showService && service.get('care_provider.id') === care_provider_id;
        }
      });
    }),
    filteredCareProviderServices: computed('careProviderServices.@each.appointments', 'careProviderServices.@each.{sortedCanceledGroupedAppointmentsIds,sortedGroupedAppointmentsWithoutCanceledIds}', 'searchValue', function () {
      var _this = this;

      var filter = this.get('searchValue').toLowerCase();
      var services = this.get('careProviderServices');
      services = services.filter(function (service) {
        if (_this.get('care_provider_id') == 'cancellations') {
          return service.get('sortedCanceledGroupedAppointments.length') > 0;
        } else {
          return service.get('sortedGroupedAppointmentsWithoutCanceled.length') > 0;
        }
      });

      if (filter.trim() === '') {
        return services;
      }

      return services.filter(function (service) {
        var groupedAppts = _this.get('care_provider_id') == 'cancellations' ? service.get('sortedCanceledGroupedAppointments') : service.get('sortedGroupedAppointmentsWithoutCanceled');
        return groupedAppts.any(function (groupedAppt) {
          return groupedAppt.get('appointments').any(function (appointment) {
            return appointment.get('pet.name').slice(0, filter.length).toLowerCase().indexOf(filter) >= 0;
          });
        });
      });
    }),
    careProviders: computed('hidingInactiveStaff', 'care_provider_id', 'model.staff.[]', 'filteredAllServices.@each.care_provider', 'searchValue', function () {
      var _this2 = this;

      if (this.get('care_provider_id') !== 'all') {
        return this.get('model.staff').filterBy('id', this.get('care_provider_id'));
      }

      if (this.get('hidingInactiveStaff') && this.get('numAllVisits') > 0) {
        return this.get('model.staff').filter(function (careProvider) {
          return _this2.get('filteredAllServices').filter(function (service) {
            return service.get('care_provider.id') === careProvider.id && service.get('appointments.length') > 0;
          }).length > 0;
        }).sortBy('calendar_order');
      } else {
        return this.get('model.staff').sortBy('calendar_order');
      }
    }),
    actions: {
      confirmRemoveAll: function confirmRemoveAll() {
        var _this3 = this;

        var serviceActions = this.get('serviceActions');

        if (this.get('removingAll')) {
          return;
        }

        this.set('showRemovingAllFlash', false);
        this.set('removingAll', true);
        var removePromise;

        if (this.get('care_provider_id') === 'all') {
          removePromise = serviceActions.removeAllFor(this.get('date'), this.get('care_provider_id'));
        } else {
          removePromise = serviceActions.removeAllInWeek(this.get('date'), this.get('care_provider_id'));
        }

        removePromise.finally(function () {
          window.location.reload();

          _this3.set('removingAll', false);
        });
      },
      showCalendarOptions: function showCalendarOptions() {
        this.set('showingCalendarOptions', true);
        this.get('schedule').send('showSidePanel');
      },
      createNewAppointment: function createNewAppointment(careProvider, dateTime) {
        var selectedHouseholdId = null;
        var filter = '';
        var defaultCareProviderId = careProvider.get('id');
        var backRoute = this.get('currentRouteName');
        var defaultTime = dateTime.format('h:mm A');
        var date = (0, _moment.default)(dateTime).format('YYYY-MM-DD');
        var preselectDate = true;
        var queryParams = {
          selectedHouseholdId: selectedHouseholdId,
          filter: filter,
          date: date,
          preselectDate: preselectDate,
          backRoute: backRoute,
          defaultCareProviderId: defaultCareProviderId,
          defaultTime: defaultTime
        };
        this.transitionToRoute('add-appointment.index', {
          queryParams: queryParams
        });
      },
      hideCalendarOptions: function hideCalendarOptions() {
        this.set('showingCalendarOptions', false);
      },
      denyRemoveAll: function denyRemoveAll() {
        this.set('showRemovingAllFlash', false);
      },
      removeAll: function removeAll() {
        this.set('showRemovingAllFlash', true);
      },
      clickAppointment: function clickAppointment(appointment) {
        if (appointment.get('appointment_detail.isLoaded')) {
          this.store.peekAll('appointment_detail').filterBy('id', appointment.get('appointment_detail.id'))[0].reload();
        }

        this.set('selectedAppointment', appointment);
        this.set('showingCalendarOptions', false);
        this.get('schedule').send('showSidePanel');
      },
      servicesChanged: function servicesChanged() {
        this.notifyPropertyChange('services');
      }
    }
  });

  _exports.default = _default;
});