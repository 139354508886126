define("frontend/controllers/admin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Controller.extend({
    currentUser: Ember.inject.service(),
    session: Ember.inject.service(),
    selectedAdminOption: computed('currentUser.user.isOwner', 'currentUser.user.isAdmin', function () {
      if (this.get('currentUser.user.isOwner')) {
        return {
          value: 'admin.index',
          label: 'Impersonate'
        };
      } else {
        return {
          value: 'admin.veterinarian-clinic-groups',
          label: 'Vet Groups'
        };
      }
    }),
    unapprovedBreeds: computed.alias('model.unapproved_breeds'),
    unapprovedVets: computed.alias('model.unapproved_vets'),
    unapprovedDoctors: computed.alias('model.unapproved_doctors'),
    unapprovedInsurances: computed.alias('model.unapproved_insurances'),
    adminOptions: computed('currentUser.user.isOwner', 'currentUser.user.isAdmin', function () {
      var options = [{
        value: 'admin.breeds',
        label: "Breeds (".concat(this.get('unapprovedBreeds'), ")"),
        showRedDot: this.get('unapprovedBreeds') > 0
      }, {
        value: 'admin.veterinarian-clinics',
        label: "Vets (".concat(this.get('unapprovedVets'), ")"),
        showRedDot: this.get('unapprovedVets') > 0
      }, {
        value: 'admin.veterinarian-clinic-groups',
        label: 'Vet Groups'
      }, {
        value: 'admin.doctors',
        label: "Doctors (".concat(this.get('unapprovedDoctors'), ")"),
        showRedDot: this.get('unapprovedDoctors') > 0
      }, {
        value: 'admin.insurances',
        label: "Insurances (".concat(this.get('unapprovedInsurances'), ")"),
        showRedDot: this.get('unapprovedInsurances') > 0
      }];

      if (this.get('currentUser.user.isOwner')) {
        options.push({
          value: 'admin.index',
          label: 'Impersonate'
        });
        options.push({
          value: 'admin.create-company',
          label: 'Create company'
        });
        options.push({
          value: 'admin.import-clients',
          label: 'Import clients'
        });
      }

      if (this.get('currentUser.user.isAdmin')) {
        options.push({
          value: 'admin.email-look-up',
          label: 'User lookup'
        });
        options.push({
          value: 'admin.companies',
          label: 'Companies'
        });
      }

      return options;
    }),
    actions: {
      onSelectedAction: function onSelectedAction(option) {
        this.set('selectedAdminOption', option);
        this.transitionToRoute(option.value);
      }
    }
  });

  _exports.default = _default;
});