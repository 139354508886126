define("frontend/routes/inbox/index", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    media: Ember.inject.service(),
    model: function model() {
      var _this$_super = this._super.apply(this, arguments),
          householdsWithMessages = _this$_super.householdsWithMessages;

      if (householdsWithMessages.get('length') > 0 && !this.get('media.isMobile')) {
        var firstId = householdsWithMessages.get('firstObject.id');

        if (firstId) {
          return this.transitionTo('inbox.household', firstId);
        }
      }
    }
  });

  _exports.default = _default;
});