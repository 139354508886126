define("frontend/templates/edit-staff", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "x/haVvv9",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"staff-new\"],[8],[0,\"\\n\"],[4,\"new-header\",null,[[\"cancel\"],[[28,\"action\",[[23,0,[]],\"cancelEditEmployee\"],null]]],{\"statements\":[[0,\"    \"],[7,\"h1\",true],[10,\"class\",\"header-text\"],[8],[0,\"Edit staff profile\"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[1,[28,\"add-edit-staff\",null,[[\"editing\",\"model\",\"offeredServiceAmounts\",\"onSaveUser\",\"onCancel\"],[true,[24,[\"model\"]],[24,[\"offeredServiceAmounts\"]],[28,\"route-action\",[\"transitionToStaff\"],null],[28,\"action\",[[23,0,[]],\"cancelEditEmployee\"],null]]]],false],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/edit-staff.hbs"
    }
  });

  _exports.default = _default;
});