define("frontend/validators/multi-length", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var MultiLength = _base.default.extend({
    // eslint-disable-next-line no-unused-vars
    validate: function validate(value, options, model, attribute) {
      if (value.length == options.value1 || value.length == options.value2) {
        return true;
      }

      return options.message;
    }
  });

  MultiLength.reopenClass({
    // eslint-disable-next-line no-unused-vars
    getDependentsFor: function getDependentsFor(attribute, options) {
      return [];
    }
  });
  var _default = MultiLength;
  _exports.default = _default;
});