define("frontend/components/auto-bill", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['auto-bill'],
    company: null,
    invoiceFrequencies: null,
    anySidePanelActive: false,
    showAutobillDropdown: false,
    actions: {
      closeSidePanel: function closeSidePanel() {
        this.closeSidePanel();
      },
      openSidePanel: function openSidePanel() {
        if (this.get('anySidePanelActive')) {
          return;
        }

        this.openSidePanel();
      }
    }
  });

  _exports.default = _default;
});