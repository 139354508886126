define("frontend/services/appointment-times", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var service = Ember.inject.service;
  var STEP_MINUTES = 15;

  var _default = Ember.Service.extend({
    timeZone: service(),
    allTimes: function allTimes() {
      var start = (0, _moment.default)().startOf('day').add(6, 'hours');
      var end = (0, _moment.default)().endOf('day');
      var times = [];

      while (end.diff(start, 'minutes') > STEP_MINUTES) {
        var time = start.format('h:mm A');
        times.push({
          value: time,
          label: "".concat(time)
        });
        start = start.add(STEP_MINUTES, 'minutes');
      }

      return times;
    }
  });

  _exports.default = _default;
});