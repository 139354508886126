define("frontend/controllers/users/login", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'email': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    }), (0, _emberCpValidations.validator)('format', {
      type: 'email'
    })],
    'password': [(0, _emberCpValidations.validator)('presence', {
      presence: true,
      ignoreBlank: true
    })]
  });

  var _default = Ember.Controller.extend(Validations, {
    queryParams: ['redirectFromInvoice'],
    session: Ember.inject.service(),
    flashMessage: Ember.inject.service(),
    currentUser: Ember.inject.service(),
    email: '',
    password: '',
    rememberMe: true,
    loginIncorrectMessage: null,
    redirectFromInvoice: false,
    actions: {
      authenticate: function authenticate() {
        var _this = this;

        this.set('emailError', false);
        this.set('passwordError', false);
        this.set('loginIncorrectMessage', null);
        this.set('email', this.get('email').trim());

        if (!this.get('validations.attrs.email.isValid')) {
          this.set('emailError', true);
        }

        if (!this.get('validations.attrs.password.isValid')) {
          this.set('passwordError', true);
        }

        if (this.get('emailError') || this.get('passwordError')) {
          return;
        }

        var _this$getProperties = this.getProperties('email', 'password'),
            email = _this$getProperties.email,
            password = _this$getProperties.password;

        var expirationTime = this.get('rememberMe') ? 365 * 24 * 60 * 60 : null;
        this.set('session.store.cookieExpirationTime', expirationTime);
        this.get('session').authenticate('authenticator:jwt', {
          user: {
            user_email: email,
            password: password
          }
        }).then(function () {
          return _this.get('currentUser').load();
        }).then(function () {
          window.location.href = '/';
        }).catch(function (reason) {
          _this.set('loginIncorrectMessage', reason.json.message);
        });
      },
      redirectToRequestAccess: function redirectToRequestAccess() {
        this.transitionToRoute('sign-up.index');
      },
      redirectToForgotPassword: function redirectToForgotPassword() {
        this.transitionToRoute('users.forgot-password', {
          queryParams: {
            invalidToken: false
          }
        });
      }
    }
  });

  _exports.default = _default;
});