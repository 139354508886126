define("frontend/components/preferred-staff-list", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    store: inject.service(),
    classNames: ['preferred-staff-list'],
    employees: [],
    household: null,
    preferredStaffSorting: ['position'],
    sortedPreferredStaff: computed.sort('household.preferred_staff', 'preferredStaffSorting'),
    employeeOptions: computed('employees.[]', function () {
      if (this.get('employees')) {
        var employees = this.get('employees').map(function (employee) {
          return {
            staff_name: employee.get('user.fullName'),
            employee_id: employee.get('id')
          };
        });
        return employees.sortBy('staff_name');
      }
    }),
    allOptionsSelected: computed('household.preferred_staff.@each.{employee_id}', function () {
      return this.get('household.preferred_staff').every(function (staff) {
        return staff.get('employee_id') !== undefined;
      });
    }),
    savePreferredStaff: function savePreferredStaff() {
      var preferred_staff = this.get('household.preferred_staff').map(function (staff) {
        return {
          employee_id: staff.get('employee_id'),
          position: staff.get('position')
        };
      });

      _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/preferred_staff"),
        method: 'PUT',
        data: {
          household_id: this.get('household.id'),
          preferred_staff: preferred_staff
        }
      });
    },
    actions: {
      addNewPreferredStaff: function addNewPreferredStaff() {
        var position = this.get('household.preferred_staff.length') + 1;
        var newStaff = this.get('store').createRecord('preferred-staff', {
          position: position
        });
        this.get('household.preferred_staff').pushObject(newStaff);
      },
      changeStaffMember: function changeStaffMember(preferredStaff, staffChoice) {
        preferredStaff.set('employee_id', staffChoice.employee_id);
        preferredStaff.set('staff_name', staffChoice.staff_name);
        this.savePreferredStaff();
      },
      deleteStaffMember: function deleteStaffMember(preferredStaff) {
        var deletedPosition = preferredStaff.get('position');
        var staffToRemove = null;
        this.get('household.preferred_staff').forEach(function (staff) {
          var position = staff.get('position');

          if (position > deletedPosition) {
            staff.set('position', position - 1);
          }

          if (position == deletedPosition) {
            staffToRemove = staff;
          }
        });
        this.get('household.preferred_staff').removeObject(staffToRemove);
        this.savePreferredStaff();
      }
    }
  });

  _exports.default = _default;
});