define("frontend/models/preferred-staff", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    employee_id: _emberData.default.attr(''),
    user_id: _emberData.default.attr(''),
    staff_name: _emberData.default.attr('capitalized-string'),
    position: _emberData.default.attr(''),
    household: _emberData.default.belongsTo('household')
  });

  _exports.default = _default;
});