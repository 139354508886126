define("frontend/templates/components/week-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "QM2mRwmu",
    "block": "{\"symbols\":[\"day\"],\"statements\":[[4,\"each\",[[24,[\"weekDaysWithSelected\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"week-select/day-select\",null,[[\"day\",\"click\",\"active\"],[[23,1,[\"date\"]],[28,\"action\",[[23,0,[]],\"clickDay\",[23,1,[\"date\"]]],null],[23,1,[\"isSelected\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/week-select.hbs"
    }
  });

  _exports.default = _default;
});