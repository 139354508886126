define("frontend/components/payments-widget/payments-table-payments-group", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    tagName: '',
    store: inject.service(),
    sortBy: 'date',
    sortAsc: false,
    paymentsAndRefunds: [],
    extraHeaders: [],
    reachedInfinity: false,
    showProcessingFee: computed('extraHeaders.[]', function () {
      return this.get('extraHeaders').includes('processing fee');
    }),
    showNetSales: computed('extraHeaders.[]', function () {
      return this.get('extraHeaders').includes('net sales');
    }),
    showAddTip: computed('extraHeaders.[]', function () {
      return this.get('extraHeaders').includes('add tip');
    }),
    showNetTip: computed('extraHeaders.[]', function () {
      return this.get('showAddTip') && this.get('extraHeaders').includes('processing fee');
    }),
    sortedPaymentsAndRefunds: computed.sort('paymentsAndRefunds', ['paymentsAndRefunds.[]', 'sortBy', 'sortAsc'], function (a, b) {
      return this.get('sortByFunctions')[this.get('sortBy')].bind(this)(a, b);
    }),
    sortByFunctions: computed('sortBy', function () {
      return {
        'date': this.sortByDate,
        'client': this.sortByClient,
        'method': this.sortByPaymentMethod,
        'collected': this.sortByCollected,
        'processing': this.sortByProcessing,
        'netSales': this.sortByNetSales,
        'tips': this.sortByTips,
        'addTips': this.sortByGrossTips
      };
    }),
    sortByDate: function sortByDate(x, y) {
      if (this.get('sortAsc')) {
        return moment(x.get('payment_date')) - moment(y.get('payment_date'));
      } else {
        return moment(y.get('payment_date')) - moment(x.get('payment_date'));
      }
    },
    sortByClient: function sortByClient(x, y) {
      if (this.get('sortAsc')) {
        return x.get('client_name') < y.get('client_name') ? 1 : -1;
      } else {
        return x.get('client_name') < y.get('client_name') ? -1 : 1;
      }
    },
    sortByPaymentMethod: function sortByPaymentMethod(x, y) {
      if (this.get('sortAsc')) {
        return x.get('payment_method') < y.get('payment_method') ? 1 : -1;
      } else {
        return x.get('payment_method') < y.get('payment_method') ? -1 : 1;
      }
    },
    sortByCollected: function sortByCollected(x, y) {
      if (this.get('sortAsc')) {
        return parseFloat(x.get('amount')) < parseFloat(y.get('amount')) ? -1 : 1;
      } else {
        return parseFloat(x.get('amount')) < parseFloat(y.get('amount')) ? 1 : -1;
      }
    },
    sortByProcessing: function sortByProcessing(x, y) {
      if (this.get('sortAsc')) {
        return parseFloat(x.get('processing_fee')) < parseFloat(y.get('processing_fee')) ? -1 : 1;
      } else {
        return parseFloat(x.get('processing_fee')) < parseFloat(y.get('processing_fee')) ? 1 : -1;
      }
    },
    sortByNetSales: function sortByNetSales(x, y) {
      if (this.get('sortAsc')) {
        return parseFloat(x.get('net_sales')) < parseFloat(y.get('net_sales')) ? -1 : 1;
      } else {
        return parseFloat(x.get('net_sales')) < parseFloat(y.get('net_sales')) ? 1 : -1;
      }
    },
    sortByTips: function sortByTips(x, y) {
      if (this.get('sortAsc')) {
        return parseFloat(x.get('net_tip')) < parseFloat(y.get('net_tip')) ? -1 : 1;
      } else {
        return parseFloat(x.get('net_tip')) < parseFloat(y.get('net_tip')) ? 1 : -1;
      }
    },
    sortByGrossTips: function sortByGrossTips(x, y) {
      if (this.get('sortAsc')) {
        return parseFloat(x.get('tip_amount')) < parseFloat(y.get('tip_amount')) ? -1 : 1;
      } else {
        return parseFloat(x.get('tip_amount')) < parseFloat(y.get('tip_amount')) ? 1 : -1;
      }
    },
    paymentMethodImage: function paymentMethodImage(method) {
      return "assets/images/".concat(method, ".png");
    },
    actions: {
      infinityLoad: function infinityLoad() {
        var _this = this;

        var date = this.get('startDate');
        var paymentType = this.get('paymentType');

        if (!date || this.get('isFetching')) {
          return;
        }

        this.set('isFetching', true);
        this.get('store').query('invoice-payment', {
          date: date,
          payment_type: paymentType
        }).then(function (payments) {
          _this.get('store').query('invoice-payment-refund', {
            date: date,
            payment_type: paymentType
          }).then(function (refunds) {
            var paymentsAndRefunds = [];
            payments.forEach(function (payment) {
              return paymentsAndRefunds.push(payment);
            });
            refunds.forEach(function (refund) {
              return paymentsAndRefunds.push(refund);
            });

            _this.set('paymentsAndRefunds', paymentsAndRefunds);

            _this.set('reachedInfinity', true);

            _this.set('isFetching', false);
          });
        });
      }
    }
  });

  _exports.default = _default;
});