define("frontend/templates/components/add-staff-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rNlLJPIx",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"h1\",true],[10,\"class\",\"header-text\"],[8],[0,\"\\n  \"],[14,1],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",false],[12,\"class\",\"cancel-add-employee\"],[12,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[3,\"action\",[[23,0,[]],\"cancelAddEmployee\"]],[8],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/add-staff-header.hbs"
    }
  });

  _exports.default = _default;
});