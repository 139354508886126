define("frontend/utils/phone-number-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MOBILE = '1';
  var WORK = '2';
  var HOME = '3';
  var OTHER = '4';
  var _default = {
    MOBILE: MOBILE,
    WORK: WORK,
    HOME: HOME,
    OTHER: OTHER
  };
  _exports.default = _default;
});