define("frontend/routes/edit-recurring-appointment-group/index", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    model: function model() {
      return this._super.apply(this, arguments);
    },
    getRepeatWeekDays: function getRepeatWeekDays(recurringAppointments, datesInPast) {
      var recurringStartDate = null;

      if (datesInPast) {
        recurringStartDate = moment().startOf('week');
      } else {
        recurringStartDate = moment(recurringAppointments.sortBy('start_date').get('firstObject').get('start_date')).startOf('week');
      }

      return recurringAppointments.map(function (recurringAppointment) {
        var startDate = moment(recurringAppointment.get('start_date'));
        var day = startDate.day();
        return recurringStartDate.day(day).format('YYYY-MM-DD');
      }).uniq();
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var recurringAppointmentGroup = model.recurringAppointmentGroup;

      if (!recurringAppointmentGroup) {
        return;
      }

      controller.set('showErrors', false);
      controller.set('changesStartInFuture', true);
      controller.set('appointmentDateSidePanelOpen', false);
      controller.set('endSidePanelOpen', false);
      controller.set('startSidePanelOpen', false);
      controller.set('sidePanelAppointment', null);
      controller.set('activeAppointment', null);
      controller.set('deletedRecurringAppointments', []);
      controller.set('editingExistingAppointment', false);
      controller.set('originalRecurringAppointmentCount', recurringAppointmentGroup.get('recurring_appointments.length'));
      var firstRecurringAppointment = recurringAppointmentGroup.get('recurring_appointments').sortBy('start_date').get('firstObject');

      if (!firstRecurringAppointment) {
        return;
      }

      if (this.anyDateStartsInPast(recurringAppointmentGroup.get('recurring_appointments').mapBy('start_date'))) {
        controller.set('startDate', null);
        var selectedRepeatWeekDays = this.getRepeatWeekDays(recurringAppointmentGroup.get('recurring_appointments'), true);
        controller.set('selectedRepeatWeekDays', selectedRepeatWeekDays);
        controller.set('originalDaysOfWeek', selectedRepeatWeekDays.map(function (day) {
          return moment(day).day();
        }).sort());
      } else {
        controller.set('changesStartInFuture', false);
        controller.set('startDate', moment(firstRecurringAppointment.get('start_date')).format('YYYY-MM-DD'));

        var _selectedRepeatWeekDays = this.getRepeatWeekDays(recurringAppointmentGroup.get('recurring_appointments'), false);

        controller.set('selectedRepeatWeekDays', _selectedRepeatWeekDays);
        controller.set('originalDaysOfWeek', _selectedRepeatWeekDays.map(function (day) {
          return moment(day).day();
        }).sort());
      }

      var endDate = firstRecurringAppointment.get('end_date');

      if (endDate) {
        controller.set('selectedEndDateOption', 'date');
        controller.set('endDate', endDate);
        controller.set('originalEndDate', endDate);
      } else {
        controller.set('selectedEndDateOption', 'never');
        controller.set('endDate', moment().format('YYYY-MM-DD'));
      }

      controller.set('selectedRepeatOption', firstRecurringAppointment.get('frequency'));
    },
    anyDateStartsInPast: function anyDateStartsInPast(dates) {
      return dates.any(this.startsInPast);
    },
    startsInPast: function startsInPast(date) {
      return moment(date, 'YYYY-MM-DD').startOf('day').isSameOrBefore(moment().startOf('day'));
    }
  });

  _exports.default = _default;
});