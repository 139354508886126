define("frontend/components/client-profile-contact-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    name: null,
    website: null,
    doctorName: null,
    fullAddress: null,
    email: null,
    phone: null,
    actions: {
      tapOnDirections: function tapOnDirections() {
        var fullAddress = this.get('fullAddress');
        var encodedAddress = encodeURIComponent(fullAddress);
        window.location.href = "https://maps.google.com?q=".concat(encodedAddress);
      },
      tapOnEmail: function tapOnEmail() {
        var email = this.get('email');
        window.location.href = "mailto:".concat(email);
      },
      tapOnPhone: function tapOnPhone() {
        var phone = this.get('phone');
        window.location.href = "tel:".concat(phone);
      }
    }
  });

  _exports.default = _default;
});