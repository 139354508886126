define("frontend/templates/components/appointment-group-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ziO2QtTc",
    "block": "{\"symbols\":[\"appointmentGroup\"],\"statements\":[[4,\"each\",[[24,[\"appointmentGroups\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"recurring\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"recurring-service-box\",null,[[\"selected\",\"recurring\",\"click\",\"service\",\"draggablePets\",\"showTime\"],[[28,\"eq\",[[24,[\"selectedTime\"]],[23,1,[\"starts_at_time\"]]],null],true,[28,\"action\",[[23,0,[]],\"selectTime\",[23,1,[\"starts_at_time\"]]],null],[23,1,[]],false,true]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"unless\",[[28,\"eq\",[[23,1,[\"sortedGroupedAppointmentsWithoutCanceled\",\"length\"]],0],null]],null,{\"statements\":[[0,\"      \"],[1,[28,\"service-box\",null,[[\"selected\",\"click\",\"service\",\"draggablePets\",\"showTime\"],[[28,\"eq\",[[24,[\"selectedTime\"]],[23,1,[\"starts_at_time\"]]],null],[28,\"action\",[[23,0,[]],\"selectTime\",[23,1,[\"starts_at_time\"]]],null],[23,1,[]],false,true]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/appointment-group-select.hbs"
    }
  });

  _exports.default = _default;
});