define("frontend/components/compensation-bar", ["exports", "frontend/mixins/money-formater"], function (_exports, _moneyFormater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend(_moneyFormater.default, {
    classNames: ['compensation-bar'],
    media: inject.service(),
    onlyTip: false,
    apptValue: 0,
    tipValue: 0,
    total: 0,
    totalWidth: computed('totalComp', function () {
      var maxWidth = this.get('media.isMobile') ? 0.65 : 0.8;
      return parseFloat(this.get('totalComp')) * maxWidth / this.get('total') * 100;
    }),
    apptWidth: computed('apptValue', function () {
      return parseFloat(this.get('apptValue')) / this.get('totalComp') * 100;
    }),
    tipWidth: computed('tipValue', function () {
      return parseFloat(this.get('tipValue')) / this.get('totalComp') * 100;
    }),
    totalComp: computed('apptValue', 'tipValue', function () {
      return (this.get('apptValue') || 0) + (this.get('tipValue') || 0);
    }),
    label: computed('apptValue', 'tipValue', 'totalComp', function () {
      var label = this.formatMoney(this.get('totalComp'));

      if (this.get('tipValue') > 0 && !this.get('onlyTip')) {
        label += "<span class=\"tip-value\"> (incl. ".concat(this.formatMoney(this.get('tipValue')), " tip)</span>");
      }

      return label;
    })
  });

  _exports.default = _default;
});