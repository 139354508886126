define("frontend/routes/edit-client/primary-contact", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    currentUser: Ember.inject.service(),
    model: function model() {
      var household = this._super.apply(this, arguments);

      return household;
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      var owner = model.get('owner');
      var phoneNumbers = owner.get('phone_numbers');

      if (phoneNumbers.get('length') == 0) {
        var defaultPhone = this.store.createRecord('phone-number');
        owner.get('phone_numbers').pushObject(defaultPhone);
      }

      var addresses = owner.get('addresses');

      if (addresses.get('length') == 0) {
        var user = this.get('currentUser.user');
        var state = user.get('company.state') || user.get('client_accounts.firstObject.company_state');
        var country = user.get('company.country') || user.get('client_accounts.firstObject.country');
        var defaultAddress = this.store.createRecord('address', {
          state: state,
          country: country
        });
        owner.get('addresses').pushObject(defaultAddress);
      }

      var emails = owner.get('emails');
      owner.set('emails', emails.sortBy('email_type').reverse());
    }
  });

  _exports.default = _default;
});