define("frontend/templates/components/invoice-tag", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Wf57QjGe",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"void\"]]],null,{\"statements\":[[0,\"  Void\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"draft\"]]],null,{\"statements\":[[0,\"  Draft\\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"partial\"]]],null,{\"statements\":[[4,\"if\",[[24,[\"invoiceReceipt\"]]],null,{\"statements\":[[0,\"    Remaining Due\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Partial\\n\"]],\"parameters\":[]}]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"due\"]]],null,{\"statements\":[[0,\"  Due\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  Paid\\n\"]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/invoice-tag.hbs"
    }
  });

  _exports.default = _default;
});