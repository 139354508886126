define("frontend/templates/components/mobile-repeating-appointments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "H0iS2Fcm",
    "block": "{\"symbols\":[\"group\"],\"statements\":[[4,\"if\",[[24,[\"recurringAppointmentGroups\",\"isFulfilled\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"appointment-times\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"recurringAppointmentGroups\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"appointment-times/recurring-appointment-group\",null,[[\"editing\",\"readOnly\",\"showPetNames\",\"clickAppointment\",\"removeAppointment\",\"recurringAppointmentGroup\"],[[24,[\"editing\"]],[24,[\"readOnly\"]],[24,[\"showPetNames\"]],[28,\"action\",[[23,0,[]],\"clickAppointment\",[23,1,[]]],null],[28,\"action\",[[23,0,[]],\"removeAppointment\",[23,1,[]]],null],[23,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[4,\"doo-error\",null,[[\"smallDoo\"],[true]],{\"statements\":[[0,\"        You haven't added any repeating appointments!\\n\"],[4,\"if\",[[24,[\"currentUser\",\"user\",\"isAdmin\"]]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[8],[0,\"\\n\"],[4,\"link-to\",null,[[\"query\",\"route\"],[[28,\"hash\",null,[[\"filter\",\"selectedHouseholdId\",\"backRoute\",\"backHouseholdId\"],[[24,[\"householdOwner\",\"fullName\"]],[24,[\"householdOwner\",\"household\",\"id\"]],\"client-list.household.household-user\",[24,[\"householdOwner\",\"household\",\"id\"]]]]],\"add-appointment\"]],{\"statements\":[[0,\"              add appointment\\n\"]],\"parameters\":[]},null],[0,\"          \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[22,\"loading-spinner\"],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/mobile-repeating-appointments.hbs"
    }
  });

  _exports.default = _default;
});