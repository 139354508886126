define("frontend/templates/components/activity-log-side-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "aOGLYH/b",
    "block": "{\"symbols\":[\"logDate\",\"log\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"activity-log-header\"],[8],[0,\"\\n  Recent activity\\n  \"],[7,\"span\",true],[10,\"class\",\"cancel\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],\"closeActivityLog\"],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[1,[28,\"inline-svg\",[\"assets/images/cancel.svg\"],null],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"activity-log-search-bar\"],[8],[0,\"\\n  \"],[1,[28,\"search-input\",null,[[\"graySearchIcon\",\"value\",\"debounce\",\"placeholder\"],[true,[24,[\"activityLogs\",\"filter\"]],true,\"Search by human or pet name\"]]],false],[0,\"\\n\"],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"infinite-scroller\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"activity-log-content\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"logDates\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"activity-log-date-header\"],[8],[0,\"\\n        \"],[1,[28,\"format-date-with-today\",[[23,1,[\"date\"]]],null],true],[0,\"\\n      \"],[9],[0,\"\\n\"],[4,\"each\",[[23,1,[\"logs\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"activity-log-item\",null,[[\"log\",\"unreadAfter\"],[[23,2,[]],[24,[\"unreadAfter\"]]]]],false],[0,\"\\n\"]],\"parameters\":[2]},null]],\"parameters\":[1]},null],[4,\"if\",[[24,[\"loadingMore\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"ppb-button\",null,[[\"color\",\"onclick\",\"text\"],[\"red-white-border\",[28,\"action\",[[23,0,[]],\"loadMoreLogs\"],null],\"Load more\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/activity-log-side-panel.hbs"
    }
  });

  _exports.default = _default;
});