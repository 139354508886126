define("frontend/templates/components/invoices-widget/sent-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jvFUziwD",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"header-text\"],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showDropdown\"]]],null],true],null]],null]],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n  \"],[1,[22,\"headerText\"],false],[7,\"div\",true],[10,\"class\",\"dropdown-arrow down\"],[8],[9],[0,\"\\n\"],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showDropdown\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"invoices-widget/sent-header-dropdown\",null,[[\"close\",\"selectOption\"],[[28,\"action\",[[23,0,[]],[28,\"mut\",[[24,[\"showDropdown\"]]],null],false],null],[28,\"action\",[[23,0,[]],\"selectOption\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/invoices-widget/sent-header.hbs"
    }
  });

  _exports.default = _default;
});