define("frontend/templates/components/company-logo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "2yLWOyeF",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"company\",\"logo_url\"]]],null,{\"statements\":[[0,\"    \"],[7,\"img\",true],[11,\"src\",[24,[\"company\",\"logo_url\"]]],[8],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"logo-container\"],[8],[0,\"\\n    \"],[7,\"img\",true],[10,\"class\",\"placeholder\"],[11,\"src\",[22,\"placeholder\"]],[8],[9],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/company-logo.hbs"
    }
  });

  _exports.default = _default;
});