define("frontend/controllers/add-appointment/index", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Controller.extend({
    addAppointmentData: inject.service(),
    currentUser: inject.service(),
    selectedPetValues: computed.alias('addAppointmentData.selectedPetValues'),
    queryParams: ['selectedHouseholdId', 'filter'],
    selectedHouseholdId: null,
    showErrorFlash: false,
    filter: '',
    loading: false,
    usersValueLabel: computed('model', 'model.@each.fullName', function () {
      return this.get('model').map(function (user) {
        return {
          value: user.get('id'),
          label: user.get('fullName')
        };
      });
    }),
    selectedHouseholdPetOptions: computed('selectedHousehold.pets.@each.{name,avatarOrPlaceholder,id}', function () {
      var household = this.get('selectedHousehold');

      if (Ember.isEmpty(household)) {
        return [];
      }

      return household.get('pets').map(function (pet) {
        var label = pet.get('name');
        var thumbnail = pet.get('avatarOrPlaceholder');
        var value = pet;
        return {
          label: label,
          thumbnail: thumbnail,
          value: value
        };
      });
    }),
    removeNewRecords: function removeNewRecords() {
      this.store.peekAll('address').filter(function (a) {
        return a.get('id') == undefined;
      }).forEach(function (a) {
        return a.deleteRecord();
      });
      this.store.peekAll('pet').filter(function (p) {
        return p.get('id') == undefined;
      }).forEach(function (p) {
        return p.deleteRecord();
      });
    },
    reloadDirtyRecords: function reloadDirtyRecords() {
      this.store.peekAll('address').filterBy('hasDirtyAttributes').forEach(function (a) {
        return a.reload();
      });
      this.store.peekAll('pet').filterBy('hasDirtyAttributes').forEach(function (p) {
        return p.reload();
      });
    },
    actions: {
      selectedPetValuesChanged: function selectedPetValuesChanged(values) {
        this.set('selectedPetValues', values);
        this.set('nextDisabled', Ember.isEmpty(this.get('selectedPetValues')));
      },
      focusSearchInput: function focusSearchInput() {
        (0, _jquery.default)('.search-input input').focus();
      },
      selectHousehold: function selectHousehold(household) {
        this.set('loading', true);
        this.set('showPets', false);
        this.set('showAddresses', false);
        this.set('selectedHousehold', household);

        if (!household) {
          this.set('loading', false);
          return;
        }

        var numPets = household.get('pets.length');
        var numAddresses = household.get('owner.addresses.length');

        if (numPets == 0 || numAddresses == 0) {
          if (numPets == 0) {
            this.send('addPet');
            this.set('showPets', true);
          }

          if (numAddresses == 0) {
            var state = this.get('currentUser.user.company.state');
            household.get('owner.addresses').pushObject(this.store.createRecord('address', {
              state: state
            }));
            this.set('showAddresses', true);
          }

          this.set('showAppointmentModal', true);
        } else if (numPets == 1) {
          this.set('selectedPetValues', household.get('pets'));
          this.send('next');
        } else {
          this.set('loading', false);
          Ember.run.scheduleOnce('afterRender', function () {
            window.scrollTo(0, 0);
          });
        }
      },
      addPet: function addPet() {
        var _this = this;

        var household = this.get('selectedHousehold');

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(household.get('id'), "/get_companies"),
          method: 'GET',
          data: {
            id: household.get('id')
          }
        }).done(function (response) {
          var pet = _this.store.createRecord('pet');

          pet.set('company_notes', response.company_notes);
          household.get('pets').pushObject(pet);
        });
      },
      closeAppointmentInfoModal: function closeAppointmentInfoModal() {
        this.removeNewRecords.call(this);
        this.set('selectedHousehold', null);
        this.set('showAppointmentModal', false);
        this.set('loading', false);
      },
      saveHousehold: function saveHousehold() {
        var _this2 = this;

        var userPromise = this.get('selectedHousehold.owner').save();
        var petPromises = this.get('selectedHousehold.pets').map(function (pet) {
          return pet.save();
        });
        var promises = [userPromise].concat(_toConsumableArray(petPromises));
        return Ember.RSVP.Promise.all(promises).then(function () {
          _this2.removeNewRecords.call(_this2);

          _this2.reloadDirtyRecords.call(_this2);

          _this2.send('selectHousehold', _this2.get('selectedHousehold'));
        });
      },
      clearHouseholdId: function clearHouseholdId() {
        this.set('selectedHouseholdId', null);
      },
      transitionToAddClient: function transitionToAddClient() {
        this.transitionToRoute('add-client.client-search');
      }
    }
  });

  _exports.default = _default;
});