define("frontend/templates/components/multi-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C8kMbyNG",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"multi-select/select-option\",null,[[\"thumbnail\",\"label\",\"value\",\"selected\",\"selectOption\",\"deselectOption\"],[[23,1,[\"thumbnail\"]],[23,1,[\"label\"]],[23,1,[\"value\"]],[28,\"contains\",[[23,1,[\"value\"]],[24,[\"selectedValues\"]]],null],[28,\"action\",[[23,0,[]],\"selectOption\"],null],[28,\"action\",[[23,0,[]],\"deselectOption\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/multi-select.hbs"
    }
  });

  _exports.default = _default;
});