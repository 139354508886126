define("frontend/templates/components/confirmation-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ql0r2upi",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"ppb-modal\",null,[[\"customContainerClassNames\"],[\"pp-confirmation-modal\"]],{\"statements\":[[0,\"  \"],[1,[28,\"inline-svg\",[[24,[\"icon\"]]],null],false],[0,\"\\n  \"],[7,\"header\",true],[8],[1,[22,\"title\"],false],[9],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"clarification\"],[8],[0,\"\\n    \"],[14,1],[0,\"\\n  \"],[9],[0,\"\\n  \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red\",[24,[\"primaryText\"]],[24,[\"primaryAction\"]]]]],false],[0,\"\\n  \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red-white\",[24,[\"secondaryText\"]],[24,[\"secondaryAction\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/confirmation-modal.hbs"
    }
  });

  _exports.default = _default;
});