define("frontend/routes/add-appointment/index", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = _baseRoute.default.extend({
    queryParams: {
      filter: {
        refreshModel: true,
        replace: true
      },
      selectedHouseholdId: {}
    },
    infinity: inject.service(),
    model: function model(_ref) {
      var filter = _ref.filter,
          selectedHouseholdId = _ref.selectedHouseholdId;
      this.set('selectedHouseholdId', selectedHouseholdId);
      return this.get('infinity').model('household', {
        perPage: 10,
        startingPage: 1,
        filter: filter
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('loading', false);
      controller.set('showErrorFlash', false);

      if (this.get('selectedHouseholdId')) {
        this.store.findRecord('household', this.get('selectedHouseholdId')).then(function (household) {
          controller.send('selectHousehold', household);
        });
      } else {
        controller.send('selectHousehold', null);
      }
    },
    actions: {
      next: function next() {
        this.transitionTo('add-appointment.details');
      },
      loading: function loading() {
        if (this.get('controller')) {
          this.set('controller.loading', true);
          return false;
        } else {
          return true;
        }
      }
    }
  });

  _exports.default = _default;
});