define("frontend/routes/staff/index", ["exports", "frontend/routes/base-route"], function (_exports, _baseRoute) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    queryParams: {
      searchFilter: {
        replace: true
      }
    },
    model: function model() {
      return this.store.query('employee', {}, {
        reload: true
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.notifyPropertyChange('employeeFilter');
      controller.set('compensationExportDisabled', false);
    },
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });

  _exports.default = _default;
});