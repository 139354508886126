define("frontend/templates/components/img-inner-border", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "jIUogY8j",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[24,[\"tooltipText\"]]],null,{\"statements\":[[4,\"tooltip-question-mark\",null,[[\"color\",\"tooltipText\"],[[24,[\"tooltipColor\"]],[24,[\"tooltipText\"]]]],{\"statements\":[[0,\"    \"],[7,\"img\",true],[11,\"src\",[22,\"src\"]],[8],[9],[0,\"\\n    \"],[7,\"div\",true],[11,\"style\",[22,\"myStyle\"]],[10,\"class\",\"border\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showBirthday\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"birthday-icon\"],[8],[0,\"\\n        \"],[7,\"img\",true],[10,\"src\",\"assets/images/birthday-icon.svg\"],[8],[9],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[7,\"img\",true],[11,\"src\",[22,\"src\"]],[8],[9],[0,\"\\n  \"],[7,\"div\",true],[11,\"style\",[22,\"myStyle\"]],[10,\"class\",\"border\"],[8],[9],[0,\"\\n\"],[4,\"if\",[[24,[\"showBirthday\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"birthday-icon\"],[8],[0,\"\\n      \"],[7,\"img\",true],[10,\"src\",\"assets/images/birthday-icon.svg\"],[8],[9],[0,\"\\n    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/img-inner-border.hbs"
    }
  });

  _exports.default = _default;
});