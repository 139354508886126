define("frontend/templates/components/doo-error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "XcqDD++x",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"img\",true],[10,\"class\",\"doo\"],[10,\"src\",\"assets/images/staff/doohand@3x.png\"],[8],[9],[0,\"\\n\"],[7,\"div\",true],[10,\"class\",\"message\"],[8],[14,1],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/doo-error.hbs"
    }
  });

  _exports.default = _default;
});