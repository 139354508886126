define("frontend/routes/add-appointment/times", ["exports", "frontend/mixins/add-appointment", "frontend/mixins/transition-back-route", "frontend/config/environment", "frontend/utils/scroll-util", "frontend/routes/base-route", "moment", "jquery"], function (_exports, _addAppointment, _transitionBackRoute, _environment, _scrollUtil, _baseRoute, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = _baseRoute.default.extend(_transitionBackRoute.default, _addAppointment.default, {
    saving: computed.alias('addAppointmentData.saving'),
    errorHandler: inject.service(),
    redirect: function redirect() {
      this._super.apply(this, arguments);

      if (this.get('addAppointmentData.selectedPetValues.length') === 0) {
        this.transitionTo('add-appointment.index', {
          queryParams: {
            selectedHouseholdId: null,
            filter: ''
          }
        });
      }
    },
    model: function model() {
      var care_providers = this.store.findAll('care-provider', {
        reload: true
      }).then(function (cps) {
        return cps.sortBy('getFirstName', 'last_name');
      });
      var household = null;

      if (this.get('addAppointmentData.selectedPetValues.length') > 0) {
        household = this.store.findRecord('household', this.get('addAppointmentData.selectedPetValues.firstObject.household.id'));
      }

      return Ember.RSVP.hash({
        household: household,
        care_providers: care_providers,
        offered_services: this.store.query('offered-service', {
          filter: {
            active: true
          }
        }, {
          reload: true
        }),
        service_categories: this.store.query('service-category', {}, {
          reload: true
        })
      });
    },
    saveSingleAppointments: function saveSingleAppointments() {
      var pet_ids = this.get('addAppointmentData.selectedPetValues').mapBy('id');
      var dateAppointments = this.get('addAppointmentData.dateAppointments');
      var frequency = 'onetime';
      var promises = [];
      var url = "".concat(_environment.default.APP.host, "/api/v1/appointment_schedulings");
      var appointments = [];
      dateAppointments.forEach(function (dateAppointment) {
        var careProvider = dateAppointment.careProvider,
            offered_service = dateAppointment.offered_service,
            date = dateAppointment.date,
            time = dateAppointment.time,
            special_instructions = dateAppointment.special_instructions,
            timeFrameStart = dateAppointment.timeFrameStart,
            timeFrameEnd = dateAppointment.timeFrameEnd,
            setByCustomWindow = dateAppointment.setByCustomWindow,
            positionsPreviousPets = dateAppointment.positionsPreviousPets,
            chargeForLateBooking = dateAppointment.chargeForLateBooking,
            addOnServices = dateAppointment.addOnServices;
        var care_provider_id = careProvider.get('id');
        var offered_service_id = offered_service.get('id');
        var add_on_services = addOnServices.map(function (addOnService) {
          return {
            offered_service_id: addOnService.get('offered_service.id'),
            pet_ids: addOnService.get('petIds')
          };
        });
        var appointment = {
          pet_ids: pet_ids,
          care_provider_id: care_provider_id,
          frequency: frequency,
          offered_service_id: offered_service_id,
          date: date,
          positionsPreviousPets: positionsPreviousPets,
          time: time,
          time_frame_start_time: timeFrameStart,
          time_frame_end_time: timeFrameEnd,
          set_by_custom_window: setByCustomWindow,
          special_instructions: special_instructions,
          charge_for_late_booking: chargeForLateBooking,
          add_on_services: add_on_services
        };
        appointments.pushObject(appointment);
      });
      promises.push(_jquery.default.ajax({
        url: url,
        method: 'POST',
        data: {
          appointments: appointments
        }
      }));
      return Ember.RSVP.all(promises).then(this.postSaveSingleAppointments.bind(this));
    },
    postSaveSingleAppointments: function postSaveSingleAppointments() {
      var _this = this;

      return new Ember.RSVP.Promise(function (resolve) {
        var firstAppointment = _this.get('addAppointmentData.dateAppointments').sortBy('start_date').get('firstObject');

        var date = firstAppointment.get('date');
        var careProviderId = firstAppointment.get('careProvider.id');
        resolve({
          careProviderId: careProviderId,
          date: date
        });
      });
    },
    saveRecurringAppointments: function saveRecurringAppointments() {
      var _this2 = this;

      var recurringAppointmentGroup = this.store.createRecord('recurring-appointment-group');
      var dateAppointments = this.get('addAppointmentData.dateAppointments');
      var pets = this.get('addAppointmentData.selectedPetValues');
      dateAppointments.forEach(function (dateAppointment) {
        var day_of_week = (0, _moment.default)(dateAppointment.date, 'YYYY-MM-DD').day();
        var start_date = null;

        if (_this2.get('repeatsMonthly')) {
          start_date = _this2.firstOfMonthFromDate(_this2.get('addAppointmentData.startDate'));
        } else {
          start_date = _this2.firstWeekdayFromDate(day_of_week, _this2.get('addAppointmentData.startDate'));
        }

        var end_date = _this2.get('addAppointmentData.selectedEndDateOption') === 'never' ? 'never' : _this2.get('addAppointmentData.endDate');
        var frequency = _this2.get('repeatsBiweekly') ? 'biweekly' : _this2.get('repeatsMonthly') ? 'monthly' : 'weekly';
        var positions_previous_pets = dateAppointment.positionsPreviousPets;
        var add_on_services = dateAppointment.addOnServices.map(function (addOnService) {
          return {
            offered_service_id: addOnService.get('offered_service.id'),
            pet_ids: addOnService.get('petIds')
          };
        });
        pets.forEach(function (pet) {
          var add_on_service_ids = add_on_services.filter(function (addOnService) {
            return addOnService.pet_ids.includes(pet.id);
          }).map(function (addOnService) {
            return addOnService.offered_service_id;
          });

          var recurringAppointment = _this2.store.createRecord('recurring-appointment', {
            start_date: start_date,
            careProvider: dateAppointment.careProvider,
            offered_service: dateAppointment.offered_service,
            preferred_start_time: dateAppointment.time,
            time_frame_start_time: dateAppointment.timeFrameStart,
            time_frame_end_time: dateAppointment.timeFrameEnd,
            set_by_custom_window: dateAppointment.setByCustomWindow,
            recurrence_ends_on_type: _this2.get('addAppointmentData.selectedEndDateOption'),
            end_date: end_date,
            frequency: frequency,
            day_of_week: day_of_week,
            positions_previous_pets: positions_previous_pets,
            pet: pet,
            add_on_service_ids: add_on_service_ids
          });

          recurringAppointmentGroup.get('recurring_appointments').pushObject(recurringAppointment);
        });
      });
      return recurringAppointmentGroup.save().then(this.postSaveRecurringAppointments.bind(this));
    },
    postSaveRecurringAppointments: function postSaveRecurringAppointments(response) {
      return new Ember.RSVP.Promise(function (resolve) {
        var firstRecurringAppointment = response.get('recurring_appointments').sortBy('start_date').get('firstObject');
        var date = firstRecurringAppointment.get('start_date');
        var careProviderId = firstRecurringAppointment.get('careProvider.id');
        response.get('recurring_appointments').filterBy('id', null).forEach(function (r) {
          return r.deleteRecord();
        });
        resolve({
          date: date,
          careProviderId: careProviderId
        });
      });
    },
    firstWeekdayFromDate: function firstWeekdayFromDate(dayOfWeek, date) {
      var startDate = (0, _moment.default)(date, 'YYYY-MM-DD');
      var momentDate = (0, _moment.default)(date, 'YYYY-MM-DD');
      var daysDiff = dayOfWeek - momentDate.day();

      if (daysDiff < 0 && !this.get('repeatsBiweekly')) {
        daysDiff += 7;
      }

      var weekDay = momentDate.add(daysDiff, 'days');
      var SUNDAY = 0;

      if (weekDay.isBefore(startDate.startOf('day')) && this.get('repeatsBiweekly')) {
        if (dayOfWeek === SUNDAY) {
          weekDay.add(1, 'week');
        } else {
          weekDay.add(2, 'weeks');
        }
      }

      return weekDay.format('YYYY-MM-DD');
    },
    firstOfMonthFromDate: function firstOfMonthFromDate(date) {
      var startDate = (0, _moment.default)(date, 'YYYY-MM-DD');

      if (startDate.date() == '1') {
        return date;
      } else {
        return startDate.add(1, 'month').startOf('month').format('YYYY-MM-DD');
      }
    },
    save: function save(savePromiseFn) {
      if (this.get('saving')) {
        return;
      }

      this.set('saving', true);
      return savePromiseFn();
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      this.get('addAppointmentData').set('dateAppointments', []);
      controller.set('activeAppointment', null);
      controller.set('editingExistingAppointment', false);
      controller.set('firstAppointmentCreated', false);
      controller.set('sidePanelAppointment', null);
      controller.set('showErrors', false);
      controller.set('saving', false);
      controller.set('showRequestOrApproveModal', false);
    },
    validate: function validate(controller) {
      controller.set('showErrors', true);

      if (controller.get('missingAppointments')) {
        _scrollUtil.default.scrollTopWithHeader('.assign-staff-date--error');

        return false;
      }

      return true;
    },
    requestAppointment: function requestAppointment() {
      var _this3 = this;

      var autoApprove = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
      var isValid = this.validate(this.get('controller'));

      if (!isValid || this.get('saving')) {
        return;
      }

      this.set('saving', true);
      var pet_ids = this.get('addAppointmentData.selectedPetValues').mapBy('id');
      var dateAppointments = this.get('addAppointmentData.dateAppointments');
      var details = {
        auto_approve: autoApprove,
        special_instructions: '',
        appointments: []
      };
      dateAppointments.forEach(function (dateAppointment) {
        var careProvider = dateAppointment.careProvider,
            offered_service = dateAppointment.offered_service,
            date = dateAppointment.date,
            time = dateAppointment.time,
            timeFrameStart = dateAppointment.timeFrameStart,
            timeFrameEnd = dateAppointment.timeFrameEnd,
            special_instructions = dateAppointment.special_instructions,
            setByCustomWindow = dateAppointment.setByCustomWindow,
            positionsPreviousPets = dateAppointment.positionsPreviousPets,
            chargeForLateBooking = dateAppointment.chargeForLateBooking,
            addOnServices = dateAppointment.addOnServices;
        var care_provider_id = careProvider.get('id');
        var offered_service_id = offered_service.get('id');
        var time_frame = {
          time: time,
          start_time: timeFrameStart,
          end_time: timeFrameEnd
        };
        var add_on_services = addOnServices.map(function (addOnService) {
          return {
            offered_service_id: addOnService.get('offered_service.id'),
            pet_ids: addOnService.get('petIds')
          };
        });
        details['special_instructions'] = special_instructions;
        details['appointments'].push({
          pet_ids: pet_ids,
          offered_service_id: offered_service_id,
          care_provider_id: care_provider_id,
          date: date,
          time_frame: time_frame,
          previous_pets: positionsPreviousPets,
          set_by_custom_window: setByCustomWindow,
          charge_for_late_booking: chargeForLateBooking,
          add_on_services: add_on_services
        });
      });
      var addAppointmentController = Ember.getOwner(this).lookup('controller:add-appointment');
      var backRoute = addAppointmentController.get('backRoute');
      var backHouseholdId = addAppointmentController.get('backHouseholdId');
      var backId = addAppointmentController.get('backId');

      _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/appointment_requests"),
        method: 'POST',
        data: {
          details: details
        }
      }).then(function () {
        _this3.set('saving', false);

        _this3.transitionBack(backRoute, backHouseholdId, backId);
      }).catch(function (e) {
        if (e.message != 'TransitionAborted') {
          _this3.get('errorHandler').handle(e);

          _this3.set('saving', false);
        }
      });
    },
    actions: {
      finish: function finish() {
        var _this4 = this;

        var isValid = this.validate(this.get('controller'));

        if (!isValid) {
          return;
        }

        var saveFn = this.get('repeatsNever') ? this.saveSingleAppointments : this.saveRecurringAppointments;
        var addAppointmentController = Ember.getOwner(this).lookup('controller:add-appointment');
        var backRoute = addAppointmentController.get('backRoute');
        var backHouseholdId = addAppointmentController.get('backHouseholdId');
        var backId = addAppointmentController.get('backId');
        this.save(saveFn.bind(this)).then(function () {
          return _this4.transitionBack(backRoute, backHouseholdId, backId);
        }).catch(function (e) {
          if (e.message != 'TransitionAborted') {
            _this4.get('errorHandler').handle(e);

            _this4.set('saving', false);
          }
        });
      },
      requestAndApprove: function requestAndApprove() {
        this.requestAppointment(true);
      },
      request: function request() {
        this.requestAppointment(false);
      },
      back: function back() {
        this.transitionTo('add-appointment.details');
      }
    }
  });

  _exports.default = _default;
});