define("frontend/controllers/profile/account", ["exports", "ember-cp-validations", "jquery"], function (_exports, _emberCpValidations, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var Validations = (0, _emberCpValidations.buildValidations)({
    'old_password': [(0, _emberCpValidations.validator)('presence', {
      ignoreBlank: true,
      presence: true
    })],
    'password': [(0, _emberCpValidations.validator)('presence', {
      ignoreBlank: true,
      presence: true
    })],
    'password_confirmation': [(0, _emberCpValidations.validator)('confirmation', {
      on: 'password',
      message: 'Passwords do not match'
    }), (0, _emberCpValidations.validator)('length', {
      min: 8
    })]
  });

  var _default = Ember.Controller.extend(Validations, {
    canSave: computed.readOnly('validations.isValid'),
    newEmail: '',
    actions: {
      resetPassword: function resetPassword() {
        var _this = this;

        this.set('didValidate', true);

        if (!this.get('canSave')) {
          return;
        }

        _jquery.default.ajax({
          url: "/api/v1/users/".concat(this.get('model.id'), "/reset_password"),
          method: 'PUT',
          data: {
            user: {
              password: this.get('password'),
              old_password: this.get('old_password'),
              password_confirmation: this.get('password_confirmation')
            }
          }
        }).done(function () {
          _this.set('didValidate', false);

          _this.set('old_password', null);

          _this.set('password', null);

          _this.set('password_confirmation', null);

          _this.transitionToRoute('profile.edit-about-me').then(function () {
            Ember.getOwner(_this).lookup('controller:application').send('notify', {
              message: 'Your password has been successfully reset!',
              type: 'success'
            });
          });
        }).fail(function (data) {
          Ember.getOwner(_this).lookup('controller:application').send('notify', {
            message: JSON.parse(data.responseText).errors[0],
            type: 'user-error'
          });
        });
      }
    }
  });

  _exports.default = _default;
});