define("frontend/components/custom-pricing-modal/edit-price", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    household: null,
    offeredService: computed.readOnly('offeredServicePrice.offeredService'),
    clientPrice: computed.alias('offeredServicePrice.offeredServiceClientPrice'),
    hasIncrementalPrice: computed.notEmpty('clientPrice.incremental_price'),
    hasPrice: computed.notEmpty('clientPrice.price'),
    showDollarIncrementalPrice: computed.or('focusIncrementalPrice', 'hasIncrementalPrice'),
    showDollarPrice: computed.or('focusPrice', 'hasPrice'),
    effectiveDates: computed('currentUser.user', function () {
      var start_date = moment(this.get('household.billing_period_start_date')).format('M/D');

      if (start_date == 'Invalid date') {
        return [];
      }

      var end_date = moment(this.get('household.billing_period_end_date')).format('M/D');
      var next_start_date = moment(this.get('household.next_billing_period_start_date')).format('M/D');
      var next_end_date = moment(this.get('household.next_billing_period_end_date')).format('M/D');
      return [{
        value: 'current',
        label: "Current Bill (".concat(start_date, " - ").concat(end_date, ")")
      }, {
        value: 'next',
        label: "Next Bill (".concat(next_start_date, " - ").concat(next_end_date, ")")
      }];
    }),
    effectiveDateClarify: computed('selectedEffectiveDate', function () {
      if (this.get('selectedEffectiveDate.value') == 'next') {
        var start_date = this.get('household.next_billing_period_start_date');
        var end_date = this.get('household.next_billing_period_end_date');
        return "Your new prices will apply starting the next billing period ".concat(moment(start_date).format('M/D'), " - ").concat(moment(end_date).format('M/D'), ". The current bill will still be on your old prices.");
      } else {
        var _end_date = this.get('household.billing_period_end_date');

        return "Your new prices will apply starting with your current bill scheduled to close on ".concat(moment(_end_date).format('M/D'), ".");
      }
    }),
    selectedEffectiveDate: computed('effectiveDates.[]', 'clientPrice.effective_date', function () {
      return this.get('effectiveDates').filterBy('value', this.get('clientPrice.effective_date')).get('firstObject');
    }),
    actions: {
      changePrice: function changePrice(price) {
        this.set('clientPrice.price', price.replace('$', ''));
      },
      changeIncrementalPrice: function changeIncrementalPrice(incrementalPrice) {
        this.set('clientPrice.incremental_price', incrementalPrice.replace('$', ''));
      },
      changeEffectiveDate: function changeEffectiveDate(effectiveDate) {
        this.set('clientPrice.effective_date', effectiveDate.value);
        this.set('selectedEffectiveDate', effectiveDate);
      },
      onClose: function onClose() {
        this.get('onClose')();
      },
      submit: function submit() {
        this.get('onSubmit')();
      },
      resetPrices: function resetPrices() {
        this.get('resetPrices')();
      }
    }
  });

  _exports.default = _default;
});