define("frontend/templates/components/invoice-type-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "54sfyqu/",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"invoice-type-select/invoice-type-select-option\",null,[[\"selected\",\"option\",\"click\"],[[28,\"eq\",[[24,[\"selectedInvoiceType\"]],[23,1,[]]],null],[23,1,[]],[28,\"action\",[[23,0,[]],\"select\",[23,1,[]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/invoice-type-select.hbs"
    }
  });

  _exports.default = _default;
});