define("frontend/mixins/age-calculator", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    calculateAge: function calculateAge(birthday) {
      var years = (0, _moment.default)().diff((0, _moment.default)(birthday), 'years');

      if (years <= 0) {
        var months = (0, _moment.default)().diff(birthday, 'months');

        if (months === 1) {
          return '1 month old';
        }

        return "".concat(months, " months old");
      } else {
        if (years === 1) {
          return '1 year old';
        }

        return "".concat(years, " years old");
      }
    }
  });

  _exports.default = _default;
});