define("frontend/components/add-email", ["exports", "frontend/mixins/add-side-panel", "frontend/utils/email-type"], function (_exports, _addSidePanel, _emailType) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Component.extend(_addSidePanel.default, {
    classNames: ['add-email', 'add-with-side-panel'],
    householdUser: null,
    household: null,
    store: inject.service(),
    emailType: computed('householdUser.emails.@each.email_type', function () {
      var types = this.get('householdUser.emails').mapBy('email_type');

      if (types.indexOf(_emailType.default.PREFERRED) < 0) {
        return _emailType.default.PREFERRED;
      } else {
        return _emailType.default.ALTERNATE;
      }
    }),
    actions: {
      editEmail: function editEmail(email) {
        this.showEditSidePanel(email.getProperties('email_type', 'address'), email);
      },
      addEmail: function addEmail() {
        var emailObject = Ember.Object.create({
          email_type: this.get('emailType'),
          address: ''
        });
        this.showAddSidePanel(emailObject);
      },
      createEmail: function createEmail(emailObject) {
        var email_type = emailObject.email_type,
            address = emailObject.address;
        var existingEmails = this.get('householdUser.emails');

        if (existingEmails.filterBy('email_type', _emailType.default.PREFERRED).length > 0 && email_type === _emailType.default.PREFERRED) {
          existingEmails.forEach(function (email) {
            return email.set('email_type', _emailType.default.ALTERNATE);
          });
        }

        var email = this.get('store').createRecord('email', {
          email_type: email_type,
          address: address
        });
        this.get('householdUser.emails').pushObject(email);
        this.send('deactivateSidePanel');
      },
      updateEmail: function updateEmail(sidePanelObject) {
        var model = this.get('model');
        var existingEmails = this.get('householdUser.emails');

        if (existingEmails.filterBy('email_type', _emailType.default.PREFERRED).length > 0 && sidePanelObject.email_type === _emailType.default.PREFERRED) {
          existingEmails.forEach(function (email) {
            return email.set('email_type', _emailType.default.ALTERNATE);
          });
        } else if (existingEmails.filterBy('email_type', _emailType.default.PREFERRED).length <= 1 && sidePanelObject.email_type === _emailType.default.ALTERNATE) {
          var existingEmail = this.get('householdUser.emails').filter(function (email) {
            return email.get('address') !== sidePanelObject.address;
          }).get('firstObject');
          existingEmail.setProperties({
            email_type: _emailType.default.PREFERRED
          });
        }

        model.setProperties(sidePanelObject);
        this.send('deactivateSidePanel');
      },
      // eslint-disable-next-line no-unused-vars
      removeEmail: function removeEmail(sidePanelObject) {
        var model = this.get('model');

        if (model.get('email_type') === _emailType.default.PREFERRED) {
          model.deleteRecord();
          var existingEmail = this.get('householdUser.emails.firstObject');
          existingEmail.setProperties({
            email_type: _emailType.default.PREFERRED
          });
        } else {
          model.deleteRecord();
        }

        this.send('deactivateSidePanel');
      }
    }
  });

  _exports.default = _default;
});