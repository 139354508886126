define("frontend/models/message", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    message_type: _emberData.default.attr('string'),
    message: _emberData.default.attr('string'),
    formatted_message: _emberData.default.attr('string'),
    sent_by_id: _emberData.default.attr(),
    sent_by_name: _emberData.default.attr('string'),
    created_at: _emberData.default.attr(),
    sent_by_staff: _emberData.default.attr(),
    sent_by_avatar: _emberData.default.attr(),
    appointment_id: _emberData.default.attr(),
    grouped_report_card_id: _emberData.default.attr(),
    report_card_photo_url: _emberData.default.attr(),
    report_card_photo_type: _emberData.default.attr(),
    appointment_request_id: _emberData.default.attr(),
    was_approved: _emberData.default.attr(),
    pending: _emberData.default.attr(),
    company_id: _emberData.default.attr(),
    image_url: _emberData.default.attr(),
    file_type: _emberData.default.attr(),
    sent_to: _emberData.default.attr('string')
  });

  _exports.default = _default;
});