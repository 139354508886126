define("frontend/routes/client-list", ["exports", "frontend/routes/base-route", "frontend/mixins/cleanup-on-transition", "jquery"], function (_exports, _baseRoute, _cleanupOnTransition, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

  function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

  function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

  var inject = Ember.inject;

  var _default = _baseRoute.default.extend(_cleanupOnTransition.default, {
    queryParams: {
      filter: {
        refreshModel: true,
        replace: true
      },
      activity_log_open: false,
      search_term: null
    },
    filtered: false,
    autoShowActivityLog: false,
    activityLogs: inject.service(),
    infinity: inject.service(),
    currentUser: inject.service(),
    beforeModel: function beforeModel(transition) {
      this._super.apply(this, arguments);

      if (transition.targetName == 'client-list.index' && !this.get('currentUser.user.canViewFullClientInfo')) {
        this.transitionTo('index');
      }

      if (this.get('controller')) {
        this.set('controller.loading', true);
      }
    },
    model: function model(params) {
      if (params.activity_log_open == 'true') {
        if (params.search_term) {
          this.set('activityLogs.filter', params.search_term);
        }

        this.set('autoShowActivityLog', true);
      }

      if (params.filter !== '') {
        this.set('filtered', true);
        return this.get('infinity').model('household', {
          perPage: 15,
          startingPage: 1,
          filter: params.filter
        });
      }

      if (this.get('households') && !this.get('filtered')) {
        return this.get('households');
      }

      this.set('filtered', false);
      return this.get('infinity').model('household', {
        perPage: 15,
        startingPage: 1
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('loading', false);
      controller.set('autoShowActivityLog', this.get('autoShowActivityLog'));
      this.set('households', model);
      Ember.run.scheduleOnce('afterRender', function () {
        var _jQuery = (0, _jquery.default)('.household-select-option.selected'),
            _jQuery2 = _slicedToArray(_jQuery, 1),
            ele = _jQuery2[0];

        if (!ele) {
          return;
        }

        ele.scrollIntoView({
          block: 'center'
        });
      });
    },
    _doUpdate: function _doUpdate(newObjects) {
      var infinityModel = this.get('households');
      return infinityModel.pushObjects(newObjects.get('content'));
    },
    actions: {
      transitionToAddClient: function transitionToAddClient() {
        this.transitionTo('add-client.client-search');
      },
      loading: function loading() {
        return !this.get('controller');
      },
      parentInfinityLoad: function parentInfinityLoad() {
        this._infinityLoad();
      }
    }
  });

  _exports.default = _default;
});