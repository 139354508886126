define("frontend/templates/components/holiday-surcharge-holiday-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3U6KpMG7",
    "block": "{\"symbols\":[\"date\"],\"statements\":[[4,\"if\",[[24,[\"showSwitches\"]]],null,{\"statements\":[[4,\"adaptive-paper-switch\",null,[[\"value\",\"onChange\",\"classNames\"],[[24,[\"toggleIsOn\"]],[28,\"action\",[[23,0,[]],\"toggleHoliday\"],null],\"holiday-toggle\"]],{\"statements\":[[0,\"    \"],[1,[22,\"switchLabel\"],true],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[24,[\"toggleIsOn\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"date-choices\"],[8],[0,\"\\n\"],[4,\"each\",[[24,[\"datesToShow\"]]],null,{\"statements\":[[0,\"        \"],[1,[28,\"holiday-surcharge-holiday-list-item-date-choice\",null,[[\"holidaySurchargePolicy\",\"momentDate\",\"holidayDate\",\"iconUrl\",\"holidayName\"],[[24,[\"holidaySurchargePolicy\"]],[23,1,[]],[24,[\"holidayDate\"]],[24,[\"iconUrl\"]],[24,[\"holidayDisplayName\"]]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[24,[\"toggleIsOn\"]]],null,{\"statements\":[[0,\"  \"],[1,[22,\"switchLabel\"],true],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/holiday-surcharge-holiday-list-item.hbs"
    }
  });

  _exports.default = _default;
});