define("frontend/templates/edit-client/add-secondary-contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4cya6Fg8",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"add-secondary-contact add-edit-page\"],[8],[0,\"\\n  \"],[7,\"div\",true],[11,\"class\",[29,[[28,\"unless\",[[28,\"media\",[\"isMobile\"],null],\"pull-left\"],null],\" text-center\"]]],[8],[0,\"\\n    \"],[1,[28,\"avatar-upload\",null,[[\"photo\",\"uploadedPhoto\"],[[24,[\"model\",\"user\",\"avatar_url\"]],[28,\"action\",[[23,0,[]],\"uploadedPhoto\"],null]]]],false],[0,\"\\n\"],[4,\"unless\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"full-name\"],[8],[1,[24,[\"model\",\"user\",\"getFirstName\"]],false],[0,\" \"],[1,[24,[\"model\",\"user\",\"last_name\"]],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"role\"],[8],[0,\"\\n        Secondary Contact\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\"],[4,\"add-client/client-inputs\",null,[[\"onSubmit\",\"householdUser\",\"household\"],[[28,\"route-action\",[\"save\",[24,[\"model\"]]],null],[24,[\"model\"]],[24,[\"model\",\"household\"]]]],{\"statements\":[[0,\"    \"],[1,[28,\"adaptive-navigation-buttons\",null,[[\"rightText\",\"leftText\",\"clickRight\",\"clickLeft\"],[\"Save\",\"Cancel\",[23,1,[\"onSubmit\"]],[28,\"route-action\",[\"cancel\",[24,[\"model\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/edit-client/add-secondary-contact.hbs"
    }
  });

  _exports.default = _default;
});