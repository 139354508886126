define("frontend/templates/components/appointment-requests-list/header-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "pL+UPHMH",
    "block": "{\"symbols\":[\"option\",\"options\",\"option\"],\"statements\":[[4,\"if\",[[24,[\"groupedHeaders\"]]],null,{\"statements\":[[4,\"each\",[[24,[\"headerEntries\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[10,\"class\",\"grouped-header-dropdown-option\"],[8],[0,\"\\n      \"],[1,[23,2,[\"firstObject\"]],false],[0,\"\\n    \"],[9],[0,\"\\n\"],[4,\"each\",[[23,2,[\"lastObject\"]]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"selectOption\",[23,3,[]]],null]],null]],[10,\"class\",\"header-dropdown-option\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n        \"],[1,[23,3,[]],false],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,3,[]],[24,[\"selectedOption\"]]],null]],null,{\"statements\":[[0,\"          \"],[7,\"div\",true],[10,\"class\",\"checkmark\"],[8],[7,\"img\",true],[11,\"src\",[29,[[22,\"checkmarkImage\"]]]],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"      \"],[9],[0,\"\\n\"]],\"parameters\":[3]},null]],\"parameters\":[2]},null]],\"parameters\":[]},{\"statements\":[[4,\"each\",[[24,[\"options\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\",true],[11,\"onclick\",[28,\"action\",[[23,0,[]],[28,\"action\",[[23,0,[]],\"selectOption\",[23,1,[]]],null]],null]],[10,\"class\",\"header-dropdown-option\"],[10,\"style\",\"touch-action: manipulation; -ms-touch-action: manipulation; cursor: pointer;\"],[8],[0,\"\\n      \"],[1,[23,1,[]],false],[0,\"\\n\"],[4,\"if\",[[28,\"eq\",[[23,1,[]],[24,[\"selectedOption\"]]],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"checkmark\"],[8],[7,\"img\",true],[11,\"src\",[29,[[22,\"checkmarkImage\"]]]],[8],[9],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[9],[0,\"\\n\"]],\"parameters\":[1]},null]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/appointment-requests-list/header-dropdown.hbs"
    }
  });

  _exports.default = _default;
});