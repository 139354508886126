define("frontend/components/user-search-list", ["exports", "frontend/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var _default = Ember.Component.extend({
    classNames: [''],
    tagName: 'ul',
    searchTerm: null,
    selectedStaff: null,
    filteredUsers: null,
    page: 0,
    perPage: 10,
    searchNow: false,
    searched: false,
    staff: false,
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      var searchTerm = this.get('searchTerm');
      var searchNow = this.get('searchNow');

      if (searchNow) {
        this.set('page', 0);
        this.send('loadHouseholds', searchTerm, true);
        this.set('searched', true);
      }
    },
    actions: {
      loadMore: function loadMore() {
        this.set('page', this.get('page') + 1);
        this.send('loadHouseholds', this.get('searchTerm'), false);
      },
      transitionToPrimary: function transitionToPrimary() {
        this.get('transitionToPrimary')(this.get('searchTerm'));
      },
      showInviteClient: function showInviteClient() {
        if (this.get('showInviteClient')) {
          this.get('showInviteClient')();
        }
      },
      loadHouseholds: function loadHouseholds(searchTerm, reload) {
        var _this = this;

        var page = this.get('page');
        var perPage = this.get('perPage');

        if (!this.get('filteredHouseholds')) {
          this.set('loading', true);
        }

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households_search?term=").concat(encodeURIComponent(searchTerm), "&page=").concat(page, "&per_page=").concat(perPage),
          method: 'GET'
        }).done(function (response) {
          // Check to make sure we got the response before the searchTerm
          // was changed
          _this.set('searchNow', false);

          if (searchTerm == _this.get('searchTerm')) {
            _this.set('loading', false);

            if (reload) {
              _this.set('filteredHouseholds', response.households);
            } else {
              var households = _this.get('filteredHouseholds');

              _this.set('filteredHouseholds', [].concat(_toConsumableArray(households), _toConsumableArray(response.households)));
            }
          }
        });
      }
    }
  });

  _exports.default = _default;
});