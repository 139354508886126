define("frontend/components/activity-log", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['activity-log'],
    showActivityLog: false,
    activityLogs: Ember.inject.service(),
    pullLogsInterval: null,
    autoShowSidePanel: false,
    hasUnreadLogs: computed.alias('activityLogs.hasUnreadLogs'),
    logs: computed('activityLogs.logs', 'activityLogs.logs.[]', 'activityLogs.filter', function () {
      var logArray = this.get('activityLogs.logs');
      var newLogs = {};
      logArray.forEach(function (log) {
        var date = moment(log.created_at).format('YYYY-MM-DD');

        if (newLogs[date]) {
          newLogs[date].push(log);
          newLogs[date] = newLogs[date].sortBy('created_at').reverse();
        } else {
          newLogs[date] = [log];
        }
      });
      return newLogs;
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.get('autoShowSidePanel')) {
        this.send('toggleActivityLog');
        this.set('autoShowSidePanel', false);
      }
    },
    actions: {
      toggleActivityLog: function toggleActivityLog() {
        if (this.get('showActivityLog')) {
          this.get('activityLogs').setUnreadAfter();
        }

        this.set('showActivityLog', !this.get('showActivityLog'));
      },
      closeActivityLog: function closeActivityLog() {
        this.get('activityLogs').setUnreadAfter();
        this.set('showActivityLog', false);
      }
    }
  });

  _exports.default = _default;
});