define("frontend/services/stripe-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject;

  var _default = Ember.Service.extend({
    ajax: inject.service(),
    createPaymentMethod: function createPaymentMethod(userId, stripe_token) {
      var payment_method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'bank_account';
      var url = "/api/v2/users/".concat(userId, "/create_stripe_payment_method");
      return this.get('ajax').request(url, {
        url: url,
        method: 'POST',
        data: {
          stripe_token: stripe_token,
          payment_method: payment_method,
          id: userId
        }
      });
    },
    fetchCustomerInfo: function fetchCustomerInfo(userId, companyId) {
      var url = "/api/v2/users/".concat(userId, "/get_stripe_info?company_id=").concat(companyId);
      return this.get('ajax').request(url);
    },
    fetchCompanyCustomerInfo: function fetchCompanyCustomerInfo() {}
  });

  _exports.default = _default;
});