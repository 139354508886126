define("frontend/routes/edit-client/add-pet", ["exports", "frontend/routes/base-route", "frontend/mixins/cleanup-on-transition", "frontend/config/environment", "jquery"], function (_exports, _baseRoute, _cleanupOnTransition, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = _baseRoute.default.extend(_cleanupOnTransition.default, {
    addClientSaving: inject.service(),
    saving: computed.alias('addClientSaving.saving'),
    model: function model() {
      var household = this._super.apply(this, arguments);

      return this.store.createRecord('pet', {
        household: household
      });
    },
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);

      controller.set('anySidePanelActive', false);

      _jquery.default.ajax({
        url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(model.get('household').get('id'), "/get_companies"),
        method: 'GET',
        data: {
          id: model.get('household').get('id')
        }
      }).done(function (response) {
        model.set('company_notes', response.company_notes);
      });
    },
    actions: {
      willTransition: function willTransition() {
        this.rollbackHasMany('controller.model.emergency_contacts');
        this.rollbackHasMany('controller.model.vaccinations');
        this.rollbackHasMany('controller.model.pet_prescriptions');
      },
      save: function save(pet) {
        var _this = this;

        this.set('saving', true);
        this.get('addClientSaving').savePet(pet).then(function () {
          return _this.modelFor('edit-client').reload();
        }).then(function () {
          _this.set('saving', false);

          return _this.send('transitionBack');
        });
      }
    }
  });

  _exports.default = _default;
});