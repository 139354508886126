define("frontend/components/report-card-duration", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['report-card-duration'],
    durationTime: computed('appointment.started_at', 'appointment.finished_at', function () {
      var start = (0, _moment.default)(this.get('appointment.started_at'));
      var finish = (0, _moment.default)(this.get('appointment.finished_at'));

      var duration = _moment.default.duration(finish.diff(start));

      var hours = duration.hours();

      if (hours < 10) {
        hours = "0".concat(hours);
      }

      var minutes = duration.minutes();

      if (minutes < 10) {
        minutes = "0".concat(minutes);
      }

      var seconds = duration.seconds();

      if (seconds < 10) {
        seconds = "0".concat(seconds);
      }

      return "".concat(hours, ":").concat(minutes, ":").concat(seconds);
    }),
    startTime: computed('appointment.started_at', 'appointment.company_time_zone', function () {
      var time = _moment.default.tz(this.get('appointment.started_at'), this.get('appointment.company_time_zone'));

      var timeDisplay = time.format('h:mm');
      var meridiem = time.format('A');
      return "".concat(timeDisplay, "<span class=\"report-card-duration--time-meridiem\">").concat(meridiem, "</span>");
    }),
    finishTime: computed('appointment.finished_at', 'appointment.company_time_zone', function () {
      var time = _moment.default.tz(this.get('appointment.finished_at'), this.get('appointment.company_time_zone'));

      var timeDisplay = time.format('h:mm');
      var meridiem = time.format('A');
      return "".concat(timeDisplay, "<span class=\"report-card-duration--time-meridiem\">").concat(meridiem, "</span>");
    })
  });

  _exports.default = _default;
});