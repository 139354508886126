define("frontend/templates/components/plaid-dropin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "a8iCuAgd",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[4,\"if\",[[24,[\"openManualBankAccount\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"bank-account-modal\",null,[[\"user_id\",\"accountHolderName\",\"showClose\",\"close\"],[[24,[\"calcd_user_id\"]],[24,[\"accountHolderName\"]],[24,[\"showManualClose\"]],[28,\"action\",[[23,0,[]],\"closeManualBankAccount\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[14,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/plaid-dropin.hbs"
    }
  });

  _exports.default = _default;
});