define("frontend/validators/additional-dog-amount", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var AdditionalDogAmount = _base.default.extend({
    validate: function validate(value, options, model) {
      var differsForAdditionalDogs = model.get('employee.differs_for_additional_dogs');
      var compensationType = model.get('employee.compensationTypeName');

      if (differsForAdditionalDogs && compensationType === 'Fixed' && Ember.isBlank(value)) {
        return "This field can't be blank";
      } else {
        return true;
      }
    }
  });

  AdditionalDogAmount.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor: function getDependentsFor() {
      return [];
    }
  });
  var _default = AdditionalDogAmount;
  _exports.default = _default;
});