define("frontend/components/readonly-field", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    classNames: ['readonly-field'],
    classNameBindings: ['fieldType'],
    noValue: computed.empty('value'),
    hasValue: computed.not('noValue'),
    allowEmpty: false,
    isVisible: computed.or('allowEmpty', 'hasValue'),
    label: null,
    value: null,
    fieldType: 'textarea',
    country: 'United States'
  });

  _exports.default = _default;
});