define("frontend/routes/client-list/household", ["exports", "frontend/config/environment", "frontend/routes/base-route", "jquery"], function (_exports, _environment, _baseRoute, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _baseRoute.default.extend({
    queryParams: {
      backDate: {
        refreshModel: true
      },
      backCareProviderId: {
        refreshModel: true
      },
      backToMessages: {
        refreshModel: true
      }
    },
    model: function model(params) {
      this.set('backDate', params.backDate);
      this.set('backCareProviderId', params.backCareProviderId);
      this.set('backToMessages', params.backToMessages);
      return this.store.findRecord('household', params.household_id);
    },
    afterModel: function afterModel(model) {
      this._super.apply(this, arguments);

      if (model.get('active') == false) {
        this.send('error');
      }
    },
    deactivate: function deactivate() {
      this._super.apply(this, arguments);

      this.set('controller.model', null);
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);

      controller.set('moreMenuOpen', false);
      controller.set('removingAppointment', null);
      controller.set('editingUpcomingAppointments', false);
    },
    backQueryParams: function backQueryParams() {
      var backRoute = this.get('controller.currentRouteName');
      var backHouseholdId = this.get('controller.model.id');
      var backId = this.get('controller.selectedUser.id');
      return {
        backHouseholdId: backHouseholdId,
        backId: backId,
        backRoute: backRoute
      };
    },
    actions: {
      error: function error() {
        this.transitionTo('client-list.household-error');
      },
      goBack: function goBack() {
        if (this.get('backCareProviderId') && this.get('backDate')) {
          this.transitionTo('schedule', {
            queryParams: {
              care_provider_id: this.get('backCareProviderId'),
              date: this.get('backDate')
            }
          });
        } else if (this.get('backToMessages')) {
          this.transitionTo('inbox.household', this.get('controller.model.id'));
        } else {
          this.transitionTo('client-list');
        }
      },
      cancelRequest: function cancelRequest(householdId) {
        var _this = this;

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(householdId, "/remove_request"),
          method: 'POST',
          data: {
            id: householdId
          }
        }).done(function () {
          _this.store.findRecord('household', householdId).then(function (household) {
            household.deleteRecord();
          });

          _this.transitionTo('client-list');
        });
      },
      requestHousehold: function requestHousehold(householdId, email) {
        var _this2 = this;

        _jquery.default.ajax({
          url: "".concat(_environment.default.APP.host, "/api/v2/households/").concat(householdId, "/add_company"),
          method: 'POST',
          data: {
            id: householdId,
            email: email
          }
        }).done(function () {
          _this2.store.findRecord('household', householdId).then(function (household) {
            household.set('client_status', 'pending');
          });

          Ember.getOwner(_this2).lookup('controller:application').send('notify', {
            message: "Your request to connect was sent to ".concat(email),
            type: 'success'
          });
        });
      },
      transitionToAddAppointment: function transitionToAddAppointment() {
        var queryParams = this.backQueryParams.call(this);
        queryParams.selectedHouseholdId = this.get('controller.model.id');
        queryParams.filter = this.get('controller.model.owner.fullName');
        this.transitionTo('add-appointment.index', {
          queryParams: queryParams
        });
      },
      transitionToEditSingleAppointment: function transitionToEditSingleAppointment(appointmentIds) {
        var queryParams = this.backQueryParams.call(this);
        queryParams.ids = appointmentIds;
        this.transitionTo('edit-single-appointment.index', {
          queryParams: queryParams
        });
      },
      transitionToEditRecurringAppointmentGroup: function transitionToEditRecurringAppointmentGroup(group) {
        var queryParams = this.backQueryParams.call(this);
        this.transitionTo('edit-recurring-appointment-group.index', group.get('id'), {
          queryParams: queryParams
        });
      },
      transitionToRemoveRecurringAppointmentGroup: function transitionToRemoveRecurringAppointmentGroup(group) {
        var queryParams = this.backQueryParams.call(this);
        this.transitionTo('remove-recurring-appointment-group', group.get('id'), {
          queryParams: queryParams
        });
      },
      transitionToHouseholdUser: function transitionToHouseholdUser(household) {
        this.transitionTo('client-list.household.household-user', household, household.get('owner'));
      },
      removeHousehold: function removeHousehold(household) {
        this.transitionTo('edit-client.remove-household', household);
      },
      transitionToInvoices: function transitionToInvoices(household) {
        var queryParams = this.backQueryParams.call(this);
        this.transitionTo('invoices-summary.index', household.get('id'), {
          queryParams: queryParams
        });
      },
      transitionToMessages: function transitionToMessages(household) {
        var queryParams = this.backQueryParams.call(this);
        this.transitionTo('client-list.household.messages', household, {
          queryParams: queryParams
        });
      },
      transitionToEditHousehold: function transitionToEditHousehold(household, petOrUser, sectionToEdit) {
        var self = this;
        household.get('users').forEach(function (user) {
          var phoneNumbers = user.get('phone_numbers');

          if (phoneNumbers.get('length') == 0) {
            var defaultPhone = self.store.createRecord('phone-number');
            user.get('phone_numbers').pushObject(defaultPhone);
          }
        });

        if (petOrUser.get('modelName') === 'household-user') {
          var queryParams = {
            backRoute: 'client-list.household.household-user',
            backHouseholdId: household.get('id'),
            backId: petOrUser.get('id')
          };

          if (petOrUser.get('isHouseholdOwner')) {
            this.transitionTo('edit-client.primary-contact', household, {
              queryParams: queryParams
            });
          } else {
            this.transitionTo('edit-client.secondary-contact', household, {
              queryParams: queryParams
            });
          }
        } else {
          var _queryParams = {
            backRoute: 'client-list.household.pet',
            backHouseholdId: household.get('id'),
            backId: petOrUser.get('id'),
            currentSection: undefined
          };

          if (sectionToEdit && typeof sectionToEdit === 'string') {
            _queryParams['editSection'] = sectionToEdit;
          }

          this.transitionTo('edit-client.pet', household, petOrUser, {
            queryParams: _queryParams
          });
        }
      }
    }
  });

  _exports.default = _default;
});