define("frontend/services/service-actions", ["exports", "moment", "jquery"], function (_exports, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    flashMessage: Ember.inject.service(),
    removeAllInDateRange: function removeAllInDateRange(startDate, endDate, careProviderId) {
      var _this = this;

      var start_date = startDate;
      var end_date = endDate;
      return new Ember.RSVP.Promise(function (resolve, reject) {
        _jquery.default.ajax({
          url: '/api/v2/services/destroy_all',
          method: 'POST',
          dataType: 'json',
          contentType: 'application/json',
          data: JSON.stringify({
            care_provider_id: careProviderId,
            start_date: start_date,
            end_date: end_date
          })
        }).then(function () {
          resolve();
        }).fail(function (err) {
          _this.get('flashMessage').error('Unable to remove all, internal server error');

          reject(err);
        });
      });
    },
    removeAllFor: function removeAllFor(date, careProviderId) {
      return this.removeAllInDateRange(date, date, careProviderId);
    },
    removeAllInWeek: function removeAllInWeek(date, careProviderId) {
      var startOfWeek = (0, _moment.default)(date).startOf('week').format('YYYY-MM-DD');
      var endOfWeek = (0, _moment.default)(date).endOf('week').format('YYYY-MM-DD');
      return this.removeAllInDateRange(startOfWeek, endOfWeek, careProviderId);
    }
  });

  _exports.default = _default;
});