define("frontend/services/polling", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    polls: {},
    add: function add(label, context, pollingCallback) {
      var interval = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : 1000;
      this.schedule(label, context, pollingCallback, interval);
    },
    stop: function stop(label) {
      if (this.get("polls.".concat(label))) {
        clearInterval(this.get("polls.".concat(label)));
      }
    },
    schedule: function schedule(label, context, pollingCallback, interval) {
      var handler = Ember.run.bind(context, pollingCallback);
      var intervalId = setInterval(handler, interval);
      this.set("polls.".concat(label), intervalId);
    }
  });

  _exports.default = _default;
});