define("frontend/components/new-header", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['new-header'],
    classNameBindings: ['hasSideNav', 'hasSidePanel:has-side-nav', 'flex:flex-header'],
    hasClose: true,
    hasSideNav: false,
    flex: false,
    shouldRemovePadding: true,
    didInsertElement: function didInsertElement() {
      (0, _jquery.default)('.ember-application').addClass('no-body-padding');
    },
    willDestroyElement: function willDestroyElement() {
      if (this.get('shouldRemovePadding')) {
        (0, _jquery.default)('.ember-application').removeClass('no-body-padding');
      }
    },
    actions: {
      cancel: function cancel() {
        if (this.cancel) {
          this.cancel();
        }
      }
    }
  });

  _exports.default = _default;
});