define("frontend/models/request-cut-off-policy", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = _emberData.default.Model.extend({
    service_category_id: _emberData.default.attr(),
    service_category: _emberData.default.belongsTo('service-category'),
    time: _emberData.default.attr('string'),
    within_minutes: _emberData.default.attr('number'),
    method: _emberData.default.attr('string'),
    days_before: _emberData.default.attr('number'),
    withinOptions: [{
      label: '30 minutes',
      value: '30'
    }, {
      label: '1 hour',
      value: '60'
    }, {
      label: '1 hour 30 minutes',
      value: '90'
    }, {
      label: '2 hours',
      value: '120'
    }, {
      label: '4 hours',
      value: '240'
    }, {
      label: '6 hours',
      value: '360'
    }, {
      label: '8 hours',
      value: '480'
    }, {
      label: '10 hours',
      value: '600'
    }, {
      label: '12 hours',
      value: '720'
    }, {
      label: '24 hours',
      value: '1440'
    }, {
      label: '48 hours',
      value: '2880'
    }, {
      label: '3 days',
      value: '4320'
    }, {
      label: '4 days',
      value: '5760'
    }, {
      label: '5 days',
      value: '7200'
    }, {
      label: '6 days',
      value: '8640'
    }, {
      label: '7 days',
      value: '10080'
    }, {
      label: '8 days',
      value: '11520'
    }, {
      label: '9 days',
      value: '12960'
    }, {
      label: '10 days',
      value: '14400'
    }, {
      label: '11 days',
      value: '15840'
    }, {
      label: '12 days',
      value: '17280'
    }, {
      label: '13 days',
      value: '18720'
    }, {
      label: '14 days',
      value: '20160'
    }, {
      label: '30 days',
      value: '43200'
    }],
    timingDescription: computed('time', 'within_minutes', 'method', 'days_before', function () {
      var _this = this;

      var desc = '';

      if (this.get('method') == 'after') {
        var daysBefore = this.get('days_before');

        if (daysBefore == 0) {
          daysBefore = 'day of';
        } else if (daysBefore == 1) {
          daysBefore = 'day before';
        } else {
          daysBefore = "".concat(daysBefore, " days before");
        }

        desc += "after ".concat(this.get('time'), " ").concat(daysBefore);
      } else {
        var options = this.get('withinOptions');
        var selected = options.filter(function (option) {
          return _this.get('within_minutes') == option.value;
        });
        var within = selected.get('firstObject');
        desc += "within ".concat(within.label);
      }

      return desc;
    })
  });

  _exports.default = _default;
});