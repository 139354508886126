define("frontend/templates/components/client-invoices-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "oL/lhjxn",
    "block": "{\"symbols\":[\"invoiceListItem\",\"index\"],\"statements\":[[4,\"each\",[[24,[\"invoiceListItems\"]]],null,{\"statements\":[[4,\"if\",[[23,1,[\"isInvoice\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"client-invoices-list/invoice-item\",null,[[\"active\",\"invoice\",\"click\"],[[28,\"and\",[[24,[\"onInvoicePage\"]],[28,\"eq\",[[24,[\"activeInvoiceId\"]],[23,1,[\"invoice\",\"id\"]]],null]],null],[23,1,[\"invoice\"]],[28,\"action\",[[23,0,[]],\"clickInvoice\",[23,1,[\"invoice\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,1,[\"isPayment\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"client-invoices-list/invoice-payment-item\",null,[[\"deletePayment\",\"invoicePayment\",\"active\",\"index\",\"showDelete\",\"click\"],[[28,\"action\",[[23,0,[]],\"deletePayment\"],null],[23,1,[\"invoice_payment\"]],[28,\"and\",[[24,[\"onPaymentPage\"]],[28,\"eq\",[[24,[\"activePaymentId\"]],[23,1,[\"invoice_payment\",\"id\"]]],null]],null],[23,2,[]],[24,[\"showDelete\"]],[28,\"action\",[[23,0,[]],\"clickPayment\",[23,1,[\"invoice_payment\"]]],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null],[4,\"if\",[[23,1,[\"isRefund\"]]],null,{\"statements\":[[0,\"    \"],[1,[28,\"client-invoices-list/invoice-refund-item\",null,[[\"invoiceRefund\"],[[23,1,[\"invoice_payment_refund\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[4,\"unless\",[[24,[\"invoiceListItems\",\"reachedInfinity\"]]],null,{\"statements\":[[0,\"  \"],[1,[28,\"custom-infinity-loader\",null,[[\"infinityModel\",\"scrollableArea\"],[[24,[\"invoiceListItems\"]],\".client-invoices-list\"]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/client-invoices-list.hbs"
    }
  });

  _exports.default = _default;
});