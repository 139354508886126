define("frontend/templates/add-client/primary-contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ORtuZtZ0",
    "block": "{\"symbols\":[\"form\"],\"statements\":[[1,[28,\"add-client/header-text\",null,[[\"text\"],[[24,[\"title\"]]]]],false],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"primary-contact add-edit-page add-page\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"text-center\"],[8],[0,\"\\n    \"],[1,[28,\"avatar-upload\",null,[[\"photo\",\"uploadedPhoto\"],[[24,[\"model\",\"user\",\"avatar_url\"]],[28,\"action\",[[23,0,[]],\"uploadedPhoto\"],null]]]],false],[0,\"\\n\"],[4,\"unless\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"full-name\"],[8],[1,[24,[\"model\",\"user\",\"fullName\"]],false],[9],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"role\"],[8],[0,\"\\n        Primary Contact\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[9],[0,\"\\n\\n\"],[4,\"add-client/client-inputs\",null,[[\"onSubmit\",\"householdUser\",\"household\"],[[28,\"route-action\",[\"goNext\"],null],[24,[\"model\"]],[24,[\"model\",\"household\"]]]],{\"statements\":[[4,\"if\",[[24,[\"isPetParent\"]]],null,{\"statements\":[[0,\"      \"],[1,[28,\"adaptive-navigation-buttons\",null,[[\"clickRight\",\"showLeft\"],[[23,1,[\"onSubmit\"]],false]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[1,[28,\"adaptive-navigation-buttons\",null,[[\"clickRight\",\"leftText\",\"clickLeft\"],[[23,1,[\"onSubmit\"]],[28,\"if\",[[28,\"media\",[\"isMobile\"],null],\"Search\",\"Back\"],null],[28,\"route-action\",[\"redirectToSearch\",null],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"parameters\":[1]},null],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/add-client/primary-contact.hbs"
    }
  });

  _exports.default = _default;
});