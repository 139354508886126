define("frontend/templates/components/client-invoices-list/invoice-refund-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MKkJNZlT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\",true],[10,\"class\",\"invoice-refund-content\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"invoice-refund-header\"],[8],[0,\"\\n    Refund issued to \"],[7,\"span\",true],[10,\"class\",\"payment-info\"],[8],[1,[24,[\"invoiceRefund\",\"payment_brand\"]],false],[0,\" ending \"],[1,[24,[\"invoiceRefund\",\"payment_last_four\"]],false],[9],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"date-line\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"date\"],[8],[0,\"\\n        \"],[1,[28,\"format-date\",[[24,[\"invoiceRefund\",\"created_at\"]],\"MMMM D, YYYY\"],null],false],[0,\"\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9],[0,\"\\n\\n\"],[7,\"div\",true],[10,\"class\",\"amount\"],[8],[0,\"\\n  \"],[7,\"div\",true],[8],[1,[28,\"format-money\",[[24,[\"invoiceRefund\",\"amount\"]]],[[\"precision\"],[2]]],false],[9],[0,\"\\n\"],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/client-invoices-list/invoice-refund-item.hbs"
    }
  });

  _exports.default = _default;
});