define("frontend/models/phone-number", ["exports", "ember-data", "frontend/utils/phone-number-type", "ember-cp-validations"], function (_exports, _emberData, _phoneNumberType, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;
  var Validations = (0, _emberCpValidations.buildValidations)({
    number: [(0, _emberCpValidations.validator)('international-phone-number', {
      presence: false,
      ignoreBlank: true
    })]
  });

  var _default = _emberData.default.Model.extend(Validations, {
    number: _emberData.default.attr('trimmed-string'),
    phone_type_id: _emberData.default.attr('string', {
      defaultValue: _phoneNumberType.default.MOBILE
    }),
    displayType: computed('isMobile', 'isHome', 'isWork', 'isOther', function () {
      if (this.get('isMobile')) {
        return 'Mobile';
      }

      if (this.get('isHome')) {
        return 'Home';
      }

      if (this.get('isWork')) {
        return 'Work';
      }

      if (this.get('isOther')) {
        return 'Other';
      }
    }),
    isMobile: computed.equal('phone_type_id', _phoneNumberType.default.MOBILE),
    isHome: computed.equal('phone_type_id', _phoneNumberType.default.HOME),
    isWork: computed.equal('phone_type_id', _phoneNumberType.default.WORK),
    isOther: computed.equal('phone_type_id', _phoneNumberType.default.OTHER)
  });

  _exports.default = _default;
});