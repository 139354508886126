define("frontend/components/popout-dropdown", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed,
      inject = Ember.inject;

  var _default = Ember.Component.extend({
    classNames: ['popout-dropdown'],
    classNameBindings: ['searchEnabled'],
    attributeBindings: ['tabIndex'],
    media: inject.service(),
    tabIndex: 1,
    options: [],
    selectedValue: null,
    passPreviousSelected: false,
    hasManyOptions: computed.gt('options.length', 5),
    notMobile: computed.not('media.isMobile'),
    searchEnabled: computed.and('hasManyOptions', 'notMobile'),
    dropdownClass: 'search-enabled',
    disabled: false,
    selectedOption: computed('selectedValue', 'options.@each.value', {
      get: function get() {
        var _this = this;

        var found = null;
        this.get('options').forEach(function (opt) {
          if (opt.groupName === undefined) {
            if (opt.value == _this.get('selectedValue')) {
              found = opt;
            }
          } else {
            var innerFound = opt.options.find(function (innerOpt) {
              return innerOpt.value == _this.get('selectedValue');
            });

            if (innerFound) {
              found = innerFound;
            }
          }
        });
        return found;
      },
      set: function set(key, _ref) {
        var value = _ref.value;
        this.set('selectedValue', value);
        var found = null;
        this.get('options').forEach(function (opt) {
          if (opt.groupName === undefined) {
            if (opt.value == value) {
              found = opt;
            }
          } else {
            var innerFound = opt.options.find(function (innerOpt) {
              return innerOpt.value == value;
            });

            if (innerFound) {
              found = innerFound;
            }
          }
        });
        return found;
      }
    }),
    willDestroyElement: function willDestroyElement() {
      (0, _jquery.default)(this.element).off('focusout');
      (0, _jquery.default)(this.element).off('focus');
    },
    actions: {
      selectOption: function selectOption(option) {
        var previousSelected = null;

        if (this.get('selectedOption')) {
          previousSelected = this.get('selectedOption').value;
        }

        this.set('selectedOption', option);

        if (this.selectOption) {
          if (this.get('passPreviousSelected')) {
            this.selectOption(option.value, previousSelected);
          } else {
            this.selectOption(option.value);
          }
        }
      },
      open: function open() {
        if (this.get('open')) {
          this.get('open').apply(void 0, arguments);
        }
      }
    }
  });

  _exports.default = _default;
});