define("frontend/components/cancel-appointment-request-modal", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var computed = Ember.computed;

  var _default = Ember.Component.extend({
    appointmentRequest: null,
    offeredService: computed.alias('appointmentRequest.offered_service'),
    pickupWindow: computed.alias('appointmentRequest.pickupWindow'),
    willInsertElement: function willInsertElement() {
      this._super.apply(this, arguments);

      this.set('cancelingAppointment', false);
      this.set('triedToCancel', false);
    },
    pets: computed('appointmentRequest.pets.[]', function () {
      return this.get('appointmentRequest.pets').map(function (pet) {
        return {
          id: pet.get('id'),
          avatarOrPlaceholder: pet.get('avatarOrPlaceholder'),
          name: pet.get('name'),
          selected: true
        };
      });
    }),
    month: computed('appointmentRequest.starts_at', function () {
      return _moment.default.parseZone(this.get('appointmentRequest.starts_at')).startOf('day').format('MMM');
    }),
    day: computed('appointmentRequest.starts_at', function () {
      return _moment.default.parseZone(this.get('appointmentRequest.starts_at')).startOf('day').format('D');
    }),
    dayOfWeek: computed('appointmentRequest.starts_at', function () {
      return _moment.default.parseZone(this.get('appointmentRequest.starts_at')).startOf('day').format('ddd');
    }),
    showSelectionError: computed('pets.@each.selected', function () {
      var selected = this.get('pets').filter(function (appt) {
        return appt.selected;
      });
      return selected.length == 0;
    }),
    actions: {
      cancelAppointmentRequest: function cancelAppointmentRequest() {
        this.set('triedToCancel', true);

        if (this.get('showSelectionError')) {
          return;
        }

        var selectedPets = this.get('pets').filter(function (pet) {
          return pet.selected;
        });
        var petIds = selectedPets.map(function (pet) {
          return pet.id;
        });
        this.set('cancelingAppointment', true);
        this.set('triedToCancel', false);
        this.get('cancelAppointmentRequest')(this.get('appointmentRequest'), petIds);
      },
      onClose: function onClose() {
        this.get('close')();
      }
    }
  });

  _exports.default = _default;
});