define("frontend/templates/components/client-import-success-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "/ReQqw5W",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"client-import-errors-modal\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"content centered\"],[8],[0,\"\\n\"],[4,\"unless\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n          \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[1,[28,\"inline-svg\",[\"assets/images/fireworks.svg\"],null],false],[9],[0,\"\\n          \"],[7,\"header\",true],[8],[0,\"Clients imported!\"],[9],[0,\"\\n        \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"middle-text\"],[8],[0,\"\\n        If there are any conflicts an email will be sent to support@petpocketbook.com with the differences found.\\n      \"],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[28,\"media\",[\"isMobile\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"mobile-button-bar\"],[8],[0,\"\\n        \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red\",\"Got it!\",[28,\"action\",[[23,0,[]],\"onClose\"],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"      \"],[7,\"div\",true],[10,\"class\",\"bottom\"],[8],[0,\"\\n        \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red\",\"Got it!\",[28,\"action\",[[23,0,[]],\"onClose\"],null]]]],false],[0,\"\\n      \"],[9],[0,\"\\n\"]],\"parameters\":[]}],[0,\"  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/client-import-success-modal.hbs"
    }
  });

  _exports.default = _default;
});