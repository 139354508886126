define("frontend/components/invoice-type-select/invoice-type-select-option", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    selected: false,
    classNameBindings: ['selected', 'option'],
    classNames: ['invoice-type-select-option']
  });

  _exports.default = _default;
});