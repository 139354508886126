define("frontend/controllers/edit-client/remove-pet", ["exports", "frontend/utils/helpers"], function (_exports, _helpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var inject = Ember.inject,
      computed = Ember.computed;

  var _default = Ember.Controller.extend({
    familyName: computed.readOnly('model.household.owner.last_name'),
    currentUser: inject.service(),
    futureAppointments: null,
    title: computed('futureAppointments', 'model', function () {
      var _getPetGrammaticalDet = (0, _helpers.getPetGrammaticalDetails)(this.get('model')),
          subject = _getPetGrammaticalDet.subject,
          subjectUpper = _getPetGrammaticalDet.subjectUpper;

      if (this.get('futureAppointments')) {
        return "".concat(subjectUpper, " still has upcoming appointments!");
      } else {
        return "Are you sure you would like to remove ".concat(subject, "?");
      }
    }),
    clarification: computed('currentUser.user.isPetParent', 'futureAppointments', 'model.name', 'familyName', function () {
      var _getPetGrammaticalDet2 = (0, _helpers.getPetGrammaticalDetails)(this.get('model')),
          subject = _getPetGrammaticalDet2.subject,
          possesivePronounUpper = _getPetGrammaticalDet2.possesivePronounUpper,
          possesivePronoun = _getPetGrammaticalDet2.possesivePronoun;

      var futureAppointments = this.get('futureAppointments');
      var petName = this.get('model.name');
      var familyName = this.get('familyName');

      if (this.get('currentUser.user.isPetParent')) {
        if (futureAppointments) {
          return "Removing ".concat(petName, " from your family profile will also <b class=\"bold\">remove all of ").concat(possesivePronoun, " future appointments</b>. Are you sure you'd like to proceed?");
        } else {
          return "This will remove ".concat(subject, " from your family profile, but past appointments will remain on your schedule and in your billing history.");
        }
      } else {
        var base = "This will remove ".concat(subject, " from the ").concat(familyName, " family profile");

        if (futureAppointments) {
          return "".concat(base, " as well as <b class=\"bold\">all future appointments for ").concat(subject, "</b>. ").concat(possesivePronounUpper, " past appointments will remain on your schedule and in your billing history. Are you sure you\u2019d like to proceed?");
        } else {
          return "".concat(base, ", but past appointments will remain on your schedule and in your billing history.");
        }
      }
    }),
    removeButtonText: computed('futureAppointments', function () {
      return this.get('futureAppointments') ? 'remove anyway' : 'remove';
    })
  });

  _exports.default = _default;
});