define("frontend/templates/components/auto-bill/invoice-frequencies", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m0ahQP8t",
    "block": "{\"symbols\":[\"invoiceFrequency\"],\"statements\":[[4,\"each\",[[24,[\"sortedInvoiceFrequencies\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"invoice-frequency-name\"],[8],[1,[23,1,[\"displayName\"]],false],[0,\" \"],[4,\"if\",[[28,\"eq\",[[23,1,[\"name\"]],\"biweekly\"],null]],null,{\"statements\":[[0,\"(\"],[1,[22,\"biweeklyStart\"],false],[0,\" - \"],[1,[22,\"biweeklyEnd\"],false],[0,\")\"]],\"parameters\":[]},null],[0,\" \"],[4,\"if\",[[28,\"eq\",[[23,1,[\"id\"]],[24,[\"defaultInvoiceFrequency\",\"id\"]]],null]],null,{\"statements\":[[7,\"span\",true],[10,\"class\",\"default\"],[8],[0,\"(default)\"],[9]],\"parameters\":[]},null],[9],[0,\"\\n\"]],\"parameters\":[1]},{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"invoice-frequency-name\"],[8],[0,\"Never\"],[9],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/auto-bill/invoice-frequencies.hbs"
    }
  });

  _exports.default = _default;
});