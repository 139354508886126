define("frontend/templates/components/late-booking-alert-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OyuXU/X6",
    "block": "{\"symbols\":[],\"statements\":[[4,\"ppb-modal\",null,[[\"customContainerClassNames\"],[\"surcharge-list-modal\"]],{\"statements\":[[0,\"  \"],[7,\"div\",true],[10,\"class\",\"content\"],[8],[0,\"\\n    \"],[7,\"div\",true],[10,\"class\",\"header-with-image\"],[8],[0,\"\\n      \"],[7,\"div\",true],[10,\"class\",\"image-container\"],[8],[1,[28,\"inline-svg\",[\"assets/images/appointment-requests/timer.svg\"],null],false],[9],[0,\"\\n    \"],[9],[0,\"\\n\\n    \"],[7,\"div\",true],[10,\"class\",\"layout-row policy-info\"],[8],[0,\"\\n      You charge a \"],[1,[24,[\"policy\",\"amount\"]],false],[0,\" for requests made \"],[1,[24,[\"policy\",\"description\"]],false],[0,\". Would you like to apply the fee to this appointment?\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"buttons-container two-buttons-next-to-each-other\"],[8],[0,\"\\n    \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red-white-border\",\"waive fee\",[28,\"action\",[[23,0,[]],\"waiveFee\"],null]]]],false],[0,\"\\n    \"],[1,[28,\"ppb-button\",null,[[\"style\",\"text\",\"click\"],[\"red\",\"apply fee\",[28,\"action\",[[23,0,[]],\"chargeFee\"],null]]]],false],[0,\"\\n  \"],[9],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend/templates/components/late-booking-alert-modal.hbs"
    }
  });

  _exports.default = _default;
});