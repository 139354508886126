define("frontend/components/weekend-surcharge-policy-modal", ["exports", "frontend/mixins/money-formater"], function (_exports, _moneyFormater) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

  function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

  function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

  function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

  function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

  function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

  var computed = Ember.computed,
      inject = Ember.inject,
      observer = Ember.observer;

  var _default = Ember.Component.extend(_moneyFormater.default, {
    store: inject.service(),
    serviceCategory: null,
    weekendSurchargePolicy: null,
    percentageType: computed.equal('weekendSurchargePolicy.amount_type', 'percentage'),
    fixedType: computed.equal('weekendSurchargePolicy.amount_type', 'fixed'),
    chargePerDay: computed.readOnly('weekendSurchargePolicy.charge_per_day'),
    chargePerVisit: computed.not('chargePerDay'),
    percentageCompensation: computed.equal('weekendSurchargePolicy.employee_compensation_type', 'percentage'),
    fixedCompensation: computed.equal('weekendSurchargePolicy.employee_compensation_type', 'fixed'),
    surchargeTypeOptions: [{
      label: 'fixed $ per visit',
      value: 'fixed'
    }, {
      label: '% of service cost',
      value: 'percentage'
    }],
    selectedAmountType: computed('weekendSurchargePolicy.amount_type', function () {
      var _this = this;

      var options = this.get('surchargeTypeOptions');
      var selected = options.filter(function (option) {
        return _this.get('weekendSurchargePolicy.amount_type') == option.value;
      });
      return selected.get('firstObject');
    }),
    didInsertElement: function didInsertElement() {
      this.set('loading', false);
      this.set('originalDow', _toConsumableArray(this.get('weekendSurchargePolicy.days_of_week')));

      if (!this.get('newWeekendSurchargePolicy') && this.get('fixedType')) {
        var fee = this.get('weekendSurchargePolicy.amount');
        this.set('weekendSurchargePolicy.amount', this.formatMoney(fee, ''));
      }
    },
    amountTypeObserver: observer('weekendSurchargePolicy.amount_type', function () {
      if (this.get('weekendSurchargePolicy.amount_type') === 'percentage') {
        this.set('weekendSurchargePolicy.charge_per_day', false);
      }
    }),
    amountError: computed('percentageType', 'weekendSurchargePolicy.amount', 'triedToSave', function () {
      if (this.get('triedToSave')) {
        var amount = this.get('weekendSurchargePolicy.amount');

        if (!amount) {
          return '* required';
        }

        if (amount <= 0) {
          return 'must be > 0';
        }

        if (amount > 100 && this.get('percentageType')) {
          return 'must be 1-100';
        }
      }

      return false;
    }),
    compensationAmountError: computed('percentageCompensation', 'weekendSurchargePolicy.employee_compensation_amount', 'triedToSave', function () {
      if (this.get('triedToSave')) {
        var amount = this.get('weekendSurchargePolicy.employee_compensation_amount');

        if (!amount && amount != 0 || amount === '') {
          return '* required';
        }

        if (amount < 0) {
          return 'must be >= 0';
        }

        if (amount > 100 && this.get('percentageCompensation')) {
          return 'must be 1-100';
        }
      }

      return false;
    }),
    actions: {
      toggleChargePerDay: function toggleChargePerDay() {
        this.toggleProperty('weekendSurchargePolicy.charge_per_day');
      },
      cancel: function cancel() {
        if (this.get('loading')) {
          return;
        }

        this.get('onClose')();
        this.get('weekendSurchargePolicy').rollbackAttributes();

        if (this.get('weekendSurchargePolicy.id')) {
          this.set('weekendSurchargePolicy.days_of_week', this.get('originalDow'));
        }
      },
      changeAmountType: function changeAmountType(option) {
        this.set('weekendSurchargePolicy.amount_type', option.value);
      },
      save: function save() {
        var _this2 = this;

        this.set('triedToSave', true);

        if (this.get('amountError') || this.get('compensationAmountError') || this.get('weekendSurchargePolicy.days_of_week.length') == 0) {
          return;
        }

        this.set('loading', true);

        if (this.get('newWeekendSurchargePolicy')) {
          this.get('weekendSurchargePolicy').save().then(function () {
            _this2.get('onClose')();
          });
        } else {
          // Updates will destroy the record and create a new one which Ember data
          // doesn't like
          var policy = this.get('weekendSurchargePolicy');
          Ember.$.ajax({
            url: "/api/v2/weekend_surcharge_policies/".concat(this.get('weekendSurchargePolicy.id')),
            method: 'PUT',
            data: {
              weekend_surcharge_policy: {
                service_category_id: policy.get('service_category.id'),
                amount: policy.get('amount'),
                amount_type: policy.get('amount_type'),
                employee_compensation_type: policy.get('employee_compensation_type'),
                employee_compensation_amount: policy.get('employee_compensation_amount'),
                days_of_week: policy.get('days_of_week'),
                charge_per_day: policy.get('charge_per_day')
              }
            }
          }).then(function (response) {
            _this2.get('weekendSurchargePolicy').deleteRecord();

            _this2.get('store').findRecord('weekend-surcharge-policy', response.weekend_surcharge_policy.id).then(function (newPolicy) {
              newPolicy.set('service_category', _this2.get('serviceCategory'));

              _this2.set('serviceCategory.weekend_surcharge_policy', newPolicy);

              _this2.get('onClose')();
            });
          });
        }
      }
    }
  });

  _exports.default = _default;
});